// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-invisible {
    visibility: hidden !important;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
    /* margin-top: 0 !important; */
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-payment/integrate/method-form/stripe/stripe-integration-from/stripe-integration-from.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,SAAS;IACT,qBAAqB;IACrB,oBAAoB;IACpB,8BAA8B;EAChC","sourcesContent":[".error-invisible {\n    visibility: hidden !important;\n    height: 0;\n    padding: 0 !important;\n    margin: 0 !important;\n    /* margin-top: 0 !important; */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
