import { DomainItem } from './../../../../../models/domain';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-domain-details-popover',
  templateUrl: './domain-details-popover.component.html',
  styleUrls: ['./domain-details-popover.component.css'],
})
export class DomainDetailsPopoverComponent implements OnInit {
  @ViewChild('popOver') public popover: NgbPopover;
  closeCounter = false;
  @Input() onlyCard: boolean = false;
  @Input() domain: DomainItem;
  constructor() {}

  ngOnInit(): void {}

  popOverCloseCounter() {
    this.closeCounter = true;

    setTimeout(() => {
      if (!!this.closeCounter) {
        this.popover.close();
      }
    }, 300);
  }

  disableCloseCounter() {
    this.closeCounter = false;
  }
}
