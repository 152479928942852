import { Component, Input, OnInit } from '@angular/core';
import { AffiliateNotificationTypeEnum } from '../../../../../../../models/affiliates-dashboard';
import { AnnouncementAffiliateReadStatusEnum } from '../../../../../../../models/owner-dashboard';

@Component({
  selector: 'app-affiliate-announcement-type-label',
  templateUrl: './aff-announcement-label.component.html',
  styleUrls: ['./aff-announcement-label.component.css'],
})
export class AffiliateAnnouncementsTypeLabelComponent implements OnInit {
  announcementAffiliateReadStatusEnum = AnnouncementAffiliateReadStatusEnum;
  @Input() announcementStatus: AnnouncementAffiliateReadStatusEnum =
    AnnouncementAffiliateReadStatusEnum.unread;
  constructor() {}

  ngOnInit(): void {}
}
