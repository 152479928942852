import { createReducer, on } from '@ngrx/store';
import { CurrentCourseStudentsActions } from './course-students.actions';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';
import { SystemProcessLoadingStatus } from '../../../../../../models/global-classes';


export interface CourseStudentsState {
  currentCourseStudents: StudentItem[];
  currentCourseStudentsLoadProcess: {
    status: SystemProcessLoadingStatus;
    error: any;
    message?: string;
  };
  error: any;
}

const initialState: CourseStudentsState = {
  currentCourseStudents: [],
  currentCourseStudentsLoadProcess: { status: 'none', error: null },
  error: null,
};

export const courseStudentsReducer = createReducer(
  initialState,
  on(CurrentCourseStudentsActions.load, (state) => ({
    ...state,
    currentCourseStudentsLoadProcess: { status: 'loading', error: null },
  })),
  on(CurrentCourseStudentsActions.loadSuccess, (state, { students }) => ({
    ...state,
    currentCourseStudentsLoadProcess: { status: 'done', error: null },
    currentCourseStudents: students,
  })),
  on(CurrentCourseStudentsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    currentCourseStudentsLoadProcess: {
      status: 'error',
      error: error.message,
    },
  }))
);
