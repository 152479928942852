import { SharedPortalService } from './../../../../components/user-dashboard/portal/shared-portal.service';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import {
  HelpCategoryTypeEnum,
  HelpItemTypeEnum,
} from './../../../../models/onboarding-items';
import { OnboardingService } from './../../../services/onboarding.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { filter } from 'rxjs';

@Component({
  selector: 'app-get-help-menu',
  templateUrl: './get-help-menu.component.html',
  styleUrls: ['./get-help-menu.component.css'],
})
export class GetHelpMenuComponent implements OnInit {
  public currentUser: User;
  bugIcon: 'bug-color' | 'bug' = 'bug';
  helpItemTypeEnum = HelpItemTypeEnum;
  helpCategoryTypeEnum = HelpCategoryTypeEnum;

  constructor(
    private authenticationService: AuthenticationService,
    public onboardingService: OnboardingService,
    public sharedPortalService: SharedPortalService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  changeBug(bugIcon: 'bug-color' | 'bug' = 'bug') {
    this.bugIcon = bugIcon;
  }
  async pathBasedHelpLogic(path: string) {
    
    if (path == '/dashboard/portal' || path == '/dashboard') {
      // await this.sharedPortalService.getUserPortals();
      this.onboardingService.setHelpItem(
        'Portal',
        !!this.sharedPortalService.userWebsites?.websites?.length
          ? this.onboardingService.startHavePortalTour
          : this.onboardingService.startPortalTour
      );
    } else if (path.includes('/dashboard/portal/view')) {
      this.onboardingService.setHelpItem(
        'Portal Management',
        this.onboardingService.startInsidePortalTour
      );
    } else {
      this.onboardingService.resetHelpItem();
    }
  }
  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        
        this.pathBasedHelpLogic(event.url);
      });

    this.pathBasedHelpLogic(this.router.url);
  }
}
