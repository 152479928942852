// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';


import { Store } from '@ngrx/store';
import { CurrentCourseStudentsActions } from './course-students.actions';
import { ApiService } from '../../../../../services';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';

@Injectable()
export class CourseStudentsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}





  loadCurrentCourseStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseStudentsActions.load), // Listen for the loadProducts action
      mergeMap(({ productId, errorHandler = undefined }) =>
        this.api.getCourseStudentsById(productId, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: {students: StudentItem[]}) =>
            CurrentCourseStudentsActions.loadSuccess({ students: response.students })
          ),
          catchError((error) =>
            of(CurrentCourseStudentsActions.loadFailure({ error }))
          )
        )
      )
    )
  );

}
