import {
  simpleFadeAnimation,
  InOutAnimation,
} from './../../../../../shared/animations/animations';
import { RefundStatusEnum } from './../../../../../models/owner-dashboard';
import { SharedRefundService } from './../../shared-refund.service';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../../shared/services/lsg.api.service';
import {
  RefundItem,
  RefundActionEnum,
} from '../../../../../models/owner-dashboard';
import { CustomNgbModalService } from 'src/app/shared/services/custom-ngbmodal.service';




@Component({
  selector: 'app-refund-item-popup',
  templateUrl: './refund-item-popup.component.html',
  styleUrls: ['./refund-item-popup.component.css'],
  animations: [simpleFadeAnimation, InOutAnimation],
})
export class RefundItemPopupComponent implements OnInit {
  @Input() refundItem: RefundItem;
  refundStatusEnum = RefundStatusEnum;
  refundActionEnum = RefundActionEnum;


  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  processing: boolean = false;



  constructor(
    public sharedRefundService: SharedRefundService,
    private modalService: CustomNgbModalService,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {}

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      fullscreen: true,
      backdrop: true,
      animation: false,
      windowClass: 'modal-animate-in lesson-item-modal learmo-full-screen-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
    // this.resetProcess();
  }

 
  onStart(mark: 'Refused' | 'Refunded' = 'Refunded') {
    this.processing = true;
  }

  onSuccess(mark: 'Refused' | 'Refunded' = 'Refunded') {
    setTimeout(() => {
      this.closeModal();  
    }, 1000);
    
    this.processing = false;
  }

  onError(error:any) {
    
    this.processing = false;
  }

}
