// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btcpay-card {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 150px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.btcpay-card.question {
  height: 240px;
  min-height: 240px;
}

.btcpay-card.registered {
  min-height: 80px;
  height: 50px;
}

.custom-icon-switch.switch.switch-lg input:empty ~ span:after {
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay-special-method/btcpay-special-method.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,iBAAiB;EACjB,0DAA0D;AAC5D;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;AACA","sourcesContent":[".btcpay-card {\n  height: fit-content;\n  min-height: 150px;\n  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);\n}\n\n.btcpay-card.question {\n  height: 240px;\n  min-height: 240px;\n}\n\n.btcpay-card.registered {\n  min-height: 80px;\n  height: 50px;\n}\n\n.custom-icon-switch.switch.switch-lg input:empty ~ span:after {\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
