
import {
  RefundItem,
  RefundActionEnum,
} from './../../../models/owner-dashboard';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { RefundRequestsActions } from 'src/app/shared/store/_features/refunds/refunds.actions';


@Injectable({
  providedIn: 'root',
})
export class SharedRefundService {
  // refundRequestsList: RefundItem[] = [];

  currentRefundItem: RefundItem;

  loaded = false;
  processing: boolean = false;

  constructor(
    private api: ApiService,
    private sharedDashboardService: SharedOwnerDashboardService,
    private store: Store
  ) {}

  async getRefundListDetailed() {
    this.store.dispatch(RefundRequestsActions.load())
    // let refundList$ = this.api.getRefundList({ dummy: false });
    // let refundList = await lastValueFrom(refundList$);

    // this.refundRequestsList = refundList;
  }

  async resolveRefundRequest(
    refundItem: RefundItem,
    resolve: RefundActionEnum,
    errorHandler: any = undefined
  ) {
    let refundResolve$ = this.api.resolveRefundRequest(
      refundItem.id,
      resolve,
      errorHandler
    );
    let refundResolve = await lastValueFrom(refundResolve$);

    return refundResolve;
  }

  startLoading() {
    this.loaded = false;
  }

  stopLoading() {
    this.loaded = true;
  }

  startProcessing() {
    this.processing = true;
  }

  endProcessing() {
    this.processing = false;
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }
}
