import { Injectable } from '@angular/core';
import { PortalContentEnum } from '../../components/user-dashboard/portal/portal.model';

@Injectable({
  providedIn: 'root',
})
export class PortalControlService {
  canActivateLogin: boolean = true;
  canNavigateAll = true;
  isFirstWebsite = false;
  selectedDomainToCreatePortal: string = undefined;

  constructor() {}
}
