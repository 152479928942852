// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/affiliate/owner-announcements/owner-announcement-users-list-popup/owner-announcement-users-list-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
