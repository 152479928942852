import { UtilitiesService } from './../../services/utilities.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  Sanitizer,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-link-view',
  templateUrl: './link-view.component.html',
  styleUrls: ['./link-view.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LinkViewComponent implements OnInit {
  tippyImageProps: NgxTippyProps = {
    trigger: 'click mouse-outside click-outside',
    followCursor: false,
    allowHTML: true,
    appendTo: document.body,
    interactive: true,
  }

  
  @Input() url: string;
  @Input() title: string;
  @Input() description: string;
  @Input() placeholder: string = 'URL will be displayed here';
  @Input() enableShare: boolean = false;

  copying = false;
  // offerUrl: string = '';

  shareUrls: {
    facebook: any;
    twitter: any;
    instagram: any;
    whatsapp: any;
    telegram: any;
  } = {
    facebook: null,
    twitter: null,
    instagram: null,
    whatsapp: null,
    telegram: null,
  };

  @Input('url') set URLSetter(value: string) {
    this.url = value;
  }
  @Input() metaData: string[] = [];
  @Input() shareMessage: string = `Hello {meta[1]} welcome to {meta[2]}`;
  @Input() hashtags: string[] = [];
  constructor(
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.buildShareUrls();
  }

  copyText() {
    this.copying = true;

    this.utilitiesService.copyToClipboard(this.url);

    setTimeout(() => {
      this.copying = false;
      this.toastr.success('Done!', 'URL copied to clipboard', {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      });
    }, 600);
  }


  getMessage() {
    let message = this.shareMessage;
    this.metaData.forEach((meta, index) => {
      message = message.replace(`{meta[${index}]}`, meta);
    })

    return encodeURIComponent(message);
  }

  getHashtags() {
   let hashtags = this.hashtags.join(',')

    return encodeURIComponent(hashtags);
  }


  buildShareUrls = () => {
    let message = this.getMessage();
    let hashtags = this.getHashtags();

    this.shareUrls.facebook = this.sanitizer.bypassSecurityTrustUrl(
      `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
    );

    this.shareUrls.twitter = this.sanitizer.bypassSecurityTrustUrl(
      `https://twitter.com/intent/tweet?text=${message}&url=${this.url}&hashtags=${hashtags}`
    );
    // this.shareUrls.instagram =  this.sanitizer.bypassSecurityTrustUrl(`https://www.instagram.com/create/?caption=${this.offer.title}&media_url=${this.offer.image[0].url}`);
    this.shareUrls.whatsapp = this.sanitizer.bypassSecurityTrustUrl(
      `https://wa.me/?text=${message}&url=${this.url}`
    );
    this.shareUrls.telegram = this.sanitizer.bypassSecurityTrustUrl(
      `https://t.me/share/url?url=${this.url}&text=${message}`
    );
  }

  
}
