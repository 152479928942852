// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messenger-modal .modal-dialog {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: 0px;
}

.messenger-modal .modal-content {
  max-height: 100vh;
}

.messenger-modal .modal-content .card-body {
  max-height: 40vh;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/messenger/messenger-chat-popup/messenger-chat-popup.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".messenger-modal .modal-dialog {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  margin: 0px;\n}\n\n.messenger-modal .modal-content {\n  max-height: 100vh;\n}\n\n.messenger-modal .modal-content .card-body {\n  max-height: 40vh;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
