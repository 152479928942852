import { ActivatedRoute, Router } from '@angular/router';
import { slideInOut } from './../../../animations/animations';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationMenuItem } from 'src/app/models/owner-dashboard';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';

@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.css'],
  animations: [slideInOut]
})
export class MainNavBarComponent implements OnInit {
  @Input() sideMenu: NavigationMenuItem[];
  @Input() isMobile: boolean;
  @Input() isCollapsed: boolean;

  constructor(public sharedOwnerDashboardService: SharedOwnerDashboardService, public router: Router, public userDashboardService: UserDashboardService) {}
  ngOnInit(): void {
    this.sideMenu.forEach(item => {
      if (!!item.subItems?.length) {
      let routerActiveArray = item.subItems.forEach(sub => {
        if (this.router.url.includes(sub.routerLink)) {
          this.expandSidebarItem(this.sideMenu, item)
         
        }
      });
      
      }
    }
      );
  }

  expandSidebarItem (sideMenu: NavigationMenuItem[],item: NavigationMenuItem) {
    
    if (!!item?.subItems?.length) {
      this.sharedOwnerDashboardService.expandSidebarItem(sideMenu, item)
      this.sharedOwnerDashboardService.isSubmenuItemActive = true;
    }

  else {
    this.userDashboardService.closeMobileCollapse()
    this.sharedOwnerDashboardService.collapseSidebarItems(sideMenu);
    this.sharedOwnerDashboardService.isSubmenuItemActive = false;
  }
}
}
