import { Component, Input, OnInit } from '@angular/core';
import { OwnerNotificationTypeEnum } from '../../../../../../../models/owner-dashboard';
import { AffiliateNotificationTypeEnum } from 'src/app/models/affiliates-dashboard';

@Component({
  selector: 'app-owner-notification-type-label',
  templateUrl: './owner-noti-type-label.component.html',
  styleUrls: ['./owner-noti-type-label.component.css'],
})
export class OwnerNotificationTypeLabelComponent implements OnInit {
  ownerNotificationTypeEnum = OwnerNotificationTypeEnum;
  @Input() notificationType: AffiliateNotificationTypeEnum | OwnerNotificationTypeEnum =
    OwnerNotificationTypeEnum.general;
  constructor() {}

  ngOnInit(): void {}
}
