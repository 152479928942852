// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-image {
    animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/ai/ai-cta-card/ai-cta-card.component.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C;;AAEA;CACC;EACC,0BAA0B;CAC3B;CACA;EACC,4BAA4B;CAC7B;CACA;EACC,0BAA0B;CAC3B;AACD","sourcesContent":[".float-image {\n    animation: float 6s ease-in-out infinite;\n}\n\n@keyframes float {\n\t0% {\n\t\ttransform: translatey(0px);\n\t}\n\t50% {\n\t\ttransform: translatey(-20px);\n\t}\n\t100% {\n\t\ttransform: translatey(0px);\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
