import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { SharedAffiliateService } from '../../shared-affiliate.service';
import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
} from '../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../shared/services/lsg.api.service';
import { AffiliatesInvitationStatusEnum } from '../../../../../models/owner-dashboard';
import { emailRegex } from '../../../../../shared/services/regex';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}
@Component({
  selector: 'app-invite-affiliates',
  templateUrl: './invite-affiliates.component.html',
  styleUrls: ['./invite-affiliates.component.css'],
})
export class InviteAffiliatesComponent implements OnInit {
  public validators = [this.must_be_email];
  public errorMessages = {
    must_be_email: 'Enter valid email adress!',
  };
  
  invitationCurrentStepEnum = InvitationCurrentStepEnum;
  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;
  InvitationResult: AffiliateMinimizedModel[] = [];
  invitationCurrentStep: InvitationCurrentStepEnum =
    InvitationCurrentStepEnum.input;
  // Private Affiliate Links
  @Input() specificPrivateAffiliateLinks: boolean = false;
  @Input() selectedPrivateAffiliateLinks: number[] = [];

  // Ng-Tag Emails Input
  emailsError: boolean = false;
  myPrivateAffiliateLinks: AffiliateLinkMinimizedModel[];
  splitPattern = new RegExp('[,; \n]');
  emailsList: string[] = [];
  invalidEmailCount: { added: number; invalid: number; repeat: number } = {
    added: 0,
    invalid: 0,
    repeat: 0,
  };

  // Report Toaster
  reportToaster: ActiveToast<any>;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    await this.getMyAffiliateLinks();
  }

  async getMyAffiliateLinks() {
    const affiliateLinksData$ = this.api.getMyAffiliateLinks();
    const affiliateLinksData = await lastValueFrom(affiliateLinksData$);
    this.myPrivateAffiliateLinks = affiliateLinksData;
  }

  async inviteAffiliates(invitationData: {
    emails: string[];
    affiliateLinkIds: number[];
  }) {
    const inviteAffiliates$ = this.api.inviteAffiliates(invitationData);
    const inviteAffiliates: AffiliateMinimizedModel[] = await lastValueFrom(
      inviteAffiliates$
    );
    this.InvitationResult = inviteAffiliates;
    return inviteAffiliates;
  }
  async inviteAndGoNext() {
    this.sharedAffiliateService.startProcessing();
    setTimeout(async () => {
      if (!!this.emailsList?.length) {
        await this.inviteAffiliates({
          affiliateLinkIds: this.selectedPrivateAffiliateLinks,
          emails: this.emailsList,
        });
        this.onInviteDone();
      } else {
        this.sharedAffiliateService.startProcessing();
        this.emailsError = true;
      }
    }, 400);
  }

  async onInviteDone() {
    this.toastr.success('Done!', 'Affilaites invited successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.sharedAffiliateService.endProcessing();
    this.invitationCurrentStep = InvitationCurrentStepEnum.result;
    await this.sharedAffiliateService.getMyAffiliates();
  }

  resetModal() {
    this.invitationCurrentStep = InvitationCurrentStepEnum.input;
    this.emailsList = [];
    this.selectedPrivateAffiliateLinks = [];
    this.InvitationResult = [];
  }
  closeModal() {
    this.modalRef.dismiss();
    this.resetModal();
  }

  // // Affiliate Links List
  // onAffiliateLinkChecked(value: any, isChecked: boolean) {
  //   const index = this.selectedPrivateAffiliateLinks.findIndex(
  //     (a) => a === value
  //   );
  //   if (isChecked && index == -1) {
  //     this.selectedPrivateAffiliateLinks.push(value);
  //   } else {
  //     this.selectedPrivateAffiliateLinks.splice(index, 1);
  //   }
  // }

  // isInputChecked(value: any) {
  //   const index = this.selectedPrivateAffiliateLinks.findIndex(
  //     (a) => a === value
  //   );

  //   if (index == -1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  // Ngx-Tag Validation
  invalidEmailError() {
    this.reportToaster?.toastRef?.close();
    this.reportToaster = this.toastr.show(
      `${this.invalidEmailCount.added} <b class='text-success'>Added</b>` +
        `<br>` +
        `${this.invalidEmailCount.invalid} <b class='text-danger'>Invalid</b>` +
        `<br>` +
        `${this.invalidEmailCount.repeat} <b class='text-warning'>Duplicates</b>`,
      'Report',
      {
        enableHtml: true,
        toastClass: 'toastr-custom-emails ngx-toastr',
      }
    );
    this.invalidEmailCount = { added: 0, invalid: 0, repeat: 0 };
  }

  invalidEmailCounter(event: { display: string; value: string }) {
    const index = this.emailsList.findIndex((a) => a == event.value);
    if (index == -1) {
      this.invalidEmailCount.invalid++;
    } else {
      this.invalidEmailCount.repeat++;
    }
  }

  onAdd() {
    this.invalidEmailCount.added++;
  }


  private must_be_email(control: UntypedFormControl) {
    const EMAIL_REGEXP = emailRegex;
    if (
      control.value != '' &&
      (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
    ) {
      return { must_be_email: true };
    }
    return null;
  }
}
