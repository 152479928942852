import {
  BlogItemStatusEnum,
  BlogPostItem,
} from './../../../../../../models/blog.model';
import { ApiService } from 'src/app/shared/services';
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { updatePostStatusActions } from 'src/app/shared/store/_features/website/blog/website-blog.actions';
import { selectBlogPostProcessStatus } from 'src/app/shared/store/_features/website/blog/website-blog.selector';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-dynamic-post-status',
  templateUrl: './dynamic-post-status.component.html',
  styleUrls: ['./dynamic-post-status.component.css'],
})
export class DynamicPostStatusComponent implements OnInit {
  loaded: boolean = false;

  postStausEnum = BlogItemStatusEnum;

  @Input() item: BlogPostItem;

  constructor(
    private toastr: ToastrService,
    public api: ApiService,
    private store: Store
  ) {}

  ngOnInit() {}

  changePostStatus(post: BlogPostItem, status: BlogItemStatusEnum) {
    this.loaded = true;
    let successsMessage = null;
    switch (status) {
      case BlogItemStatusEnum.published:
        successsMessage = 'Post Published Successfully';
        break;
      case BlogItemStatusEnum.draft:
        successsMessage = 'Post Drafted Successfully';
        break;
      default:
        break;
    }

    this.store.dispatch(
      updatePostStatusActions.update({ postId: post.id, status })
    );



    this.store.select(selectBlogPostProcessStatus).pipe(takeWhile((process) => process.status === 'updating', true)).subscribe((process) => {
        if ( process.status === 'done')
       {

        return this.toastr.success(successsMessage);

        } else if (!!process.error) {

          return this.toastr.error(process?.error?.error);

        }
      });
  }
}
