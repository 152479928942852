import { SharedProfileService } from '../shared-profile.service';

import {
  Component,
  OnInit,
  Renderer2,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { AuthenticationService } from '../../../shared/services';
import { User } from 'src/app/models';
import { ProfileUiControlService } from '../../../shared/services/profile-ui-control.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';
import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy {

  static onUpdateProfileEvent: EventEmitter<any>;
  onUpdateProfileEvent: Subscription;

  constructor(
    private route: Router,
    private renderer: Renderer2,

    public profileUiService: ProfileUiControlService,
    public userDashboardService: UserDashboardService,
    private sharedOwnerDashboardService: SharedOwnerDashboardService,
    public sharedProfileService: SharedProfileService,
    private title: Title
  ) {
    this.title.setTitle('Account Settings')
  }
  ngOnDestroy(): void {


    this.userDashboardService.showUi(['sidebar', 'header']);
    
    // if (!this.userDashboardService.isMobile) {
    //   this.userDashboardService.deactivateSidebarMinimizeMode();
    // }
  }
  ngOnInit(): void {
    this.sharedOwnerDashboardService.clearTitle();
    this.sharedOwnerDashboardService.clearBreadcrumbs();
    this.sharedOwnerDashboardService.clearActions();
    
    this.buildNavigationMenu();
    // if (this.userDashboardService.isMobile) {
    //   this.userDashboardService.closeMobileCollapse();
    // } else {
    //   this.userDashboardService.activateSidebarMinimizeMode();
    // }


    // this.sharedProfileService.loaded = true;



  }

  buildNavigationMenu() {
    this.sharedProfileService.profileNavigationMenuItems = [
      {
        label: 'Account Information',
        url: `/dashboard/profile/account`,
        iconPath: 'assets/images/svg/system/overview.svg',
        customId: 'portal-overview',
      },

      {
        label: 'Sign In & Security',
        url: `/dashboard/profile/security`,
        iconPath: 'assets/images/svg/system/settings.svg',
        customId: 'portal-payment-methods',
      },
      {
        label: 'Payment Methods',
        url: `/dashboard/profile/payments`,
        iconPath: 'assets/images/svg/system/settings.svg',
        customId: 'portal-payment-methods',
      },
      {
        label: 'Subscription & Billing',
        url: `/dashboard/profile/subscription-and-billing`,
        iconPath: 'assets/images/svg/system/settings.svg',
        customId: 'portal-payment-methods',
      },
    ];
  }

  navigateToProfile(route: string) {
    this.route.navigate(['profile', route]);
  }

  profileAsideToggle() {
    this.profileUiService.profileAsideStatus =
      !this.profileUiService.profileAsideStatus;
    if (this.profileUiService.profileAsideStatus === true) {
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeStyle(document.body, 'overflow');
    }
  }
}
