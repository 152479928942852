import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AiService } from '../../services/ai.service';

import LearmoEditorJS from './utils';
import { debounceTime, map, Subscription, switchMap, tap } from 'rxjs';


// export enum AIGeneratorTypeEnum {
//   none = 'none',
//   FloatBottom = 'float_bottom',
//   long_description = 'long_description',
//   question = 'question',
// }

@Component({
  selector: 'app-learmo-block-editor',
  templateUrl: './learmo-block-editor.component.html',
  styleUrls: ['./learmo-block-editor.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LearmoBlockEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  editorController!: LearmoEditorJS;

  // htmlDebugContent = '';
  // aIGeneratorTypeEnum = AIGeneratorTypeEnum;

  isInitialContentSet = false;
  initialContent = '';

  content = '';

  // unsavedContent = '';

  preventAutoStart = false;
  _starter = false;
  @Input('starter') set setStrater(value: boolean) {
    if (!value) {
      this.preventAutoStart = true;
    }
    this._starter = value;
    if ((!this.editorController?.isInitialized && !this.editorController?.isEditorReady) && value == true) {
      this.initializeEditor();
    }
  }

  // @Input('unsavedContent') set setUnsavedContent(value: string) {
  //   this.unsavedContent = value;
  // }
  @Input('content') set setContent(value: string) {
    if (!this.isInitialContentSet) {
      this.initialContent = value;
      this.isInitialContentSet = true;
    }
    if (value != this.editorController?.lastEmitContent) {
      this.content = value;
      this.editorController?.setHTMLContent(value, true);
    }
    // this.content = value;
  }

  @Output() CatchController = new EventEmitter<LearmoEditorJS>();
  @Output() contentChange = new EventEmitter<string>();

  @Input() stickEditorHead = true;
  @Input() containerMinHeight = '250px';
  @Input() containerMaxHeight = '400px';
  // @Input() containerClass = '';
  @Input() editorID = '';
  @Input() placeholder = '';
  // @ViewChild('editorContainer') modalContent: TemplateRef<any>;
  // @Input() modalRef: NgbModalRef;

  // @Input() aiType: AIGeneratorTypeEnum = AIGeneratorTypeEnum.none;
  @Input() promptFunction: (...args: any) => string = (inputValue: string) => {
    return `Generate a html start with <body> formatted, description consists of (Overview, Objectives, Methodology, Prerequisites and Thoughts) for the course '${inputValue}' **use html for bullets and lists and include emojis**:`;
  };

  
  @Input() hideBuiltInAiButton = false;

  modalMode = false;
  muteChangeEmit = true;
  @Input() generatorInputValue = '';

  subscriptions: Subscription[] = [];
  constructor(private modalService: NgbModal, private ai: AiService) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.preventAutoStart) {
      this.initializeEditor();
    }
  }

  initializeEditor() {
    if (!this.editorID?.length) {
      this.editorID = LearmoEditorJS.generateUniqueID();
    }
    this.editorController = new LearmoEditorJS(
      {
        content: this.initialContent,
        editorID: this.editorID,
        promptFunction: this.promptFunction,
      },
      this.ai
    );

    this.subscriptions.push(
      this.editorController.onReady$.subscribe(this._onReady)
    );
    this.subscriptions.push(
      this.editorController.onChange$.pipe(
        tap(() => this._onDebounceStart()), // Action when debounce starts
        debounceTime(3500),
        tap(() => this._onDebounceEnd()),   // Action when debounce ends
        map((value) => this._onChange(value))   // Your main change logic
      ).subscribe()
    );
  }

  private _onDebounceStart() {
    console.log('Debounce started');
    this.editorController.debonucing = true;
    // Add your start logic here
  }

  private _onDebounceEnd() {
    console.log('Debounce ended');
    this.editorController.debonucing = false;
    // Add your end logic here
  }
  clearEditor() {
    // this.htmlDebugContent = '';
    this.editorController.clearEditor();
  }

  startGenerating() {
    this.editorController.startGenerating();
  }

  private _onChange = (value: string) => {
    console.log('change', value);
    this.contentChange.emit(value);
    
  };

  private _onReady = () => {
    this.CatchController.emit(this.editorController);
  };


  saveCurrentValue() {
    console.log('Editor Blurred')
    this.editorController.saveEditorCurrentValue();
  }

  // closeModal() {
  //   this.modalRef.dismiss();
  // }

  // async open(content?) {
  //   this.onOpenModal();
  //   this.modalRef = this.modalService.open(this.modalContent, {
  //     ariaLabelledBy: 'modal-basic-title',
  //     size: 'lg',
  //     fullscreen: true,
  //     backdrop: true,
  //     centered: true,
  //     animation: false,
  //     windowClass: 'global-modal-class learmo-editor-modal',
  //     beforeDismiss: () => {
  //       this.modalMode = false;
  //       return true;
  //     },
  //   });
  // }

  // async onOpenModal() {
  //   this.modalMode = true;
  //   this.editorController.setHTMLToEditor(this.content, { emitValue: false });
  // }
}
