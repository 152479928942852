
const languages = [
    { "value": "af", "label": "Afrikaans" },
    { "value": "sq", "label": "Albanian" },
    { "value": "ar", "label": "Arabic" },
    { "value": "hy", "label": "Armenian" },
    { "value": "eu", "label": "Basque" },
    { "value": "bn", "label": "Bengali" },
    { "value": "bg", "label": "Bulgarian" },
    { "value": "ca", "label": "Catalan" },
    { "value": "km", "label": "Cambodian" },
    { "value": "zh", "label": "Chinese (Mandarin)" },
    { "value": "hr", "label": "Croatian" },
    { "value": "cs", "label": "Czech" },
    { "value": "da", "label": "Danish" },
    { "value": "nl", "label": "Dutch" },
    { "value": "en", "label": "English" },
    { "value": "et", "label": "Estonian" },
    { "value": "fj", "label": "Fiji" },
    { "value": "fi", "label": "Finnish" },
    { "value": "fr", "label": "French" },
    { "value": "ka", "label": "Georgian" },
    { "value": "de", "label": "German" },
    { "value": "el", "label": "Greek" },
    { "value": "gu", "label": "Gujarati" },
    { "value": "he", "label": "Hebrew" },
    { "value": "hi", "label": "Hindi" },
    { "value": "hu", "label": "Hungarian" },
    { "value": "is", "label": "Icelandic" },
    { "value": "id", "label": "Indonesian" },
    { "value": "ga", "label": "Irish" },
    { "value": "it", "label": "Italian" },
    { "value": "ja", "label": "Japanese" },
    { "value": "jw", "label": "Javanese" },
    { "value": "ko", "label": "Korean" },
    { "value": "la", "label": "Latin" },
    { "value": "lv", "label": "Latvian" },
    { "value": "lt", "label": "Lithuanian" },
    { "value": "mk", "label": "Macedonian" },
    { "value": "ms", "label": "Malay" },
    { "value": "ml", "label": "Malayalam" },
    { "value": "mt", "label": "Maltese" },
    { "value": "mi", "label": "Maori" },
    { "value": "mr", "label": "Marathi" },
    { "value": "mn", "label": "Mongolian" },
    { "value": "ne", "label": "Nepali" },
    { "value": "no", "label": "Norwegian" },
    { "value": "fa", "label": "Persian" },
    { "value": "pl", "label": "Polish" },
    { "value": "pt", "label": "Portuguese" },
    { "value": "pa", "label": "Punjabi" },
    { "value": "qu", "label": "Quechua" },
    { "value": "ro", "label": "Romanian" },
    { "value": "ru", "label": "Russian" },
    { "value": "sm", "label": "Samoan" },
    { "value": "sr", "label": "Serbian" },
    { "value": "sk", "label": "Slovak" },
    { "value": "sl", "label": "Slovenian" },
    { "value": "es", "label": "Spanish" },
    { "value": "sw", "label": "Swahili" },
    { "value": "sv", "label": "Swedish" },
    { "value": "ta", "label": "Tamil" },
    { "value": "tt", "label": "Tatar" },
    { "value": "te", "label": "Telugu" },
    { "value": "th", "label": "Thai" },
    { "value": "bo", "label": "Tibetan" },
    { "value": "to", "label": "Tonga" },
    { "value": "tr", "label": "Turkish" },
    { "value": "uk", "label": "Ukrainian" },
    { "value": "ur", "label": "Urdu" },
    { "value": "uz", "label": "Uzbek" },
    { "value": "vi", "label": "Vietnamese" },
    { "value": "cy", "label": "Welsh" },
    { "value": "xh", "label": "Xhosa" }
  ]
  

export default languages;