// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';
import { ApiService } from '../../../services';
import { createNewProductActions,CurrentProductActions, ProductsActions, productOfferActions, updateCurrentProductActions } from './products.actions';
import { ProductItem } from 'src/app/components/user-dashboard/product/models/product';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { startOfferLoadingActions } from '../offers/offers.actions';
import { Store } from '@ngrx/store';


@Injectable()
export class ProductsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {

  }


  loadProducts$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProductsActions.load), // Listen for the loadProducts action
    mergeMap(() =>
      this.api.getProducts().pipe( // Use pipe method correctly within mergeMap
        map((products) => ProductsActions.loadSuccess({ products })),
        catchError((error) => of(ProductsActions.loadFailure({ error }))
      )
    )
  )
));


loadCurrentProduct$ = createEffect(() =>
this.actions$.pipe(
  ofType(CurrentProductActions.load), // Listen for the loadProducts action
  mergeMap(({productId}) =>
    this.api.getProductById(productId).pipe( // Use pipe method correctly within mergeMap
      map((response: ProductItem) => CurrentProductActions.loadSuccess({ product: response })),
      catchError((error) => of(CurrentProductActions.loadFailure({ error }))
    )
  )
)
));


createNewOffer$ = createEffect(() =>
this.actions$.pipe(
  ofType(createNewProductActions.create), // Listen for the loadProducts action
  mergeMap(({createPayload}) =>

    this.api.createCourse(createPayload).pipe( // Use pipe method correctly within mergeMap
      map((response: ProductItem) => createNewProductActions.createSuccess({ product: response })),
      catchError((error) => of(createNewProductActions.createFailure({ error }))
    )
  )
)
));

updateCurrentProduct$ = createEffect(() =>
this.actions$.pipe(
  ofType(updateCurrentProductActions.update), // Listen for the loadProducts action
  mergeMap(({productId, changes}) =>
    this.api.updateProduct(productId, changes, (error)=>{throw error}).pipe( // Use pipe method correctly within mergeMap
      map((response: {product: ProductItem}) => updateCurrentProductActions.updateSuccess({ product: response.product })),
      catchError((error) => of(updateCurrentProductActions.updateFailure({ error }))
    )
  )
)
));


setOfferAsDefault$ = createEffect(() =>
this.actions$.pipe(
  ofType(productOfferActions.setOffer), // Listen for the loadProducts action
  tap(({offerId, productId}) => this.store.dispatch(startOfferLoadingActions.startOffer({offerId, productId, loadingStatus: 'loading'}))), // Dispatch startOfferLoading action before API call
  mergeMap(({offerId}) =>
    this.api.setOfferAsDefault(offerId, (error)=>{throw error}).pipe( // Use pipe method correctly within mergeMap
      map((response: {offer: OfferItem}) => productOfferActions.setOfferSuccess({ offerId: offerId, offer: response.offer })),
      catchError((error) => of(productOfferActions.setOfferFailure({offerId ,error }))
    )
  )
)
));
}
