import { MoneyModel } from './sales-funnel';

export class PageItem {
  public id: number;
  public status: PageItemStatusEnum;
  public url: string;
  public title: string;
  public createdAt: number;
  public updatedAt: number;
  public screenshotIndex: number = null;
  public loading: boolean = false;
  public screenshot: string = 'assets/images/svg/loading.svg';
}

export enum PageItemStatusEnum {
  published = 'publish',
  draft = 'draft',
  system = 'system',
}

export enum Payment_Gateway_Enum {
  paypal = 'payPal',
  btcpay = 'btcPay',
  stripe = 'stripe',
}

export enum Payout_Gateway_Enum {
  paypal = 'payPal',
  btcpay = 'btcPay',
  stripe = 'stripe',
  wireTransfer = 'wireTransfer',
}

export class OwnerPayoutSettingsObject {
  payoutDue: PayoutDuePeriodObject;
  payoutMethods: OwnerPayoutMethodObject[];
}

export class OwnerPayoutMethodObject {
  public id?: number;
  public display_name: string;
  public enum: Payout_Gateway_Enum;
  public priorityGrade?: number;
  public icon?: string;
  public status?: PayoutMethodStatusEnum;
  public minimumAmount?: MoneyModel;
}

export enum PayoutMethodStatusEnum {
  on = 'on',
  off = 'off',
  unset = 'unset',
  unavailable = 'unavailable',
}

export class PayoutDuePeriodObject {
  public id?: number;
  public displayName?: string;
  public enum?: PayoutDuePeriodEnum;
  public every?: number;
}

export enum PayoutDuePeriodEnum {
  weekly = 'weekly',
  every15days = 'every15days',
  monthly = 'monthly',
}

export class PortalSenderEmailInput {
  constructor(public fromName: string, public replyToEmail: string) {}
}

export class PaypalInfoModel {
  integrated: boolean;
  email: string;
}
export class BTCPayInfoModel {
  integrated: boolean;
  public apiKey?: string;
  public btcPayUrl?: string;
  public storeId?: string;
  public isWalletCreated?: boolean;
}
export class StripeInfoModel {
  integrated: boolean;
  accountId: string;
}
export class PaymentGatewaysInformation {
  payPal: PaypalInfoModel;
  btcPay: BTCPayInfoModel;
  stripe: StripeInfoModel;
}

export class PaymentGatewaysIntegration {
  payPal?: PaypalPaymentGatewayModel;
  btcPay?: BtcpayPaymentGatewayModel;
  stripe?: StripePaymentGatewayModel;
}

export class PaymentGatewayModel {
  id: Payment_Gateway_Enum;
  public name: string;
  public description: string;
  public image: string;
  // public status: 'integrated' | 'not-integrated';
  integrated: boolean | null;
  // Paypal

  public email?: string;

  // Stripe
  public accountId?: string;

  // BTCPay
  public apiKey?: string;
  public btcPayUrl?: string;
  public storeId?: string;
  public isWalletCreated?: boolean;
}

export class StripePaymentGatewayModel {
  constructor(
    public publishableKey: string = null,
    public secretKey: string = null
  ) {}
}
export class BtcpayPaymentGatewayModel {
  constructor(
    public btcPayUrl: string = null,
    public apiKey: string = null,
    public storeId: string = null,
    public returnURL?: string
  ) {}
}
export class PaypalPaymentGatewayModel {
  constructor(
    public emailAddress: string = null,
    public merchantId: string = null,
    public clientId: string = null,
    public secretKey: string = null
  ) {}
}

export enum WS_Website_Process_State_Enum {
  create,
  delete,
}

export class SortingTypeModel {
  public index?: number;
  public displayName: string;
  public column: string;
  public order: 'asc' | 'dsc';
}

export class FilteringTypeModel {
  public index?: number;
  public displayName: string;
  filters?: { column: string, value: any }[];
  exclusions?: { column: string, value: any }[];

  // public column?: string;
  // public value?: string;
  // public column2?: string;
  // public value2?: string;
  // public excludeCol?: string;
  // public excludeVal?: string;
  public execution?: () => void | Promise<void>;
}

export class WebsiteUpdate {
  public readonly id: number;
  public readonly portalName: string;
  public readonly template: string;
  public readonly url: string;
  public step: string;
  public percentage: number;
  public processType: WS_Website_Process_State_Enum;
  public error: {
    readonly code: number;
    readonly message: string;
  };
}

export class RolePermissions {
  public readonly type: string;
  public readonly value: number;
}
export class RoleModel {
  public role: string;
  public rolePermissions: RolePermissions[];
}

export class CourseModel {
  public readonly id: number;
  public readonly name: string;
  public readonly image?: string;
  public readonly description: string;
  public readonly progress: {
    totalSteps: number;
    currentStep?: number;
    completedSteps: number;
  };
  public readonly courseUrl: string;
}
