import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ai-outline-cta-card',
  templateUrl: './ai-outline-cta-card.component.html',
  styleUrls: ['./ai-outline-cta-card.component.css'],
})
export class AiOutlineCtaCardComponent implements OnInit {
  @Input() type: 'title' | 'description' = 'title';
  @Output() CTAClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() SecondaryClick: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
