import { Pipe, PipeTransform } from '@angular/core';
import { genericFilter } from './utils/generic-filter.utils'; // Adjust the path accordingly

@Pipe({
  name: 'geniricfilter',
})
export class GeniricFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, exclude: string[] = []): any[] {
    return genericFilter(items, searchText, exclude);
  }
}
