import { AccountSetupService } from './account-setup.service';
import { selectAccountSetupSteps } from './../../../../../shared/store/_features/account-setup/account-setup.selector';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services';
import { ProductItem, ProductModel } from '../../../product/models/product';
import { CourseModel } from 'src/app/models/portal';
import { OfferItem } from '../../../offer/models/offer';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { ProductsActions } from 'src/app/shared/store/_features/products/products.actions';
import { selectProducts } from 'src/app/shared/store/_features/products/products.selector';
import {
  loadAccountSetupStepsActions,
   setStepInProgress,
  stepUpdate,
} from 'src/app/shared/store/_features/account-setup/account-setup.actions';

export enum AccountSetupStepStatusEnum {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum AccountSetupStepActionEnum {
  create_product = 'create_product',
  build_the_content = 'build_the_content',
  setup_offer = 'setup_offer',
  set_payment_gateways = 'set_payment_gateways',
  publish_and_sell = 'publish_and_sell',
}
export class AccountSetupStep {
  id: number;
  title: string;
  description: string;
  actionEnum: AccountSetupStepActionEnum;
  status: AccountSetupStepStatusEnum;
  meta?: {
    course?: ProductItem & { product_id: any };
    offer?: OfferItem;
  };
  missClick?: boolean;
}
@Component({
  selector: 'app-setup-steps',
  templateUrl: './setup-steps.component.html',
  styleUrls: ['./setup-steps.component.css'],
})
export class SetupStepsComponent implements OnInit {
  disableTooltip: boolean = false;
  manyProducts: boolean = false;

  @Output() stepClicked = new EventEmitter<AccountSetupStep>();
  accountSetupStepStatusEnum = AccountSetupStepStatusEnum;

  // products$: Observable<ProductItem[]>;

  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private store: Store,
    public accountSetupService: AccountSetupService
  ) {
    // this.products$ = this.store.select(selectProducts);
  }
  // ngOnDestroy(): void {
  //   this.accountSetupService.subscriptions.forEach(subscription => subscription.unsubscribe());
  // }

  async ngOnInit() {
    await this.getSteps();
  }



  goToBuildContent(
    id: number = this.accountSetupService.widgetSteps[0]?.meta?.course
      ?.product_id
  ) {
    if (!!id) {
      this.router.navigate(['dashboard', 'product', id, 'course', 'builder']);
    }
  }

  goToSetFirstOffer(
    id: number = this.accountSetupService.widgetSteps[0]?.meta?.offer?.id
  ) {
    if (!!id) {
      this.router.navigate(['dashboard', 'offer', id, 'edit']);
    }
  }

  async getSteps() {
    this.store.dispatch(loadAccountSetupStepsActions.load());
    // let request$ = this.api.getAccountSetupSteps();
    // let response: { AccountSetupStep: AccountSetupStep[] } = await lastValueFrom(request$);
    //
    // this.widgetSteps = response.AccountSetupStep;
  }

  onStepClicked(step: AccountSetupStep) {
    if (step.status == AccountSetupStepStatusEnum.DONE) {
      // this.stepClicked.emit(step);
      return false;
    }

    if (step.id != this.accountSetupService.nextStep.id) {
      let step: AccountSetupStep;

      step = JSON.parse(JSON.stringify(this.accountSetupService.nextStep));
      this.disableTooltip = true;
      step.missClick = true;
      this.store.dispatch(stepUpdate({ step }));
      setTimeout(() => {
        step = JSON.parse(JSON.stringify(this.accountSetupService.nextStep));
        step.missClick = false;
        this.store.dispatch(stepUpdate({ step }));
        this.disableTooltip = false;
      }, 2500);

      return false;

    }

    this.store.dispatch(setStepInProgress({ step }));

    setTimeout(() => {
      switch (step.actionEnum) {
        case AccountSetupStepActionEnum.create_product:
          this.goToCreateProduct();
          break;
        case AccountSetupStepActionEnum.build_the_content:
          this.goToBuildContent();
          break;
        case AccountSetupStepActionEnum.setup_offer:
          this.goToSetFirstOffer();
          break;
        default:
          break;
      }
      this.stepClicked.emit(step);
    }, 1000);
  }

  goToCreateProduct() {
    this.router.navigate(['dashboard', 'product', 'create']);
  }
}
