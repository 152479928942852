import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import OpenAI from 'openai';
import {
  ChatCompletionCreateParams,
  ChatCompletionCreateParamsStreaming,
  ChatCompletionUserMessageParam,
  CompletionCreateParams,
  CompletionCreateParamsStreaming,
} from 'openai/resources';
import { Stream } from 'openai/streaming';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

class TextCompletionChunk {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    index: number;
    finish_reason: string | null;
    text: string;
    logprobs: {
      top_logprobs: Array<Record<string, unknown>>;
    };
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  openai = new OpenAI({
    apiKey: environment.aiConfig.apiKey,
    dangerouslyAllowBrowser: true,
    maxRetries: 5,
    defaultQuery: {},
    baseURL: 'https://ai-beta.learmo.dev/v1',
  });

  // private fallbackApiUrl = 'https://ai-beta.learmo.dev/api/v1/generate';
  private apiUrl = 'https://ai-beta.learmo.dev/v1/completions';
  // private apiUrl = 'http://127.0.0.1:5000/v1/completions';
  // private apiUrl = 'https://ai-gen2.learmo.dev/v1/completions' // AI GEN2

  constructor(private http: HttpClient) {}

  generateRandomNumber(): number {
    // let exclude = [1,2,4,6,7,8,9];

    // let include = [3,5,10];
    let include = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return include[Math.floor(Math.random() * include.length)] ?? null;
  }

 getChatCompletion(
  history: ChatCompletionUserMessageParam[],
  message: string,
  stream: boolean = true,
  useTemplate: boolean = true
): Observable<string> {
  if (useTemplate) {
    // Apply any templating logic if needed
    message = `USER: ${message}\nASSISTANT:`;
  }

  const body: ChatCompletionCreateParams = {
    model: environment.aiConfig.modelName,
    messages: [...history, { role: "user", content: message }],
    stream,
  };

  return this.getChatCompletionRequest(body);
}

getChatCompletionRequest(body: ChatCompletionCreateParams): Observable<string> {
  let receivedString = '';

  return new Observable<string>((observer) => {
    const request = this.openai.chat.completions.create({ ...body });

    request
      .then(async (stream) => {
        const decoder = new TextDecoder('utf-8');

        if (this.isStream(stream)) {
          for await (const chunk of stream) {
            receivedString += chunk.choices[0].delta?.content ?? '';

            if (chunk.choices[0].finish_reason === 'stop') {
              observer.complete();
              return;
            }

            if (chunk.choices[0]?.delta?.content) {
              observer.next(chunk.choices[0].delta.content);
            }
          }
        } else {
          observer.next(stream.choices[0].message.content);
        }
      })
      .catch((err: Error) => {
        Swal.fire({
          title: 'Temporary Unavailability',
          text: 'The AI service is currently unavailable. Please try again later.',
          icon: 'warning',
          preConfirm: () => observer.error(err),
        });
      });
  });
}


  //  generateRandomNumber(): number {
  //   // let exclude = [1,2,4,6,7,8,9];

  //   // Generate a random number between 1 and 10
  //   const randomNumber: number = Math.floor(Math.random() * 10) + 1;
  //   if (exclude.includes(randomNumber)) {
  //     return this.generateRandomNumber();
  //   }
  //   return randomNumber;
  // }

  getCompletion(
    prompt: string,
    stream: boolean = true,
    useTemplate: boolean = true
  ): Observable<string> {
    if (useTemplate) {
      // Apply template if needed
      prompt = `<|begin_of_text|><|start_header_id|>user<|end_header_id|>
      ${prompt}<|eot_id|><|start_header_id|>assistant<|end_header_id|>`;
    }
  
    const todayDate = new Date().toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
    const systemMessage = `Cutting Knowledge Date: December 2023
    Today Date: ${todayDate}
    You are a highly capable, helpful assistant, designed by Learmo to provide precise and concise responses that strictly adhere to instructions. Deliver exactly the information requested without adding any extra context or commentary.`;
  
    const finalPrompt = `<|begin_of_text|><|start_header_id|>system<|end_header_id|>
    ${systemMessage}<|eot_id|><|start_header_id|>user<|end_header_id|>
    ${prompt}<|eot_id|><|start_header_id|>assistant<|end_header_id|>`;
  
    const body: CompletionCreateParams = {
      model: environment.aiConfig.modelName,
      prompt: finalPrompt,
      max_tokens: 2048,
      temperature: 1.8,
      top_p: 0.18,
      seed: this.generateRandomNumber(),
      stream,
    };
  
    return this.getCompletionRequest(body);
  }
  
  getCompletionRequest(body: CompletionCreateParams): Observable<string> {
    let receivedString = '';
  
    return new Observable<string>((observer) => {
      const request = this.openai.completions.create({ ...body });
  
      request
        .then(async (stream) => {
          const decoder = new TextDecoder('utf-8');
  
          if (this.isStream(stream)) {
            for await (const chunk of stream) {
              receivedString += chunk.choices[0].text;
  
              if (chunk.choices[0].finish_reason === 'stop') {
                observer.complete();
                return;
              }
  
              if (chunk.choices[0]?.text) {
                observer.next(chunk.choices[0].text);
              }
            }
          } else {
            observer.next(stream.choices[0].text);
            observer.complete();
          }
        })
        .catch((err: Error) => {
          Swal.fire({
            title: 'Temporary Unavailability',
            text: 'The AI service is currently unavailable. Please try again later.',
            icon: 'warning',
            preConfirm: () => observer.error(err),
          });
        });
    });
  }
  

  isStream<T>(obj: any): obj is Stream<T> {
    return typeof obj?.[Symbol.asyncIterator] === 'function';
  }
}
