import { Subscription } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  UploadProgressFile,
  SendMessageRequest,
} from '../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-chat-box',
  templateUrl: './messenger-chat-box.component.html',
  styleUrls: [
    './messenger-chat-box.component.css',
    '../messenger.component.css',
  ],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(200, style({ transform: 'translateX(-150px)', opacity: 0 })),
      ]),
      transition('void => *', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class MessengerChatBoxComponent implements OnInit, OnDestroy {
  // Outputs
  @Output() MessageUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Output() UploadingUpdate: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() SendClick: EventEmitter<number[]> = new EventEmitter<number[]>();

  // Inputs
  @Input() resetUploader: EventEmitter<any> = new EventEmitter<any>();
  @Input() removeFile: EventEmitter<any> = new EventEmitter<any>();

  @Input() clearMessage: EventEmitter<any> = new EventEmitter<any>();
  clearMessageSubscription: Subscription;
  // Input Var
  @Input() disableSend: boolean = false;

  uploadingFiles: UploadProgressFile[] = [];

  message: SendMessageRequest = {
    text: '',
    mediaIds: [],
  };

  constructor() {}
  ngOnDestroy(): void {
    this.clearMessageSubscription.unsubscribe();
  }

  ngOnInit() {
    this.clearMessageSubscription = this.clearMessage.subscribe((event) => {
      this.clearInternalMessage();
    });
  }

  clearInternalMessage() {
    this.message.text = '';
    this.message.mediaIds = [];
  }

  onUploadingFilesChange(files: UploadProgressFile[]) {
    this.uploadingFiles = files;
  }

  onMessageUpdate(message: string) {
    this.message.text = message;
    this.MessageUpdate.emit(message);
  }

  onUploadingUpdate(mediaIds: number[]) {
    this.message.mediaIds = mediaIds;
    this.UploadingUpdate.emit(mediaIds);
  }

  onRemoveClick(id: number) {
    this.removeFile.emit(id);
  }

  onSendClick() {
    this.SendClick.emit();
  }
  async onKeydown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.disableSend) {
        this.onSendClick();
      }

      return false;
    } else {
    }
  }

  // UI Functions
  autoGrowTextZone(e) {
    e.target.style.height = '50px';
    e.target.style.height =
      e.target.scrollHeight > 50 ? e.target.scrollHeight + 10 + 'px' : '50px';
  }
}
