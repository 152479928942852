
import { createAction, props } from '@ngrx/store';
import { AppwriteException, Models } from 'appwrite';
import { User } from 'src/app/models';


export const initializeState = createAction(
  '[Auth] Initialize State and Check Session'
);


export const loginWithEmailAndPassword = createAction(
    '[Auth] User Login',
    props<{ email: string, password: string, fromRegister: boolean }>()
  );

  // export const loginWithMagicLink = createAction(
  //   '[Auth] User Login',
  //   props<{ email: string}>()
  // );
  
  export const generateUserToken = createAction(
    '[Auth] Login Generate Current User Token'
  );

  export const refreshUserToken = createAction(
    '[Auth] Login Refresh Current User Token',
  );

  export const lockTokenRefreshSchedule = createAction(
    '[Auth] Login Refresh Current User Token',
    props<{ tokenTimeout?: number }>()
  );

  export const unlockTokenRefreshSchedule = createAction(
    '[Auth] Login Refresh Current User Token'
  );

  export const getCurrentUserLearmoProfile = createAction(
    '[Auth] Login Get Current User Learmo Profile',
    props<{ redirect: boolean }>()
  );

  export const getCurrentUserAppwriteProfile = createAction(
    '[Auth] Login Get Current User Appwrite Profile',
  );
  
  export const setUserToken = createAction(
    '[Auth] Login Set Current User Token',
    props<{ token: string }>()
  );

  export const CheckWebHook = createAction(
    '[Auth] Login After Webhook Done'
  );

  export const onWebHookDone = createAction(
    '[Auth] Webhook triggered successfully'
  );

  export const setCurrentUserLearmoProfile = createAction(
    '[Auth] Login Set Current User Learmo Profile',
    props<{ learmoProfile: User }>()
  );
  
  export const setCurrentUserAppwriteProfile = createAction(
    '[Auth] Login Set Current User Appwrite Profile',
    props<{ appwriteProfile: Models.User<Models.Preferences> }>()
  );


  export const loginSuccess = createAction(
    '[Auth] Login Success'
  );



  
  export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: AppwriteException }>()
  );
  
  // export const updateProfile = createAction(
  //   '[User] Update Profile',
  //   props<{ profile: Partial<User> }>()
  // );

  
  export const markWebsiteOnboardingAsDone = createAction('[Auth] Mark Website Onboarding As Done');
export const markWebsiteOnboardingAsDoneSuccess = createAction('[Auth] Mark Website Onboarding As Done Success', props<{ resp: any }>());

export const forgetSession = createAction('[Auth] Forgot', props<{ redirect?: boolean }>());

