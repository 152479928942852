// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unlinked-dynamic-label::after {
  content: "Unlinked";
}

.unlinked-dynamic-label:hover::after {
  content: "Link to Portal";
}

.grid-3-cols {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media (max-width: 1100px) {
  .grid-3-cols {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 920px) {
  .grid-3-cols {
    display: inline-grid;
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/domains/domain-item/domain-details-card/domain-details-card.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,kCAAkC;EAClC,SAAS;AACX;;AAEA;EACE;IACE,oBAAoB;IACpB,8BAA8B;IAC9B,SAAS;EACX;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,0BAA0B;EAC5B;AACF","sourcesContent":[".unlinked-dynamic-label::after {\n  content: \"Unlinked\";\n}\n\n.unlinked-dynamic-label:hover::after {\n  content: \"Link to Portal\";\n}\n\n.grid-3-cols {\n  display: inline-grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 20px;\n}\n\n@media (max-width: 1100px) {\n  .grid-3-cols {\n    display: inline-grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n  }\n}\n\n@media (max-width: 920px) {\n  .grid-3-cols {\n    display: inline-grid;\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
