import { Directive, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Directive({
  selector: 'textarea[autoresize]',
})
export class TextareaAutoresizeDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    // Use setTimeout to introduce a slight delay and wait for the content to be rendered
    setTimeout(() => this.adjust(), 0);
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  private adjust(): void {
    const nativeElement = this.el.nativeElement as HTMLTextAreaElement;
    nativeElement.style.overflow = 'hidden';
    nativeElement.style.height = 'auto';
    nativeElement.style.height = nativeElement.scrollHeight + 'px';
  }
}
