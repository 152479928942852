import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { SharedPortalViewService } from '../../shared-portal-view.service';
import { ActivatedRoute } from '@angular/router';
import {
  PaymentGatewayModel,
  Payment_Gateway_Enum,
} from 'src/app/models/portal';
import { ApiService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectWebsitePaymentGatewayByEnum } from 'src/app/shared/store/_features/website/payments/website-payments.selector';

@Component({
  selector: 'app-payment-integrate',
  templateUrl: './integrate.component.html',
  styleUrls: ['./integrate.component.css'],
})
export class IntegratePortalPaymentsComponent implements OnInit, AfterViewInit {
  processing: boolean = false;
  currentPaymentGateway: PaymentGatewayModel;
  @Input() gateway: Payment_Gateway_Enum;
  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    public aroute: ActivatedRoute,
    private title: Title,
    private api: ApiService,
    private store: Store
  ) {
    this.sharedPortalViewService.paymentPageStatus = 'integrate';
    const sub = this.aroute.params.subscribe(async (params) => {
      const slug = params['slug'];
      if (!!slug) {
      console.log("Payment SLUG: ", slug )
      this.gateway = slug;
      }
      
      
    });
  }
  ngAfterViewInit(): void {
    console.log("This Gateway: ", this.gateway)
    if (!!this.gateway) {
      this.store.select(selectWebsitePaymentGatewayByEnum(this.gateway)).subscribe(value => {
        this.currentPaymentGateway = value;
        console.log(this.currentPaymentGateway);
      })
    }
  }


  
  ngOnInit(): void {
  


    
    
    
    // this.title.setTitle(
    //   this.sharedPortalViewService.currentWebsite.website.label +
    //     ' > ' +
    //     this.sharedPortalViewService.currentPaymentGateway?.name
    // );
  }
  
  onStartLoading() {
    this.processing = true;
  }

  onError() {
    this.processing = false;
  }

  onDone() {
    setTimeout(() => {
      this.processing = false;  
    }, 500);
  }

  onIntegrationDone(methodSlug: Payment_Gateway_Enum) {
    // this.sharedPortalViewService.viewPayment(methodSlug);

  }


  // async setAndIntegratePaymentGateway(slug: Payment_Gateway_Enum) {

  //   // const selectedGateway = this.sharedPortalViewService.paymentGateways.find((a) => a.slug.toLowerCase() === slug.toLowerCase());
  //   // this.sharedPortalViewService.currentPaymentGateway = selectedGateway;
    
  //   // if (!!selectedGateway?.integrated) {
  //   //   // this.sharedPortalViewService.viewPayment(slug);
  //   // }

  //   this.loaded = true;
  // }
}
