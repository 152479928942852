import { Component, Input, OnInit } from '@angular/core';
import { StudentItem } from 'src/app/components/user-dashboard/product/models/course';
import { ApiService } from 'src/app/shared/services';


export enum AffiliateNoteStatusEnum {
  done = 'done',
  saving = 'saving',
  editing = 'editing',
  no_changes = 'no_changes',
  none = 'none',
}
@Component({
  selector: 'app-student-profile-card',
  templateUrl: './student-profile-card.component.html',
  styleUrls: ['./student-profile-card.component.css'],
})
export class StudentProfileCardComponent implements OnInit {

  affiliateNoteStatusEnum = AffiliateNoteStatusEnum;


  @Input() student: StudentItem;
  // @Input() onlyCard: boolean = false;
  // affiliateNote: string = '';
  // affiliateNoteStatus: AffiliateNoteStatusEnum = AffiliateNoteStatusEnum.none;
  constructor(
    private api: ApiService
  ) {}

  ngOnInit(): void {}







}
