

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { mergeMap, map, catchError, of, tap } from "rxjs";
import { BlogPostItem } from "src/app/models/blog.model";
import { ApiService } from "src/app/shared/services";
import { WebsiteBlogListActions, WebsiteBlogCreateActions, deleteWebsiteBlogPostActions, updateWebsiteBlogPostActions, updatePostStatusActions } from "./website-blog.actions";





@Injectable()
export class WebsiteBlogEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,

  ) { }


  loadWebsiteBlogPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebsiteBlogListActions.load),
      mergeMap(() =>
        this.api.getWebsiteBlog()
          .pipe(
            map((response: BlogPostItem[]) => {
              console.log(response);
              return WebsiteBlogListActions.loadSuccess({ posts: [...response] })
            }
            ),
            catchError((error) =>
              of(WebsiteBlogListActions.loadFailure({ error }))
            )
          )
      )
    )
  );

  createWebsiteBlogPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebsiteBlogCreateActions.create),
      mergeMap(({payload}) =>
        this.api.createWebsiteBlogPost(payload)
          .pipe(
            map((response: BlogPostItem) => {
              console.log(response);

              return WebsiteBlogCreateActions.loadSuccess({ post: response })
            }
            ),
            catchError((error) =>
              of(WebsiteBlogCreateActions.loadFailure({ error }))
            )
          )
      )
    )
  );


  deleteWebsiteBlogPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWebsiteBlogPostActions.delete),

      mergeMap(({ postId }) =>
        this.api.deleteWebsiteBlogPost(postId).pipe(
          map(() => {
            return deleteWebsiteBlogPostActions.deleteSuccess({ postId });
          }),
          catchError((error) => {
            return of(deleteWebsiteBlogPostActions.deleteFailure({ postId, error }));
          })
        )
      )
    )
  );



    updatePost$ = createEffect(() =>
      this.actions$.pipe(
        ofType(updateWebsiteBlogPostActions.update),
        mergeMap(({postId, changes}) =>
          this.api.updateWebsiteBlogPost(postId, changes ).pipe(
            map((response:BlogPostItem) => updateWebsiteBlogPostActions.updateSuccess({ post: response  })),
            catchError((error) => of(updateWebsiteBlogPostActions.updateFailure({ error }))
          )
        )
      )
      ));


      updatePostStatus$ = createEffect(() =>
        this.actions$.pipe(
          ofType(updatePostStatusActions.update),
          mergeMap(({ postId, status }) =>
            this.api.updatePostStatus(postId, status).pipe(
              map((response: BlogPostItem) =>
                updateWebsiteBlogPostActions.updateSuccess({ post: response })
              ),
              catchError((error) =>
                of(updateWebsiteBlogPostActions.updateFailure({ error }))
              )
            )
          )
        )
      );

    }
