import { FormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedPortalViewService } from 'src/app/components/user-dashboard/portal/view/shared-portal-view.service';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { WebsiteModel } from 'src/app/components/user-dashboard/portal/portal.model';
import { Store } from '@ngrx/store';
import { selectWebsite } from '../../store/_features/website/website.selector';

@Component({
  selector: 'app-go-to-portal',
  templateUrl: './go-to-portal.component.html',
  styleUrls: ['./go-to-portal.component.css'],
})
export class GoToPortalComponent implements OnInit, OnDestroy {
  website: WebsiteModel;
  @Input() auth: 'demo' | 'admin' | 'visitor' = 'admin';
  @Input() _domain: string = '';

  @Input() redirectPath: string = '';
  _redirectUrl: string = '';

  @Input() username: string = '';
  @Input() token: string = 'NHJjMzZhNVdBWmpVR1kyQXhJbGh1dz09';

  _finalUrl: string;

  @Input() auto: boolean = true;

  subscriptions: Subscription[] = [];
  constructor(private store: Store) {}

  @Input('domain') set domainSetter(domain) {
    this._domain = domain;
    this.generateUrl();
  }

  @Input('redirectUrl') set redirectUrlSetter(redirectUrl) {
    this._redirectUrl = redirectUrl;
    this.generateUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  async ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectWebsite).subscribe((value) => {
        if (!!value) {
          this.website = value;
          this._domain = value.siteUrl;
          this.generateUrl();
          switch (this.auth) {
            case 'admin':
              this.username = value.websiteCredentials.username;
              this.token = value.websiteCredentials.token;
              break;
            case 'demo':
              this.username = value.demoCredentials.username;
              this.token = value.demoCredentials.token;
              break;
            case 'visitor':
              this.username = 'visitor';
              this.token = null;
              break;
            default:
              this.username = value.websiteCredentials.username;
              this.token = value.websiteCredentials.token;
              break;
          }
        }
        if (!!this.auto) {
          //  this.subscriptions.push(
          //   // this.website$.subscribe((website) => {
          //   //   if (website) {
          //   //     this.username = website.websiteCredentials.username;
          //   //     this._domain = website.siteUrl;
          //   //     this.token = website.websiteCredentials.token;
          //   //   }
          //   // })
          //  )

          this.generateUrl();
        }
      })
    );
  }

  generateUrl() {
    const baseUrl = `https://${this._domain}/?redme=`;
    let redirectUrl = this.encodeRedirect(this._redirectUrl);
    if (!!this.redirectPath.length) {
      redirectUrl = this.encodeRedirect(
        `https://${this._domain}/${this.redirectPath}`
      );
    }
    this._finalUrl = baseUrl + redirectUrl;
  }
  // onSubmit() {
  //   const myform = document.getElementById(
  //     `myCheckWebsiteForm${this.uid}`
  //   ) as HTMLFormElement;
  //   myform.submit();
  // }

  encodeRedirect(url) {
    return encodeURIComponent(url);
  }
}
