import { selectNotifications, selectNotificationsState, selectUnreadNotificationsCount } from './../store/_features/notifications/notifications.selector';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import {
  of,
  delay,
  interval,
  mergeMap,
  Subscription,
  lastValueFrom,
} from 'rxjs';
import { Observable } from 'rxjs';
import { ApiService } from './lsg.api.service';
import { AffiliateApi } from './affiliate.api.service';

import {
  Messages_Event_Type_Enum,
  NotificationElement,
  NotificationsResponseModel,
} from 'src/app/models/owner-dashboard';
import { textChangeRangeIsUnchanged } from 'typescript';
import { IndividualConfig, ToastrService, ActiveToast } from 'ngx-toastr';
import { NotificationToastComponent } from './notification-toast/notification-toast.component';
import { SharedAffiliateDashboardService } from '../layout/affiliate-dashboard/shared-affiliate-dashboard.service';
import { PortalSocketService } from './portal-socket.service';
import { MessengerThread } from '../../models/owner-dashboard';
import { SharedMessengerService } from '../partials/messenger/shared-messenger.service';
import { MessageToastComponent } from './message-toast/message-toast.component';
import { AnnouncementToastComponent } from './announcement-toast/announcement-toast.component';
import { SharedAffiliateUserDashboardService } from '../../components/affiliate-dashboard/shared-affiliate-user-dashboard.service';
import { UserDashboardService } from './user-dashboard.service';
import { Store } from '@ngrx/store';
import {  NotificationsActions, newNotifications } from '../store/_features/notifications/notifications.actions';

export enum NotificationUserTypeEnum {
  affiliate = 'affiliate',
  owner = 'owner',
}
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  @Output() onNewOwnerNotification = new EventEmitter<string>();
  @Output() onNewAffiliateNotification = new EventEmitter<string>();

  notificationSound: HTMLAudioElement = new Audio('assets/notification.mp3');
  seenSound: HTMLAudioElement = new Audio('assets/seen.mp3');
  // initiated: {owner: boolean, affiliate: boolean} = {affiliate: false, owner: false};

  // messagesList: { messages: MessengerThread[]; unreadMessagesCount: number } = {
  //   messages: [],
  //   unreadMessagesCount: 0,
  // };

  ownerNotifications: NotificationsResponseModel = {
    notifications: [],
    unreadInvitaionsCount: 0,
    unreadNotificationsCount: 0,
    unreadMessagesCount: 0,
  };

  affiliateNotifications: NotificationsResponseModel = {
    unreadInvitaionsCount: 0,
    unreadNotificationsCount: 0,
    unreadAnnouncementsCount: 0,
    unreadMessagesCount: 0,
    announcements: [],
    invitations: [],
    notifications: [],
  };

  messengerToastConfig = {
    toastComponent: MessageToastComponent,
    tapToDismiss: false,
    toastClass: 'ip-custom-toast',
    timeOut: 4200,
    extendedTimeOut: 1100,
    // timeOut: 0,
    // extendedTimeOut: 0,
  };

  notificationToastConfig = {
    toastComponent: NotificationToastComponent,
    tapToDismiss: false,
    toastClass: 'ip-custom-toast',
    timeOut: 4200,
    extendedTimeOut: 1100,
  };

  announcementToastConfig = {
    toastComponent: AnnouncementToastComponent,
    tapToDismiss: false,
    toastClass: 'ip-custom-toast',
    timeOut: 4200,
    extendedTimeOut: 1100,
  };

  ownerNotificationsSubscription: Subscription;
  affiliateNotificationsSubscription: Subscription;

  messengerSubscription: Subscription;

  loaded: {
    notifications: boolean;
    announcements: boolean;
    messages: boolean;
  } = {
    announcements: false,
    notifications: false,
    messages: false,
  };

  notifications$;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private api: ApiService,
    private affiliateApi: AffiliateApi,
    private toastr: ToastrService,
    private sharedAffiliateDashboardService: SharedAffiliateDashboardService,
    private sharedAffiliateUserDashboardService: SharedAffiliateUserDashboardService,
    private portalSocketService: PortalSocketService,
    public sharedMessengerService: SharedMessengerService, //private messengerApi: MessengerApi
    public userDashboardService: UserDashboardService,
    private store: Store
  ) {
    this.notificationErrorHandler = this.notificationErrorHandler.bind(this);
    this.store.select(selectNotifications).subscribe(value=> {
      this.ownerNotifications.notifications = value;
    })

    this.store.select(selectUnreadNotificationsCount).subscribe(value => {
      this.ownerNotifications.unreadNotificationsCount = value;
    })

    this.startOwnerNotificationsListening();
    this.startMessengerListening();
    this.portalSocketService.onSocketSetupDone.subscribe(value=> {
      this.startOwnerNotificationsListening();
      this.startMessengerListening();
    })

  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  playSeenSound() {
    this.seenSound.play();
  }

  async getLatestOwnerNotifications() {
    let ownerNotifications$ = this.api.getOwnerNotifications(
      true,
      this.notificationErrorHandler
    );
    return await lastValueFrom(ownerNotifications$);
  }

  async getLatestAffiliateNotifications() {
    let affiliateNotifications$ = this.affiliateApi.getAffiliateNotifications(
      true,
      this.notificationErrorHandler
    );
    return await lastValueFrom(affiliateNotifications$);
  }

  notificationErrorHandler() {}

  async getAffiliateNotificationsList() {
    this.loaded.notifications = false;
    this.loaded.announcements = false;
    let affiliateNotifications$ = this.affiliateApi.getAffiliateNotifications(
      false,
      this.notificationErrorHandler
    );
    let affiliateNotifications: NotificationsResponseModel =
      await lastValueFrom(affiliateNotifications$);
    this.affiliateNotifications = affiliateNotifications;
    this.loaded.announcements = true;
    this.loaded.notifications = true;
  }

  async getOwnerNotificationsList() {
    this.store.dispatch(NotificationsActions.load());
  }

  startMessengerListening() {

    this.messengerSubscription =
      this.portalSocketService.messengerListener.subscribe(
        (data: MessengerThread) => {
          if (data.type == this.userDashboardService.dashboardSessionMode) {
            if (data.eventType == Messages_Event_Type_Enum.newMessage) {
              this.handleNewMessage(data);
              // this.sharedMessengerService.messengerData.threads
              //   .unreadMessagesCount++;
            } else if (data.eventType == Messages_Event_Type_Enum.seenUpdate) {
              this.handleNewSeen(data);
            }
          } else {
            this.userDashboardService.enableOtherSideNotification();
          }
        }
      );
  }

  plusMessageByNetworkId(networkId: number) {
    let networkIndex =
      this.sharedAffiliateDashboardService.myOwnersList.joinedNetworks.findIndex(
        (a) => a.id == networkId
      );

    if (networkIndex == 0 || (!!networkIndex && networkIndex != -1)) {
      this.sharedAffiliateDashboardService.myOwnersList.joinedNetworks[
        networkIndex
      ].unreadMessagesCount++;
    }
  }

  handleNewMessage(data: MessengerThread) {
    // this.messagesList.unreadMessagesCount = 1;
    // TODO: Messenger Messages Count
    this.sharedMessengerService.handleNewMessage(data);
    if (
      data.id != this.sharedMessengerService.messengerData?.currentThread?.id
    ) {
      this.plusMessageByNetworkId(data.users[0].id);
      // this.sharedMessengerService.messengerData.threads.unreadMessagesCount++;
      // this.sharedMessengerService.handleNewMessage(data);
      this.playNotificationSound();
      this.triggerMessengerNotification(data);
    }
  }

  handleNewSeen(data: MessengerThread) {
    // this.messagesList.unreadMessagesCount = 1;
    // TODO: Messenger Messages Count
    // if (
    //   data.id == this.sharedMessengerService.messengerData?.currentThread?.id
    // ) {

    // } else {

    // }

    this.sharedMessengerService.handleNewSeen(data);
    // this.sharedMessengerService.messengerData.threads.unreadMessagesCount++;
    this.playSeenSound();
  }

  triggerMessengerNotification(thread: MessengerThread) {
    const messengerToast: ActiveToast<any> = this.toastr.show(
      'Hello',
      'Koko',
      this.messengerToastConfig
    );
    messengerToast.toastRef.componentInstance.thread = thread;
  }

  startOwnerNotificationsListening() {

    this.messengerSubscription =
    this.portalSocketService.notificationsListener.subscribe(
      (notification: NotificationElement) => {
        this.store.dispatch(newNotifications({notification}));
        // this.handleNewOwnerNotifications(notification)
        this.playNotificationSound();
        this.triggerNotification(notification);
      }
    );

    // let ticker = interval(8 * 1000);

    // this.ownerNotificationsSubscription = ticker
    //   .pipe(mergeMap(async () => await this.getLatestOwnerNotifications()))
    //   .subscribe((data: NotificationsResponseModel) =>
    //     this.handleNewOwnerNotifications(data)
    //   );
  }

  endOwnerNotificationsListening() {
    this.ownerNotificationsSubscription?.unsubscribe();
  }

  endMessengerListening() {
    this.messengerSubscription?.unsubscribe();
  }

  triggerNotification(notificationObject: NotificationElement) {
    const notif: ActiveToast<any> = this.toastr.show(
      'Hello',
      'Koko',
      this.notificationToastConfig
    );
    notif.toastRef.componentInstance.notificationObject = notificationObject;
  }

  triggerAnnouncement(announcement: NotificationElement) {
    const notif: ActiveToast<any> = this.toastr.show(
      'Hello',
      'Koko',
      this.announcementToastConfig
    );
    notif.toastRef.componentInstance.announcement = announcement;
  }

  startAffiliateNotificationsListening() {
    let ticker = interval(8 * 1000);

    this.affiliateNotificationsSubscription = ticker
      .pipe(mergeMap(async () => await this.getLatestAffiliateNotifications()))
      .subscribe((data: NotificationsResponseModel) =>
        this.handleNewAffiliateNotifications(data)
      );
  }

  // handleNewOwnerNotifications(data: NotificationElement) {
  //   this.ownerNotifications.unreadNotificationsCount =
  //     data.unreadNotificationsCount;
  //   this.ownerNotifications.unreadMessagesCount = data.unreadMessagesCount;
  //   if (!!data.notifications?.length) {
  //     data.notifications.forEach((notification) => {
  //       notification.notificationUserType = NotificationUserTypeEnum.owner;
  //       this.ownerNotifications.notifications.unshift(notification);
  //       this.playNotificationSound();
  //       this.triggerNotification(notification);
  //     });
  //   }
  // }
  handleNewAffiliateNotifications(data: NotificationsResponseModel) {
    this.affiliateNotifications.unreadInvitaionsCount =
      data.unreadInvitaionsCount;
    this.affiliateNotifications.unreadNotificationsCount =
      data.unreadNotificationsCount;
    this.affiliateNotifications.unreadAnnouncementsCount =
      data.unreadAnnouncementsCount;
    this.affiliateNotifications.unreadMessagesCount = data.unreadMessagesCount;

    if (!!data.invitations?.length) {
      data.invitations.forEach((invitation) => {
        invitation.notificationUserType = NotificationUserTypeEnum.affiliate;
        this.affiliateNotifications.invitations.unshift(invitation);
        this.playNotificationSound();
        this.triggerNotification(invitation);
        this.sharedAffiliateDashboardService.reloadMyOwnersList();
      });
    }
    if (!!data.notifications?.length) {
      data.notifications.forEach((notification) => {
        notification.notificationUserType = NotificationUserTypeEnum.affiliate;
        this.affiliateNotifications.notifications.unshift(notification);
        this.playNotificationSound();
        this.triggerNotification(notification);
      });
    }

    if (!!data.announcements?.length) {
      data.announcements.forEach((announcement) => {
        announcement.notificationUserType = NotificationUserTypeEnum.affiliate;
        this.affiliateNotifications.announcements.unshift(announcement);
        this.playNotificationSound();
        this.triggerAnnouncement(announcement);
      });
    }
  }

  endAffiliateNotificationsListening() {
    this.affiliateNotificationsSubscription.unsubscribe();
  }
}
