import { DomainCheckData } from './../../../../../../models/domain';
import { PaymentServiceInterface } from './../../../../../../models/payment.model';

import { ApiService } from './../../../../../../shared/services/lsg.api.service';
import {
  InOutAnimation,
  cardAnimation,
} from './../../../../../../shared/animations/animations';
import { SharedDomainService } from './../../../shared-domain.service';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { domainRegex } from '../../../../../../shared/services/regex';
import { PaymentServiceInterfaceTypeEnum } from 'src/app/models/payment.model';
import { PaymentProcessType } from 'src/app/components/register/register.component';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';

export enum CheckPageViewStatusEnum {
  input = 'input',
  available = 'available',
  unavailable = 'unavailable',
}

export enum DomainCheckStatusEnum {
  none = 'none',
  processing = 'processing',
}

export enum CardModeStatusEnum {
  old = 'old',
  new = 'new',
}

@Component({
  selector: 'app-domain-purchase-summary',
  templateUrl: './domain-purchase-summary.component.html',
  styleUrls: ['./domain-purchase-summary.component.css'],
  animations: [InOutAnimation, cardAnimation],
})
export class DomainPurchaseSummaryComponent implements OnInit, OnDestroy {
  btcPayTerms = [
    {
      text: 'The money back guarantee trial shall last for a period of 14 days only. After the 14 day trial, the account will no longer be eligible for a refund.',
    },
    {
      text: 'Customers signing up for the trial using the BTC+Alt Coins method understand they are responsible to submit their payments in a timely manner to avoid the suspension of the service,',
    },
    {
      text: "Customers not wishing to continue the Service once the money back guarantee trial ends must cancel the Service using the Cancel Account Form located in the User's Account Settings before the trial period ends and provide the required information to viabilice the refund. ",
    },
    {
      text: 'Offer applies to new customers only who select the 14 Day Money back guarantee Trial option when singing up to a service plan.',
    },
    {
      text: 'Offer might not be available for customers in all regions and countries.',
    },
    {
      text: 'Notice will be sent if payment fails for any reason. It is the customers responsibility to make sure payment details are correct to avoid suspension of service.',
    },
    {
      text: 'learmo reserves the right to remove or cancel the trial offer at any time.',
    },
    {
      text: 'learmo reserves the right to change the features of the trial offer at any time.',
    },
    { text: 'learmo General Terms and Conditions apply.' },
    {
      text: 'learmo reviews all orders and reserves the right to deny or cancel service at any time and for any reason.',
    },
  ];
  payEmit: EventEmitter<any> = new EventEmitter();

  checkPageViewStatusEnum = CheckPageViewStatusEnum;
  domainCheckStatusEnum = DomainCheckStatusEnum;
  paymentServiceInterfaceTypeEnum = PaymentServiceInterfaceTypeEnum;
  cardModeStatusEnum = CardModeStatusEnum;

  //Payment Section
  paymentErrorMessage: string;
  paymentProcessing: boolean = false;

  checkPageViewStatus: CheckPageViewStatusEnum = CheckPageViewStatusEnum.input;

  formSubscription: Subscription;
  checkDomainForm = new FormGroup({
    name: new FormControl<string>(null, {
      validators: [Validators.required, Validators.pattern(domainRegex)],
      updateOn: 'change',
    }),
    tld: new FormControl('com', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  domainCheckResult: DomainCheckData;

  loaded: boolean = false;
  domainCheckStatus: DomainCheckStatusEnum = DomainCheckStatusEnum.none;

  constructor(
    public sharedDomainService: SharedDomainService,
    public progressSpinnerService: ProgressSpinnerService,
    private api: ApiService
  ) {
    this.sharedDomainService.initializePaymentMethods();
  }
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.loaded = true;
  }

  // Payment Section

  addCard() {
    this.payEmit.emit({
      bundleID: undefined,
      paymentProcess: PaymentProcessType.save_only,
    });
  }

  resetPaymentService() {
    this.sharedDomainService.wizardData.paymentService = null;
    this.sharedDomainService.wizardData.selectedCard = null;
    this.sharedDomainService.hideNextButton();
  }

  async selectPaymentService(service: PaymentServiceInterface) {
    if (
      !!service.active &&
      service.enum != this.sharedDomainService?.wizardData?.paymentService?.enum
    ) {
      switch (service.enum) {
        case PaymentServiceInterfaceTypeEnum.CreditCard:
          await this.sharedDomainService.getAllPaymentMethodsForUser();

          break;
        case PaymentServiceInterfaceTypeEnum.BitCoin:
          this.sharedDomainService.showNextButton();
          break;

        default:
          break;
      }
      this.sharedDomainService.wizardData.paymentService = service;
      this.sharedDomainService.scroll('paymentAction', 'start');
    }
  }
  async setPaymentErrorMessage(errorMessage: string) {
    this.paymentErrorMessage = errorMessage;
  }

  startPaymentProcessing() {
    this.progressSpinnerService.showLoader('Payment Processing');
  }
  async endPaymentProcessing() {
    await this.sharedDomainService.getAllPaymentMethodsForUser();
    this.sharedDomainService.switchCardMode(CardModeStatusEnum.old);
    this.progressSpinnerService.hideLoader();
  }

  startProcessing() {
    this.domainCheckStatus = DomainCheckStatusEnum.processing;
  }

  endProcessing() {
    this.domainCheckStatus = DomainCheckStatusEnum.none;
  }

  async goNext() {
    // this.createPortalForm.markAllAsTouched();
    // if (
    //   !!this.createPortalForm.valid ||
    //   this.sharedPortalService.wizardSettings.portalType ==
    //     PortalActiveChoiceEnum.existing
    // ) {
    //   this.sharedPortalService?.wizardModel?.goToNextStep();
    // }
  }
}
