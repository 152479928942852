import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ProductModel } from 'src/app/components/user-dashboard/product/models/product';
import { ApiService } from 'src/app/shared/services';
import { Store } from '@ngrx/store';
import { selectOffers } from 'src/app/shared/store/_features/offers/offers.selector';
import { offersActions } from 'src/app/shared/store/_features/offers/offers.actions';

@Component({
  selector: 'app-offer-selector',
  templateUrl: './offer-selector.component.html',
  styleUrls: ['./offer-selector.component.css'],
})
export class OfferSelectorComponent implements OnInit {
  @Input() offersList: OfferItem[]; // Input property to pass the list of products
  @Input() placeholder: string = "Select an Offer"; // Input property to pass the list of products
  @Input() formtext: string;
  _formControl: FormControl = new FormControl('', [Validators.required]); // Input property to pass the form control
  @Output() valueChange = new EventEmitter<OfferItem>(); // Output property for form control changes
  constructor(private api: ApiService, private store: Store) {
    this._formControl.valueChanges.subscribe((value) => {
      let offer = this.offersList.find((offer) => offer.id == value);
      this.valueChange.emit(offer);
    });
  }

  @Input('customFormControl') set formControlValue(value: any) {
    this._formControl = value;
    this._formControl.valueChanges.subscribe((value) => {
      let offer = this.offersList.find((offer) => offer.id == value);
      this.valueChange.emit(offer);
    });
  }

  async ngOnInit() {
    if (!this.offersList.length) {
      this.store.select(selectOffers).subscribe((value: OfferItem[])=> {
        if (!!value?.length) {
          this.offersList = value;
        }
      })

    this.getOffers();
  }
  else {
    this.selectDefaultOffer()
  }


  }

  selectDefaultOffer() {
    if (this.offersList?.length == 1) {
      this._formControl.setValue(this.offersList[0].id);
      // this.valueChange.emit(this.offersList[0])
    }
    else {
      let defaultOffer = this.offersList.find((offer) => !!offer.isDefault);
      if (defaultOffer) {
      this._formControl.setValue(defaultOffer.id);
      }
    }
  }



  async getOffers() {
    this.store.dispatch(offersActions.load());

  }

}
