import { EventEmitter, Injectable, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom, merge, takeWhile } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal from 'sweetalert2';
import {
  MediaLibraryFileModel,
  DeleteResponse,
  OfferDeleteResponse,
  EditCheckoutPageModel,
  CheckoutTestimonials,
  CheckoutExtraInfo,
  UpdateEditCheckoutResponseModel,
  CurrenciesModel,
  CheckoutAppearances,
  CheckoutContent,
  CheckoutBanner,
} from '../../../models/sales-funnel';
import { MinimizedPortalModel } from '../portal/dto/portal.output';

import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import {
  OfferEditPayload,
  OfferEditResponse,
  OfferItem,
  OfferStausEnum,
  Payment_Status_Enum,
} from './models/offer';
import { ProductItem } from '../product/models/product';

import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';
import {
  offersActions,
  cloneOfferActions,
  deleteOfferActions,
  currentOfferActions,
  updateOfferStatusActions,
   updateOfferActions,
} from 'src/app/shared/store/_features/offers/offers.actions';
import { Store } from '@ngrx/store';
import { DynamicActionButtonItem } from 'src/app/shared/partials/dynamic-preview-buttons/dynamic-action-buttons.component';
import { selectCurrentOffer, selectOfferById, selectOffersState } from 'src/app/shared/store/_features/offers/offers.selector';
import { productOfferActions } from 'src/app/shared/store/_features/products/products.actions';
import { selectProductOfferById } from 'src/app/shared/store/_features/products/products.selector';

@Injectable({
  providedIn: 'root',
})
export class SharedOfferService {
  checkoutSaveEmitter = new EventEmitter<any>();

  offerPageNavigations: TopLevelNavMenuItem[] = [];
  previewButtons: DynamicActionButtonItem[] = [];
  checkoutHeadButtons: DynamicActionButtonItem[] = [];

  // currentMenuTab: EditCheckoutMenuEnum = EditCheckoutMenuEnum.loading;
  scrollTo: string = null;
  _scrollTo: Subject<string> = new Subject();
  saveEditCheckoutStatus: 'error' | 'loading' | 'none' | 'done' = 'none';

  timeoutReference: ReturnType<typeof setTimeout>;

  iframeEmitter: EventEmitter<any> = new EventEmitter<any>();

  currentOffer: OfferItem;
  // currentOfferUrl: string = '';
  userPortals: MinimizedPortalModel[] = [];
  currencies: CurrenciesModel[] = [];
  status: {
    payment: 'error' | 'edited' | 'none';
    settings: 'error' | 'edited' | 'none';
    offer: 'error' | 'edited' | 'none';
    information: 'error' | 'edited' | 'none';
    products: 'error' | 'edited' | 'none';
  } = {
    payment: 'none',
    settings: 'none',
    offer: 'none',
    information: 'none',
    products: 'none',
  };

  uiCheckboxes: {
    downloadExpiry: boolean;
    allowedDownloadTimes: boolean;
    expireAt: boolean;
    stock: boolean;
  } = {
    downloadExpiry: false,
    allowedDownloadTimes: false,
    expireAt: false,
    stock: false,
  };

  paymentGateways: {
    btcPay: boolean;
    payPal: boolean;
    stripe: boolean;
  } = {
    btcPay: null,
    payPal: null,
    stripe: null,
  };

  errorMsg: {
    payment: string;
    settings: string;
    offer: string;
    information: string;
    products: string;
  } = {
    payment: '',
    settings: '',
    offer: '',
    information: '',
    products: '',
  };

  loading: {
    currencies: boolean;
    portals: boolean;
    products: boolean;
    settingsModal: boolean;
    getLink: boolean;
  } = {
    currencies: true,
    portals: true,
    products: true,
    settingsModal: true,
    getLink: true,
  };

  paymentTypes: { name: string; displayName: string }[] = [
    { name: 'free', displayName: 'Free' },
    { name: 'one_time', displayName: 'One-time payment' },
  ];
  offerEditPayload: OfferItem;

  checkout: EditCheckoutPageModel = new EditCheckoutPageModel();
  checkoutEditPayload: EditCheckoutPageModel = new EditCheckoutPageModel();
  products: ProductItem[] = [];
  offers: OfferItem[] = [];
  offerContentTemp: MediaLibraryFileModel[] | number[] = [];
  loaded = false;
  processing: {
    add: 'loading' | 'done' | 'none' | 'error' | 'no-change' | 'success';
    secondary: 'loading' | 'done' | 'none' | 'error' | 'no-change' | 'success';
  } = { add: 'none', secondary: 'none' };
  currentOfferId: number = null;
  deleting: boolean = false;

  pageStatus: 'edit' | 'view' | 'edit-checkout';

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private sharedDashboardService: SharedOwnerDashboardService,
    private store: Store
  ) {
    this.getOfferErrorHandler = this.getOfferErrorHandler.bind(this);
    this.updateOfferErrorHandler = this.updateOfferErrorHandler.bind(this);
    this.updateCheckoutErrorHandler =
      this.updateCheckoutErrorHandler.bind(this);

      this.store.select(selectCurrentOffer).subscribe((currentOffer)=> {
        this.currentOffer = currentOffer;

      })
  }

  generateOfferNavigationMenu(offer: OfferItem = this.currentOffer) {
    // let OfferCount = !!product?.offers?.length ? product.offers.length : '0';

    this.offerPageNavigations = [
      { label: 'Edit Details', url: `/dashboard/offer/${offer.id}/edit` },
      // {
      //   label: `Edit Checkout`,
      //   url: `/dashboard/offer/${offer.id}/edit-checkout`,
      // },
      {
        label: 'Pricing & Payment',
        url: `/dashboard/offer/${offer.id}/payment`
      },
      // {
      //   label: 'Edit Checkout',
      //   url: `/dashboard/offer/${offer.id}/edit-checkout`
      // },
      // {
      //   label: 'Insights',
      //   url: `/dashboard/product/${offer.id}/course/students`,
      //   soon: true,
      // },
      // {
      //   label: 'Activity',
      //   url: `/dashboard/product/${product.id}/course/activity`,
      // },
    ];
  }

  generateOfferPreviewLinks(offer: OfferItem = this.currentOffer) {
    this.previewButtons = [
      {
        label: 'Edit Checkout',
        colorSet: 'light-primary',
        buttonSize: 'sm',
        hiddenButton: false,
        colorSetHover: 'light-primary',
        iconSvg: 'assets/images/svg/system/core/checkout.svg',
        routerLink: `/dashboard/offer/${offer.id}/edit-checkout`,
        action: ()=> {
          this.sharedDashboardService.setBackRoute(`Back to offer ${this.currentOffer.title}`)
        }
      },
      {
        label: 'Preview',
        colorSet: 'light-success',
        hiddenButton: true,
        buttonSize: 'sm',
        colorSetHover: 'light-success',
        iconSvg: 'assets/images/svg/system/preview.svg',
        url: offer.previewLink,
      },

    ];
  }

  editCheckoutScrollTo() {
    if (!!this.scrollTo) {
      let el = document.getElementById(this.scrollTo);
      if (!!el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el.classList.add('checkout-iam-here-indicator');



      setTimeout(() => {
        el.classList.remove('checkout-iam-here-indicator');
        this.scrollTo = null;
      }, 1200);
    }
    }
  }


  // syncCheckoutWithUI() {
  //   this.uiCheckboxes.downloadExpiry = !!this.offer?.constraints.downloadExpiry;

  //   this.uiCheckboxes.expireAt = !!this.offer?.constraints.expireAt;

  //   this.uiCheckboxes.stock = !!this.offer?.constraints.stock;

  //   this.uiCheckboxes.allowedDownloadTimes =
  //     !!this.offer?.constraints.allowedDownloadTimes;
  // }

  // async goToTab(slug: EditCheckoutMenuEnum) {
  //   this.currentMenuTab = slug;
  // }



  backToEditOffer() {
    if (!this.sharedDashboardService.canNavigateAll) {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.goToEdit();
        this.sharedDashboardService.enableNavigate();
      } else {
        return false;
      }
    } else {
      this.goToEdit();
      this.sharedDashboardService.enableNavigate();
    }
  }

  // syncPaymentGatewayWithUI() {
  //   this.paymentGateways.stripe =
  //     this.offer.payment_type.stripe === Payment_Status_Enum.enabled
  //       ? true
  //       : undefined;
  //   this.paymentGateways.btcPay =
  //     this.offer.payment_type.btcPay === Payment_Status_Enum.enabled
  //       ? true
  //       : undefined;
  //   this.paymentGateways.payPal =
  //     this.offer.payment_type.payPal === Payment_Status_Enum.enabled
  //       ? true
  //       : undefined;
  // }

  startEdited(
    part:
      | 'payment'
      | 'settings'
      | 'offer'
      | 'products'
      | 'information'
      | 'status',
    status: 'error' | 'edited' | 'none',
    msg: string = '',
    time: number = 5000
  ) {
    switch (status) {
      case 'edited':
        this.status[part] = status;
        setTimeout(() => {
          this.status[part] = 'none';
        }, 2000);
        break;
      case 'error':
        this.status[part] = status;
        this.errorMsg[part] = msg;
        if (!!time) {
          setTimeout(() => {
            this.status[part] = 'none';
            this.errorMsg[part] = '';
          }, time);
        }
        break;
      case 'none':
        this.status[part] = 'none';
        this.errorMsg[part] = '';
        break;
      default:
        break;
    }
  }

  detectMissingItems(offer: OfferItem) {
    if (this.pageStatus !== 'edit') {
      this.goToEdit();
    }

    offer.isComplete.incompleteItems.forEach((a) => {
      this.startEdited(a.area, 'error', a.msg, 0);
      this.scroll(a.area);
    });
  }

  scroll(id: string, position: ScrollLogicalPosition = 'start') {
    let el = document.getElementById(id);
    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth', block: position });
    }
  }

  unDetectMissingItems(
    area:
      | 'payment'
      | 'settings'
      | 'offer'
      | 'products'
      | 'information'
      | 'status' = 'offer'
  ) {
    if (area === 'offer') {
      this.startEdited('information', 'none', '', 0);
      this.startEdited('offer', 'none', '', 0);
      this.startEdited('payment', 'none', '', 0);
      this.startEdited('products', 'none', '', 0);
      this.startEdited('settings', 'none', '', 0);
      this.startEdited('status', 'none', '', 0);
    } else {
      this.startEdited(area, 'none', '', 0);
    }
  }

  async getOfferById(id: number) {
    this.store.dispatch(currentOfferActions.load({offerId: id}))
    // this.loaded = false;
    // const request$ = this.api.getOfferById(id, this.getOfferErrorHandler);
    // const response: OfferItem = await lastValueFrom(request$);
    // this.offer = new OfferItem(response);
    // this.offerEditPayload = JSON.parse(JSON.stringify(this.offer));
    // this.currentOfferUrl = this.offer.offerLink;
    // this.loaded = true;
  }

  async getCheckoutById(id: number) {
    this.loaded = false;
    const checkout$ = this.api.getCheckoutById(id, this.getOfferErrorHandler);
    const checkout = await lastValueFrom(checkout$);
    this.checkout = JSON.parse(JSON.stringify(checkout));
    this.checkoutEditPayload = JSON.parse(JSON.stringify(checkout));
    // this.currentOfferUrl = this.checkout.offerLink;
    this.loaded = true;
  }

  async updateCheckout(payload: {
    checkoutContent?: CheckoutContent,
    checkoutBanner?: CheckoutBanner,
    appearances?: CheckoutAppearances,
    extraContactInfo?: CheckoutExtraInfo,
    testimonials?: CheckoutTestimonials[],
  }) {

    this.saveEditCheckoutStatus = 'loading';
    const checkoutUpdate$ = this.api.updateCheckout(
      this.currentOfferId,
      payload,
      this.updateCheckoutErrorHandler
    );
    const checkout: UpdateEditCheckoutResponseModel = await lastValueFrom(
      checkoutUpdate$
    );
    this.checkout = JSON.parse(JSON.stringify(checkout.checkoutInfo));
    this.checkoutEditPayload = JSON.parse(
      JSON.stringify(checkout.checkoutInfo)
    );

    this.sharedDashboardService.enableNavigate();
    if (!!checkout.anyDataUpdated) {
      this.saveEditCheckoutStatus = 'done';
      setTimeout(() => {
        this.saveEditCheckoutStatus = 'none';
      }, 1000);
    } else {
      // TODO: No thing Updated Action
      this.saveEditCheckoutStatus = 'done';
      setTimeout(() => {
        this.saveEditCheckoutStatus = 'none';
      }, 1000);
    }
    this.iframeEmitter.emit();
    this.toastr.success('Done!', 'Checkout Page updated successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }



  updateCheckoutErrorHandler(error: any) {
    this.saveEditCheckoutStatus = 'none';
    this.toastr.error('Error!', 'Something went wrong', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }
  getOfferErrorHandler(error: any) {
    Swal.fire({
      icon: 'error',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'bg-primary',
        cancelButton: 'bg-danger',
      },
      confirmButtonText: 'Back to Offers',
      showCancelButton: false,
      preConfirm: (result) => {
        this.goToOffers();
      },

      title: 'Something went wrong',
      html: `<span>Cannot load this offer</span>`,
    });
  }

  startProcessing(secondary: boolean = false) {
    if (!secondary) {
      this.processing.add = 'loading';
    } else {
      this.processing.secondary = 'loading';
    }
  }

  endProcessing(
    secondary: boolean = false,
    status: 'loading' | 'done' | 'none' | 'error' | 'no-change' = 'done'
  ) {
    if (!secondary) {
      this.processing.add = status;
      setTimeout(() => {
        this.processing.add = 'none';
      }, 1000);
    } else {
      this.processing.secondary = status;
      setTimeout(() => {
        this.processing.secondary = 'none';
      }, 1000);
    }
  }

  updateOfferErrorHandler(error: any) {
    // this.sharedOfferService.startEdited('payment', 'error');
  }






  async setOfferAsDefault(offer: OfferItem) {
    // let editOffer: OfferEditResponse;
    // const payload = {
    //   isDefault: true,
    // };

    // const updateOffer$ = this.api.setOfferAsDefault(
    //   offer.id,
    //   this.updateOfferErrorHandler
    // );

    // editOffer = await lastValueFrom(updateOffer$);
    // offer.isDefault = editOffer.offer.isDefault;
    this.store.dispatch(productOfferActions.setOffer({offerId: offer.id, productId: offer.products[0].id}));
    this.watchOfferForUpdate(offer.id, 'Offer Marked as default Successfully');

    // this.toastr.success('Done!', 'Offer Marked as default Successfully', {
    //   closeButton: false,
    //   positionClass: 'toast-bottom-right',
    // });
  }

  async changeOfferStatus(offer: OfferItem, status: OfferStausEnum) {
    let successMessage = undefined;
    switch (status) {
      case OfferStausEnum.published:
        successMessage = 'Offer Published Successfully';
        break;
        case OfferStausEnum.draft:
          successMessage = 'Offer Drafted Successfully';
        default:
        break
    }

    this.store.dispatch(updateOfferStatusActions.update({ offerId:offer.id, productId: offer.products[0].id, status }));
    this.watchOfferForUpdate(offer.id, successMessage);
    // this.store.select(selectOffersState).pipe(takeWhile(state => state?.currentOffer?.updating !== 'done' && state?.currentOffer?.updating !== 'error', true)
    // ).subscribe(state=> {
    //   console.log("Current Offer Update Status is: ",state?.currentOffer?.updating)
    //   switch (state?.currentOffer?.updating) {
    //     case 'done':
    //       this.onOfferUpdate(state.currentOffer, 'Offer marked as default successfully');
    //       break;
    //     case 'error':
    //       this.onOfferUpdateError(state.currentOffer.errorMessage)
    //     break;
    //     default:
    //       break;
    //   }

    // })

    // const isFrozen = Object.isFrozen(offer);
    // if (!isFrozen) {
    //   offer.status = response.offer.status;
    // }
  }

  async updateOffer(offerId: number, productId: number, payload?: OfferEditPayload) {
    this.store.dispatch(updateOfferActions.update({offerId, productId,  updates: payload}));
    this.watchOfferForUpdate(offerId);
    // this.store.select(selectOffersState).pipe(takeWhile(state => state?.currentOffer?.updating !== 'done' && state?.currentOffer?.updating !== 'error', true)
    // ).subscribe(state=> {
    //   console.log("Current Offer Update Status is: ",state?.currentOffer?.updating)
    //   switch (state?.currentOffer.updating) {
    //     case 'done':
    //       this.onOfferUpdate(state.currentOffer);
    //       break;
    //     case 'error':
    //       this.onOfferUpdateError(state.currentOffer.errorMessage)
    //     break;
    //     default:
    //       break;
    //   }

    // })

  }


  watchOfferForUpdate(offerId: number, successMessage: string = undefined, doneCallback = this.onOfferUpdate, errorCallback = this.onOfferUpdateError) {
    const offers$ = this.store.select(selectOfferById(offerId));
    const products$ = this.store.select(selectProductOfferById(offerId));



    merge(offers$, products$).pipe(takeWhile(offer => offer?.updating !== 'done' && offer?.updating !== 'error', true)
    ).subscribe(offer=> {
      console.log("Current Offer Update Status is: ",offer?.updating)
      switch (offer?.updating) {
        case 'done':
          doneCallback(offer, successMessage);
          break;
        case 'error':
          errorCallback(offer.errorMessage)
        break;
        default:
          break;
      }

    })
  }
  onOfferUpdateError = (errorMessage: string) => {
    // this.sharedOfferService.offer = offer;

    this.toastr.error(errorMessage);
    this.sharedDashboardService.enableNavigate();
  }

  onOfferUpdate = (offer: OfferItem, successMessage: string = 'Offer Updated Successfully') => {
    // this.sharedOfferService.offer = offer;

    this.toastr.success(successMessage, 'Done!', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.sharedDashboardService.enableNavigate();

  }

  // updateOfferData(
  //   offer: OfferItem,
  //   type: 'offer' | 'payment' | 'settings' | 'status' = 'offer'
  // ) {
  //   switch (type) {
  //     case 'offer':
  //       this.offer = JSON.parse(JSON.stringify(offer));
  //       this.offerEditPayload = JSON.parse(JSON.stringify(offer));
  //       this.enableNavigate();
  //       break;
  //     case 'payment':
  //       this.offer.payment_type = JSON.parse(
  //         JSON.stringify(offer.payment_type)
  //       );
  //       this.offerEditPayload.payment_type = JSON.parse(
  //         JSON.stringify(offer.payment_type)
  //       );
  //       break;
  //     case 'settings':
  //       this.offer.previewLink = offer.previewLink;
  //       this.currentOfferUrl = this.offer.offerLink = offer.offerLink;

  //       this.offer.parentPortal = JSON.parse(
  //         JSON.stringify(offer.parentPortal)
  //       );
  //       this.offerEditPayload.parentPortal = JSON.parse(
  //         JSON.stringify(offer.parentPortal)
  //       );
  //       this.offer.isDefault = offer.isDefault;
  //       this.offerEditPayload.isDefault = offer.isDefault;

  //       this.offer.payment_type = JSON.parse(
  //         JSON.stringify(offer.payment_type)
  //       );
  //       this.offerEditPayload.payment_type = JSON.parse(
  //         JSON.stringify(offer.payment_type)
  //       );
  //       break;
  //     default:
  //       break;
  //   }

  //   this.offer.status = offer.status;
  //   this.offerEditPayload.status = offer.status;
  //   this.offer.isComplete = JSON.parse(JSON.stringify(offer.isComplete));
  //   this.offerEditPayload.isComplete = JSON.parse(
  //     JSON.stringify(offer.isComplete)
  //   );
  // }
  updateSuccessToast(
    type: 'offer' | 'payment' | 'settings' | 'status' = 'offer'
  ) {
    switch (type) {
      case 'offer':
        this.startEdited('offer', 'edited');
        this.toastr.success('Done!', 'Offer Updated Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      case 'payment':
        this.startEdited('payment', 'edited');
        this.toastr.success('Done!', 'Payment Settings Updated Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      case 'settings':
        this.startEdited('settings', 'edited');
        this.toastr.success('Done!', 'Offer Settings Updated Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      default:
        break;
    }
  }

  noUpdateToast(type: 'offer' | 'payment' | 'settings' | 'status' = 'offer') {
    switch (type) {
      case 'offer':
        // this.startEdited('offer', 'edited');
        this.toastr.warning('Oops!', 'Offer has no changes done', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      case 'status':
        // this.startEdited('offer', 'edited');
        this.toastr.warning('Oops!', 'Status has no changes done', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      case 'payment':
        // this.startEdited('payment', 'edited');
        this.toastr.warning('Oops!', 'Payment Settings has no changes done', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      case 'settings':
        // this.startEdited('settings', 'edited');
        this.toastr.warning('Oops!', 'Offer Settings has no changes done', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        break;
      default:
        break;
    }
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }

  goToOffers() {
    this.route.navigate(['dashboard', 'offer']);
  }

  goToView() {
    this.route.navigate([
      'dashboard',
      'product',
      'ip',
      this.currentOfferId,
      'view',
    ]);
  }

  goToEdit(offerId: number = this.currentOfferId) {
    this.route.navigate(['dashboard', 'offer', offerId, 'edit']);
  }

  goToEditCheckout() {
    this.route.navigate([
      'dashboard',
      'offer',
      this.currentOfferId,
      'edit-checkout',
    ]);
  }

  syncOfferProducts() {
    // this.offerEditPayload.included_products_ids =
    //   this.offerEditPayload.included_products.map((a) => a.id);
  }

  async getProducts() {
    this.loading.products = true;
    const products$ = this.api.getProducts();
    this.products = await lastValueFrom(products$);
    this.products = this.products.filter((a) => !!a.isComplete);
    this.loading.products = false;
  }

  async getOffers() {
    this.store.dispatch(offersActions.load());
    // this.loaded = false;
    // const offers$ = this.api.getOffers();
    // let offers: { offers: OfferItem[] } = await lastValueFrom(offers$);
    // this.offers = offers.offers;
    // this.loaded = true;
    // return offers;
  }

  async cloneOfferAlert(offer: OfferItem) {
    return Swal.fire({
      title: 'Are you sure you want to clone this offer?',
      html: `The offer <strong>${offer.title}</strong> will be duplicated`,

      icon: 'info',
      width: '400px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-primary',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger',
      },
      input: 'checkbox',
      inputPlaceholder: `I confirm that I want to <strong class="text-primary">Clone</strong> this offer.`,
      inputValidator: (value: any) => {
        if (!!value) {
          return null;
        } else {
          return 'Read carefully and check the agreement';
        }
      },
      preConfirm: async (inputValue: any) => {
        // return new Promise<string>((resolve) => {
        //   setTimeout(() => {
        //     resolve('Success!');
        //   }, 99999999);
        // })
        this.store.dispatch(cloneOfferActions.clone({offerId: offer.id, productId: offer.products[0].id}));
        // const cloneRequest$ = this.api.cloneOffer(offer.id);
        // return await lastValueFrom(cloneRequest$);
        return true;
        // return cloneRequest;
      },
      showCancelButton: true,
      confirmButtonText: 'Clone',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,

      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        return result.value;
      }
    });
  }

  getOfferUrl(): string {
    this.loading.getLink = true;
    const url = 'https://www.myinfluencerportal.com/checkout?id=222';
    this.loading.getLink = false;
    return url;
  }

  async deleteOfferAlert(offer: OfferItem) {
    return Swal.fire({
      title: 'Are you sure want to delete?',
      html: `
        You will not be able to recover this Offer
        `,
      icon: 'error',
      width: '600px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-danger',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger',
      },
      input:
        offer.status == OfferStausEnum.published ? 'checkbox' : undefined,
      inputPlaceholder: `I am sure that i'll lose my deleted offer.`,
      inputValidator: (value: any) => {
        if (!!value) {
          return null;
        } else {
          return 'Read carefully and check the agreement';
        }
      },
      preConfirm: async (inputValue: any) => {
        this.store.dispatch(deleteOfferActions.delete({offerId: offer.id, productId: offer.products[0].id}));
        // let deleteResponse = await this.deleteOfferRequest(offer.id);
        // //
        // this.toastr.success(`Offer Deleted Successfully`);
        return true;
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        return result.value;
      }
    });
  }

  async deleteOfferRequest(offerId: number) {

    const deleteRequest$ = this.api.deleteOffer(offerId);
    let deleteResponse = await lastValueFrom(deleteRequest$);
    return deleteResponse;
  }

  public async getUserPortals() {
    this.loading.portals = true;
    const res$ = this.api.getPortalsList();
    this.userPortals = await lastValueFrom(res$);
    this.loading.portals = false;
  }

  public async getCurrencies() {
    this.loading.currencies = true;
    const res$ = await this.api.getCurrencies();
    this.currencies = await lastValueFrom(res$);
    this.loading.currencies = false;
  }
}
