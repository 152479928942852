import { Payout_Gateway_Enum } from 'src/app/models/portal';
import { PayoutDuePeriodObject, PayoutMethodStatusEnum } from './portal';
import {
  AvatarResult,
  ChartValue,
  CommissionsModel,
  NotificationElement,
  OwnerNotificationTypeEnum,
} from './owner-dashboard';
import { MediaLibraryFileModel, MoneyModel } from './sales-funnel';
import { User } from './user';
import { NotificationUserTypeEnum } from '../shared/services/notifications.service';
import {
  CustomerObject,
  PeriodBasedObject,
  StandardPagination,
  AffiliateMinimizedModel,
} from './owner-dashboard';

// --------------------------------------------- Product -----------------------------------------------
export class AffiliateLinkOfOwner {
  public id: number;
  public name: string;
  public commissions: CommissionsModel;
  public link: string;
  public visits: number;
  public uniqueVisits: number;
  public image: string;
  public salesCount: number;
  public earnings: number;
  public isVip: boolean;
  public type: 'general' | 'vip';
  public visibility?: 'public' | 'private';
  public series: ChartValue[];
  public inCompare?: boolean;
}

export class AffiliateDashboardTotalTrackingData {
  totalCommissions: number;
  dueCommissions: CommissionInfo;
  processedCommissions: CommissionInfo;
  pendingCommissions: CommissionInfo;
  visits: number;
  uniqueVisits: number;
  conversion: number;
  conversionRate: number;
  lastSaleDate: number;
}

export class CommissionInfo {
  amount: number;
  date: number;
}

// Affiliate Dashboard

export class OnwerForAffiliateModel {
  public id: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public status: OwnerNetworkStatusEnum;
  public image?: AvatarResult;
  public invitedAt: number;
  public joinedAt?: number;
  // public affiliateLinks?: AffiliateLinkOfOwner[];
  public messengerThreadId: number;
  public unreadMessagesCount: number;
  public unreadAnnouncementsCount: number;
}

export class OwnerOverview extends PeriodBasedObject {
  public affiliateLinks: AffiliateLinkOfOwner[]; // The list array, this was sent before in GET affiliate/owners/:id
  // public graphData: AffiliateGraphData[];// The graph array
}

export interface NetworkEarningsList
  extends PeriodBasedObject,
    StandardPagination {
  earnings: NetworkEarnings[];
}

export class NetworkEarnings {
  id: number;
  paymentMethod: string;
  customer: CustomerObject;
  saleDate: number;
  commission: number;
  type: EarningActivityTypeEnum; // Direct|Indirect|VIP
  status: EarningActivityStatusEnum; // Pending|Due|Processed
  dueDate: number;
}

export class OverviewTrackingAndEarningsGraph extends PeriodBasedObject {
  dashboardseries: AffiliateGraphData[];
}
export class AffiliateGraphData {
  name: string;
  value: string;
  series: ChartValue[];
}

// Statistics Page

export class DashboardNetworksStatistics extends PeriodBasedObject {
  networksStatistics: NetworkStatistics[];
  // statisticsSeries: AffiliateGraphData[];// This is for the graph, if ownerIds is not sent then this field will be empty array
}

export class NetworkStatistics {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  image: string;
  joinedDate: number;
  uniqueClicks: number;
  salesCount: number;
  earnings: MoneyModel;
  series: ChartValue[];
  inCompare: boolean;
}

export enum EarningActivityStatusEnum {
  pending = 'pending',
  due = 'due',
  processed = 'processed',
}

export enum EarningActivityTypeEnum {
  vip = 'VIP',
  indirect = 'Indirect',
  direct = 'Direct',
}

export enum OwnerNetworkStatusEnum {
  pending = 'invited',
  joined = 'joined',
  rejected = 'rejected',
  blocked = 'blocked',
}
export class GetOnwersForAffiliateResponse {
  joinedNetworks: OnwerForAffiliateModel[];
  invitedNetworks: OnwerForAffiliateModel[];
}

export enum AffiliateNotificationTypeEnum {
  invitation = 'invitation',
  new_sale = 'new_sale',
  commission_processed = 'commission_processed',
  affiliate_link_add = 'affiliate_link_add',
  affiliate_link_update = 'affiliate_link_update',
  affiliate_link_paused = 'affiliate_link_paused',
  affiliate_link_resumed = 'affiliate_link_resumed',
  refund = 'refund',
  general = 'general',
}

export enum NotificationStatusEnum {
  read = 'read',
  unread = 'unread',
}
export class AffiliateNotificationElement extends NotificationElement {
  declare type: AffiliateNotificationTypeEnum;
}

export class InvitationHashData {
  userData: UserDataForInvitation; // If NOT null then this user already registered before
  hashData: HashDataBody;
  invitationOwner: OnwerForInvitation;
  owners?: OnwerForInvitation[];
  accepted: boolean;
  maxCommission: number; // New
  maxCommissionVip: number; // New
  maxCommissionLev1: number; // New
  maxCommissionLev2: number; // New
}

export class UserDataForInvitation {
  id?: number;
  username?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  isRegistered?: boolean;
  image?: AvatarResult;
}

export class OnwerForInvitation {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  image?: AvatarResult;
  invitedAt: number;
  hash: string;
  accepted?: NetworkAcceptEnum;
  maxCommissionVip: number; // New
  maxCommissionLev1: number; // New
  maxCommissionLev2: number; // New
}

export enum NetworkAcceptEnum {
  accepted = 'accepted',
  later = 'later',
  none = 'none',
  accepted_block = 'accepted_block',
}

export class HashDataBody {
  id: number;
  hash: string;
}

// Affiliate Network Preferences
export class AffiliateNetworkPayoutSettingsObject {
  payoutDue: PayoutDuePeriodObject;
  payoutMethods: AffiliateNetworkPayoutMethodObject[];
}

export class AffiliateNetworkPayoutMethodObject {
  public id?: number;
  public display_name: string;
  public enum: Payout_Gateway_Enum;
  public priorityGrade?: number;
  public icon?: string;
  public status?: PayoutMethodStatusEnum;
  public minimumAmount?: MoneyModel;
  public payPalConfiguration?: PaypalConfiguration;
  public btcPayConfiguration?: BtcpayConfiguration;
  public wireTransferConfiguration?: WireTransferConfiguration;
}

export class PaypalConfiguration {
  public email?: string;
}

export class BtcpayConfiguration {
  public walletAddress?: string;
}

export class WireTransferConfiguration {
  name?: string;
  address?: string;
  account?: string;
  phone?: string;
  swiftCode?: string;
  iban?: string;
  routingNumber?: string;
}

export class BankDetailsFromSwift {
  id: number;
  bank: string;
  city: string;
  branch: string;
  swiftCode: string;
  country: string;
  countryCode: string;
}
