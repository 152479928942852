import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../services';

import { Store } from '@ngrx/store';
import {

  loadAccountSetupStepsActions,
  stepUpdate,
} from './account-setup.actions';


@Injectable()
export class AccountSetupEffects {
  loadSteps$;
  // setStepAsDone$;
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {
    this.loadSteps$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadAccountSetupStepsActions.load),
        mergeMap(() =>
          this.api.getAccountSetupSteps((error)=>{throw error}).pipe(
            map((response: any) =>
              loadAccountSetupStepsActions.loadSuccess({
                steps: response.AccountSetupStep,
              })
            ),
            catchError((error) => of(loadAccountSetupStepsActions.loadFailure({ error })))
          )
        )
      )
    );



  }
}
