import { AuthenticationService } from 'src/app/shared/services';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('disableInterceptor')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        return this.handleHttpError(err, request, next);
      })
    );
  }

  private handleHttpError(err: any, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (err && typeof err !== 'string' && 'status' in err) {
      if (err.status == HttpStatusCode.Unauthorized) {
        // this.toastr.error('Session timeout!');
        // location.reload();
        return this.handleUnauthorizedError(request, next);
      }
      return throwError(
        ()=> !!err.error
        ? err.error
        : 'Internal error happened during requesting resource.'
      );
    }

    const error =
      typeof err === 'string'
        ? err
        : typeof err === 'object' && err instanceof HttpErrorResponse
        ? `${err.status} ${err.statusText}`
        : 'error' in err
        ? err.error.message
        : 'statusText' in err
        ? err.statusText
        : `${err}`;

    return throwError(()=> error);
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Example: Redirect to login page or handle unauthorized error
    // this.router.navigate(['/login']);
    // Or you can just propagate the error further
    this.authenticationService.logout();
    return throwError(() => 'Unauthorized');
  }
}
