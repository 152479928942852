import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { ProfileUiControlService } from '../services/profile-ui-control.service';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(public profileUiService: ProfileUiControlService) {}
  canDeactivate(component: ComponentCanDeactivate): boolean {
    if (!!component?.canDeactivate && !component?.canDeactivate()) {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.profileUiService.changesSaved = 0;
        this.profileUiService.passwordChangesSaved = 0;
        this.profileUiService.profileEditStatus = false;
        this.profileUiService.profileAsideStatus = false;
        return true;
      } else {
        return false;
      }
    }
    this.profileUiService.changesSaved = 0;
    this.profileUiService.passwordChangesSaved = 0;
    this.profileUiService.profileEditStatus = false;
    this.profileUiService.profileAsideStatus = false;
    return true;
  }
}
