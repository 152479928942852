import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

const CONFIG = {
  DEFAULT: false,
  VOICE: 'Fred',
};

@Component({
  selector: 'app-learmo-robot',
  templateUrl: './learmo-robot.component.html',
  styleUrls: ['./learmo-robot.component.css']
})
export class LearmoRobotComponent implements OnInit, OnDestroy {
  speechSynthesisRef = speechSynthesis;
  subscription: Subscription;
  talking: boolean;
  talker: SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
  @Input() message: string = "Hello! I'm Learmo, your new AI assistant. It's great to meet you and I'm here to help you with anything you need. Whether you're looking for information, need some assistance, or just want to chat, I'm always here for you. Let's get started!";
  @Input() talkEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  ngOnDestroy(): void {
    this.stopTalking()
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.talkEmitter.subscribe((a)=> {
      this.toggleTalking();
    })
  }

  toggleTalking() {
    if (speechSynthesis.speaking) {
      this.stopTalking();
  } else {

      this.startTalking();
  }
  }
  startTalking() {

    this.talker.text = this.message;
    let voices = speechSynthesis.getVoices();
    if (!CONFIG.DEFAULT) {
      this.talker.voice = voices.find(voice => voice.name === CONFIG.VOICE);
    }
    this.talker.onend = (event) => {
      this.talking = false;
    }

    this.talking = true;
    speechSynthesis.speak(this.talker);


  }

  stopTalking() {
    this.talking = false;
    speechSynthesis.cancel()
  }

}
