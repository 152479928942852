import { Action, createReducer, on } from '@ngrx/store';
import { WebsiteModel, WebsiteProcessTypeEnum } from '../../../../components/user-dashboard/portal/portal.model';
import {
  websiteActions,
  websitePagesActions,
  updateWebsiteIdentity,
  updateWebsiteSenderEmailConfig,
  setWebsiteAsReady,
  updateWebsitePage,
  loadWebsitePagesScreenshot,
  addNewWebsitePage,
  deleteWebsitePage,
  websiteCategoriesActions,
  createWebsiteCategoriesActions,
  websiteInstructorsActions,
} from './website.actions';
import { PageItem, PaymentGatewaysInformation } from 'src/app/models/portal';
import { forgetSession } from '../user/auth/auth.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {  CourseCategoryItem } from 'src/app/components/user-dashboard/product/models/course';
import { SystemProcess } from 'src/app/models/global-classes';
import { InstructorItem } from 'src/app/components/user-dashboard/contacts/models/contacts';

export interface WebsiteState extends EntityState<PageItem> {
  website: WebsiteModel | null;
  loaded: boolean;
  isWebsiteReady: boolean;
  error: any;
  // pages: PageItem[] | null; // Assuming you have a PageModel or a similar model
  pagesLoaded: boolean;
  pagesError: any;

  categories: CourseCategoryItem[],
  categoriesProcess: SystemProcess,
  lastAddedCategory: CourseCategoryItem,

  instructors: InstructorItem[],
  instructorsProcess: SystemProcess,
  lastAddedInstructor: InstructorItem
}

export const pageAdapter = createEntityAdapter<PageItem>();



const initialState: WebsiteState = pageAdapter.getInitialState({
  website: null,
  loaded: false,
  isWebsiteReady: null,
  error: null,
  // pages: null,
  pagesLoaded: false,
  pagesError: null,
  

  categories: [],
  categoriesProcess: new SystemProcess(),
  lastAddedCategory: null,

  instructors: [],
  instructorsProcess: new SystemProcess(),
  lastAddedInstructor: null
});

export const websiteReducer = createReducer(
  initialState,
  on(websiteActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(websiteActions.loadSuccess, (state, { website }) => ({
    ...state,
    website: website,
    loaded: true,
    isWebsiteReady: !website?.process?.isProcessing || (website?.process?.type == WebsiteProcessTypeEnum.creating && !website?.process?.isProcessing) ? true : false
  })),
  on(setWebsiteAsReady, (state, {website}) =>
   {
    return  {
      ...state,
      website: {...website},
      isWebsiteReady: !website?.process?.isProcessing || (website?.process?.type == WebsiteProcessTypeEnum.creating && !website?.process?.isProcessing) ? true : false
    }
   }
  ),
  on(websiteActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  on(updateWebsiteIdentity, (state, { identity }) => {
    const updatedObject = { ...state.website, identity };
    return { ...state, website: updatedObject, loaded: true };
  }),

  on(updateWebsiteSenderEmailConfig, (state, { senderEmailConfig }) => {
    const updatedObject = {
      ...state.website,
      senderMailConfig: {
        ...state.website.senderMailConfig,
        ...senderEmailConfig,
      },
    };
    return { ...state, website: updatedObject, loaded: true };
  }),

  on(websitePagesActions.load, (state) => ({
    ...state,
    pagesLoaded: false,
    pagesError: null,
  })), // Handle loading of pages

  on(updateWebsitePage, (state, { page }) => {
    return pageAdapter.updateOne(
      {
        id: page.id,
        changes: { ...page },
      },
      state
    );
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),


  on(websitePagesActions.loadSuccess, (state, { pages }) => {
    return pageAdapter.setAll(pages, { ...state, pagesLoaded: true });
  }),

  on(websitePagesActions.loadFailure, (state, { error }) => ({
    ...state,
    pagesError: error,
    pagesLoaded: false,
  })), // Handle error in loading pages
  // Handle other actions and state changes here

  on(addNewWebsitePage, (state, { page }) => {
    return pageAdapter.addOne(page, state);
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(deleteWebsitePage, (state, { page }) => pageAdapter.removeOne(page.id, { ...state, loading: false })),






  // Categories
  on(websiteCategoriesActions.loadSuccess, (state, {categories}) => ({
    ...state,
    categories,
    categoriesProcess: new SystemProcess('done')
  })),


  on(createWebsiteCategoriesActions.create, (state, {}) => ({
    ...state,
    categoriesProcess: {...state.categoriesProcess,status: 'creating'}
  })),
  on(createWebsiteCategoriesActions.createSuccess, (state, {category}) => ({
    ...state,
    categories: [...state.categories, category],
    lastAddedCategory: category,
    categoriesProcess: {...state.categoriesProcess,status: 'done'}
  })),

  on(createWebsiteCategoriesActions.createFailure, (state, {error}) => ({
    ...state,
    categoriesProcess: {...state.categoriesProcess,status: 'error', error: error}
  })),



   // Instructors
   on(websiteInstructorsActions.loadSuccess, (state, {instructors}) => ({
    ...state,
    instructors,
    instructorsProcess: new SystemProcess('done')
  })),






  on(forgetSession, (state) => initialState)
);
