import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UploadProgressFile } from '../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-attachment-file-view',
  templateUrl: './attachment-file-view.component.html',
  styleUrls: ['./attachment-file-view.component.css'],
})
export class AttachmentFileViewComponent implements OnInit {
  @Input() item: UploadProgressFile;
  @Output() onRemoveClick: EventEmitter<any> = new EventEmitter<any>();

  acceptedImages = ['bmp', 'gif', 'jpeg', 'png', 'svg', 'tiff', 'webp', 'jpg'];
  acceptedVideos = [
    'avi',
    'avf',
    'divx',
    'mp4',
    'mpeg',
    'ogg',
    'ogg',
    'webm',
    '3gpp',
    '3gp',
    '3gpp2',
  ];

  constructor() {}

  removeClick(id: number) {
    this.onRemoveClick.emit(id);
  }
  ngOnInit(): void {
    if (!!this.item?.extension) {
      if (this.acceptedImages.includes(this.item.extension)) {
        this.item.type = 'image';
      } else if (this.acceptedVideos.includes(this.item.extension)) {
        this.item.type = 'video';
      } else {
        this.item.type = 'other';
      }
    }
  }
}
