import { Component, OnInit } from '@angular/core';
import { InOutAnimation } from 'src/app/shared/animations/animations';
import { AnimatedLoaderType } from 'src/app/shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';

@Component({
  selector: 'app-website-finalizing',
  templateUrl: './website-finalizing.component.html',
  styleUrls: ['./website-finalizing.component.css'],
  animations: [InOutAnimation]
})
export class WebsiteFinalizingComponent implements OnInit {
  message: {
    title: string;
    body: string;
    animationType: AnimatedLoaderType;
  } = {
    title: 'Completing Your Website',
    body: 'Please wait...',
    animationType: AnimatedLoaderType.learmo_loader,
  };

  constructor() {}

  ngOnInit(): void {
    this.message = {title: 'Completing Your Website', body: 'Please wait...', animationType: AnimatedLoaderType.learmo_loader};

    setTimeout(() => {
      this.message = { title: 'This process may take a several minutes', body: 'Please wait while we complete the process...', animationType: AnimatedLoaderType.pencel };
    }, 7000);
  }
}
