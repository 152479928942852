const ICONS = {
    category_name: 'Time',
    icons_list: [
      "user",
      "user-square",
      "user-tick",
      "people",
      "user-edit",
      "profile-user",
      "profile-circle"
    ]



};


export default ICONS
