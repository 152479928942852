import { SharedOwnerDashboardService } from './../../../../../../../../shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { OwnerFunnelStatusEnum } from './../../../../../../../../models/funnels';

import { SharedFunnelService } from './../../../../../shared-funnel.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-builder-header',
  templateUrl: './builder-header.component.html',
  styleUrls: ['./builder-header.component.css'],
})
export class BuilderHeaderComponent implements OnInit {
  ownerFunnelStatusEnum = OwnerFunnelStatusEnum;

  @Input() backButtonLabel = 'Offer Edit';
  @Output() backButton = new EventEmitter();
  @Output() primaryButton = new EventEmitter();
  @Output() secondaryButton = new EventEmitter();

  constructor(
    public sharedFunnelService: SharedFunnelService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService
  ) {}

  ngOnInit() {}

  async onPrimaryButton() {
    this.primaryButton.emit();
  }

  async onSecondaryButton() {
    this.secondaryButton.emit();
  }

  async onBackButton() {
    this.backButton.emit();
  }
}
