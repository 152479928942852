import { Component, Input, OnInit } from '@angular/core';
import { InOutAnimation } from 'src/app/shared/animations/animations';

export enum AnimatedLoaderType {
  ai_loader = 'ai_loader',
  pencel = 'pencil',
  learmo_loader = 'learmo_loader'
}
@Component({
  selector: 'app-ai-thinking-loader',
  templateUrl: './ai-thinking-loader.component.html',
  styleUrls: ['./ai-thinking-loader.component.css'],
  animations: [InOutAnimation]
})
export class AiThinkingLoaderComponent implements OnInit {
  animatedLoaderType = AnimatedLoaderType;
  @Input() type: AnimatedLoaderType = AnimatedLoaderType.ai_loader
  constructor() { }

  ngOnInit() {
  }

  invertAnimation() {
    if (this.type == AnimatedLoaderType.ai_loader) {
      this.type = AnimatedLoaderType.learmo_loader
    }
    else {
      this.type = AnimatedLoaderType.ai_loader
    }
  }
}
