import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Models } from 'appwrite';

import { Country, CountryState } from 'src/app/models/country';

export class ActiveSessionItemModel {
  id: number;
  country: Country;
  state: CountryState;
  deviceType: DeviceTypeEnum;
  os: OperatingSystemEnum;
  isTwoFactorAuthenticated?: boolean;
  loginTime: number;
  lastActivityTime: number;
  ipAddress: string;
  userAgent?: string;
  browserName: BrowserNameEnum;
  browserVersion: string;
  expirationTime?: number;
  isCurrentSession: boolean;
  isActive: boolean;
}

export enum BrowserNameEnum {
  Chrome = 'Chrome',
  Edge = 'Edge',
  Opera = 'Opera',
  Firefox = 'Firefox',
  Safari = 'Safari',
  InternetExplorer = 'Internet Explorer',
  Yandex = 'Yandex',
  UCBrowser = 'UC Browser',
  WeChat = 'WeChat',
  QQBrowser = 'QQ Browser',
  CocCoc = 'Coc Coc',
  Dolphin = 'Dolphin',
  MIUIBrowser = 'MIUI Browser',
  Tesla = 'Tesla',
  Browser360 = '360 Browser',
  AvastAVG = 'Avast/AVG Secure Browser',
  FirefoxFocus = 'Firefox Focus',
  OperaTouch = 'Opera Touch',
  Kindle = 'Kindle',
  LunascapeMaxthon = 'Lunascape/Maxthon',
  TridentBased = 'Trident-based Browser',
  WebkitKHTMLBased = 'Webkit/KHTML-based Browser',
  Electron = 'Electron-based App',
  TikTok = 'TikTok',
  ChromeHeadless = 'Chrome Headless',
  ChromeWebView = 'Chrome WebView',
  AndroidBrowser = 'Android Browser',
  MobileSafari = 'Mobile Safari',
  unrecognized = 'Unrecognized'
}


export enum OperatingSystemEnum {
  Windows = 'Windows',
  WindowsPhone = 'Windows Phone',
  MacOS = 'Mac OS',
  iOS = 'iOS',
  Android = 'Android',
  Kindle = 'Kindle',
  Linux = 'Linux',
  Tizen = 'Tizen',
  Sailfish = 'Sailfish',
  Huawei = 'Huawei',
  Oculus = 'Oculus',
  Samsung = 'Samsung',
  FirefoxOS = 'Firefox OS',
  ChromeOS = 'Chrome OS',
  unrecognized = 'Unrecognized'
}

export enum DeviceTypeEnum {
  computer = 'Computer',
  watch = 'Watch',
  phone = 'Phone',
  device = 'Device',
  unrecognized = 'Unrecognized'
}

@Component({
  selector: 'app-active-session-item',
  templateUrl: './active-session-item.component.html',
  styleUrls: ['./active-session-item.component.css'],
})
export class ActiveSessionItemComponent implements OnInit {
  @Input() item: Models.Session;
  @Output() onKill = new EventEmitter<any>();
  constructor(public appwriteService: AppwriteService) {}

  ngOnInit() {}

  async killSessionByID() {
    await this.appwriteService.killSessionByID(this.item.$id);
    this.onKill.emit();
  }
}
