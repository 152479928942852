import { Component, Input, OnInit } from '@angular/core';
import { MediaLibraryFileModel } from '../../../../models/sales-funnel';

@Component({
  selector: 'app-file-type-icon',
  templateUrl: './file-type-icon.component.html',
  styleUrls: ['./file-type-icon.component.css'],
})
export class FileTypeIconComponent implements OnInit {
  type = 'file';
  ext = '';
  @Input('file') set setType(value: MediaLibraryFileModel | string) {
    if (value instanceof MediaLibraryFileModel) {
      this.type = value.type.name;
      this.ext = value?.fileInfo.ext.substring(1);
    }
    else {
      this.type = 'file';
      this.ext = value;
    }
    
  };
  @Input() size: { width?: string; height?: string };

  constructor() {}

  ngOnInit() {}
}
