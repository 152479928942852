// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';
import { ApiService } from '../../../services';
import { RefundRequestsActions } from './refunds.actions';



@Injectable()
export class RefundRequestsEffects {
  loadRefundRequests$;

  constructor(
    private actions$: Actions,
    private api: ApiService
  ) {
    this.loadRefundRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefundRequestsActions.load), // Listen for the loadProducts action
      mergeMap(() =>
        this.api.getRefundList().pipe( // Use pipe method correctly within mergeMap
          map((refundRequests) => RefundRequestsActions.loadSuccess({ refundRequests })),
          catchError((error) => of(RefundRequestsActions.loadFailure({ error }))
        )
      )
    )
  ));
  }
}
