import { Router } from '@angular/router';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem, DropdownItemActionTypeEnum } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { OfferItem, Offer_Payment_Type_Enum } from 'src/app/components/user-dashboard/offer/models/offer';
import { PageItem, PageItemStatusEnum } from 'src/app/models/portal';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/shared/services';
import { lastValueFrom, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { deleteWebsitePage } from 'src/app/shared/store/_features/website/website.actions';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-page-list-item',
  templateUrl: './page-list-item.component.html',
  styleUrls: ['./page-list-item.component.css']
})
export class PageListItemComponent implements OnInit {
  page: PageItem;
  @Input('page') set setPage(page: PageItem) {
    this.page = page;
    this.generateOfferDropdown();
  }

  @Output() onDeleteDone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloneDone: EventEmitter<PageItem> = new EventEmitter<PageItem>();
  // onClickRouter:string | any[];
  dropdownItems: DropdownItem [] = []

  // offerProductNameMatches: boolean = false;
  constructor(private router: Router, private api: ApiService, private store: Store, private toastr: ToastrService) { }

  ngOnInit() {
    // 
    
    // this.offerProductNameMatches = this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name);
    // switch (this.product.type.name) {
    //   case 'course':
    //     this.generateCourseDropdown()
    //     break;
    //     case 'downloadable':
    //     this.generateDownloadableDropdown()
    //     break;
    //   default:
    //     break;
    // }

  }

  // get offerProductNameMatches() {
  //   return this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name)
  // }

  generateOfferDropdown() {
    // this.onClickRouter = ['/dashboard', 'offer', this.page.id, 'edit'];
    
    this.dropdownItems = [
      {
        label: 'Preview Page',
        iconSvg: 'assets/images/svg/system/preview.svg',
        type: DropdownItemActionTypeEnum.normal,
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        href: this.page.url,
        target: '_blank',
        // routerLink: `?p=${ this.page?.id }&brizy-edit`,

      },
      {
        label: 'Edit Details',
        iconSvg: 'assets/images/svg/system/edit.svg',
        type: DropdownItemActionTypeEnum.portal,
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        // routerLink: `?p=${ this.page?.id }&brizy-edit`,
        routerLink: `?breakdance=builder&id=${ this.page?.id }`
      },
      // {
      //   label: 'Duplicate',
      //   iconSvg: 'assets/images/svg/system/copy.svg',
      //   colorSet: 'gray-700',
      //   colorSetHover: 'primary',
      //   action : () => {
      //     this.clonePage(this.page)
      //   },
      // },
      this.page.status === PageItemStatusEnum.system ? {forceHide: true, colorSet: null, iconSvg: null, label: null}: undefined,
      {
        label: 'Delete',
        iconSvg: 'assets/images/svg/system/trash.svg',
        colorSet: 'danger',
        forceHide: this.page.status === PageItemStatusEnum.system,
        colorSetHover: 'danger',
        action: ()=>{this.deletePage(this.page)}
      }
    ]
  }


  clonePage = async (page: PageItem) => {

    // let offerClone: OfferItem = await this.sharedOfferService.cloneOfferAlert(offer);
    // if (!!offerClone) {
    //   this.onCloneDone.emit(offerClone)
    // }
  }


  deletePage = async (page: PageItem) => {
    
    // const deleteProcess = await this.api.deletePage(+id, pageID).toPromise();


    Swal.fire({
      title: 'Are you sure want to delete?',
      html: `
        You will not be able to recover this Page
        `,
      icon: 'error',
      width: '600px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-danger',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger',
      },
      input:
        page.status == PageItemStatusEnum.published ? 'checkbox' : undefined,
      inputPlaceholder: `I am sure that i'll lose my deleted page.`,
      preConfirm: async () => {
       return await this.deletePageRequest(page);
       
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (res.value.success) {
        this.store.dispatch(deleteWebsitePage({page}));
        this.toastr.success(`Page Deleted Successfully`);
        return true;
      } else {
        return false;
      }
    });
  }

  async deletePageRequest(page: PageItem) {
    let request$ = this.api
    .deletePage(page.id);

    let response: {success: boolean} = await lastValueFrom(request$);

    return response;
  }

}
