import {
  isUserPaymentCardsLoaded,
  selectUserPaymentCards,
} from './../../store/_features/payments/payments.selector';
import { Subscription, lastValueFrom, skipWhile, takeWhile } from 'rxjs';
import { Plan } from 'src/app/models/plans';
import {
  ChangeDetectorRef,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ConfirmCardPaymentData,
  ConfirmCardPaymentOptions,
  PaymentIntentOrSetupIntentResult,
  PaymentIntentResult,
  SetupIntent,
  SetupIntentResult,
  StripeCardCvcElement,
  StripeCardElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementBase,
  StripeElements,
  StripeElementType,
  StripeError,
} from '@stripe/stripe-js';
import { Country } from 'src/app/models/country';
import { ApiService } from '../../../shared/services/lsg.api.service';
import { PaymentService } from '../../../shared/services/payment.service';
import { FormControl, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { PaymentProcessType } from '../../../components/register/register.component';
import Swal from 'sweetalert2';
import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';
import { AuthenticationService } from '../../../shared/services';
import { HolderNameRegex } from '../../services/regex';
import { PaymentMethodModel } from 'src/app/models/payment.model';
import { Store } from '@ngrx/store';
import { UserPaymentCardsActions } from '../../store/_features/payments/payments.actions';
import { ToastrService } from 'ngx-toastr';

export enum ProcessingStatusEnum {
  none = 'none',
  processing = 'processing',
  success = 'success',
  error = 'error',
}

export enum StripeValidationErrorTypeEnum {
  card_number = 'card_number',
  card_expiry = 'card_expiry',
  card_cvc = 'card_cvc',
}

@Component({
  selector: 'app-stripepaymentsimple',
  templateUrl: './stripepaymentsimple.component.html',
  styleUrls: ['./stripepaymentsimple.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ 'max-height': '0px', minHeight: '0', opacity: 0 }),
        animate('0.4s ease-out', style({ 'max-height': '200px', opacity: 1 })),
      ]),
    ]),
  ],
})
export class StripepaymentSimpleComponent implements OnInit, OnDestroy {
  userPaymentCards: PaymentMethodModel[] = [];
  isChangingTheCard: boolean = false;
  isAddingNewMethod: boolean = false;

  selectedPaymentMethod: PaymentMethodModel;

  // Enums
  processingStatusEnum = ProcessingStatusEnum;
  stripeValidationErrorTypeEnum = StripeValidationErrorTypeEnum;

  // State
  isReadyForPayment: boolean = false;
  cardsLoaded: boolean = false;
  stripeInitialized: boolean[] = [];

  processing: ProcessingStatusEnum = ProcessingStatusEnum.none;
  // clientSecret: string;
  validationErrorType: StripeValidationErrorTypeEnum;
  validationErrorMessage: string;
  isCardFlipped = false;
  isPaymentValid = false;

  paymentErrorMessage: string;

  // Form Controls
  cardHolderElement = new FormControl('', [
    Validators.required,
    Validators.pattern(HolderNameRegex),
  ]);
  cardElement: StripeCardNumberElement;
  cardExpiryElement: StripeCardExpiryElement;
  cardCVCElement: StripeCardCvcElement;

  // Card status
  cardNumberStatus: boolean;
  cardExpiryStatus: boolean;
  cardCVCStatus: boolean;
  cardFieldsStatus = { number: false, expiry: false, cvc: false };

  // Card error arrays
  cardCVCErrors: string[] = []; // Initialize as needed
  cardExpiryErrors: string[] = []; // Initialize as needed
  cardNumberErrors: string[] = []; // Initialize as needed
  error: string;

  // Subscription
  payNowSubscription: Subscription;

  // UI Inputs
  @Input() showErrorMessages: boolean = false;
  // Inputs and Outputs
  @Input() bundleID: number; // Added missing bundleID
  @Input() strictAddingNewMethod: boolean = false;
  @Input() enableUseExistingCards: boolean = false;
  @Input() payNow: EventEmitter<any>;
  @Input() couponID = undefined;

  // Payment Events
  @Output() EnablePayment = new EventEmitter<boolean>();
  @Output() onLoadDone = new EventEmitter();
  @Output() onStartPayment = new EventEmitter();
  @Output() onFinishPayment = new EventEmitter();
  @Output() onErrorPayment = new EventEmitter<string>();

  // Emitters
  @Output() isPaymentValidEmitter = new EventEmitter<boolean>();
  @Output() focusing = new EventEmitter();

  // Address
  address = {
    city: undefined,
    state: undefined,
    country: undefined,
    line1: undefined,
    line2: undefined,
    postal_code: undefined,
  };

  // Countries
  countries: Country[];

  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private paymentService: PaymentService,
    private store: Store,
    private toastr: ToastrService
  ) {
    this.store
      .select(selectUserPaymentCards)
      .pipe(skipWhile((x) => !x))
      .subscribe((cards) => {
        console.log(cards);
        this.userPaymentCards = cards;
        if (cards.length < 1) {
          this.isAddingNewMethod = true;
          this.isChangingTheCard = false;
          this.enableUseExistingCards = false;
        }
        else {
          this.isAddingNewMethod = false;
          this.isChangingTheCard = false;
          this.enableUseExistingCards = true;
        }
      });

    this.store.select(isUserPaymentCardsLoaded).subscribe((loaded) => {
      console.log(loaded);
      this.cardsLoaded = loaded;
      this.checkReadyAndEmit();
    });
  }

  ngOnInit(): void {
    this.payNowSubscription = this.payNow?.subscribe((data) =>
      this.handlePayNow()
    );
    this.store.dispatch(UserPaymentCardsActions.load());
    this.loadStripeCard();
    this.getCountries();
  }

  ngOnDestroy(): void {
    this.payNowSubscription.unsubscribe();
  }

  // Country Management
  async onCountryChanged(value: string) {}

  private async getCountries() {
    try {
      const request$ = this.api.getCountries(this.handleGetCountriesError);
      this.countries = await lastValueFrom(request$);
    } catch (error) {
      this.handleGetCountriesError(error);
    }
  }

  handleGetCountriesError(error: HttpErrorResponse) {
    // Handle error accordingly
  }

  // Payment Submission
  private handlePayNow = () => {
    this.submitPaymentMethod();
  };

  submitPaymentMethod = async () => {
    // console.log('submitPaymentMethod', this.bundleID, this.isAddingNewMethod, this.strictAddingNewMethod); return;
    if (!!this.bundleID && !this.strictAddingNewMethod) {
      if (!!this.isAddingNewMethod) {
        await this.saveCardAndPay();
      } else {
        if (!!this.selectedPaymentMethod?.payment_id) {
          await this.payWithCard(this.selectedPaymentMethod.payment_id);
        } else {
          this.toastr.warning('Please select a payment method');
          return;
        }
      }
    } else {
      await this.saveCard();
    }
  };
  /**
   * @deprecated Use submitPaymentMethod instead
   */
  async submitPayment(paymentProcess: PaymentProcessType, pid?: string) {
    switch (paymentProcess) {
      case PaymentProcessType.save_only:
        await this.saveCard();
        break;
      case PaymentProcessType.save_and_register:
        await this.saveCard();
        break;
      case PaymentProcessType.just_pay:
        await this.payWithCard(pid);
        break;
      case PaymentProcessType.pay_and_save:
        await this.saveCardAndPay();
        break;
    }
  }

  // Card Operations
  saveCard = async (config: { emitDone: boolean } = { emitDone: true }) => {
    console.info('=======Calling Save Card======');
    this.onStartPayment.emit();
    this.processing = ProcessingStatusEnum.processing;

    const request$ = this.api.addPaymentMethod(
      this.addPaymentMethodErrorHandler
    );
    const response = await lastValueFrom(request$);

    const setupIntent = await this.confirmStripe(
      'cardSetup',
      response.clientSecret,
      config,
      {
        payment_method: {
          card: this.cardElement,
          billing_details: {
            name: this.cardHolderElement.value,
          },
        },
      }
    );

    return setupIntent;
  };

  saveCardAndPay = async () => {
    console.info('=======Calling Save Card And Pay======');
    this.onStartPayment.emit();
    const setupIntent = await this.saveCard({emitDone: false});
    await this.payWithCard((setupIntent as any).payment_method);
    // this.processing = ProcessingStatusEnum.processing;

    // const request$ = this.api.addPaymentMethod(
    //   this.bundleID,
    //   this.couponID,
    //   null,
    //   this.addPaymentMethodErrorHandler
    // );
    // const response = await lastValueFrom(request$);

    // const setupIntent = await this.confirmStripe(
    //   'cardSetup',
    //   response.clientSecret,
    //   {
    //     payment_method: {
    //       card: this.cardElement,
    //       billing_details: {
    //         name: this.cardHolderElement.value,
    //       },
    //     },
    //   }
    // );

    // console.log(setupIntent);
  };

  payWithCard = async (pid: string, typeOfPayment: 'main' | 'sub' = 'main') => {
    console.info('=======Calling Pay With Card======');
    this.onStartPayment.emit();
    this.processing = ProcessingStatusEnum.processing;

    const request$ = this.paymentService.subscriptionStripe(
      this.bundleID,
      pid,
      undefined,
      this.handleSubscribeUserError
    );
    const subscribe = await lastValueFrom(request$);

    await this.confirmStripe('cardPayment', subscribe.clientSecret);
  };

  async confirmStripe(
    mode: 'cardSetup' | 'cardPayment',
    clientSecret: string,
    config: { emitDone: boolean } = { emitDone: true },
    data?: ConfirmCardPaymentData,
    options?: ConfirmCardPaymentOptions,
  ) {
    console.log('mode', mode);
    console.log('Data', data);
    console.log('clientSecret', clientSecret);

    const confirmMethod =
      mode === 'cardSetup'
        ? this.paymentService.stripe.confirmCardSetup
        : this.paymentService.stripe.confirmCardPayment;

    return confirmMethod(clientSecret, data, options)
      .then(
        async ({
          error,
          paymentIntent,
          setupIntent,
        }: PaymentIntentOrSetupIntentResult) => {
          if (paymentIntent || setupIntent) {
            await this.delay(3000);
            if (config?.emitDone) {
              this.handlePaymentFinish();
              setTimeout(() => this.cardElement.clear(), 5000);
            }
            return paymentIntent || setupIntent;
          } else if (error || setupIntent?.last_setup_error) {
            const errorMessage =
              error?.message || setupIntent?.last_setup_error?.message;
            if (error?.type === 'validation_error') {
              this.setValidationErrorType(error);
            } else {
              this.handlePaymentError(errorMessage);
            }
          }
        }
      )
      .catch((error) => {
        this.handlePaymentError(error.message);
      });
  }

  handlePaymentError = (errorMessage: any) => {
    this.onErrorPayment.emit(errorMessage);
    this.paymentErrorMessage = errorMessage;
    this.processing = ProcessingStatusEnum.error;
  };
  handlePaymentFinish() {
    this.onFinishPayment.emit();
    this.processing = ProcessingStatusEnum.success;
  }

  delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  private setValidationErrorType(error: StripeError) {
    if (this.cardNumberErrors.includes(error.code)) {
      this.validationErrorType = StripeValidationErrorTypeEnum.card_number;
    } else if (this.cardExpiryErrors.includes(error.code)) {
      this.validationErrorType = StripeValidationErrorTypeEnum.card_expiry;
    } else if (this.cardCVCErrors.includes(error.code)) {
      this.validationErrorType = StripeValidationErrorTypeEnum.card_cvc;
    }
    this.validationErrorMessage = error.message;
    this.handlePaymentError(null);
  }

  addPaymentMethodErrorHandler = (error: any) => {
    // Handle payment method error
  };

  handleSubscribeUserError = (error: any) => {
    this.handlePaymentError(error.raw.message || error.message || error);
  };

  onSelectCardItem(paymentMethod: PaymentMethodModel) {
    this.selectedPaymentMethod = paymentMethod;
    this.EnablePayment.emit(true);
  }

  // UI Mode Management Functions
  backToDefaultMode() {
    this.changeSelectDiffrentCardMode(false);
    this.changeAddNewCardMode(false);
  }

  changeSelectDiffrentCardMode(mode: boolean = true) {
    this.isChangingTheCard = mode;
    this.EnablePayment.emit(!mode);
  }

  changeAddNewCardMode(mode: boolean = true) {
    this.isAddingNewMethod = mode;
  }

  // Stripe Elements Loading
  async loadStripeCard() {
    await this.paymentService.loadStripe();
    const elements = this.paymentService.stripe.elements();

    this.cardElement = await this.initializeCardElement(
      elements,
      'cardNumber',
      '#cardNumber',
      'number'
    );
    this.cardExpiryElement = await this.initializeCardElement(
      elements,
      'cardExpiry',
      '#cardExpiry',
      'expiry'
    );
    this.cardCVCElement = await this.initializeCardElement(
      elements,
      'cardCvc',
      '#cardCvc',
      'cvc'
    );
  }

  private async initializeCardElement(
    elements: StripeElements,
    type: any,
    mountId: string,
    fieldType: string
  ) {
    const initializedIndex = this.stripeInitialized.push(false) - 1;
    const checkElementExists = (id: string): boolean => {
      return !!document.getElementById(id);
    };

    const waitForElement = (
      id: string,
      interval: number = 100
    ): Promise<void> => {
      let realId = id.replace('#', '');
      return new Promise((resolve) => {
        const check = setInterval(() => {
          const checkResult = checkElementExists(realId);
          console.log(checkResult);
          if (checkResult) {
            clearInterval(check);
            resolve();
          }
        }, interval);
      });
    };

    await waitForElement(mountId);

    const element = elements.create(type);
    element.mount(mountId);
    element.on('change', (event: any) =>
      this.handleFieldChange(event, fieldType)
    );
    element.on('focus', () => this.handleFieldFocus(fieldType));
    element.on('blur', () => this.handleFieldBlur(fieldType));
    element.on('ready', () => {
      this.stripeInitialized[initializedIndex] = true;
      this.checkReadyAndEmit();
    });
    console.log(this.stripeInitialized);

    return element as any;
  }

  private handleFieldChange(event: any, fieldType: string) {
    this.focusing.emit();

    if (fieldType === 'number') {
      this.cardNumberStatus = event.complete;
      if (this.cardNumberStatus) this.cardExpiryElement.focus();
    } else if (fieldType === 'expiry') {
      this.cardExpiryStatus = event.complete;
      if (this.cardExpiryStatus) this.cardCVCElement.focus();
    } else if (fieldType === 'cvc') {
      this.cardCVCStatus = event.complete;
      if (this.cardCVCStatus) this.cardCVCElement.blur();
    }

    this.isPaymentValid =
      this.cardNumberStatus && this.cardExpiryStatus && this.cardCVCStatus;
    this.isPaymentValidEmitter.emit(this.isPaymentValid);
    this.cdr.markForCheck();
  }

  private handleFieldFocus(fieldType: string) {
    this.cardFieldsStatus[fieldType] = true;
    this.cdr.markForCheck();
  }

  private handleFieldBlur(fieldType: string) {
    this.cardFieldsStatus[fieldType] = false;
    this.cdr.markForCheck();
  }

  checkReadyAndEmit = () => {
    if (this.stripeInitialized.every((x) => x) && this.cardsLoaded) {
      this.onLoadDone.emit();
      this.EnablePayment.emit(true);
      this.isReadyForPayment = true;
    }
  };
}
