import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import GeneralIcons from './data/languages';
import languages from './data/languages';
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() currentValue: string;
  CSS_STARTER = 'lang-icon';
  languagesList: { value: string; label: string }[] = languages;
  multiple: boolean = false;
  @Input() placeholder: string = 'Select Language(s)';
  @Input('multiple') set setMultiple(value: boolean) {
    this.multiple = value;
    this.placeholder = this.multiple ? 'Select Language(s)' : 'Select Language';
  }

  @Output() valueChange = new EventEmitter<string>();
  // Select Settings
  
  constructor() {
    this.placeholder = this.multiple ? 'Select Language(s)' : 'Select Language';
  }

  ngOnInit() {}

  onSelectValue(value: string) {
    if (this.currentValue !== value) {
      this.valueChange.emit(value);
      this.currentValue = value;
    }
   
  }
}
