import { Component, OnInit } from '@angular/core';
import {
  PageStatusEnum,
  MyAffiliatesPageStatusEnum,
  SharedAffiliateService,
} from '../../../shared-affiliate.service';

@Component({
  selector: 'app-affiliate-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ViewComponent implements OnInit {
  urls: {
    name: string;
    url: string;
    stats?: { clicks?: number; conversions?: number; conversionsRate?: number };
  }[] = [
    {
      name: 'Affiliate Link #1',
      url: 'https://www.myinfluencerportal.com/offer/21ASSDA2w25WQ',
      stats: { clicks: 220, conversions: 200, conversionsRate: 1.5 },
    },
    {
      name: 'Affiliate Link #2',
      url: 'https://www.myinfluencerportal.com/offer/21ASSDA2w25WQ',
      stats: { clicks: 220, conversions: 200, conversionsRate: 1.5 },
    },
    {
      name: 'Affiliate Link #3',
      url: 'https://www.myinfluencerportal.com/offer/21ASSDA2w25WQ',
      stats: { clicks: 220, conversions: 200, conversionsRate: 1.5 },
    },
  ];

  constructor(public sharedAffiliateService: SharedAffiliateService) {
    this.sharedAffiliateService.goToMyAffiliatesTab(
      MyAffiliatesPageStatusEnum.viewDetails
    );
  }

  ngOnInit(): void {
    this.sharedAffiliateService.stopLoading();
  }
}
