import { MediaLibraryFileModel } from "./sales-funnel";

export class SystemUserBase {
    id: number;
    name: string;
    email: string;
    createdAt: number;
    avatar?: MediaLibraryFileModel[]
    avatar_id?: number[];
}

export class CustomerItem extends SystemUserBase {

}


