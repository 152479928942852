import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';
import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { User } from 'src/app/models';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services';
import { ProfileUiControlService } from '../../services/profile-ui-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalControlService } from '../../services/portal-control.service';

@Component({
  selector: 'si-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
})
export class TopMenuComponent implements OnInit {
  userDropdownOpen = false;
  static onUpdateProfileEvent: EventEmitter<any>;
  @Output() onStartTour = new EventEmitter();
  headerStuck = false;
  @Input() background = true;
  @Input() checkout = false;
  @Input() logoUrl = null;
  @Input() logoRouter = '/';
  @Input() disableLogo = false;
  @Input() onBoardingEnabled = false;

  public currentUser: User;
  inPortal: boolean = true;
  subscription: Subscription = undefined;
  constructor(
    private authenticationService: AuthenticationService,
    public profileUiService: ProfileUiControlService,
    private portalControlService: PortalControlService,
    private route: Router,
    private aroute: ActivatedRoute,
    public userDashboardService: UserDashboardService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService
  ) {
    this.userDashboardService.headerStickChange.subscribe((value) => {
      this.headerStuck = value
  });
  }


  ngOnInit() {
    if (this.route.url.includes('portal')) {
      this.inPortal = true;
    }

    this.currentUser = this.authenticationService.currentUserValue;
    // TopMenuComponent.onUpdateProfileEvent = new EventEmitter();
    // TopMenuComponent.onUpdateProfileEvent.subscribe(
    //   (a) => (this.currentUser = this.authenticationService.currentUserValue)
    // );
  }

  async repeatTour() {
    await this.route.navigate(['dashboard', 'portal']);
    this.onStartTour.emit();
  }

  toggleUserDropdown() {
    this.userDropdownOpen = !this.userDropdownOpen;
  }

  logout() {
    // this.portalControlService.canActivateLogin = true;
    this.authenticationService.logout();
  }



}
