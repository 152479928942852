// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-outline-fix-button:hover .svg-icon{
  fill: #fff !important;
}

.ip-outline-fix-button:focus {
  color: #007bff !important;
    background-color: transparent !important;
    border-color: #007bff !important;
}

.ip-solid-fix-button:focus {
  color: #FFFFFF !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}


.learmo-course-grid-item .course-price {
  position: absolute;
  top: 0;
  right: 15px;
  height: 80px;
  transform: translate(50%, -50%);
  width: 80px;
  border-radius: 50% !important;
  font-size: 1.2em;
  z-index: 2;
  text-align: center;
  box-shadow: var(--bs-ribbon-label-box-shadow);
  color: var(--bs-primary-inverse);
  display: flex;
  justify-content: center;
  align-items: center;
}

.learmo-course-grid-item .course-price {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


.learmo-course-grid-item .hover-truncated-description {
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/dynamic-items-card/dynamic-items-card.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;IACvB,wCAAwC;IACxC,gCAAgC;AACpC;;AAEA;EACE,yBAAyB;IACvB,oCAAoC;IACpC,gCAAgC;AACpC;;;AAGA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,WAAW;EACX,6BAA6B;EAC7B,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,6CAA6C;EAC7C,gCAAgC;EAChC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,4DAA4D;AAC9D;;;AAGA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB;AACvB","sourcesContent":[".ip-outline-fix-button:hover .svg-icon{\n  fill: #fff !important;\n}\n\n.ip-outline-fix-button:focus {\n  color: #007bff !important;\n    background-color: transparent !important;\n    border-color: #007bff !important;\n}\n\n.ip-solid-fix-button:focus {\n  color: #FFFFFF !important;\n    background-color: #007bff !important;\n    border-color: #007bff !important;\n}\n\n\n.learmo-course-grid-item .course-price {\n  position: absolute;\n  top: 0;\n  right: 15px;\n  height: 80px;\n  transform: translate(50%, -50%);\n  width: 80px;\n  border-radius: 50% !important;\n  font-size: 1.2em;\n  z-index: 2;\n  text-align: center;\n  box-shadow: var(--bs-ribbon-label-box-shadow);\n  color: var(--bs-primary-inverse);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.learmo-course-grid-item .course-price {\n  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n}\n\n\n.learmo-course-grid-item .hover-truncated-description {\n  transition: all 0.3s ease-in-out;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
