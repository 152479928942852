const ICONS = {
    category_name: 'Arrows',
    icons_list: [
      "exit-right-corner",
  "dots-circle-vertical",
  "check-square",
  "right-left",
  "arrow-down",
  "dots-horizontal",
  "arrow-right-left",
  "up-down",
  "double-check",
  "arrow-up-left",
  "down",
  "exit-up",
  "up-square",
  "down-square",
  "plus-square",
  "dots-circle",
  "arrow-down-left",
  "double-check-circle",
  "up",
  "entrance-right",
  "arrow-right",
  "arrow-two-diagonals",
  "minus-square",
  "arrow-diagonal",
  "black-left",
  "arrow-down-refraction",
  "black-right",
  "double-left",
  "arrow-circle-left",
  "arrow-zigzag",
  "plus",
  "check",
  "exit-left",
  "arrow-circle-right",
  "cross-square",
  "entrance-left",
  "left-square",
  "arrows-loop",
  "black-left-line",
  "double-left-arrow",
  "check-circle",
  "right",
  "dots-square-vertical",
  "arrow-up-right",
  "exit-down",
  "dots-square",
  "to-left",
  "double-down",
  "plus-circle",
  "black-down",
  "double-up",
  "black-up",
  "double-right-arrow",
  "arrow-up",
  "black-right-line",
  "arrow-up-refraction",
  "arrow-left",
  "cross",
  "minus-circle",
  "arrow-down-right",
  "exit-right",
  "to-right",
  "double-right",
  "arrow-mix",
  "right-square",
  "arrows-circle",
  "cross-circle",
  "left",
  "minus",
  "dots-vertical",
  "arrow-up-down"
    ],

};


export default ICONS
