import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  MediaLibraryCategoryEnum,
  MediaLibraryFileModel,
  MediaLibraryTypeModel,
  MediaLibraryUploadResponseModel,
  MediaLibraryUploadType,
} from 'src/app/models/sales-funnel';
import { ApiService } from '../../../shared/services/lsg.api.service';

import { UserDashboardService } from '../../../shared/services/user-dashboard.service';
import { lastValueFrom, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FilteringTypeModel } from '../../../models/portal';
import {
  trigger,
  transition,
  style,
  animate,
  state,
  keyframes,
  sequence,
  query,
  stagger,
} from '@angular/animations';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MoveTypes } from 'ngx-image-cropper/lib/interfaces/move-start.interface';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.css'],

})
export class MediaLibraryComponent implements OnInit {

  isUploadModalOpen: boolean = false;



  // Media library categories
  mediaLibraryCategoryEnum = MediaLibraryCategoryEnum;

  // Uploading status flags
  loaded = false;

  // Image dimension restrictions
  restrict: {
    width?: number;
    height?: number;
  };

  // File type filters
  filterFilesBy: string[] = [];

  // File upload form

  // Whether a file is required to be uploaded
  @Input() required = false;

  // Whether to display borders around images
  @Input() enableBorders = false;

  // Whether multiple files can be uploaded at once
  @Input() multiFiles = true;

  // Whether to view the files in the media library
  @Input() view = false;

  // Type of upload: any, content, media, video, image, audio
  @Input() uploadType: MediaLibraryUploadType | string = MediaLibraryUploadType.Media;

    @Input() imgSpecs: {
      width?: number;
      height?: number;
      ratio?: number;
    } = {
      width: null,
      height: null,
      ratio: null,
    };

  // Category of uploaded media
  @Input() uploadCategory: MediaLibraryCategoryEnum;

  // Selected files in the media library
  @Input() selectedFiles: MediaLibraryFileModel[] = [];

  // Temporary selected files in the media library
  @Input() selectedFilesTemp: MediaLibraryFileModel[] = [];

  // Temporary selected file IDs in the media library
  @Input() selectedFilesTempIds: number[] = [];

  // Selected file IDs in the media library
  @Input() selectedFilesIds: number[] = [];

  // Label for the upload field
  @Input() uploadFieldLabel: string = 'Upload Content';

  @Output() selectedFilesChange: EventEmitter<MediaLibraryFileModel[]> =
    new EventEmitter<MediaLibraryFileModel[]>();
  @Output() selectedFilesIdsChange: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  mediaStorage: MediaLibraryFileModel[][] = [];
  mediaLibraryFiles: MediaLibraryFileModel[] = [];
  mediaLibraryTypes: MediaLibraryTypeModel[] = [];

  uploadToast: any;
  uploadText: string = 'File Upload';


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private route: Router,
    public userDashboardService: UserDashboardService,
    private api: ApiService,
    private toastr: ToastrService,
    private _httpClient: HttpClient
  ) {
    this.addMediaFileErrorHandler = this.addMediaFileErrorHandler.bind(this);
  }

  ngOnInit(): void {
    this.generateUploadText();
    // if (this.selectedFiles?.length > 0) {
    // this.selectedFilesIds = this.selectedFiles
    //     .slice()
    //     .map((value) => value.id);
    // }
  }

  openMediaLibrary() {
    this.isUploadModalOpen = true;
  }



  generateUploadText() {
    const uploadTypeToText = {
      video: 'Video Upload',
      image: 'Image Upload',
      audio: 'Audio Upload',
      any: 'File Upload',
      media: 'Media Upload',
      content: 'Content Upload',
    };

    if (!!this.uploadFieldLabel) {
      this.uploadText = `${this.uploadFieldLabel} Upload`;
    } else {
      this.uploadText = uploadTypeToText[this.uploadType] || '';
    }
  }

  addMediaFileErrorHandler(error: any) {
    this.dismissUploadToast();
    this.toastr.error('Cannot upload this file', 'Upload Error!', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }


  uploadingWithCountToast(count: number) {
    this.uploadToast = this.toastr.info(
      `Uploading ${count} ${count > 1 ? 'Files' : 'File'}`,
      'Processing!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
        toastClass: 'shadow-none ngx-toastr bg-primary text-white',
      }
    );
  }

  dismissUploadToast() {
    this.toastr.remove(this.uploadToast.toastId);
  }
}
