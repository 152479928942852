import { ProductItem } from '../../../../components/user-dashboard/product/models/product';
import { SharedProductService } from '../../../../components/user-dashboard/product/shared-product.service';
import { Router } from '@angular/router';
import { SharedOfferService } from '../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { listAnimation } from 'src/app/shared/animations/animations';
import { StudentItem } from 'src/app/components/user-dashboard/product/models/course';
import { LocalPaginationConfig, PeriodResultModel, RefundItem, RefundStatusEnum } from 'src/app/models/owner-dashboard';
import { SalesLogElement } from 'src/app/models/system-earnings';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    listAnimation,
  ],
})
export class RefundListComponent implements OnInit {
  @Input() currentPeriod:PeriodResultModel; 
  @Input() refundList: RefundItem[] = [

  ];
  @Input() loaded: boolean = false;

  paginationConfig: LocalPaginationConfig = new LocalPaginationConfig({
    currentPage : 1,
    startIndex : 0,
    numberInPage : 8,
    endIndex : 8,
  });
  // Pagination


  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    filters: [],
  };
  filterTypes: FilteringTypeModel[] = [
    {
      index: 0,
      displayName: 'All',
      filters: [],
    },
    {
      index: 1,
      displayName: 'Action Required',
      filters: [{ column: 'status', value: RefundStatusEnum.actionRequired }],
    },
    {
      index: 2,
      displayName: 'Refused',
      filters: [{ column: 'status', value: RefundStatusEnum.Refused }],
    },
    {
      index: 3,
      displayName: 'Refunded',
      filters: [{ column: 'status', value: RefundStatusEnum.Refunded }],
    },
  ];

  

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdAt',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
    { displayName: 'A-Z Title', column: 'name', order: 'asc' },
    { displayName: 'Z-A Title', column: 'name', order: 'dsc' },
  ];

  constructor(
    public sharedProductService: SharedProductService,
    private router: Router
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  async onPeriodChange(value: PeriodResultModel) {
    // this.sharedEarningsService.systemEarningsPageData.period = value;
    // 

    // this.sharedEarningsService.cardLoading = true;
    // await this.sharedEarningsService.getSystemEarnings({
    //   period: value.period.enum,
    // });
    // this.sharedEarningsService.cardLoading = false;
    // this.loaded = false;
    // await this.sharedEarningsService.getEarningsSalesLogDetailed(value);
    // this.loaded = true;
  }


  filterTypeChange(filterObject: FilteringTypeModel) {
    //
    this.filterObject = filterObject;
    // this.resetPagination();
  }
  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  deleteProduct(product: ProductItem) {
    this.sharedProductService.deleteProductAlert(product);
  }

  cloneProduct(product: ProductItem) {
    this.sharedProductService.cloneProductAlert(product);
  }

  viewProduct(id: number) {
    this.router.navigate(['dashboard', 'product', id, 'view']);
  }

  editProduct(id: number) {
    this.router.navigate(['dashboard', 'product', id, 'edit']);
  }

  goToTutorials() {
    this.router.navigate(['/dashboard/tutorials']);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}

}
