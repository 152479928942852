import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { loadCustomScript } from '@paypal/paypal-js';
import { lastValueFrom } from 'rxjs';
import { BtcpayPaymentGatewayModel, PaymentGatewaysIntegration, PaypalPaymentGatewayModel } from 'src/app/models/portal';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-btcpay-integration-form',
  templateUrl: './btcpay-integration-form.component.html',
  styleUrls: ['./btcpay-integration-form.component.css'],
})
export class BtcpayIntegrationFormComponent implements OnInit {
  isError: string;
  paypalStorageKey = environment.storageKeys.paypalIntegration;
  processing = false;
  integratePaymentForm: FormGroup = new FormGroup({
    btcPayUrl: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    apiKey: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    storeId: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });


  constructor(private api: ApiService, private toastr: ToastrService) {
    this.integratePaymentForm.valueChanges.subscribe((a) => {
      this.isError = null;
    });
  }

  ngOnInit() {
    
  }



  async submitForm() {
    this.integratePaymentForm.markAllAsTouched();
    if (!this.integratePaymentForm.valid) {
      return false;
    }

    let payload:BtcpayPaymentGatewayModel = {
      btcPayUrl: this.integratePaymentForm.get('btcPayUrl').value,
      apiKey: this.integratePaymentForm.get('apiKey').value,
      storeId: this.integratePaymentForm.get('storeId').value
    }

    await this.integrationRequest({ btcPay: payload });
  }
  
  async integrationRequest(payload: PaymentGatewaysIntegration) {
    this.processing = true;
    this.integratePaymentForm.disable();
    const request$ = this.api.integratePaymentGateways(
      payload,
      this.onError
    );
    const response: { success: boolean } = await lastValueFrom(request$);
    if (response.success) {
      this.onSuccess();
    }
  }
  

  async onSuccess() {

    // await this.sharedPortalViewService.goToPayments();
    this.toastr.success(
      `Paypal payment gateway integrated successfully`,
      'Done!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );
    // await this.sharedPortalViewService.getPaymentMethods();
    setTimeout(() => {
      this.processing = false;
    }, 500);
    this.integratePaymentForm.enable();
  }

  onError = (error: any) => {
    this.integratePaymentForm.enable();
    this.processing = false;
    // this.startEdited('offer', 'edited');
    this.toastr.error(
      `Paypal payment gateway doesn't integrated`,
      'Error!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );

    this.isError = error?.message;
  };

  // Auto Payment Functions
  async goData(data: BtcpayPaymentGatewayModel) {
    const request$ = this.api.integratePaymentGateways(
      { btcPay: data },
      this.onError
    );
    const request = await lastValueFrom(request$);
    localStorage.removeItem('btcPayData');
    this.onSuccess();
  }
  goToBTCPAYAuthorization() {
    // window.location.href = `${this.btcpayObject.btcPayUrl}/api-keys/authorize?permissions=btcpay.store.canviewinvoices&permissions=btcpay.store.cancreateinvoice&permissions=btcpay.store.webhooks.canmodifywebhooks&permissions=btcpay.store.canviewstoresettings&permissions=btcpay.store.canmodifyinvoices&applicationName=learmo&strict=true&selectiveStores=true&redirect=https://localhost:4200/dashboard/portal/view/2232221512/payments/btcPay/integrate`;
  }
}
