// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-invitation {
  background-color: #DDDFF8 !important;
  color: #25375F !important;
}


.label-affiliate-link-add-update {
  background-color: #F8DDF8 !important;
  color: #5F2552 !important;
}


.label-affiliate-link-paused {
  background-color: #D7D7D7 !important;
  color: #484848 !important;
}

.label-affiliate-link-resumed {
  background-color: #DDF8E6 !important;
  color: #255F27 !important;
}

.label-new-sale {
  background-color: #DDF8E6 !important;
  color: #255F27 !important;
}

.label-commission-processed {
  background-color: #DDE9F8 !important;
  color: #253E5F !important;
}

.label-refund {
  background-color: #F8DDDD !important;
  color: #5F2526 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/top-menu/fast-actions-buttons/affiliate-notifications-list/affiliate-notification-element/aff-noti-type-label/aff-noti-type-label.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;;AAGA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;;AAGA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B","sourcesContent":[".label-invitation {\n  background-color: #DDDFF8 !important;\n  color: #25375F !important;\n}\n\n\n.label-affiliate-link-add-update {\n  background-color: #F8DDF8 !important;\n  color: #5F2552 !important;\n}\n\n\n.label-affiliate-link-paused {\n  background-color: #D7D7D7 !important;\n  color: #484848 !important;\n}\n\n.label-affiliate-link-resumed {\n  background-color: #DDF8E6 !important;\n  color: #255F27 !important;\n}\n\n.label-new-sale {\n  background-color: #DDF8E6 !important;\n  color: #255F27 !important;\n}\n\n.label-commission-processed {\n  background-color: #DDE9F8 !important;\n  color: #253E5F !important;\n}\n\n.label-refund {\n  background-color: #F8DDDD !important;\n  color: #5F2526 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
