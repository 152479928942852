// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-container {
  /* background-color: #FBFBFB;
  border-radius: 0 0 35px 35px; */
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/steps-ui-view/steps-ui-view.component.css"],"names":[],"mappings":"AAAA;EACE;iCAC+B;AACjC","sourcesContent":[".steps-container {\n  /* background-color: #FBFBFB;\n  border-radius: 0 0 35px 35px; */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
