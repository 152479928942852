// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-list {
    max-height: 70vh;
  overflow: auto;
  min-height: -moz-fit-content;
  min-height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notifications-list.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB,cAAc;EACd,4BAAuB;EAAvB,uBAAuB;AACzB","sourcesContent":[".notifications-list {\n    max-height: 70vh;\n  overflow: auto;\n  min-height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
