import { MediaLibraryFileModel } from './sales-funnel';
export class BlogPostItem {
    id: number;
    title: string;
    status: BlogItemStatusEnum;
    content: string;
    public url: string;
    image: MediaLibraryFileModel[];
    imageId: number[];
    createdAt: number;
    updatedAt: number;
}

export enum BlogItemStatusEnum {
    published = 'publish',
    draft = 'draft',
  }