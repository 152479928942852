// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import {
  ProductsActions,
  deleteProduct,
  CurrentProductActions,
  updateCurrentProductActions,
  productOfferActions,
  createNewProductActions,
  clearCurrentProduct,
} from './products.actions';
import { ProductItem } from 'src/app/components/user-dashboard/product/models/product';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { forgetSession } from '../user/auth/auth.actions';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import {
  cloneOfferActions,
  createNewOfferActions,
  deleteOfferActions,
  startOfferLoadingActions,
  updateOfferActions,
  updateOfferStatusActions,
} from '../offers/offers.actions';

export interface ProductsOfferState extends EntityState<OfferItem> {}
export interface ProductsState extends EntityState<ProductItem> {
  currentProduct: ProductItem;
  currentProductOffers: ProductsOfferState;
  currentProductProcess: { loaded: boolean; status: SystemProcessLoadingStatus; error: any };
  
  createNewProductProcess: {
    status: SystemProcessLoadingStatus;
    error: any;
    lastCreatedProduct: ProductItem;
  };
  cloneOfferProcess: { status: SystemProcessLoadingStatus; error: any };
  loaded: boolean;
  error: any;
}

export const productAdapter = createEntityAdapter<ProductItem>();
export const productOfferAdapter = createEntityAdapter<OfferItem>();

const initialState: ProductsState = productAdapter.getInitialState({
  currentProduct: undefined,
  currentProductOffers: productOfferAdapter.getInitialState(),
  currentProductProcess: { loaded: false, status: 'none', error: null },
  // currentProductLoaded: {status: 'none', error: null}, 
  createNewProductProcess: {
    status: 'none',
    error: undefined,
    lastCreatedProduct: undefined,
  },
  cloneOfferProcess: { status: 'none', error: undefined },
  loaded: false,
  error: null,
});

export const productsReducer = createReducer(
  initialState,

  // ? Products List Related
  on(ProductsActions.load, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ProductsActions.loadSuccess, (state, { products }) => {
    return productAdapter.setAll(products, { ...state, loaded: true });
  }),
  on(ProductsActions.loadFailure, (state, { error }) => {
    if (typeof error !== 'string') {
      return state;
    }

    return { ...state, error, loaded: false };
  }),

  on(createNewProductActions.create, (state, { createPayload }) => {
    return {
      ...state,
      createNewProductProcess: {
        status: 'loading',
        error: undefined,
        lastCreatedProduct: undefined,
      },
    };
  }),
  on(createNewProductActions.createSuccess, (state, { product }) => {
    console.log('Added Product', product);
    return productAdapter.addOne(product, {
      ...state,
      createNewProductProcess: {
        status: 'done',
        error: undefined,
        lastCreatedProduct: product,
      },
    });
  }),
  on(createNewProductActions.createFailure, (state, { error }) => {
    return {
      ...state,
      createNewProductProcess: {
        status: 'error',
        error: error.message,
        lastCreatedProduct: undefined,
      },
    };
  }),

  // ! Current Product Related Actions​
  // ? Current Product Management
  on(CurrentProductActions.load, (state) => {
    return {
      ...state,
      currentProductProcess: { loaded: false ,status: 'none', error: null },
    };
  }),
  on(CurrentProductActions.loadSuccess, (state, { product }) => {
    return {
      ...state,
      currentProduct: product,
      currentProductProcess: { loaded: true,status: 'none', error: null },
      currentProductOffers: productOfferAdapter.setAll(
        product.offers,
        state.currentProductOffers
      ),
    };
  }),
  on(CurrentProductActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    currentProductProcess: { loaded: false,status: 'none', error: error.message },
  })),

  // ? Current Product Update
  on(updateCurrentProductActions.update, (state) => ({
    ...state,
    currentProduct: { ...state.currentProduct, updating: 'loading' },
  })),
  on(updateCurrentProductActions.updateSuccess, (state, { product }) => ({
    ...state,
    currentProduct: { ...product, updating: 'done' },
  })),
  on(updateCurrentProductActions.updateFailure, (state, { error }) => ({
    ...state,
    currentProduct: {
      ...state.currentProduct,
      updating: 'error',
      errorMessage: error.message,
    },
  })),

  // ! Offers Related Actions​

  on(cloneOfferActions.clone, (state, { productId, offerId }) => {
    if (productId == state.currentProduct?.id) {
      return {
        ...state,
        cloneOfferProcess: { status: 'loading', error: null },
      };
    }
    return state;
  }),

  // on(cloneOfferSuccess, (state, {productId, offerId}) =>
  // {
  //   return {...state, cloneOfferProcess: {status: 'done', error: null}};
  // }),

  on(cloneOfferActions.cloneFailure, (state, { error }) => {
    return {
      ...state,
      cloneOfferProcess: { status: 'error', error: error.message },
    };
  }),

  // ? Update Offer Status
  on(
    updateOfferActions.updateSuccess,
    (state, { offerId, productId, changes }) => {
      if (productId == state.currentProduct?.id) {
        return {
          ...state,
          currentProductOffers: productOfferAdapter.updateOne(
            { id: offerId, changes: { ...changes, updating: 'done' } },
            state.currentProductOffers
          ),
        };
      }
      return state;
    }
  ),

  on(productOfferActions.setOfferSuccess, (state, { offer }) => {
    const updatedOffer = { ...offer, isDefault: true, updating: 'done' };
    const otherOffersChanges = (state.currentProductOffers.ids as number[])
      .filter((id) => id !== offer.id)
      .map((id) => ({ id, changes: { isDefault: false } }));

    return {
      ...state,
      currentProductOffers: productOfferAdapter.updateMany(
        [{ id: offer.id, changes: updatedOffer }, ...otherOffersChanges],
        state.currentProductOffers
      ),
    };
  }),

  on(productOfferActions.setOfferFailure, (state, { error, offerId }) => {
    const updatedOffer: Partial<OfferItem> = {
      updating: 'error',
      errorMessage: error.message,
    };

    return {
      ...state,
      currentProductOffers: productOfferAdapter.updateOne(
        { id: offerId, changes: updatedOffer },
        state.currentProductOffers
      ),
    };
  }),

  on(
    startOfferLoadingActions.startOffer,
    (state, { offerId, loadingStatus }) => {
      const offerFromCollection = state.currentProductOffers.entities[offerId];
      if (!!offerFromCollection) {
        return {
          ...state,
          currentProductOffers: productOfferAdapter.updateOne(
            {
              id: offerId,
              changes: { ...offerFromCollection, updating: loadingStatus },
            },
            state.currentProductOffers
          ),
        };
      }
      return state;
    }
  ),

  // ? Add Offer to current product offers list, state collection
  on(createNewOfferActions.createSuccess, (state, { offer, productId }) => {
    const isCurrentProduct = state.currentProduct?.id === productId;

    if (isCurrentProduct) {
      const currentProductUpdatedOffersList = [
        offer,
        ...(state.currentProduct?.offers || []),
      ];

      return {
        ...state,
        currentProductOffers: productOfferAdapter.addOne(
          offer,
          state.currentProductOffers
        ),
        currentProduct: {
          ...state.currentProduct,
          offerCount: (state.currentProduct?.offerCount || 0) + 1,
          offers: currentProductUpdatedOffersList,
        },
        cloneOfferProcess: { status: 'done', error: null },
      };
    }

    return state;
  }),

  // ? Add Offer to products list item object
  on(createNewOfferActions.createSuccess, (state, { offer, productId }) => {
    const targetProductFromCollection = state.entities[productId];

    if (!!targetProductFromCollection) {
      return productAdapter.updateOne(
        {
          id: productId,
          changes: {
            offers: [offer, ...(targetProductFromCollection.offers || [])],
            offerCount: (targetProductFromCollection.offerCount || 0) + 1,
          },
        },
        { ...state, cloneOfferProcess: { status: 'done', error: null } }
      );
    }

    return state;
  }),

  // ! Delete Offer from current product offers list, state collection and current product
  on(deleteOfferActions.deleteSuccess, (state, { offerId, productId }) => {
    const isCurrentProduct = state.currentProduct?.id === productId;

    if (isCurrentProduct) {
      const currentProductUpdatedOffersList = (
        state.currentProduct?.offers || []
      ).filter((o) => o.id !== offerId);

      return {
        ...state,
        currentProductOffers: productOfferAdapter.removeOne(
          offerId,
          state.currentProductOffers
        ),
        currentProduct: {
          ...state.currentProduct,
          offerCount: (state.currentProduct?.offerCount || 0) - 1,
          offers: currentProductUpdatedOffersList,
        },
      };
    }

    return state;
  }),

  // ! Delete Offer from products list item object
  on(deleteOfferActions.deleteSuccess, (state, { offerId, productId }) => {
    const targetProductFromCollection = state.entities[productId];

    if (!!targetProductFromCollection) {
      const targetProductUpdatedOffersList = (
        targetProductFromCollection.offers || []
      ).filter((o) => o.id !== offerId);

      return productAdapter.updateOne(
        {
          id: productId,
          changes: {
            offers: targetProductUpdatedOffersList,
            offerCount: (targetProductFromCollection.offerCount || 0) - 1,
          },
        },
        state
      );
    }

    return state;
  }),

  on(deleteProduct, (state, { product }) =>
    productAdapter.removeOne(product.id, { ...state, loading: false })
  ),
  on(forgetSession, clearCurrentProduct, (state) => initialState)
);
