import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationsService,
  NotificationUserTypeEnum,
} from '../../../../../services/notifications.service';

import { lastValueFrom } from 'rxjs';
import { AffiliateApi } from '../../../../../services/affiliate.api.service';
import { TimePipe } from 'src/app/shared/pipes/dateago.pipe';
import { NotificationStatusEnum, AffiliateNotificationElement } from 'src/app/models/affiliates-dashboard';

@Component({
  selector: 'app-affiliate-announcements-element',
  templateUrl: './affiliate-announcements-element.component.html',
  styleUrls: ['./affiliate-announcements-element.component.css'],
})
export class AffiliateAnnouncementElementComponent implements OnInit {
  affiliateNotificationStatusEnum = NotificationStatusEnum;
  notificationUserTypeEnum = NotificationUserTypeEnum;
  @Input() forToast: boolean = false;
  @Input() firstElement: boolean = false;
  @Input() notificationObject: AffiliateNotificationElement;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public affiliateApi: AffiliateApi,
    public notificationService: NotificationsService
  ) {}

  onClickAnnouncement() {
    this.router.navigate([
      'affiliate',
      'announcements',
      this.notificationObject.target.announcementId,
    ]);
  }

  getNotificationTimeAgo(value: number) {
    const timePipe = new TimePipe();
    let timeAgo: string = timePipe.transform(value * 1000);
    return timeAgo;
  }

  // async markNotificationAsRead() {
  //   let request$ = this.affiliateApi.markNotificationAsRead(
  //     this.notificationObject.id
  //   );
  //   let response = await lastValueFrom(request$);
  //   this.notificationObject.status = NotificationStatusEnum.read;
  // }

  goToNetworkById(id: number) {
    this.router.navigate(['affiliate', 'network', id]);
  }

  ngOnInit() {}
}
