import { Component } from '@angular/core';

@Component({
  selector: 'app-global-search-history',
  templateUrl: './global-search-history.component.html',
  styleUrls: ['./global-search-history.component.css']
})
export class GlobalSearchHistoryComponent {

}
