import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { lastValueFrom } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  PageStatusEnum,
  SharedAffiliateService,
} from '../../shared-affiliate.service';
import { ProductCreateModel } from '../../../product/models/product';

export enum AffiliateLinkTypeEnum {
  vip = 'vip',
  general = 'general',
  none = 'none',
}
@Component({
  selector: 'app-affiliate-link-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CreateComponent implements OnInit {
  affiliateLinkTypeEnum = AffiliateLinkTypeEnum;
  linkTypes: {
    name: string;
    description: string;
    image: string;
    slug: AffiliateLinkTypeEnum;
  }[] = [
    {
      name: 'General',
      description:
        'General Affiliate link can have 1 or 2 levels of affiliate commissions.',
      image: 'assets/images/svg/affiliate/general-thumb.svg',
      slug: AffiliateLinkTypeEnum.general,
    },
    {
      name: 'VIP',
      description:
        'VIP Affiliate link can have 1 or 2 levels of affiliate commissions, and have an additional affiliate comission for 1 VIP affiliate of your choice.',
      image: 'assets/images/svg/affiliate/vip-thumb.svg',
      slug: AffiliateLinkTypeEnum.vip,
    },
  ];
  contentSelectedFiles: number[] = [];
  ImageSelectedFiles: number[] = [];

  productObject: ProductCreateModel = new ProductCreateModel();
  // Form Variables
  createProductForm = new UntypedFormGroup({
    productName: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    productDescription: new UntypedFormControl('', { updateOn: 'change' }),
  });

  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private modalService: NgbModal,
    private route: Router,

    private toastr: ToastrService
  ) {
    this.sharedAffiliateService.goToTab(PageStatusEnum.none);
  }

  ngOnInit(): void {
    this.sharedAffiliateService.getUserOffers();
    this.sharedAffiliateService.getMyAffiliates();
  }

  goBack() {
    this.route.navigate(['dashboard', 'affiliate', 'links']);
  }

  onOpenModal() {
    this.resetForm();
    this.productObject.type_id = 1;
  }

  resetForm() {
    this.productObject = new ProductCreateModel();
    this.createProductForm.reset();
  }

  async createNewProduct() {}

  onProductCreateDone() {
    this.route.navigate(['dashboard', 'product']);
    this.modalService.dismissAll();
    this.toastr.success('Done!', 'Product Created Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  createProductErrorHandler(error: any) {
    alert(error.message);
  }

  open(content) {
    this.onOpenModal();
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
}
