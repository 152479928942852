import { CheckDomainIfExistResult } from './../../../dto/portal.output';
import { ApiService } from 'src/app/shared/services';
import {
  checkSiteNameRegex,
  domainRegex,
} from './../../../../../../shared/services/regex';
import { SharedPortalViewService } from './../../shared-portal-view.service';
import { UserWebsite, WebsiteModel } from './../../../portal.model';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DomainItem } from 'src/app/models/domain';

export enum SubdomainCheckStatusEnum {
  none = 'none',
  loading = 'loading',
  available = 'available',
  unavailable = 'unavailable',
}

@Component({
  selector: 'app-edit-portal-subdomain',
  templateUrl: './edit-portal-subdomain.component.html',
  styleUrls: ['./edit-portal-subdomain.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class EditPortalSubdomainComponent implements OnInit {
  subdomainCheckStatusEnum = SubdomainCheckStatusEnum;
  subdomainCheckStatus: SubdomainCheckStatusEnum =
    SubdomainCheckStatusEnum.none;
  portalSubdomainConfigurationForm = new FormGroup({
    subdomain: new FormControl(null, {
      validators: [Validators.required, Validators.pattern(domainRegex)],
      updateOn: 'change',
    }),
  });

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() website: WebsiteModel

  subdomain: DomainItem;
  // offerSettingsForm = new FormGroup({
  //   isDefault: new FormControl('', {
  //     updateOn: 'change',
  //   }),
  //   parentPortal: new FormControl('', { updateOn: 'change' }),
  //   iConfirm: new FormControl('', { updateOn: 'change' }),
  // });

  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    await this.initializePopup();
    this.portalSubdomainConfigurationForm.controls.subdomain.valueChanges.subscribe(
      (value) => {
        this.subdomainCheckStatus = SubdomainCheckStatusEnum.none;
        this.checkSubdomainAvailability(value);
      }
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  compareWith(item, selected) {
    return +item.siteId === +selected.siteId;
  }

  async initializePopup() {
    this.subdomainCheckStatus = SubdomainCheckStatusEnum.none;
    this.subdomain = JSON.parse(JSON.stringify(this.sharedPortalViewService.currentWebsite.website.domains.subdomain));
    this.portalSubdomainConfigurationForm.controls.subdomain.setValue(
      this.subdomain.info.name,
      { emitEvent: false }
    );
  }
  async afterOpenModal() {
    await this.initializePopup();
  }

  async updateSettings() {
    this.portalSubdomainConfigurationForm.markAllAsTouched();
    if (!!this.portalSubdomainConfigurationForm.valid) {
      let domain = this.portalSubdomainConfigurationForm.value.subdomain;

      await this.sharedPortalViewService.changePortalSubdomain(domain);
      this.closeModal();
    }
  }

  async checkSubdomainRequest(
    subdomain: string,
    portalId: number = this.sharedPortalViewService.currentWebsite.id
  ) {
    const subdomainCheck$ = this.api.checkSubdomainAvailability(
      portalId,
      subdomain,
      this.onError
    );
    const subdomainCheck: CheckDomainIfExistResult = await lastValueFrom(
      subdomainCheck$
    );

    return subdomainCheck;
  }

  async checkSubdomainAvailability(
    subdomain: string = this.portalSubdomainConfigurationForm.value.subdomain
  ) {
    this.subdomainCheckStatus = SubdomainCheckStatusEnum.loading;
    let result = await this.checkSubdomainRequest(subdomain);

    if (!result.isExist) {
      this.onAvailible();
    } else {
      this.onError();
    }
  }

  onAvailible = () => {
    this.subdomainCheckStatus = SubdomainCheckStatusEnum.available;
  };

  onError = () => {
    this.subdomainCheckStatus = SubdomainCheckStatusEnum.unavailable;
  };

  onChangeDefault(value: any) {
    // if (
    //   this.offerSettings.parentPortal.siteId !=
    //   this.sharedOfferService.offer?.parentPortal?.siteId
    // ) {
    //   if (!!value) {
    //     this.confirmationRequired = true;
    //   } else {
    //     this.confirmationRequired = false;
    //   }
    // } else {
    //   if (!!this.sharedOfferService.offer.isDefault) {
    //     this.confirmationRequired = false;
    //   } else {
    //     if (!!value) {
    //       this.confirmationRequired = true;
    //     } else {
    //       this.confirmationRequired = false;
    //     }
    //   }
    // }
  }
  async onOpenModal() {}
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
