import { SharedEarningsService } from './../../../shared-earnings.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { BrowserNameEnum, DeviceTypeEnum, OperatingSystemEnum } from 'src/app/components/profile/security-settings/active-sessions-section/active-session-item/active-session-item.component';
import { Payment_Gateway_Enum } from 'src/app/models/portal';
import { SalesLogDetailedElement } from 'src/app/models/system-earnings';
import { PaymentMethodViewTypeEnum } from 'src/app/shared/partials/system-payment-method-view/system-payment-method-view.component';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-sales-item-view',
  templateUrl: './sales-item-view.component.html',
  styleUrls: ['./sales-item-view.component.css'],
})
export class SalesItemViewComponent implements OnInit {
  browserNameEnum = BrowserNameEnum;
  deviceTypeEnum = DeviceTypeEnum;
  operatingSystemEnum = OperatingSystemEnum;
  loaded: boolean = false;
  paymentMethodViewTypeEnum = PaymentMethodViewTypeEnum;
  salesPaymentMethodEnum = Payment_Gateway_Enum;
  // saleItem: SalesLogDetailedElement;

  itemId: number;
  @Input('itemId') set saleItemId(id: number) {
    if (!!id) {
      this.itemId = id;
      this.getSaleItemById(id);
    }
  }

  constructor(public api: ApiService, private route: ActivatedRoute, public sharedEarningsService: SharedEarningsService) {

    // const id = +this.route.snapshot.paramMap.get('id');
    
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!this.itemId){
      const id = params['id'];
      if (!!id) {
        this.itemId = id;
        this.getSaleItemById(id);
      }
    }
    });
  }

  async getSaleItemById(id: number = this.itemId) {
    this.loaded = false;
    let request$ = this.api.getSaleItemById(id);
    let response = await lastValueFrom(request$);
    this.sharedEarningsService.currentSalesItem = response;
    this.loaded = true;
  }
}
