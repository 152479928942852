// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-item.active .stepper-icon {
  background: var(--bs-primary);
}

.stepper-item.active .stepper-number {
    color: var(--bs-primary-light) !important;
}


.stepper-item.done .stepper-icon {
  background: var(--bs-success-light) !important;
}

.stepper-item.done .stepper-number {
    color: var(--bs-success);
}


.stepper-item.done.active .stepper-icon {
  background-color: var(--bs-success) !important;
}

.stepper-item.done.active .stepper-number {
    color: var(--bs-success-light) !important;
}


.stepper-item.dummy .stepper-icon {
  background: var(--bs-secondary-light) !important;
}

.stepper-item.dummy .stepper-number {
    color: var(--bs-secondary);
}

/* .svg-success {
  fill: #00af76 !important;
} */


.stepper-line-horizontal {
  display: block;
    flex-grow: 1;
    margin-top: calc(var(--bs-stepper-pills-size) / 2);
    border-top: var(--bs-stepper-line-border);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/partials/wizard-step-item/wizard-step-item.component.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;IACI,yCAAyC;AAC7C;;;AAGA;EACE,8CAA8C;AAChD;;AAEA;IACI,wBAAwB;AAC5B;;;AAGA;EACE,8CAA8C;AAChD;;AAEA;IACI,yCAAyC;AAC7C;;;AAGA;EACE,gDAAgD;AAClD;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;;GAEG;;;AAGH;EACE,cAAc;IACZ,YAAY;IACZ,kDAAkD;IAClD,yCAAyC;AAC7C","sourcesContent":[".stepper-item.active .stepper-icon {\n  background: var(--bs-primary);\n}\n\n.stepper-item.active .stepper-number {\n    color: var(--bs-primary-light) !important;\n}\n\n\n.stepper-item.done .stepper-icon {\n  background: var(--bs-success-light) !important;\n}\n\n.stepper-item.done .stepper-number {\n    color: var(--bs-success);\n}\n\n\n.stepper-item.done.active .stepper-icon {\n  background-color: var(--bs-success) !important;\n}\n\n.stepper-item.done.active .stepper-number {\n    color: var(--bs-success-light) !important;\n}\n\n\n.stepper-item.dummy .stepper-icon {\n  background: var(--bs-secondary-light) !important;\n}\n\n.stepper-item.dummy .stepper-number {\n    color: var(--bs-secondary);\n}\n\n/* .svg-success {\n  fill: #00af76 !important;\n} */\n\n\n.stepper-line-horizontal {\n  display: block;\n    flex-grow: 1;\n    margin-top: calc(var(--bs-stepper-pills-size) / 2);\n    border-top: var(--bs-stepper-line-border);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
