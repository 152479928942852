import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../../shared/services/lsg.api.service';

import { SharedAffiliateService } from '../../../affiliate/shared-affiliate.service';
import { AnnouncementObject } from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-owner-announcement-item-popup',
  templateUrl: './owner-announcement-item-popup.component.html',
  styleUrls: ['./owner-announcement-item-popup.component.css'],
})
export class OwnerAnnouncementPopupComponent implements OnInit {
  // @Input() refundItem: RefundItem;
  @Input() announcementItem: AnnouncementObject;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {}

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in sales-item-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  // FileUpload
}
