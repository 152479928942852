import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Payment_Gateway_Enum } from 'src/app/models/portal';
import { SalesLogDetailedElement } from 'src/app/models/system-earnings';
import { PaymentMethodViewTypeEnum } from 'src/app/shared/partials/system-payment-method-view/system-payment-method-view.component';
import { ApiService } from 'src/app/shared/services';
import { SharedRefundService } from '../../shared-refund.service';
import {
  RefundActionEnum,
  RefundItem,
  RefundStatusEnum,
} from 'src/app/models/owner-dashboard';


import { ToastrService } from 'ngx-toastr';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { updateRefundRequest } from 'src/app/shared/store/_features/refunds/refunds.actions';

@Component({
  selector: 'app-refund-item-view',
  templateUrl: './refund-item-view.component.html',
  styleUrls: ['./refund-item-view.component.css'],
})
export class RefundItemViewComponent implements OnInit {

  @Output() onStart = new EventEmitter<RefundActionEnum>();
  @Output() onSuccess = new EventEmitter<RefundActionEnum>();
  @Output() onError = new EventEmitter<any>();
  

  showSaleDetails: boolean = false;
  refundStatusEnum = RefundStatusEnum;
  
  refundActionEnum = RefundActionEnum;
  

  loaded: boolean = false;
  paymentMethodViewTypeEnum = PaymentMethodViewTypeEnum;
  salesPaymentMethodEnum = Payment_Gateway_Enum;
  // saleItem: SalesLogDetailedElement;

  itemId: number;
  @Input('itemId') set refundItemId(id: number) {
    if (!!id) {
      this.itemId = id;
      this.getRefundItemById(id);
    }
  }

  constructor(
    public api: ApiService,
    private route: ActivatedRoute,
    public sharedRefundService: SharedRefundService,
    private toastr: ToastrService,
    private store: Store
  ) {
    this.route.params.subscribe((params) => {
      
      const id = params['id'];
      if (!!id) {
        this.itemId = id;
        this.getRefundItemById(id);
      }
    });
    // const id = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
  
  }

  startRefundProcess() {
    this.alertBeforeRsolve(RefundActionEnum.Refunded);
  }



  startRefuseProcess() {
    this.alertBeforeRsolve(RefundActionEnum.Refused);
  }

  resetProcess() {
    this.sharedRefundService.endProcessing();
  }



  async alertBeforeRsolve(action: RefundActionEnum) {
    Swal.fire({
      title: `Are you sure want to mark this request as ${action}?`,
      html: `
      ${this.generateResolveAlert()}
      `,
      icon: 'warning',
      width: '600px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-warning',
        cancelButton: 'btn btn-light',
        input: 'form-check form-check-warning',
      },
      input: 'checkbox',
      inputPlaceholder: `I know that i'll mark this refund request as <strong>${action}</strong>`,
      inputValidator: (value: any) => {
        
        if (!!value) {
          return null;
        } else {
          return 'Read carefully and check the agreement';
        }
      },
      showLoaderOnConfirm: true,
      preConfirm: async (inputValue: any) => {
        return await this.markAsResolved(action);
      },
      showCancelButton: true,
      confirmButtonText: 'Mark as Refunded',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }) 
  }

  generateResolveAlert() {
    return `
    <div class="alert gap-3 bg-light-warning d-flex text-start d-flex align-items-center p-2 my-4">
    <i class="ler-duotone ler-information fs-3x text-warning">
    <i class="path1"></i>
    <i class="path2"></i>
    <i class="path3"></i>
</i>
    <div>
    Once confirmed, the action is final and <strong class="text-warning">cannot be reversed</strong>.
    </div>

    </div>
   
    `;
  }

  async markAsResolved(action: RefundActionEnum) {
    this.onResolveStart(action);
      let response: {refund: RefundItem, success: boolean} = await this.sharedRefundService.resolveRefundRequest(
        this.sharedRefundService.currentRefundItem,
        action,
        this.resolveErrorHandler
      );
      this.store.dispatch(updateRefundRequest({itemId: response.refund.id, item: response.refund}));
      this.sharedRefundService.currentRefundItem = response.refund;
      this.onResolveSuccess(action);
  }


  onResolveStart(action: RefundActionEnum) {
    this.onStart.emit(action);
    this.sharedRefundService.startProcessing();
  }
  onResolveSuccess(action: RefundActionEnum) {
    this.onSuccess.emit(action);
    this.toastr.success('Done!', `Marked As ${action}`, {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.sharedRefundService.endProcessing();
  }

  onResolveError() {
    this.toastr.error('Error!', 'Something Went Wrong', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  resolveErrorHandler = (error: any) => {
    this.onError.emit(error);
    this.onResolveError();
  };
  async getRefundItemById(id: number = this.itemId) {
    this.loaded = false;
    let request$ = this.api.getRefundItemById(id);
    let response = await lastValueFrom(request$);
    this.sharedRefundService.currentRefundItem = response;
    this.loaded = true;
  }



  // UI Functions
  onViewSaleDetails() {
    this.showSaleDetails = !this.showSaleDetails;
    setTimeout(() => {
      this.scrollTo('saleItemArea', 'start')
    }, 500);
  }


  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

}
