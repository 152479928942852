import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ProductModel } from 'src/app/components/user-dashboard/product/models/product';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.css'],
})
export class ProductSelectorComponent implements OnInit {
  @Input() productsList: ProductModel[]; // Input property to pass the list of products
  _formControl: FormControl; // Input property to pass the form control
  @Output() formControlChange = new EventEmitter<FormControl>(); // Output property for form control changes

  @Input() restrectedProduct: boolean = false;
  restrectedProductModel: ProductModel;
  constructor(private api: ApiService) {
    // Listen for changes in the form control value and emit it

  }

  @Input('formControl') set formControlValue(value: any) {
    this._formControl = value;

    this._formControl.valueChanges.subscribe((value) => {
      this.formControlChange.emit(this._formControl);
    });
  }

  async ngOnInit() {
    this.productsList = await this.getProducts();
    if (!!this.restrectedProduct) {
      this.restrectedProductModel = this.productsList.find((product) => product.id == this._formControl?.value);
    }
  }



  async getProducts() {
    let request$ = this.api.getProducts();
    let response = await lastValueFrom(request$);

    
    return response;
  }

}
