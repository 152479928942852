import { Injectable } from '@angular/core';
import { Client, Account, Models, ID, OAuthProvider } from 'appwrite';
import { Observable, catchError, from, lastValueFrom, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";

@Injectable({ providedIn: 'root' })
export class AppwriteService {
  client = new Client()
    .setEndpoint(environment.appwrite.url) // Your API Endpoint
    .setProject(environment.appwrite.projectKey);
  account = new Account(this.client);
  tempAccount;
  constructor() {
 
  }


  setPreference(key: string, value: string) {
    return from(this.account.updatePrefs({ [key]: value }));
  }
  
  emailVerificationRequest() {
    return from(this.account.createVerification(environment.appwrite.verificationRedirectURL))
  }

  emailVerificationApply(userId: string, secret: string) {
    return this.account.updateVerification(userId, secret);
  }

  async getUserSessions() {
    let request$ = this.account.listSessions();

    return request$.then((response) => {
      return response;
    })

  }

  async getUserCurrentSession() {
    let request$ = this.account.getSession('current');

    return request$.then((response) => {
      return response;
    })

  }

  getUserCurrentUserAppwriteProfile() {
    return from(this.account.get());
  }

  async killSessionByID(id) {
    await this.account.deleteSession(id);
  }
  async clearAppwriteSession() {
    this.account.getSession('current').then(async (session) => {
      await this.account.deleteSession('current');
      this.regenerateAppwriteClient();
    }).catch((error)=>{
      console.log(error)
      this.regenerateAppwriteClient();
    })
    
  }

  regenerateAppwriteClient() {
    this.client = new Client()
    .setEndpoint(environment.appwrite.url) // Your API Endpoint
    .setProject(environment.appwrite.projectKey);
    this.account =  new Account(this.client);
    return this.account;
  }

  generateCleanAppwriteClient() {
    let client = new Client()
    .setEndpoint(environment.appwrite.url) // Your API Endpoint
    .setProject(environment.appwrite.projectKey);
    let account =  new Account(client);
    return account;
  }

  generateTempAccountToken(token: string) {
    let client = new Client()
    .setEndpoint(environment.appwrite.url) // Your API Endpoint
    .setProject(environment.appwrite.projectKey).setJWT(token);
    return new Account(client);
  }

  createJWT() {
    return from(this.generateCleanAppwriteClient().createJWT());
  }


  refreshToken(): Observable<string> {
    return this.createJWT().pipe(
      switchMap((newToken: { jwt: string }) => {
        this.setCurrentToken(newToken.jwt);
        return of(newToken.jwt);
      })
    );
  }
  // private createNewJWT(): Promise<Models.Jwt> {
  //   return this.account.createJWT();
  // }


  async isSessionValid(): Promise<boolean> {
    try {
      let user = await this.generateCleanAppwriteClient().get();
  
      // Additional checks can be added here based on your requirements
  
      // Assuming a user is valid if it exists
      return !!user;
    } catch (error) {
      console.error('Error checking Session validity:', error);
      return false; // Handle errors appropriately
    }
  }


  isTokenValidParse(token: string, meta?: any): boolean {
    try {
      const currentDate = Math.floor(new Date().getTime() / 1000);
      const decoded = jwtDecode(token);
      
      const tokenLifeTime = decoded.exp - currentDate;
      // Assuming 'exp' is a required field in the decoded token
      // console.log("URL: ", meta?.URL,)
      // console.table({
      //   DecodedEXP: decoded.exp,
      //   CurrentDate: currentDate,
      //   TokenLifeTime: tokenLifeTime
      // });
      // console.log('----------------------------------------')

      if ((tokenLifeTime < 300)) {
        return false;
      }
  
      return true;
    } catch (error) {
      console.error('Error decoding token:', error);
      // Handle decoding errors appropriately
      return false;
    }
  }
  isTokenValidObservable(token: string): Observable<boolean> {
    let account = this.generateTempAccountToken(token);

    return from(account.get()).pipe(
      map((user: any) => {
        // Additional checks can be added here based on your requirements

        // Assuming a user is valid if it exists
        return !!user;
      }),
      catchError((error: any) => {
        console.error('Error checking token validity:', error);
        return [false]; // Handle errors appropriately, returning a default value
      })
    );
  }
  async isTokenValid(token: string): Promise<boolean> {
    try {
      let account = this.generateTempAccountToken(token);
      let user = await account.get();
  
      // Additional checks can be added here based on your requirements
  
      // Assuming a user is valid if it exists
      return !!user;
    } catch (error) {
      console.error('Error checking token validity:', error);
      return false; // Handle errors appropriately
    }
  }
  
 

  setCurrentToken(token: string) {
    this.client.setJWT(token);
    this.account = new Account(this.client);
  }
  loginWithEmailAndPassword(credintials: { email: string; password: string }) {
    return from(
      this.account.createEmailPasswordSession(credintials.email, credintials.password)
    );
  }

  loginWithMagicLink(credintials: { email: string}) {
    return from(
      this.account.createMagicURLToken(ID.unique(),credintials.email, environment.appwrite.oAuthCallbackUrl.magicLink)
    );
  }

  loginWithPhone(credintials: { phone: string}) {
    return from(
      this.account.createPhoneToken(ID.unique(),credintials.phone)
    );
  }

  handleMagicLinkCallback(userId: string, secret: string) {
    return from(
    this.account.updateMagicURLSession(userId, secret)
    );
  }

  handlePhoneCallback(userId: string, secret: string) {
    return from(
    this.account.updatePhoneSession(userId, secret)
    );
  }
  

  // ----------------------- Reset Password -----------------------
  createPasswordRecoveryRequest(email: string) {
    return from(this.account.createRecovery(email, environment.appwrite.resetPasswordCallbackUrl));
  }

  confirmPasswordRecoveryRequest(data: {userId: string, secret: string, password: string}) {
    return from(this.account.updateRecovery(data.userId, data.secret, data.password));
  }


  createOAuth2Session(provider: OAuthProvider = OAuthProvider.Google) {
    this.account.createOAuth2Session(provider,environment.appwrite.oAuthCallbackUrl.google, environment.appwrite.oAuthFailUrl);
  }

  createNewAccountWithEmail(userInfo: {
    username?: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
  }) {
    let username = this.generateUserName(userInfo)
    return from(
      this.account.create(
        username,
        userInfo.email,
        userInfo.password,
        `${userInfo.firstname} ${userInfo.lastname}`
      )
    );
  }


  generateUserName(userInfo: {
    firstname: string;
    lastname: string;
    email: string;
  }): string {
    // Use the first letter of the firstname as the username prefix, 
    // or if firstname is empty, use the first letter of the email
    const usernamePrefix = userInfo.firstname ? userInfo.firstname.charAt(0) : userInfo.email.charAt(0);
    
    // Combine the username prefix with the lowercase lastname
    const usernameBody = userInfo.lastname.toLowerCase()

    // Add a random number to make the username unique
    const randomSuffix = Math.floor(Math.random() * 1000);

    // Combine all parts to create the final username
    const username = `${usernamePrefix.toLowerCase()}${usernameBody}${randomSuffix}`;

    return username.replace(' ', '-');;
}




  async isWebhookDone() {
    let result = await this.account.get()

    return !!result.labels.includes('learmoRegistered');
  }
}


