export class OneTimePaymentResult {
  public readonly pk: string;
  public readonly sessionId: string;
}

export class OntTimePaymentOutputBtcpay {
  public readonly invoiceUrl: string;
  public transactionId: string;
}

export enum PaymentServiceInterfaceTypeEnum {
  CreditCard = 'CreditCard',
  BitCoin = 'BitCoin',
  EWallet = 'EWallet',
}
export class PaymentServiceInterface {
  public id: number;
  public name: string;
  public enum: PaymentServiceInterfaceTypeEnum;
  public logo: string;
  public recommended: boolean;
  public active: boolean;
}

export class GetBtcRateResult {
  public readonly btcRate: number;
}

export enum RateTypeEnum {
  btc_usd = 'BTC_USD',
}

export class PaymentMethodModel {
  id: string;
  payment_id: string;
  customer_id: string;
  last_four: string;
  card_holder_name: string;
  brand: string;
  country?: string;
  state?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  is_default: number;
  exp_month: number;
  exp_year: number;


  deleted?: boolean;
}
