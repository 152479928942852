import { OffersState, offerAdapter } from './offers.reducer';

// selectors/products.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';


// Create a feature selector for the 'products' feature
export const selectOffersState = createFeatureSelector<OffersState>('offers');

// Create a selector to get the products from the state
export const selectOffers = createSelector(
    selectOffersState,
    (state) => offerAdapter.getSelectors().selectAll(state)
  );

export const isOffersLoaded = createSelector(selectOffersState, (state) => state.offersLoadingProcess.status == 'loading' ? false : true);
export const isOffersLoadingError = createSelector(selectOffersState, (state) => state?.offersLoadingProcess?.error);


// Current Offer Management
export const selectCurrentOffer = createSelector(
  selectOffersState,
  (state) => state.currentOffer
);

export const isCurrentOfferLoaded = createSelector(selectOffersState, (state) => state.currentOfferProcess.status == 'loading' ? false : true);
export const isCurrentOfferLoadingError = createSelector(selectOffersState, (state) => state.currentOfferProcess.error);

export const isCreateOfferLoaded = createSelector(selectOffersState, (state) => state.createNewOfferProcess.status == 'loading' ? false : true);
export const isCreateOfferLoadingError = createSelector(selectOffersState, (state) => state.createNewOfferProcess.error);


export const selectOfferById = (offerId: number) => createSelector(
  selectOffersState,
  (state) => {
    if (state.currentOffer?.id === offerId) {
      return state.currentOffer;
    }
    return state.entities[offerId];
  }
);