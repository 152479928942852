import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-list',
  templateUrl: './loading-list.component.html',
  styleUrls: ['./loading-list.component.css']
})
export class LoadingListComponent implements OnInit {
  loadingSkeletonItems: any[] = []

  constructor() { }

  @Input('itemsCount') set itemsArray(value: number) {
    this.loadingSkeletonItems = Array.from({ length: value }, (_, index) => index);
  }

  ngOnInit() {
  }

}
