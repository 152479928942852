import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-method-item',
  templateUrl: './login-method-item.component.html',
  styleUrls: ['./login-method-item.component.css']
})
export class LoginMethodItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
