import { Router } from '@angular/router';
import {
  backSettingsObject,
  BackSettingsType,
  SharedOwnerDashboardService,
} from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-owner-dynamic-back-button',
  templateUrl: './owner-dynamic-back-button.component.html',
  styleUrls: ['./owner-dynamic-back-button.component.css'],
})
export class OwnerDynamicBackButtonComponent implements OnInit {
  @Input() backSettings: backSettingsObject = {
    route: '/dashboard',
    text: 'Dashboard',
    type: BackSettingsType.standard,
  };

  @Input() customButton: boolean = false;

  constructor(
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    private router: Router
  ) {}

  goBack() {
    this.sharedOwnerDashboardService.goBack();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.sharedOwnerDashboardService.backSettings =
        this.sharedOwnerDashboardService.getBackRoute;
      if (
        !this.sharedOwnerDashboardService.backSettings?.refUrl &&
        !!this.sharedOwnerDashboardService.backSettings
      ) {
        this.sharedOwnerDashboardService.setRefUrl(this.router.url);
      }

      this.checkBackSettings();
    }, 800);
  }

  checkBackSettings() {
    const backSettings = this.sharedOwnerDashboardService.getBackRoute;

    if (backSettings?.refUrl != this.router.url || !backSettings) {
      // this.sharedOwnerDashboardService.destroyBackRoute();
      this.sharedOwnerDashboardService.setBackRoute(
        'Dashboard',
        '/dashboard',
        undefined,
        BackSettingsType.standard
      );
    }
  }
}
