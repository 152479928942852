// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';


import { Store } from '@ngrx/store';

import { ApiService } from '../../../../../services';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';
import { CurrentCourseReportsActions } from './course-reports.actions';

@Injectable()
export class CourseReportsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}





  loadCurrentCourseReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseReportsActions.load), // Listen for the loadProducts action
      mergeMap(({ productId, errorHandler = undefined }) =>
        this.api.getCourseReports(productId, true, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: {students: StudentItem[], demoStudents: StudentItem[], totalProgress: number, completed: number}) =>
            CurrentCourseReportsActions.loadSuccess({ students: response.students, demoStudents: response.demoStudents, totalProgress: response.totalProgress, completedStudentsCount: response.completed })
          ),
          catchError((error) =>
            of(CurrentCourseReportsActions.loadFailure({ error }))
          )
        )
      )
    )
  );

}
