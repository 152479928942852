import { ApiService } from 'src/app/shared/services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  WizardModel,
  WizardStepStatusEnum,
  StepVisibilityEnum,
  WizardTypeEnum,
} from 'src/app/models/wizard';
import { GlobalWizardSettings } from '../../portal/dto/portal.input';
import { ProductItem, ProductWizardData } from '../models/product';
import { lastValueFrom, takeWhile } from 'rxjs';
import { CourseCreationInput } from '../models/course';
import { Offer_Payment_Type_Enum } from '../../offer/models/offer';

import { AiCurrentStateEnum } from './product-create-wizard/product-title-wizard-step/product-title-wizard-step.component';
import { AiGeneratorComponentData, CurrentIdeationStepEnum } from 'src/app/shared/partials/ai/ai-idea-generator/ai-generator.models';
import { Store } from '@ngrx/store';
import { DescriptionEnterStatusEnum } from './product-create-wizard/product-description-image-wizard-step/product-description-image-wizard-step.component';
import { createNewProductActions } from 'src/app/shared/store/_features/products/products.actions';
import { selectProductsState } from 'src/app/shared/store/_features/products/products.selector';

@Injectable({
  providedIn: 'root',
})
export class ProductCreateService {

  titleAiCurrentState: AiCurrentStateEnum = AiCurrentStateEnum.prompt;
  loadingCreate: boolean = false;
  // Create Wizard
  wizardSettings: GlobalWizardSettings = {
    portalType: null,
    summaryAnimation: null,
    finalStepLabel: 'Create',
    titleAiCurrentState: AiCurrentStateEnum.prompt,
    descriptionAiCurrentState: DescriptionEnterStatusEnum.none
  };

  wizardData: ProductWizardData = {
    productType: null,
    courseInput: {
      name: '',
      shortDescription: '',
    },
    temp: new Object()
  };

  countForFinishing: {
    counter: any;
    count: number;
  } = { count: null, counter: null };

  public wizardModel: WizardModel;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private api: ApiService,
    private store: Store
  ) {}

  public resetWizardValues() {
    this.countForFinishing = { count: null, counter: null };
    this.wizardSettings = {
      portalType: null,
      summaryAnimation: null,
      finalStepLabel: 'Create',
      titleAiCurrentState: AiCurrentStateEnum.prompt,
      descriptionAiCurrentState: DescriptionEnterStatusEnum.none
    };

    this.resetTheAi();
    // this.templates.currentTemplate = { index: null, template: null };
    // this.templates.currentCategory = { index: null, category: null };

    // this.funnels.currentFunnel = { index: null, funnel: null };
    // this.funnels.currentCategory = { index: null, category: null };

    this.wizardData = {
      productType: null,
      temp: new Object(),
    };

    this.wizardModel?.resetWizard();
  }

  resetTheAi() {
    // Todo: Reset the AI
  }
  setWizardNextTitle(text: string) {
    this.wizardModel.currentStep.step.nextButtonLabel = text;
  }

  // Wizard Initial
  initializeWizardProductCreate() {
    this.wizardSettings.finalStepLabel = 'Create My Product';
    this.wizardModel = new WizardModel(
      [
        {
          id: 1,
          title: 'Product Type',
          description: 'Construct your product',
          innerContent: { title: 'Which product concepts resonate with you?', description: `We'd like to know what specific products you're passionate about creating.` },
          status: WizardStepStatusEnum.none,
          enumTag: 'template',
          visibility: StepVisibilityEnum.visible,
          isValid: false,
          hideButtons: {
            back: false,
            next: false,
          },
          hideSteps: true,
          navigationAction: this.goToSelectChooseProductType,
          submissionCustomAction: this.onTypeSubmission,
          validate: this.selectProductTypeValidator,
          nextButtonLabel: 'Choose to continue'
        },
        {
          id: 2,
          title: 'Variant Steps',
          description: 'Depends on type',
          status: WizardStepStatusEnum.dummy,
          visibility: StepVisibilityEnum.visible,
          isValid: true,

          hideButtons: {
            back: false,
            next: false,
          },
          navigationAction: this.goToProductTitle,
        },
      ],
      0,
      WizardTypeEnum.product,
      this.backAction,
      this.createProduct
    );
    //
  }

  async initCourseInput() {
    this.wizardData.courseInput = {
      name: null,
      shortDescription: null,
      payment_type: {
        name: Offer_Payment_Type_Enum.free,
        price: null
      },
    };
  }

  async initializeCourseProductCreate() {
    this.wizardSettings.finalStepLabel = 'Create Course';
    this.initCourseInput();
    this.wizardModel?.setSteps([
      {
        id: 1,
        title: 'Product Type',
        description: 'Construct your product',
        innerContent: { title: `Choosing to develop a course is a superb choice` },
        status: WizardStepStatusEnum.active_done,
        enumTag: 'template',
        canNavigateByClick: true,
        visibility: StepVisibilityEnum.visible,
        isValid: false,
        hideButtons: {
          back: false,
          next: false,
        },
        hideSteps: true,
        navigationAction: this.goToSelectChooseProductType,
        submissionCustomAction: this.onTypeSubmission,
        validate: this.selectProductTypeValidator,
        nextButtonLabel: 'Get started'
      },
      {
        id: 2,
        title: 'Course Title',
        description: 'Name your course',
        enumTag: 'title',
        innerContent: {
          title: 'Write a title for your course',
          description:
            'Craft a catchy title and capture your students’ imagination. Make it short, precise, memorable and SEO friendly by including strong keywords in it.',
        },
        status: WizardStepStatusEnum.none,
        visibility: StepVisibilityEnum.visible,
        isValid: false,
        validate: this.courseTitleValidator,
        hideButtons: {
          back: false,
          next: false,
        },
        navigationAction: this.goToProductTitle,
      },
      {
        id: 3,
        title: 'Course Details',
        enumTag: 'description',
        description: 'Description & Details',
        innerContent: {
          title: 'How will you introduce your course?',
          description: `The course intro is the first thing that potential students will see when browsing your course. It's important to create a clear and concise introduction that is both informative and engaging. `,
        },
        status: WizardStepStatusEnum.none,
        visibility: StepVisibilityEnum.visible,
        isValid: false,
        validate: this.courseDescriptionValidator,
        hideButtons: {
          back: false,
          next: false,
        },
        navigationAction: this.goToProductDescription,
      },
      // {
      //   id: 4,
      //   title: 'Course Image',
      //   description: 'Visual Representation',
      //   innerContent: {
      //     title: 'How will you describe your course?',
      //     description: `The course image is the first visual representation that potential students will have of your course. It's essential to choose an image that accurately reflects the course content and captures their attention.`,
      //   },
      //   status: WizardStepStatusEnum.none,
      //   visibility: StepVisibilityEnum.visible,
      //   isValid: false,
      //   validate: this.courseImageValidator,
      //   hideButtons: {
      //     back: false,
      //     next: false,
      //   },
      //   navigationAction: this.goToProductImage,
      // },
      {
        id: 5,
        title: 'Pricing',
        enumTag: 'pricing',
        description: 'Pricing & Payment preferences',
        innerContent: {
          title: 'Price your Course',
          description: `Choose whether this Course is paid or free. If it’s paid, set its price and payment preferences.`,
        },
        status: WizardStepStatusEnum.none,
        visibility: StepVisibilityEnum.visible,
        isValid: false,
        validate: this.courseOfferValidator,
        hideButtons: {
          back: false,
          next: false,
        },
        navigationAction: this.goToProductOffer,
      },
      {
        id: 5,
        title: 'Summary',
        description: 'Product Summary',
        innerContent: {
          title: 'Review',
          description: `Double-check your masterpiece! In this final step, review all the details of your meticulously crafted course product.`,
        },
        status: WizardStepStatusEnum.none,
        visibility: StepVisibilityEnum.visible,
        isValid: true,
        hideButtons: {
          back: false,
          next: false,
        },
        navigationAction: this.goToSummary,
        nextButtonLabel: 'Create & Build'
      },
    ]);
  }

  // Wizard Type Course

  onTypeSubmission = () => {

    switch (this.wizardData?.productType?.enum) {
      case 'course':
        if (!this.wizardData?.courseInput) {
          this.wizardData.courseInput = { name: '', shortDescription: '' };
        }
        break;

      default:
        break;
    }
    //     this.userDashboardService.hideSidebar();
    // this.userDashboardService.closeCollapse();
  };

  // Wizard General Actions
  goToSelectChooseProductType = () => {
    this.router.navigate(['dashboard', 'product', 'create', 'step1']);
  };

  goToProductTitle = () => {
    this.router.navigate(['dashboard', 'product', 'create', 'product-title']);
  };

  goToProductDescription = () => {
    this.router.navigate([
      'dashboard',
      'product',
      'create',
      'product-description',
    ]);
  };

  goToProductImage = () => {
    this.router.navigate(['dashboard', 'product', 'create', 'product-image']);
  };

  goToProductOffer = () => {
    this.router.navigate(['dashboard', 'product', 'create', 'product-offer']);
  };

  goToSummary = () => {
    this.router.navigate(['dashboard', 'product', 'create', 'summary']);
  };


  selectProductTypeValidator = () => {
    return !!this.wizardData?.productType;
  };

  backAction = () => {
    this.router.navigate(['dashboard', 'product']);
  };

  // Wizard Course Actions

  courseTitleValidator = () => {
    return !!this.wizardData?.courseInput?.name;
  };

  courseDescriptionValidator = () => {
    return !!this.wizardData?.courseInput?.shortDescription;
  };

  courseImageValidator = () => {
    return !!this.wizardData?.courseInput?.image_id;
  };

  courseOfferValidator = () => {
    if (this.wizardData?.courseInput?.payment_type.name == Offer_Payment_Type_Enum.one_time && this.wizardData?.courseInput?.payment_type.price.value <= 0) {
      // this.toastr.error('Please enter a valid price');
      return false;
    }

    return true;
  };

  // Wizard Auxiliary Actions
  startAnimationAndRedirect(callback: () => void | Promise<void>) {
    this.countForFinishing.count = 5;
    this.countForFinishing.counter = setInterval(() => {
      this.countForFinishing.count--;
      if (this.countForFinishing.count <= 0) {
        callback();
      }
    }, 1000);
  }

  public resetWizard() {
    if (this.countForFinishing.counter) {
      clearInterval(this.countForFinishing.counter);
    }
    this.resetWizardValues();
  }

  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  // Wizard Final Actions
  createProduct = async () => {
    this.store.dispatch(createNewProductActions.create({createPayload: this.wizardData.courseInput}))
    this.loadingCreate = true;
    this.store.select(selectProductsState).pipe(takeWhile(state => state?.createNewProductProcess.status !== 'done' && state?.createNewProductProcess.status !== 'error', true)).subscribe(state=> {
      switch (state?.createNewProductProcess.status) {
        case 'done':
          this.onProductCreateDone(state.createNewProductProcess.lastCreatedProduct);
          break;
        case 'error':
          this.onProductCreateError(state.createNewProductProcess.error)
        break;
        default:
          break;
      }

    })

  };


  onProductCreateDone = (product: ProductItem) => {
    this.startAnimationAndRedirect(() => {
      this.router.navigate(['dashboard', 'product']).then(() => {
        this.toastr.success('Product Created Successfully', 'Done!');
        // this.store.dispatch(addNewProduct({product}))
        this.resetWizard();
        this.loadingCreate = false;
        this.scrollTo(`productItem-${product?.id}`);
      });
      // setTimeout(async () => {
      //   this.scrollTo(`productItem-${product?.id}`);
      // }, 2500);
    });
  }

  onProductCreateError = (errorMessage: string) => {
    this.startAnimationAndRedirect(() => {
      this.router.navigate(['dashboard', 'product']).then(() => {
        this.toastr.error(errorMessage, "Error!");
        // this.store.dispatch(addNewProduct({product}))
        this.resetWizard();
        this.loadingCreate = false;
      });
      // setTimeout(async () => {
      //   this.scrollTo(`productItem-${product?.id}`);
      // }, 2500);
    });
  }


  async createNewProduct(courseInput: CourseCreationInput) {
    const createCourse$ = this.api.createCourse(courseInput);
    let createCourse = await lastValueFrom(createCourse$);

    return createCourse;

  }


}
