import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  MessageTypeEnum,
  MessengerThreadStatusEnum,
  MessageSenderTypeEnum,
  MessengerMessage,
} from '../../../../../models/owner-dashboard';
import { MediaLibraryFileModel } from '../../../../../models/sales-funnel';

@Component({
  selector: 'app-messenger-chat-user-message',
  templateUrl: './messenger-chat-user-message.component.html',
  styleUrls: [
    './messenger-chat-user-message.component.css',
    '../../messenger.component.css',
  ],
})
export class MessengerChatUserMessageComponent implements OnInit {
  messageSenderTypeEnum = MessageSenderTypeEnum;
  messengerThreadStatusEnum = MessengerThreadStatusEnum;
  messageTypeEnum = MessageTypeEnum;
  @Output() ImageClick: EventEmitter<MediaLibraryFileModel> =
    new EventEmitter<MediaLibraryFileModel>();
  @Input() message: MessengerMessage;

  constructor() {}

  ngOnInit() {}

  async onImageClick(media: MediaLibraryFileModel) {
    this.ImageClick.emit(media);
  }
}
