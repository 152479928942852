import { BtcpayPaymentGatewayModel } from './../../../../../../../../../models/portal';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-verify-btcpay-info',
  templateUrl: './verify-btcpay-info.component.html',
  styleUrls: ['./verify-btcpay-info.component.css'],
})
export class VerifyBtcpayInfoComponent implements OnInit {
  btcPay: BtcpayPaymentGatewayModel = JSON.parse(
    localStorage.getItem('btcPayData')
  );
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private api: ApiService) {

    this.route.queryParams.subscribe((params) => {
      let { apiKey, storeID } = params;
      if (!!this.btcPay) {
        this.btcPay.apiKey = apiKey;
        this.btcPay.storeId = storeID;
        localStorage.setItem('btcPayData', JSON.stringify(this.btcPay));
        this.router.navigateByUrl(this.btcPay.returnURL);
        this.toastr.success("BTCPay Integrated Successfully")
      } else {
        this.router.navigate(['dashboard']);
      }

       // { orderby: "price" }
    });
  }

  ngOnInit(): void {}

  // async goData(data: BtcpayPaymentGatewayModel) {
  //   const request$ = this.api.integratePaymentGateways(
  //     this.sharedPortalViewService.currentWebsite.id,
  //     { btcPay: data },
  //     this.integrationRequestErrorHandler
  //   );
  //   const request = await lastValueFrom(request$);
  //   localStorage.removeItem('btcPayData');
  //   this.onSuccessIntegration();
  // }
}
