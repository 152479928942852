import { PortalControlService } from '../services/portal-control.service';
import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { ProfileUiControlService } from '../services/profile-ui-control.service';
import { NavCanDeactivate } from './nav-can-deactivate';

@Injectable()
export class CanDeactivatePortalGuard
  implements CanDeactivate<NavCanDeactivate>
{
  constructor(
    public portalControlService: PortalControlService,
    private route: Router
  ) {}
  canDeactivate(component: NavCanDeactivate): boolean {
    if (!!component?.canDeactivate && !component?.canDeactivate()) {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.portalControlService.canNavigateAll = true;
        return true;
      } else {
        return false;
      }
    } else {
      this.portalControlService.canNavigateAll = true;
      return true;
    }
  }
}
