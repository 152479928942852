import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  AffiliateMinimizedModel,
  AffiliatesInvitationStatusEnum,
} from '../../../../../../../models/owner-dashboard';
import {
  PageStatusEnum,
  SharedAffiliateService,
} from '../../../../shared-affiliate.service';

@Component({
  selector: 'app-affiliate-profile-popover',
  templateUrl: './affiliate-profile-popover.component.html',
  styleUrls: ['./affiliate-profile-popover.component.css'],
})
export class AffiliateProfilePopoverComponent implements OnInit {
  @ViewChild('popOver') public popover: NgbPopover;
  closeCounter = false;
  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;
  pageStatusEnum = PageStatusEnum;
  @Input() onlyCard: boolean = false;
  @Input() affiliateInfo: any;
  constructor(public sharedAffiliateService: SharedAffiliateService) {}

  ngOnInit(): void {}

  popOverCloseCounter() {
    this.closeCounter = true;

    setTimeout(() => {
      if (!!this.closeCounter) {
        this.popover.close();
      }
    }, 300);
  }

  disableCloseCounter() {
    this.closeCounter = false;
  }
}
