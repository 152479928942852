export function formatDuration(duration: number): string {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  if (duration < msPerMinute) {
    return `${Math.round(duration / 1000)} Sec`;
  } else if (duration < msPerHour) {
    return `${Math.round(duration / msPerMinute)} Min`;
  } else if (duration < msPerDay) {
    return `${Math.round(duration / msPerHour)} Hrs`;
  } else if (duration < msPerMonth) {
    return `${Math.round(duration / msPerDay)} Days`;
  } else if (duration < msPerYear) {
    return `${Math.round(duration / msPerMonth)} Months`;
  } else {
    return `${Math.round(duration / msPerYear)} Yrs`;
  }
}
