import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { MediaLibraryFileModel } from 'src/app/models/sales-funnel';
import { DropdownItem } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { Store } from '@ngrx/store';
 import Swal from 'sweetalert2';
import { selectMediaLibraryState } from 'src/app/shared/store/_features/media-library/media-library.selector';
import { MediaLibraryFileActions } from 'src/app/shared/store/_features/media-library/media-library.actions';


@Component({
  selector: 'app-media-library-grid-file-view',
  templateUrl: './media-library-grid-file-view.component.html',
  styleUrls: ['./media-library-grid-file-view.component.css']
})
export class MediaLibraryGridFileViewComponent implements OnInit {
  tippyImageProps: NgxTippyProps = {
    trigger: 'click click-outside',
    followCursor: false,
    allowHTML: true,
    appendTo: document.body,
  }

  tippyVideoProps: NgxTippyProps = {
    trigger: 'click click-outside',
    followCursor: false,
    allowHTML: true,
    interactive: true,
    appendTo: document.body,
  }
  iframeLoaded: boolean = false;
  defaultVideoThumb = 'assets/images/svg/files/video.svg';
 @Input() item: MediaLibraryFileModel;

 @Input() selected: boolean = false;
 @Output() onClickActiveArea = new EventEmitter<MediaLibraryFileModel>();
 dropdownItems:DropdownItem [] = [

 ]

 @Output() onStartDeleting = new EventEmitter();

  constructor(private store: Store) { }

  ngOnInit() {
    this.generateFileDropdown()
  }

  frameLoaded() {

    this.iframeLoaded = true;
  }

  selectFile() {
    this.onClickActiveArea.emit(this.item);
  }

  generateFileDropdown() {
    this.dropdownItems = [
      {
        label: 'Delete',
        iconSvg: 'assets/images/svg/system/trash.svg',
        colorSet: 'danger',
        colorSetHover: 'danger',
        action: ()=>{this.deleteFile(this.item)}
      }
    ]
  }

  deleteFile = (item: MediaLibraryFileModel) => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      html: `
        You will not be able to recover this File
        `,
      icon: 'error',
      width: '300px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-danger',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger',
      },
      input:
      !!item?.used ? 'checkbox' : undefined,
      inputPlaceholder: `I am sure that i'll lose my deleted file.`,
      preConfirm: async () => {
       return this.store.dispatch(MediaLibraryFileActions.delete({file: item}));

      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((result) => {
      this.onStartDeleting.emit();
    })

  }

}
