import {
  Injectable,
  Output,
  EventEmitter,
  ApplicationRef,
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { map } from 'rxjs/internal/operators/map';

import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {
  WebsiteUpdate,
  WS_Website_Process_State_Enum,
} from '../../../app/models/portal';
import { WebsiteModel, WebsiteProcessModel } from 'src/app/components/user-dashboard/portal/portal.model';
import {
  MessengerThread,
  NotificationElement,
} from 'src/app/models/owner-dashboard';
import { AccountSetupStep } from 'src/app/components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';
import { Store } from '@ngrx/store';
import { selectToken } from '../store/_features/user/user.selector';
import { combineLatest } from 'rxjs';

export enum SocketConnectionStringStatus {
  disconnected = 'disconnected',
  connected = 'connected',
  reconnecting = 'reconnecting',
  terminated = 'terminated',
}

@Injectable({
  providedIn: 'root',
})
export class PortalSocketService {
  // @Output() onloadingDone = new EventEmitter<{ id: string; index: number }>();
  // @Output() onPortalCreatedEvent = new EventEmitter<WebsiteModel>();
@Output() onSocketSetupDone = new EventEmitter<any>();
  SocketConnectionStatus = false;
  SocketFlowStatus = false;
  redirectPending = false;
  redirectTime = 5;
  websiteProcessListener: Observable<WebsiteProcessModel>;
  websiteListener: Observable<WebsiteModel>;
  messengerListener: Observable<MessengerThread>;
  notificationsListener: Observable<NotificationElement>;
  accountSetupListener: Observable<AccountSetupStep>;
  // processListData: WebsiteUpdate[] = [];
  // createListData: WebsiteUpdate[] = [];
  // deleteListData: WebsiteUpdate[] = [];
  socketStatusString: SocketConnectionStringStatus = undefined;
  socket: Socket;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private store: Store
  ) {

    

    this.authenticationService.token.subscribe((newToken: string) => {
      this.closeUserSocket();
      // Assuming selectToken returns the new token value
      if (!!newToken) {
        this.webSocketConnect(newToken);  
      }
      
      // You can also pass the newToken to the webSocketConnect() function if needed
      // this.webSocketConnect(newToken);
    });
    
    // this.authenticationService.onLogin.subscribe(value=> {
    //   this.webSocketConnect();
    // })

    this.authenticationService.onLogout.subscribe((value: boolean) => {
      
      this.closeUserSocket();
    });
  }

  closeUserSocket() {
    if (this.socket) {
      this.SocketConnectionStatus = false;
      this.socketStatusString = SocketConnectionStringStatus.terminated;
      this.socket.disconnect(true);
      this.terminateListeners();
    }
    
  }

  webSocketConnect(token: string = this.authenticationService.currentTokenValue) {
    
    const config: SocketIoConfig = {
      url: environment.api.socketUrl,
      options: {
        reconnection: true,
        timeout: 1500,
        withCredentials: true,
        path: environment.api.subPath,
        transports: ['websocket', 'polling'],
        query: {
          authorization: `bearer ${token}`,
        },
      },
    };
    if (this.SocketConnectionStatus === false) {
      this.socket = new Socket(config);
      this.handleSocketConnection();

      this.setupListeners();
      this.SocketConnectionStatus = true;
      this.onSocketSetupDone.emit();

    }
  }

  async setupListeners() {
    
    this.websiteProcessListener = this.socket.fromEvent('websiteProcess');
    this.websiteListener = this.socket.fromEvent('website');
    this.notificationsListener = this.socket.fromEvent('notification');
    this.messengerListener = this.socket.fromEvent('message');
    this.accountSetupListener = this.socket.fromEvent('setup');
  }

  async terminateListeners() {
    // this.socket.removeAllListeners('list');
    
    this.socket.removeAllListeners('website');
    this.socket.removeAllListeners('notification');
    this.socket.removeAllListeners('message');
    this.socket.removeAllListeners('setup');
    
    
  }

  getProcess() {
    this.socket.emit('list');
  }

  async handleSocketConnection() {
    this.socket.on('connect', () => {
      this.socketStatusString = SocketConnectionStringStatus.connected;
      // this.getProcess();
    });

    this.socket.on('reconnect', () => {
      
      this.socketStatusString = SocketConnectionStringStatus.reconnecting;
    });

    this.socket.on('disconnect', () => {
      if (this.socketStatusString !== SocketConnectionStringStatus.terminated) {
        this.socketStatusString = SocketConnectionStringStatus.disconnected;
        const reconnect = setInterval(() => {
          this.socket.connect();
          if (
            this.socketStatusString === SocketConnectionStringStatus.connected
          ) {
            clearInterval(reconnect);
          } else {
            this.socketStatusString = SocketConnectionStringStatus.reconnecting;
          }
        }, 1000);
      }
    });
  }
}
