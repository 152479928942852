import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services';
import { Payout_Gateway_Enum } from '../../../../../../../models/portal';
import {
  AffiliateMinimizedModel,
  AffiliatesInvitationStatusEnum,
} from '../../../../../../../models/owner-dashboard';
import {
  PageStatusEnum,
  SharedAffiliateService,
} from '../../../../shared-affiliate.service';

export enum AffiliateNoteStatusEnum {
  done = 'done',
  saving = 'saving',
  editing = 'editing',
  no_changes = 'no_changes',
  none = 'none',
}
@Component({
  selector: 'app-affiliate-profile-card',
  templateUrl: './affiliate-profile-card.component.html',
  styleUrls: ['./affiliate-profile-card.component.css'],
})
export class AffiliateProfileCardComponent implements OnInit {
  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;
  affiliateNoteStatusEnum = AffiliateNoteStatusEnum;
  pageStatusEnum = PageStatusEnum;
  salesPaymentMethodEnum = Payout_Gateway_Enum;

  @Input() affiliate: AffiliateMinimizedModel;
  @Input() onlyCard: boolean = false;
  affiliateNote: string = '';
  affiliateNoteStatus: AffiliateNoteStatusEnum = AffiliateNoteStatusEnum.none;
  constructor(
    private sharedAffiliateService: SharedAffiliateService,
    private api: ApiService
  ) {}

  ngOnInit(): void {}

  onFocusNote() {
    this.affiliateNoteStatus = AffiliateNoteStatusEnum.editing;
  }

  async onBlurNote(value) {
    if (value == this.affiliate.note) {
      this.affiliateNoteStatus = AffiliateNoteStatusEnum.no_changes;
      setTimeout(() => {
        this.affiliateNoteStatus = AffiliateNoteStatusEnum.none;
      }, 1500);
    } else {
      await this.onChangeNote(value);
    }
  }

  async onChangeNote(value) {
    this.affiliateNoteStatus = AffiliateNoteStatusEnum.saving;
    await this.saveNote(value);
    this.affiliateNoteStatus = AffiliateNoteStatusEnum.done;
    setTimeout(() => {
      this.affiliateNoteStatus = AffiliateNoteStatusEnum.none;
    }, 1500);
  }

  async saveNote(value) {
    let response = await this.sharedAffiliateService.updateAffiliateNote(
      this.affiliate.id,
      value
    );
    this.affiliate = response;
  }
}
