
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppwriteException, Models } from 'appwrite';
import { UpdateProfileRequest, User } from '../../../../../models';
import { LoginMethodResult } from './profile.effect';
import { LoginMethodsEnum } from 'src/app/components/auth/login-alt/user-login-form-alt/user-login-form-alt.component';
import { LoginMethodStatusEnum } from 'src/app/components/profile/security-settings/login-methods/login-methods.component';




//Update User Profile
export const userProfileActions = createActionGroup({
  source: 'Update User Profile',
  events: {
    update:props<{payload: UpdateProfileRequest}>(),
    updateSuccess:props<{ learmoProfile: User }>(),
    updateFailure: props<{ error: any }>(),
  },
});




//Update User Email
export const userEmailActions = createActionGroup({
  source: 'Update User Email',
  events: {
    update:props<{payload: {email: string, password: string}}>(),
    updateSuccess:props<{response: Partial<User>}>(),
    updateFailure: props<{ error: any }>(),
  },
});



//Enable two factor authentication
export const enable2FAActions = createActionGroup({
  source: 'Enable two factor authentication',
  events: {
    enable:emptyProps(),
    enableSuccess:emptyProps(),
    enableFailure: props<{ error: any }>(),
  },
});




//Get User Login Method Config
export const userLoginMethodConfigActions = createActionGroup({
  source: 'Get User Login Method Config',
  events: {
    get:emptyProps(),
    getSuccess:props<{loginMethodsResults: LoginMethodResult[]}>(),
    getFailure: props<{ error: any }>(),
  },
});



//Change Login Method Config
export const changeLoginMethodConfigActions = createActionGroup({
  source: 'Change Login Method Config',
  events: {
    change:props<{method: LoginMethodsEnum, enabled: boolean}>(),
    changeSuccess:props<{method: LoginMethodsEnum, enabled: LoginMethodStatusEnum}>(),
    changeFailure: props<{ error: any }>(),
  },
});








// export const updateUserProfile = createAction(  '[Profile] Update User Profile',  props<{payload: UpdateProfileRequest}>());
// export const updateUserProfileSuccess = createAction(   '[Profile] Update User Profile Success',   props<{ learmoProfile: User }>() );
// export const updateUserProfileFailure = createAction(  '[Profile] Update User Profile Failure',  props<{ error: any }>());





//  export const updateUserEmail = createAction(  '[Profile] Update User Email',  props<{payload: {email: string, password: string}}>());
//  export const updateUserEmailSuccess = createAction(  '[Profile] Update User Email Success',  props<{response: Partial<User>}>());
//  export const updateUserEmailFailure = createAction(  '[Profile] Update User Email Failure',  props<{error: any}>());



// export const enable2FA = createAction(  '[Profile] Enable two factor authentication');
// export const enable2FASuccess = createAction(  '[Profile] Enable two factor authentication Success');
// export const enable2FAFailure = createAction(  '[Profile] Enable two factor authentication Failure',  props<{error: any}>());



// export const getUserLoginMethodConfig = createAction(   '[Profile] Get User Login Method Config' );
// export const getUserLoginMethodConfigSuccess = createAction(  '[Profile] Get User Login Method Config Success',  props<{loginMethodsResults: LoginMethodResult[]}>());
// export const getUserLoginMethodConfigFailure = createAction(  '[Profile] Get User Login Method Config Failure',  props<{error: any}>());


// export const changeLoginMethodConfig = createAction(   '[Profile] Change Login Method Config',   props<{method: LoginMethodsEnum, enabled: boolean}>() );
// export const changeLoginMethodConfigSuccess = createAction(  '[Profile] Change Login Method Config Success',  props<{method: LoginMethodsEnum, enabled: LoginMethodStatusEnum}>());
// export const changeLoginMethodConfigFailure = createAction(  '[Profile] Change Login Method Config Failure',  props<{error: any}>());
