import { MediaLibraryFileModel } from './../../../models/sales-funnel';
import {
  UserWebsite, WebsiteIdentity, WebsiteModel,
} from './../../../components/user-dashboard/portal/portal.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-browser-address-bar',
  templateUrl: './browser-address-bar.component.html',
  styleUrls: ['./browser-address-bar.component.css'],
})
export class BrowserAddressBarComponent implements OnInit {
  @Input() headOnly: boolean = true;
  @Input() favicon: MediaLibraryFileModel;
  @Input() website:
    | { siteUrl?: string; identity: Partial<WebsiteIdentity> }
    | Partial<WebsiteModel>;
  constructor() {}

  ngOnInit(): void {}
}
