import { Component, OnInit, Input } from '@angular/core';
import {
  MessengerUser,
  MessengerUserRoleEnum,
} from '../../../../../../models/owner-dashboard';
import { Router } from '@angular/router';
import { AffiliateMinimizedModel } from '../../../../../../models/owner-dashboard';
import {
  AnnouncementAffiliate,
  AnnouncementAffiliateReadStatusEnum,
} from '../../../../../../models/owner-dashboard';

@Component({
  selector: 'app-announcement-affiliate-item',
  templateUrl: './announcement-affiliate-item.component.html',
  styleUrls: ['./announcement-affiliate-item.component.css'],
})
export class AnnouncementAffiliateItemComponent implements OnInit {
  @Input() affiliate: AffiliateMinimizedModel;
  announcementAffiliateReadStatusEnum = AnnouncementAffiliateReadStatusEnum;

  @Input() me: boolean = false;
  constructor(private router: Router) {}

  ngOnInit() {}

  viewAffiliate(id: number) {
    // this.router.navigate([
    //   'dashboard',
    //   'affiliate',
    //   'my-affiliates',
    //   id,
    //   'view',
    // ]);
  }
}
