import { Country } from "src/app/models/country";

export const systemCountries: Country[] = [
    {
      "id": 5,
      "name": "AFGHANISTAN",
      "code": "AF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AF.png"
    },
    {
      "id": 8,
      "name": "ALBANIA",
      "code": "AL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AL.png"
    },
    {
      "id": 126,
      "name": "ALGERIA",
      "code": "DZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DZ.png"
    },
    {
      "id": 238,
      "name": "AMERICAN SAMOA",
      "code": "AS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AS.png"
    },
    {
      "id": 3,
      "name": "ANDORRA",
      "code": "AD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AD.png"
    },
    {
      "id": 11,
      "name": "ANGOLA",
      "code": "AO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AO.png"
    },
    {
      "id": 7,
      "name": "ANGUILLA",
      "code": "AI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AI.png"
    },
    {
      "id": 12,
      "name": "ANTARCTICA",
      "code": "AQ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AQ.png"
    },
    {
      "id": 6,
      "name": "ANTIGUA AND BARBUDA",
      "code": "AG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AG.png"
    },
    {
      "id": 239,
      "name": "ARGENTINA",
      "code": "AR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AR.png"
    },
    {
      "id": 9,
      "name": "ARMENIA",
      "code": "AM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AM.png"
    },
    {
      "id": 15,
      "name": "ARUBA",
      "code": "AW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AW.png"
    },
    {
      "id": 14,
      "name": "AUSTRALIA",
      "code": "AU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AU.png"
    },
    {
      "id": 13,
      "name": "AUSTRIA",
      "code": "AT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AT.png"
    },
    {
      "id": 16,
      "name": "AZERBAIJAN",
      "code": "AZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AZ.png"
    },
    {
      "id": 30,
      "name": "BAHAMAS",
      "code": "BS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BS.png"
    },
    {
      "id": 23,
      "name": "BAHRAIN",
      "code": "BH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BH.png"
    },
    {
      "id": 19,
      "name": "BANGLADESH",
      "code": "BD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BD.png"
    },
    {
      "id": 18,
      "name": "BARBADOS",
      "code": "BB",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BB.png"
    },
    {
      "id": 34,
      "name": "BELARUS",
      "code": "BY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BY.png"
    },
    {
      "id": 20,
      "name": "BELGIUM",
      "code": "BE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BE.png"
    },
    {
      "id": 35,
      "name": "BELIZE",
      "code": "BZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BZ.png"
    },
    {
      "id": 25,
      "name": "BENIN",
      "code": "BJ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BJ.png"
    },
    {
      "id": 26,
      "name": "BERMUDA",
      "code": "BM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BM.png"
    },
    {
      "id": 31,
      "name": "BHUTAN",
      "code": "BT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BT.png"
    },
    {
      "id": 28,
      "name": "BOLIVIA",
      "code": "BO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BO.png"
    },
    {
      "id": 17,
      "name": "BOSNIA AND HERZEGOVINA",
      "code": "BA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BA.png"
    },
    {
      "id": 33,
      "name": "BOTSWANA",
      "code": "BW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BW.png"
    },
    {
      "id": 32,
      "name": "BOUVET ISLAND",
      "code": "BV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BV.png"
    },
    {
      "id": 29,
      "name": "BRAZIL",
      "code": "BR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BR.png"
    },
    {
      "id": 168,
      "name": "BRITISH INDIAN OCEAN TERRITORY",
      "code": "IO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IO.png"
    },
    {
      "id": 27,
      "name": "BRUNEI DARUSSALAM",
      "code": "BN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BN.png"
    },
    {
      "id": 22,
      "name": "BULGARIA",
      "code": "BG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BG.png"
    },
    {
      "id": 21,
      "name": "BURKINA FASO",
      "code": "BF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BF.png"
    },
    {
      "id": 24,
      "name": "BURUNDI",
      "code": "BI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/BI.png"
    },
    {
      "id": 178,
      "name": "CAMBODIA",
      "code": "KH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KH.png"
    },
    {
      "id": 44,
      "name": "CAMEROON",
      "code": "CM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CM.png"
    },
    {
      "id": 2,
      "name": "CANADA",
      "code": "CA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CA.png"
    },
    {
      "id": 49,
      "name": "CAPE VERDE",
      "code": "CV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CV.png"
    },
    {
      "id": 185,
      "name": "CAYMAN ISLANDS",
      "code": "KY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KY.png"
    },
    {
      "id": 38,
      "name": "CENTRAL AFRICAN REPUBLIC",
      "code": "CF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CF.png"
    },
    {
      "id": 104,
      "name": "CHAD",
      "code": "TD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TD.png"
    },
    {
      "id": 43,
      "name": "CHILE",
      "code": "CL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CL.png"
    },
    {
      "id": 50,
      "name": "CHRISTMAS ISLAND",
      "code": "CX",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CX.png"
    },
    {
      "id": 36,
      "name": "COCOS (KEELING) ISLANDS",
      "code": "CC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CC.png"
    },
    {
      "id": 46,
      "name": "COLOMBIA",
      "code": "CO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CO.png"
    },
    {
      "id": 180,
      "name": "COMOROS",
      "code": "KM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KM.png"
    },
    {
      "id": 39,
      "name": "CONGO",
      "code": "CG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CG.png"
    },
    {
      "id": 37,
      "name": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
      "code": "CD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CD.png"
    },
    {
      "id": 42,
      "name": "COOK ISLANDS",
      "code": "CK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CK.png"
    },
    {
      "id": 47,
      "name": "COSTA RICA",
      "code": "CR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CR.png"
    },
    {
      "id": 41,
      "name": "COTE D'IVOIRE",
      "code": "CI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CI.png"
    },
    {
      "id": 161,
      "name": "CROATIA",
      "code": "HR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HR.png"
    },
    {
      "id": 48,
      "name": "CUBA",
      "code": "CU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CU.png"
    },
    {
      "id": 119,
      "name": "CYPRUS",
      "code": "CY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CY.png"
    },
    {
      "id": 120,
      "name": "CZECH REPUBLIC",
      "code": "CZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CZ.png"
    },
    {
      "id": 123,
      "name": "DENMARK",
      "code": "DK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DK.png"
    },
    {
      "id": 122,
      "name": "DJIBOUTI",
      "code": "DJ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DJ.png"
    },
    {
      "id": 124,
      "name": "DOMINICA",
      "code": "DM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DM.png"
    },
    {
      "id": 125,
      "name": "DOMINICAN REPUBLIC",
      "code": "DO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DO.png"
    },
    {
      "id": 127,
      "name": "ECUADOR",
      "code": "EC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/EC.png"
    },
    {
      "id": 129,
      "name": "EGYPT",
      "code": "EG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/EG.png"
    },
    {
      "id": 100,
      "name": "EL SALVADOR",
      "code": "SV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SV.png"
    },
    {
      "id": 151,
      "name": "EQUATORIAL GUINEA",
      "code": "GQ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GQ.png"
    },
    {
      "id": 131,
      "name": "ERITREA",
      "code": "ER",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ER.png"
    },
    {
      "id": 128,
      "name": "ESTONIA",
      "code": "EE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/EE.png"
    },
    {
      "id": 133,
      "name": "ETHIOPIA",
      "code": "ET",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ET.png"
    },
    {
      "id": 136,
      "name": "FALKLAND ISLANDS (MALVINAS)",
      "code": "FK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FK.png"
    },
    {
      "id": 138,
      "name": "FAROE ISLANDS",
      "code": "FO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FO.png"
    },
    {
      "id": 135,
      "name": "FIJI",
      "code": "FJ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FJ.png"
    },
    {
      "id": 134,
      "name": "FINLAND",
      "code": "FI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FI.png"
    },
    {
      "id": 139,
      "name": "FRANCE",
      "code": "FR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FR.png"
    },
    {
      "id": 144,
      "name": "FRENCH GUIANA",
      "code": "GF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GF.png"
    },
    {
      "id": 67,
      "name": "FRENCH POLYNESIA",
      "code": "PF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PF.png"
    },
    {
      "id": 105,
      "name": "FRENCH SOUTHERN TERRITORIES",
      "code": "TF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TF.png"
    },
    {
      "id": 140,
      "name": "GABON",
      "code": "GA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GA.png"
    },
    {
      "id": 148,
      "name": "GAMBIA",
      "code": "GM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GM.png"
    },
    {
      "id": 143,
      "name": "GEORGIA",
      "code": "GE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GE.png"
    },
    {
      "id": 121,
      "name": "GERMANY",
      "code": "DE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/DE.png"
    },
    {
      "id": 145,
      "name": "GHANA",
      "code": "GH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GH.png"
    },
    {
      "id": 146,
      "name": "GIBRALTAR",
      "code": "GI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GI.png"
    },
    {
      "id": 152,
      "name": "GREECE",
      "code": "GR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GR.png"
    },
    {
      "id": 147,
      "name": "GREENLAND",
      "code": "GL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GL.png"
    },
    {
      "id": 142,
      "name": "GRENADA",
      "code": "GD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GD.png"
    },
    {
      "id": 150,
      "name": "GUADELOUPE",
      "code": "GP",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GP.png"
    },
    {
      "id": 155,
      "name": "GUAM",
      "code": "GU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GU.png"
    },
    {
      "id": 154,
      "name": "GUATEMALA",
      "code": "GT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GT.png"
    },
    {
      "id": 240,
      "name": "GUERNSEY",
      "code": "GG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GG.png"
    },
    {
      "id": 149,
      "name": "GUINEA",
      "code": "GN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GN.png"
    },
    {
      "id": 156,
      "name": "GUINEA-BISSAU",
      "code": "GW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GW.png"
    },
    {
      "id": 157,
      "name": "GUYANA",
      "code": "GY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GY.png"
    },
    {
      "id": 162,
      "name": "HAITI",
      "code": "HT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HT.png"
    },
    {
      "id": 159,
      "name": "HEARD ISLAND AND MCDONALD ISLANDS",
      "code": "HM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HM.png"
    },
    {
      "id": 223,
      "name": "HOLY SEE (VATICAN CITY STATE)",
      "code": "VA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VA.png"
    },
    {
      "id": 160,
      "name": "HONDURAS",
      "code": "HN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HN.png"
    },
    {
      "id": 158,
      "name": "HONG KONG",
      "code": "HK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HK.png"
    },
    {
      "id": 163,
      "name": "HUNGARY",
      "code": "HU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/HU.png"
    },
    {
      "id": 171,
      "name": "ICELAND",
      "code": "IS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IS.png"
    },
    {
      "id": 167,
      "name": "INDIA",
      "code": "IN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IN.png"
    },
    {
      "id": 164,
      "name": "INDONESIA",
      "code": "ID",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ID.png"
    },
    {
      "id": 169,
      "name": "IRAQ",
      "code": "IQ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IQ.png"
    },
    {
      "id": 165,
      "name": "IRELAND",
      "code": "IE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IE.png"
    },
    {
      "id": 241,
      "name": "ISLE OF MAN",
      "code": "IM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IM.png"
    },
    {
      "id": 166,
      "name": "ISRAEL",
      "code": "IL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IL.png"
    },
    {
      "id": 172,
      "name": "ITALY",
      "code": "IT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/IT.png"
    },
    {
      "id": 173,
      "name": "JAMAICA",
      "code": "JM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/JM.png"
    },
    {
      "id": 175,
      "name": "JAPAN",
      "code": "JP",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/JP.png"
    },
    {
      "id": 242,
      "name": "JERSEY",
      "code": "JE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/JE.png"
    },
    {
      "id": 174,
      "name": "JORDAN",
      "code": "JO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/JO.png"
    },
    {
      "id": 186,
      "name": "KAZAKHSTAN",
      "code": "KZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KZ.png"
    },
    {
      "id": 176,
      "name": "KENYA",
      "code": "KE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KE.png"
    },
    {
      "id": 179,
      "name": "KIRIBATI",
      "code": "KI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KI.png"
    },
    {
      "id": 182,
      "name": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      "code": "KP",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KP.png"
    },
    {
      "id": 183,
      "name": "KOREA, REPUBLIC OF",
      "code": "KR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KR.png"
    },
    {
      "id": 184,
      "name": "KUWAIT",
      "code": "KW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KW.png"
    },
    {
      "id": 177,
      "name": "KYRGYZSTAN",
      "code": "KG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KG.png"
    },
    {
      "id": 187,
      "name": "LAO PEOPLE",
      "code": "LA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LA.png"
    },
    {
      "id": 196,
      "name": "LATVIA",
      "code": "LV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LV.png"
    },
    {
      "id": 188,
      "name": "LEBANON",
      "code": "LB",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LB.png"
    },
    {
      "id": 193,
      "name": "LESOTHO",
      "code": "LS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LS.png"
    },
    {
      "id": 192,
      "name": "LIBERIA",
      "code": "LR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LR.png"
    },
    {
      "id": 197,
      "name": "LIBYAN ARAB JAMAHIRIYA",
      "code": "LY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LY.png"
    },
    {
      "id": 190,
      "name": "LIECHTENSTEIN",
      "code": "LI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LI.png"
    },
    {
      "id": 194,
      "name": "LITHUANIA",
      "code": "LT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LT.png"
    },
    {
      "id": 195,
      "name": "LUXEMBOURG",
      "code": "LU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LU.png"
    },
    {
      "id": 207,
      "name": "MACAO",
      "code": "MO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MO.png"
    },
    {
      "id": 203,
      "name": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
      "code": "MK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MK.png"
    },
    {
      "id": 201,
      "name": "MADAGASCAR",
      "code": "MG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MG.png"
    },
    {
      "id": 215,
      "name": "MALAWI",
      "code": "MW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MW.png"
    },
    {
      "id": 217,
      "name": "MALAYSIA",
      "code": "MY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MY.png"
    },
    {
      "id": 214,
      "name": "MALDIVES",
      "code": "MV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MV.png"
    },
    {
      "id": 204,
      "name": "MALI",
      "code": "ML",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ML.png"
    },
    {
      "id": 212,
      "name": "MALTA",
      "code": "MT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MT.png"
    },
    {
      "id": 202,
      "name": "MARSHALL ISLANDS",
      "code": "MH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MH.png"
    },
    {
      "id": 209,
      "name": "MARTINIQUE",
      "code": "MQ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MQ.png"
    },
    {
      "id": 210,
      "name": "MAURITANIA",
      "code": "MR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MR.png"
    },
    {
      "id": 213,
      "name": "MAURITIUS",
      "code": "MU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MU.png"
    },
    {
      "id": 233,
      "name": "MAYOTTE",
      "code": "YT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/YT.png"
    },
    {
      "id": 216,
      "name": "MEXICO",
      "code": "MX",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MX.png"
    },
    {
      "id": 137,
      "name": "MICRONESIA, FEDERATED STATES OF",
      "code": "FM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/FM.png"
    },
    {
      "id": 200,
      "name": "MOLDOVA, REPUBLIC OF",
      "code": "MD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MD.png"
    },
    {
      "id": 199,
      "name": "MONACO",
      "code": "MC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MC.png"
    },
    {
      "id": 206,
      "name": "MONGOLIA",
      "code": "MN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MN.png"
    },
    {
      "id": 243,
      "name": "MONTENEGRO",
      "code": "ME",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ME.png"
    },
    {
      "id": 211,
      "name": "MONTSERRAT",
      "code": "MS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MS.png"
    },
    {
      "id": 198,
      "name": "MOROCCO",
      "code": "MA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MA.png"
    },
    {
      "id": 51,
      "name": "MOZAMBIQUE",
      "code": "MZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MZ.png"
    },
    {
      "id": 52,
      "name": "NAMIBIA",
      "code": "NA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NA.png"
    },
    {
      "id": 61,
      "name": "NAURU",
      "code": "NR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NR.png"
    },
    {
      "id": 60,
      "name": "NEPAL",
      "code": "NP",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NP.png"
    },
    {
      "id": 58,
      "name": "NETHERLANDS",
      "code": "NL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NL.png"
    },
    {
      "id": 10,
      "name": "NETHERLANDS ANTILLES",
      "code": "AN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AN.png"
    },
    {
      "id": 53,
      "name": "NEW CALEDONIA",
      "code": "NC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NC.png"
    },
    {
      "id": 63,
      "name": "NEW ZEALAND",
      "code": "NZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NZ.png"
    },
    {
      "id": 57,
      "name": "NICARAGUA",
      "code": "NI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NI.png"
    },
    {
      "id": 54,
      "name": "NIGER",
      "code": "NE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NE.png"
    },
    {
      "id": 56,
      "name": "NIGERIA",
      "code": "NG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NG.png"
    },
    {
      "id": 62,
      "name": "NIUE",
      "code": "NU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NU.png"
    },
    {
      "id": 55,
      "name": "NORFOLK ISLAND",
      "code": "NF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NF.png"
    },
    {
      "id": 208,
      "name": "NORTHERN MARIANA ISLANDS",
      "code": "MP",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/MP.png"
    },
    {
      "id": 59,
      "name": "NORWAY",
      "code": "NO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/NO.png"
    },
    {
      "id": 64,
      "name": "OMAN",
      "code": "OM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/OM.png"
    },
    {
      "id": 70,
      "name": "PAKISTAN",
      "code": "PK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PK.png"
    },
    {
      "id": 77,
      "name": "PALAU",
      "code": "PW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PW.png"
    },
    {
      "id": 75,
      "name": "PALESTINIAN TERRITORY, OCCUPIED",
      "code": "PS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PS.png"
    },
    {
      "id": 65,
      "name": "PANAMA",
      "code": "PA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PA.png"
    },
    {
      "id": 68,
      "name": "PAPUA NEW GUINEA",
      "code": "PG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PG.png"
    },
    {
      "id": 78,
      "name": "PARAGUAY",
      "code": "PY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PY.png"
    },
    {
      "id": 66,
      "name": "PERU",
      "code": "PE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PE.png"
    },
    {
      "id": 69,
      "name": "PHILIPPINES",
      "code": "PH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PH.png"
    },
    {
      "id": 73,
      "name": "PITCAIRN",
      "code": "PN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PN.png"
    },
    {
      "id": 71,
      "name": "POLAND",
      "code": "PL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PL.png"
    },
    {
      "id": 76,
      "name": "PORTUGAL",
      "code": "PT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PT.png"
    },
    {
      "id": 74,
      "name": "PUERTO RICO",
      "code": "PR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PR.png"
    },
    {
      "id": 79,
      "name": "QATAR",
      "code": "QA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/QA.png"
    },
    {
      "id": 80,
      "name": "REUNION",
      "code": "RE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/RE.png"
    },
    {
      "id": 81,
      "name": "ROMANIA",
      "code": "RO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/RO.png"
    },
    {
      "id": 82,
      "name": "RUSSIAN FEDERATION",
      "code": "RU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/RU.png"
    },
    {
      "id": 83,
      "name": "RWANDA",
      "code": "RW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/RW.png"
    },
    {
      "id": 90,
      "name": "SAINT HELENA",
      "code": "SH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SH.png"
    },
    {
      "id": 181,
      "name": "SAINT KITTS AND NEVIS",
      "code": "KN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/KN.png"
    },
    {
      "id": 189,
      "name": "SAINT LUCIA",
      "code": "LC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LC.png"
    },
    {
      "id": 72,
      "name": "SAINT PIERRE AND MIQUELON",
      "code": "PM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/PM.png"
    },
    {
      "id": 224,
      "name": "SAINT VINCENT AND THE GRENADINES",
      "code": "VC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VC.png"
    },
    {
      "id": 231,
      "name": "SAMOA",
      "code": "WS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/WS.png"
    },
    {
      "id": 95,
      "name": "SAN MARINO",
      "code": "SM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SM.png"
    },
    {
      "id": 99,
      "name": "SAO TOME AND PRINCIPE",
      "code": "ST",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ST.png"
    },
    {
      "id": 84,
      "name": "SAUDI ARABIA",
      "code": "SA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SA.png"
    },
    {
      "id": 96,
      "name": "SENEGAL",
      "code": "SN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SN.png"
    },
    {
      "id": 234,
      "name": "SERBIA",
      "code": "RS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/RS.png"
    },
    {
      "id": 86,
      "name": "SEYCHELLES",
      "code": "SC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SC.png"
    },
    {
      "id": 94,
      "name": "SIERRA LEONE",
      "code": "SL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SL.png"
    },
    {
      "id": 89,
      "name": "SINGAPORE",
      "code": "SG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SG.png"
    },
    {
      "id": 93,
      "name": "SLOVAKIA",
      "code": "SK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SK.png"
    },
    {
      "id": 91,
      "name": "SLOVENIA",
      "code": "SI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SI.png"
    },
    {
      "id": 85,
      "name": "SOLOMON ISLANDS",
      "code": "SB",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SB.png"
    },
    {
      "id": 97,
      "name": "SOMALIA",
      "code": "SO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SO.png"
    },
    {
      "id": 235,
      "name": "SOUTH AFRICA",
      "code": "ZA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ZA.png"
    },
    {
      "id": 153,
      "name": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
      "code": "GS",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GS.png"
    },
    {
      "id": 132,
      "name": "SPAIN",
      "code": "ES",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ES.png"
    },
    {
      "id": 191,
      "name": "SRI LANKA",
      "code": "LK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/LK.png"
    },
    {
      "id": 87,
      "name": "SUDAN",
      "code": "SD",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SD.png"
    },
    {
      "id": 98,
      "name": "SURINAME",
      "code": "SR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SR.png"
    },
    {
      "id": 92,
      "name": "SVALBARD AND JAN MAYEN",
      "code": "SJ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SJ.png"
    },
    {
      "id": 102,
      "name": "SWAZILAND",
      "code": "SZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SZ.png"
    },
    {
      "id": 88,
      "name": "SWEDEN",
      "code": "SE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SE.png"
    },
    {
      "id": 40,
      "name": "SWITZERLAND",
      "code": "CH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/CH.png"
    },
    {
      "id": 101,
      "name": "SYRIAN ARAB REPUBLIC",
      "code": "SY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/SY.png"
    },
    {
      "id": 117,
      "name": "TAIWAN",
      "code": "TW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TW.png"
    },
    {
      "id": 108,
      "name": "TAJIKISTAN",
      "code": "TJ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TJ.png"
    },
    {
      "id": 118,
      "name": "TANZANIA, UNITED REPUBLIC OF",
      "code": "TZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TZ.png"
    },
    {
      "id": 107,
      "name": "THAILAND",
      "code": "TH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TH.png"
    },
    {
      "id": 110,
      "name": "TIMOR-LESTE",
      "code": "TL",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TL.png"
    },
    {
      "id": 106,
      "name": "TOGO",
      "code": "TG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TG.png"
    },
    {
      "id": 109,
      "name": "TOKELAU",
      "code": "TK",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TK.png"
    },
    {
      "id": 113,
      "name": "TONGA",
      "code": "TO",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TO.png"
    },
    {
      "id": 115,
      "name": "TRINIDAD AND TOBAGO",
      "code": "TT",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TT.png"
    },
    {
      "id": 112,
      "name": "TUNISIA",
      "code": "TN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TN.png"
    },
    {
      "id": 114,
      "name": "TURKEY",
      "code": "TR",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TR.png"
    },
    {
      "id": 111,
      "name": "TURKMENISTAN",
      "code": "TM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TM.png"
    },
    {
      "id": 103,
      "name": "TURKS AND CAICOS ISLANDS",
      "code": "TC",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TC.png"
    },
    {
      "id": 116,
      "name": "TUVALU",
      "code": "TV",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/TV.png"
    },
    {
      "id": 219,
      "name": "UGANDA",
      "code": "UG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/UG.png"
    },
    {
      "id": 218,
      "name": "UKRAINE",
      "code": "UA",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/UA.png"
    },
    {
      "id": 4,
      "name": "UNITED ARAB EMIRATES",
      "code": "AE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/AE.png"
    },
    {
      "id": 141,
      "name": "UNITED KINGDOM",
      "code": "GB",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/GB.png"
    },
    {
      "id": 1,
      "name": "UNITED STATES",
      "code": "US",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/US.png"
    },
    {
      "id": 220,
      "name": "UNITED STATES MINOR OUTLYING ISLANDS",
      "code": "UM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/UM.png"
    },
    {
      "id": 221,
      "name": "URUGUAY",
      "code": "UY",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/UY.png"
    },
    {
      "id": 222,
      "name": "UZBEKISTAN",
      "code": "UZ",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/UZ.png"
    },
    {
      "id": 229,
      "name": "VANUATU",
      "code": "VU",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VU.png"
    },
    {
      "id": 225,
      "name": "VENEZUELA",
      "code": "VE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VE.png"
    },
    {
      "id": 228,
      "name": "VIET NAM",
      "code": "VN",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VN.png"
    },
    {
      "id": 226,
      "name": "VIRGIN ISLANDS, BRITISH",
      "code": "VG",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VG.png"
    },
    {
      "id": 227,
      "name": "VIRGIN ISLANDS, U.S.",
      "code": "VI",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/VI.png"
    },
    {
      "id": 230,
      "name": "WALLIS AND FUTUNA",
      "code": "WF",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/WF.png"
    },
    {
      "id": 130,
      "name": "WESTERN SAHARA",
      "code": "EH",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/EH.png"
    },
    {
      "id": 232,
      "name": "YEMEN",
      "code": "YE",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/YE.png"
    },
    {
      "id": 236,
      "name": "ZAMBIA",
      "code": "ZM",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ZM.png"
    },
    {
      "id": 237,
      "name": "ZIMBABWE",
      "code": "ZW",
      "flag": "http://74.208.68.245/remote/img/flags-by-code-2/ZW.png"
    }
  ];


  export function findCountryByCodeOrName(countryCodeOrName: string): Country | undefined {
    let country = systemCountries.find((country) => country.code.toLocaleLowerCase() == countryCodeOrName.toLocaleLowerCase() || country.name.toLocaleLowerCase() == countryCodeOrName.toLocaleLowerCase());
    return country;
  }