
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';



export const passwordRecoveryRequestActions = createActionGroup({
  source: ' Password Recovery Request',
  events: {
    create:props<{ email: string }>(),
    createSuccess: props<{ email: string }>(),
    createFailure: props<{ error: any }>(),
  },
});




export const passwordRecoveryConfirmActions = createActionGroup({
  source: 'Password Recovery Confirmation Success',
  events: {
    Confirm:props<{ userId: string, secret: string, password: string }>(),
    ConfirmSuccess: emptyProps(),
    ConfirmFailure: props<{ error: any }>(),
  },
});








// export const createPasswordRecoveryRequest = createAction('[Forgot] Password Recovery Request',props<{ email: string }>());
// export const createPasswordRecoveryRequestSuccess = createAction( '[Forgot] Password Recovery Request Success', props<{ email: string }>());
// export const createPasswordRecoveryRequestFailure = createAction( '[Forgot] Password Recovery Request Failure', props<{ error: any }>());




//export const passwordRecoveryConfirm = createAction( '[Forgot] Password Recovery Confirmation', props<{ userId: string, secret: string, password: string, passwordAgain: string }>());
//export const passwordRecoveryConfirmSuccess = createAction( '[Forgot] Password Recovery Confirmation Success');
//export const passwordRecoveryConfirmFailure = createAction(  '[Forgot] Password Recovery Confirmation Failure',  props<{ error: any }>());
