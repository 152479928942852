import { ApiService } from '../../../../services/lsg.api.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';

import { Store } from '@ngrx/store';

import { BlogPostItem } from '../../../../../models/blog.model';
import { websitePaymentGatewaysActions } from './website-payments.actions';




@Injectable()
export class WebsitePaymentsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }


  
  loadWebsitePaymentGateways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(websitePaymentGatewaysActions.load),
      mergeMap(() =>
        this.api.getPaymentGatewaysStatus().pipe(
          map((response) =>
            websitePaymentGatewaysActions.loadSuccess({ paymentGateways: response })
          ),
          catchError((error) =>
            of(websitePaymentGatewaysActions.loadFailure({ error }))
          )
        )
      )
    )
  );
}
