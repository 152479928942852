import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotificationsService } from '../../../../services/notifications.service';
import { NotificationElement } from 'src/app/models/owner-dashboard';


@Component({
  selector: 'app-owner-notifications-list',
  templateUrl: './owner-notifications-list.component.html',
  styleUrls: ['./owner-notifications-list.component.css'],
})
export class OwnerNotificationsListComponent implements OnInit {
  @Output() NotificationElementClicked = new EventEmitter<NotificationElement>();
  // @Input() notifications: NotificationElement[] = [
  //   {
  //     id: 1,
  //     content: ', has accepted your invitation to join your network.',
  //     target: { label: 'Hossam Abdallah (hossam@gmail.com)' },
  //     type: OwnerNotificationTypeEnum.affiliate_accepted,
  //     status: NotificationStatusEnum.unread,
  //     image: 'https://picsum.photos/200',
  //     time: 1648358110,
  //   },
  // ];

  constructor(public notificationsService: NotificationsService) {}

  ngOnInit(): void {}
}
