import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
  providedIn: 'root'
})
export class CustomNgbModalService extends NgbModal {
  open(content: any, options: NgbModalOptions = {}): NgbModalRef {
    const originalBeforeDismiss = options.beforeDismiss;
    options.beforeDismiss = () => {
      return new Promise((resolve, reject) => {
        if (originalBeforeDismiss) {
          originalBeforeDismiss();
        }
        const modal = document.querySelector('.modal');
        const modalDialog = document.querySelector('.modal-dialog');

        if (modalDialog.classList.contains('modal-animate-in')) {
          
          modalDialog.classList.replace('modal-animate-in', 'modal-animate-out')
          
          // modalDialog.classList.remove('modal-animate-in');
          // modalDialog.classList.add('modal-animate-out');
        }
        else {
          modal.classList.add('overflow-y-hidden')
          modalDialog.classList.add('modal-animate-out');
        }


        

        setTimeout(() => {
          modal.classList.remove('overflow-y-hidden')
          modalDialog.classList.remove('modal-animate-out');
          resolve(true);
        }, 350);


      });


    };
    return super.open(content, options);
  }
}
