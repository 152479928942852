// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 900px) {
  .wizard-image {
    display: none;
  }

  .summary-container {
    width: 100%;
  }

}

.summary-container {
  gap: 1.5em;
  max-width: 670px;
  min-width: 600px;
}
/* @media screen and (min-width:900px) and (max-width: 1024px) {
  .newflexcol,.flexcol{width: 46%;}

} */


.wizard-image img {
  max-width: 100%;
  margin-left: -25%;
  width: 550px;
}

.slideInAnimation {
  animation: slidein 4s forwards;
}

.slideOutAnimation {
  animation: slideout 6s forwards;
}


@keyframes slidein {
  100% { right: 250px; }
}


@keyframes slideout {
  0% { right: 250px; }
  100% { right: 50%; }
}


.option-card {
  transition: all 0.3s ease-in-out;
}

.option-card.active {
  border-color: #00af76 !important;
}

.option-card.inactive {
  opacity: 0.7;
}
.option-card.inactive:hover {
  opacity: 1;
}
/* .option-card:hover .svg-icon {
  -webkit-transition: fill 0.3s ease !important;
  transition: fill 0.3s ease !important;
  fill: #fff; }

.option-card:hover {
  background-color: #0078FF !important;
  color: #fff !important;
} */
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/select-portal/select-portal.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;AAEF;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;AACA;;;GAGG;;;AAGH;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EAEE,8BAA8B;AAChC;;AAEA;EAEE,+BAA+B;AACjC;;;AAGA;EACE,OAAO,YAAY,EAAE;AACvB;;;AAGA;EACE,KAAK,YAAY,EAAE;EACnB,OAAO,UAAU,EAAE;AACrB;;;AAGA;EAEE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;;;;;;;;GAQG","sourcesContent":["@media screen and (max-width: 900px) {\n  .wizard-image {\n    display: none;\n  }\n\n  .summary-container {\n    width: 100%;\n  }\n\n}\n\n.summary-container {\n  gap: 1.5em;\n  max-width: 670px;\n  min-width: 600px;\n}\n/* @media screen and (min-width:900px) and (max-width: 1024px) {\n  .newflexcol,.flexcol{width: 46%;}\n\n} */\n\n\n.wizard-image img {\n  max-width: 100%;\n  margin-left: -25%;\n  width: 550px;\n}\n\n.slideInAnimation {\n  -webkit-animation: slidein 4s forwards;\n  animation: slidein 4s forwards;\n}\n\n.slideOutAnimation {\n  -webkit-animation: slideout 6s forwards;\n  animation: slideout 6s forwards;\n}\n\n\n@keyframes slidein {\n  100% { right: 250px; }\n}\n\n\n@keyframes slideout {\n  0% { right: 250px; }\n  100% { right: 50%; }\n}\n\n\n.option-card {\n  -webkit-transition: all 0.3s ease-in-out;\n  transition: all 0.3s ease-in-out;\n}\n\n.option-card.active {\n  border-color: #00af76 !important;\n}\n\n.option-card.inactive {\n  opacity: 0.7;\n}\n.option-card.inactive:hover {\n  opacity: 1;\n}\n/* .option-card:hover .svg-icon {\n  -webkit-transition: fill 0.3s ease !important;\n  transition: fill 0.3s ease !important;\n  fill: #fff; }\n\n.option-card:hover {\n  background-color: #0078FF !important;\n  color: #fff !important;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
