const ICONS = {
    category_name: 'Location',
    icons_list: [
      "geolocation-home",
      "map",
      "telephone-geolocation",
      "satellite",
      "flag",
      "focus",
      "pointers",
      "compass",
      "route",
      "geolocation"



    ],

};


export default ICONS
