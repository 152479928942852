// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  max-width: 1094px;
  margin: 0 auto;
}

.item-layout {
  width: 30%;
  margin-left: 40px;
  position: relative;
  text-align: center;
  height: 120px;
  /* background: #E3E8EC; */
}

.item-layout::after {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 2px;
  right: -40px;
  background: #e3e8ec;
  top: 50%;
  transform: translateY(100%);
}

.item-layout:nth-of-type(1) {
  margin-left: 0;
}
.item-layout:last-child::after {
  width: 0;
  height: 0;
  background-color: none;
}

.item-layout:nth-of-type(1)::after {
  /* border-left: 60px solid #F7F7F7; */
}

.item-layout-image {
  /* margin-top: -50px; */
  width: 50px;
  height: 50px;
  border-radius: 20px;
}

.animate {
  opacity: 0;
  transform: scale(0.25);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  animation: append-animate 0.6s ease-in-out forwards;
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/funnels-layout/funnel-item/funnel-item-info-popup/funnel-item-page/funnel-item-page.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;AACA;EACE,QAAQ;EACR,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,mDAAmD;EACnD,mDAAmD;AACrD;;AAEA;EACE;IACE,mBAAmB;IACnB,UAAU;EACZ;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".list {\n  display: flex;\n  max-width: 1094px;\n  margin: 0 auto;\n}\n\n.item-layout {\n  width: 30%;\n  margin-left: 40px;\n  position: relative;\n  text-align: center;\n  height: 120px;\n  /* background: #E3E8EC; */\n}\n\n.item-layout::after {\n  transition: all 0.2s ease-in-out;\n  content: \"\";\n  position: absolute;\n  display: inline-block;\n  width: 40px;\n  height: 2px;\n  right: -40px;\n  background: #e3e8ec;\n  top: 50%;\n  transform: translateY(100%);\n}\n\n.item-layout:nth-of-type(1) {\n  margin-left: 0;\n}\n.item-layout:last-child::after {\n  width: 0;\n  height: 0;\n  background-color: none;\n}\n\n.item-layout:nth-of-type(1)::after {\n  /* border-left: 60px solid #F7F7F7; */\n}\n\n.item-layout-image {\n  /* margin-top: -50px; */\n  width: 50px;\n  height: 50px;\n  border-radius: 20px;\n}\n\n.animate {\n  opacity: 0;\n  transform: scale(0.25);\n  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);\n  animation: append-animate 0.6s ease-in-out forwards;\n}\n\n@keyframes append-animate {\n  from {\n    transform: scale(0);\n    opacity: 0;\n  }\n  to {\n    transform: scale(1);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
