// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.swatch {
  border-radius: 0.7em;
  padding: 0;
  outline: none !important;
  float: left;
  transition: all 0.2s ease-in-out;
  border: 3px solid var(--bs-light) !important;
  border-collapse: separate !important;
  overflow: hidden;
}

.swatch:hover:not(.active):not(.custom) {
  transition: all 0.2s ease-in-out;
  transform:scale(1.15);
}

.swatch:hover:not(.active).custom {
  transition: all 0.2s ease-in-out;
  transform:scale(1.05);
}

.swatch.active {
  border-radius: 0.9em;
  transition: all 0.2s ease-in-out;
  transform:scale(1.3) translateY(-5px);
}

.swatch.active {
  /* border: 2px solid #c7c7cc; */
}



`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-identity/portal-identity-page/basic-information/color-select/color-select.component.css"],"names":[],"mappings":";;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,wBAAwB;EACxB,WAAW;EACX,gCAAgC;EAChC,4CAA4C;EAC5C,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,gCAAgC;EAChC,qCAAqC;AACvC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["\n\n.swatch {\n  border-radius: 0.7em;\n  padding: 0;\n  outline: none !important;\n  float: left;\n  transition: all 0.2s ease-in-out;\n  border: 3px solid var(--bs-light) !important;\n  border-collapse: separate !important;\n  overflow: hidden;\n}\n\n.swatch:hover:not(.active):not(.custom) {\n  transition: all 0.2s ease-in-out;\n  transform:scale(1.15);\n}\n\n.swatch:hover:not(.active).custom {\n  transition: all 0.2s ease-in-out;\n  transform:scale(1.05);\n}\n\n.swatch.active {\n  border-radius: 0.9em;\n  transition: all 0.2s ease-in-out;\n  transform:scale(1.3) translateY(-5px);\n}\n\n.swatch.active {\n  /* border: 2px solid #c7c7cc; */\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
