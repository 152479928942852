import { ProductItem, ProductModel, ProductTypeModel } from '../../../../../components/user-dashboard/product/models/product';
import { Router } from '@angular/router';
import { SharedProductService } from '../../../../../components/user-dashboard/product/shared-product.service';

import { Component, OnInit, Input } from '@angular/core';
import { DropdownItem } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { FunnelItem } from 'src/app/models/funnels';
import { SharedFunnelService } from 'src/app/components/user-dashboard/funnel/shared-funnel.service';


@Component({
  selector: 'app-funnel-list-item',
  templateUrl: './funnel-list-item.component.html',
  styleUrls: ['./funnel-list-item.component.css']
})
export class FunnelListItemComponent implements OnInit {
  productTypeModel = ProductTypeModel
  @Input() funnel: FunnelItem;

  onClickRouter:string | any[];
  dropdownItems: DropdownItem [] = [

  ]
  constructor(public sharedFunnelService: SharedFunnelService, private router: Router) { }

  ngOnInit() {
    this.generateFunnelDropdown()

  }

generateFunnelDropdown() {
    this.onClickRouter = ['/dashboard', 'funnel', this.funnel.id, 'edit'];
    this.dropdownItems = [
      {
        label: 'Edit Funnel Flow',
        iconSvg: 'assets/images/svg/system/funnel-builder.svg',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        routerLink: ['/dashboard', 'funnel', this.funnel.id, 'edit']
      },
      {
        label: 'Edit Details',
        iconSvg: 'assets/images/svg/system/edit.svg',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        routerLink: ['/dashboard', 'funnel', this.funnel.id, 'edit']
      },
      {
        label: 'Duplicate',
        iconSvg: 'assets/images/svg/system/copy.svg',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        action : () => {
          this.cloneFunnel(this.funnel)
        },
      },
      undefined,
      {
        label: 'Delete',
        iconSvg: 'assets/images/svg/system/trash.svg',
        colorSet: 'danger',
        colorSetHover: 'danger',
        action: ()=>{this.deleteFunnel(this.funnel)}
      }
    ]
  }

  editFunnel = (funnel: FunnelItem) => {
    this.sharedFunnelService.goToEdit(funnel.id)
  }


  deleteFunnel = (funnel: FunnelItem) => {
    this.sharedFunnelService.deleteFunnel(funnel);
  }
  cloneFunnel = (funnel: FunnelItem) => {
    // this.sharedFunnelService.cloneOfferAlert(funnel);
  }


}
