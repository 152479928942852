import { ToastrService } from 'ngx-toastr';
import { DomainItem } from 'src/app/models/domain';
import { SharedDomainService } from './../domains/shared-domain.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { UserWebsitesResult } from './dto/portal.output';

import { WizardModel, WizardStepStatusEnum } from './../../../models/wizard';
import {
  FunnelCategory,
  FunnelTemplateItem,
  PortalCategory,
  PortalFunnels,
  PortalTemplates,
  TemplateItem,
  UserWebsite,
  UserWebsites,
} from './portal.model';
import {
  CreateWebsiteInput,
  GlobalWizardData,
  GlobalWizardSettings,
} from './dto/portal.input';
import { Router } from '@angular/router';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map, lastValueFrom } from 'rxjs';
import {
  NumbersOfDomainsAndPortalsForUser,
  NumbersOfDomainsOrPortalsForUser,
} from 'src/app/models/subscription';
import { checkPortalRegex } from 'src/app/shared/services/regex';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/shared/services';
import { Confirm, Report } from 'notiflix';
import { HttpErrorResponse } from '@angular/common/http';
import { PortalActiveChoiceEnum } from 'src/app/shared/wizard/select-portal/select-portal.component';
import { SharedPortalViewService } from './view/shared-portal-view.service';


export enum PortalTypeEnum {}

@Injectable({
  providedIn: 'root',
})
export class SharedPortalService {
  // loadingTemplates: boolean =
  userWebsites: UserWebsites = { websites: [] };

  @Output() setFunnelPortalType: EventEmitter<PortalActiveChoiceEnum> =
    new EventEmitter<PortalActiveChoiceEnum>();

  userPermissions: NumbersOfDomainsAndPortalsForUser;
  numbersOfDomainsAndPortalsForUser: NumbersOfDomainsAndPortalsForUser;
  numbersOfDomainsForUser: NumbersOfDomainsOrPortalsForUser;
  numbersOfPortalsForUser: NumbersOfDomainsOrPortalsForUser;

  loading: boolean = false;
  portals: {
    currentPortal: {
      index: number;
      portal: UserWebsite;
    };
  } = {
    currentPortal: {
      index: null,
      portal: null,
    },
  };

  templates: {
    portalTemplates: PortalTemplates;
    currentCategory: {
      index: number;
      category: PortalCategory;
    };
    currentTemplate: {
      index: number;
      template: TemplateItem;
    };
  } = {
    portalTemplates: { categories: [] },
    currentCategory: {
      category: null,
      index: null,
    },
    currentTemplate: {
      index: null,
      template: null,
    },
  };

  funnels: {
    portalFunnels: PortalFunnels;
    currentCategory: {
      index: number;
      category: FunnelCategory;
    };
    currentFunnel: {
      index: number;
      funnel: FunnelTemplateItem;
    };
  } = {
    portalFunnels: { categories: [] },
    currentCategory: {
      category: null,
      index: null,
    },
    currentFunnel: {
      index: null,
      funnel: null,
    },
  };

  countForFinishing: {
    counter: any;
    count: number;
  } = { count: null, counter: null };

  templatesNavigationCategory: { index: number; category: PortalCategory } = {
    index: null,
    category: null,
  };
  funnelsNavigationCategory: { index: number; category: FunnelCategory } = {
    index: null,
    category: null,
  };

  wizardSettings: GlobalWizardSettings = {
    portalType: null,
    summaryAnimation: null,
    finalStepLabel: 'Create',
  };
  wizardData: GlobalWizardData = {
    funnelId: null,
    portalId: null,
    domainId: null,
    domain: null,
    portalName: null,
    portalTitle: null,
    templateId: null,
    portalIdTemp: null,
  };
  createPortalObject: CreateWebsiteInput = new CreateWebsiteInput();
  // createFunnelObject: {portalType}

  public wizardModel: WizardModel;
  constructor(
    private router: Router,
    private api: ApiService,
    private sharedOwnerDashboardService: SharedOwnerDashboardService,
    private sharedPortalViewService: SharedPortalViewService,
    private sharedDomainService: SharedDomainService,
    private toastr: ToastrService
  ) {
    this.handleGetCountOfDomainsError =
      this.handleGetCountOfDomainsError.bind(this);
    this.handleGetCountOfPortalsError =
      this.handleGetCountOfPortalsError.bind(this);
  }

  // async startNewDomainOnboarding(id = this.wizardData.domainId) {
  //   this.wizardData.domain = await this.sharedDomainService.getDomainById(id);
  //   this.getUserPortals;
  //   Swal.fire({
  //     title: 'Well Done!',
  //     html: `
  //     We're on the way to create a new portal to be linked with the domain <br><b>${this.wizardData.domain.info.name}.${this.wizardData.domain.info.tld}</b>
  //     `,
  //     confirmButtonText: 'Ok, Great!',
  //     allowOutsideClick: true,
  //     allowEscapeKey: true,

  //     customClass: {
  //       confirmButton: 'btn-success btn',
  //     },
  //     icon: 'success',
  //   }).then((result) => {});
  // }




  getUserPortalsErrorHandler = (error: HttpErrorResponse) => {
    this.toastr.error(error.message, 'Something Went Wrong');
  };

  callScreenShot = async (url: string, fullPage: boolean): Promise<string> => {
    const screenshot$ = this.api.screenshot(
      'https://' + url,
      fullPage,
      this.handleScreenshotError
    );
    const screenshot = await lastValueFrom(screenshot$);

    return screenshot?.img;
  };

  handleScreenshotError = (error: HttpErrorResponse) => {
    const msg = 'Something went wrong in portal service.';
    // return an observable with a user friendly message
    return error.message || msg;
  };

  public resetWizard() {
    this.countForFinishing = { count: null, counter: null };
    this.templates.currentTemplate = { index: null, template: null };
    this.templates.currentCategory = { index: null, category: null };

    this.funnels.currentFunnel = { index: null, funnel: null };
    this.funnels.currentCategory = { index: null, category: null };

    this.wizardData = {
      funnelId: null,
      portalId: null,
      portalName: null,
      portalTitle: null,
      templateId: null,
    };
    this.portals = {
      currentPortal: {
        index: null,
        portal: null,
      },
    };
    this.wizardSettings = {
      portalType: null,
      summaryAnimation: null,
      finalStepLabel: 'Create',
    };
    this.wizardModel?.resetWizard();
  }

  startLoading() {
    this.loading = true;
  }
  stopLoading() {
    this.loading = false;
  }

  onChooseExistingPortal(portal) {
    this.resetNewPortalData();
    this.setActivateChoice(PortalActiveChoiceEnum.existing);
    this.setSelectedPortal(portal);
    this.wizardModel.currentStep.step.hideButtons.next = false;
    this.wizardModel.currentStep.step.hideButtons.back = false;
  }

  resetNewPortalData() {
    this.wizardData.templateId = null;
    this.wizardData.portalName = null;
    this.templates = {
      portalTemplates: { categories: [] },
      currentCategory: {
        category: null,
        index: null,
      },
      currentTemplate: {
        index: null,
        template: null,
      },
    };
  }

  setSelectedPortal(portal: UserWebsite) {
    let portalIndex = this.userWebsites.websites.findIndex(
      (a) => a.siteId == portal.siteId
    );

    if (portalIndex > -1) {
      this.wizardData.portalId = portal.siteId;
      this.portals.currentPortal = {
        index: portalIndex,
        portal,
      };
    }
  }

  setActivateChoice(choice: PortalActiveChoiceEnum) {
    // this.sharedPortalService?.wizardModel?.markCurrentStepAsValid();
    this.setFunnelPortalType.emit(choice);
    this.wizardSettings.portalType = choice;
  }

  setInactiveChoice() {
    this.wizardSettings.portalType = null;
  }

  async onChooseCreatePortal() {
    if (
      !this.numbersOfDomainsAndPortalsForUser?.portals?.current ||
      !this.numbersOfDomainsAndPortalsForUser?.portals?.max
    ) {
      await this.getCountOfSubscriptionPermissionsPortalsAndDomains();
    }
    if (
      this.numbersOfDomainsAndPortalsForUser.portals.current >=
      this.numbersOfDomainsAndPortalsForUser.portals.max
    ) {
      this.showPortalLimitAlert();
    } else {
      this.goToCreatePortal();
    }
  }

  goToPortals() {
    this.router.navigate(['dashboard', 'portal']);
  }

  goToCreatePortal() {
    this.router.navigate(['dashboard', 'portal', 'create']);
  }

  goToCreateFunnel() {
    this.sharedOwnerDashboardService.setBackRoute('Dashboard');
    this.router.navigate(['dashboard', 'funnel', 'create']);
  }

  // Begin: Templates Management

  selectTemplateCategoryByIndex(index: number) {
    if (!!this.templates?.portalTemplates?.categories[index]) {
      
        this.templatesNavigationCategory = {
          index,
          category: this.templates.portalTemplates.categories[index],
        };  
      
      
    }
  }

  selectTemplateByIndex(index: number) {
    if (!!this.templatesNavigationCategory?.category?.templates[index]) {
      this.templates.currentCategory = JSON.parse(
        JSON.stringify(this.templatesNavigationCategory)
      );
      this.templates.currentTemplate = {
        index,
        template: this.templates.currentCategory.category.templates[index],
      };
      this.wizardData.templateId =
        this.templates.currentCategory.category.templates[index].id;
    }
  }

  // Begin: Funnels Management
  selectFunnelCategoryByIndex(index: number) {
    if (!!this.funnels?.portalFunnels?.categories[index]) {
      this.funnelsNavigationCategory = {
        index,
        category: this.funnels.portalFunnels.categories[index],
      };
    }
  }

  selectFunnelByIndex(index: number) {
    if (!!this.funnelsNavigationCategory?.category?.funnels[index]) {
      //
      this.funnels.currentCategory = JSON.parse(
        JSON.stringify(this.funnelsNavigationCategory)
      );
      //
      this.funnels.currentFunnel = {
        index,
        funnel: this.funnels.currentCategory.category.funnels[index],
      };
      this.wizardData.funnelId =
        this.funnels.currentCategory.category.funnels[index].id;
    }
  }

  unUseTemplateByIndex(index: number) {
    if (!!this.templates?.currentCategory?.category?.templates[index]) {
      this.templates.currentTemplate = {
        index,
        template: this.templates.currentCategory.category.templates[index],
      };
      this.wizardData.templateId =
        this.templates.currentCategory.category.templates[index].id;
    }
  }

  async getTemplates() {
    let portalTemplates$ = this.api.getTemplates();
    let portalTemplates = await lastValueFrom(portalTemplates$);
    this.templates.portalTemplates = portalTemplates;
    if (!!this.templates.portalTemplates?.categories?.length) {
      this.selectTemplateCategoryByIndex(0);
    }
  }

  async getTemplateFunnels() {
    let portalFunnels$ = this.api.getFunnels();
    let portalFunnels: PortalFunnels = await lastValueFrom(portalFunnels$);
    // portalFunnels.categories[0].funnels = [...portalFunnels.categories[0].funnels, ...portalFunnels.categories[0].funnels, ...portalFunnels.categories[0].funnels, ...portalFunnels.categories[0].funnels,...portalFunnels.categories[0].funnels,...portalFunnels.categories[0].funnels,...portalFunnels.categories[0].funnels]
    this.funnels.portalFunnels = portalFunnels;
    if (!!this.funnels.portalFunnels?.categories?.length) {
      this.selectFunnelCategoryByIndex(0);
    }
  }

  showPortalLimitAlert() {
    //   Report.warning(
    //     `We're Sorry`,
    //     `Your current plan doesn't support purchasing more than <b>${this.numbersOfDomainsAndPortalsForUser.portals.max}</b> portals`,
    //     'OK',
    //     ()=> {
    //       this.goToPortals();
    // },
    //     );
    Swal.fire({
      allowOutsideClick: true,
      title: "We're Sorry",
      html: `Your current plan doesn't support purchasing more than <b>${this.numbersOfDomainsAndPortalsForUser.portals.max}</b> portals`,
      icon: 'warning',
      confirmButtonText: 'Ok',
    }).then((res) => {
      if (res.value) {
        this.goToPortals();
      }
    });
  }

  async getCountOfSubscriptionPermissionsPortalsAndDomains() {
    // this.startLoading();
    let numbersOfDomainsAndPortalsForUser$ =
      this.api.getCountOfSubscriptionPermissionsPortalsAndDomains(
        this.handleGetCountOfDomainsError
      );
    let numbersOfDomainsAndPortalsForUser: NumbersOfDomainsAndPortalsForUser =
      await lastValueFrom(numbersOfDomainsAndPortalsForUser$);
    this.numbersOfDomainsAndPortalsForUser = numbersOfDomainsAndPortalsForUser;
    // this.stopLoading();
  }

  async getCountOfSubscriptionPermissionsDomains() {
    const result: NumbersOfDomainsOrPortalsForUser = await this.api
      .getCountOfSubscriptionPermissionsDomains(
        this.handleGetCountOfDomainsError
      )
      .toPromise();
    this.numbersOfDomainsForUser = result;
  }

  async getCountOfSubscriptionPermissionsPortals() {
    let numbersOfPortalsForUser$ =
      this.api.getCountOfSubscriptionPermissionsPortals(
        this.handleGetCountOfPortalsError
      );
    let numbersOfPortalsForUser: NumbersOfDomainsOrPortalsForUser =
      await lastValueFrom(numbersOfPortalsForUser$);
    this.numbersOfPortalsForUser = numbersOfPortalsForUser;
  }

  goToMyPortals() {
    this.router.navigate(['dashboard', 'portal']);
  }

  goBack() {
    history.back();
  }
  private handleGetCountOfDomainsError(error: any) {}
  private handleGetCountOfPortalsError(error: any) {}
}
