import { HttpErrorResponse } from '@angular/common/http';
import { User } from './../../models/user';
import { ErrorHandler, Injectable } from '@angular/core';
import Gleap from 'gleap';
import * as StackTrace from 'stacktrace-js';

export interface LogError {
  Time: string;
  Route: string;
  Message: string;
  User: string;
}

@Injectable({
  providedIn: 'root',
})
export class CrashReportsService {
  httpError(error: any) {}

  removeIdentity() {
    Gleap.trackEvent('Logged out');
    let isUserIdentified = Gleap.isUserIdentified();

    if (!!isUserIdentified) {
      Gleap.clearIdentity();
    }
  }


  silentGleapHttpErrorReport(error: HttpErrorResponse) {
    const errorTitle = `${error.message} - ${
      (error as any)?.statusCode || error.status
    }`;

    const statusPeriority: 'HIGH' | 'MEDIUM' | 'LOW' =
      (error as any)?.statusCode == 500 ? 'HIGH' : 'MEDIUM';

    Gleap.sendSilentCrashReport(errorTitle, statusPeriority);
  }
  
  setIdentity(currentUser: User) {
    Gleap.trackEvent(`Logged in`);

    Gleap.identify(`${currentUser?.id}`, {
      name: `${currentUser?.firstName} ${
        currentUser?.lastName ? currentUser.lastName : ''
      } (${currentUser?.role?.name})`,
      email: currentUser.email,
      phone: currentUser.phone,
      customData: {
        Avatar: currentUser.avatar.big,
        Username: currentUser.username,
        Country: `${currentUser?.country?.name} (${currentUser?.country?.code})`,
        State: currentUser?.state?.name,
        Birthdate: !!currentUser?.birthdate
          ? new Date(+currentUser.birthdate * 1000)
          : null,
        RegistredAt: !!currentUser?.registered
          ? new Date(+currentUser.registered * 1000)
          : null,
        IsSubscribed: !!currentUser?.subscription?.isSubscribed ? 'Yes' : 'No',
        Subscription: `${currentUser?.subscription?.name} - (${currentUser?.subscription?.subscriptionType})`,
        SubscriptionEndsAt: !!currentUser?.subscription?.endDate
          ? new Date(+currentUser.subscription.endDate)
          : null,
      },
    });
  }
  silentReportWithStackTrace(stackTrace: StackTrace.StackFrame[]) {
    

    const statusPeriority: 'HIGH' | 'MEDIUM' | 'LOW' = 'MEDIUM';
      
    Gleap.attachCustomData(stackTrace);
    Gleap.sendSilentCrashReport(null, statusPeriority);
  }
}
