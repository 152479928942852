import { PaymentsEffects } from './_features/payments/payments.effect';
import { SubscriptionEffects } from './_features/subscription/subscription.effect';
import { CourseReportsEffects } from './_features/products/course/reports/course-reports.effect';
import { CourseQuizItemsEffects } from './_features/products/course/course-items/course-quiz-item/course-quiz-items.effects';
import { CourseStudentsEffects } from './_features/products/course/students/course-students.effect';
import { CourseItemsEffects } from './_features/products/course/course-items/course-items.effect';
import { CourseDetailsEffects } from './_features/products/course/details/course-details.effect';
import { WebsitePaymentsEffects } from './_features/website/payments/website-payments.effect';
import { WebsiteBlogEffects } from './_features/website/blog/website-blog.effect';
import { WebsiteAnalyticsEffects } from './_features/website/analytics/website-analytics.effect';
import { ContactsEffects } from './_features/contacts/contacts.effect';
import { CourseEffects } from './_features/products/course/course.effect';
import { PasswordForgotEffects } from './_features/user/password-forgot/password-forgot.effect';
import { ProfileEffects } from './_features/user/profile/profile.effect';
import { RegisterEffects } from './_features/user/register/register.effect';
import { AuthEffects } from './_features/user/auth/auth.effect';
import { MediaLibraryEffects } from './_features/media-library/media-library.effect';
import { DashboardTrackingEffects } from './_features/dashboard-tracking/dashboard-tracking.effect';
import { RefundRequestsEffects } from './_features/refunds/refunds.effect';
import { AccountSetupEffects } from './_features/account-setup/account-setup.effect';
import { NotificationsEffects } from './_features/notifications/notifications.effect';
import { WebsiteEffects } from './_features/website/website.effect';
import { OffersEffects } from './_features/offers/offers.effect';
import { ProductsEffects } from './_features/products/products.effect';
import { EffectsModule } from '@ngrx/effects';
// app-store.module.ts
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { rootReducer } from './learmo.reducer';
import { SharedModule } from '../shared.module';

let stateEffects = [
  ProductsEffects,
  OffersEffects,
  //Website Related
  WebsiteEffects,
  WebsiteAnalyticsEffects,
  WebsiteBlogEffects,
  WebsitePaymentsEffects,
//Course Related
  CourseEffects,
  CourseItemsEffects,
  CourseQuizItemsEffects,
  CourseDetailsEffects,
  CourseStudentsEffects,
  CourseReportsEffects,

  NotificationsEffects,
  AccountSetupEffects,
  RefundRequestsEffects,
  DashboardTrackingEffects,
  MediaLibraryEffects,
  AuthEffects,
  RegisterEffects,
  ProfileEffects,
  PasswordForgotEffects,
  
  ContactsEffects,


  SubscriptionEffects,
  PaymentsEffects
];
@NgModule({
  imports: [
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([...stateEffects]),
    SharedModule,
  ],
})
export class LearmoStoreModule {}
