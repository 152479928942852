import {
  OwnerFunnelStatusEnum,
  FunnelItem,
} from './../../../../../models/funnels';
import { SharedFunnelService } from './../../shared-funnel.service';
import { UtilitiesService } from '../../../../../shared/services/utilities.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import { ApiService } from '../../../../../shared/services';

@Component({
  selector: 'app-funnel-edit-details',
  templateUrl: './funnel-edit-details.component.html',
  styleUrls: ['./funnel-edit-details.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FunnelEditDetailsComponent implements OnInit {
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  ownerFunnelStatusEnum = OwnerFunnelStatusEnum;

  funnelDetailsForm = new FormGroup({
    status: new FormControl<OwnerFunnelStatusEnum>(null, {
      updateOn: 'change',
    }),
    title: new FormControl(null, {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  // offerUrl: string = '';
  @Input() funnel: FunnelItem;

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService,
    public sharedFunnelService: SharedFunnelService
  ) {
    // this.funnel = this.sharedFunnelService.funnelEditPayload;
  }

  ngOnInit(): void {}

  closeModal() {
    this.modalService.dismissAll();
  }

  async afterOpenModal() {
    this.funnelDetailsForm.controls.status.setValue(this.funnel.status);
    this.funnelDetailsForm.controls.title.setValue(this.funnel.title);
    // const urlReuqest$ = this.sharedOfferService.getOfferUrl();
    // const urlRequest = await lastValueFrom(urlReuqest$);
    // this.offerUrl = this.sharedOfferService.getOfferUrl();
  }

  async onSave() {
    await this.sharedFunnelService.updateFunnel(this.funnel.id, {
      title: this.funnelDetailsForm.value.title,
      status: this.funnelDetailsForm.value.status,
    });

    this.closeModal();
  }

  async onOpenModal() {}

  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
