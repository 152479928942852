// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.period-item.out-of-range {
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/period-button/period-button.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".period-item.out-of-range {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
