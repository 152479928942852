import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  WebsiteIdentity,
  WebsiteModel,
  WebsiteSenderMailConfiguration,
} from '../../../../components/user-dashboard/portal/portal.model';
import { PageItem, PaymentGatewaysInformation } from 'src/app/models/portal';
import { CourseCategoryItem, CourseInstructorItem } from 'src/app/components/user-dashboard/product/models/course';



//Actions for loading websites
export const websiteActions = createActionGroup({
  source: 'Load Website',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ website: WebsiteModel }>(),
    loadFailure: props<{ error: any }>(),
  },
});



//Actions for loading website pages
export const websitePagesActions = createActionGroup({
  source: ' Load Website Pages',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ pages: PageItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});







// Actions for loading website Categories
export const websiteCategoriesActions = createActionGroup({
  source: 'Get Website Categories',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ categories: CourseCategoryItem[], }>(),
    loadFailure: props<{ error: any }>(),
  },
});




// Actions for create Website Categories
export const createWebsiteCategoriesActions = createActionGroup({
  source: 'Create Website Categories',
  events: {
    create:props<{ payload: { name: string }, errorHandler?: any }>(),
    createSuccess:props<{ category: CourseCategoryItem, }>(),
    createFailure: props<{ error: any }>(),
  },
});




// Actions for load Website Instructors
export const websiteInstructorsActions = createActionGroup({
  source: 'Get Website Instrcutors',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ instructors: CourseInstructorItem[], }>(),
    loadFailure: props<{ error: any }>(),
  },
});



export const setWebsiteAsReady = createAction('[Website] Website is ready', props<{ website: WebsiteModel }>() );

// Actions for updating identity
export const updateWebsiteIdentity = createAction(  '[Website] Update Website Identity Success',  props<{ identity: WebsiteIdentity }>());
export const updateWebsiteSenderEmailConfig = createAction(  '[Website] Update Website Sender Email Config Success',  props<{ senderEmailConfig: Partial<WebsiteSenderMailConfiguration> }>());




export const addNewWebsitePage = createAction(  '[Website] Add New Website Page',  props<{ page: PageItem }>());


export const deleteWebsitePage = createAction(  '[Website] Delete Website Page',  props<{ page: Partial<PageItem> }>());


export const updateWebsitePage = createAction(  '[Website] Update Website Page',  props<{ page: Partial<PageItem> }>());


export const loadWebsitePagesScreenshot = createAction(  '[Website] Load Website Page Screenshot',  props<{ pageIds: number[] }>());




