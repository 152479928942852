import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxTippyInstance, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { AccountSetupStep } from 'src/app/components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';
import { AccountSetupService } from '../setup-steps/account-setup.service';

@Component({
  selector: 'app-account-steps-tracking-sidewidget',
  templateUrl: './account-steps-tracking-sidewidget.component.html',
  styleUrls: ['./account-steps-tracking-sidewidget.component.css']
})
export class AccountStepsTrackingSidewidgetComponent implements OnInit, AfterViewInit {
  
  tippyProps: NgxTippyProps = {
    followCursor: false
  }
  isOpen: boolean = false;

  @Input('isOpen') set isOpenSetter(value: boolean) {
    this.isOpen = value;
  }

  @Output() isOpenChange = new EventEmitter<boolean>();
  constructor(public ngxTippyService: NgxTippyService, public accountSetupService: AccountSetupService) { }
  ngAfterViewInit(): void {
     this.accountSetupService.tippyInstance = this.ngxTippyService.getInstance('help_message')

     this.accountSetupService.showMessage('Let me guide you');
  }



  
  openGuide() {
    this.isOpen = true;
    this.isOpenChange.emit(this.isOpen);
  }

  closeGuide() {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  toggleGuide() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  onStepClicked(item: AccountSetupStep) {
    this.closeGuide()
    setTimeout(() => {
      this.accountSetupService.showMessage(`I will remain here until you have completed your task.`, 0, 1500)  
    }, 500);
    
  }

  ngOnInit() {
    // this.ngxTippyService.show('help_message')
    // this.tippyInstance = this.ngxTippyService.getInstance('help_message')
    // this.tippyInstance.show();
  }

}
