// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  display: flex ;
}

.tab-content .tab-pane {
  display: none;
}

.tab-content .tab-pane.active.show {
  display: flex;
}


.icons{
  max-height: 350px;

}


.search{
  top: 4.5%;
  left: 91%;

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/icons-selector/icons-selector.component.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE,iBAAiB;;AAEnB;;;AAGA;EACE,SAAS;EACT,SAAS;;AAEX","sourcesContent":["\n\n.dropdown-menu {\n  display: none;\n}\n\n.dropdown-menu.show {\n  display: flex ;\n}\n\n.tab-content .tab-pane {\n  display: none;\n}\n\n.tab-content .tab-pane.active.show {\n  display: flex;\n}\n\n\n.icons{\n  max-height: 350px;\n\n}\n\n\n.search{\n  top: 4.5%;\n  left: 91%;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
