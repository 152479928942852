import { isCurrentProductLoaded, selectCurrentProduct, selectProducts, selectProductsState } from '../../../shared/store/_features/products/products.selector';
import { ProgressSpinnerService } from './../../../shared/services/progress-spinner.service';
import { WizardModel } from './../../../models/wizard';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { lastValueFrom, takeWhile } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal from 'sweetalert2';
import {
  MediaLibraryFileModel,
  DeleteResponse,
} from '../../../models/sales-funnel';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';

import {
  ProductEditResponse,
  ProductItem,
} from './models/product';


import { createNewProductActions, deleteProduct, CurrentProductActions, ProductsActions, updateCurrentProductActions } from 'src/app/shared/store/_features/products/products.actions';
import { Store } from '@ngrx/store';
import { DynamicActionButtonItem } from 'src/app/shared/partials/dynamic-preview-buttons/dynamic-action-buttons.component';

@Injectable({
  providedIn: 'root',
})
export class SharedProductService {
  minimalProductView: boolean = false;
  productPageNavigations: TopLevelNavMenuItem[] = [];
  previewButtons: DynamicActionButtonItem[] = [];
  currentProductId: number = 0;
  currentProduct: ProductItem;
  // productEditPayload: ProductItem;
  // products: ProductItem[] = [];

  productContentTemp: MediaLibraryFileModel[] | number[] = [];
  isCurrentProductLoaded: boolean = false;
  processing: 'loading' | 'done' | 'none' = 'none';

  deleting: boolean = false;

  pageStatus: 'edit' | 'view' | 'offers';

  productNameError = false;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private sharedDashboardService: SharedOwnerDashboardService,
    private progressSpinnerService: ProgressSpinnerService,
    private store: Store
  ) {
    this.getProductErrorHandler = this.getProductErrorHandler.bind(this);
    this.store.select(selectCurrentProduct).subscribe((currentProduct)=> {
      this.currentProduct = currentProduct;

    });

    this.store.select(isCurrentProductLoaded).subscribe((isLoaded)=> {
      this.isCurrentProductLoaded = isLoaded;
    })
  }

  generateProductMenuNav(product: ProductItem) {
    switch (product?.type?.name) {
      case 'course':
        this.generateCourseNavigationMenu(product);
        break;

      default:
        this.generateDownloadbaleNavigationMenu(product);
        break;
    }
  }

  generateProductPreviewLinks(product: ProductItem) {
    switch (product?.type?.name) {
      case 'course':
        this.generateCoursePreviewLinks(product);
        break;

      default:
        this.generateDownloadablePreviewLinks(product);
        break;
    }
  }

  navigateToTypeRoute(product: ProductItem) {
    const currentVisitURL = this.router.url;
    if (currentVisitURL.endsWith(`product/${product.id}`)) {
      switch (product?.type?.name) {
        case 'course':
          if (!currentVisitURL.includes('course')) {
            this.router.navigate(['dashboard', 'product', product.id, 'course'], {replaceUrl: true});
          }
          break;

        default:
          if (!currentVisitURL.includes('downloadable')) {
            this.router.navigate(['dashboard', 'product', product.id, 'downloadable'], {replaceUrl: true});
          }
          break;
      }
    }

  }

  generateDownloadablePreviewLinks(product: ProductItem) {
    let previewButton: DynamicActionButtonItem = {
      label: 'Preview',
      colorSet: 'light',
      buttonSize: 'sm',
      hiddenButton: false,
      iconSvg: 'assets/images/svg/system/preview.svg',
      url: product.previewUrl,
    };

    this.previewButtons = [previewButton];
  }

  generateCoursePreviewLinks(product: ProductItem) {
    let visitorPreviewButton: DynamicActionButtonItem = {
      label: 'As a Visitor',
      colorSet: 'gray-700',
      colorSetHover: 'primary',
      url: product.visitorUrl,
      auth: 'visitor',
    };

    let enrolledPreviewButton: DynamicActionButtonItem = {
      label: 'As a Student',
      colorSet: 'gray-700',
      colorSetHover: 'primary',
      url: product.previewUrl,
      auth: 'demo',
    };

    this.previewButtons = [
      {
        label: 'Preview',
        colorSet: 'light-primary',
        colorSetHover: 'light-primary',
        hiddenButton: false,
        buttonSize: 'sm',
        iconSvg: 'assets/images/svg/system/preview.svg',
        url: null,

        subItems: [visitorPreviewButton, enrolledPreviewButton],
      },
    ];
  }

  generateCourseNavigationMenu(product: ProductItem) {
    let OfferCount = !!product?.offers?.length ? product.offers.length : '0';

    this.productPageNavigations = [
      {
        label: 'Edit Details',
        url: `/dashboard/product/${product.id}/course/edit`,
      },
      {
        label: 'Curriculum Builder',
        url: `/dashboard/product/${product.id}/course/builder`,
        // subMenu: [
        //   {label: 'Outline', url: `/dashboard/product/${product.id}/course/builder/outline`},
        //   {label: 'Content', url: `/dashboard/product/${product.id}/course/builder/content`},
        // ]
      },
      {
        label: `Offers (${OfferCount})`,
        url: `/dashboard/product/${product.id}/offers`,
      },
      // {
      //   label: 'Students',
      //   url: `/dashboard/product/${product.id}/course/students`,
      // },
      {
        label: 'Students & Reports',
        url: `/dashboard/product/${product.id}/course/reports`,
      },
      // {
      //   label: 'Activity',
      //   url: `/dashboard/product/${product.id}/course/activity`,
      // },
    ];
  }

  generateDownloadbaleNavigationMenu(product: ProductItem) {
    let OfferCount = !!product?.offers?.length ? product.offers.length : '0';

    this.productPageNavigations = [
      {
        label: 'Content',
        url: `/dashboard/product/${product.id}/downloadable/content`,
      },
      { label: 'Edit Details', url: `/dashboard/product/${product.id}/edit` },
      {
        label: `Offers (${OfferCount})`,
        url: `/dashboard/product/${product.id}/offers`,
      },
    ];
  }

  async getProductById(id: number) {

    this.store.dispatch(CurrentProductActions.load({productId: id}))

    // this.loaded.item = false;
    // const product$ = this.api.getProductById(id, this.getProductErrorHandler);
    // this.product = await lastValueFrom(product$);
    // this.loaded.item = true;
  }

  // async getCourseById(id: number) {
  //   this.loaded.item = false;
  //   const product$ = this.api.getCourseById(id, this.getProductErrorHandler);
  //   this.product = await lastValueFrom(product$);
  //   this.productEditPayload = JSON.parse(JSON.stringify(this.product));
  //   this.loaded.item = true;
  // }

  getProductErrorHandler(error: any) {
    this.goToProducts();
  }

  onChooseCreate() {
    this.router.navigate(['dashboard', 'product', 'create']);
  }

  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing() {
    this.processing = 'done';
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }

  async updateProduct(productId: number ,payload: Partial<ProductItem>) {
    this.store.dispatch(updateCurrentProductActions.update({productId, changes: payload}));

    this.store.select(selectProductsState).pipe(takeWhile(state => state?.currentProduct.updating !== 'done' && state?.currentProduct.updating !== 'error', true)
    ).subscribe(state=> {
      console.log("Current Product Update Status is: ",state?.currentProduct.updating)
      switch (state?.currentProduct.updating) {
        case 'done':
          this.onProductUpdate(state.currentProduct);
          break;
        case 'error':
          this.onProductUpdateError(state.currentProduct.errorMessage)
        break;
        default:
          break;
      }

    })
      // this.startProcessing();
      // const UpdateRequest$ = this.api.updateProduct(
      //   this.currentProductId,
      //   payload
      // );
      // const UpdateRequest: ProductEditResponse = await lastValueFrom(
      //   UpdateRequest$
      // );

      // this.currentProduct = UpdateRequest.product;
      // this.toastr.success('Updated Successfully', 'Done!', {
      //   closeButton: false,
      //   positionClass: 'toast-bottom-right',
      // });
      // this.enableNavigate();
      // this.endProcessing();
      // return false;
  }

  onProductUpdate = (product: ProductItem) => {
    this.toastr.success('Updated Successfully', 'Done!');
      this.sharedDashboardService.enableNavigate();

  }

  onProductUpdateError = (errorMessage: string) => {
    this.toastr.error(errorMessage);
    this.sharedDashboardService.enableNavigate();
  }

  goToProducts() {
    this.router.navigate(['dashboard', 'product']);
  }

  goToView() {
    this.router.navigate([
      'dashboard',
      'product',
      this.currentProductId,
      'view',
    ]);
  }

  goToEdit() {
    this.router.navigate([
      'dashboard',
      'product',
      this.currentProductId,
      'edit',
    ]);
  }

  goToProductOffers() {
    this.router.navigate([
      'dashboard',
      'product',
      this.currentProductId,
      'offers',
    ]);
  }

  async getProducts() {
    this.store.dispatch(ProductsActions.load());
  }

  async cloneProductAlert(product: ProductItem) {
    Swal.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      buttonsStyling: false,
      icon: 'info',
      width: '650px',
      padding: '20px 70px',
      customClass: {
        title: 'd-block',
        container: 'text-center',
        confirmButton: 'btn btn-success m-1',
        cancelButton: 'btn btn-danger m-1',
      },
      inputPlaceholder:
        'I confirm that I want to <strong class="text-info">Clone</strong> this product.',
      title:
        'Are you sure you want to clone <strong>' +
        product.name +
        '</strong> product?',
      html: 'This <strong>Product</strong> will be duplicated',
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const cloneRequest$ = this.api.cloneProduct(product.id);
        const cloneRequest = await lastValueFrom(cloneRequest$);
        this.toastr.success('Done!', 'Product Cloned Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return cloneRequest;
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value) {
        this.store.dispatch(createNewProductActions.create(res.value))
      } else {
        return false;
      }
    });
    const deleteCheck = Swal.getInput();
    const confirmCheck = Swal.getConfirmButton();
    const cancelButton = Swal.getCancelButton();

    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Cloning, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

  async deleteProductAlert(product: ProductItem, fromEdit: boolean = false) {
    let offersList = '';
    const beforeDeleteRequest$ = this.api.deleteProduct(product.id);
    const beforeDelete: DeleteResponse = await lastValueFrom(
      beforeDeleteRequest$
    );

    Swal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      icon: 'error',
      width: '650px',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-light',
        input: 'form-check form-check-danger',
      },
      input: !!beforeDelete?.offers?.length ? 'checkbox' : undefined,
      inputPlaceholder: `I know that i'll lose my deleted <strong class="text-danger">product and it's ${beforeDelete?.offers?.length} Offer(s).</strong>`,
      title: 'Are you sure want to delete?',
      html:
        beforeDelete.offers?.length > 0
          ? 'By <strong>deleting</strong> this <strong>Product</strong>, it will be <strong>removed</strong> from the following <strong>Offers:</strong>' +
            `<br>
      ${this.generateItemDeleteWarningBlock(beforeDelete.offers)}
      `
          : '',
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const DeleteRequest$ = this.api.deleteProduct(product.id, true);
        const DeleteRequest = await lastValueFrom(DeleteRequest$);
        if (!!fromEdit) {
          await this.getProducts();
        }
        this.toastr.success('Done!', 'Product Deleted Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return DeleteRequest;
        // TODO: Delete Request
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value?.success) {
        this.goToProducts();
        setTimeout(() => {
          this.store.dispatch(deleteProduct({product}))
        }, 500);

      }
    });
  }

  generateItemDeleteWarningBlock(
    offers: {
      id: number;
      title: string;
      effect: string;
    }[]
  ) {
    if (!offers?.length) {
      return '';
    }
    let offersList = '';
    offers.forEach((offerItem) => {
      offersList =
        offersList +
        ` <li class="d-flex align-items-center"><span class="bullet bg-danger me-5"></span>
        <span class="w-10px flex-grow-1 pe-10 text-truncate">${offerItem.title}</span>
        <span
      class="badge badge-danger badge-lg text-capitalize"
      style="vertical-align: bottom"
      >${offerItem.effect}</span></li>`;
    });

    return `<div class="alert gap-3 bg-light-danger d-flex text-start p-2 mt-6 mb-0">
    <i class="ler-duotone ler-information fs-3x text-danger">
    <i class="path1"></i>
    <i class="path2"></i>
    <i class="path3"></i>
</i>
    <div class="d-flex flex-column flex-grow-1">
        <span>By Deleting this <strong>Product</strong> you will lose its <strong>${offers.length} Offers</strong>.</span>
        <div class="d-flex flex-column mt-2 gap-4 px-5">
        ${offersList}
        </div>
    </div>

    </div>`;
  }
}

