import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return '';
    }

    // Assuming value is an object with 'firstName' and 'lastName' properties.
    const { firstName, lastName } = value;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return firstName || lastName || '';
    }
  }
}
