const ICONS = {
    category_name: 'Archive',
    icons_list: [
      "book-square",
      "receipt-square",
      "save-2",
      "archive-tick"



    ],

};


export default ICONS
