// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard Cards */

.flexgrid {
  display: inline-block;
  display: flex;
  flex-flow: wrap;
  flex-grow: 50px;
  gap: 3.5%;
}
.flexcol{
  transition: all 0.2s ease-in-out;
 display: inline-block;
 float: left;
 width: 31%;
 text-align: center;
}





.badge-salesfunnel {
  background: #E89E3A;
  color: #fff;
}

.badge-optin {
  background: #D540BF;
  color: #fff;
}

.badge-webinar {
  background: #2ebef7;
  color: #fff;
}

.type-badge {
  min-width: 70px;
    font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
}

 @media screen and (max-width: 900px) {
  .newflexcol,.flexcol{width: 100%;}
  .cards {
    padding: 0;
  }

}

@media screen and (min-width:900px) and (max-width: 1024px) {
  .newflexcol,.flexcol{width: 46%;}

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/funnels-layout/funnels-layout.component.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,qBAAqB;EACrB,aAAa;EACb,eAAe;EACf,eAAe;EACf,SAAS;AACX;AACA;EACE,gCAAgC;CACjC,qBAAqB;CACrB,WAAW;CACX,UAAU;CACV,kBAAkB;AACnB;;;;;;AAMA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;IACb,eAAe;EACjB,yCAAyC;AAC3C;;CAEC;EACC,qBAAqB,WAAW,CAAC;EACjC;IACE,UAAU;EACZ;;AAEF;;AAEA;EACE,qBAAqB,UAAU,CAAC;;AAElC","sourcesContent":["/* Dashboard Cards */\n\n.flexgrid {\n  display: inline-block;\n  display: flex;\n  flex-flow: wrap;\n  flex-grow: 50px;\n  gap: 3.5%;\n}\n.flexcol{\n  transition: all 0.2s ease-in-out;\n display: inline-block;\n float: left;\n width: 31%;\n text-align: center;\n}\n\n\n\n\n\n.badge-salesfunnel {\n  background: #E89E3A;\n  color: #fff;\n}\n\n.badge-optin {\n  background: #D540BF;\n  color: #fff;\n}\n\n.badge-webinar {\n  background: #2ebef7;\n  color: #fff;\n}\n\n.type-badge {\n  min-width: 70px;\n    font-size: 16px;\n  font-family: Helvetica, Arial, sans-serif;\n}\n\n @media screen and (max-width: 900px) {\n  .newflexcol,.flexcol{width: 100%;}\n  .cards {\n    padding: 0;\n  }\n\n}\n\n@media screen and (min-width:900px) and (max-width: 1024px) {\n  .newflexcol,.flexcol{width: 46%;}\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
