import { Component, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-media-library-cropper',
  templateUrl: './media-library-cropper.component.html',
  styleUrls: ['./media-library-cropper.component.css']
})
export class MediaLibraryCropperComponent implements OnInit {
  imageCropperStatus: boolean = false;
  imageChangedEvent: any = '';
  imageCurrentName: any = '';
  imageEventFile: ImageCroppedEvent;


  @Input() imgSpecs: {
    width?: number;
    height?: number;
    ratio?: number;
  } = {
    width: null,
    height: null,
    ratio: null,
  };

  @Output() onCropDone = new EventEmitter<File>();

  @ViewChild('imageCropperContainer') cropperContent: TemplateRef<any>;
  cropperRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  @Output() cropperOpenerChange = new EventEmitter<File>();
  @Input('cropperOpener') set setCropperFile(file: File) {
    if (!!file) {
    this.openCropper(undefined, file)
    }
  }


  dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async doneCrop() {
    this.imageCropperStatus = false;
    // done crop

    const file = this.dataURLtoFile(
      this.imageEventFile.base64,
      this.imageCurrentName
    );
    this.onCropDone.emit(file);
    this.closeCropper();
    // this.uploadFileItem(file);
    
  }

  cancelCrop() {
    this.closeCropper();
  }

  closeCropper() {
    this.resetCropper();
    this.cropperRef.dismiss();
  }

  resetCropper() {
    this.imageCurrentName = null;
    this.imageChangedEvent = null;
    this.imageCropperStatus = false;
    this.imageEventFile = null;
    this.cropperOpenerChange.emit(null)
  }
  openCropper(content = this.cropperContent, file: File) {
    this.imageChangedEvent = file;
    this.imageCurrentName = file.name;
    this.cropperRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
      windowClass: 'global-modal-class modal-animate-in mymodalcustomclass',
    });

    this.cropperRef.result.then(
      (result) => {this.cropperOpenerChange.emit(null)},
      (reason) => {this.cropperOpenerChange.emit(null)}
    );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageEventFile = event;
    
  }
  imageLoaded() {
    this.imageCropperStatus = true;
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
