import { CreateAnnouncementRequest } from './../../../../../../models/owner-dashboard';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MessengerThread,
  AnnouncementObject,
} from '../../../../../../models/owner-dashboard';

@Component({
  selector: 'app-announcement-recipients-label',
  templateUrl: './announcement-recipients-label.component.html',
  styleUrls: ['./announcement-recipients-label.component.css'],
})
export class AnnouncementRecipientLabelComponent implements OnInit {
  @Input() announcementItem: AnnouncementObject | CreateAnnouncementRequest;
  @Input() disableTooltip: boolean = false;
  tooltipLimit: number = 5;
  tooltipList: string = '';
  constructor() {}

  ngOnInit(): void {
    if (!!this.announcementItem?.affiliates?.length) {
      this.generateStructuredName();
    }
  }

  generateStructuredName() {
    let label =
      this.announcementItem.affiliates[0].affiliate.firstName +
      ' ' +
      this.announcementItem.affiliates[0].affiliate.lastName;
    let count = this.announcementItem.affiliates.length - 1;
    this.announcementItem.structuredName = { label: label, othersCount: count };
  }
}
