import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import stickybits, { StickyBits } from 'stickybits';

@Directive({
  selector: '[learmoStick]',
})
export class LearmoStickDirective
  implements AfterContentInit, OnChanges, OnDestroy
{
  private cssClassObserver?: IntersectionObserver;
  private instance?: StickyBits;
  private isSticky;
  private isStuck;

  @Input() stickDisable: boolean = false
  @Input() noStyles: boolean;
  @Input() scrollEl: Element | string;
  @Input() parentClass = 'sticky-parent';
  @Input() stickyChangeClass = 'sticky--change';
  @Input() stickyChangeNumber: number;
  @Input() stickyClass = 'sticky';
  @Input() stickyOffset: number = 0;
  @Input() stuckClass = 'stucky';
  @Input() useFixed: boolean = false;
  @Input() useGetBoundingClientRect: boolean;
  @Input() useStickyClasses = true;
  @Input() verticalPosition: 'top' | 'bottom' = 'top';
  @Output() sticky = new EventEmitter<boolean>();
  @Output() stuck = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private zone: NgZone
  ) {}

  ngAfterContentInit() {
    if (!this.stickDisable) {
      this.init();  
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.stickDisable) {
    if (this.instance) {
      this.destroy();
      this.init();
    }
  }
  }

  ngOnDestroy() {
    if (!this.stickDisable) {
    this.destroy();
    }
  }

  private init() {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.zone.runOutsideAngular(() => {
      // this.instance = 
      this.initStickybits(element);
      this.cssClassObserver = this.initStickObserver(element);
    });

  }

  private initStickybits(element: HTMLElement): void {
    element.classList.add('position-sticky');
    element.style.zIndex = `5`;

    if (this.verticalPosition === 'top') {
      element.style.top = `${this.stickyOffset}px`;
      element.style.bottom = '';
    } else if (this.verticalPosition === 'bottom') {
      element.style.top = '';
      element.style.bottom = `${this.stickyOffset}px`;
    }
  }


  private initStickObserver(element: HTMLElement): IntersectionObserver {
    const observer = new IntersectionObserver(([entry]) => {
      this.updateStickyStatus(entry);
    }, {
      rootMargin: '-1px 0px 0px 0px',
      threshold: [0, 1],
    });
  
    observer.observe(element);
    
    // Perform an initial check to set the correct state
    const initialEntry = this.createMockEntry(element);
    setTimeout(() => {
      this.updateStickyStatus(initialEntry);  
    }, 500);
    
  
    return observer;
  }
  
  private updateStickyStatus = (entry: IntersectionObserverEntry): void => {
    const boundingClientRect = entry.boundingClientRect;
    const viewportHeight = window.innerHeight;

    // console.log(entry);
    // console.log(viewportHeight);
    // Enhanced logic to determine if the element is sticky
    this.isSticky = entry.intersectionRatio < 1 && !!entry.rootBounds.y;
    this.isStuck = !this.isSticky;
  
    entry.target.classList.toggle(this.stickyClass, this.isSticky);
    entry.target.classList.toggle(this.stuckClass, this.isStuck);
  
    // Optional: Log the sticky status for debugging
    // console.log(`Element is ${this.isSticky ? 'sticky' : 'not sticky'}`);
  
    if (!!this.isSticky && this.isSticky != undefined) {
      
      this.sticky.emit(this.isSticky);
      // console.log("Emitted sticky event");
    }
    else {
      this.stuck.emit(this.isStuck);
      // console.log("Emitted stuck event");
    }

  }
  
  // Helper method to calculate the initial intersection ratio
   // Helper method to calculate the initial intersection ratio
   private calculateInitialIntersectionRatio(element: HTMLElement): number {
    const boundingClientRect = element.getBoundingClientRect();
    const rootBoundingClientRect = element.ownerDocument.documentElement.getBoundingClientRect();

    const intersectionRect = {
      top: Math.max(rootBoundingClientRect.top, boundingClientRect.top),
      bottom: Math.min(rootBoundingClientRect.bottom, boundingClientRect.bottom),
      height: Math.min(rootBoundingClientRect.bottom, boundingClientRect.bottom) - Math.max(rootBoundingClientRect.top, boundingClientRect.top)
    };

    return intersectionRect.height / boundingClientRect.height;
  }

  // Create a mock IntersectionObserverEntry for the initial state check
  private createMockEntry(element: HTMLElement): IntersectionObserverEntry {
    const boundingClientRect = element.getBoundingClientRect();
    return {
      target: element,
      boundingClientRect: boundingClientRect,
      intersectionRatio: this.calculateInitialIntersectionRatio(element),
      intersectionRect: boundingClientRect,
      isIntersecting: true,
      rootBounds: element.ownerDocument.documentElement.getBoundingClientRect(),
      time: Date.now()
    } as IntersectionObserverEntry;
  }

  

  private destroy() {
    this.instance?.cleanup();
    this.instance = undefined;
    this.cssClassObserver?.disconnect();
    this.cssClassObserver = undefined;
  }
}
