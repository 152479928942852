import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[learmoVisible]'
})


export class LearmoVisibleDirective implements OnInit, OnDestroy {
  @Output() visible = new EventEmitter<boolean>();
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.createObserver();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private createObserver() {
    const options = {
      root: null, // Use the viewport as the root
      threshold: 0.1 // Trigger the callback when 10% of the element is visible
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.visible.emit(entry.isIntersecting);
      });
    }, options);

    this.observer.observe(this.el.nativeElement);
  }
}
