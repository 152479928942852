// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { OfferCreationDTO, OfferEditPayload, OfferItem, OfferStausEnum } from '../../../../components/user-dashboard/offer/models/offer';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';



export const offersActions = createActionGroup({
  source: 'Load Offers',
  events: {
    load:emptyProps(),
    loadSuccess: props<{ offers: OfferItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});

// Current Offer Management
export const currentOfferActions = createActionGroup({
  source: 'Load Current Offer',
  events: {
    load:props<{offerId: number}>(),
    loadSuccess: props<{ offer: OfferItem }>(),
    loadFailure: props<{ error: any }>(),
  },
});



export const updateOfferActions = createActionGroup({
  source: 'Update Current Offer',
  events: {
    update:props<{offerId: number, productId: number, updates: OfferEditPayload}>(),
    updateSuccess: props<{offerId: number, productId: number, changes: OfferItem }>(),
    updateFailure: props<{offerId: number, productId: number, error: any }>(),
  },
});


export const updateOfferStatusActions = createActionGroup({
  source: ' Update Offer Status',
  events: {
    update:props<{offerId: number, productId: number, status: OfferStausEnum}>(),
    // updateSuccess: props<{ offer: OfferItem }>(),
    // updateFailure: props<{ error: any }>(),
  },
});




export const createNewOfferActions = createActionGroup({
  source: 'Add New Offer',
  events: {
    create:props<{ createPayload: OfferCreationDTO }>(),
    createSuccess: props<{ offer: OfferItem, productId: number }>(),
    createFailure: props<{ error: any }>(),
  },
});


export const deleteOfferActions = createActionGroup({
  source: ' Delete Offers',
  events: {
    delete:props<{ offerId: number, productId: number }>(),
    deleteSuccess: props<{ offerId: number, productId: number }>(),
    deleteFailure: props<{ offerId: number, productId: number, error: any }>(),
  },
});


export const cloneOfferActions = createActionGroup({
  source: ' Clone Offer',
  events: {
    clone:props<{ offerId: number, productId: number }>(),
    // cloneSuccess: props<{ offerId: number, productId: number }>(),
    cloneFailure: props<{ offerId: number, productId: number, error: any }>(),
  },
});


export const startOfferLoadingActions = createActionGroup({
  source: ' Start Offer Loading',
  events: {
    startOffer:props<{offerId: number, productId: number, loadingStatus: SystemProcessLoadingStatus}>(),
    // endOfferSuccess: props<{offerId: number, productId: number,}>(),
    // endOfferFailure: props<{offerId: number,productId: number, error: number}>(),
  },
});












// export const loadOffers = createAction('[Offers] Load Offers');
// export const loadOffersSuccess = createAction('[Offers] Load Offers Success', props<{ offers: OfferItem[] }>());
// export const loadOffersFailure = createAction('[Offers] Load Offers Failure', props<{ error: any }>());
// Define other actions as needed for your feature

// Current Offer Management
// export const loadCurrentOffer = createAction('[Offers] Load Current Offer', props<{offerId: number}>());
// export const loadCurrentOfferSuccess = createAction('[Offers] Load Current Offer Success', props<{ offer: OfferItem }>());
// export const loadCurrentOfferFailure = createAction('[Offers] Load Current Offer Failure', props<{ error: any }>());


// Current Offer Update


// export const updateOffer = createAction('[Offers] Update Current Offer', props<{offerId: number, productId: number, updates: OfferEditPayload}>());
// export const updateOfferSuccess = createAction('[Offers] Update Current Offer Success', props<{offerId: number, productId: number, changes: OfferItem }>());
// export const updateOfferFailure = createAction('[Offers] Update Current Offer Failure', props<{offerId: number, productId: number, error: any }>());


// export const updateOfferStatus = createAction('[Offers] Update Offer Status', props<{offerId: number, productId: number, status: OfferStausEnum}>());
         // export const updateOfferStatusSuccess = createAction('[Offers] Update Offer Status Success', props<{ offer: OfferItem }>());
         // export const updateOfferStatusFailure = createAction('[Offers] Update Offer Status Success', props<{ error: any }>());


// export const updateOfferStatus = createAction('[Offers] Offer Added Successfully', props<{ offer: OfferItem }>());



// export const createNewOffer = createAction('[Offers] Add New Offer', props<{ createPayload: OfferCreationDTO }>());
// export const createNewOfferSuccess = createAction('[Offers] Offer Added Successfully', props<{ offer: OfferItem, productId: number }>());
// export const createNewOfferFailure = createAction('[Offers] Create new Offer Failure', props<{ error: any }>());



// export const deleteOffer = createAction('[Offers] Delete Offers', props<{ offerId: number, productId: number }>());
// export const deleteOfferSuccess = createAction('[Offers] Delete Offers Successfully', props<{ offerId: number, productId: number }>());
// export const deleteOfferFailure = createAction('[Offers] Delete Offers Failure', props<{ offerId: number, productId: number, error: any }>());



// export const cloneOffer = createAction('[Offers] Clone Offer', props<{ offerId: number, productId: number }>());
// // export const cloneOfferSuccess = createAction('[Offers] Clone Offer Success', props<{ offerId: number, productId: number }>());
// export const cloneOfferFailure = createAction('[Offers] Clone Offer Failure', props<{ offerId: number, productId: number, error: any }>());




// UI Utiities Actions
// export const startOfferLoading = createAction('[Offers] Start Offer Loading', props<{offerId: number, productId: number, loadingStatus: SystemProcessLoadingStatus}>());
// // export const endOfferLoadingSuccess = createAction('[Offers] End Offer Loading Successfully', props<{offerId: number, productId: number,}>());
// // export const endOfferLoadingFailure = createAction('[Offers] End Offer Loading Failure', props<{offerId: number,productId: number, error: number}>());
