
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppwriteException } from 'appwrite';



export const initializeState = createAction(
  '[Register] Initialize State and Check Session'
);

export const createNewAccount = createAction(  '[Register] Create New Account',  props<{firstname: string, lastname: string, email: string, password: string }>());




//Verify User Email
export const emailVerificationRequestActions = createActionGroup({
  source: 'Verify User Email',
  events: {
    send:emptyProps(),
    sendSuccess:props<{result: any }>(),
    sendFailure: props<{ error: any }>(),
  },
});

//  export const sendEmailVerificationRequest = createAction(   '[Register] Verify User Email', );
//  export const sendEmailVerificationSuccess = createAction(  '[Register] Successfully Verified User Email',  props<{result: any }>());
//  export const sendEmailVerificationFailure = createAction(  '[Register] Failed To Verify User Email',  props<{error: any }>());




export const markWebsiteOnboardingAsDone = createAction('[Auth] Mark Website Onboarding As Done');


export const markWebsiteOnboardingAsDoneSuccess = createAction('[Auth] Mark Website Onboarding As Done Success', props<{ resp: any }>());



export const forgetSession = createAction('[Auth] Forgot');

