import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';
import { InOutAnimation } from 'src/app/shared/animations/animations';
import { AnimatedLoaderType } from 'src/app/shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';
import { AuthenticationService } from 'src/app/shared/services';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { isWebhookDone } from 'src/app/shared/store/_features/user/user.selector';
import Swal from 'sweetalert2';

export class OnboardingWizardSetupStep {
  enum: OnboardingWizardSetupStepsEnum;
}
export enum OnboardingWizardSetupStepsEnum {
  welcome = 'welcome',
  site_title = 'site_title',
  site_logo = 'site_logo',
  site_template = 'site_template',
}

@Component({
  selector: 'app-account-setup-wizard',
  templateUrl: './account-setup-wizard.component.html',
  styleUrls: ['./account-setup-wizard.component.css'],
  animations: [InOutAnimation]
})
export class AccountSetupWizardComponent implements OnInit {
  animatedLoaderType = AnimatedLoaderType
  
  processing: boolean = false;
  isWebhookDone: boolean = false;
  onboardingWizardSetupStepsEnum = OnboardingWizardSetupStepsEnum;
  currentStep: OnboardingWizardSetupStep;
  currentStepIndex: number;
  steps: OnboardingWizardSetupStep[] = [
    // {
    //   enum: OnboardingWizardSetupStepsEnum.site_template,
    // },
    {
      enum: OnboardingWizardSetupStepsEnum.welcome,
    },
    {
      enum: OnboardingWizardSetupStepsEnum.site_title,
    },
    // {
    //   enum: OnboardingWizardSetupStepsEnum.site_logo,
    // },
    {
      enum: OnboardingWizardSetupStepsEnum.site_template,
    },
  ];
  constructor(
    public sharedProfileService: SharedProfileService,
    private authService: AuthenticationService,
    private appwriteService: AppwriteService,
    private toastr: ToastrService,
    private store: Store
  ) {
    this.activateStepByIndex(0);
    
    this.store.select(isWebhookDone).subscribe((value)=> {
      this.isWebhookDone = value;
    })
  }

  activateStepByIndex(index: number) {
    
    if (!!this.steps[index]) {
      this.currentStep = this.steps[index];
      this.currentStepIndex = index;
    }
  }

  nextStep() {
    this.processing = true;
    setTimeout(() => {
      this.activateStepByIndex(this.currentStepIndex + 1);  
      this.processing = false;
    }, 500);
  }

  async finalStep() {
    this.processing = true;
    setTimeout(async () => {
      await this.onSkip();
      this.processing = false;
    }, 500);
    
  }

  async onSkip() {
    this.authService.markWebsiteOnboardingAsDone();
  }

  async ngOnInit() {
    this.isWebhookDone = await this.appwriteService.isWebhookDone()
  }
}
