import { Pipe, PipeTransform } from '@angular/core';
import { specialFilter } from './utils/special-filter.utils'; // Adjust the path accordingly

@Pipe({
  name: 'specialfilter',
})
export class SpecialFilterPipe implements PipeTransform {
  transform(
    items: any[],
    filters: { column: string, value: any }[] = [],
    exclusions: { column: string, value: any }[] = []
  ): any[] {
    if (!items) { return []; }
    if (!filters && !exclusions || !(filters instanceof Array)) { return items; }
    return specialFilter(items, filters, exclusions);
  }
}
