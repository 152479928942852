// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item.active, .dropdown-item:active {
  color: #141414 !important;
  background-color: #dbdbdb !important;
}

/* .dropdown-item:focus {
  background-color: #fff !important;
} */

.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {
  color: #F64E60 !important;
  background-color: #dbdbdb !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/user-drop/affiliate-drop/affiliate-drop.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,oCAAoC;AACtC","sourcesContent":[".dropdown-item.active, .dropdown-item:active {\n  color: #141414 !important;\n  background-color: #dbdbdb !important;\n}\n\n/* .dropdown-item:focus {\n  background-color: #fff !important;\n} */\n\n.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {\n  color: #F64E60 !important;\n  background-color: #dbdbdb !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
