import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SharedMessengerService } from '../shared-messenger.service';
import { AffiliateMinimizedModel } from '../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-select-affiliate-to-chat',
  templateUrl: './messenger-select-affiliate-to-chat.component.html',
  styleUrls: ['./messenger-select-affiliate-to-chat.component.css'],
})
export class MessengerSelectAffiliateToChatComponent implements OnInit {
  @Input() dataArray: any[];
  @Input() dataModel: any;
  @Input() placeholder: string;
  @Output() onDataModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdd: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  constructor(public sharedMessengerService: SharedMessengerService) {}

  ngOnInit() {}

  // NGX Chips Functions
  // Ngx-Tag Validation
  async Add(data: any) {
    this.onAdd.emit(data);
    this.onChange(this.dataModel);
  }

  syncUserIds() {
    this.sharedMessengerService.newChatBody.threadUserIds =
      this.sharedMessengerService.newChatBody.threadUsers.map((a) => a.id);
  }

  async Remove() {
    this.onRemove.emit();
    this.onChange(this.dataModel);
  }

  onChange(data: any) {
    this.onDataModelChange.emit(data);
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return !!JSON.stringify(item).toLocaleLowerCase().includes(term);
  }
}
