// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.announcements-area {
    transition: all 0.3s ease-in-out;
}
.announcements-area.focus {
    transform: translateY(-10px) scale(1.03);
    box-shadow: var(--bs-box-shadow);
    /* border: 1px solid var(--bs-primary); */
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/profile-main-page/profile.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA;IACI,wCAAwC;IACxC,gCAAgC;IAChC,yCAAyC;AAC7C","sourcesContent":[".announcements-area {\n    transition: all 0.3s ease-in-out;\n}\n.announcements-area.focus {\n    transform: translateY(-10px) scale(1.03);\n    box-shadow: var(--bs-box-shadow);\n    /* border: 1px solid var(--bs-primary); */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
