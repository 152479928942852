import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactsState, contactsAdapter } from './contacts.reducer';



// Create a feature selector for the 'products' feature
export const selectContactsState = createFeatureSelector<ContactsState>('contacts');

// Create a selector to get the products from the state (maintaining the same name)
export const selectContacts = createSelector(
    selectContactsState,
    (state) => contactsAdapter.getSelectors().selectAll(state)
  );

export const selectInstructorsContacts = createSelector(
  selectContacts,
  (allContacts) => allContacts.filter((contact) => contact.type == 'instructor')
);

// Create a selector to get the loading status (maintaining the same name)
export const isCreateContactProcessing = createSelector(selectContactsState, (state) => ({status: state.createNewContactProcess.status , error: state.createNewContactProcess.error, lastCreatedContact: state.createNewContactProcess.lastCreatedContact}));

// Create a selector to get the error status (maintaining the same name)
export const isRefundRequestsLoadingError = createSelector(selectContactsState, (state) => state.error);
