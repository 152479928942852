// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  h1.splash-message {
    animation: fadeInOut 2s ease-in-out forwards;
  }
  

  .bullet {
    transition: all 0.5s ease-in-out;
  }
  .bullet.active {
    width: 30px !important;
    background-color: var(--bs-primary) !important;
  }

  .bullet:hover {
    background-color: var(--bs-primary) !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/account-setup-wizard/welcome-step/welcome-step.component.css"],"names":[],"mappings":"AAAA;IACI,KAAK,UAAU,EAAE;IACjB,MAAM,UAAU,EAAE;IAClB,OAAO,UAAU,EAAE;EACrB;;EAEA;IACE,4CAA4C;EAC9C;;;EAGA;IACE,gCAAgC;EAClC;EACA;IACE,sBAAsB;IACtB,8CAA8C;EAChD;;EAEA;IACE,8CAA8C;EAChD","sourcesContent":["@keyframes fadeInOut {\n    0% { opacity: 0; }\n    50% { opacity: 1; }\n    100% { opacity: 0; }\n  }\n  \n  h1.splash-message {\n    animation: fadeInOut 2s ease-in-out forwards;\n  }\n  \n\n  .bullet {\n    transition: all 0.5s ease-in-out;\n  }\n  .bullet.active {\n    width: 30px !important;\n    background-color: var(--bs-primary) !important;\n  }\n\n  .bullet:hover {\n    background-color: var(--bs-primary) !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
