import { PeriodResultModel, PeriodTypeModel } from 'src/app/models/owner-dashboard';
import { DashboardStatisticsTracking } from './../../../../models/learmo-dashboard';
// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

export const DashboardTrackingActions = createActionGroup({
  source: 'Load Dashboard Tracking',
  events: {
    load:props<{period: PeriodTypeModel}>(), // Use emptyProps() for actions without props
    loadSuccess: props<{ dashboardTracking: DashboardStatisticsTracking }>(),
    loadFailure: props<{ error: any }>(),
  },
});


// export const loadDashboardTracking = createAction('[DashboardTracking] Load DashboardTracking', props<{period: PeriodTypeModel}>());
// export const loadDashboardTrackingSuccess = createAction('[DashboardTracking] Load DashboardTracking Success', props<{ dashboardTracking: DashboardStatisticsTracking }>());
// export const loadDashboardTrackingFailure = createAction('[DashboardTracking] Load DashboardTracking Failure', props<{ error: any }>());
// Define other actions as needed for your feature

// export const updateRefundRequest = createAction('[RefundRequests] Updated RefundRequests Successfully', props<{ itemId: number, item: RefundItem }>());
