import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { colorSet } from 'src/app/models/global-classes';


export class CtaMessageItem {
  main: {value: string, writing?: boolean};
  sub?: {value: string, writing?: boolean};
}

export class CtaFeature {
  title: string;
  sub: string;
  icon: string;
}
@Component({
  selector: 'app-ai-cta-card',
  templateUrl: './ai-cta-card.component.html',
  styleUrls: ['./ai-cta-card.component.css'],
})
export class AiCtaCardComponent implements OnInit {
  lock: boolean = false;
  @Output() CTAClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() SecondaryClick: EventEmitter<any> = new EventEmitter<any>();
  delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  @Input() buttonLabel: string = "Use Learmo AI";
  @Input() colorSet: colorSet = 'primary';
  @Input() hideMainAction: boolean = false;
  @Input() features: CtaFeature[] = []
  @Input() routeToGo: string | [] = null;
  compare_input: CtaMessageItem;
  _message: CtaMessageItem = {
    main: {value: '', writing: true},
    sub: {value: '', writing: true}
  }
  _image: string = 'assets/images/illustrations/brain-question.svg'

  messagesQueue: CtaMessageItem[] = [];
  constructor() {}




  @Input('message') set messageSetter(content: CtaMessageItem) {
    if (!!this.lock) {
      this.messagesQueue.push(content);
      
    }
    else {
    this.processMessageInput(content)
    }
  }


  async processMessageInput(content: CtaMessageItem, isItration: boolean = false) {

    if (this.compare_input?.main?.value != content?.main?.value || this.compare_input?.sub?.value != content?.sub?.value) {

      this.compare_input = content;
      if (!!content.main?.value && content.main.value != this._message.main.value) {
        this._message.main  = {value: '', writing: true}
      }
      if (!!content.sub?.value && content.sub.value != this._message.sub.value) {
        this._message.sub  = {value: '', writing: true}
      }

      if (!!content.main?.value && content.main.value != this._message.main.value) {
        let mainSplit = this.splitStringRandom(content.main.value);
        

        (async () => {
          this.lock = true;
          for (const char of mainSplit) {
            await this.delay(200);
            this._message.main.value = this._message.main.value.concat(char);
          }
          // Perform any actions with the final concatenated string here
          this._message.main.writing = false;
          if (!!content.sub?.value && content.sub.value != this._message.sub.value) {
            let subSplit = this.splitStringRandom(content.sub.value);
            

            (async () => {
              for (const char of subSplit) {
                await this.delay(250);
                this._message.sub.value = this._message.sub.value.concat(char);
              }
              // Perform any actions with the final concatenated string here
              this._message.sub.writing = false;
              this.lock = false;
              if (!!this.messagesQueue.length) {
                setTimeout(() => {
                  this.processMessageInput(this.messagesQueue[0])
                  this.messagesQueue.splice(0, 1)
                }, 1000);





              }
            })();
          }
          else {
            this.lock = false;
          }
        })();
      }
    }

  }

  async waitForSeconds(delay: number): Promise<string> {
   return new Promise((resolve) => setTimeout(resolve, delay));
  }

  splitStringRandom(input: string): string[] {
    if (!input) {
      return [];
    }

    const result: string[] = [];
    let remainingLength = input.length;

    while (remainingLength > 0) {
      const length = Math.floor(Math.random() * remainingLength) + 1;
      result.push(input.slice(0, length));
      input = input.slice(length);
      remainingLength = input.length;
    }

    return result;
  }

  @Input('image') set imageSetter(image: string) {
    this._image = image;
  }
  ngOnInit() {}
}
