import {
  FunnelSequenceStep,
  TemplatePageItem,
} from './../../../../../../components/user-dashboard/portal/portal.model';

import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-template-item-page',
  templateUrl: './template-item-page.component.html',
  styleUrls: ['./template-item-page.component.css'],
})
export class TemplateItemPageComponent implements OnInit, AfterViewInit {
  // funnelSequenceItemTypeEnum = FunnelSequenceItemTypeEnum;
  @Input() templatePage: TemplatePageItem;

  constructor() {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
