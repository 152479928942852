import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { CurrenciesModel } from 'src/app/models/sales-funnel';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.css']
})
export class MoneyInputComponent implements OnInit {
  @Input() currenciesList: CurrenciesModel[];


  _moneyFormGroup: any;
  @Output() formGroupChange = new EventEmitter<FormGroup>();

  @Input() valueFormControlName: string;
  @Input() currencyFormControlName: string;
  constructor(private api: ApiService) { }

  @Input('formGroup') set formControlValue(value: any) {
    this._moneyFormGroup = value;

    this._moneyFormGroup.valueChanges.subscribe((value) => {
      this.formGroupChange.emit(this._moneyFormGroup);
    });
  }


  async ngOnInit() {
    this.currenciesList = await this.getProducts();

  }

  handleValueChange(newValue: any) {
    
    // this._moneyFormGroup.get(this.valueFormControlName)?.setValue(newValue);
  }


  async getProducts() {
    let request$ = this.api.getCurrencies();
    let response = await lastValueFrom(request$);

    
    return response;
  }

}
