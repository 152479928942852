import { QuillEditorComponent } from 'ngx-quill';
import { EditorConfigService } from './../../../../../../shared/services/editor-config.service';
import {
  OwnerFunnelEmail,
  OwnerFunnelPage,
} from './../../../../../../models/funnels';
import {
  SaveButtonStatusEnum,
  SharedFunnelService,
} from './../../../shared-funnel.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export enum CurrentTabEnum {
  settings = 'settings',
  automation = 'automation',
}
@Component({
  selector: 'app-funnel-page-edit',
  templateUrl: './funnel-page-edit.component.html',
  styleUrls: ['./funnel-page-edit.component.css'],
})
export class FunnelPageEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  loaded = false;
  @ViewChild(QuillEditorComponent, { static: true })
  editorRef: QuillEditorComponent;
  formChangeSubscription: Subscription;
  pageDetailsForm = new FormGroup({
    newPage: new FormControl(null, {
      updateOn: 'change',
    }),
    pageTitle: new FormControl(null, {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  saveButtonStatusEnum = SaveButtonStatusEnum;
  currentTabEnum = CurrentTabEnum;

  @Input() currentPage: OwnerFunnelPage;

  saveEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public sharedFunnelService: SharedFunnelService,
    public editorConfig: EditorConfigService
  ) {}

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.loaded = false;
    await this.sharedFunnelService.getFunnelAvailablePages(
      this.sharedFunnelService.currentFunnelId
    );
    this.editorConfig.editorRef = this.editorRef;
    this.loaded = true;
    setTimeout(() => {
      this.formChangeSubscription = this.pageDetailsForm.valueChanges.subscribe(
        (val) => {
          this.sharedFunnelService.disableNavigate();
        }
      );
    }, 0);
  }

  ngAfterViewInit(): void {}

  goToTab(tab: CurrentTabEnum) {
    this.sharedFunnelService.onNavigationDisabled(() => {
      this.sharedFunnelService.goToPageEditingTab(tab);
    });
  }

  async saveChanges() {
    this.saveEmitter.emit();
  }

  close() {
    this.onClose.emit();
  }
}
