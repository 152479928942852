import { Title } from '@angular/platform-browser';
import { UserWebsite } from './../../../components/user-dashboard/portal/portal.model';
import { SharedPortalService } from './../../../components/user-dashboard/portal/shared-portal.service';

import { Component, OnInit } from '@angular/core';
import {
  AffiliateDashboardData,
  TotalTrackingData,
} from 'src/app/models/owner-dashboard';
import { findIndex, lastValueFrom } from 'rxjs';


import { trigger, transition, style, animate } from '@angular/animations';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  WizardModel,
  WizardStepStatusEnum,
  StepVisibilityEnum,
  WizardTypeEnum,
} from 'src/app/models/wizard';

export enum PortalActiveChoiceEnum {
  new = 'new',
  existing = 'existing',
}

@Component({
  selector: 'app-select-portal',
  templateUrl: './select-portal.component.html',
  styleUrls: ['./select-portal.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SelectPortalComponent implements OnInit {
  portalActiveChoiceEnum = PortalActiveChoiceEnum;
  // portalActiveChoice: PortalActiveChoiceEnum;
  tempPortal: { index: number; portal: UserWebsite } = {
    index: null,
    portal: null,
  };
  dashboardData: AffiliateDashboardData;
  createPortalForm = new UntypedFormGroup({
    portalName: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  disableCreateNewPortal: boolean = false;

  constructor(
    public sharedPortalService: SharedPortalService,
    private title: Title
  ) {
    this.title.setTitle('Choose Your Portal');
  }

  async ngOnInit() {
    this.sharedPortalService.startLoading();
    await this.sharedPortalService.getCountOfSubscriptionPermissionsPortalsAndDomains();
    if (
      this.sharedPortalService.numbersOfDomainsAndPortalsForUser.portals
        .current >=
      this.sharedPortalService.numbersOfDomainsAndPortalsForUser.portals.max
    ) {
      this.disableCreateNewPortal = true;
    }
    setTimeout(() => {
      this.sharedPortalService.stopLoading();
    }, 500);
  }

  onChooseExistingPortal(portal) {
    this.sharedPortalService.onChooseExistingPortal(portal);
  }

  onChooseCreateNewPortal() {
    if (!!this.disableCreateNewPortal) {
      return false;
    }
    // this.setInactiveChoice()
    this.setActivateChoice(PortalActiveChoiceEnum.new);
    if (
      !!this.sharedPortalService.wizardData.portalId ||
      !!this.sharedPortalService.portals?.currentPortal?.portal ||
      this.sharedPortalService.portals?.currentPortal?.index > -1
    ) {
      this.tempPortal = {
        index: this.sharedPortalService.portals.currentPortal.index,
        portal: this.sharedPortalService.portals.currentPortal.portal,
      };
      this.unsetSelectedPortal();
    }
    this.sharedPortalService.wizardModel.currentStep.step.hideButtons.next =
      false;
    this.sharedPortalService.wizardModel.currentStep.step.hideButtons.back =
      false;
  }

  setSelectedPortal(portal: UserWebsite) {
    this.setSelectedPortal(portal);
  }

  unsetSelectedPortal() {
    this.sharedPortalService.wizardData.portalId = null;
    this.sharedPortalService.portals.currentPortal = {
      index: null,
      portal: null,
    };
  }

  resetNewPortalData() {
    this.sharedPortalService.resetNewPortalData();
  }

  setActivateChoice(choice: PortalActiveChoiceEnum) {
    // this.sharedPortalService?.wizardModel?.markCurrentStepAsValid();
    this.sharedPortalService.setActivateChoice(choice);
  }

  setInactiveChoice() {
    this.sharedPortalService.setInactiveChoice();
  }

  async goNext() {
    this.createPortalForm.markAllAsTouched();
    if (!!this.createPortalForm.valid) {
      this.sharedPortalService?.wizardModel?.goToNextStep();
    }
  }
  // async getTemplates() {
  //   await this.sharedPortalService.getTemplates()
  // }
}
