// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Filter Search Area */
.custom-bootstrap-nav .nav-link {
  padding: 0 !important;
  padding-bottom: 0.2em !important;
}

/* .custom-bootstrap-nav .nav-item:first-child {
    padding: 0.5rem 0;
  } */

.custom-bootstrap-nav .nav-item {
  padding: 0.5rem 1em 1em 0;
}

.invisible-button {
  visibility: hidden;
}

.custom-bootstrap-nav .nav-link {
  color: #212529;
}
.custom-bootstrap-nav .nav-link.active {
  font-weight: bold;
  border-bottom: 4px solid #007bff !important;
}

.has-search .form-control {
  padding-left: 3.2rem;
  border-radius: 4px;
}

.has-search .form-control-feedback {
  position: absolute;
  /* z-index: 2; */
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  font-size: 1.4em;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
/* Filter Search Area */

.form-control {
  display: block;
  width: 20%;
  height: calc(2em + 0.75rem + 2px);
  padding: 1.375rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/head-title-add/head-title-add.component.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;;KAEK;;AAEL;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,2CAA2C;AAC7C;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;AACb;AACA,uBAAuB;;AAEvB;EACE,cAAc;EACd,UAAU;EACV,iCAAiC;EACjC,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,4BAA4B;;EAE5B,sBAAsB;AACxB","sourcesContent":["/* Filter Search Area */\n.custom-bootstrap-nav .nav-link {\n  padding: 0 !important;\n  padding-bottom: 0.2em !important;\n}\n\n/* .custom-bootstrap-nav .nav-item:first-child {\n    padding: 0.5rem 0;\n  } */\n\n.custom-bootstrap-nav .nav-item {\n  padding: 0.5rem 1em 1em 0;\n}\n\n.invisible-button {\n  visibility: hidden;\n}\n\n.custom-bootstrap-nav .nav-link {\n  color: #212529;\n}\n.custom-bootstrap-nav .nav-link.active {\n  font-weight: bold;\n  border-bottom: 4px solid #007bff !important;\n}\n\n.has-search .form-control {\n  padding-left: 3.2rem;\n  border-radius: 4px;\n}\n\n.has-search .form-control-feedback {\n  position: absolute;\n  /* z-index: 2; */\n  display: block;\n  width: 3.2rem;\n  height: 3.2rem;\n  line-height: 3.2rem;\n  font-size: 1.4em;\n  text-align: center;\n  pointer-events: none;\n  color: #aaa;\n}\n/* Filter Search Area */\n\n.form-control {\n  display: block;\n  width: 20%;\n  height: calc(2em + 0.75rem + 2px);\n  padding: 1.375rem 0.75rem;\n  font-size: 1.2rem;\n  font-weight: 400;\n  line-height: 1.5;\n  color: #495057;\n  background-color: #fff;\n  background-clip: padding-box;\n\n  border-radius: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
