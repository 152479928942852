// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* 
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 250;
} */
.app-header-container {
  height: var(--bs-app-header-height);
}

.app-sidebar.stick-sidebar, .app-sidebar.drawer-start #kt_app_sidebar_wrapper {
  transition: all 1s ease-in-out;
}




.app-sidebar.drawer-start:not(.stick-sidebar) {
  /* transform: translateX(-106%); */
}

.app-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0;
  padding-right: 0;
}

/* .app-main {
  margin-top: var(--bs-app-header-after-height-margin);
} */



/* @media (min-width: 992px) {

  .app-sidebar.small-sized {
    width: var(--bs-app-sidebar-width-actual);
  }
  .app-sidebar.drawer-start #kt_app_sidebar_wrapper {

    border-right: 0px var(--bs-primary) solid;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .app-sidebar.drawer-start {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .app-sidebar.drawer-start:not(.stick-sidebar) #kt_app_sidebar_wrapper {
    border-right: 5px var(--bs-primary) solid;
  }

  .app-sidebar.stick-sidebar {
      transform: translateX(-40px);
  }
.app-main.no-side {
  margin-left: calc(80px + var(--bs-app-sidebar-width-actual));
}
} */


`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/owner-dashboard.component.css"],"names":[],"mappings":";;AAEA;;;;;;GAMG;AACH;EACE,mCAAmC;AACrC;;AAEA;EACE,8BAA8B;AAChC;;;;;AAKA;EACE,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,gBAAgB;AAClB;;AAEA;;GAEG;;;;AAIH;;;;;;;;;;;;;;;;;;;;;;;;;GAyBG","sourcesContent":["\n\n/* \nheader {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 250;\n} */\n.app-header-container {\n  height: var(--bs-app-header-height);\n}\n\n.app-sidebar.stick-sidebar, .app-sidebar.drawer-start #kt_app_sidebar_wrapper {\n  transition: all 1s ease-in-out;\n}\n\n\n\n\n.app-sidebar.drawer-start:not(.stick-sidebar) {\n  /* transform: translateX(-106%); */\n}\n\n.app-content {\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n/* .app-main {\n  margin-top: var(--bs-app-header-after-height-margin);\n} */\n\n\n\n/* @media (min-width: 992px) {\n\n  .app-sidebar.small-sized {\n    width: var(--bs-app-sidebar-width-actual);\n  }\n  .app-sidebar.drawer-start #kt_app_sidebar_wrapper {\n\n    border-right: 0px var(--bs-primary) solid;\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n  }\n  .app-sidebar.drawer-start {\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n  }\n  .app-sidebar.drawer-start:not(.stick-sidebar) #kt_app_sidebar_wrapper {\n    border-right: 5px var(--bs-primary) solid;\n  }\n\n  .app-sidebar.stick-sidebar {\n      transform: translateX(-40px);\n  }\n.app-main.no-side {\n  margin-left: calc(80px + var(--bs-app-sidebar-width-actual));\n}\n} */\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
