// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  transition: all 0.5s ease-in-out !important;
}

/* .item:hover {
  --bs-bg-rgb-color: var(--bs-light-rgb);
  background-color: rgba(var(--bs-bg-rgb-color), 0.50) !important;
} */

.more-info {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out !important;
}
.item:hover .more-info {
  opacity: 1;
  transform: translateY(0px);
  max-height: 50px;
}


.ip-switch-fix {
  margin-top:0 !important;
}

.image-loaded-style {
  background-size: 100% !important;
  background-position: top center !important;
}

.loading-style {
  background-size: 37% !important;
  background-position: center center !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/blog-posts-list/blog-posts-list-item/blog-posts-list-item.component.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;;;GAGG;;AAEH;EACE,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,4BAA4B;EAC5B,2CAA2C;AAC7C;AACA;EACE,UAAU;EACV,0BAA0B;EAC1B,gBAAgB;AAClB;;;AAGA;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,0CAA0C;AAC5C;;AAEA;EACE,+BAA+B;EAC/B,6CAA6C;AAC/C","sourcesContent":[".item {\n  transition: all 0.5s ease-in-out !important;\n}\n\n/* .item:hover {\n  --bs-bg-rgb-color: var(--bs-light-rgb);\n  background-color: rgba(var(--bs-bg-rgb-color), 0.50) !important;\n} */\n\n.more-info {\n  max-height: 0;\n  overflow: hidden;\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: all 0.5s ease-in-out !important;\n}\n.item:hover .more-info {\n  opacity: 1;\n  transform: translateY(0px);\n  max-height: 50px;\n}\n\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.image-loaded-style {\n  background-size: 100% !important;\n  background-position: top center !important;\n}\n\n.loading-style {\n  background-size: 37% !important;\n  background-position: center center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
