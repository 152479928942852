import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { SharedAffiliateService } from '../../shared-affiliate.service';
import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
  AffiliatesInvitationStatusEnum,
} from '../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services';
import { HttpEventType, HttpResponse } from '@angular/common/http';

export enum InvitationCurrentStepEnum {
  upload = 'upload',
  input = 'input',
  result = 'result',
}

export class KajabiAffiliate {
  email: string;
  name: string;
  joined: boolean;
}

export class ImportFromKajabiResponse {
  totalCount: number;
  alreadyJoinedCount: number;
  affiliates: KajabiAffiliate[];
}
@Component({
  selector: 'app-invite-affiliates-from-kajabi',
  templateUrl: './invite-affiliates-from-kajabi.component.html',
  styleUrls: ['./invite-affiliates-from-kajabi.component.css'],
})
export class InviteAffiliatesFromKajabiComponent implements OnInit {
  invitationCurrentStepEnum = InvitationCurrentStepEnum;
  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;

  InvitationResult: AffiliateMinimizedModel[] = [];

  InvitationAffiliates: string[] = [];
  InvitationAffiliatesIndexes: number[] = [];
  FileResult: ImportFromKajabiResponse = {
    totalCount: 250,
    alreadyJoinedCount: 20,
    affiliates: [],
  };

  // {
  //   name: 'Hossam H. Abdah',
  //   email: 'semo.hassan4@gmail.com',
  //   joined: false,
  // },
  // { name: 'Hossam H. Abda', email: 'semo@gmail.com', joined: true },
  // { name: 'Hossam H. Abdall', email: 'hassan4@gmail.com', joined: false },
  // { name: 'Hossam H. Abdall', email: 'assan4@gmail.com', joined: false },
  // { name: 'Hossam H. Abdalla', email: 'assan4@gmail.com', joined: false },

  invitationCurrentStep: InvitationCurrentStepEnum =
    InvitationCurrentStepEnum.upload;

  // Private Affiliate Links
  myPrivateAffiliateLinks: AffiliateLinkMinimizedModel[];
  specificPrivateAffiliateLinks: boolean = false;
  selectedPrivateAffiliateLinks: number[] = [];

  // FileUpload
  @ViewChild('labelImport')
  fileNameLabel: string;

  fileToUpload: File = null;

  // pagination
  startIndex = 0;
  lastIndex = 10;
  perPage = 10;
  partiallyLoading = false;

  // Report Toaster
  reportToaster: ActiveToast<any>;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    await this.getMyAffiliateLinks();
  }

  onScroll(event: any): void {
    if (this.lastIndex < this.FileResult.affiliates.length) {
      this.partiallyLoading = true;
      setTimeout(() => {
        this.lastIndex = this.lastIndex + this.perPage;
        this.partiallyLoading = false;
      }, 1200);
    }
  }

  async uploadAndGoNext() {
    this.sharedAffiliateService.startProcessing();
    await this.onSubmitFile(this.fileToUpload);
    this.invitationCurrentStep = InvitationCurrentStepEnum.input;
    this.sharedAffiliateService.endProcessing();
  }

  async submitEmailsAndGoNext() {
    this.sharedAffiliateService.startProcessing();

    setTimeout(async () => {
      if (!!this.InvitationAffiliates?.length) {
        await this.inviteAffiliates({
          affiliateLinkIds: this.selectedPrivateAffiliateLinks,
          emails: this.InvitationAffiliates,
        });
        this.onInviteDone();
      } else {
        if (this.FileResult.alreadyJoinedCount >= this.FileResult.totalCount) {
          this.toastr.error(
            'All affiliate in this list are joined your network'
          );
        } else {
          this.toastr.error('You selected 0 affiliates to invite');
        }
        this.sharedAffiliateService.endProcessing();
      }
    }, 400);
  }

  async onInviteDone() {
    this.toastr.success('Done!', 'Affilaites invited successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.sharedAffiliateService.endProcessing();
    this.invitationCurrentStep = InvitationCurrentStepEnum.result;
    await this.sharedAffiliateService.getMyAffiliates();
  }

  async inviteAffiliates(invitationData: {
    emails: string[];
    affiliateLinkIds: number[];
  }) {
    const inviteAffiliates$ = this.api.inviteAffiliates(invitationData);
    const inviteAffiliates: AffiliateMinimizedModel[] = await lastValueFrom(
      inviteAffiliates$
    );
    this.InvitationResult = inviteAffiliates;
    return inviteAffiliates;
  }

  async onSubmitFile(file) {
    const progress = 0;
    const formData = new FormData();
    formData.append('file', file);

    // const resp =
    const fileResult$ = this.api.uploadKajabiCsv(formData);
    const fileResult: ImportFromKajabiResponse = await lastValueFrom(fileResult$);
    fileResult.affiliates.forEach((element, index) => {
      if (!element.joined) {
        this.onAffiliateChecked(element, true, index);
      }
    });

    this.FileResult = fileResult;
  }

  readUrl(event: { target: HTMLInputElement }) {
    const maxFileSize = 3;
    if (event.target.files.length === 0) {return false}
    // Image upload validation
    const mimeType = event.target.files[0].type;
    const filesize = event.target.files[0].size / 1024 / 1024;

    // if (mimeType.match(/.+(\/csv)/) == null) {
    //   this.toastr.error('Unsupported Type');
    //   return false;
    // }
    if (filesize > maxFileSize) {
      this.toastr.error('File Size is larger than 3MB');
      return false;
    }
    this.fileNameLabel = Array.from(event.target.files)
      .map((f) => f.name)
      .join(', ');
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async (_event) => {
      this.fileToUpload = event.target.files[0];
    };
  }

  async getMyAffiliateLinks() {
    const affiliateLinksData$ = this.api.getMyAffiliateLinks();
    const affiliateLinksData = await lastValueFrom(affiliateLinksData$);
    this.myPrivateAffiliateLinks = affiliateLinksData;
  }

  resetModal() {
    this.invitationCurrentStep = InvitationCurrentStepEnum.upload;
    this.sharedAffiliateService.endProcessing();
    this.FileResult = { affiliates: [], alreadyJoinedCount: 0, totalCount: 0 };
    this.selectedPrivateAffiliateLinks = [];
    this.InvitationAffiliates = [];
    this.InvitationAffiliatesIndexes = [];
    this.fileNameLabel = null;

    this.fileToUpload = null;

    // pagination
    this.startIndex = 0;
    this.lastIndex = 10;
    this.perPage = 20;
    this.partiallyLoading = false;
  }
  closeModal() {
    this.modalRef.dismiss();
    this.resetModal();
  }

  // Affiliate Links List
  onAffiliateLinkChecked(value: any, isChecked: boolean) {
    const index = this.selectedPrivateAffiliateLinks.findIndex(
      (a) => a === value
    );
    if (isChecked && index == -1) {
      this.selectedPrivateAffiliateLinks.push(value);
    } else {
      this.selectedPrivateAffiliateLinks.splice(index, 1);
    }
  }

  onAffiliateChecked(value: KajabiAffiliate, isChecked: boolean, i: number) {
    const index = this.InvitationAffiliates.findIndex((a) => a === value.email);
    if (isChecked && index == -1) {
      this.InvitationAffiliates.push(value.email);
      this.InvitationAffiliatesIndexes.push(i);
    } else {
      this.InvitationAffiliates.splice(index, 1);
      this.InvitationAffiliatesIndexes.splice(index, 1);
    }
  }

  isAffiliateInputChecked(i: number) {
    const index = this.InvitationAffiliatesIndexes.findIndex((a) => a === i);

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  isInputChecked(value: any) {
    const index = this.selectedPrivateAffiliateLinks.findIndex(
      (a) => a === value
    );

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  // FileUpload
}
