import { CreateQuizQuestionActions, UpdateQuizQuestionActions } from './course-quiz-item/course-quiz-items.actions';


// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { NEVER, of } from 'rxjs';



import { Store } from '@ngrx/store';
import { UpdateCourseItemActions, CourseItemStatusActions, DeleteCourseItemActions, ChangeItemProcessStatus, loadCourseItemFullDetails } from './course-items.actions';

import { CourseHeirarchyModel, CourseHierarchy, CourseHierarchyItem, CourseHierarchyItemTypeEnum } from '../../../../../../components/user-dashboard/product/models/course';
import { ApiService } from '../../../../../services';
import { updateCurrentCourseHeirarchyStructure } from '../../course/course.actions';


@Injectable()
export class CourseItemsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }



  loadCourseItemFullDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourseItemFullDetails.load), // Listen for the loadProducts action
      mergeMap(({ productId, itemId, itemType, errorHandler = undefined }) => {
        this.store.dispatch(ChangeItemProcessStatus({ statusChange: { itemId, itemType, status: 'loading_details' } }));
        return this.api.genericGetCourseItem(productId, itemId, itemType, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: CourseHierarchyItem) =>
            loadCourseItemFullDetails.success({ loadedItem: response, statusChange: { itemId, itemType, status: 'done' } })
          ),
          catchError((error) =>
            of(CourseItemStatusActions.failure({ error: { processType: 'loading_details', error }, statusChange: { itemId, itemType, status: 'error' } }))
          )
        )

      }
      )
    )
  );

  updateCourseItemStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseItemStatusActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) => {
        this.store.dispatch(ChangeItemProcessStatus({ statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'updating_status' } }));

        return this.api.changeCourseItemStatus(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: CourseHeirarchyModel) =>
            CourseItemStatusActions.success({ heirarchy: response, statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'done' } })
          ),
          catchError((error) =>
            of(CourseItemStatusActions.failure({ error: { processType: 'updating_status', error }, statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'error' } }))
          )
        )
      }
      )
    )
  );


  updateCourseItemDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCourseItemActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, itemId, itemType, payload, errorHandler = undefined }) => {

        this.store.dispatch(ChangeItemProcessStatus({ statusChange: { itemId, itemType: itemType, status: 'updating' } }));
        let selectedRequest = this.api.genericUpdateCourseItem(productId, itemId, itemType, payload, errorHandler);
        return selectedRequest.pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            UpdateCourseItemActions.success({ updatedItem: response, statusChange: { itemId, itemType: itemType, status: 'done' } })
          ),
          catchError((error) =>
            of(UpdateCourseItemActions.failure({ error: { processType: 'updating', error }, statusChange: { itemId, itemType, status: 'error' } }))
          )
        )
      }
      )
    )
  );

  deleteCourseItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCourseItemActions.delete), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) => {
        this.store.dispatch(ChangeItemProcessStatus({ statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'deleting' } }));

        return this.api.deleteCourseItem(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: { hierarchy: CourseHierarchy }) =>
            DeleteCourseItemActions.success({ heirarchy: { hierarchy: response.hierarchy }, statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'done' } })
          ),
          catchError((error) =>
            of(DeleteCourseItemActions.failure({ error: { processType: 'deleting', error }, statusChange: { itemId: payload.id, itemType: payload.itemType, status: 'error' } }))
          )
        )
      }
      )
    )
  );


 

  // Generic Functions

  updateCourseItemStatusToSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourseItemFullDetails.success, UpdateCourseItemActions.success, CourseItemStatusActions.success, DeleteCourseItemActions.success), // Listen for the loadProducts action
      mergeMap(({ statusChange }) => {
        return of(ChangeItemProcessStatus({ statusChange }));
      })
    )
  );

  updateCourseItemStatusToError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourseItemFullDetails.failure, UpdateCourseItemActions.failure, CourseItemStatusActions.failure, DeleteCourseItemActions.failure), // Listen for the loadProducts action
      mergeMap(({ statusChange, error }) => {
        return of(ChangeItemProcessStatus({ statusChange, error }));
      })
    )
  );




}
