import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]',
})
export class NumericDirective {
  @Input() decimals: number = 0;
  @Input() negative: boolean = false;
  @Input() maxValue: number | null = null;

  constructor(private el: ElementRef) {}

  private getRegExp(allowNegative: boolean): RegExp {
    const negativePart = allowNegative ? '-?' : '';
    const decimalPart = this.decimals > 0 ? `(\\.\\d{0,${this.decimals}})?` : '';
    const regExpString = `^${negativePart}\\d+${decimalPart}$`;
    return new RegExp(regExpString);
  }

  private validateValue(value: string, allowNegative: boolean): boolean {
    const regExp = this.getRegExp(allowNegative);
    if (!regExp.test(value)) {
      return false;
    }
    if (this.maxValue !== null && parseFloat(value) > this.maxValue) {
      return false;
    }
    return true;
  }

  private runValidation() {
    setTimeout(() => {
      const currentValue = this.el.nativeElement.value;
      const allowNegative = !!this.negative;
      const isValid = currentValue === '' || this.validateValue(currentValue, allowNegative);

      if (!isValid) {
        this.el.nativeElement.value = this.oldValue;
      } else {
        this.oldValue = currentValue;
      }
    });
  }

  private oldValue: string = '';

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Escape', 
      'Control', 'Meta', 'Shift', 'Alt'
    ];

    const numberKeys = '0123456789'.split('');
    const decimalKey = '.';
    const negativeKey = '-';

    // Allow special keys
    if (allowedKeys.includes(event.key) ||
        (event.ctrlKey || event.metaKey) && (event.key.toLowerCase() === 'a' || event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v' || event.key.toLowerCase() === 'x')) {
      return;
    }

    const allowNegative = !!this.negative;
    const currentValue = this.el.nativeElement.value;
    const isFirstCharacter = this.el.nativeElement.selectionStart === 0;

    // Block non-numeric, multiple decimals, invalid negative positions, and 'e'
    if (
      !numberKeys.includes(event.key) &&
      (event.key !== decimalKey || this.decimals <= 0 || currentValue.includes(decimalKey)) &&
      (event.key !== negativeKey || !allowNegative || !isFirstCharacter) ||
      event.key.toLowerCase() == 'e'
    ) {
      event.preventDefault();
    }

    this.runValidation();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.runValidation();
  }
}
