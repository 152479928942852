import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, timer, switchMap, Subscription, lastValueFrom } from 'rxjs';
import { SetWebsiteIdentityRequestBody, WebsiteIdentity } from 'src/app/components/user-dashboard/portal/portal.model';
import { SharedPortalViewService } from 'src/app/components/user-dashboard/portal/view/shared-portal-view.service';
import { InOutAnimation } from 'src/app/shared/animations/animations';
import { ApiService } from 'src/app/shared/services';
import { updateWebsiteIdentity } from 'src/app/shared/store/_features/website/website.actions';
import { selectWebsiteIdentity } from 'src/app/shared/store/_features/website/website.selector';

@Component({
  selector: 'app-portal-identity-step',
  templateUrl: './portal-identity-step.component.html',
  styleUrls: ['./portal-identity-step.component.css'],
  animations: [InOutAnimation],
})
export class PortalIdentityStepComponent implements OnInit {
  @Output() onSkip = new EventEmitter<any>();

  @Output() onDone = new EventEmitter<any>();

  processing: boolean = false;
  
  currentFocus: 'title' | 'description' = null;
  websiteTitleConfigurationForm = new FormGroup({
    title: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
    description: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [],
    }),
  });
  saveEmitter = new EventEmitter<any>();

  @Input() firstName: string;

  
  userName: string;

  subscriptions: Subscription[] = [];
  identity: WebsiteIdentity;

  loaded: boolean = false;
  constructor(private api: ApiService, private store: Store) {
    
    this.subscriptions.push(
      this.store.select(selectWebsiteIdentity).subscribe(value=> {
        
        this.identity = value;
        this.websiteTitleConfigurationForm.patchValue(this.identity);
        // this.websiteTitleConfigurationForm.patchValue(value);
        this.loaded = true;
      })
    )
  }
  ngOnInit() {
    
  }

  async setWebsiteIdentity(data: SetWebsiteIdentityRequestBody) {
    const request = this.api.setWebsitedentity(data);
    const response: WebsiteIdentity = await lastValueFrom(request);
    this.store.dispatch(updateWebsiteIdentity({ identity: response }));
    // this.currentWebsite.website.identity = response;
    return response;
  }
  async onSave() {
    if (!this.processing) {
    this.processing = true;
    let payload: SetWebsiteIdentityRequestBody = {
      title: this.websiteTitleConfigurationForm.value.title,
      description: this.websiteTitleConfigurationForm.value.description,
    }
    await this.setWebsiteIdentity(payload)

    this.onDone.emit();
    setTimeout(() => {
      this.processing = false;
    }, 500);
    }
  }

}
