import worldMap from '@highcharts/map-collection/custom/world.geo.json';

import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import {
  PeriodResultModel,
  PeriodTypeValue,
} from 'src/app/models/owner-dashboard';

import { ApiService } from 'src/app/shared/services';

import {
  ApexNonAxisChartSeries,
  ApexChart,
  ApexResponsive,
} from 'ng-apexcharts';
import { SharedEarningsService } from '../shared-earnings.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

export enum TechEarningsCategoryEnum {
  operating_system = 'operating-system',
  device = 'device',
  browser = 'browser'
}

export class TechEarningsCategory {
  id?: number;
  enum: TechEarningsCategoryEnum;
  displayName: string;
}
@Component({
  selector: 'app-owner-earnings-tech',
  templateUrl: './earnings-tech.component.html',
  styleUrls: ['./earnings-tech.component.css'],
})
export class EarningsTechComponent implements OnInit {

  // Page Start
  pageData: { compareLinksIds: number[]; period: PeriodResultModel } = {
    compareLinksIds: [],
    period: null,
  };

  periodLimits: PeriodTypeValue;

  constructor(
    public sharedEarningsService: SharedEarningsService,
    private router: Router,
    private api: ApiService
  ) {}

  async ngOnInit() {


  }


  async onPeriodChange(value: PeriodResultModel) {

  }
}
