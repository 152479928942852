import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

// Define a feature selector
export const selectUserState = createFeatureSelector<UserState>('user');

// Create selectors for individual properties
export const selectLearmoProfile = createSelector(
  selectUserState,
  (state) => {
    return state.learmoProfile
  }
);

export const selectAppwriteProfile = createSelector(
  selectUserState,
  (state) => state.appwriteProfile
);

export const selectToken = createSelector(
  selectUserState,
  (state) => state.token
);


export const selectLoginMethods = createSelector(
  selectUserState,
  (state) => state.loginMethods
);

// export const isEmailConfirmed = createSelector(
//   selectUserState,
//   (state) => state.isEmailConfirmed
// );

export const selectLoginFailureError = createSelector(
  selectUserState,
  (state) => state.error
);


export const selectSubscription = createSelector(
  selectUserState,
  (state) => state.subscription
);


export const isEmailChanged = createSelector(
  selectUserState,
  (state) => state.isEmailChanged
);

export const isWebhookDone = createSelector(
  selectUserState,
  (state) => state.isRegistrationWebhookDone
);