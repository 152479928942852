// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';
import { ApiService } from '../../../services';
import {
  addNewMediaLibraryFiles,


  MediaLibraryFileActions,


  MediaLibraryActions,


  MediaLibraryQuotaActions,


  MediaLibraryTypesActions,

} from './media-library.actions';

@Injectable()
export class MediaLibraryEffects {
  loadMediaLibraryFiles$;
  deleteMediaLibraryFile$;
  loadMediaLibraryTypes$;

  constructor(private actions$: Actions, private api: ApiService) {
    this.loadMediaLibraryFiles$ = createEffect(() =>
      this.actions$.pipe(
        ofType(MediaLibraryActions.load), // Listen for the loadProducts action
        mergeMap(({ category }) =>
          this.api.getAllMedia(category).pipe(
            // Use pipe method correctly within mergeMap
            map((response) => MediaLibraryActions.loadSuccess({ files: response })),
            catchError((error) => of(MediaLibraryActions.loadFailure({ error })))
          )
        )
      )
    );


    this.deleteMediaLibraryFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryFileActions.delete),
      mergeMap(({file}) => {
        return this.api.deleteMediaById(file.id).pipe(
          mergeMap((response) => {
            if (response?.success) {
              return of(MediaLibraryFileActions.deleteSuccess({ fileId: file.id }));
            }
            else {
              return of(MediaLibraryFileActions.deleteFailure({ fileId: file.id, error: response?.message }));
            }
          }),
          catchError((error) => of(MediaLibraryFileActions.deleteFailure({ fileId: file.id, error })))
        );
      })
    )
  );


    this.loadMediaLibraryTypes$ = createEffect(() =>
      this.actions$.pipe(
        ofType(MediaLibraryTypesActions.load), // Listen for the loadProducts action
        mergeMap(({ uploadType }) =>
          this.api.getMediaTypes().pipe(
            // Use pipe method correctly within mergeMap
            map((response) => {
              const filterByType = {
                media: ['image', 'video'],
                image: ['image'],
                video: ['video'],
              };
              const currentFilter = filterByType[uploadType];

              if (!!currentFilter) {
                response = response.filter((type) =>
                  currentFilter.includes(type.name)
                );
              }

              return MediaLibraryTypesActions.loadSuccess({ types: response });
            }),
            catchError((error) => of(MediaLibraryTypesActions.loadFailure({ error })))
          )
        )
      )
    );


    this.loadMediaLibraryTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryQuotaActions.load, MediaLibraryFileActions.delete, addNewMediaLibraryFiles), // Listen for the loadProducts action
      mergeMap(() =>
        this.api.getMediaLibraryQuota((error)=> {throw error}).pipe(
          // Use pipe method correctly within mergeMap
          map((response) => {
            console.log(response)

            return MediaLibraryQuotaActions.loadSuccess({ quota: response });
          }),
          catchError((error) => of(MediaLibraryQuotaActions.loadFailure({ error })))
        )
      )
    )
  );
  }
}
