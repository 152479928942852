import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationElement, OwnerNotificationTypeEnum } from '../../../../../../models/owner-dashboard';
import { NotificationsService } from '../../../../../services/notifications.service';

import { lastValueFrom } from 'rxjs';
import { AffiliateApi } from '../../../../../services/affiliate.api.service';
import { TimePipe } from 'src/app/shared/pipes/dateago.pipe';
import { NotificationStatusEnum } from 'src/app/models/affiliates-dashboard';
import { Store } from '@ngrx/store';
import { setNotificationAsRead } from 'src/app/shared/store/_features/notifications/notifications.actions';


@Component({
  selector: 'app-owner-notification-element',
  templateUrl: './owner-notification-element.component.html',
  styleUrls: ['./owner-notification-element.component.css'],
})
export class OwnerNotificationElementComponent implements OnInit {
  notificationStatusEnum = NotificationStatusEnum;
  @Input() forToast: boolean = false;
  @Input() firstElement: boolean = false;
  @Input() notificationObject: NotificationElement;

  @Output() NotificationElementClicked = new EventEmitter<NotificationElement>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  onClickNotification() {
    
    this.markNotificationAsRead();
    this.NotificationElementClicked.emit(this.notificationObject);
    switch (this.notificationObject.type) {
      case OwnerNotificationTypeEnum.invitation_accepted:
        this.goToAffiliateProfileById(
          this.notificationObject.target.affiliateId
        );
        break;
      case OwnerNotificationTypeEnum.invitation_rejected:
        this.goToAffiliateProfileById(
          this.notificationObject.target.affiliateId
        );
        break;
      case OwnerNotificationTypeEnum.new_sale:
          this.goToSalesItem(this.notificationObject.target.saleId)
        break;
        case OwnerNotificationTypeEnum.refund:
          this.goToRefundItem(this.notificationObject.target.refundId)
        break;
      default:
        break;
    }
  }

  goToSalesItem(id: number) {
    this.router.navigate(['dashboard', 'earnings', 'sales','item', id])
  }

  goToRefundItem(id: number) {
    this.router.navigate(['dashboard', 'refund','item', id])
  }

  async markNotificationAsRead() {
    this.store.dispatch(setNotificationAsRead({notificationsIds: [this.notificationObject.id]}))
  }

  getNotificationTimeAgo(value: number) {
    const timePipe = new TimePipe();
    let timeAgo: string = timePipe.transform(value * 1000);
    return timeAgo;
  }

  goToAffiliateProfileById(id: number) {
    this.router.navigate(['dashboard', 'affiliate', 'my-affiliates', id]);
  }

  ngOnInit() {}
}
