import { Component, OnInit, Input } from '@angular/core';
import zxcvbn from 'ngx-zxcvbn-wrapper';

@Component({
  selector: 'app-password-meter',
  templateUrl: './password-meter.component.html',
  styleUrls: ['./password-meter.component.css']
})
export class PasswordMeterComponent implements OnInit {
  strengthMeter;
  strengthScore;
  strengthPercentage;
  strengthFeedback = '';
  constructor() { }

  @Input('password') set passwordVal(value: string) {
    this.checkPasswordStrength(value);
  }
  ngOnInit() {
  }

  checkPasswordStrength(password: string) {
    this.strengthMeter = zxcvbn(password);
    this.strengthScore = +this.strengthMeter.score;
    this.strengthPercentage = (this.strengthMeter.score + 1) * 20;
    this.strengthFeedback = `${this.strengthMeter.feedback.suggestions.join(', ')}`
}


}
