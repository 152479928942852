import { PointerGuidanceService } from './pointer-guidance.service';
import { Router } from '@angular/router';
import { CreatePortalSteps } from './../onboarding/createPortal';
import { ShepherdService } from 'angular-shepherd';
import { Injectable, ViewChild } from '@angular/core';
import Shepherd from 'shepherd.js';
import {
  HelpActionsEnum,
  HelpCategory,
  HelpCategoryTypeEnum,
  HelpItemTypeEnum,
} from 'src/app/models/onboarding-items';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  portalMenuDropdown: NgbDropdown;

  lockDropdown: boolean = false;
  tour: Shepherd.Tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shadow-md bg-purple-dark',
      scrollTo: { inline: 'center', block: 'center', behavior: 'smooth' },
      cancelIcon: {
        enabled: true,
        label: 'Skip Tour',
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 7,
    },
    confirmCancel: true,
  });
  openHelpCenter = () => {
    window.open('https://help.myinfluencerportal.com/');
  };
  helpObject: HelpCategory[] = [
    {
      id: 2,
      displayName: 'Resources Center',
      description: 'Get started with these tools below',
      type: HelpCategoryTypeEnum.default,
      items: [
        {
          displayName: 'Help Center',
          icon: 'help-center',
          enum: HelpActionsEnum.help_center,
          type: HelpItemTypeEnum.default,
          action: this.openHelpCenter,
        },
        {
          displayName: 'Videos',
          icon: 'video',
          enum: HelpActionsEnum.help_center,
          type: HelpItemTypeEnum.default,
        },
        {
          displayName: 'Community',
          icon: 'community',
          enum: HelpActionsEnum.help_center,
          type: HelpItemTypeEnum.soon,
        },
      ],
    },
    {
      id: 3,
      displayName: 'Support',
      type: HelpCategoryTypeEnum.default,
      items: [
        {
          id: 1,
          displayName: 'Connect with support',
          icon: 'support',
          enum: HelpActionsEnum.help_center,
          type: HelpItemTypeEnum.default,
        },
        {
          id: 2,
          displayName: 'Ask for a feature',
          icon: 'feature',
          enum: HelpActionsEnum.help_center,
          type: HelpItemTypeEnum.default,
        },
      ],
    },
  ];

  specialHelp: HelpCategory = {
    id: 1,
    displayName: 'Current Section Support',
    description: 'Special Help related to current page',
    type: HelpCategoryTypeEnum.hidden,
    items: [
      {
        displayName: 'Take a tour',
        icon: 'onboarding',
        enum: HelpActionsEnum.help_center,
        type: HelpItemTypeEnum.default,
        action: null,
      },
    ],
  };

  constructor(
    private shepherdService: ShepherdService,
    private router: Router,
    public pointerGuidanceService: PointerGuidanceService
  ) {
    this.initializeTourObject();
  }

  get tourOngoing() {
    return this.tour.isActive();
  }

  initializeTourObject() {
    this.helpObject.unshift(this.specialHelp);
  }

  startTour() {
    this.tour.start();
  }

  async clickOnElement(
    query: string,
    type: 'fake' = null,
    timeout: number = 0
  ) {
    return new Promise((resolve, reject) => {
      let element = document.querySelectorAll(query)[0];
      if (!!element) {
        element.classList.add('pulse');

        setTimeout(() => {
          if (type !== 'fake') {
            (element as HTMLElement)?.click();
          }
          element.classList.remove('pulse');
          setTimeout(async () => {
            resolve(0);
          }, timeout);
        }, 500);
      }
    });
  }

  lockPortalDropDown() {
    return new Promise((resolve, reject) => {
      this.clickOnElement('#ip-create-funnel-portal-drop', 'fake');
      this.portalMenuDropdown.autoClose = false;
      setTimeout(() => {
        this.portalMenuDropdown.open();
        setTimeout(() => {
          resolve(0);
        }, 0);
      }, 500);
    });
  }

  unlockPortalDropDown = () => {
    
    // this.portalMenuDropdown.autoClose = true;
    // this.portalMenuDropdown.close();
  };

  clearTourSteps() {
    this.tour.complete();
    this.tour.steps = [];
  }

  setHelpItem(itemName: string, action: () => void | Promise<void>) {
    this.specialHelp.displayName = `${itemName} Section Support`;
    this.specialHelp.items[0].displayName = `Tour inside ${itemName}`;
    this.specialHelp.items[0].action = action;
    this.specialHelp.type = HelpCategoryTypeEnum.new;
  }

  resetHelpItem() {
    this.specialHelp.items[0].action = null;
    this.specialHelp.type = HelpCategoryTypeEnum.hidden;
  }

  // OnBoarding Items

  startCreateFirstPortalTour = (start: boolean = true) => {
    this.clearTourSteps();
    let steps = [...this.createFirstPortalTourSteps];
    this.tour.addSteps(steps);

    if (!!start) {
      this.startTour();
    }
  };

  startGeneralTour = (start: boolean = true) => {
    this.clearTourSteps();
    let steps = [
      ...this.generalTourStepsBeforePortal,
      ...this.portalTourSteps,
      ...this.salesTourSteps,
      ...this.generalTourStepsAfterPortal,
    ];
    this.tour.addSteps(steps);

    if (!!start) {
      this.startTour();
    }
  };

  startPortalTour = (start: boolean = true) => {
    this.clearTourSteps();

    this.tour.addSteps(this.portalTourSteps);

    if (!!start) {
      this.startTour();
    }
  };

  startHavePortalTour = (start: boolean = true) => {
    this.clearTourSteps();

    this.tour.addSteps([
      ...this.havePortalTourSteps,
      ...this.insidePortalTour_Pages,
      ...this.insidePortalTour_Overview,
      ...this.insidePortalTour_Sales,
      ...this.insidePortalTour_PaymentMethods,
      ...this.insidePortalTour_Marketing,
    ]);

    if (!!start) {
      this.startTour();
    }
  };

  startInsidePortalTour = (start: boolean = true) => {
    this.clearTourSteps();

    this.tour.addSteps([
      ...this.insidePortalTour_Pages,
      ...this.insidePortalTour_Overview,
      ...this.insidePortalTour_Sales,
      ...this.insidePortalTour_PaymentMethods,
      ...this.insidePortalTour_Marketing,
    ]);

    if (!!start) {
      this.startTour();
    }
  };

  showHelpCenterTour = (start: boolean = true) => {
    this.clearTourSteps();

    this.tour.addSteps(this.showHelpCenterTourSteps);

    if (!!start) {
      this.startTour();
    }
  };

  newPortalClickHandler = (selector: string) => {
    let element = document.querySelector(selector);
    
    if (!!element) {
      element.addEventListener('click', () => {
        this.clearTourSteps();
      });
    }
  };

  portalTourSteps: Shepherd.Step[] | object[] = [
    {
      id: 'ip-create-funnel-portal-drop',
      title: 'Start a new journey',
      text: 'You Can Create New Portal or Funnel From This Dropdown .',
      attachTo: {
        element: '#ip-create-funnel-portal-drop',
        on: 'left',
      },
      classes: 'example-step-extra-class me-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'ip-create-funnel-button',
      title: 'New Funnel',
      text: ' Funnels are pages within a Portal that have a purpose, like showing your product and turning prospects into leads or sales.',
      attachTo: {
        element: '#ip-create-funnel-button',
        on: 'left',
      },

      classes: 'example-step-extra-class me-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
      beforeShowPromise: () => {
        return this.lockPortalDropDown();
      },
      when: {
        cancel: () => {
          this.unlockPortalDropDown();
        },
      },
    },
    {
      id: 'ip-create-portal-button',
      title: 'New Portal',
      text: 'Portals are websites that can contain your pages and funnels.',
      attachTo: {
        element: '#ip-create-portal-button',
        on: 'left',
      },

      classes: 'example-step-extra-class me-6',
      buttons: [
        {
          text: 'Got it',
          classes: 'btn btn-success',
          action: () => {
            this.tour.next();
            this.unlockPortalDropDown();
          },
        },
      ],
    },
  ];

  createFirstPortalTourSteps: Shepherd.Step[] | object[] = [
    {
      id: 'ip-create-funnel-portal-drop',
      title: 'Start a new journey',
      text: 'You Can Create New Portal or Funnel From This Dropdown .',
      attachTo: {
        element: '#ip-create-funnel-portal-drop',
        on: 'left',
      },
      classes: 'example-step-extra-class me-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'ip-create-portal-button',
      title: 'Create, Start right here',
      text: "Portals are websites that can contain your pages and funnels let's start creating one.",
      cancelIcon: {
        enabled: false,
        label: 'Skip Tour',
      },
      canClickTarget: true,
      attachTo: {
        element: '#ip-create-portal-button',
        on: 'left',
      },
      beforeShowPromise: () => {
        return this.lockPortalDropDown();
      },
      when: {
        cancel: () => {
          this.unlockPortalDropDown();
        },
        show: () => {
          this.pointerGuidanceService.navigateToElementByID(
            '#ip-create-portal-button'
          );
          this.newPortalClickHandler('#ip-create-portal-button');
        },
      },
      classes: 'example-step-extra-class me-6',
    },
  ];

  generalTourStepsBeforePortal: Shepherd.Step[] | object[] = [
    {
      id: 'coursesTab',
      title: 'Courses',
      text: "Learn about the dos and don'ts of influencer marketing.",
      attachTo: {
        element: '#coursesTab',
        on: 'right',
      },
      classes: 'example-step-extra-class ms-6',

      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'portalsTab',
      title: 'My Portals',
      text: 'Create, View, and Manage everything related to your Portals and Pages',
      attachTo: {
        element: '#portalsTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      beforeShowPromise: () => {
        return this.clickOnElement('#portalsTab');
      },
      buttons: [
        {
          text: 'Back',
          classes: 'btn btn-secondary',
          action: this.tour.back,
        },
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
  ];

  salesTourSteps: Shepherd.Step[] | object[] = [
    {
      id: 'salesTab',
      title: 'Sales',
      text: 'Create, view and Manage your products, offers, funnels and affiliate programs.',
      attachTo: {
        element: '#salesTab',
        on: 'right',
      },
      classes: 'example-step-extra-class ms-6',
      beforeShowPromise: () => {
        return this.clickOnElement('#salesTab');
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'productsTab',
      title: 'My Products',
      text: 'here you can view your created products, or even create a new one',
      attachTo: {
        element: '#productsTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'offersTab',
      title: 'My Offers',
      text: 'here you can view your created offers, or even create a new one',
      attachTo: {
        element: '#offersTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'funnelsTab',
      title: 'My Funnels',
      text: 'here you can view your created funnels, or even create a new one',
      attachTo: {
        element: '#funnelsTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'affiliatesTab',
      title: 'Affiliate System Dashboard',
      text: 'here you can view your created funnels, or even create a new one',
      attachTo: {
        element: '#affiliatesTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'refundTab',
      title: 'Refund Requests',
      text: 'here you can view your sales refund requests, and take a decision about them',
      attachTo: {
        element: '#refundTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
  ];
  generalTourStepsAfterPortal: Shepherd.Step[] | object[] = [
    {
      id: 'systemEarningsTab',
      title: 'System Earnings',
      text: 'View and manage everything related to your sales and earnings.',
      attachTo: {
        element: '#systemEarningsTab',
        on: 'left',
      },
      classes: 'example-step-extra-class ms-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
  ];

  showHelpCenterTourSteps: Shepherd.Step[] | object[] = [
    {
      id: 'helpFloatingButton',
      title: 'Get help any time',
      text: 'Visit each page Tour, find helpful tutorials and articles or ask for help whenever you need it.',
      attachTo: {
        element: '#helpFloatingButton',
        on: 'top',
      },
      classes: 'example-step-extra-class mb-6',
      buttons: [
        {
          text: 'OK',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
  ];

  // Inside Portal
  havePortalTourSteps: Shepherd.Step[] | object[] = [
    {
      id: 'course-container',
      title: 'Journey Starts Here',
      text: 'This is your latest created portal',
      attachTo: {
        element: '.course-container',
        on: 'top',
      },
      classes: 'example-step-extra-class mb-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'portal-preview-button',
      title: 'Portal Preview',
      text: 'Click the Preview button to open your portal in a new tab',
      attachTo: {
        element: '.portal-preview-button',
        on: 'top',
      },

      classes: 'example-step-extra-class mb-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: this.tour.next,
        },
      ],
    },
    {
      id: 'portal-manage-button',
      title: 'Portal Management',
      text: 'Click the Manage button to edit everything related it, like pages, payment methods, domains, etc.',
      attachTo: {
        element: '.portal-manage-button',
        on: 'top',
      },

      classes: 'example-step-extra-class mb-6',
      buttons: [
        {
          text: 'Got it',
          classes: 'btn btn-success',
          action: async () => {
            this.tour.hide();
            await this.clickOnElement('.portal-manage-button', null, 1500);
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_Pages: Shepherd.Step[] | object[] = [
    {
      id: 'portal-pages',
      title: 'Pages',
      text: 'Here you can find website pages and funnels, also you can create new ones',
      attachTo: {
        element: '#portal-pages',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-pages', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
    {
      id: 'new-portal-page-action',
      title: 'Create a new page',
      text: 'Here, you can create a new website page',
      attachTo: {
        element: '#new-portal-page-action',
        on: 'left',
      },

      classes: 'example-step-extra-class me-6',
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_Overview: Shepherd.Step[] | object[] = [
    {
      id: 'portal-overview',
      title: 'Overview',
      text: 'Here you can find your portal general information',
      attachTo: {
        element: '#portal-overview',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-overview', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_Domains: Shepherd.Step[] | object[] = [
    {
      id: 'portal-domains',
      title: 'Domains',
      text: 'Here you can manage your portal domains',
      attachTo: {
        element: '#portal-domains',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-domains', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_Sales: Shepherd.Step[] | object[] = [
    {
      id: 'portal-sales',
      title: 'Sales',
      text: 'You can manage offers and affiliate links which attached to this portal',
      attachTo: {
        element: '#portal-sales',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-sales', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_PaymentMethods: Shepherd.Step[] | object[] = [
    {
      id: 'portal-payment-methods',
      title: 'Payment Methods',
      text: 'You can manage and integrate payment methods to be used by the offers attached to this portal',
      attachTo: {
        element: '#portal-payment-methods',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-payment-methods', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];

  insidePortalTour_Marketing: Shepherd.Step[] | object[] = [
    {
      id: 'portal-marketing',
      title: 'Marketing',
      text: 'Here, you can find all your marketing tools that would help you to start sell & earn',
      attachTo: {
        element: '#portal-marketing',
        on: 'bottom',
      },

      classes: 'example-step-extra-class mt-6',
      beforeShowPromise: async () => {
        return await this.clickOnElement('#portal-marketing', null, 0);
      },
      buttons: [
        {
          text: 'Next',
          classes: 'btn btn-primary',
          action: () => {
            this.tour.next();
          },
        },
      ],
    },
  ];
}
