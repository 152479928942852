import { SharedPortalService } from './../../../../../components/user-dashboard/portal/shared-portal.service';
import {
  FunnelTemplateItem,
  Funnel_Sequence_Enum,
  ThemeMediaTypeEnum,
} from './../../../../../components/user-dashboard/portal/portal.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
} from '../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../shared/services/lsg.api.service';
import { AffiliatesInvitationStatusEnum } from '../../../../../models/owner-dashboard';
import { emailRegex } from '../../../../../shared/services/regex';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}
@Component({
  selector: 'app-funnel-item-info-popup',
  templateUrl: './funnel-item-info-popup.component.html',
  styleUrls: ['./funnel-item-info-popup.component.css'],
})
export class FunnelItemInfoPopupComponent implements OnInit {
  @Input() selected: boolean = false;
  @Input() onClickUsePopup: EventEmitter<FunnelTemplateItem> =
    new EventEmitter<FunnelTemplateItem>();

  @Input() item: FunnelTemplateItem;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    public sharedPortalService: SharedPortalService
  ) {}

  async ngOnInit() {}

  resetModal() {}
  closeModal() {
    this.modalRef.dismiss();
    this.resetModal();
  }

  useActionPopup() {
    this.onClickUsePopup.emit(this.item);
  }
  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in informative-item-view-window',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }
}
