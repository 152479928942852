
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { AccountSetupStep } from '../../../../components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';

export const loadAccountSetupStepsActions = createActionGroup({
  source: ' Load Account Setup',
  events: {
    load: emptyProps(), // Use emptyProps() for actions without props
    loadSuccess: props<{ steps: AccountSetupStep[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});



// Actions for loading websites
// export const loadAccountSetupSteps = createAction('[Account Setup] Load Account Setup');
// export const loadAccountSetupStepsSuccess = createAction('[Account Setup] Load Account Setup Success', props<{ steps: AccountSetupStep[] }>());
// export const loadAccountSetupStepsFailure = createAction('[Account Setup] Load Account Setup Failure', props<{ error: any }>());


// Actions for updating identity
export const setStepInProgress = createAction('[Account Setup] Account Setup Step Is in progress', props<{ step: AccountSetupStep }>());
export const stepUpdate = createAction('[Account Setup] Account Setup Step Done', props<{ step: AccountSetupStep }>());

