export function genericSort(
    items: any[],
    column: string = '',
    order: 'asc' | 'dsc' = 'asc'
  ): any[] {
    if (!Array.isArray(items) || !column || !order) {
      return items;
    }
  
    const sortedItems = [...items]; // Create a new array to avoid modifying the original
  
    sortedItems.sort((a, b) => {
      const valueA = extractValue(a, column);
      const valueB = extractValue(b, column);
  
      if (typeof valueA === 'string') {
        return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
      return 0;
    });
  
    return sortedItems;
  }
  
  function extractValue(item: any, column: string): any {
    if (item && typeof item === 'object' && column in item) {
      return item[column];
    }
    return item;
  }
  