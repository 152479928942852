import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  mergeMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  finalize,
  switchMap,
  concatMap,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';
import { concat, interval, of } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { AuthenticationService } from '../../../../services/authentication.service';
import * as AuthActions from '../auth/auth.actions';
import * as RegisterActions from '../register/register.actions';

import { ApiService } from '../../../../services';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';

import { environment } from 'src/environments/environment';
import { AppwriteException } from 'appwrite';

@Injectable()
export class RegisterEffects {
  private currentUserStorageKey = environment.storageKeys.currentUserStorageKey;
  private appwriteProfileStorageKey =
    environment.storageKeys.appwriteProfileStorageKey;
  private tokenStorageKey = environment.storageKeys.tokenStorageKey;
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private appwriteService: AppwriteService,
    private router: Router,
    private store: Store
  ) {}

  // initializeState$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.initializeState),
  //     mergeMap(() => {
  //       const storedToken = localStorage.getItem(this.tokenStorageKey);
  //       const storedProfile = localStorage.getItem(this.currentUserStorageKey);
  //       console.log({storedProfile, storedToken})
  //       if (storedToken && storedProfile) {
  //         const parsedProfile = JSON.parse(storedProfile);

  //         // Dispatch actions to set the state with data from localStorage
  //         return [
  //           AuthActions.setUserToken({ token: storedToken }),
  //           AuthActions.setCurrentUserProfile({ profile: parsedProfile }),
  //         ];
  //       } else {
  //         // If no data in localStorage, return an empty action
  //         return of({ type: 'NO_ACTION' });
  //       }
  //     })
  //   )
  // );

  createNewAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterActions.createNewAccount),
      mergeMap(({ firstname, lastname, email, password }) =>
        this.appwriteService
          .createNewAccountWithEmail({
            firstname,
            lastname,
            email,
            password,
          })
          .pipe(
            mergeMap((response) => [
              AuthActions.loginWithEmailAndPassword({ email, password, fromRegister: true }),
            ]),
            catchError(async (error: AppwriteException) =>
              AuthActions.loginFailure({ error })
            )
          )
      )
    )
  );

  sendEmailVerificationRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(RegisterActions.emailVerificationRequestActions.send),
    switchMap(() =>
      this.appwriteService.emailVerificationRequest().pipe(
        switchMap((result) => {
          // Do something with the result if needed
          console.log(result);

          // Dispatch a success action if necessary
          return of(RegisterActions.emailVerificationRequestActions.sendSuccess({ result }));
        }),
        catchError((error) => {
          // Handle the error, and dispatch a failure action if necessary
          console.log(error);
          return of(RegisterActions.emailVerificationRequestActions.sendFailure({ error }));
        })
      )
    )
  )
);

  showLoginFailureAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap(({ error }) => {
          // Show a Swal alert for login failure
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Login Failed',
          //   text: error,
          // });
        })
      ),
    { dispatch: false } // This effect does not dispatch any new actions
  );

  forgot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forgetSession),
        tap(() => {
          localStorage.removeItem(this.currentUserStorageKey);
          localStorage.removeItem(this.appwriteProfileStorageKey);
          localStorage.removeItem(this.tokenStorageKey);
        })
      ),
    { dispatch: false } // This effect does not dispatch any new actions
  );
}
