import {
  TemplateMediaItem,
  ThemeMediaTypeEnum,
} from './../../../components/user-dashboard/portal/portal.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { EffectFlip, SwiperOptions } from 'swiper';

import SwiperCore, { Zoom, FreeMode, Navigation, Thumbs, Lazy } from 'swiper';
SwiperCore.use([Zoom, FreeMode, Navigation, Thumbs, Lazy]);
// SwiperCore.use([EffectCards]);
@Component({
  selector: 'app-item-gallery',
  templateUrl: './item-gallery.component.html',
  styleUrls: ['./item-gallery.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ItemGalleryComponent implements OnInit {
  // @Input() mainImage: TemplateMediaItem;
  // @Input() images: TemplateMediaItem[];

  @Output() onDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  themeMediaTypeEnum = ThemeMediaTypeEnum;
  thumbsSwiper: any;

  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: {
      enabled: true,
    },
    direction: 'horizontal',
    pagination: { clickable: true },
    slideClass: 'item-gallery-custom',
    effect: 'slide',
    grabCursor: true,
    centeredSlides: true,
    zoom: {
      maxRatio: 2,
      toggle: true,
    },
    // preventClicks: true,
    // preventClicksPropagation: true,
  };
  _loadedMain: boolean = false;
  _loadedImages: boolean = false;
  _images: TemplateMediaItem[] = [];

  constructor() {}
  @Input('mainImage') set mainImage(item: TemplateMediaItem) {
    // this._images.unshift(item);
    setTimeout(() => {
      this._loadedMain = true;
    }, 300);
  }

  @Input('images') set images(value: TemplateMediaItem[]) {
    // this._images = value;
    this._images.push(...value);

    this._loadedImages = true;
  }

  onSwiper([swiper]) {}
  onSlideChange() {}

  ngOnInit(): void {
    // this._images = [
    //   new ImageItem({ src: this.mainImage.url, thumb: this.mainImage.url }),
    //   // ... more items
    // ];
  }
}
