// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}

.ip-table tbody td {
  padding: 0.4em 0.75em !important;
}

.ip-table th {
  padding: 1em 0.75em !important;
}



.stick-this-to-top {
  position: sticky;
  top: 0;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/affiliate/owner-announcements/announcement-create-popup/announcement-create-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;;;AAIA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n.ip-table tbody td {\n  padding: 0.4em 0.75em !important;\n}\n\n.ip-table th {\n  padding: 1em 0.75em !important;\n}\n\n\n\n.stick-this-to-top {\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
