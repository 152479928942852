import { FunnelSequenceItemTypeEnum } from './../../../../../../models/funnels';
import {
  FunnelSequenceStep,
  Funnel_Sequence_Enum,
} from './../../../../../../components/user-dashboard/portal/portal.model';

import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-funnel-item-page',
  templateUrl: './funnel-item-page.component.html',
  styleUrls: ['./funnel-item-page.component.css'],
})
export class FunnelItemPageComponent implements OnInit, AfterViewInit {
  Funnel_Sequence_Enum = Funnel_Sequence_Enum;
  @Input() funnelSequence: FunnelSequenceStep[];

  @Input() firstItem: boolean = false;
  constructor() {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
