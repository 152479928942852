import { SystemUserBase } from "../../../../models/system.base.models";

export type ContactItemType = 'instructor' | 'customer' | 'student' | 'lead';
export class ContactItem extends SystemUserBase {

  type?: ContactItemType;
}



export class InstructorItem extends ContactItem {

}

