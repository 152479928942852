const ICONS = {
    category_name: 'Notifications',
    icons_list: [
      "notification-circle",
      "notification-favorite",
      "notification-2",
      "notification",
      "notification-bing",
      "notification-status",
      "notification-on",
      "message-text-2",
      "message-notif",
      "message-add",
      "sms",
      "directbox-default",
      "message-text",
      "messages",
      "address-book",
      "message-edit",
      "message-minus",

    ]



};


export default ICONS
