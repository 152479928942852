import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';

import { SharedAffiliateService } from '../../shared-affiliate.service';
import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
} from '../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../shared/services/lsg.api.service';
import { AffiliatesInvitationStatusEnum } from '../../../../../models/owner-dashboard';
import { emailRegex } from '../../../../../shared/services/regex';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}
@Component({
  selector: 'app-select-affiliate-links',
  templateUrl: './select-affiliate-links.component.html',
  styleUrls: ['./select-affiliate-links.component.css'],
})
export class SelectAffiliateLinkComponent implements OnInit {
  @Output() valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  // Private Affiliate Links
  specificPrivateAffiliateLinks: boolean = false;
  selectedPrivateAffiliateLinks: number[] = [];

  // Ng-Tag Emails Input
  myPrivateAffiliateLinks: AffiliateLinkMinimizedModel[];

  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private api: ApiService
  ) {}

  async ngOnInit() {
    await this.getMyAffiliateLinks();
  }

  @Input('preSelect') set preSelect(value: number[]) {
    if (!!value.length) {
      this.selectedPrivateAffiliateLinks = value;
      this.specificPrivateAffiliateLinks = true;
    }
  }

  async getMyAffiliateLinks() {
    let affiliateLinksData$ = this.api.getMyAffiliateLinks();
    let affiliateLinksData = await lastValueFrom(affiliateLinksData$);
    this.myPrivateAffiliateLinks = affiliateLinksData;
  }

  // Affiliate Links List
  onAffiliateLinkChecked(value: any, isChecked: boolean) {
    const index = this.selectedPrivateAffiliateLinks.findIndex(
      (a) => a === value
    );
    if (isChecked && index == -1) {
      this.selectedPrivateAffiliateLinks.push(value);
    } else {
      this.selectedPrivateAffiliateLinks.splice(index, 1);
    }
    this.valueChange.emit(this.selectedPrivateAffiliateLinks);
  }

  isInputChecked(value: any) {
    const index = this.selectedPrivateAffiliateLinks.findIndex(
      (a) => a === value
    );

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
}
