import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  mergeMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  finalize,
  switchMap,
  filter,
  takeUntil,
  takeWhile,
  take,
  timeout,
  delay,
} from 'rxjs/operators';
import { EMPTY, concat, from, interval, of, timer } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { AuthenticationService } from '../../../../services/authentication.service';
import * as AuthActions from './auth.actions';
import * as RegisterActions from '../register/register.actions';
import { ApiService } from '../../../../services';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { selectUserState } from '../user.selector';
import { environment } from 'src/environments/environment';
import { AppwriteException } from 'appwrite';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthEffects {
  private currentUserStorageKey = environment.storageKeys.currentUserStorageKey;
  private appwriteProfileStorageKey =
    environment.storageKeys.appwriteProfileStorageKey;
  private tokenStorageKey = environment.storageKeys.tokenStorageKey;
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private appwriteService: AppwriteService,
    private router: Router,
    private store: Store
  ) {}

  initializeState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initializeState),
      mergeMap(() => {
        const storedToken = localStorage.getItem(this.tokenStorageKey);
        const storedLearmoProfile = localStorage.getItem(
          this.currentUserStorageKey
        );
        const storedAppwriteProfile = localStorage.getItem(
          this.currentUserStorageKey
        );

        if (
          storedToken &&
          storedLearmoProfile &&
          storedAppwriteProfile &&
          storedLearmoProfile != 'undefined' &&
          storedToken != 'undefined' &&
          storedAppwriteProfile != 'undefined'
        ) {
          const parsedLearmoProfile = JSON.parse(storedLearmoProfile);
          const parsedAppwriteProfile = JSON.parse(storedAppwriteProfile);
          return [
            AuthActions.setUserToken({ token: storedToken }),
            AuthActions.setCurrentUserLearmoProfile({
              learmoProfile: parsedLearmoProfile,
            }),
            AuthActions.setCurrentUserAppwriteProfile({
              appwriteProfile: parsedAppwriteProfile,
            }),
          ];

          // Dispatch actions to set the state with data from localStorage
        } else {
          // If no data in localStorage, return an empty action
          return of({ type: 'NO_ACTION' });
        }
      })
    )
  );

  signinRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithEmailAndPassword),
      mergeMap(({ email, password, fromRegister }) =>
        this.appwriteService
          .loginWithEmailAndPassword({ email, password })
          .pipe(
            map((response) => AuthActions.generateUserToken()),
            catchError(async (error: AppwriteException) =>
              AuthActions.loginFailure({ error })
            )
          )
      )
    )
  );

  generateToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.generateUserToken),
      mergeMap(() =>
        this.appwriteService.createJWT().pipe(
          switchMap((result) =>
            concat(
              of(AuthActions.setUserToken({ token: result.jwt })),
              of(AuthActions.CheckWebHook())
            )
          ),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshUserToken),
      take(1),
      mergeMap(({ }) =>
      this.appwriteService.createJWT().pipe(
        switchMap((result) =>
          concat(
            of(AuthActions.setUserToken({ token: result.jwt })),
            of(AuthActions.unlockTokenRefreshSchedule())
          )
        ),
        catchError((error) => of(AuthActions.loginFailure({ error })))
      )
      )
    )
  );

  setUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserToken),
      withLatestFrom(this.store.pipe(select(selectUserState))),
      mergeMap(([{ token }, state]) => {
        try {
          const currentDate = Math.floor(new Date().getTime() / 1000);
          const decoded = jwtDecode(token);

          const tokenLifeTime = decoded.exp - currentDate;
          // Assuming 'exp' is a required field in the decoded token
          console.log('Token Lifetime NGRX: ', tokenLifeTime);
          if (!tokenLifeTime || tokenLifeTime < 240) {
            console.log('Refresh NOW ');
            this.store.dispatch(AuthActions.lockTokenRefreshSchedule({ tokenTimeout: tokenLifeTime }));
            return this.appwriteService.refreshToken().pipe(
              switchMap((result) =>  concat(
                of(AuthActions.setUserToken({ token: result })),
                of(AuthActions.unlockTokenRefreshSchedule())
              )),
              catchError((error) => of(AuthActions.loginFailure({ error })))
            );
          } else {

            if (!!state.isTokenRefreshScheduled) {
              return EMPTY;
            }
            console.log('Will Refresh after ', (tokenLifeTime - 240));
            return timer((tokenLifeTime - 240) * 1000).pipe(
              take(1),
              switchMap(() => {
                const tokenTimeout = tokenLifeTime - 240;
                this.store.dispatch(AuthActions.lockTokenRefreshSchedule({ tokenTimeout: tokenTimeout }));
                return of(AuthActions.refreshUserToken());
              })
            )
          }

        } catch (error) {
          console.error('Error decoding token:', error);
          // Handle decoding errors appropriately
          return EMPTY;
        }
      })
    )
  );

  WaitUntilWebhookDone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.CheckWebHook),
        switchMap(() =>
          interval(1000).pipe(
            switchMap(() => this.appwriteService.isWebhookDone()), // Assuming isWebhookDone returns a promise
            filter((isWebhookDone) => !!isWebhookDone), // Continue only when isWebhookDone is truthy
            take(1) // Take only the first emission after the condition becomes true
          )
        ),
        tap(() => {
          this.store.dispatch(AuthActions.onWebHookDone()),
            this.store.dispatch(
              AuthActions.getCurrentUserLearmoProfile({ redirect: true })
            );
          this.store.dispatch(AuthActions.getCurrentUserAppwriteProfile());
        })
      ),
    { dispatch: false } // This effect does not dispatch any new actions
  );

  getUserLearmoProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCurrentUserLearmoProfile),
      mergeMap(({ redirect }) =>
        this.api.me().pipe(
          switchMap((result) =>
            concat(
              of(
                AuthActions.setCurrentUserLearmoProfile({
                  learmoProfile: result,
                })
              ),
              !!redirect ? of(AuthActions.loginSuccess()) : undefined
            )
          ),
          catchError((error) => [AuthActions.loginFailure(error)])
        )
      )
    )
  );

  getUserAppwriteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCurrentUserAppwriteProfile),
      mergeMap(() =>
        this.appwriteService.getUserCurrentUserAppwriteProfile().pipe(
          switchMap((result) =>
            concat(
              of(
                AuthActions.setCurrentUserAppwriteProfile({
                  appwriteProfile: result,
                })
              )
            )
          ),
          catchError((error) => [AuthActions.loginFailure(error)])
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        withLatestFrom(this.store.pipe(select(selectUserState))),
        mergeMap(([type, userState]) => {
          // Handle the successful login actions here

          // Use userState to access other parts of the state if needed
          const token = userState.token;
          const profile = userState.learmoProfile;

          if (!!userState?.isRegistering) {
            this.store.dispatch(RegisterActions.emailVerificationRequestActions.send());
          }

          return [this.handleNavigation({ profile })];
          // Store data in localStorage
          // localStorage.setItem(this.tokenStorageKey, token);
          // localStorage.setItem(
          //   this.currentUserStorageKey,
          //   JSON.stringify(profile)
          // );

          // You may want to dispatch additional actions or perform other tasks here
        })
      ),
    { dispatch: false }
  );

  //   refreshToken$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.refreshUserToken),
  //     mergeMap(() =>
  //       this.appwriteService.createJWT().pipe(
  //         map((result) =>
  //           AuthActions.setUserToken({ token: result.jwt }))
  //         ,
  //         catchError((error) => of(AuthActions.loginFailure({ error })))
  //       )
  //     )
  //   )
  // );

  markWebsiteOnboardingAsDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.markWebsiteOnboardingAsDone),
      mergeMap(() =>
        this.api.markWebsiteOnboardingAsDone().pipe(
          map((resp) => {
            return AuthActions.markWebsiteOnboardingAsDoneSuccess({
              resp: resp?.onBoarding?.website_setup == 1,
            });
          })
        )
      )
    )
  );
  // storeTokenInLocalStorage$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AuthActions.setUserToken),
  //       map(({ token }) => localStorage.setItem('token', token))
  //     ),
  //   { dispatch: false }
  // );

  showLoginFailureAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap(({ error }) => {
          // Show a Swal alert for login failure
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Login Failed',
          //   text: error,
          // });
        })
      ),
    { dispatch: false } // This effect does not dispatch any new actions
  );

  forgot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forgetSession),
        tap(({ redirect }) => {
          localStorage.removeItem(this.currentUserStorageKey);
          localStorage.removeItem(this.appwriteProfileStorageKey);
          localStorage.removeItem(this.tokenStorageKey);
          this.appwriteService.regenerateAppwriteClient();
          if (!!redirect) {
            this.router.navigate(['auth/login']);
          }
        })
      ),

    { dispatch: false } // This effect does not dispatch any new actions
  );

  private handleNavigation(resp: any): void {
    const { profile, redirectUrl } = resp;
    switch (profile?.role?.name) {
      case 'subscriber':
        if (profile.subscription.isSubscribed) {
          this.router.navigate([redirectUrl || 'dashboard']);
        } else {
          this.router.navigate(['/choose-plan']);
        }
        break;
      case 'affiliate':
        this.router.navigate(['/affiliate']);
        break;
      default:
        this.router.navigate(['dashboard']);
        break;
    }
  }
}
