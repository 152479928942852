const ICONS = {
    category_name: 'Time',
    icons_list: [
      "calendar-add",
      "calendar-search",
      "calendar-2",
      "calendar-tick",
      "time",
      "watch",
      "calendar-edit",
      "calendar",
      "calendar-8",
      "timer",
      "calendar-remove"
    ]


};


export default ICONS
