import { Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductType } from '../../../../models/product';
import { ProductCreateService } from '../../../product-create.service';
import {  Offer_Payment_Type_Enum } from 'src/app/components/user-dashboard/offer/models/offer';

@Component({
  selector: 'app-product-type-preview',
  templateUrl: './product-type-preview.component.html',
  styleUrls: ['./product-type-preview.component.css']
})
export class ProductTypePreviewComponent implements OnInit, AfterViewInit {
  Offer_Payment_Type_Enum = Offer_Payment_Type_Enum;
  @Output() somethingClick = new EventEmitter<string>();
  @Input() productType: ProductType;
  @ViewChild('productTypeModal') modalContent: TemplateRef<any>;
  modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private route: Router,
    public productCreateService: ProductCreateService
  ) {
    (window as any).smoothScroll = this.smoothScroll.bind(this);
  }


  ngAfterViewInit(): void {
    
    setTimeout(() => {
      this.smoothScroll('bottom', 1500);
      setTimeout(() => {
        this.smoothScroll('top', 300);
      }, 1800);
    }, 500);
    
    
  }


  smoothScroll(direction: 'top' | 'bottom', duration) {
    const viewPort = document.getElementById('viewPort');
    const maxScroll = viewPort.scrollHeight - viewPort.getBoundingClientRect().height;
    const scrollStep = 10;
    const scrollDelay = 1000 / 60; // 60 frames per second
  
    function scroll(currentTime, targetScroll) {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  
      viewPort.scrollTop = startingScroll + easeInOutQuad(progress) * (targetScroll - startingScroll);
  
      if (timeElapsed < duration) {
        requestAnimationFrame(newTime => scroll(newTime, targetScroll));
      }
    }
  
    const startingScroll = viewPort.scrollTop;
    const startTime = performance.now();
  
    const targetScroll = direction === 'bottom' ? maxScroll : 0;
  
    setTimeout(() => {
      scroll(startTime, targetScroll);
    }, scrollDelay);
  }
  
  




  ngOnDestroy(): void {

  }

  async ngOnInit() {

  }

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List


  clickOn(value: string) {
    this.somethingClick.emit(value)
  }
  // Modal Open

  async open(content?) {
    
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: true,
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in informative-item-view-window',
    });
  }

  useActionPopup() {

  }


  async onOpenModal() {

  }


}
