import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MessengerThread,
  MessengerThreadStatusEnum,
} from '../../../../../../models/owner-dashboard';
import { AffiliateApi } from '../../../../../services/affiliate.api.service';
import { TimePipe } from '../../../../../pipes/dateago.pipe';
import { SharedMessengerService } from '../../../../../partials/messenger/shared-messenger.service';
import {
  UserDashboardService,
  DashboardSessionModeType,
} from '../../../../../services/user-dashboard.service';

@Component({
  selector: 'app-owner-messages-element',
  templateUrl: './owner-messages-element.component.html',
  styleUrls: ['./owner-messages-element.component.css'],
})
export class OwnerMessagesElementComponent implements OnInit {
  messengerThreadStatusEnum = MessengerThreadStatusEnum;
  @Input() forToast: boolean = false;
  @Input() firstElement: boolean = false;
  @Input() thread: MessengerThread;
  @Input() isAffiliate: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public affiliateApi: AffiliateApi,
    public sharedMessengerService: SharedMessengerService,
    public userDashboardService: UserDashboardService
  ) {
    this.isAffiliate = this.userDashboardService?.dashboardSessionMode ==
    DashboardSessionModeType.affiliate;
  }

  onClickNotification() {
    this.markNotificationAsRead();
    // switch (this.notificationObject.type) {
    //   case OwnerNotificationTypeEnum.invitation_accepted:
    //     this.goToAffiliateProfileById(
    //       this.notificationObject.target.affiliateId
    //     );
    //     break;

    //   default:
    //     break;
    // }
  }

  async markNotificationAsRead() {
    this.thread.status = MessengerThreadStatusEnum.read;
  }

  getNotificationTimeAgo(value: number) {
    const timePipe = new TimePipe();
    const timeAgo: string = timePipe.transform(value * 1000);
    return timeAgo;
  }

  async goToThread(id: number) {
    if (this.isAffiliate) {
      this.router.navigate(['affiliate', 'messenger', id]);
    } else {
      this.router.navigate(['dashboard', 'messenger', id]);
    }

    await this.sharedMessengerService.changeActiveThread(id);
    this.sharedMessengerService.enableGalleryRefresh();
  }

  ngOnInit() {}
}
