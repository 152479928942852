import { MiniPortalItem } from './../../../../../../models/domain';
import { SharedPortalViewService } from './../../shared-portal-view.service';
import { UserWebsite, WebsiteModel } from './../../../portal.model';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DomainItem } from 'src/app/models/domain';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-add-portal-custom-domain',
  templateUrl: './add-portal-custom-domain.component.html',
  styleUrls: ['./add-portal-custom-domain.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AddPortalCustomDomainComponent implements OnInit {
  portalAddCustomDomainForm = new FormGroup({
    domain: new FormControl<DomainItem>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
  });
  portalSelectDomain: DomainItem[];

  loaded: boolean = false;
  customDomain: DomainItem;
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() website: WebsiteModel

  // offerSettingsForm = new FormGroup({
  //   isDefault: new FormControl('', {
  //     updateOn: 'change',
  //   }),
  //   parentPortal: new FormControl('', { updateOn: 'change' }),
  //   iConfirm: new FormControl('', { updateOn: 'change' }),
  // });

  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    private modalService: NgbModal,
    private route: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    // await this.initializePopup();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  compareWith(item, selected) {
    return +item.id === +selected.id;
  }

  async initializePopup() {
    this.loaded = false;
    let domainSelectList =
      await this.sharedPortalViewService.getUserDomainsExcludePortal();
    this.portalSelectDomain = JSON.parse(JSON.stringify(domainSelectList));

    this.loaded = true;
    // this.subdomain = JSON.parse(JSON.stringify(this.portal.domains.subdomain));
  }
  async afterOpenModal() {
    await this.initializePopup();
  }

  onChangeDomain(value: any) {
    this.customDomain = value;
  }

  async updateSettings() {
    this.portalAddCustomDomainForm.markAllAsTouched();
    if (!!this.portalAddCustomDomainForm.valid) {
      if (!!this.customDomain?.portalInfo) {
        if (this.customDomain.portalInfo.siteId == this.website.id) {
          // alert('Already Linked With This Portal');
          this.toastr.error('This Domain is already linked with this portal');
          this.closeModal();
          return false;
        } else if (this.customDomain.portalInfo.siteId != this.website.id) {
          this.onDomainIsAlreadyUsed(this.customDomain, this.website);
          return false;
        }
      }

      await this.sharedPortalViewService.linkPortalWithCustomDomain(
        this.website,
        this.customDomain
      );
      this.closeModal();
    }
  }

  onDomainIsAlreadyUsed(domain: DomainItem, website: WebsiteModel) {
    Swal.fire({
      title: `This Domain is already linked with ${domain.portalInfo.portalName}`,
      html: `The Domain <b>${domain.info.name}.${domain.info.tld}</b> is already used by Portal <b>${domain.portalInfo.portalName}</b>,
      if you confirm the domain will redirect to Portal <b>${website.label}</b> instead of it's current portal
      `,
      width: '50%',
      icon: 'warning',
      confirmButtonText: "I'm Sure",
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
    }).then(async (res: SweetAlertResult) => {
      if (res.isConfirmed) {
        await this.sharedPortalViewService.linkPortalWithCustomDomain(
          this.website,
          this.customDomain
        );
        this.closeModal();
      } else {
        // this.closeModal();
      }
    });
  }

  onChangeDefault(value: any) {
    // if (
    //   this.offerSettings.parentPortal.siteId !=
    //   this.sharedOfferService.offer?.parentPortal?.siteId
    // ) {
    //   if (!!value) {
    //     this.confirmationRequired = true;
    //   } else {
    //     this.confirmationRequired = false;
    //   }
    // } else {
    //   if (!!this.sharedOfferService.offer.isDefault) {
    //     this.confirmationRequired = false;
    //   } else {
    //     if (!!value) {
    //       this.confirmationRequired = true;
    //     } else {
    //       this.confirmationRequired = false;
    //     }
    //   }
    // }
  }

  clearForm() {
    this.customDomain = null;
    this.portalAddCustomDomainForm.controls.domain.setValue(null);
  }
  async onOpenModal() {
    this.clearForm();
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
