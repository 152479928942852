import { InstructorsUpdateDto } from './../../../../../../components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-instructors-card/course-instructors-edit-modal/dto/instructors-edit.dto';
import { CategoryUpdateDto } from './../../../../../../components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-category-card/course-category-edit-modal/dto/category-edit.dto';

// actions/products.actions.ts
import { createAction, createActionGroup, props } from '@ngrx/store';

import { fail } from 'assert';
import { CourseDetailsModelBase } from '../../../../../../components/user-dashboard/product/models/course';

// Current Course Details
export const CurrentCourseDetailsActions = createActionGroup({
  source: 'Current Course Details',
  events: {
    load: props<{ productId: number; errorHandler?: any }>(),
    loadSuccess: props<{ details: any }>(),
    loadFailure: props<{ error: any }>(),
    update: props<{
      productId: number;
      payload: Partial<CourseDetailsModelBase>;
      errorHandler?: any;
    }>(),
    updateSuccess: props<{ details: Partial<CourseDetailsModelBase> }>(),
    updateFailure: props<{ error: any }>(),
  },
});

export const CurrentCourseCategoryActions = createActionGroup({
  source: 'Current Course Category',
  events: {
    update: props<{
      productId: number;
      payload: CategoryUpdateDto;
      errorHandler?: any;
    }>(),
    updateSuccess: props<{ details: Partial<CourseDetailsModelBase> }>(),
    updateFailure: props<{ error: any }>(),
  },
});

export const CurrentCourseInstructorsActions = createActionGroup({
  source: ' Update Current Course Instructors',
  events: {
    update: props<{
      productId: number;
      payload: InstructorsUpdateDto;
      errorHandler?: any;
    }>(),
    updateSuccess: props<{ details: Partial<CourseDetailsModelBase> }>(),
    updateFailure: props<{ error: any }>(),
  },
});







export const clearCurrentCourse = createAction(
  '[Course] Clear Current Course Data'
);

