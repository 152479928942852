import { TimePipe } from '../../pipes/dateago.pipe';
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-affiliate-dashboard-stats-item',
  templateUrl: './affiliate-dashboard-stats-item.component.html',
  styleUrls: ['./affiliate-dashboard-stats-item.component.css'],
})
export class AffiliateDashboardStatsItemComponent implements OnInit {
  @Input() customTitle: boolean = false;
  @Input() title: string;
  @Input() type: 'percent' | 'number' | 'currency' | 'seconds' = 'number';
  @Input() align: 'left' | 'right' | 'center' = 'left';
  @Input() dashNull: boolean = false;
  @Input() size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h1';
  @Input() color:
    | 'danger'
    | 'primary'
    | 'warning'
    | 'success'
    | 'muted'
    | 'black' = null;
  _count: number;
  secondsFormat: { count: number; label: string };
  loading: boolean = true;
  constructor() {}

  @Input() set count(value: number) {
    if (!!value || value == 0) {
      this.loading = false;
    } else {
      this.loading = true;
    }
    this._count = value;
    if (this.type == 'seconds') {
      this.secondsFormat = this.transformIntoAgoFormat(this._count * 1000);
    }
  }

  ngOnInit(): void {}

  transformIntoAgoFormat(value: number) {
    const timePipe = new TimePipe();
    let timeAgo: string = timePipe.transform(value);
    let label = '';
    let split = timeAgo?.toString()?.split(' ');
    if (!+split[0]) {
      return { count: null, label: timeAgo };
    } else {
      split.forEach((item) => {
        if (!+item) {
          label = label + ' ' + item;
        }
      });
      return { count: +split[0], label: label };
    }
  }
}
