const ICONS = {
    category_name: 'Ecommerce',
    icons_list: [
      "basket-ok",
      "cheque",
      "handcart",
      "shop",
      "tag",
      "purchase",
      "discount",
      "package",
      "percentage",
      "barcode",
      "lots-shopping",
      "basket"



    ],

};


export default ICONS
