const ICONS = {
    category_name: 'Education',
    icons_list: [
      "brifecase-timer",
      "briefcase",
      "clipboard",
      "bookmark-2",
      "note",
      "note-2",
      "book-open",
      "book",
      "teacher",
      "award",
      "brifecase-tick",
      "brifecase-cros",
      "bookmark",



    ]
};


export default ICONS
