import { OfferItem } from './../../../../components/user-dashboard/offer/models/offer';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { disableOutAnimation, listAnimation } from 'src/app/shared/animations/animations';
import { LocalPaginationConfig } from 'src/app/models/owner-dashboard';
import { Store } from '@ngrx/store';

import { ProductItem } from 'src/app/components/user-dashboard/product/models/product';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    listAnimation,
    disableOutAnimation
  ],
})
export class OffersListComponent implements OnInit {
  @Input() parentProduct: ProductItem;
  @Input() backText: string = 'Offers';


  @Input() offersList: OfferItem[] = [];
  @Output() offersListChange = new EventEmitter<OfferItem[]>();

  @Output() onSelectItem = new EventEmitter<OfferItem>();

  @Input() disableOnClickNavigation: boolean = false;

  @Input() loaded: boolean = false;
  loadingSkeletonItems: any[] = Array.from({ length: 10 }, (_, index) => false);
  // Pagination
  paginationConfig: LocalPaginationConfig = new LocalPaginationConfig({
    currentPage : 1,
    startIndex : 0,
    numberInPage : 8,
    endIndex : 8,
  });

  @Input() disableDefaultRelatedOptions: boolean = false;


  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdAt',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
    { displayName: 'A-Z Title', column: 'title', order: 'asc' },
    { displayName: 'Z-A Title', column: 'title', order: 'dsc' },
  ];

  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    filters: []
  };
  filterTypes: FilteringTypeModel[] = [
    { index: 0, displayName: 'All', filters: [] },
    {
      index: 1,
      displayName: 'Published',
      filters: [{ column: 'status', value: 'published' }],
    },
    { index: 2, displayName: 'Draft', filters: [{ column: 'status', value: 'draft' }] },
  ];

  selectedItem: OfferItem;
  constructor(
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public sharedOfferService: SharedOfferService,
    private router: Router,
    private store: Store
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  onClickItem(offer: OfferItem) {
    this.onSelectItem.emit(offer);
    this.selectedItem = offer;
  }

  onSetOfferAsDefault(offer: OfferItem) {
    this.offersList.forEach((item)=> {
      if(item.id == offer.id) {
        item.isDefault = true;
      } else {
        item.isDefault = false;
      }
    })
  }

  onDeleteDone(id: number) {
    this.offersList = this.offersList.filter(offer => offer.id != id);
    this.offersListChange.emit(this.offersList);
    // this.store.dispatch(deleteOfferFromProduct({productId:this.parentProduct.id , offerId:id }))
  }


  onCloneDone(offer: OfferItem) {
    console.log(offer);
    this.offersList = [offer, ...this.offersList];
    this.offersListChange.emit(this.offersList);
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  resetPagination() {
    this.paginationConfig.startIndex = 0;
    this.paginationConfig.endIndex = this.paginationConfig.numberInPage;
    this.paginationConfig.currentPage = 1;
  }

  filterTypeChange(filterObject: FilteringTypeModel) {
    this.filterObject = filterObject;
    this.resetPagination();
  }

  deleteOffer(offer: OfferItem) {
    this.sharedOfferService.deleteOfferAlert(offer);
  }

  cloneOffer(offer: OfferItem) {
    this.sharedOfferService.cloneOfferAlert(offer);
  }

  viewProduct(id: number) {
    this.router.navigate(['dashboard', 'product', id, 'view']);
  }

  async editOffer(id: number) {
    this.sharedOwnerDashboardService.setBackRoute(this.backText);
    await this.router.navigate(['dashboard', 'offer', id, 'edit']);
  }

  async previewOffer(offer: OfferItem) {
    window.open(offer.previewLink, '_blank');
  }

  goToTutorials() {
    this.router.navigate(['/dashboard/tutorials']);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}


}
