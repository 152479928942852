// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { RefundRequestsActions, updateRefundRequest } from './refunds.actions';
import { RefundItem } from '../../../../models/owner-dashboard';
import { forgetSession } from '../user/auth/auth.actions';


export interface RefundRequestsState extends EntityState<RefundItem> {
  loaded: boolean;
  error: any;
}

export const refundRequestsAdapter = createEntityAdapter<RefundItem>();


const initialState: RefundRequestsState = refundRequestsAdapter.getInitialState({
  loaded: false,
  error: null,
});

export const refundRequestsReducer = createReducer(
  initialState,
  on(RefundRequestsActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(RefundRequestsActions.loadSuccess, (state, { refundRequests }) => {
    return refundRequestsAdapter.setAll(refundRequests, { ...state, loaded: true });
  }),
  on(RefundRequestsActions.loadFailure, (state, { error }) => {
    if (typeof error !== 'string') {
      return state;
    }

    return { ...state, error, loaded: false };
  }),
  // Handle other actions and state changes here
  on(updateRefundRequest, (state, { itemId, item}) => {
    return refundRequestsAdapter.updateOne(
      {
        id: itemId,
        changes: { ...item },
      },
      state
    );
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(forgetSession, (state) => initialState)
);
