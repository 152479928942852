// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.modal-dialog {
  margin: 9.75rem auto !important;
}



a.video {
  cursor: pointer;
}
a {
  outline: none;
}
/* a.video:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .15s;
} */
figure {
  display: block;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 0 0 0;
  border: 1px solid #efefef;
}
figure img {
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 0.8;
  transform: scale(1);
  transition: all .20s;
}
/* figure figcaption {
  position: absolute;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  width: 100%;
  background: rgb(0 0 0 / 67%);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem;
  transform: translateY(0);
  opacity: 1;
  transition: all .20s;
} */
a.video:hover figure img {
  opacity: 1;
  transform: scale(1.4);
}
a.video:hover figure figcaption {
  opacity: 0;
  transform: translateY(50%);
}
/* a.video:hover:before {
  background-color: rgba(0, 0, 0, 0.3);
} */


`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/video-modal/video-modal.component.css"],"names":[],"mappings":";;AAEA;EACE,+BAA+B;AACjC;;;;AAIA;EACE,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;;;;;;;;;;GAUG;AACH;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;EAC5B,kCAAkC;EAClC,sBAAsB;EACtB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB;AACA;;;;;;;;;;;;;GAaG;AACH;EACE,UAAU;EACV,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,0BAA0B;AAC5B;AACA;;GAEG","sourcesContent":["\n\n.modal-dialog {\n  margin: 9.75rem auto !important;\n}\n\n\n\na.video {\n  cursor: pointer;\n}\na {\n  outline: none;\n}\n/* a.video:before {\n  content: '';\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0);\n  transition: background-color .15s;\n} */\nfigure {\n  display: block;\n  position: relative;\n  overflow: hidden;\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: cover;\n  margin: 0 0 0 0;\n  border: 1px solid #efefef;\n}\nfigure img {\n  display: block;\n  max-width: 100%;\n  height: auto;\n  opacity: 0.8;\n  transform: scale(1);\n  transition: all .20s;\n}\n/* figure figcaption {\n  position: absolute;\n  bottom: 0;\n  border-radius: 20px 20px 0 0;\n  width: 100%;\n  background: rgb(0 0 0 / 67%);\n  color: #fff;\n  font-size: 1.4rem;\n  font-weight: 600;\n  padding: 1rem;\n  transform: translateY(0);\n  opacity: 1;\n  transition: all .20s;\n} */\na.video:hover figure img {\n  opacity: 1;\n  transform: scale(1.4);\n}\na.video:hover figure figcaption {\n  opacity: 0;\n  transform: translateY(50%);\n}\n/* a.video:hover:before {\n  background-color: rgba(0, 0, 0, 0.3);\n} */\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
