import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationsService,
  NotificationUserTypeEnum,
} from '../../../../../services/notifications.service';

import { lastValueFrom } from 'rxjs';
import { AffiliateApi } from '../../../../../services/affiliate.api.service';
import { TimePipe } from 'src/app/shared/pipes/dateago.pipe';
import { AffiliateNotificationElement, AffiliateNotificationTypeEnum, NotificationStatusEnum } from 'src/app/models/affiliates-dashboard';

@Component({
  selector: 'app-affiliate-notification-element',
  templateUrl: './affiliate-notification-element.component.html',
  styleUrls: ['./affiliate-notification-element.component.css'],
})
export class AffiliateNotificationElementComponent implements OnInit {
  affiliateNotificationStatusEnum = NotificationStatusEnum;
  notificationUserTypeEnum = NotificationUserTypeEnum;
  @Input() forToast: boolean = false;
  @Input() firstElement: boolean = false;
  @Input() notificationObject: AffiliateNotificationElement;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public affiliateApi: AffiliateApi
  ) {}

  onClickNotification() {
    this.markNotificationAsRead();
    switch (this.notificationObject.type) {
      case AffiliateNotificationTypeEnum.invitation:
        this.goToNetworkById(this.notificationObject.target.ownerId);
        break;
      case AffiliateNotificationTypeEnum.affiliate_link_add:
        this.goToNetworkById(this.notificationObject.target.ownerId);
        break;
      case AffiliateNotificationTypeEnum.affiliate_link_update:
        this.goToNetworkById(this.notificationObject.target.ownerId);
        break;
      case AffiliateNotificationTypeEnum.affiliate_link_paused:
        this.goToNetworkById(this.notificationObject.target.ownerId);
        break;
      case AffiliateNotificationTypeEnum.affiliate_link_resumed:
        this.goToNetworkById(this.notificationObject.target.ownerId);
        break;
      default:
        break;
    }
  }

  getNotificationTimeAgo(value: number) {
    const timePipe = new TimePipe();
    let timeAgo: string = timePipe.transform(value * 1000);
    return timeAgo;
  }

  async markNotificationAsRead() {
    let request$ = this.affiliateApi.markNotificationAsRead(
      this.notificationObject.id
    );
    let response = await lastValueFrom(request$);
    this.notificationObject.status = NotificationStatusEnum.read;
  }

  goToNetworkById(id: number) {
    this.router.navigate(['affiliate', 'network', id]);
  }

  ngOnInit() {}
}
