import { MediaLibraryState, mediaLibraryAdapter } from './media-library.reducer';

// selectors/products.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';


// Create a feature selector for the 'products' feature
export const selectMediaLibraryState = createFeatureSelector<MediaLibraryState>('mediaLibrary');

// Create a selector to get the products from the state
export const selectMediaLibraryFiles = createSelector(
    selectMediaLibraryState,
    (state) => mediaLibraryAdapter.getSelectors().selectAll(state).sort((a, b) => b.uploadDate - a.uploadDate)
  );


  // Media Library Types
export const selectMediaLibraryTypes = createSelector(
  selectMediaLibraryState,
  (state) => state.mediaLibraryTypes
);


  // Media Library Quota
  export const selectMediaLibraryQuota = createSelector(
    selectMediaLibraryState,
    (state) => state.mediaLibraryQuota
  );

  export const isMediaLibraryQuotaLoaded = createSelector(
    selectMediaLibraryState,
    (state) => state.mediaLibraryQuotaLoaded
  );




export const isMediaLibraryLoaded = createSelector(selectMediaLibraryState, (state) => state.loaded);
export const isMediaLibraryLoadingError = createSelector(selectMediaLibraryState, (state) => state.error);
