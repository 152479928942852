import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { forgetSession } from '../../user/register/register.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { PaymentGatewayModel } from '../../../../../models/portal';
import { websitePaymentGatewaysActions } from './website-payments.actions';
import { generatePaymentMethodsFullModel } from './payment.utils';


export interface WebsitePaymentsState extends EntityState<PaymentGatewayModel> {
  paymentGatewaysLoaded: boolean;
  paymentGatewaysError: any;
}

export const paymentGatewaysAdapter = createEntityAdapter<PaymentGatewayModel>();

const initialState: WebsitePaymentsState = paymentGatewaysAdapter.getInitialState({
  // createPostProcess: { status: 'none', error: null },
  paymentGatewaysLoaded: false,
  paymentGatewaysError: null
});

export const websitePaymentsReducer = createReducer(
  initialState,

  // Posts List
  on(websitePaymentGatewaysActions.load, (state) => ({
    ...state,
    postsLoaded: false,
    postsError: null
  })),
  on(websitePaymentGatewaysActions.loadSuccess, (state, { paymentGateways }) => {
    console.log(paymentGateways);
    let paymentGatewaysResults = generatePaymentMethodsFullModel(paymentGateways);
    console.log(paymentGatewaysResults);
    return paymentGatewaysAdapter.setAll(paymentGatewaysResults, {
      ...state,
      postsLoaded: true
    });

   }
  ),

  on(websitePaymentGatewaysActions.loadFailure, (state, { error }) => ({
    ...state,
    postsError: error
  })),

    on(forgetSession, (state) => initialState)
);
