import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class SharedSfxService {
  bonk: HTMLAudioElement = new Audio('assets/bonk.mp3');
  crumbling: HTMLAudioElement = new Audio('assets/paper-crumbling.mp3');

  playBonk() {
    this.bonk.play();
  }

  playCrumble() {
    this.crumbling.play();
  }
  constructor() {}
}
