import { createReducer, on } from '@ngrx/store';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';
import { SystemProcessLoadingStatus } from '../../../../../../models/global-classes';
import { CurrentCourseReportsActions } from './course-reports.actions';

export interface CourseReportsState {
  currentCourseProgressReport: {
    students: StudentItem[];
    demoStudents: StudentItem[];
    totalProgress: number;
    completedStudentsCount: number;
  };
  currentCourseStudentsLoadProcess: {
    status: SystemProcessLoadingStatus;
    error: any;
    message?: string;
  };
  error: any;
}

const initialState: CourseReportsState = {
  currentCourseProgressReport: {
    students: [],
    demoStudents: [],
    totalProgress: 0,
    completedStudentsCount: 0,
  },
  currentCourseStudentsLoadProcess: { status: 'none', error: null },
  error: null,
};

export const courseReportsReducer = createReducer(
  initialState,
  on(CurrentCourseReportsActions.load, (state) => ({
    ...state,
    currentCourseStudentsLoadProcess: { status: 'loading', error: null },
  })),
  on(CurrentCourseReportsActions.loadSuccess, (state, studentsProgress) => ({
    ...state,
    currentCourseStudentsLoadProcess: { status: 'done', error: null },
    currentCourseProgressReport: studentsProgress,
  })),
  on(CurrentCourseReportsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    currentCourseStudentsLoadProcess: {
      status: 'error',
      error: error.message,
    },
  }))
);
