import { CreateAnnouncementRequest } from './../../../../../../models/owner-dashboard';
import { Component, Input, OnInit } from '@angular/core';
import {
  MessengerThread,
  AnnouncementObject,
} from '../../../../../../models/owner-dashboard';

@Component({
  selector: 'app-announcement-recipients-images',
  templateUrl: './announcement-recipients-images.component.html',
  styleUrls: ['./announcement-recipients-images.component.css'],
})
export class AnnouncementRecipientsImagesComponent implements OnInit {
  @Input() announcementItem: AnnouncementObject | CreateAnnouncementRequest;
  @Input() enableTooltip: boolean = true;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'sm';
  @Input() maxNumber: number = 5;
  constructor() {}

  ngOnInit(): void {}
}
