// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.toast-custom-notification {
  transition: all 150ms ease-in-out !important;
  text-align: left;
  /* padding: 1em 2em !important; */
  border-left: 7px solid rgb(0 123 255);
  margin-top: 1em;
  background-color: #fff !important;
  border-radius: 14px;
  max-width: 350px;
  width: 350px;
  top: 0px;
  position: relative;
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.8;
}

  .toast-custom-notification:hover {
      background-color: #fafafa !important;
      box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);;
      opacity: 1;
  }
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/messenger/messenger-thread-item/messenger-thread-item.component.css"],"names":[],"mappings":";;AAEA;EACE,4CAA4C;EAC5C,gBAAgB;EAChB,iCAAiC;EACjC,qCAAqC;EACrC,eAAe;EACf,iCAAiC;EACjC,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,QAAQ;EACR,kBAAkB;EAClB,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;EAEE;MACI,oCAAoC;MACpC,wCAAwC;MACxC,UAAU;EACd","sourcesContent":["\n\n.toast-custom-notification {\n  transition: all 150ms ease-in-out !important;\n  text-align: left;\n  /* padding: 1em 2em !important; */\n  border-left: 7px solid rgb(0 123 255);\n  margin-top: 1em;\n  background-color: #fff !important;\n  border-radius: 14px;\n  max-width: 350px;\n  width: 350px;\n  top: 0px;\n  position: relative;\n  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);\n  pointer-events: auto;\n  cursor: pointer;\n  opacity: 0.8;\n}\n\n  .toast-custom-notification:hover {\n      background-color: #fafafa !important;\n      box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);;\n      opacity: 1;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
