import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { of, throwError, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import Swal from 'sweetalert2';
import { PeriodResultModel } from '../../models/owner-dashboard';

@Injectable({ providedIn: 'root' })
export class AffiliateApi {
  private apiUrl;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {
    if ('api' in environment && 'url' in environment['api']) {
      this.apiUrl = environment['api']['url'];
    }
  }

  // Announcements
  getMyAnnouncements(errorHandler: any = undefined) {
    return this.get(`affiliate/announcements`, {}, errorHandler);
  }

  getMyAnnouncementsByNetworkId(
    networkId: number,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/announcements-network/${networkId}`,
      {},
      errorHandler
    );
  }

  getAnnouncementById(id: number, errorHandler: any = undefined) {
    return this.get(`affiliate/announcements/${id}`, {}, errorHandler);
  }

  getMyOwners(errorHandler: any = undefined) {
    return this.get(`affiliate/owners`, {}, errorHandler);
  }

  acceptInvitationById(networkId: number, errorHandler: any = undefined) {
    return this.post(
      `affiliate/accept-invitation/${networkId}`,
      {},
      errorHandler
    );
  }

  rejectInvitationById(
    networkId: number,
    prevent: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.post(
      `affiliate/reject-invitation/${networkId}?block=${prevent}`,
      errorHandler
    );
  }

  markNotificationAsRead(
    notificationId: number,
    errorHandler: any = undefined
  ) {
    return this.put(
      `affiliate/notifications`,
      {
        notificationIds: [notificationId],
      },
      errorHandler
    );
  }

  getAffiliateNotifications(
    latest: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/notifications?latest=${latest}`,
      {},
      errorHandler
    );
  }

  getOwnerById(id: number, errorHandler: any = undefined) {
    return this.get(`affiliate/owners/${id}`, {}, errorHandler);
  }

  getOwnerOverviewData(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/commissions-graph/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getOwnerPreferences(id: number, errorHandler: any = undefined) {
    return this.get(`affiliate/payouts-settings/${id}`, {}, errorHandler);
  }

  setOwnerPaypalPreferences(
    id: number,
    data: any,
    errorHandler: any = undefined
  ) {
    return this.post(`affiliate/paypal-settings/${id}`, data, errorHandler);
  }

  setOwnerBtcpayPreferences(
    id: number,
    data: any,
    errorHandler: any = undefined
  ) {
    return this.post(`affiliate/btc-settings/${id}`, data, errorHandler);
  }

  setOwnerWiretransferPreferences(
    id: number,
    data: any,
    errorHandler: any = undefined
  ) {
    return this.post(`affiliate/bank-settings/${id}`, data, errorHandler);
  }

  checkSwiftCodeInformation(swift: string, errorHandler: any = undefined) {
    return this.get(
      `affiliate/bank-details-swiftcode/?swift=${swift}`,
      errorHandler
    );
  }

  // Dashboard APIs
  getDashboardEarnings(
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/network-earnings` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getDashboardGraph(
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/dashboard-graph` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getStatisticsComparisionData(
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/statistics` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getEarningsByNetworkId(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `affiliate/network-earnings/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getDashboardTrackingTotals(errorHandler: any = undefined) {
    return this.get(`affiliate/dashboard-totals`, {}, errorHandler);
  }

  getDashboardTrackingTotalsByNetwork(
    id: number,
    errorHandler: any = undefined
  ) {
    return this.get(`affiliate/dashboard-totals/${id}`, {}, errorHandler);
  }

  // OpenInvitation Apis
  getInvitationHashData(hash: string, errorHandler: any = undefined) {
    return this.get(`users/invitation-hash/${hash}`, {}, errorHandler);
  }

  private post(path: string, params: any, errorHandler: any = undefined) {
    return this.call('post', path, params, errorHandler);
  }

  private patch(path: string, params: any, errorHandler: any = undefined) {
    return this.call('patch', path, params, errorHandler);
  }

  private get(path: string, params: any, errorHandler: any = undefined) {
    return this.call('get', path, params, errorHandler);
  }

  private getScreenshot(
    path: string,
    params: any,
    errorHandler: any = undefined
  ) {
    return this.callScreenshot('get', path, params, errorHandler);
  }

  private put(path: string, params: any, errorHandler: any = undefined) {
    return this.call('put', path, params, errorHandler);
  }

  private delete(path: string, params: any, errorHandler: any = undefined) {
    return this.call('delete', path, params, errorHandler);
  }

  private download(
    path: string,
    params: any = undefined,
    errorHandler: any = undefined
  ) {
    if (!errorHandler) errorHandler = this.handleError;
    return this.http
      .get<any>(`${this.apiUrl}/${path}`, {
        params,
        headers: this.headers,
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(errorHandler));
  }

  public upload(path: string, formData, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;

    return this.http
      .request<any>('post', `${this.apiUrl}/${path}`, {
        body: formData,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  call(method, path: string, params: any, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);
    return this.http
      .request<any>(method, `${this.apiUrl}/${path}`, {
        body: params,
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  freeCall(method, url: string, params: any, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);
    return this.http
      .request<any>(method, `${url}`, {
        body: params,
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('API SERVICE ERROR: ', error);
    Swal.fire({
      title: 'Ooops, Something went wrong!',
      html: `
      learmo has encountered an error. If this problem persists,
contact us at help@influencerportal.com
      `,
      confirmButtonText: 'Reload',
      allowOutsideClick: true,
      allowEscapeKey: true,

      customClass: {
        confirmButton: 'btn-success btn',
      },
      icon: 'error',
    }).then((result) => {
      if (result.isConfirmed) {
        document.location.reload();
      }
    });
    return throwError(
      'We were unable to connect to the Servers, please check your connection and try again.'
    );
  }

  callScreenshot(
    method,
    path: string,
    params: any,
    errorHandler: any = undefined
  ) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);

    return this.http
      .request<any>(
        method,
        `${this.apiUrl}/${path}`,
        {
          body: params,
          headers: this.headers,
        }
      )
      .pipe(
        map((resp) => {
          if (resp?.error) {
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }
}
