const ICONS = {
    category_name: 'Security',
    icons_list: [
      "shield-search",
      "password-check",
      "shield-tick",
      "lock",
      "key",
      "shield",
      "shield-cross",
      "key-square",
      "eye-slash",
      "security-check",
      "lock-3",
      "scan-barcode",
      "lock-2",
      "eye",
      "shield-slash",
      "security-user"
    ],

};


export default ICONS
