import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bundle, Plan } from 'src/app/models/plans';
import { BundleRenewalType } from 'src/app/models/subscription';

@Component({
  selector: 'app-settings-plan-item',
  templateUrl: './settings-plan-item.component.html',
  styleUrls: ['./settings-plan-item.component.css']
})
export class SettingsPlanItemComponent implements OnInit, AfterViewInit {
  item: Bundle;
  
  @Input() parentPlan: Plan;
  currentPlansType: BundleRenewalType = BundleRenewalType.annual;
  @Input('currentPlansType') set setCurrentPlansType(type: BundleRenewalType) { 
    this.currentPlansType = type;
    this.item = this.returnTheCorrectView(type)
  } 
  @Input() isCurrentPlan: boolean = false;
  @Input() isCurrentBundle: boolean = false;
  @Input() isActive: boolean = false;

  @Output() itemClick: EventEmitter<Bundle> = new EventEmitter<Bundle>();
  constructor() { }

  ngAfterViewInit(): void {
    if (this.isCurrentPlan) {
      this.item = this.returnTheCorrectView(this.currentPlansType);
    }
    // if (this.currentViewType == 'annual') {
    //   this.item = this.parentPlan.annual;
    // }
  }

  returnTheCorrectView(currentPlansType: BundleRenewalType = BundleRenewalType.annual) {
    if (!!this.parentPlan.annual && !!this.parentPlan.monthly) {
      if (currentPlansType == BundleRenewalType.annual) {
        return this.parentPlan.annual;
      } else {
        return this.parentPlan.monthly;
      }  
    }
    else {
      if (!!this.parentPlan.annual) {
        return this.parentPlan.annual;
      }
      else {
        return this.parentPlan.monthly;
      }
    }
    
  }
  ngOnInit() {
  }


  onClick() {
    this.itemClick.emit(this.item);
  }
}
