import {
  FunnelTemplateItem,
  TemplateItem,
} from './../../../../components/user-dashboard/portal/portal.model';

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-funnel-item',
  templateUrl: './funnel-item.component.html',
  styleUrls: ['./funnel-item.component.css'],
})
export class FunnelItemComponent implements OnInit {
  @Input() funnel: FunnelTemplateItem;
  @Input() index: number;
  @Input() selected: boolean = false;
  @Output() onClickUse: EventEmitter<FunnelTemplateItem> =
    new EventEmitter<FunnelTemplateItem>();
  @Output() onClickUsePopup: EventEmitter<FunnelTemplateItem> =
    new EventEmitter<FunnelTemplateItem>();
  @Output() onClickUnUse: EventEmitter<FunnelTemplateItem> =
    new EventEmitter<FunnelTemplateItem>();
  constructor() {}

  ngOnInit(): void {}

  // useActionPopup(funnel: FunnelItem) {
  //   this.onClickUse.emit(funnel);
  // }

  useAction(funnel: FunnelTemplateItem) {
    this.onClickUse.emit(funnel);
  }

  unUseAction(funnel: FunnelTemplateItem) {
    this.onClickUnUse.emit(funnel);
  }
}
