import { CreateAnnouncementRequest } from './../../../../../models/owner-dashboard';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { AnnouncementObject } from '../../../../../models/owner-dashboard';
import {
  AnnouncementAffiliateReadStatusEnum,
  MessengerThread,
  MessengerUserRoleEnum,
} from '../../../../../models/owner-dashboard';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}
@Component({
  selector: 'app-owner-announcement-users-list-popup',
  templateUrl: './owner-announcement-users-list-popup.component.html',
  styleUrls: ['./owner-announcement-users-list-popup.component.css'],
})
export class OwnerAnnouncementUsersListPopupComponent implements OnInit {
  announcementAffiliateReadStatusEnum = AnnouncementAffiliateReadStatusEnum;
  @Input() announcement: AnnouncementObject | CreateAnnouncementRequest;

  // Report Toaster
  reportToaster: ActiveToast<any>;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {}

  onOpenModal() {}

  closeModal() {
    this.modalRef.dismiss();
  }

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
    });
  }
}
