// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  transition: all 0.5s ease-in-out !important;
}

/* .item:hover {
  --bs-bg-rgb-color: var(--bs-light-rgb);
  background-color: rgba(var(--bs-bg-rgb-color), 0.50) !important;
} */

.more-info {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out !important;
}
.item:hover .more-info {
  opacity: 1;
  transform: translateY(0px);
  max-height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/pages-list/page-list-item/page-list-item.component.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;;;GAGG;;AAEH;EACE,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,4BAA4B;EAC5B,2CAA2C;AAC7C;AACA;EACE,UAAU;EACV,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[".item {\n  transition: all 0.5s ease-in-out !important;\n}\n\n/* .item:hover {\n  --bs-bg-rgb-color: var(--bs-light-rgb);\n  background-color: rgba(var(--bs-bg-rgb-color), 0.50) !important;\n} */\n\n.more-info {\n  max-height: 0;\n  overflow: hidden;\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: all 0.5s ease-in-out !important;\n}\n.item:hover .more-info {\n  opacity: 1;\n  transform: translateY(0px);\n  max-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
