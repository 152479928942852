import { Router } from '@angular/router';
import { CreatePortalSteps } from './../onboarding/createPortal';
import { ShepherdService } from 'angular-shepherd';
import { Injectable, ViewChild } from '@angular/core';
import Shepherd from 'shepherd.js';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { OffsetType } from '../layout/owner-dashboard/pointer-guide/pointer-guide.component';

@Injectable({
  providedIn: 'root',
})
export class PointerGuidanceService {
  activateGuidance: boolean = false;
  myPointer: HTMLElement;
  moveState: 'start' | 'end' | 'none' = 'none';

  pointerType: 'pointer' | 'checkmark-circle' = 'pointer';
  constructor(
    private shepherdService: ShepherdService,
    private router: Router
  ) {}

  navigateToElementByID(selector: string = '#ip-create-funnel-portal-drop') {
    this.activateGuidance = true;
    this.moveState = 'start';
    setTimeout(() => {
      let offset: OffsetType = this.getElementCoordinates(selector);
      this.movePointerWithOffset(offset);
      // this.myPointer.style.top = `${offset.top}px`;
      
      setTimeout(() => {
        this.moveState = 'end';
      }, 1000);
    }, 1000);
  }

  movePointerWithOffset(offset: OffsetType) {
    this.myPointer.style.transform = `translate(${offset.left}px, ${offset.top}px)`;
  }
  getElementCoordinates(selector: string) {
    let element = document.querySelector(selector);
    let bodyRect = document.body.getBoundingClientRect();
    let elemRect = element.getBoundingClientRect();
    let offset = {
      top: elemRect.top - bodyRect.top,
      left: elemRect.left - bodyRect.left,
    };

    return offset;
  }
}
