// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom button styles */
.custom-quill-editor .custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Adjust the padding as needed */
  cursor: pointer;
  color: #555; /* Adjust the text color as needed */
}

.learmo-editor-modal .modal-content {
  padding: 2em
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/learmo-editor/learmo-editor.component.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe,EAAE,iCAAiC;EAClD,eAAe;EACf,WAAW,EAAE,oCAAoC;AACnD;;AAEA;EACE;AACF","sourcesContent":["/* Custom button styles */\n.custom-quill-editor .custom-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 10px; /* Adjust the padding as needed */\n  cursor: pointer;\n  color: #555; /* Adjust the text color as needed */\n}\n\n.learmo-editor-modal .modal-content {\n  padding: 2em\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
