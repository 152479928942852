const ICONS = {
    category_name: 'Devices',
    icons_list: [
      "monitor-mobile",
      "devices",
      "keyboard",
      "devices-2",
      "bluetooth",
      "wifi",
      "airpod",
      "simcard-2",
      "speaker",
      "printer",
      "simcard",
      "router",
      "phone",
      "electronic-clock",
      "external-drive",
      "laptop",
      "tablet",
      "screen",
      "calculator",
      "mouse"
    ]


};


export default ICONS
