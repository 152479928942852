import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { emailRegex } from 'src/app/shared/services/regex';

import { RegisterModel } from 'src/app/models/register';
import { AuthenticationService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';
import { cardAnimation } from 'src/app/shared/animations/animations';

export enum RegisterErrorCode {
  username_exist = 602,
  email_exist = 609,
}

export enum CurrentWaitListStep {
  capture = 'capture',
  done = 'done'
}
@Component({
  selector: 'app-wait-list-form',
  templateUrl: './wait-list-form.component.html',
  styleUrls: ['./wait-list-form.component.css'],
  animations: [cardAnimation]
})
export class WaitListFormComponent implements OnInit {
  currentWaitListStep = CurrentWaitListStep;
  currentStep: CurrentWaitListStep = CurrentWaitListStep.capture;
  
  @Output() onLoadingStart = new EventEmitter<any>();
  @Output() onLoadingDone = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  passwordInputType: 'password' | 'text' = 'password';

  validationObj: {isEmailExist: boolean, isUsernameExist: boolean} = {
    isEmailExist : false,
    isUsernameExist : false,
  }

  signupForm = new FormGroup({
    leadGroup: new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(emailRegex),
      ]),
    }),
    // registrationGroup: new FormGroup({
    //   username: new FormControl('', [
    //     Validators.required,
    //   ]),
    //   password: new FormControl('', [
    //     Validators.required,
    //     Validators.minLength(8),
    //   ]),
    //   toc: new FormControl(false, [this.requiredTrueValidator]),
    // })
    
  });

  constructor(private authenticationService: AuthenticationService) {

  }

  ngOnInit() {
    let emailControl = this.signupForm.get('leadGroup').get('email');
    emailControl.valueChanges.subscribe((value)=> {
      if (emailControl.hasError('isExist')) {
        emailControl.hasError = undefined;
      }
    })

    let usernameControl = this.signupForm.get('registrationGroup').get('username');
    usernameControl.valueChanges.subscribe((value)=> {
      if (usernameControl.hasError('isExist')) {
        usernameControl.hasError = undefined;
      }
    })
    // this.signupForm.controls.password.valueChanges.subscribe((value)=> {
    // });
  }

  isCurrentStep(step: CurrentWaitListStep): boolean {
    return this.currentStep === step;
  }


  // Custom validator for "I Agree" checkbox
  requiredTrueValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    if (!control?.value) {
      return { requiredTrue: true };
    }
    return null;
  }


  togglePasswordVisibility() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  async captureLead(payload: Partial<RegisterModel>) {
    let request$ = this.authenticationService.captureLead(payload, this.handleCaptureLeadError);
    let response = await lastValueFrom(request$);
    
  }

  handleCaptureLeadError = (error: {
    error: string;
    message: string;
    statusCode: number;
  }) => {
    switch (error?.statusCode) {
      case 609:
        this.validationObj.isEmailExist = true;
        this.signupForm.get('leadGroup').get('email').setErrors({isExist: true})
        break;
      default:
        break;
    }
    this.onSignupError(error);
    
  };
  

  async signUp(registerModel: Partial<RegisterModel>) {
    this.onSignupStart();
    let request = this.authenticationService.register(
      registerModel,
      this.handleRegisterError
    );
    // let response = await lastValueFrom(request);
    // this.onSignupDone(response);
    
    //   this.authenticationService
    //     .register(registerModel, this.handleRegisterError)
    //     .subscribe(async (response) => {
    //       if (this.paymentMethod === PaymentMethodEnum.stripe) {
    //         this.payEmit.emit({
    //           bundleID: this.selectedPlan.plan.id,
    //           paymentProcess: PaymentProcessType.save,
    //         });
    //       } else {
    //         let subscribe: { created: boolean } = await this.paymentService
    //           .subscriptionBtcPay(this.selectedPlan.plan.id)
    //           .toPromise();
    //         if (!!subscribe.created) {
    //           this.fireCreatedAccountAlert();
    //         } else {
    //           this.fireStopLoadingSwalError('Error');
    //         }
    //       }
    //     });
    // } else {
    //   if (!this.isPaymentValid && !!this.registerform.valid) {
    //     this.PaymentErrorMessage(
    //       'Please enter a valid credit card information.'
    //     );
    //   }
    // }
  }

  handleRegisterError = (error: {
    error: string;
    message: string;
    statusCode: number;
  }) => {
    switch (error?.statusCode) {
      case 609:
        this.validationObj.isEmailExist = true;
        this.signupForm.get('leadGroup').get('email').setErrors({isExist: true})
        break;
      case 602:
        this.signupForm.get('registrationGroup').get('username').setErrors({isExist: true})
        break;

      default:
        break;
    }
    this.onSignupError(error);
    
  };

  onSubmit() {
    if (this.isCurrentStep(CurrentWaitListStep.capture)) {
      this.submitCaptureStep();
    }
  }
  
  private async submitCaptureStep() {
    this.signupForm.controls.leadGroup.markAllAsTouched();
  
    if (this.signupForm.controls.leadGroup.valid) {
      const payload: Partial<RegisterModel> = {
        firstname: this.signupForm.value.leadGroup.firstname,
        lastname: this.signupForm.value.leadGroup.lastname,
        email: this.signupForm.get('leadGroup').get('email').value,
      };


      await this.captureLead(payload)
      this.currentStep = CurrentWaitListStep.done;
    }
  }
  
  // private submitRegisterStep() {
  //   this.signupForm.controls.registrationGroup.markAllAsTouched();
  //   this.logFormErrors(this.signupForm);
  
  //   if (this.signupForm.controls.leadGroup.valid && this.signupForm.controls.registrationGroup.valid) {
  //     const payload: Partial<RegisterModel> = {
  //       firstname: this.signupForm.value.leadGroup.firstname,
  //       lastname: this.signupForm.value.leadGroup.lastname,
  //       email: this.signupForm.get('leadGroup').get('email').value,
  //       username: this.signupForm.value.registrationGroup.username,
  //       password: this.signupForm.value.registrationGroup.password,
  //       confirmPassword: this.signupForm.value.registrationGroup.password,
  //     };
  
  //     this.signUp(payload);
  //   }
  // }
  

  logFormErrors(formGroup: FormGroup | FormControl, prefix: string = '') {
    if (formGroup instanceof FormGroup) {
      for (const controlName in formGroup.controls) {
        const control = formGroup.get(controlName);

        if (control instanceof FormGroup || control instanceof FormControl) {
          this.logFormErrors(control, `${prefix}${controlName}.`);
        }
      }
    } else if (formGroup instanceof FormControl && formGroup.errors) {
      
    }
  }

  onSignupStart() {
    this.onLoadingStart.emit();
  }

  onSignupDone(resp) {
    // this.authenticationService.onRegisterResponse(resp)
    setTimeout(() => {
      this.onLoadingDone.emit();
    }, 500);

  }

  onSignupError(error: { error: string; message: string; statusCode: number }) {
    this.onError.emit();
  }
}
