// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 900px) {
  .wizard-image {
    display: none;
  }

  .summary-container {
    width: 100%;
  }

}

.summary-container {
  max-width: 500px;
}
/* @media screen and (min-width:900px) and (max-width: 1024px) {
  .newflexcol,.flexcol{width: 46%;}

} */


.wizard-image img {

  /* margin-right: -200%; */
  max-width: 400px; height: auto
}


.slideInAnimation {
  position: absolute;
  z-index: -1;
  right: -50%;
  top: 50%;
  transform: translate(50%,-50%) scaleX(-1);
  animation: slidein 4s forwards;
}

.slideOutAnimation {
  animation: slideout 6s forwards;
}


@keyframes slidein {
  100% { right: 250px; }
}


@keyframes slideout {
  0% { right: 250px; }
  100% { right: 50%; }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/wizard-summary/wizard-summary.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;AAEF;;AAEA;EACE,gBAAgB;AAClB;AACA;;;GAGG;;;AAGH;;EAEE,yBAAyB;EACzB,gBAAgB,EAAE;AACpB;;;AAGA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,QAAQ;EACR,yCAAyC;EAEzC,8BAA8B;AAChC;;AAEA;EAEE,+BAA+B;AACjC;;;AAGA;EACE,OAAO,YAAY,EAAE;AACvB;;;AAGA;EACE,KAAK,YAAY,EAAE;EACnB,OAAO,UAAU,EAAE;AACrB","sourcesContent":["@media screen and (max-width: 900px) {\n  .wizard-image {\n    display: none;\n  }\n\n  .summary-container {\n    width: 100%;\n  }\n\n}\n\n.summary-container {\n  max-width: 500px;\n}\n/* @media screen and (min-width:900px) and (max-width: 1024px) {\n  .newflexcol,.flexcol{width: 46%;}\n\n} */\n\n\n.wizard-image img {\n\n  /* margin-right: -200%; */\n  max-width: 400px; height: auto\n}\n\n\n.slideInAnimation {\n  position: absolute;\n  z-index: -1;\n  right: -50%;\n  top: 50%;\n  transform: translate(50%,-50%) scaleX(-1);\n  -webkit-animation: slidein 4s forwards;\n  animation: slidein 4s forwards;\n}\n\n.slideOutAnimation {\n  -webkit-animation: slideout 6s forwards;\n  animation: slideout 6s forwards;\n}\n\n\n@keyframes slidein {\n  100% { right: 250px; }\n}\n\n\n@keyframes slideout {\n  0% { right: 250px; }\n  100% { right: 50%; }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
