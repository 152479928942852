import { SharedOwnerDashboardService } from './../../../../../shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { isAccountSetupStepsDone, selectAccountSetupNextDoStep, selectAccountSetupSteps } from 'src/app/shared/store/_features/account-setup/account-setup.selector';
import { AccountSetupStep, AccountSetupStepStatusEnum } from './setup-steps.component';
import { PortalSocketService } from 'src/app/shared/services/portal-socket.service';
import { loadAccountSetupStepsActions, stepUpdate } from 'src/app/shared/store/_features/account-setup/account-setup.actions';
import Swal from 'sweetalert2';
import { NgxTippyInstance } from 'ngx-tippy-wrapper';
import { colorSet } from 'src/app/models/global-classes';

@Injectable({
  providedIn: 'root'
})
export class AccountSetupService {
  sideWidgetColor: colorSet = 'primary';
  tippyMessage: string = "Get Help";
  tippyInstance: NgxTippyInstance;


  widgetSteps: AccountSetupStep[];
  subscriptions: Subscription[] = [];

  stepDoneSound: HTMLAudioElement = new Audio('assets/notification.mp3');

  nextStep: AccountSetupStep;
  isStepsDone: boolean = false;
  constructor(private store: Store, private portalSocketService: PortalSocketService, private sharedOwnerDashboardService: SharedOwnerDashboardService) {
    this.store.dispatch(loadAccountSetupStepsActions.load());

    this.subscriptions.push(

      this.store.select(selectAccountSetupSteps).subscribe(value=> {
      this.widgetSteps = value;
    })
    )

    this.subscriptions.push(

      this.store.select(selectAccountSetupNextDoStep).subscribe(value=> {
      this.nextStep = value;
    })
    )

    this.startProgressListening();
    this.portalSocketService.onSocketSetupDone.subscribe((value) => {
      this.startProgressListening();
    });


  }

  showMessage(content: string, openTimeout: number = 0 ,closeTimeout: number = 1500, color: colorSet = 'primary') {
    this.tippyMessage = content;
    setTimeout(() => {

      this.tippyInstance.show();
      this.sideWidgetColor = color;
      if (!!closeTimeout) {
        setTimeout(() => {
          this.tippyInstance.hide();
          this.sideWidgetColor = 'primary';
        }, closeTimeout);
      }
    }, openTimeout);
  }

  playStepDoneSound() {
    this.stepDoneSound.play();
  }

  startProgressListening() {
    this.subscriptions.push(
    this.portalSocketService.accountSetupListener.subscribe(
      (step: AccountSetupStep) => {
        let stepTemp: AccountSetupStep = JSON.parse(JSON.stringify(this.widgetSteps.find(a=> a.id == step.id)));
        this.store.dispatch(stepUpdate({step}));
        // this.handleNewOwnerNotifications(notification)
        if (stepTemp.status == AccountSetupStepStatusEnum.DONE && step.status == AccountSetupStepStatusEnum.NONE) {
          this.showMessage(`You've stepped back; feel free to reach out to me if assistance is required.`, 0, 4000, 'danger')
        } else {
          this.playStepDoneSound();
          this.triggerStepDoneAction(step);
        }
      }
    )
    );
  }


  triggerStepDoneAction(step: AccountSetupStep) {
    // Swal.fire({
    //   title: "Action is done",
    //   icon: 'success'
    // })
    this.showMessage('You have completed a step. Feel free to reach out to me if assistance is required.', 0, 5000, 'success')
    // this.sharedOwnerDashboardService.isGuideOpen = true;

    // setTimeout(() => {
    //   this.sharedOwnerDashboardService.isGuideOpen = false;
    // }, 5500);

  }
}
