import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WebsitePaymentsState, paymentGatewaysAdapter } from './website-payments.reducer';
import { Payment_Gateway_Enum } from 'src/app/models/portal';
import { WebsiteCompoundState } from '../../../learmo.reducer';


// Create a feature selector for the 'website' feature
export const selectCompoundWebsiteState = createFeatureSelector<WebsiteCompoundState>('website');
export const selectWebsitePaymentsState = createSelector(selectCompoundWebsiteState, (state) => state.payments);



export const selectWebsitePaymentGateways = createSelector(selectWebsitePaymentsState, (state) => paymentGatewaysAdapter.getSelectors().selectAll(state));
export const selectWebsitePaymentGatewayByEnum = (enumValue: Payment_Gateway_Enum) => createSelector(
  selectWebsitePaymentsState,
  (state) => paymentGatewaysAdapter.getSelectors().selectAll(state).find(gateway => gateway.id.toLowerCase() == enumValue.toLowerCase())
);



export const isWebsitePaymentGatewaysLoaded = createSelector(selectWebsitePaymentsState, (state) => state.paymentGatewaysLoaded);
export const isWebsitePaymentGatewaysLoadingError = createSelector(selectWebsitePaymentsState, (state) => state.paymentGatewaysError);




