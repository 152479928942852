import { CrashReportsService } from './crash-reports.service';
import { Injectable, EventEmitter } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  throwError,
  lastValueFrom,
  from,
  Subject,
  of,
  concat,
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { User, UpdateUserTwoFaPayload, Subscription } from '../../models';
import { ApiService } from './lsg.api.service';
import { Router } from '@angular/router';
import { RegisterModel } from '../../models/register';
import { FinsihUserTourResponse } from '../../models/user';
import { PortalControlService } from './portal-control.service';
import { ToastrService } from 'ngx-toastr';
import Gleap from 'gleap';
import { AppwriteService } from './appwrite.sdk.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import {
  CheckWebHook,
  forgetSession,
  generateUserToken,
  getCurrentUserAppwriteProfile,
  getCurrentUserLearmoProfile,
  initializeState,
  loginFailure,
  loginWithEmailAndPassword,
  markWebsiteOnboardingAsDone,
  setCurrentUserLearmoProfile,
  setUserToken,
} from '../store/_features/user/auth/auth.actions';
import {
  selectAppwriteProfile,
  selectLearmoProfile,
  selectToken,
} from '../store/_features/user/user.selector';
import { createNewAccount } from '../store/_features/user/register/register.actions';
import { Models } from 'appwrite';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  isRefreshingToken: boolean = false;
  refreshTokenSubject: Subject<any> = new Subject<any>();


  onLogout: EventEmitter<boolean> = new EventEmitter<boolean>();
  onLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

  public redirectUrl: string;

  public currentUser: Observable<User>;
  public appwriteProfile: Observable<Models.User<Models.Preferences>>;
  public token: Observable<string>;
  public isEmailConfirmed: Observable<boolean>;

  currentUserValue: User;
  appwriteProfileValue: Models.User<Models.Preferences>;
  currentTokenValue: string;

  private currentUserStorageKey = environment.storageKeys.currentUserStorageKey;
  private appwriteProfileStorageKey =
    environment.storageKeys.appwriteProfileStorageKey;
  private tokenStorageKey = environment.storageKeys.tokenStorageKey;

  constructor(
    private appwriteService: AppwriteService,
    private http: HttpClient,
    private api: ApiService,
    private router: Router,
    private portalControlService: PortalControlService,
    private toastr: ToastrService,
    private store: Store,
    private crashReportsService: CrashReportsService
  ) {
    this.appwriteProfile = this.store.select(selectAppwriteProfile);
    this.currentUser = this.store.select(selectLearmoProfile);
    this.token = this.store.select(selectToken);

    this.currentUser.subscribe((profile) => {
      this.currentUserValue = profile;
      if (profile) {
        localStorage.setItem(
          this.currentUserStorageKey,
          JSON.stringify(profile)
        );
        this.setIdentity(profile);


      }
    });

    this.appwriteProfile.subscribe((profile) => {
      this.appwriteProfileValue = profile;
      if (profile) {
        localStorage.setItem(
          this.appwriteProfileStorageKey,
          JSON.stringify(profile)
        );
      }
    });

    this.token.subscribe((token) => {
      this.currentTokenValue = token;
      if (token) {
        localStorage.setItem(this.tokenStorageKey, token);
        this.appwriteService.setCurrentToken(token);
      }
    });
    this.store.dispatch(initializeState());
  }

  public set setCurrentAppwriteValue(profile: any) {
    localStorage.setItem(
      this.appwriteProfileStorageKey,
      JSON.stringify(profile)
    );
    // this.currentUserSubject.next(profile);
  }

  isTokenValidParse(token = this.currentTokenValue) {
    return this.appwriteService.isTokenValidParse(token);
  }
  async isTokenValid() {
    let isTokenValid = await this.appwriteService.isTokenValid(
      this.currentTokenValue
    );
    return isTokenValid;
  }
  forget() {
    this.store.dispatch(forgetSession({ redirect: true }));
  }

  // resetPasswordRequest(email: string) {
  //   return this.appwriteService.createPasswordRecoveryRequest(email)
  // }

  setIdentity(currentUserValue: User = this.currentUserValue) {
    this.crashReportsService.setIdentity(currentUserValue);
  }

  removeIdentity() {
    this.crashReportsService.removeIdentity();
  }


  // emailVerificationRequest() {
  //   return this.appwriteService.emailVerificationRequest();
  // }
  public onLoginResponse(response, forceAffiliate: boolean = false) {
    // this.setUserSessionData(response);
    this.onLogin.emit();
  }

  handleUserLoginRedirect(forceAffiliate: boolean = false) {
    if (!!forceAffiliate) {
      this.router.navigate(['/affiliate']);
    } else {
      switch (this.currentUserValue?.role?.name) {
        case 'subscriber':
          if (!!this.currentUserValue.subscription.isSubscribed) {
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = null;
            } else {
              this.router.navigate(['dashboard']);
            }
          } else {
            this.router.navigate(['/choose-plan']);
          }
          break;
        case 'affiliate':
          this.router.navigate(['/affiliate']);
          break;
        default:
          this.router.navigate(['dashboard']);
          break;
      }
    }
  }

  // public onRegisterResponse(resp) {
  //   this.onLoginResponse(resp);
  // }

  public onVerifiedResponse(resp) {
    // this.setUserSessionData(resp);
  }

  // public onUpdateSettingsResponse(
  //   updatesettingsReq: UpdateUserSettingsRequest
  // ) {
  //   const user: User = this.currentUserValue;
  //   user.twofa = updatesettingsReq.twofa;
  //   localStorage.removeItem(this.currentUserStorageKey);
  //   this.setCurrentUserValue = user;
  // }

  loginWithEmailAndPassword(
    email: string,
    password: string,
  ) {
    this.store.dispatch(loginWithEmailAndPassword({ email, password, fromRegister: false }));
    
  }

  loginWithPhone(
    phone: string,
  ) {
    return this.appwriteService.loginWithPhone({phone})
  }

  loginWithMagicLink(
    email: string,
  ) {
    return this.appwriteService.loginWithMagicLink({email})
  }


  async handleCreatedSession(
    appwriteProfile,
    redirect: boolean = true,
    errorHandler: any = undefined
  ) {
    this.store.dispatch(generateUserToken());
  }

  async getMyProfile(errorHandler?: any) {
    let request$ = this.api.me(errorHandler);
    let response = await lastValueFrom(request$);

    return response;
  }
  async getSubscription(errorHandler?: any) {
    let subscription$ = this.api.getSubscriptionInfo(
      !!errorHandler ? errorHandler : this.handleError
    );

    await lastValueFrom(subscription$).then((value) => {
      this.onGetSubscriptionResponse(value);
    });
  }

  public onGetSubscriptionResponse(resp) {
    const userObject: User = this.currentUserValue;
    if (!!userObject) {
      userObject.subscription = resp;
      localStorage.setItem(
        this.currentUserStorageKey,
        JSON.stringify(userObject)
      );
      // this.currentUserSubject.next(userObject);
    } else {
      this.logout(false);
    }
  }

  register(model: Partial<RegisterModel>, errorHandler?: any) {
    this.store.dispatch(
      createNewAccount({
        firstname: model.firstname,
        lastname: model.lastname,
        email: model.email,
        password: model.password,
      })
    );
    // let request$ = this.appwriteService.account.create(model.username,model.email, model.password, `${model.firstname} ${model.lastname}`);
    // request$.then(async (result)=>{
    //   // this.appwriteService.account.createEmailSession(model.email, model.password);
    //   setTimeout(() => {
    //     this.login(model.email, model.password, false);
    //   }, 1000);

    //   // this.handleUserRegistered();
    // }).catch((error)=>{
    //
    //   errorHandler();
    // })
    // // return this.api.register(
    // //   model,
    // //   !!errorHandler ? errorHandler : this.handleError
    // // );
  }

  // async handleUserRegistered(errorHandler: any = undefined) {
  //   return await this.sendVerificationEmail();
  // }

  // async sendVerificationEmail(errorHandler?: any) {

  // }

  captureLead(model: Partial<RegisterModel>, errorHandler?: any) {
    return this.api.captureLead(
      model,
      !!errorHandler ? errorHandler : this.handleError
    );
  }

  async reloadMyProfile() {
    this.store.dispatch(getCurrentUserLearmoProfile({ redirect: false }));
    this.store.dispatch(getCurrentUserAppwriteProfile());
    // let request$ = this.api.me();
    // let response = await lastValueFrom(request$);

    // this.updateProfile(response);
  }

  updateProfile(resp: any) {
    this.store.dispatch(setCurrentUserLearmoProfile({ learmoProfile: resp }));
  }

  // loginVerified(email: string, sc: string, errorHandler?: any) {
  //   return this.api
  //     .confirmEmail(email, sc, !!errorHandler ? errorHandler : this.handleError)
  //     .pipe(
  //       map((resp) => {
  //         this.onVerifiedResponse(resp);
  //         return resp;
  //       })
  //     );
  // }

  logout(manual: boolean = true) {
    this.portalControlService.canActivateLogin = true;
    this.forget();
    if (!manual) {
      this.toastr.error('Session timeout!');
    }
    this.appwriteService.clearAppwriteSession();
    this.onLogout.emit(true);
    this.removeIdentity();
  }

  markWebsiteOnboardingAsDone(errorHandler?: any) {
    this.store.dispatch(markWebsiteOnboardingAsDone());
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(
      error.message || 'Something went wrong during authorization.'
    );
  }

  // Auth Check Logic
  async checkAuth(url: string = undefined) {
    return this.checkToken()
      .then((isTokenValid) => {
        if (!!isTokenValid) {
          return true;
        } else {
          return this.checkSession().then(async (isSessionValid) => {
            if (isSessionValid) {
              await this.refreshToken();
              return true; 
            } else {
              throw throwError(() => 'No session found');
            }
          });
        }
      })
      .catch((error) => {
        throw throwError(() => 'No session found');
      });
  }
  private async checkToken(): Promise<boolean> {
    if (!!this.currentUserValue?.id && !!this.currentTokenValue) {
      return this.appwriteService
        .isTokenValid(this.currentTokenValue)
        .then((isTokenValid) => {
          if (isTokenValid) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.error('Error checking token validity:', error);
          return false;
        });
    }
  }

  private async checkSession(): Promise<boolean> {
    return this.appwriteService
      .isSessionValid()
      .then((isSessionValid) => {
        if (isSessionValid) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.error('Error checking session validity:', error);
        return false;
      });
  }

  refreshTokenObservable(): Observable<string> {
    return this.appwriteService.refreshToken().pipe(
      switchMap((newToken: string) => {
        this.store.dispatch(setUserToken({ token: newToken }));
        return of(newToken);
      })
    );
  }
  

  async refreshToken() {
    let request$ =  this.refreshTokenObservable();
    let newToken = await lastValueFrom(request$);
    return newToken;
  }



  handleMagicLinkCallback(userId: string, secret: string) {
    return this.appwriteService.handleMagicLinkCallback(userId, secret).pipe(
      map((result) => {
        // Do something with the result if needed
        console.log(result);
        this.store.dispatch(generateUserToken());
        return null
      }),
      catchError((error) => {
        // Handle the error, and dispatch a failure action if necessary
        this.toastr.error(error.message, 'Error!');
        this.router.navigate(['/'])
        throw error;
      })).subscribe();
  }


  handlePhoneCallback(userId: string, secret: string) {
    return this.appwriteService.handleMagicLinkCallback(userId, secret).pipe(
      switchMap((result) => {
        // Do something with the result if needed
        console.log(result);
        this.store.dispatch(generateUserToken());
        return null
      }),
      catchError((error) => {
        // Handle the error, and dispatch a failure action if necessary
        this.toastr.error(error.message, 'Error!');
        throw error;
      })).subscribe();
  }
}
