import { EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from '../../../shared/services';
import { MessengerApi } from '../../services/messenger.api.service';
import {
  IPGalleryItem,
  MessengerData,
  MessengerThread,
  MessengerThreadStatusEnum,
} from '../../../models/owner-dashboard';
import { lastValueFrom, Subscription } from 'rxjs';
import {
  MessengerThreadsPaginated,
  SendMessageRequest,
} from '../../../models/owner-dashboard';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { PortalSocketService } from '../../services/portal-socket.service';
import {
  DashboardSessionModeType,
  UserDashboardService,
} from '../../services/user-dashboard.service';

import {
  MessengerUser,
  AffiliateMinimizedModel,
} from '../../../models/owner-dashboard';

export enum SectionLoadedStatusEnum {
  none = 'none',
  loading = 'loading',
  empty = 'empty',
  filled = 'filled',
  creating = 'creating',
}

@Injectable({
  providedIn: 'root',
})
export class SharedMessengerService {
  notificationSound: HTMLAudioElement = new Audio('assets/notification.mp3');
  // loaded: boolean = false;
  loaded: {
    threadsList: SectionLoadedStatusEnum;
    currentThread: SectionLoadedStatusEnum;
    gallery: SectionLoadedStatusEnum;
  } = {
    currentThread: SectionLoadedStatusEnum.loading,
    threadsList: SectionLoadedStatusEnum.loading,
    gallery: SectionLoadedStatusEnum.loading,
  };
  tempMessengerData: MessengerData = {
    threads: null,
    currentThread: null,
    currentThreadId: null,
    currentThreadIndex: null,
  };
  messengerData: MessengerData = {
    threads: null,
    currentThread: null,
    currentThreadId: null,
    currentThreadIndex: null,
  };
  message: SendMessageRequest = {
    text: '',
    mediaIds: [],
  };

  newChatBody: SendMessageRequest = {
    threadUserIds: [],
    threadUsers: [],
    text: '',
    mediaIds: [],
  };

  galleryItems: IPGalleryItem[] = [];
  galleryData: ImageItem[] = [];
  galleryNeedsRefresh: boolean = true;

  scrollLoading: {
    threadsList: {
      previous: boolean;
      next: boolean;
    };
    currentThread: {
      previous: boolean;
      next: boolean;
    };
    gallery: boolean;
  } = {
    currentThread: {
      previous: false,
      next: false,
    },
    threadsList: {
      previous: false,
      next: false,
    },
    gallery: false,
  };

  loadThreadsToTopRequired: boolean = false;

  chatAutoCompleteItems: AffiliateMinimizedModel[] = [];
  chatSubscription: Subscription;
  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private messengerApi: MessengerApi,
    private portalSocketService: PortalSocketService,
    private userDashboardService: UserDashboardService
  ) {}


  async goToThread(id: number) {
    let isAffiliate =
      this.userDashboardService.dashboardSessionMode ==
      DashboardSessionModeType.affiliate;
    if (isAffiliate) {
      this.route.navigate(['affiliate', 'messenger', id]);
    } else {
      this.route.navigate(['dashboard', 'messenger', id]);
    }

    await this.changeActiveThread(id);
    this.enableGalleryRefresh();
    // this.changeActiveThread(id);
  }

  async onToTopButtonClick() {
    await this.loadToPageZero();
    this.loadThreadsToTopRequired = false;
  }
  goToMessenger() {
    this.onToTopButtonClick();
    let isAffiliate =
      this.userDashboardService.dashboardSessionMode ==
      DashboardSessionModeType.affiliate;
    if (isAffiliate) {
      this.route.navigate(['affiliate', 'messenger']);
    } else {
      this.route.navigate(['dashboard', 'messenger']);
    }
  }
  handleNewMessage(
    data: MessengerThread,
    callingFrom: 'messenger' | 'other' = 'messenger'
  ) {
    if (this.route.url.indexOf('messenger') > -1) {
      callingFrom = 'messenger';
    } else {
      callingFrom = 'other';
    }

    this.generateStructuredName(data);

    //     this.messengerData.currentThread.messages.maxNextPage =
    // data.messages.currentPage;
    if (data.id == this.messengerData.currentThreadId) {
      this.pushMessageDataToCurrentThread(data);
    } else {
      this.plusMessageToNotifications();

      let index = this.messengerData.threads.data.findIndex(
        (a) => a.id == data.id
      );
      if ((!!index && index != -1) || index == 0) {
        this.pushMessageDataToByThreadIndex(data, index);
      } else {
      }

      if (callingFrom == 'messenger') {
        
        if (!this.threadItemInViewport(data.id)) {
          this.loadThreadsToTopRequired = true;
        }
      }
    }
  }

  plusMessageToNotifications() {
    this.messengerData.threads.unreadMessagesCount++;
  }

  minusMessageNotifications() {
    if (this.messengerData.threads.unreadMessagesCount > 0) {
      this.messengerData.threads.unreadMessagesCount--;
    }
  }

  async handleNewSeen(
    data: MessengerThread,
    callingFrom: 'messenger' | 'other' = 'messenger'
  ) {
    if (this.route.url.indexOf('messenger') > -1) {
      callingFrom = 'messenger';
    } else {
      callingFrom = 'other';
    }

    this.generateStructuredName(data);

    //     this.messengerData.currentThread.messages.maxNextPage =
    // data.messages.currentPage;
    if (data.id == this.messengerData.currentThreadId) {
      await this.updateSeenForCurrentThread(data);
    } else {
      let index = this.messengerData.threads.data.findIndex(
        (a) => a.id == data.id
      );
      if ((!!index && index != -1) || index == 0) {
        this.updateSeenByThreadIndex(data, index);
      } else {
      }
    }

    // if (callingFrom == 'messenger') {
    //   if (!this.threadItemInViewport(data.id)) {
    //     this.loadThreadsToTopRequired = true;
    //   }
    // }
  }

  async updateSeenForCurrentThread(data: MessengerThread) {
    // const tempMessagesData = this.messengerData.currentThread.messages;
    await this.getThreadById(data.id, undefined, undefined, 'update_seen');
    // await this.scrollChatBoxToBottom(0);
  }

  updateSeenByThreadIndex(data: MessengerThread, index: number) {
    this.messengerData.threads.data[index].lastActivity = data.lastActivity;
    this.messengerData.threads.data[index].status =
      MessengerThreadStatusEnum.unread;
  }

  threadItemInViewport(id: number) {
    let idStructure = `threadItem${id}`;
    let el = document.getElementById(idStructure);
    if (!!el) {
      return this.elementInViewport(el);
    } else {
      return false;
    }
  }
  elementInViewport(el) {
    var bounding = el.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  pushMessageDataToByThreadIndex(data: MessengerThread, index: number) {
    this.messengerData.threads.data[index].id = data.id;
    this.messengerData.threads.data[index].messages.data.push(
      ...data.messages.data
    );
    this.messengerData.threads.data[index].lastActivity = data.lastActivity;
    this.messengerData.threads.data[index].status =
      MessengerThreadStatusEnum.unread;

    if (!!index) {
      this.moveThreadToTop(this.messengerData.threads.data, index, 0);
    }
  }

  pushMessageDataToCurrentThread(data: MessengerThread) {
    const tempMessagesData = this.messengerData.currentThread.messages;
    tempMessagesData.data.push(...data.messages.data);
    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages = tempMessagesData;

    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].lastActivity = data.lastActivity;

    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].status = MessengerThreadStatusEnum.read;

    this.moveThreadToTop(
      this.messengerData.threads.data,
      this.messengerData.currentThreadIndex,
      0
    );

    this.messengerData.currentThreadIndex = 0;
    this.messengerData.currentThread = this.messengerData.threads.data[0];
    this.scrollChatBoxToBottom();
    this.markAsRead(this.messengerData.currentThread);
  }

  async loadToPageZero() {
    if (this.messengerData?.threads?.currentPage != 0) {
      for (
        let index = 0;
        index < this.messengerData.threads.currentPage;
        index++
      ) {
        await this.getThreadsListPreviousPage();
        this.scrollThreadsListToTop();
      }
    } else {
      this.scrollThreadsListToTop();
    }
  }

  scrollThreadsListToTop() {
    let el = document.getElementById('threadsList');
    if (!!el) {
      el.scrollTop = 0;
    }
  }

  startLoading(
    key: 'currentThread' | 'threadsList' | 'gallery' = 'threadsList'
  ) {
    this.loaded[key] = SectionLoadedStatusEnum.loading;
  }

  async createNewThread() {
    await this.getChatAutoCompleteData();
    this.changeLoadingStatus('currentThread', SectionLoadedStatusEnum.creating);
    this.tempMessengerData = JSON.parse(JSON.stringify(this.messengerData));
    this.messengerData = {
      threads: this.messengerData.threads,
      currentThread: null,
      currentThreadId: null,
      currentThreadIndex: null,
    };
  }

  async createNewThreadByUserId(id: number) {
    await this.createNewThread();
    if (!!id) {
      let userIndex = this.chatAutoCompleteItems.findIndex((a) => a.id == id);

      if (userIndex >= 0) {
        await this.onAddAffiliateToNewChat(
          this.chatAutoCompleteItems[userIndex],
          undefined,
          true
        );
      }
    }
  }

  async onAddAffiliateToNewChat(
    affiliate: AffiliateMinimizedModel,
    isAffiliate: boolean = false,
    runOnFind: boolean = false
  ) {
    let idIndex = this.newChatBody.threadUserIds.findIndex(
      (a) => a == affiliate.id
    );
    let affiliateIndex = this.newChatBody.threadUsers.findIndex(
      (a) => a.id == affiliate.id
    );
    if (idIndex == -1) {
      this.newChatBody.threadUserIds.push(affiliate.id);
    }
    if (affiliateIndex == -1) {
      this.newChatBody.threadUsers.push(affiliate);
    }

    await this.lookForThreadByUsers(
      this.newChatBody.threadUserIds,
      isAffiliate,
      runOnFind
    );
    //
    //
  }
  async getGalleryImages(id: number, force: boolean = false) {
    if (!!this.galleryNeedsRefresh || !this.galleryItems.length || force) {
      this.startLoading('gallery');
      const gallery$ = this.messengerApi.getThreadMediaById(id);
      const gallery: IPGalleryItem[] = await lastValueFrom(gallery$);
      this.galleryItems = gallery;
      this.galleryData = this.galleryItems.map(
        (a) => new ImageItem({ src: a.src, thumb: a.thumb })
      );
      this.disableGalleryRefresh();
      this.changeLoadingStatus('gallery', SectionLoadedStatusEnum.none);
    }
  }

  async buildGalleryImages() {
    await this.getGalleryImages(this.messengerData.currentThreadId);
  }

  enableGalleryRefresh() {
    this.galleryNeedsRefresh = true;
  }

  disableGalleryRefresh() {
    this.galleryNeedsRefresh = false;
  }

  generateStructuredName(thread: MessengerThread) {
    let label = thread.users[0].firstName;
    let count = thread.users.length - 1;
    thread.structuredName = { label: label, othersCount: count };
  }

  changeLoadingStatus(
    key: 'currentThread' | 'threadsList' | 'gallery' = 'threadsList',
    status: SectionLoadedStatusEnum
  ) {
    this.loaded[key] = status;
  }
  async getMessengerThreads(
    page: number = 0,
    itemsPerPage: number = 12,
    action: 'previousPage' | 'nexPage' | 'firstTime' = 'firstTime',
    id?: number,
    isAffiliate: boolean = false
  ) {
    if (action == 'firstTime') {
      this.startLoading('threadsList');
    }
    const threadsList$ = this.messengerApi.getThreads(
      page,
      itemsPerPage,
      id,
      isAffiliate
    );
    const threadsList: MessengerThreadsPaginated = await lastValueFrom(
      threadsList$
    );

    threadsList.data.forEach((element) => {
      this.generateStructuredName(element);
    });

    threadsList.maxNextPage = threadsList.currentPage;
    threadsList.maxPreviousPage = threadsList.currentPage;

    this.addThreadItemToMessengerList(threadsList, action);
    if (!!id && threadsList.countInCurrentPage < itemsPerPage) {
      this.getThreadsListPreviousPage();
    }
    if (threadsList?.data?.length > 0) {
      this.changeLoadingStatus('threadsList', SectionLoadedStatusEnum.filled);
    } else {
      this.changeLoadingStatus('threadsList', SectionLoadedStatusEnum.empty);
    }
  }

  addThreadItemToMessengerList(
    threads: MessengerThreadsPaginated,
    action: 'previousPage' | 'nexPage' | 'firstTime' = 'firstTime'
  ) {
    if (action == 'firstTime') {
      this.messengerData.threads = threads;
      
    } else if (action == 'nexPage') {
      this.messengerData.threads.data.push(...threads.data);
      this.messengerData.threads.maxNextPage = threads.currentPage;
    } else if (action == 'previousPage') {
      this.messengerData.threads.data.unshift(...threads.data);
      this.messengerData.threads.maxPreviousPage = threads.currentPage;
    }

    this.messengerData.threads.countInCurrentPage = threads.countInCurrentPage;
  }

  async getThreadsListNextPage(isAffiliate: boolean = false) {
    if (
      this.messengerData?.threads?.totalPages - 1 >
      this.messengerData?.threads?.maxNextPage
    ) {
      await this.getMessengerThreads(
        this.messengerData.threads.maxNextPage + 1,
        undefined,
        'nexPage',
        undefined,
        isAffiliate
      );
    }
  }

  async getThreadsListPreviousPage(isAffiliate: boolean = false) {
    if (this.messengerData?.threads?.maxPreviousPage > 0) {
      await this.getMessengerThreads(
        this.messengerData.threads.maxPreviousPage - 1,
        undefined,
        'previousPage',
        undefined,
        isAffiliate
      );
    } else {
      this.loadThreadsToTopRequired = false;
    }
  }

  async getThreadById(
    id: number,
    page: number = 0,
    itemsPerPage: number = 20,
    action:
      | 'previousPage'
      | 'nexPage'
      | 'firstTime'
      | 'looking'
      | 'update_seen' = 'firstTime'
  ) {
    if (action == 'firstTime') {
      this.startLoading('currentThread');
    }
    const thread$ = this.messengerApi.getThreadById(id, page, itemsPerPage);
    const thread: MessengerThread = await lastValueFrom(thread$);
    this.generateStructuredName(thread);
    thread.messages.maxNextPage = thread.messages.currentPage;
    thread.messages.maxPreviousPage = thread.messages.currentPage;
    if (action == 'update_seen') {
      thread.status = MessengerThreadStatusEnum.read;
    }
    this.addThreadToMessenger(thread, action);
    // this.messengerData.currentThread.id = thread.id;
    if (action == 'looking') {
      this.changeLoadingStatus(
        'currentThread',
        SectionLoadedStatusEnum.creating
      );
    }
    if (
      this.messengerData?.currentThread?.messages?.data?.length > 0 &&
      action != 'looking'
    ) {
      this.changeLoadingStatus('currentThread', SectionLoadedStatusEnum.filled);
    } else {
      this.changeLoadingStatus('currentThread', SectionLoadedStatusEnum.empty);
    }
  }

  addThreadToMessenger(
    thread: MessengerThread,
    action: 'previousPage' | 'nexPage' | 'firstTime' | 'looking' | 'update_seen'
  ) {
    if (action == 'firstTime') {
      this.messengerData.threads.data[this.messengerData.currentThreadIndex] =
        thread;
      this.messengerData.currentThread =
        this.messengerData.threads.data[this.messengerData.currentThreadIndex];
    } else if (action == 'update_seen') {
      this.messengerData.threads.data[this.messengerData.currentThreadIndex] =
        thread;
      this.messengerData.currentThread =
        this.messengerData.threads.data[this.messengerData.currentThreadIndex];
    } else if (action == 'looking') {
      this.messengerData.currentThread = thread;
    } else if (action == 'nexPage') {
      this.addNextPageDataToCurrentThread(thread);
    } else if (action == 'previousPage') {
      this.addPreviousPageDataToCurrentThread(thread);
    }
    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages.countInCurrentPage = thread.messages.countInCurrentPage;
  }

  addNextPageDataToCurrentThread(thread: MessengerThread) {
    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages.data.unshift(...thread.messages.data);

    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages.maxNextPage = thread.messages.currentPage;
  }

  addPreviousPageDataToCurrentThread(thread: MessengerThread) {
    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages.data.push(...thread.messages.data);

    this.messengerData.threads.data[
      this.messengerData.currentThreadIndex
    ].messages.maxPreviousPage = thread.messages.currentPage;
  }

  async getCurrentThreadNextPage() {
    if (
      this.messengerData?.currentThread?.messages?.totalPages - 1 >
      this.messengerData?.currentThread?.messages?.maxNextPage
    ) {
      //
      await this.getThreadById(
        this.messengerData.currentThreadId,
        this.messengerData.currentThread.messages.maxNextPage + 1,
        undefined,
        'nexPage'
      );
    }
  }

  async getCurrentThreadPreviousPage() {
    if (this.messengerData?.currentThread?.messages?.maxPreviousPage > 0) {
      await this.getThreadById(
        this.messengerData.currentThreadId,
        this.messengerData.currentThread.messages.maxPreviousPage - 1,
        this.messengerData.currentThread.messages.countInCurrentPage,
        'previousPage'
      );
    }
  }

  async scrollChatBoxToBottom(delay: number = 300) {
    setTimeout(() => {
      const element = document.getElementById('messagesChatArea');
      if (!!element) {
        element.scrollTop = element.scrollHeight;
      }
    }, delay);

    // try {
    //   this.chatMessagesBox.nativeElement.scrollTop =
    //     this.chatMessagesBox.nativeElement.scrollHeight;
    // } catch (err) {}
  }
  async changeActiveThread(
    id: number,
    action: 'previousPage' | 'nexPage' | 'firstTime' | 'looking' = 'firstTime'
  ) {
    this.clearUsersList();

    const threadIndex = this.messengerData.threads?.data?.findIndex(
      (thread) => thread.id == id
    );

    if (threadIndex == 0 || (!!threadIndex && threadIndex != -1)) {
      this.startLoading('currentThread');
      this.messengerData.currentThreadId =
        this.messengerData.threads.data[threadIndex].id;
      this.messengerData.currentThreadIndex = threadIndex;

      await this.getThreadById(
        this.messengerData.currentThreadId,
        undefined,
        undefined,
        action
      );

      await this.scrollChatBoxToBottom(300);
      await this.scrollToThreadById(id, 400);
      this.markAsRead(this.messengerData.threads.data[threadIndex]);
    } else {
      this.resetMessenger();
      // this.messengerData = {
      //   threads: null,
      //   currentThread: null,
      //   currentThreadId: null,
      //   currentThreadIndex: null,
      // };
    }
  }

  resetMessenger() {
    this.messengerData.currentThread = null;
    this.messengerData.currentThreadId = null;
    this.messengerData.currentThreadIndex = null;
  }
  async scrollToThreadById(id: number, delay: number = 300) {
    setTimeout(async () => {
      let idStructure = `threadItem${id}`;
      await this.scroll(idStructure, 'center');
    }, delay);
  }

  async scroll(id: string, position: ScrollLogicalPosition = 'end') {
    let el = document.getElementById(id);
    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth', block: position });
    }
  }

  markAsRead(thread: MessengerThread) {
    thread.status = MessengerThreadStatusEnum.read;
  }

  async lookForThreadByUsers(
    userIds: number[],
    isAffiliate: boolean = false,
    runOnFind: boolean = false
  ) {
    let threadsByUser$ = this.messengerApi.getThreadByUserIds(
      userIds,
      isAffiliate
    );
    let threadsByUser: MessengerThread = await lastValueFrom(threadsByUser$);
    if (!!threadsByUser.id) {
      this.generateStructuredName(threadsByUser);
      // await this.changeActiveThread(threadsByUser.id);
      this.messengerData.currentThread = threadsByUser;
      this.messengerData.currentThreadId = threadsByUser.id;
      this.scrollChatBoxToBottom(300);

      if (!!runOnFind) {
        this.changeActiveThread(threadsByUser.id);
      }
      // this.changeLoadingStatus('currentThread', SectionLoadedStatusEnum.creating)
    } else {
      this.messengerData.currentThread = null;
      this.messengerData.currentThreadId = null;
    }
  }

  moveThreadToTop(arr, fromIndex, toIndex) {
    if (fromIndex != toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  clearUsersList() {
    this.newChatBody = {
      threadUserIds: [],
      threadUsers: [],
      text: '',
      mediaIds: [],
    };
  }

  async startNewThread(message: SendMessageRequest) {
    const thread$ = this.messengerApi.sendMessageByThreadId({
      threadUserIds: message.threadUserIds,
      text: message.text,
      mediaIds: message.mediaIds,
    });
    const createdThread: MessengerThread = await lastValueFrom(thread$);

    this.messengerData.threads.data.unshift(createdThread);
    this.generateStructuredName(this.messengerData.threads.data[0]);
    this.changeActiveThread(createdThread.id);
  }

  async sendMessage(message: SendMessageRequest) {
    const thread$ = this.messengerApi.sendMessageByThreadId({
      text: message.text,
      mediaIds: message.mediaIds,
      threadId: this.messengerData.currentThreadId,
    });
    const thread: MessengerThread = await lastValueFrom(thread$);
    if (thread.id == this.messengerData.currentThreadId) {
      this.messengerData.threads.data[this.messengerData.currentThreadIndex] =
        thread;

      this.moveThreadToTop(
        this.messengerData.threads.data,
        this.messengerData.currentThreadIndex,
        0
      );
      this.generateStructuredName(this.messengerData.threads.data[0]);
      this.messengerData.currentThread = this.messengerData.threads.data[0];
      this.messengerData.currentThreadIndex = 0;
    }
    // TODO: Move to top if not current
    // this.messengerData.currentThread.id = thread.id;
  }

  async getChatAutoCompleteData() {
    let chatAutoCompleteItems$ = this.api.getMyAffiliates('joined');
    let chatAutoCompleteItems = await lastValueFrom(chatAutoCompleteItems$);
    this.chatAutoCompleteItems = chatAutoCompleteItems;
  }

  clearMessage() {
    this.message.text = '';
    this.message.mediaIds = [];
  }
}
