import { Component, Input, OnInit } from '@angular/core';
import { SharedProfileService } from '../../../shared-profile.service';

@Component({
  selector: 'app-subscription-valid-until',
  templateUrl: './subscription-valid-until.component.html',
  styleUrls: ['./subscription-valid-until.component.css']
})
export class SubscriptionValidUntilComponent implements OnInit {
  @Input() textClasses: string = 'text-gray-800 fw-bold';
  constructor(public sharedProfileService: SharedProfileService) { }

  ngOnInit() {
  }

}
