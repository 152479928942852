import { ContactItem } from 'src/app/components/user-dashboard/contacts/models/contacts';
// actions/products.actions.ts
import { createAction,createActionGroup, props, emptyProps } from '@ngrx/store';
import { RefundItem } from '../../../../models/owner-dashboard';
import { CreateContactDto } from 'src/app/components/user-dashboard/contacts/create/dto/create-contact.dto';

export const ContactsActions = createActionGroup({
  source: 'Load Contacts',
  events: {
    load: emptyProps(), // Use emptyProps() for actions without props
    loadSuccess: props<{ contacts: ContactItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});


export const NewContactActions = createActionGroup({
  source: 'Create New Contact',
  events: {
    loadNewContact: props<{ createPayload: CreateContactDto }>(),
    loadSuccess: props<{ contact: ContactItem }>(),
    loadFailure: props<{ error: any }>(),
  },
});


// export const loadContacts = createAction('[Contacts] Load Contacts');
// export const loadContactsSuccess = createAction('[Contacts] Load Contacts Success', props<{ contacts: ContactItem[] }>());
// export const loadContactsFailure = createAction('[Contacts] Load Contacts Failure', props<{ error: any }>());
// Define other actions as needed for your feature

// export const createNewContact = createAction('[Contacts] Add New Contact', props<{ createPayload: CreateContactDto }>());
// export const createNewContactSuccess = createAction('[Contacts] Contact Added Successfully', props<{ contact: ContactItem }>());
// export const createNewContactFailure = createAction('[Contacts] Create new Contact Failure', props<{ error: any }>());


export const updateContact = createAction('[Contacts] Updated Contacts Successfully', props<{ itemId: number, item: RefundItem }>());
