export function genericFilter(
  items: any[],
  searchText: string,
  exclude: string[] = [
    'status',
    'invitedAt',
    'image',
    'visibility',
    'type',
    'offer',
    'link'
  ]
): any[] {
  if (!items) { return []; }
  if (!searchText) { return items; }
  
  searchText = searchText.toLowerCase();

  return items.filter((item) => {
    let fullNameResult = false;
    let fullName = '';

    if (item?.firstName && item?.lastName) {
      fullName = item.firstName + ' ' + item.lastName;
      fullNameResult = fullName.toLowerCase().includes(searchText);
    }
    if (item?.affiliate?.firstName && item?.affiliate?.lastName) {
      fullName = item.affiliate.firstName + ' ' + item.affiliate.lastName;
      fullNameResult = fullName.toLowerCase().includes(searchText);
    }

    return Object.keys(item).some((k) => {
      let excludeResult = exclude.includes(k);

      return (
        (item[k] != null &&
          !excludeResult &&
          JSON.stringify(item[k])
            .toString()
            .toLowerCase()
            .includes(searchText)) ||
        fullNameResult
      );
    });
  });
}
