import { Injectable, ViewChild } from '@angular/core';
import * as Emoji from 'quill-emoji';

import * as Quill from 'quill';
import 'quill-mention';
import QuillMarkdown from 'quilljs-markdown';
import { QuillConfig, QuillEditorComponent, QuillModules } from 'ngx-quill';

var Parchment = Quill.import('parchment');

let CustomClass = new Parchment.Attributor.Class('custom', 'ql-custom', {
  scope: Parchment.Scope.INLINE,
});

Quill.register(CustomClass, true);

var customButton = document.querySelector('#custom-button');

@Injectable({
  providedIn: 'root',
})
export class EditorConfigService {
  editorRef: QuillEditorComponent;

  qullToolbar = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons

    [{ list: 'ordered' }, { list: 'bullet' }],
    //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //[{ 'direction': 'rtl' }],                         // text direction

    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    //[{ 'font': [] }],

    ['clean'], // remove formatting button

    ['link'],
    ['emoji'],

    //['link', 'image', 'video']
  ];
  quillConfig: QuillModules = {
    
    //toolbar: '.toolbar',
    toolbar: {
      container: this.qullToolbar,
      handlers: {
        fullscreen: () => {
          // Call the custom button's onClick method when the "omega" button is clicked
          
        },
    },
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': false,
    QuillMarkdown: {
      ignoreTags: [ 'pre', 'strikethrough'], // @option - if you need to ignore some tags.
      
      tags: { // @option if you need to change for trigger pattern for some tags. 
       blockquote: {
         pattern: /^(\|){1,6}\s/g,
       },
       bold: {
         pattern:  /^(\|){1,6}\s/g,
       },
       italic: {
         pattern: /(\_){1}(.+?)(?:\1){1}/g,
       },
     },
   },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          },
        },
      },
    },
  };

  quillEmailConfig: QuillModules = {
    //toolbar: '.toolbar',
    toolbar: {
      container: this.qullToolbar,
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': false,
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: false,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editorRef.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      mentionDenotationChars: ['{{', '{'],
      source: (searchTerm, renderList) => {
        const values = [
          { id: 1, value: '{{first_name}}' },
          { id: 2, value: '{{last_name}}' },
        ];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          },
        },
      },
    },
  };
  constructor() {
    Quill.register('modules/emoji', Emoji);
    Quill.register('modules/QuillMarkdown', QuillMarkdown, true)

  }
}
