import { ComponentCanDeactivate } from './component-can-deactivate';
import { Directive } from '@angular/core';
import { PortalControlService } from '../services/portal-control.service';

@Directive()
export abstract class NavCanDeactivate extends ComponentCanDeactivate {
  constructor(public portalControlService: PortalControlService) {
    super();
  }
  canDeactivate(): boolean {
    return !!this.portalControlService.canNavigateAll;
  }
}
