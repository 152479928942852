import { productAdapter } from '../products/products.reducer';
// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import { OfferItem } from '../../../../components/user-dashboard/offer/models/offer';
import {
  addNewMediaLibraryFiles,


  MediaLibraryFileActions,


  MediaLibraryActions,


  MediaLibraryQuotaActions,


  MediaLibraryTypesActions,

  updateMediaLibraryFile,
} from './media-library.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { forgetSession } from '../user/auth/auth.actions';
import { MediaLibraryFileModel, MediaLibraryTypeModel, UserQuotaModel } from '../../../../models/sales-funnel';

export interface MediaLibraryState extends EntityState<MediaLibraryFileModel> {
    mediaLibraryTypes: MediaLibraryTypeModel[];
  mediaLibraryTypesLoaded: boolean,
  mediaLibraryQuotaLoaded: boolean,
  mediaLibraryQuota: UserQuotaModel;
  loaded: boolean;
  deleteFileProcessing: boolean;
  error: any;
}

export const mediaLibraryAdapter = createEntityAdapter<MediaLibraryFileModel>();

const initialState: MediaLibraryState = mediaLibraryAdapter.getInitialState({
  mediaLibraryTypes: [],
  mediaLibraryTypesLoaded: false,
  mediaLibraryQuotaLoaded: false,
  mediaLibraryQuota: undefined,
  loaded: false,
  deleteFileProcessing: false,
  error: null,
});

export const mediaLibraryReducer = createReducer(
  initialState,
  on(MediaLibraryActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(MediaLibraryActions.loadSuccess, (state, { files }) => {
    return mediaLibraryAdapter.setAll(files, { ...state, loaded: true });
  }),
  on(MediaLibraryActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  // Media Library Types
  on(MediaLibraryTypesActions.load, (state) => ({ ...state, mediaLibraryTypesLoaded: false })),
  on(MediaLibraryTypesActions.loadSuccess, (state, { types }) => {
    return {...state, mediaLibraryTypes: types, mediaLibraryTypesLoaded: true}
  }),


  on(MediaLibraryQuotaActions.loadSuccess, (state, { quota }) => {
    return {...state, mediaLibraryQuota: {quota: +quota?.quota, used: +quota?.used, remain: +quota?.remain, percentage: +quota?.percentage}, mediaLibraryQuotaLoaded: true}
  }),

  // Handle other actions and state changes here
  on(addNewMediaLibraryFiles, (state, { files }) => {
    return mediaLibraryAdapter.addMany(files, state);
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(updateMediaLibraryFile, (state, { file }) => {
    if (!!file.id) {
      return mediaLibraryAdapter.updateOne(
        { id: file.id, changes: file },
        state
      );
    } else {
      return state;
    }
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),

  on(MediaLibraryFileActions.delete, (state, { file }) => {
    return mediaLibraryAdapter.updateOne(
      { id: file.id, changes: { deleting: true } },
      state
    );
  }),
  on(MediaLibraryFileActions.deleteSuccess, (state, { fileId }) => {
    if (fileId) {
      return mediaLibraryAdapter.removeOne(fileId, state);
    } else {
      return state;
    }
  }),

  on(MediaLibraryFileActions.deleteFailure, (state, { error, fileId }) => {
    return mediaLibraryAdapter.updateOne(
      { id: fileId, changes: { deleting: false } },
      state
    );
  }),
  on(forgetSession, (state) => initialState)
);
