import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-label',
  templateUrl: './draft-label.component.html',
  styleUrls: ['./draft-label.component.css']
})
export class DraftLabelComponent implements OnInit {
  @Input() type: 'label' | 'dropdown' = 'label'
  @Input() dropIsActive: boolean;
  @Input() customLabel: string = 'Draft'
  constructor() { }

  ngOnInit() {
  }

}
