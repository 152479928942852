// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-card.selectable {
    cursor: pointer;
}

.payment-card {
    border: 1px solid var(--bs-secondary);
}
.payment-card.selectable.selected {
    opacity: 1;
    border: 1px solid var(--bs-primary);
    background-color: var(--bs-primary-light) !important;
}
.payment-card.selectable:not(.selected) {
    opacity: 0.5;
}

.payment-card.selectable:not(.selected):hover {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/payments/payment-method-item/payment-method-item.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,qCAAqC;AACzC;AACA;IACI,UAAU;IACV,mCAAmC;IACnC,oDAAoD;AACxD;AACA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".payment-card.selectable {\n    cursor: pointer;\n}\n\n.payment-card {\n    border: 1px solid var(--bs-secondary);\n}\n.payment-card.selectable.selected {\n    opacity: 1;\n    border: 1px solid var(--bs-primary);\n    background-color: var(--bs-primary-light) !important;\n}\n.payment-card.selectable:not(.selected) {\n    opacity: 0.5;\n}\n\n.payment-card.selectable:not(.selected):hover {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
