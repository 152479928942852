import { Payment_Gateway_Enum } from './../../../../models/portal';
import { Title } from '@angular/platform-browser';
import { EditorConfigService } from './../../../../shared/services/editor-config.service';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, skipUntil, skipWhile, takeWhile } from 'rxjs';
import { DashboardCanDeactivate } from 'src/app/shared/guards/dashboard-can-deactivate';
import { ApiService } from '../../../../shared/services/lsg.api.service';

import { SharedOfferService } from '../shared-offer.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { ProductItem, ProductModel } from '../../product/models/product';
import {
  OfferEditPayload,
  OfferItem,
  Offer_Payment_Type_Enum,
  OfferStausEnum,
  Payment_Status_Enum,
} from '../models/offer';
import { MoneyModel } from 'src/app/models/sales-funnel';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';
import {
  listAnimation,
  slideInOut,
} from 'src/app/shared/animations/animations';
import { Store } from '@ngrx/store';
import { selectCurrentOffer, selectOffersState } from 'src/app/shared/store/_features/offers/offers.selector';

export enum ConstrainsFieldsEnum {
  downloadExpiry = 'downloadExpiry',
  allowedDownloadTimes = 'allowedDownloadTimes',
  expireAt = 'expireAt',
  stock = 'stock',
}

@Component({
  selector: 'app-offer-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    slideInOut,
  ],
})
export class OfferEditComponent
  extends DashboardCanDeactivate
  implements OnInit, OnDestroy
{
  createOfferForm = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    description: new FormControl('', {
      validators: [],
      updateOn: 'change',
    }),
    constraints: new FormGroup({
      expireAt: new FormControl(null),
      stock: new FormControl(null),
    }),
  });

  currentOffer: OfferItem;

  showStatusInfo = false;

  paymentGatewayEnum = Payment_Gateway_Enum;
  offer_Payment_Type_Enum = Offer_Payment_Type_Enum;
  offerStausEnum = OfferStausEnum;
  constrainsFieldsEnum = ConstrainsFieldsEnum;
  minDate: Date = new Date();
  birthDateInput: any;
  product: ProductItem;
  // loaded = true;

  addProduct: boolean = false;

  isFormSet: boolean = false;

  @Input() hideInfoColumn: boolean = false;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private aroute: ActivatedRoute,
    public sharedOfferService: SharedOfferService,
    private toastr: ToastrService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public editorConfig: EditorConfigService,
    public loadingService: ProgressSpinnerService,
    private store: Store
  ) {
    super(sharedOwnerDashboardService);
    this.sharedOfferService.pageStatus = 'edit';
    this.store.select(selectCurrentOffer)
    .subscribe((currentOffer) => {
      if (!currentOffer) return
      this.currentOffer = currentOffer;
      if (!this.isFormSet) {
        this.setOfferToForm(currentOffer);
      }
    });
  
    // const sub = this.aroute.params.subscribe((params) => {
    
    //   this.sharedOfferService.currentOfferId = +params['id']; // (+) converts string 'id' to a number
    // });
  }
  async ngOnInit() {
    this.calculateMaxDate();
    await this.sharedOfferService.getProducts();

    this.trackFormChanges();
  }

  ngOnDestroy(): void {
    this.sharedOfferService.unDetectMissingItems();
  }


  onChangeStatus() {
    this.showStatusInfo = true;
  }
  setOfferToForm(offer: OfferItem = this.sharedOfferService.currentOffer) {
    this.createOfferForm.patchValue(offer, { emitEvent: false });
    if (!!offer.constraints?.expireAt) {
      this.createOfferForm.controls.constraints.controls.expireAt.setValue(
        new Date(offer.constraints.expireAt * 1000),
        { emitEvent: false }
      );
    }
    this.isFormSet = true;
  }

  scrollTo(selector: string, position: ScrollLogicalPosition = 'nearest') {
    (document.querySelector(selector) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }



  getOfferFromForm() {
    let offerPayload: OfferEditPayload = JSON.parse(
      JSON.stringify(this.createOfferForm.value)
    );
    
    offerPayload.constraints.expireAt =
      new Date(offerPayload.constraints.expireAt).getTime() / 1000;

    // [
    //   Payment_Gateway_Enum.btcpay,
    //   Payment_Gateway_Enum.paypal,
    //   Payment_Gateway_Enum.stripe,
    // ].forEach((element) => {
    //   switch (offerPayload.payment_type[element]) {
    //     case Payment_Status_Enum.enabled:
    //       offerPayload.payment_type[element] = true;
    //       break;

    //     case Payment_Status_Enum.disabled:
    //       offerPayload.payment_type[element] = false;
    //       break;
    //     default:
    //       offerPayload.payment_type[element] = undefined;
    //       break;
    //   }
    // });

    return offerPayload;
  }

  async updateOffer() {
    this.createOfferForm.markAllAsTouched();
    if (!this.createOfferForm.valid) {
      this.toastr.error('Check Your Inputs!', '', {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      });
      this.scrollTo('.ng-invalid');
      return false;
    }
    let payload = this.getOfferFromForm();
    
    
    this.sharedOfferService.updateOffer(this.currentOffer.id,this.currentOffer.products[0].id, payload);

    
  }

  

  

  trackFormChanges(): void {
    this.createOfferForm.valueChanges.subscribe((val) => {
      
      this.sharedOfferService.disableNavigate();
    });
  }

  disableAddProductToOffer() {
    this.addProduct = false;
    this.sharedOfferService.syncOfferProducts();
  }

  unSelectProduct(index: number) {
    // this.sharedOfferService.offerEditPayload.included_products.splice(index, 1);
    this.sharedOfferService.syncOfferProducts();
  }

  enableAddProductToOffer() {
    this.sharedOfferService.unDetectMissingItems('products');
    this.addProduct = true;
  }

  deleteProduct(offer: OfferItem) {
    // this.sharedOfferService.deleteOfferAlert(offer, true);
  }

  onExpireAtChange(value: any) {
    let checked = value.currentTarget.checked;
    if (!!checked) {
      if (!!this.currentOffer.constraints.expireAt) {
        this.handleExpirationDateSelect(
          new Date(this.currentOffer.constraints.expireAt * 1000)
        );
      } else {
        this.handleExpirationDateSelect(new Date(Date.now()));
      }
    } else {
      this.handleExpirationDateSelect(null);
    }
  }

  onStockChange(value: any) {
    let checked = value.currentTarget.checked;
    if (!!checked) {
      this.createOfferForm.get('constraints.stock')?.setValue(1);
    } else {
      this.createOfferForm.get('constraints.stock')?.setValue(null);
    }
  }

  handleExpirationDateSelect(date: Date) {
    this.createOfferForm.get('constraints.expireAt')?.setValue(date);
  }

  logTest(value = '') {}

  // onDateChanged(value: any) {
  //   if (!!value) {
  //     let date = new Date(value.toString()).getTime() / 1000;

  //     this.sharedOfferService.offerEditPayload.constraints.expireAt = date;
  //   }
  // }

  calculateMaxDate() {
    this.minDate.setDate(new Date().getTime());
  }

  dateKeypressRestriction() {
    return false;
  }
}
