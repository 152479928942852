import { CreateContactDto } from './dto/create-contact.dto';
import { SharedContactService } from './../shared-contact.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  ContentChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../../shared/services/lsg.api.service';
import { MediaLibraryFileModel } from '../../../../models/sales-funnel';
import { lastValueFrom, take, takeWhile } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OnDestroy, AfterViewInit } from '@angular/core';
import { SharedProductService } from '../../product/shared-product.service';

import { Store } from '@ngrx/store';

 import { ContactItem, ContactItemType } from '../models/contacts';
import { colorSet } from 'src/app/models/global-classes';
import {
  isCreateContactProcessing,
  selectContactsState,
} from 'src/app/shared/store/_features/contacts/contacts.selector';
import { NewContactActions } from 'src/app/shared/store/_features/contacts/contacts.actions';

export interface UiContactType {
  name: string;
  description: string;
  value: ContactItemType;
  iconSvg: string;
  iconFont: string;
  iconColor: colorSet;
}
@Component({
  selector: 'app-contact-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ContactCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  processing: boolean = false;
  // @Input() customAction = false;
  modalRef: NgbModalRef;
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() includeThisProduct: number;
  @Input() setThisPortal: number;

  @Output() onCreateDone = new EventEmitter<ContactItem>();
  // loading: { currencies: boolean; portals: boolean } = {
  //   currencies: true,
  //   portals: true,
  // };

  currentContactType: UiContactType;
  contactsTypes: UiContactType[] = [
    {
      name: 'Instructor',
      description: 'Identifies educators responsible for course content.',
      value: 'instructor',
      iconSvg: '',
      iconFont: '',
      iconColor: 'primary',
    },
    {
      name: 'Lead',
      description:
        'Used in email automation to convert leads to enrolled students.',
      value: 'lead',
      iconSvg: '',
      iconFont: '',
      iconColor: 'danger',
    },
    {
      name: 'Student',
      description:
        'You can add a student and grant him access to specific products.',
      value: 'student',
      iconSvg: '',
      iconFont: '',
      iconColor: 'success',
    },
  ];

  contactType: ContactItemType;

  lockForType: boolean = false;
  @Input('contactType')  set setContactType(value: ContactItemType) {
    this.contactType = value;
    this.lockForType = true;
    // this.createContactForm.patchValue({type: value});
  }

  // Form Variables
  createContactForm = new FormGroup({
    name: new FormControl('', {
      updateOn: 'change',
    }),
    description: new FormControl('', {
      updateOn: 'change',
    }),
    email: new FormControl('', {
      validators: [Validators.email],
      updateOn: 'change',
    }),
    avatar: new FormControl([], {
      updateOn: 'change',
    }),
    avatar_id: new FormControl([], {
      updateOn: 'change',
    }),
    type: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    public sharedContactService: SharedContactService,
    public sharedProductService: SharedProductService,
    private store: Store
  ) {
    this.store.select(isCreateContactProcessing).subscribe((process) => {
      this.processing = process.status == 'creating';
    });

    this.createContactForm.controls.type.valueChanges.subscribe((value) => {
      let currentType = this.contactsTypes.find((a) => a.value === value);
      this.currentContactType = currentType;
    });
    this.createContactForm.valueChanges.subscribe((value) => {

    });

  }
  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {}

  async ngOnInit() {
    // await this.sharedOfferService.getProducts();
  }

  // Function to set up conditional validation

  closeModal() {
    if (this.processing) {
      this.toastr.warning('Wait until processing is finished');
    } else {
      this.modalRef.dismiss();
    }
  }

  resetContactType() {
    this.createContactForm.get('type').patchValue(null);
  }

  openModalProcess() {
    this.open();
  }
  // updatePrice(formGroup: FormGroup) {
  //   this.createContactForm.get('payment_type.price').patchValue(formGroup.value)
  //   // this.createContactForm.get('payment_type.price').setValue(eventValue);
  // }

  async createNewContact() {
    let createPayload: CreateContactDto = new CreateContactDto({
      name: this.createContactForm.value.name ?? undefined,
      description: this.createContactForm.value.description ?? undefined,
      avatar_id: this.createContactForm.value.avatar_id ?? [],
      email: this.createContactForm.value.email ?? undefined,
      type: (this.createContactForm.value.type as ContactItemType) ?? undefined,
    });
    this.createContactForm.markAllAsTouched();

    if (!!this.createContactForm.valid) {
      this.store.dispatch(NewContactActions.loadNewContact({ createPayload }));
      this.store
        .select(selectContactsState)
        .pipe(
          takeWhile(
            (state) =>
              state?.createNewContactProcess.status !== 'done' &&
              state?.createNewContactProcess.status !== 'error',
            true
          )
        )
        .subscribe((state) => {
          switch (state?.createNewContactProcess.status) {
            case 'done':
              this.onContactCreateDone(
                state.createNewContactProcess.lastCreatedContact
              );
              break;
            case 'error':
              this.onContactCreateError(state.createNewContactProcess.error);
              break;
            default:
              break;
          }
        });
    }
  }

  onContactCreateDone = (contact: ContactItem) => {
    // this.route.navigate(['dashboard', 'product']);
    this.closeModal();
    this.onCreateDone.emit(contact);

    this.toastr.success('Done!', 'Contact Added Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  };

  onContactCreateError = (error) => {
    this.toastr.error(error.message);
  };

  resetForm() {
    this.processing = false;
    if (!!this.contactType) {

      this.createContactForm.reset({ type: this.contactType}, { emitEvent: true });
    }
    else {
    this.createContactForm.reset(undefined, { emitEvent: false });
    }
  }

  async afterOpenModal() {
    // await this.sharedOfferService.getProducts();
    // await this.sharedOfferService.getUserPortals();
    // await this.sharedOfferService.getCurrencies();
  }

  async onOpenModal() {
    this.resetForm();
  }
  async open() {
    await this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: true,
      windowClass: 'global-modal-class modal-animate-in add-new-modal',
    });

    this.modalRef.result.then(
      (result) => {
        this.resetForm();
      },
      (reason) => {
        this.resetForm();
      }
    );

    await this.afterOpenModal();
  }
}
