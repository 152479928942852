import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MoneyModel } from 'src/app/models/sales-funnel';

@Component({
  selector: 'app-pricing-slider',
  templateUrl: './pricing-slider.component.html',
  styleUrls: ['./pricing-slider.component.css']
})
export class PricingSliderComponent implements OnInit {
  minValue: number = 1;
  maxValue: number = 1000;

  @Input()  sliderValue: Partial<MoneyModel>  = {
    currency: {
      id: 1,
    },
    value: 20
  };
  @Output() sliderValueChange = new EventEmitter<Partial<MoneyModel>>();
  @Output() sliderPriceValueChange = new EventEmitter<number>();
  @Output() sliderPriceCurrencyChange = new EventEmitter<{id: number}>();
  
  @Input() manually = false;
  @Input() disableSmartMode: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  valueChange(value: number) {
    this.sliderValue.value = value;
    if (value >= this.maxValue * 0.9 && this.maxValue < 150000) {
      this.maxValue = Math.floor(this.maxValue +10)
    }
    this.sliderValueChange.emit(this.sliderValue);
    this.sliderPriceValueChange.emit(this.sliderValue.value)
  }

}
