import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


import { AnnouncementObject } from '../../../../../models/owner-dashboard';
import { EventEmitter, OnDestroy } from '@angular/core';
import { AffiliateApi } from '../../../../../shared/services/affiliate.api.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-affiliate-announcement-item-popup',
  templateUrl: './affiliate-announcement-item-popup.component.html',
  styleUrls: ['./affiliate-announcement-item-popup.component.css'],
})
export class AffiliateAnnouncementPopupComponent implements OnInit, OnDestroy {
  // @Input() refundItem: RefundItem;
  loaded: boolean = false;
  @Input() announcementItem: AnnouncementObject;

  @Input() opener: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private affiliateApi: AffiliateApi,
    private toastr: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.opener.unsubscribe();
  }

  async ngOnInit() {
    this.opener.subscribe((a) => {
      if (a == this.announcementItem.id) {
        this.open();
      }
    });
  }

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in sales-item-modal',
    });
  }

  async getCurrentAnnouncement() {
    const announcement$ = this.affiliateApi.getAnnouncementById(
      this.announcementItem.id
    );
    const announcement = await lastValueFrom(announcement$);
    this.announcementItem = announcement;
  }

  async onOpenModal() {
    this.loaded = false;
    await this.getCurrentAnnouncement();
    this.loaded = true;
  }

  // FileUpload
}
