import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { colorSet } from 'src/app/models/global-classes';
import { listAnimation } from '../../animations/animations';
import { ContactItem } from 'src/app/components/user-dashboard/contacts/models/contacts';

@Component({
  selector: 'app-users-symbols-group',
  templateUrl: './users-symbols-group.component.html',
  styleUrls: ['./users-symbols-group.component.css'],
  animations: [listAnimation]
})
export class UsersSymbolsGroupComponent implements OnInit {
  @Input() maxViewCount = 2;
  @Input() usersList: Partial<ContactItem>[] = [];
  colorSet: colorSet[] = ['primary','info','success','warning','light'];
  @Input() colorless: boolean = false;
  @Input() symbolSize: number = 35;
  
  @Output() onSymbolClick = new EventEmitter<Partial<ContactItem>>();
  @Output() onOthersClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  clickOnOthers() {
    this.onOthersClick.emit();
  }
  clickOnUser(user: Partial<ContactItem>) {
    this.onSymbolClick.emit(user);
  }
}
