import { DomainItem } from './../../../../../models/domain';
import { SharedDomainService } from './../../shared-domain.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../shared/services';
import { MinimizedPortalModel } from '../../../portal/dto/portal.output';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { UserWebsite } from '../../../portal/portal.model';

@Component({
  selector: 'app-domain-portal-settings',
  templateUrl: './domain-portal-settings.component.html',
  styleUrls: ['./domain-portal-settings.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DomainPortalSettingsComponent implements OnInit {
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() domain: DomainItem;

  confirmationRequired = false;
  iConfirm = false;
  domainSettings: {
    isDefault: boolean;
    linkedPortal: MinimizedPortalModel;
  } = {
    isDefault: false,
    linkedPortal: {
      siteId: null,
      portalName: null,
      defaultOffer: null,
      template: null,
    },
  };

  // offerSettingsForm = new FormGroup({
  //   isDefault: new FormControl('', {
  //     updateOn: 'change',
  //   }),
  //   parentPortal: new FormControl('', { updateOn: 'change' }),
  //   iConfirm: new FormControl('', { updateOn: 'change' }),
  // });

  constructor(
    public sharedDomainService: SharedDomainService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    // await this.initializePopup();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  compareWith(item, selected) {
    return +item.siteId === +selected.siteId;
  }

  async initializePopup() {
    this.sharedDomainService.loading.portals = true;
    await this.sharedDomainService.getUserPortals();
    this.domainSettings = {
      isDefault: this.domain.isDefault,
      linkedPortal: this.domain.portalInfo,
    };
    this.sharedDomainService.loading.portals = false;
  }
  async afterOpenModal() {
    await this.initializePopup();
  }

  async updateSettings() {
    if (!!this.domainSettings.linkedPortal) {
      if (!!this.domain.portalInfo) {
        this.onDomainIsAlreadyUsed(
          this.domain,
          this.domainSettings.linkedPortal
        );
        return false;
      }
      await this.sharedDomainService.linkDomainToPortal(
        this.domain,
        this.domainSettings.linkedPortal.siteId,
        this.domainSettings.isDefault
      );
    } else {
      await this.sharedDomainService.unlinkDomainToPortal(
        this.domain,
        this.domain.portalInfo.siteId
      );
    }
    // await this.sharedOfferService.updateOffer(null, 'settings', payload);
    this.closeModal();
  }

  onDomainIsAlreadyUsed(domain: DomainItem, newPortal: MinimizedPortalModel) {
    Swal.fire({
      title: `This Domain is already linked with ${domain.portalInfo.portalName}`,
      html: `The Domain <b>${domain.info.name}.${domain.info.tld}</b> is already used by Portal <b>${domain.portalInfo.portalName}</b>,
      if you confirm the domain will redirect to Portal <b>${newPortal.portalName}</b> instead of it's current portal
      `,
      width: '50%',
      icon: 'warning',
      confirmButtonText: "I'm Sure",
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
    }).then(async (res: SweetAlertResult) => {
      if (res.isConfirmed) {
        await this.sharedDomainService.linkDomainToPortal(
          this.domain,
          this.domainSettings.linkedPortal.siteId,
          this.domainSettings.isDefault
        );
        this.closeModal();
      } else {
        // this.closeModal();
      }
    });
  }

  onChangePortal(value: MinimizedPortalModel) {
    // if (value.siteId != this.sharedOfferService.offer?.parentPortal?.siteId) {
    //   this.offerSettings.isDefault = false;
    // } else {
    //   this.offerSettings.isDefault = this.sharedOfferService.offer.isDefault;
    // }
    // this.confirmationRequired = false;
  }

  onChangeDefault(value: any) {
    // if (
    //   this.offerSettings.parentPortal.siteId !=
    //   this.sharedOfferService.offer?.parentPortal?.siteId
    // ) {
    //   if (!!value) {
    //     this.confirmationRequired = true;
    //   } else {
    //     this.confirmationRequired = false;
    //   }
    // } else {
    //   if (!!this.sharedOfferService.offer.isDefault) {
    //     this.confirmationRequired = false;
    //   } else {
    //     if (!!value) {
    //       this.confirmationRequired = true;
    //     } else {
    //       this.confirmationRequired = false;
    //     }
    //   }
    // }
  }
  async onOpenModal() {
    this.iConfirm = false;
    this.confirmationRequired = false;
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
