// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-container {
  position: relative;
  border-radius: 22px;
}

.template-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 22px 22px 0 0;
}

.template-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 22px;
  background-size: cover;
  background-position: top;
  /* overflow: hidden; */
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/template-item/template-item.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,oBAAoB;EACpB,mBAAmB;EACnB,sBAAsB;EACtB,wBAAwB;EACxB,sBAAsB;EACtB,+LAA+L,EAAE,mDAAmD;AACtP","sourcesContent":[".template-container {\n  position: relative;\n  border-radius: 22px;\n}\n\n.template-info {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  border-radius: 22px 22px 0 0;\n}\n\n.template-image {\n  position: relative;\n  width: 100%;\n  padding-bottom: 100%;\n  border-radius: 22px;\n  background-size: cover;\n  background-position: top;\n  /* overflow: hidden; */\n  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
