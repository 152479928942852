import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { listAnimation } from 'src/app/shared/animations/animations';
import { AiService } from 'src/app/shared/services/ai.service';
import Swal, { SweetAlertResult } from 'sweetalert2';


export class instanceItem {
  prompt: string;
  result?: string;
  startTime?: number;
  endTime?: number;
  elapsedTime?: number;
  timerID?: any;
  status?: 'run' | 'done' | 'error' | 'prepare';
  errorBody?: any;
  tokensCount?: number;
}

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.css'],
  animations: [listAnimation]
})
export class PlaygroundComponent implements OnInit {
  numberOfInstances = 1;
  currentCommand: string = "Generate a crash course outline on the topic 'Data Science: Mastering Machine Learning: Practical Techniques for Coding and Applying Predictive Analytics' The course should consist of four modules + Module 5 Conclusion, each containing three lessons. Please provide only titles without any extra information. Avoid generating any concluding statements or additional text after the requested outline:";
  instances: instanceItem[] = [

  ]
  lastInstanceElapsedTime: number;
  constructor(private ai: AiService, private toastr: ToastrService) {
  }
  ngOnInit(): void {

  }

  async RunInstances(count: number = this.numberOfInstances ) {
    if (!!this.instances.length) {
      Swal.fire(
        {
          title: 'Sure you want to clear the last results?',
          html: 'Running a new testing group will clear your last results',
          showCancelButton: true,
          buttonsStyling: false,
          customClass: {
            title: 'fs-1',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary'
          },
          icon: 'warning',
          preConfirm: ()=> {
            this.instances = [];
          }

        }
      ).then(async (result: SweetAlertResult)=> {
        if (!!result.isConfirmed) {
        if (count > 50) {
          this.toastr.error(`Please, dont try to exceed 50 instances because the web server is in development mode`)
          return false;
        }
        for (let index = 0; index < this.numberOfInstances; index++) {
          await this.generateATitleRequest()
        }
      }
      })
    }
    else {
      if (count > 300) {
        this.toastr.error(`Please, dont try to exceed 15 instances because the web server is in development mode`)
        return false;
      }
      for (let index = 0; index < this.numberOfInstances; index++) {
        await this.generateATitleRequest()
      }
    }

  }
  async generateATitleRequest(command: string = this.currentCommand) {

    const prompt = `${command}`;
    let index = this.createNewInstance(prompt);


      this.startTimer(index);

    this.ai.getCompletion(prompt).subscribe({
      next: (response: string) => {
        this.instances[index].tokensCount++;
        this.instances[index].status = 'run'
        this.instances[index].result += response;
      },
      error: (error) => {
        
        // Handle the error
        this.stopTimer(index);
    this.instances[index].status = 'error'
    this.instances[index].errorBody = error;
      },
      complete: () => {
        
        this.processFinishedInstance(index);

      },
    });
  }

  createNewInstance(prompt: string) {
    let index = this.instances.push({
      prompt,
      status: 'prepare',
      result: '',
      tokensCount: 0
    })
    return index - 1;
  }

  processFinishedInstance(index: number) {
    this.stopTimer(index);
    this.instances[index].status = 'done'
    this.lastInstanceElapsedTime = this.instances[index].elapsedTime
  }


  startTimer(index: number): void {
    this.instances[index].startTime = new Date().getTime();

    this.instances[index].timerID = setInterval(() => {
      const currentTime = new Date().getTime();
      this.instances[index].elapsedTime = currentTime - this.instances[index].startTime;
    }, 100);
  }

  stopTimer(index: number) {
    clearInterval(this.instances[index].timerID);
  }
}
