import { DomainItem } from 'src/app/models/domain';
import { PortalActiveChoiceEnum } from 'src/app/shared/wizard/select-portal/select-portal.component';
import { AiCurrentStateEnum } from '../../product/create/product-create-wizard/product-title-wizard-step/product-title-wizard-step.component';
import { DescriptionEnterStatusEnum } from '../../product/create/product-create-wizard/product-description-image-wizard-step/product-description-image-wizard-step.component';

export class CreateWebsiteInput {
  constructor(
    public portalName: string = '',
    public templateId: number = null,
    public portalTitle?: string,
    public domainId?: number
  ) {}
}

export class DomainsForUserResult {
  public renewalDate: number;
  public createdDate: number;
  public portalName: string;
  public status: string;
  public url: string;
}

export class GlobalWizardSettings {
  portalType: PortalActiveChoiceEnum;
  finalStepLabel: string;
  summaryAnimation: SummaryAnimationEnum;
  // nextButtonText?: string;
  titleAiCurrentState?: AiCurrentStateEnum;
  descriptionAiCurrentState?: DescriptionEnterStatusEnum
}

export enum SummaryAnimationEnum {
  none = 'none',
  portal = 'portal',
}
export class GlobalWizardData {
  portalName?: string;
  templateId?: number;
  funnelId?: number;
  portalId?: number;
  domainId?: number;
  domain?: DomainItem;
  portalIdTemp?: number;
  portalTitle?: string;
}
