import { ProductItem, ProductModel, ProductTypeModel } from '../../../../../components/user-dashboard/product/models/product';
import { Router } from '@angular/router';
import { SharedProductService } from '../../../../../components/user-dashboard/product/shared-product.service';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { OfferItem, Offer_Payment_Type_Enum } from 'src/app/components/user-dashboard/offer/models/offer';
import { SharedOfferService } from 'src/app/components/user-dashboard/offer/shared-offer.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-offer-list-minimal-item',
  templateUrl: './offer-list-minimal-item.component.html',
  styleUrls: ['./offer-list-minimal-item.component.css']
})
export class OfferListMinimalItemComponent implements OnInit {
  offer_Payment_Type_Enum = Offer_Payment_Type_Enum
  productTypeModel = ProductTypeModel

  offer: OfferItem;
  @Input('offer') set setOffer(offer: OfferItem) {
    this.offer = offer;
    this.generateOfferDropdown();
  }
  
  @Input('isOfferDefault') set setIsOfferDefault(isDefault: boolean) {
    // this.offer.isDefault = isDefault;
    // this.generateOfferDropdown();
  }


  @Output() onDeleteDone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloneDone: EventEmitter<OfferItem> = new EventEmitter<OfferItem>();
  @Output() onSetAsDefault: EventEmitter<OfferItem> = new EventEmitter<OfferItem>();
  
  dropdownItems: DropdownItem [] = []
  @Input() disableDefaultRelatedOptions: boolean = false;
  @Input() disableOnClickNavigation: boolean = false;
  onClickRouter: string | any[];

  @Input() isActive: boolean = false;
  // offerProductNameMatches: boolean = false;
  constructor(public sharedProductService: SharedProductService, private router: Router, public sharedOfferService: SharedOfferService, private toastr: ToastrService) { }

  ngOnInit() {
    // 

    // this.offerProductNameMatches = this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name);
    // switch (this.product.type.name) {
    //   case 'course':
    //     this.generateCourseDropdown()
    //     break;
    //     case 'downloadable':
    //     this.generateDownloadableDropdown()
    //     break;
    //   default:
    //     break;
    // }

  }

  get offerProductNameMatches() {
    return this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name)
  }

  generateOfferDropdown() {
    this.onClickRouter = ['/dashboard', 'offer', this.offer.id, 'edit'];
    this.dropdownItems = [
      {
        label: 'Edit Details',
        iconSvg: 'assets/images/svg/system/edit.svg',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        routerLink: ['/dashboard', 'offer', this.offer.id, 'edit']
      },
      {
        label: 'Set as Default',
        iconSvg: 'assets/images/svg/system/offer/default.svg',
        colorSet: 'gray-700',
        colorSetHover: 'info',
        forceHide: !!this.offer.isDefault || !!this.disableDefaultRelatedOptions,
        action : this.setOfferAsDefault,
      },
      {
        label: 'Duplicate',
        iconSvg: 'assets/images/svg/system/copy.svg',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        action : () => {
          this.cloneOffer(this.offer)
        },
      },
      undefined,
      {
        label: 'Delete',
        iconSvg: 'assets/images/svg/system/trash.svg',
        colorSet: 'danger',
        colorSetHover: 'danger',
        
        action: () => {this.deleteOffer(this.offer)}
      }
    ]
  }


  setOfferAsDefault = async (offer: OfferItem = this.offer) => {
    this.sharedOfferService.setOfferAsDefault(offer);
    // this.onSetAsDefault.emit(result.offer);
    
  }

   deleteOffer = async (offer: OfferItem) => {
    if (!!this.offer.isDefault) {
     this.toastr.error('Default offer cannot be deleted', 'Error!');
    }
    else {
    this.sharedOfferService.deleteOfferAlert(offer);
    }
    // if (result.success) {
    //   this.onDeleteDone.emit();
    // }
  }
  cloneOffer = async (offer: OfferItem) => {

    // const offerClone: OfferItem = await 
    this.sharedOfferService.cloneOfferAlert(offer);
    // if (!!offerClone) {
    //   this.onCloneDone.emit(offerClone)
    // }
  }


}
