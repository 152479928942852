// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-menu-link.active {
  background-color: var(--bs-primary-light) !important;
  color: var(--bs-primary) !important;
}


.responsive-grid-template {
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  /* grid-auto-rows: 80px; */
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/main-nav-bar/main-nav-bar.component.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,mCAAmC;AACrC;;;AAGA;EACE,0DAA0D;EAC1D,0BAA0B;AAC5B","sourcesContent":[".sub-menu-link.active {\n  background-color: var(--bs-primary-light) !important;\n  color: var(--bs-primary) !important;\n}\n\n\n.responsive-grid-template {\n  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));\n  /* grid-auto-rows: 80px; */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
