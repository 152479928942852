import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-published-label',
  templateUrl: './published-label.component.html',
  styleUrls: ['./published-label.component.css']
})
export class PublishedLabelComponent implements OnInit {
  @Input() type: 'label' | 'dropdown' = 'label'
  @Input() dropIsActive: boolean;
  @Input() customLabel: string = 'Published'
  constructor() { }

  ngOnInit() {
  }

}
