import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-item',
  templateUrl: './stats-item.component.html',
  styleUrls: ['./stats-item.component.css'],
})
export class StatsItemComponent implements OnInit {
  @Input() title: string;
  @Input() type: 'percent' | 'number' | 'currency' | 'seconds' = 'number';
  @Input() count: number;
  constructor() {}

  ngOnInit(): void {}
}
