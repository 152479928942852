import { Store } from '@ngrx/store';
import { SharedPortalViewService } from '../../../components/user-dashboard/portal/view/shared-portal-view.service';
import { colorSet } from '../../../models/global-classes';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { WebsiteModel } from 'src/app/components/user-dashboard/portal/portal.model';
import { selectWebsite } from '../../store/_features/website/website.selector';

export class DynamicActionButtonItem {
  label: string;
  url?: string;
  routerLink?: string;
  action?: ()=> any = () => {};
  iconSvg?: string;
  buttonSize?: 'sm' | 'lg' | null;
  hiddenButton?: boolean;
  colorSet: colorSet = 'primary';
  colorSetHover?: colorSet = 'primary';
  subItems?: DynamicActionButtonItem[];
  auth?: 'demo' | 'admin' | 'visitor';
}
@Component({
  selector: 'app-dynamic-action-buttons',
  templateUrl: './dynamic-action-buttons.component.html',
  styleUrls: ['./dynamic-action-buttons.component.css'],
})
export class DynamicActionButtonsComponent {
  website$: Observable<WebsiteModel>;
  constructor(private store: Store) {
    this.website$ = this.store.select(selectWebsite);
  }
  @Input() buttonsList: DynamicActionButtonItem[] = [];
}
