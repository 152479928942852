import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil, finalize } from 'rxjs/operators';

@Component({
  selector: 'number-tracker',
  template: `{{ currentNumber }}`
})
export class NumberTrackerComponent implements OnDestroy {
  @Input()
  set end(endRange: number | string) {
    this._counterSub$.next(+endRange);
  }

  @Input() durationInSeconds = 1; // Set the desired duration in seconds
  @Input() countInterval = 20; // Set the time interval in milliseconds between each step
  public currentNumber = 0;
  private _counterSub$ = new Subject<number>();
  private _onDestroy$ = new Subject();

  constructor() {
    let stepValue: number; // Declare stepValue here

    this._counterSub$
      .pipe(
        switchMap((endRange: number) => {
          const diff = endRange - this.currentNumber;
          const steps = (this.durationInSeconds * 1000) / this.countInterval; // Calculate the number of steps based on duration
          stepValue = Math.round(diff / steps); // Set stepValue here (rounded to the nearest integer)
          return timer(0, this.countInterval).pipe(
            takeUntil(this._onDestroy$),
            finalize(() => (this.currentNumber = endRange)), // Ensure the final number is the endRange
            takeUntil(timer(this.countInterval * steps).pipe(takeUntil(this._onDestroy$))),
            takeUntil(this._counterSub$) // Ensure we stop counting if a new end value is provided
          );
        })
      )
      .subscribe(() => (this.currentNumber += stepValue)); // Use stepValue here
  }

  ngOnDestroy() {
    this._onDestroy$.next(0);
    this._onDestroy$.complete();
  }
}
