const ICONS = {
    category_name: 'Finance',
    icons_list: [
      "theta",
      "dollar",
      "binance",
      "nexo",
      "euro",
      "avalanche",
      "bitcoin",
      "wallet",
      "price-tag",
      "finance-calculator",
      "dash",
      "lts",
      "vibe",
      "credit-cart",
      "paypal",
      "bill",
      "ocean",
      "celsius",
      "educare",
      "enjin-coin",
      "two-credit-cart",
      "bank",
      "binance-usd",
      "wanchain",
      "trello",
      "save-deposit",
      "xmr",
      "financial-schedule",
      "office-bag"


    ],

};


export default ICONS
