import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { FunnelItem } from './../../../../models/funnels';

import { SharedFunnelService } from './../../../../components/user-dashboard/funnel/shared-funnel.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  SortingTypeModel,
  FilteringTypeModel,
} from '../../../../models/portal';
import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';

import { DivStateAnimation, disableOutAnimation, listAnimation } from 'src/app/shared/animations/animations';
import { LocalPaginationConfig } from 'src/app/models/owner-dashboard';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-funnels-list',
  templateUrl: './funnels-list.component.html',
  styleUrls: ['./funnels-list.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    listAnimation,
    disableOutAnimation
  ],
})
export class FunnelsListComponent implements OnInit {
  @Output() createFunction: EventEmitter<any> = new EventEmitter<any>();
  @Input() backText: string = 'Funnels';
  // @Input() funnelsList: FunnelItem[] = [];
  @Input() loaded = false;

    // Pagination
    paginationConfig: LocalPaginationConfig = new LocalPaginationConfig({
      currentPage : 1,
      numberInPage : 8,
      startIndex : 0,
      endIndex : 8,
    })


    // Sort And Filtering Features
    searchWord: string = '';
    sortObject: SortingTypeModel = {
      displayName: 'Newest',
      column: 'createdAt',
      order: 'dsc',
    };
    sortTypes: SortingTypeModel[] = [
      { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
      { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
      { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
      { displayName: 'A-Z Title', column: 'name', order: 'asc' },
      { displayName: 'Z-A Title', column: 'name', order: 'dsc' },
    ];


  funnelsList: FunnelItem[] = []

  constructor(
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public sharedFunnelService: SharedFunnelService,
    private router: Router
  ) {}

  @Input('funnelsList') set setFunnelsList(list: FunnelItem[]) {
    this.funnelsList = list;
    this.sharedFunnelService.funnels = list;
  }

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }


  // deleteOffer(offer: OfferItem) {
  //   // this.sharedOfferService.deleteOfferAlert(offer);
  // }

  // cloneOffer(offer: OfferItem) {
  //   // this.sharedOfferService.cloneOfferAlert(offer);
  // }

  editFunnel(id: number) {
    this.sharedOwnerDashboardService.setBackRoute(this.backText);
    this.sharedFunnelService.goToEdit(id);
  }

  goToSubscribersList(id: number) {
    this.sharedOwnerDashboardService.setBackRoute(this.backText);
    this.sharedFunnelService.goToSubscribersList(id);
  }

  // async previewOffer(offer: OfferItem) {
  //   window.open(offer.previewLink, '_blank');
  // }

  goToTutorials() {
    this.router.navigate(['/dashboard/tutorials']);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}


}
