const ICONS = {
    category_name: 'Folders',
    icons_list: [
      "tablet-delete",
      "file-added",
      "file-up",
      "minus-folder",
      "file",
      "delete-files",
      "add-folder",
      "file-left",
      "file-deleted",
      "some-files",
      "file-right",
      "notepad",
      "notepad-bookmark",
      "document",
      "like-folder",
      "folder-up",
      "folder-added",
      "file-down",
      "filter-tablet",
      "tablet-book",
      "update-file",
      "add-notepad",
      "questionnaire-tablet",
      "tablet-up",
      "tablet-ok",
      "update-folder",
      "files-tablet",
      "folder-down",
      "notepad-edit",
      "tablet-text-up",
      "search-list",
      "tablet-text-down",
      "add-files",
      "tablet-down",
      "delete-folder",
      "folder",
      "file-sheet"
    ]


};


export default ICONS
