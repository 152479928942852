import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomCheckBoxCardOption } from 'src/app/models/global-classes';

@Component({
  selector: 'app-dynamic-checkbox-card',
  templateUrl: './dynamic-checkbox-card.component.html',
  styleUrls: ['./dynamic-checkbox-card.component.css']
})
export class DynamicCheckboxCardComponent implements OnInit {
  inputValue: any;
  @Input('inputValue') set setInputValue(inputValue: any) {
    this.inputValue = inputValue;
  }

  @Output() inputValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() option: CustomCheckBoxCardOption;
  @Input() isActive: boolean;

  @Input() formControlInput: any = new FormControl();
  constructor() {}

  ngOnInit() {
    
  }
}
