// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { OfferItem } from '../../../../components/user-dashboard/offer/models/offer';
import { MediaLibraryCategoryEnum, MediaLibraryFileModel, MediaLibraryTypeModel, MediaLibraryUploadType } from '../../../../models/sales-funnel';



export const MediaLibraryActions = createActionGroup({
  source: 'Load Media Library',
  events: {
    load: props<{ category: MediaLibraryCategoryEnum, uploadType: MediaLibraryUploadType | string }>(),
    loadSuccess: props<{ files: MediaLibraryFileModel[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});


// Media Library Types
export const MediaLibraryTypesActions = createActionGroup({
  source: 'Load Media Library Types',
  events: {
    load: props<{ uploadType: MediaLibraryUploadType | string }>(),
    loadSuccess: props<{ types: MediaLibraryTypeModel[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});


export const MediaLibraryQuotaActions = createActionGroup({
  source: 'Load Media Library Quota',
  events: {
    load:emptyProps(),
    loadSuccess: props<{ quota: any }>(),
    loadFailure: props<{ error: any }>(),
  },
});


export const MediaLibraryFileActions = createActionGroup({
  source: ' Delete Media Library File',
  events: {
    delete:props<{ file: MediaLibraryFileModel }>(),
    deleteSuccess: props<{ fileId: number }>(),
    deleteFailure: props<{ fileId: number, error: any }>(),
  },
});

export const updateMediaLibraryFile = createAction('[File] File Updated Successfully', props<{ file: MediaLibraryFileModel }>());
export const addNewMediaLibraryFiles = createAction('[File] File Added Successfully', props<{ files: MediaLibraryFileModel[] }>());




// export const loadMediaLibrary = createAction('[MediaLibrary] Load MediaLibrary', props<{ category: MediaLibraryCategoryEnum, uploadType: MediaLibraryUploadType }>());
// export const loadMediaLibrarySuccess = createAction('[MediaLibrary] Load MediaLibrary Success', props<{ files: MediaLibraryFileModel[] }>());
// export const loadMediaLibraryFailure = createAction('[MediaLibrary] Load MediaLibrary Failure', props<{ error: any }>());
// Define other actions as needed for your feature



// Media Library Types

// export const loadMediaLibraryTypes = createAction('[MediaLibraryTypes] Load MediaLibraryTypes', props<{ uploadType: MediaLibraryUploadType }>());
// export const loadMediaLibraryTypesSuccess = createAction('[MediaLibraryTypes] Load MediaLibraryTypes Success', props<{ types: MediaLibraryTypeModel[] }>());
// export const loadMediaLibraryTypesFailure = createAction('[MediaLibraryTypes] Load MediaLibraryTypes Failure', props<{ error: any }>());


// Media Library Types
// export const loadMediaLibraryQuota = createAction('[MediaLibraryQuota] Load MediaLibraryQuota');
// export const loadMediaLibraryQuotaSuccess = createAction('[MediaLibraryQuota] Load MediaLibraryQuota Success', props<{ quota: any }>());
// export const loadMediaLibraryQuotaFailure = createAction('[MediaLibraryQuota] Load MediaLibraryQuota Failure', props<{ error: any }>());




// export const deleteMediaLibraryFile = createAction('[File] Delete Media Library File', props<{ file: MediaLibraryFileModel }>());
// export const deleteMediaLibraryFileSuccess = createAction('[File] File Deleted Successfully', props<{ fileId: number }>());
// export const deleteMediaLibraryFileFailure = createAction('[File] File Delete Failure', props<{ fileId: number, error: any }>());



