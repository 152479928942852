import { OwnerPayoutSettingsObject } from './../../../models/portal';
import { AffiliateNetworkPayoutSettingsObject } from './../../../models/affiliates-dashboard';

import { EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal from 'sweetalert2';

import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
  
  TotalTrackingData,
  EarningsTrackingData,
  AffiliateLinkEarningsList,
  AffiliateEarningsList,
  EarningsVsCommissions,
  ChartData,
  PayoutStatusEnum,
  PayoutMoreDetailsObject,
  PayoutLogsObject,
  PayoutTotals,
  PayoutLogsList,
  PayoutMoreDetailsList,
  PayoutsExportList,
  OwnerUnpaidPayoutsPerAffiliate,
  OwnerPaidPayoutsPerAffiliate,
} from 'src/app/models/owner-dashboard';

import {
  PeriodResultModel,
  PayoutsExportObject,
} from '../../../models/owner-dashboard';

import { Payment_Gateway_Enum } from 'src/app/models/portal';
import {
  MarkPayoutAsProccessedResponse,
  CreateAnnouncementRequest,
  AnnouncementObject,
} from '../../../models/owner-dashboard';
import { OfferItem, OfferStausEnum } from '../offer/models/offer';
import { SalesLogList } from 'src/app/models/system-earnings';

export enum PageStatusEnum {
  overview = 'dashboard',
  myAffiliates = 'myAffiliates',
  affiliateLinks = 'affiliateLinks',
  earnings = 'earnings',
  payouts = 'payouts',
  announcements = 'announcements',
  messages = 'messages',
  none = 'none',
}

export enum MyAffiliatesPageStatusEnum {
  viewDetails = 'viewDetails',
  earnings = 'earnings',
  payouts = 'payouts',
  announcements = 'announcements',
  none = 'none',
}

export enum AffiliatesLinkPageStatusEnum {
  viewDetails = 'viewDetails',
  editDetails = 'editDetails',
  affiliates = 'affiliates',
  earnings = 'earnings',
  none = 'none',
}

export enum EarningsPageStatusEnum {
  overview = 'overview',
  sales = 'sales',
  network_links = 'network_links',
  network_affiliates = 'network_affiliates',
  earnings = 'earnings',
  demographic = 'demographic',
}

export enum PayoutsPageStatusEnum {
  export = 'export',
  more_details = 'more_details',
  log = 'log',
  settings = 'settings',
}

@Injectable({
  providedIn: 'root',
})
export class SharedAffiliateService {
  totalTrackingData: TotalTrackingData;
  earningsTrackingData: EarningsTrackingData;

  userOffers: OfferItem[] = [];
  loading: {
    offers: boolean;
  } = {
    offers: true,
  };

  affiliates: AffiliateMinimizedModel[] = [];

  affiliateLinks: AffiliateLinkMinimizedModel[] = [];

  earningsAffiliateLinks: AffiliateLinkEarningsList = null;
  earningsAffiliates: AffiliateEarningsList = null;

  earningsOverviewSalesLog: SalesLogList = null;
  earningsSalesLogDetailed: SalesLogList = null;

  payoutsTotals: PayoutTotals;
  payoutsExport: PayoutsExportList = {
    periodsPayouts: [],
  };
  payoutsMoreDetails: PayoutMoreDetailsList = {
    tempID: null,
    payoutMoreDetailsList: [],
    periodInfos: [],
    requestedPeriod: null,
  };
  payoutsLogs: PayoutLogsList = {
    tempID: null,
    periodInfos: [],
    affiliatePayouts: [],
    requestedPeriod: null,
  };
  payoutsSettings: OwnerPayoutSettingsObject = {
    payoutDue: null,
    payoutMethods: [],
  };

  saveEditCheckoutStatus: 'error' | 'loading' | 'none' | 'done' = 'none';

  iframeEmitter: EventEmitter<any> = new EventEmitter<any>();
  affiliateLinkSave: EventEmitter<any> = new EventEmitter<any>();
  currentOfferUrl: string = '';

  loaded = false;
  internalLoaded = false;

  pageStatus: PageStatusEnum;
  myAffiliatesPageStatus: MyAffiliatesPageStatusEnum;
  affiliateLinkPageStatus: AffiliatesLinkPageStatusEnum;
  earningsPageStatus: EarningsPageStatusEnum;
  payoutsPageStatus: PayoutsPageStatusEnum;

  currentAffiliateId: number;
  currentAffiliate: {
    affiliate: AffiliateMinimizedModel;
    linksEarnings: AffiliateLinkEarningsList;
    salesEarnings: SalesLogList;
    earningsVsCommissions: EarningsVsCommissions;
    payoutsMoreDetails: OwnerUnpaidPayoutsPerAffiliate;
    payoutsLogs: OwnerPaidPayoutsPerAffiliate;
    announcementsList: AnnouncementObject[];
  } = {
    affiliate: null,
    linksEarnings: null,
    salesEarnings: null,
    earningsVsCommissions: null,
    payoutsLogs: {
      affiliate: null,
      payouts: [],
    },
    payoutsMoreDetails: {
      affiliate: null,
      payouts: [],
    },
    announcementsList: [],
  };

  currentAffiliateLinkId: number;
  currentAffiliateLink: {
    link: AffiliateLinkMinimizedModel;
    salesEarnings: SalesLogList;
    earningsChart: ChartData;
    totalTrackingData: TotalTrackingData;
    earningsTrackingData: EarningsTrackingData;
  } = {
    link: null,
    salesEarnings: null,
    earningsChart: null,
    totalTrackingData: null,
    earningsTrackingData: null,
  };
  processing: 'loading' | 'done' | 'none' = 'none';

  // settingsStatus: {
  //   payoutMethods: 'error' | 'edited' | 'none';
  // } = {
  //   payoutMethods: 'none',
  // };

  onProfilePeriodTypeSet: EventEmitter<PeriodResultModel> =
    new EventEmitter<PeriodResultModel>();

  onAffiliateLinkPeriodTypeSet: EventEmitter<PeriodResultModel> =
    new EventEmitter<PeriodResultModel>();

  // Announcements
  createAnnouncement: CreateAnnouncementRequest =
    new CreateAnnouncementRequest();

  announcementsList: AnnouncementObject[] = [];

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private sharedDashboardService: SharedOwnerDashboardService
  ) {
    this.getAffiliateLinkErrorHandler =
      this.getAffiliateLinkErrorHandler.bind(this);
    this.getAffiliateErrorHandler = this.getAffiliateErrorHandler.bind(this);
  }

  messageCurrentAffiliate() {
    this.goToThread(
      this.currentAffiliate.affiliate.messengerThreadId,
      this.currentAffiliate.affiliate.id
    );
  }

  goToThread(threadId: number, userId: number) {
    if (!!threadId) {
      this.route.navigate(['dashboard', 'messenger', threadId]);
    } else {
      this.route.navigate(['dashboard', 'messenger', 'u', userId]);
    }
  }

  // Announcement
  async createNewAnnouncement(announcementBody: any) {
    let newAnnouncement$ = this.api.createAnnouncement(
      this.createAnnouncement.getRequestValues()
    );
    let newAnnouncement = await lastValueFrom(newAnnouncement$);

    this.announcementsList.unshift(newAnnouncement);

    
  }

  async getAnnouncementsList() {
    let announcementsList$ = this.api.getMyAnnouncements();
    let announcementsList = await lastValueFrom(announcementsList$);

    this.announcementsList = announcementsList;
  }

  async getAnnouncementsListByAffiliateId(
    id: number = this.currentAffiliateId
  ) {
    let announcementsList$ = this.api.getMyAnnouncementsByAffiliateId(id);
    let announcementsList = await lastValueFrom(announcementsList$);

    this.currentAffiliate.announcementsList = announcementsList;
  }

  // Settings Functions
  async getPayoutsSettingsData() {
    let payoutsSettings$ = this.api.getPayoutsSettingsData();
    let payoutsSettings = await lastValueFrom(payoutsSettings$);
    this.payoutsSettings = payoutsSettings;
  }

  // Payouts Functions
  async getPayoutsLogsData(id: number = null) {
    let payoutsLogs$ = this.api.getPayoutsLogsData(id);
    let payoutsLogs = await lastValueFrom(payoutsLogs$);
    this.payoutsLogs = payoutsLogs;
  }

  async getPayoutsTotalsData() {
    let payoutsTotals$ = this.api.getPayoutsTotalsData();
    let payoutsTotals = await lastValueFrom(payoutsTotals$);
    this.payoutsTotals = payoutsTotals;
  }

  async getPayoutsExportData() {
    let payoutsExport$ = this.api.getPayoutsExportData();
    let payoutsExport = await lastValueFrom(payoutsExport$);
    this.payoutsExport = payoutsExport;
  }

  async exportPayoutsPeriodData(period: PayoutsExportObject) {
    let exportRequest$ = this.api.exportPayoutsPeriodData(period.id);
    let exportRequest = await lastValueFrom(exportRequest$);
  }

  async getPayoutsMoreDetailsDataData(periodId: number = null) {
    let payoutsMoreDetails$ = this.api.getPayoutsMoreDetailsData(periodId);
    let payoutsMoreDetails = await lastValueFrom(payoutsMoreDetails$);
    this.payoutsMoreDetails = payoutsMoreDetails;
  }

  async markPayoutAsProcessed(id: number) {
    let payoutsProcessedResponse$ = this.api.markPayoutAsProcessed(id);
    let payoutsProcessedResponse: MarkPayoutAsProccessedResponse =
      await lastValueFrom(payoutsProcessedResponse$);
    return payoutsProcessedResponse;
  }

  async updateAffiliateNote(id: number, note: string) {
    let updateAffiliateNote$ = this.api.updateAffiliateNote(id, note);
    let updateAffiliateNote: AffiliateMinimizedModel = await lastValueFrom(
      updateAffiliateNote$
    );
    return updateAffiliateNote;
  }

  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing() {
    this.processing = 'done';
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }
  async updateAffiliateLink(
    id,
    affiliateLinkObject: AffiliateLinkMinimizedModel
  ) {
    let updateAffiliateLink$ = this.api.updateAffiliateLinkById(
      id,
      affiliateLinkObject
    );
    let updateAffiliateLink: AffiliateLinkMinimizedModel = await lastValueFrom(
      updateAffiliateLink$
    );
    this.currentAffiliateLink.link = updateAffiliateLink;

    return updateAffiliateLink;
  }

  async getTotalTrackingData() {
    let totalTrackingData$ = this.api.getTotalTracking();
    let totalTrackingData = await lastValueFrom(totalTrackingData$);
    this.totalTrackingData = totalTrackingData;
  }

  async getTotalTrackingByIdData() {
    let totalTrackingData$ = this.api.getTotalTrackingByAffiliateLink(
      this.currentAffiliateLinkId
    );
    let totalTrackingData = await lastValueFrom(totalTrackingData$);
    this.currentAffiliateLink.totalTrackingData = totalTrackingData;
  }

  async getAffiliatesLinkEarningsTotalTracking() {
    let totalTrackingData$ = this.api.getAffiliatesLinkEarningsTotalTracking(
      this.currentAffiliateLinkId
    );
    let totalTrackingData = await lastValueFrom(totalTrackingData$);
    this.currentAffiliateLink.earningsTrackingData = totalTrackingData;
  }

  async getEarningsTrackingData() {
    let earningsTrackingData$ = this.api.getEarningsTrackingData();
    let earningsTrackingData = await lastValueFrom(earningsTrackingData$);
    this.earningsTrackingData = earningsTrackingData;
  }

  startInternalLoading() {
    this.internalLoaded = false;
  }

  stopInternalLoading() {
    this.internalLoaded = true;
  }

  startLoading() {
    this.loaded = false;
  }

  stopLoading() {
    this.loaded = true;
  }
  public async getUserOffers() {
    this.loading.offers = true;
    const res$ = this.api.getOffers(OfferStausEnum.published);
    this.userOffers = await lastValueFrom(res$);
    this.loading.offers = false;
  }

  async resendInvitation(email: string) {
    let affiliateInvitation$ = this.api.resendInvitationToAffiliates({
      emails: [email],
    });
    let affiliateInvitation = await lastValueFrom(affiliateInvitation$);
    return affiliateInvitation;
  }

  async getAffiliateLinkById(id: number) {
    let affiliateLink$ = this.api.getAffiliateLinkById(
      id,
      this.getAffiliateLinkErrorHandler
    );
    let affiliateLink = await lastValueFrom(affiliateLink$);

    this.currentAffiliateLink.link = affiliateLink;
  }

  async createAffiliateLink(affiliateLink: AffiliateLinkMinimizedModel) {
    let request$ = this.api.createAffiliateLink(affiliateLink);
    let response = await lastValueFrom(request$);
  }

  getAffiliateErrorHandler(error: any) {
    Swal.fire({
      icon: 'error',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'bg-primary',
        cancelButton: 'bg-danger',
      },
      confirmButtonText: 'Back to Affiliates',
      showCancelButton: false,
      preConfirm: (result) => {
        this.goToAffiliates();
      },

      title: 'Something went wrong',
      html: `<span>${error.message}</span>`,
    });
  }
  getAffiliateLinkErrorHandler(error: any) {
    Swal.fire({
      icon: 'error',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'bg-primary',
        cancelButton: 'bg-danger',
      },
      confirmButtonText: 'Back to Affiliate Links',
      showCancelButton: false,
      preConfirm: (result) => {
        this.goToAffiliateLinks();
      },

      title: 'Something went wrong',
      html: `<span>${error.message}</span>`,
    });
  }
  goToAffiliateLinks() {
    this.route.navigate(['dashboard', 'affiliate', 'links']);
  }

  goToAffiliates() {
    this.route.navigate(['dashboard', 'affiliate', 'my-affiliates']);
  }

  async goToTab(slug: PageStatusEnum) {
    this.pageStatus = slug;
  }

  async goToMyAffiliatesTab(slug: MyAffiliatesPageStatusEnum) {
    this.myAffiliatesPageStatus = slug;
  }

  async goToAffiliateLinkTab(slug: AffiliatesLinkPageStatusEnum) {
    this.affiliateLinkPageStatus = slug;
  }

  async goToEarningsTab(slug: EarningsPageStatusEnum) {
    this.earningsPageStatus = slug;
  }

  async goToPayoutsTab(slug: PayoutsPageStatusEnum) {
    this.payoutsPageStatus = slug;
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }

  // Api Calls
  async getMyAffiliates(
    status: 'joined' | 'invited' | 'rejected' | 'blocked' = undefined
  ) {
    let affiliatesData$ = this.api.getMyAffiliates(status);
    let affiliatesData = await lastValueFrom(affiliatesData$);
    this.affiliates = affiliatesData;
  }

  async getMyAffiliateLinks(hasAffiliates: boolean = false) {
    let affiliateLinksData$ = this.api.getMyAffiliateLinks(hasAffiliates);
    let affiliateLinksData = await lastValueFrom(affiliateLinksData$);
    this.affiliateLinks = affiliateLinksData;
  }

  async getEarningsAffiliateLinks(period: PeriodResultModel = null) {
    let earningsAffiliateLinksData$ =
      this.api.getEarningsAffiliateLinks(period);
    let earningsAffiliateLinksData: AffiliateLinkEarningsList =
      await lastValueFrom(earningsAffiliateLinksData$);
    this.earningsAffiliateLinks = earningsAffiliateLinksData;
  }

  // Begin: Current Affiliate Data

  async getAffiliateById(id: number) {
    let affiliate$ = this.api.getAffiliateById(
      id,
      this.getAffiliateErrorHandler
    );
    let affiliate = await lastValueFrom(affiliate$);

    this.currentAffiliate.affiliate = affiliate;
  }

  //Payouts
  async getAffiliatePayoutsLog() {
    let dummy = false;
    let affiliatePayoutsLog$ = this.api.getAffiliatePayoutsLog(
      this.currentAffiliateId,
      dummy
    );
    let affiliatePayoutsLog = await lastValueFrom(affiliatePayoutsLog$);

    this.currentAffiliate.payoutsLogs = affiliatePayoutsLog;
  }

  async getAffiliatePayoutsDetailed() {
    let dummy = false;
    let affiliatePayoutsDetailed$ = this.api.getAffiliatePayoutsDetailed(
      this.currentAffiliateId,
      dummy
    );
    let affiliatePayoutsDetailed = await lastValueFrom(
      affiliatePayoutsDetailed$
    );

    this.currentAffiliate.payoutsMoreDetails = affiliatePayoutsDetailed;
  }

  // Earnings

  async getEarningsAffiliateLinksByAffiliateId(
    period: PeriodResultModel = null
  ) {
    let earningsAffiliateLinksData$ =
      this.api.getEarningsAffiliateLinksByAffiliateId(
        this.currentAffiliateId,
        period
      );
    let earningsAffiliateLinksData = await lastValueFrom(
      earningsAffiliateLinksData$
    );

    this.currentAffiliate.linksEarnings = earningsAffiliateLinksData;
  }

  async getAffiliateEarningsSalesLogDetailed(period: PeriodResultModel = null) {
    let affiliateEarningsSalesLogDetailed$ =
      this.api.getAffiliateEarningsSalesLogDetailed(
        this.currentAffiliateId,
        period
      );
    let affiliateEarningsSalesLogDetailed = await lastValueFrom(
      affiliateEarningsSalesLogDetailed$
    );

    this.currentAffiliate.salesEarnings = affiliateEarningsSalesLogDetailed;
  }

  async getAffiliateEarningsVsCommissions(period: PeriodResultModel = null) {
    let affiliateEarningsVsCommissions$ =
      this.api.getAffiliateEarningsVsCommissions(
        this.currentAffiliateId,
        period
      );
    let affiliateEarningsVsCommissions: EarningsVsCommissions =
      await lastValueFrom(affiliateEarningsVsCommissions$);

    this.currentAffiliate.earningsVsCommissions =
      affiliateEarningsVsCommissions;
    this.currentAffiliate.earningsVsCommissions.chartData = [
      ...this.currentAffiliate.earningsVsCommissions.chartData,
    ];
  }

  // Begin: Current Affiliate Data

  async getEarningsAffiliates(period: PeriodResultModel = null) {
    let earningsAffiliatesData$ = this.api.getEarningsAffiliate(period);
    let earningsAffiliatesData: AffiliateEarningsList = await lastValueFrom(
      earningsAffiliatesData$
    );
    this.earningsAffiliates = earningsAffiliatesData;
  }

  async getEarningsSalesLog(period: PeriodResultModel = null) {
    // let earningsSalesLogData$ = this.api.getSystemEarnings();
    // let earningsSalesLogData = await lastValueFrom(earningsSalesLogData$);

    // this.earningsOverviewSalesLog = earningsSalesLogData;
  }

  async getEarningsSalesLogDetailed(period: PeriodResultModel = null) {
    // let earningsSalesLogDetailed$ =
    //   this.api.getEarningsSalesLogDetailed(period);
    // let earningsSalesLogDetailed = await lastValueFrom(
    //   earningsSalesLogDetailed$
    // );

    // this.earningsSalesLogDetailed = earningsSalesLogDetailed;
  }

  async getAffiliateLinkEarningsSalesLogDetailed(
    period: PeriodResultModel = null
  ) {
    let affiliateLinkEarningsSalesLogDetailed$ =
      this.api.getAffiliateLinkEarningsSalesLogDetailed(
        this.currentAffiliateLinkId,
        period
      );
    let affiliateLinkEarningsSalesLogDetailed = await lastValueFrom(
      affiliateLinkEarningsSalesLogDetailed$
    );

    this.currentAffiliateLink.salesEarnings =
      affiliateLinkEarningsSalesLogDetailed;
  }

  async getAffiliateLinkEarningsChart(period: PeriodResultModel = null) {
    let affiliateLinkEarningsChart$ = this.api.getAffiliateLinkEarningsChart(
      this.currentAffiliateLinkId,
      period
    );
    let affiliateLinkEarningsChart: ChartData = await lastValueFrom(
      affiliateLinkEarningsChart$
    );
    this.currentAffiliateLink.earningsChart = affiliateLinkEarningsChart;
    this.currentAffiliateLink.earningsChart.chartData = [
      this.currentAffiliateLink.earningsChart,
    ];
    // this.currentAffiliateLink.earningsChart.series = [...affiliateLinkEarningsChart.series];
  }

  async suspendAffiliateLinkAlert(
    affiliateLink: AffiliateLinkMinimizedModel,
    fromEdit: boolean = false
  ) {
    Swal.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      buttonsStyling: false,
      icon: 'warning',
      width: '650px',
      padding: '20px 70px',
      customClass: {
        title: 'd-block',
        container: 'text-start',
        confirmButton: 'btn btn-lg btn-danger m-1',
        cancelButton: 'btn btn-lg btn-success m-1',
      },
      inputPlaceholder:
        'I confirm that I want to <strong class="text-danger">Suspend</strong> this Affiliate Link.',
      title:
        'Are you sure you want to suspend <strong>' +
        affiliateLink.name +
        '</strong>?',
      html: `Affiliates will no longer be able to sell attached offers using this link, however you and your affiliates will not lose access to it's statistics and reports
<br>
      <b>You may resume a Suspended Affiliate Link anytime</b>.`,
      confirmButtonText: 'Suspend',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const suspendRequest$ = this.api.suspendAffiliateLinkById(
          affiliateLink.id
        );
        const suspendRequest = await lastValueFrom(suspendRequest$);
        if (!fromEdit) {
          await this.getMyAffiliateLinks();
        } else {
          this.currentAffiliateLink.link = suspendRequest;
        }
        this.toastr.success('Done!', 'Affiliate Link Suspended Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return suspendRequest;
        // TODO: Delete Request
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value?.success) {
        if (!!fromEdit) {
          this.goToAffiliateLinks();
        }
      }
    });
    const deleteCheck = Swal.getInput();
    const confirmCheck = Swal.getConfirmButton();
    const cancelButton = Swal.getCancelButton();
    Swal.getIcon().className = 'swal2-icon swal2-error swal2-icon-show';
    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Deleting, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

  async resumeAffiliateLinkAlert(
    affiliateLink: AffiliateLinkMinimizedModel,
    fromEdit: boolean = false
  ) {
    Swal.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      buttonsStyling: false,
      icon: 'success',
      width: '650px',
      padding: '20px 70px',
      customClass: {
        title: 'd-block',
        container: 'text-start',
        cancelButton: 'btn btn-danger m-1',
        confirmButton: 'btn btn-success m-1',
      },
      inputPlaceholder:
        'I confirm that I want to <strong class="text-success">Resume</strong> this Affiliate Link.',
      title:
        'Are you sure you want to resume <strong>' +
        affiliateLink.name +
        '</strong>?',
      html: `Affiliates will be able to sell attached offers using this link
<br>
      <b>You may suspend your Affiliate Link again anytime</b>.`,
      confirmButtonText: 'Resume',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const ResumeRequest$ = this.api.resumeAffiliateLinkById(
          affiliateLink.id
        );
        const ResumeRequest = await lastValueFrom(ResumeRequest$);
        if (!fromEdit) {
          await this.getMyAffiliateLinks();
        } else {
          this.currentAffiliateLink.link = ResumeRequest;
        }
        this.toastr.success('Done!', 'Affiliate Link Resumed Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return ResumeRequest;
        // TODO: Delete Request
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value?.success) {
        if (!!fromEdit) {
          this.goToAffiliateLinks();
        } else {
        }
      }
    });
    const deleteCheck = Swal.getInput();
    const confirmCheck = Swal.getConfirmButton();
    const cancelButton = Swal.getCancelButton();
    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Deleting, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }
}
