// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { RefundItem } from '../../../../models/owner-dashboard';


export const RefundRequestsActions = createActionGroup({
  source: 'Load Refund Requests',
  events: {
    load: emptyProps(),
    loadSuccess: props<{ refundRequests: RefundItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});

// export const loadRefundRequests = createAction('[RefundRequests] Load RefundRequests');
// export const loadRefundRequestsSuccess = createAction('[RefundRequests] Load RefundRequests Success', props<{ refundRequests: RefundItem[] }>());
// export const loadRefundRequestsFailure = createAction('[RefundRequests] Load RefundRequests Failure', props<{ error: any }>());
// Define other actions as needed for your feature

export const updateRefundRequest = createAction('[RefundRequests] Updated RefundRequests Successfully', props<{ itemId: number, item: RefundItem }>());
