import { Title } from '@angular/platform-browser';
import { SharedPortalService } from './../../../components/user-dashboard/portal/shared-portal.service';

import { Component, OnInit } from '@angular/core';
import {
  AffiliateDashboardData,
  TotalTrackingData,
} from 'src/app/models/owner-dashboard';
import { lastValueFrom } from 'rxjs';

import { simpleFadeAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-funnels-layout',
  templateUrl: './funnels-layout.component.html',
  styleUrls: ['./funnels-layout.component.css'],
  animations: [simpleFadeAnimation],
})
export class FunnelsLayoutComponent implements OnInit {
  dashboardData: AffiliateDashboardData;
  // themes: number[] = [1,2,3,4,5]
  constructor(
    public sharedPortalService: SharedPortalService,
    private title: Title
  ) {
    // this.title.setTitle('Choose Your Funnel Template');
  }

  async ngOnInit() {
    this.sharedPortalService.startLoading();
    await this.getTemplateFunnels();
    this.sharedPortalService.stopLoading();
  }

  async getTemplateFunnels() {
    await this.sharedPortalService.getTemplateFunnels();
  }

  async selectFunnelByIndex(index: number) {
    this.sharedPortalService.selectFunnelByIndex(index);
    this.sharedPortalService?.wizardModel?.stickActionButtons();
    if (!!this.sharedPortalService.wizardModel?.currentStep?.step) {
    this.sharedPortalService.wizardModel.currentStep.step.hideButtons.next =
      false;
    }
  }
}
