const ICONS = {
    category_name: 'Technologies',
    icons_list: [
      "tech-wifi",
      "joystick",
      "faceid",
      "technology-3",
      "technology-2",
      "electricity",
      "fingerprint-scanning",
      "technology-4",
      "artificial-intelligence",
      "technology"




    ],

};


export default ICONS
