// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .nav-link {
  color: var(--bs-text-dark) !important;
  font-weight: 400 !important;
}

.nav-link.active {
  color: var(--bs-text-primary) !important;
  font-weight: 600 !important;
} */
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/dynamic-preview-buttons/dynamic-action-buttons.component.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG","sourcesContent":["/* .nav-link {\n  color: var(--bs-text-dark) !important;\n  font-weight: 400 !important;\n}\n\n.nav-link.active {\n  color: var(--bs-text-primary) !important;\n  font-weight: 600 !important;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
