// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results {
  z-index: 107;
    position: absolute;
    margin: 0px;
    top: 50px !important;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/global-search/global-search.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;IACV,kBAAkB;IAClB,WAAW;IACX,oBAAoB;IACpB,WAAW;AACf","sourcesContent":[".search-results {\n  z-index: 107;\n    position: absolute;\n    margin: 0px;\n    top: 50px !important;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
