import { UtilitiesService } from './../../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';

export enum CopyStatusEnum {
  none = 'none',
  copy = 'copy',
  done = 'done',
}
@Component({
  selector: 'app-email-vew-with-copy',
  templateUrl: './email-vew-with-copy.component.html',
  styleUrls: ['./email-vew-with-copy.component.css'],
})
export class EmailVewWithCopyComponent implements OnInit {
  @Input() email: string = '';
  copyStatusEnum = CopyStatusEnum;
  copyStatus: CopyStatusEnum = CopyStatusEnum.none;
  constructor(
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {}

  startCopyProcessing() {
    this.copyStatus = CopyStatusEnum.copy;
  }

  doneCopyProcessing() {
    this.copyStatus = CopyStatusEnum.done;
    setTimeout(() => {
      this.copyStatus = CopyStatusEnum.none;
      this.toastr.success('Done!', 'Email copied to clipboard', {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      });
    }, 600);
  }

  copyText() {
    this.startCopyProcessing();
    this.utilitiesService.copyToClipboard(this.email);
    this.doneCopyProcessing();
  }
}
