import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortSelectedFirst',
})
export class SortSelectedFirstPipe implements PipeTransform {
  transform(items: any[], selectedItems: any[]): any[] {
    if (!items || !selectedItems) {
      return items;
    }

    return items.sort((a, b) => {
      const aSelected = selectedItems.includes(a) || selectedItems.includes(a.id);
      const bSelected = selectedItems.includes(b) || selectedItems.includes(b.id);

      if (aSelected && !bSelected) {
        return -1;
      } else if (!aSelected && bSelected) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
