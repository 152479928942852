import { createReducer, on } from '@ngrx/store';
import {
  CurrentCourseDetailsActions,
  CurrentCourseCategoryActions,
  CurrentCourseInstructorsActions,
} from './course-details.actions';
import { CourseDetailsModelBase } from '../../../../../../components/user-dashboard/product/models/course';
import { SystemProcessLoadingStatus } from '../../../../../../models/global-classes';


export interface CourseDetailsState {
  currentCourseDetails: CourseDetailsModelBase;
  currentCourseDetailsProcess: {
    status: SystemProcessLoadingStatus;
    error: any;
    message?: string;
  };
  error: any;
}

const initialState: CourseDetailsState = {
  currentCourseDetails: {
    categories: [],
    features: [],
    instructors: [],
    extraMetas: {
      duration: null,
      language: null,
      level: null,
    },
  },
  currentCourseDetailsProcess: { status: 'none', error: null },
  error: null,
};

export const courseDetailsReducer = createReducer(
  initialState,
  on(CurrentCourseDetailsActions.load, (state) => ({
    ...state,
    currentCourseDetailsProcess: { status: 'loading', error: null },
  })),
  on(CurrentCourseDetailsActions.loadSuccess, (state, { details }) => ({
    ...state,
    currentCourseDetails: details,
    currentCourseDetailsProcess: { status: 'done', error: null },
  })),
  on(CurrentCourseDetailsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    currentCourseDetailsProcess: {
      status: 'error',
      error: error.message,
    },
  })),
  on(CurrentCourseDetailsActions.updateSuccess, (state, { details }) => ({
    ...state,
    currentCourseDetails: { ...state.currentCourseDetails, ...details },
    currentCourseDetailsProcess: { status: 'done', error: null },
  })),
  on(CurrentCourseCategoryActions.updateSuccess, (state, { details }) => ({
    ...state,
    currentCourseDetails: { ...state.currentCourseDetails, ...details },
    currentCourseDetailsProcess: { status: 'done', error: null },
  })),
  on(CurrentCourseInstructorsActions.updateSuccess, (state, { details }) => ({
    ...state,
    currentCourseDetails: { ...state.currentCourseDetails, ...details },
    currentCourseDetailsProcess: { status: 'done', error: null },
  })),
  on(
    CurrentCourseDetailsActions.update,
    CurrentCourseCategoryActions.update,
    CurrentCourseInstructorsActions.update,
    (state) => ({
      ...state,
      currentCourseDetailsProcess: {
        status: 'updating',
        message: 'Updating',
        error: null,
      },
    })
  )
);
