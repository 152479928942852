import { PeriodBasedLoading } from './../../../models/owner-dashboard';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  DatePeriodObject,
  PeriodResultModel,
  PeriodTypeEnum,
  PeriodTypeModel,
  PeriodUnitEnum,
  PeriodUnitModel,
} from 'src/app/models/owner-dashboard';

@Component({
  selector: 'app-period-date-navigator',
  templateUrl: './period-date-navigator.component.html',
  styleUrls: ['./period-date-navigator.component.css'],
})
export class PeriodDateNavigatorComponent implements OnInit, OnDestroy {
  @Input() enableUnitFilter: boolean = false;
  // Emitters
  @Output() onPeriodChange: EventEmitter<DatePeriodObject> =
    new EventEmitter<DatePeriodObject>();

  @Input() onPeriodTypeSet: EventEmitter<DatePeriodObject> =
    new EventEmitter<DatePeriodObject>();
  onPeriodTypeSetSubscription: Subscription;
  // Input Data
  // Pagination
  @Input() currentPeriod: DatePeriodObject;
  currentIndex = 0;
  @Input() currentId = null;

  _periodsList: DatePeriodObject[] = [];
  @Input() loaded: PeriodBasedLoading = { data: false, navigator: false };

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnDestroy(): void {
    this.onPeriodTypeSetSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.onPeriodTypeSetSubscription = this.onPeriodTypeSet.subscribe(
      (value: PeriodResultModel) => {}
    );
  }

  @Input('periodsList') set periodsList(value: DatePeriodObject[]) {
    this._periodsList = value;
    if (!!this._periodsList.length) {
      if (!!this.currentId) {
        this.goToPeriodById(this.currentId);
      } else {
        this.goToPeriod(this.currentIndex);
      }
    }
  }

  // Pagination

  goToPeriod(periodIndex, emit: boolean = true) {
    if (periodIndex >= 0) {
      this.currentIndex = periodIndex;
      this.currentPeriod = this._periodsList[periodIndex];
      if (!!emit) {
        this.onPeriodChange.emit(this.currentPeriod);
      }
    }
  }

  goToPeriodById(periodId: number) {
    if (!!periodId) {
      let periodIndex = this._periodsList.findIndex((a) => a.id == periodId);
      if (periodIndex > -1) {
        this.goToPeriod(periodIndex);
      } else {
        this.goToPeriod(this.currentIndex);
      }
    }
  }
}
