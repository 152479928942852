import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  timer,
  switchMap,
  Subscription,
  lastValueFrom,
} from 'rxjs';
import {
  SetWebsiteIdentityRequestBody,
  TemplateItem,
  WebsiteIdentity,
} from 'src/app/components/user-dashboard/portal/portal.model';
import { InOutAnimation } from 'src/app/shared/animations/animations';
import { ApiService, AuthenticationService } from 'src/app/shared/services';
import { selectWebsiteIdentity } from 'src/app/shared/store/_features/website/website.selector';

@Component({
  selector: 'app-site-template-step',
  templateUrl: './site-template-step.component.html',
  styleUrls: ['./site-template-step.component.css'],
  animations: [InOutAnimation],
})
export class SiteTemplateStepComponent implements OnInit {
  selectedTemplate: TemplateItem;
  @Output() onDone = new EventEmitter<any>();
  @Output() onSkip = new EventEmitter<any>();

  currentFocus: 'title' | 'description' = null;
  websiteTitleConfigurationForm = new FormGroup({
    title: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
    description: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [],
    }),
  });
  saveEmitter = new EventEmitter<any>();

  @Input() firstName: string;

  userName: string;

  subscriptions: Subscription[] = [];
  identity: WebsiteIdentity;

  loaded: boolean = false;
  constructor(
    private store: Store,
    private api: ApiService,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.subscriptions.push(
      this.store.select(selectWebsiteIdentity).subscribe((value) => {
        
        this.identity = value;
        this.websiteTitleConfigurationForm.patchValue(this.identity);
        // this.websiteTitleConfigurationForm.patchValue(value);
        this.loaded = true;
      })
    );
  }
  ngOnInit() {}

  onSelectTemplate(template: TemplateItem) {
    
    this.selectedTemplate = template;
  }


  // async finishFirstTour() {
  //   const request$ = this.authenticationService.finsihUserTour();
  //   const request = await lastValueFrom(request$);
  // }



  async onSave() {
    // this.toastr.warning('This step is currently inactive and has not been implemented yet.')

    this.onDone.emit();
  }
}
