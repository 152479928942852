import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';



export const getWebsiteMainAnalyticsActions = createActionGroup({
  source: ' Load Website Main Analytics',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ stats: any }>(),
    loadFailure: props<{ error: any }>(),
  },
});