import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { BlogPostItem } from '../../../../../models/blog.model';
import { PaymentGatewaysInformation } from '../../../../../models/portal';



export const websitePaymentGatewaysActions = createActionGroup({
  source: 'Load Website Payment Gateways',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ paymentGateways: PaymentGatewaysInformation }>(),
    loadFailure: props<{ error: any }>(),
  },
});



// export const WebsiteBlogCreateActions = createActionGroup({
//   source: ' Create Website Blog Post',
//   events: {
//     create:props<{ payload: CreateBlogPostDTO }>(),
//     loadSuccess:props<{ post: BlogPostItem }>(),
//     loadFailure: props<{ error: any }>(),
//   },
// });