import { SharedPortalViewService } from './../../../../shared-portal-view.service';
import { BtcpayPaymentGatewayModel } from './../../../../../../../../models/portal';

import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { colorSet } from 'src/app/models/global-classes';
import { environment } from 'src/environments/environment';

export class WizardStep {
  prompt: string;
  description: string;
  beforeAction?: () => void;
  options: { label: string; action: () => void; colorSet: colorSet }[];
  enum: BtcPayWizardStatusEnum;
}

export enum BtcPayWizardStatusEnum {
  doYouHaveAccount = 'doYouHaveAccount',
  createNewAccount = 'createNewAccount',
  doYouHaveStore = 'doYouHaveStore',
  clickToAuthorize = 'clickToAuthorize',
  waiting = 'waiting',
}
@Component({
  selector: 'app-btcpay-special-method',
  templateUrl: './btcpay-special-method.component.html',
  styleUrls: ['./btcpay-special-method.component.css'],
})
export class BtcpaySpecialMethodComponent implements OnInit, OnDestroy {
  btcPayStorage: BtcpayPaymentGatewayModel = new BtcpayPaymentGatewayModel();
  waiting: boolean = false;
  manualInputMode = false;
  btcPayWizardStatusEnum = BtcPayWizardStatusEnum;
  currentStep: WizardStep;
  wizardSteps: WizardStep[];
  btcPayWizardStatus: BtcPayWizardStatusEnum = BtcPayWizardStatusEnum.doYouHaveAccount;
  @Output() GotData: EventEmitter<BtcpayPaymentGatewayModel> =
    new EventEmitter<BtcpayPaymentGatewayModel>();
  constructor(
    
    private sharedPortalViewService: SharedPortalViewService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    if (
      !!this.btcPayStorage?.apiKey &&
      !!this.btcPayStorage?.storeId &&
      !!this.btcPayStorage.btcPayUrl
    ) {
      localStorage.removeItem('btcPayData');
    }
  }

  ngOnInit(): void {
    this.initializeWizard();
    let btcPayStorage: BtcpayPaymentGatewayModel = JSON.parse(
      localStorage.getItem('btcPayData')
    );

    if (
      !!btcPayStorage?.apiKey &&
      !!btcPayStorage?.storeId &&
      !!btcPayStorage.btcPayUrl
    ) {
      this.btcPayStorage = btcPayStorage;
      this.GotData.emit(this.btcPayStorage);
    } else {
      this.btcPayStorage.btcPayUrl =
        environment.btcPayServerURL;
      this.btcPayStorage.returnURL = this.router.url;
      this.updateLocalStorage();
    }
  }

  initializeWizard() {
    this.wizardSteps = [
      {
        prompt: 'Do you have a BTCPay account?',
        description: 'A BTCPay account is required for integration',
        options: [
          {
            label: 'Create new account',
            action: () => {
              this.goToStepByEnum(BtcPayWizardStatusEnum.createNewAccount);
              setTimeout(() => {
                this.createAnAccount();
              }, 500);
            },
            colorSet: 'primary'
          },
          {
            label: 'I already have an account',
            action: () => {
              this.goToStepByEnum(BtcPayWizardStatusEnum.doYouHaveStore);
            },
            colorSet: 'light'
          },
        ],
        enum: BtcPayWizardStatusEnum.doYouHaveAccount,
      },
      {
        prompt: 'Have you completed your BTCPay registration?',
        description: `I'll be waiting here until you create your account`,
        options: [
          {
            label: 'Done, Created my account',
            action: () => {
              this.goToStepByEnum(BtcPayWizardStatusEnum.doYouHaveStore);
            },
            colorSet: 'success'
          },
        ],
        enum: BtcPayWizardStatusEnum.createNewAccount,
      },
      {
        prompt: 'You need to establish a Store Entity in BTCPay now',
        description: `Creating a Store Entity and connecting a wallet in BTCPay is a requirement to begin receiving cryptocurrency payments.`,
        options: [
          {
            label: 'Create a new store',
            action: () => {
              this.createStore();
              setTimeout(() => {
                this.goToStepByEnum(BtcPayWizardStatusEnum.clickToAuthorize);  
              }, 500);
              
            },
            colorSet: 'primary'
          },
          {
            label: 'I already have a store',
            action: () => {
              this.goToStepByEnum(BtcPayWizardStatusEnum.clickToAuthorize);
            },
            colorSet: 'light'
          },
        ],
        enum: BtcPayWizardStatusEnum.doYouHaveStore,
      },
      {
        prompt: 'Authorize Learmo for BTCPay Integration',
        description: `You should authorize Learmo to link your website with your BTCPay store.`,
        options: [
          {
            label: 'Authorize Learmo',
            action: () => {
              this.authorizeLearmo();
            },
            colorSet: 'primary'
          },
        ],
        enum: BtcPayWizardStatusEnum.clickToAuthorize,
      },
    ];

    this.goToStepByIndex(0);
  }

  goToStepByEnum(value: BtcPayWizardStatusEnum) {
    let step = this.wizardSteps.find((step) => step.enum == value);
    if (!!step) {
      this.goToStep(step);
    }
  }

  goToStepByIndex(index: number) {
    if (!!this.wizardSteps[index]) {
      this.goToStep(this.wizardSteps[index]);
    }
  }

  goToStep(step: WizardStep) {
    this.currentStep = step;
  }

  updateLocalStorage() {
    localStorage.setItem('btcPayData', JSON.stringify(this.btcPayStorage));
  }

  setupWalletToStore() {
    // this.btcPayWizardStatus = BtcPayWizardStatusEnum.waiting;
    window.open(
      `${this.btcPayStorage.btcPayUrl}/stores/${this.btcPayStorage.storeId}/onchain/BTC`,
      '_blank'
    );
  }

  createAnAccount() {    // this.btcPayWizardStatus = BtcPayWizardStatusEnum.waiting;
    window.open(
      `${environment.btcPayServerURL}/register`,
      '_blank'
    );
  }

  createStore() {
    // this.btcPayWizardStatus = BtcPayWizardStatusEnum.waiting;
    window.open(
      `${this.btcPayStorage.btcPayUrl}/stores/create/?redirect=` +
      encodeURIComponent(
        `${environment.api.url}/${environment.btcPayRedirectPOSTIntegrationURL}`
      ));
  }

  authorizeLearmo() {
    // this.btcPayWizardStatus = BtcPayWizardStatusEnum.waiting;
    document.location.href =
      `${this.btcPayStorage.btcPayUrl}/api-keys/authorize?permissions=btcpay.store.canviewinvoices&permissions=btcpay.store.cancreateinvoice&permissions=btcpay.store.webhooks.canmodifywebhooks&permissions=btcpay.store.canviewstoresettings&permissions=btcpay.store.canmodifyinvoices&applicationName=learmo&strict=true&selectiveStores=true&redirect=` +
      encodeURIComponent(
        `${environment.api.url}/${environment.btcPayRedirectPOSTIntegrationURL}`
      );
  }

  scrollTo(
    elementID: string,
    position: ScrollLogicalPosition = 'nearest',
    timeout: number = 0
  ) {
    setTimeout(() => {
      (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
        behavior: 'smooth',
        block: position,
      });
    }, timeout);
  }
}
