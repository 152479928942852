// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guidance-layer {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
}

.guidance-layer .pointer-item {
  position: absolute;
}

.guidance-layer.active {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.guidance-layer.active .pointer-item {
  transition: all 1s ease-in-out !important;
  z-index: 500;
  padding: 20px;
}

.guidance-layer.active .pointer-item.icon-of-pointer svg {
  transition: transform 0.3s ease-in-out !important;
  transform: scale(2);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/pointer-guide/pointer-guide.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yCAAyC;EACzC,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iDAAiD;EACjD,mBAAmB;AACrB","sourcesContent":[".guidance-layer {\n  display: none;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.guidance-layer .pointer-item {\n  position: absolute;\n}\n\n.guidance-layer.active {\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 9999999;\n}\n\n.guidance-layer.active .pointer-item {\n  transition: all 1s ease-in-out !important;\n  z-index: 500;\n  padding: 20px;\n}\n\n.guidance-layer.active .pointer-item.icon-of-pointer svg {\n  transition: transform 0.3s ease-in-out !important;\n  transform: scale(2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
