import { AppwriteService } from '../../../shared/services/appwrite.sdk.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService, ApiService } from '../../../shared/services';

import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';

import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';


import { PortalControlService } from '../../../shared/services/portal-control.service';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.4s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit {

  // countries: Country[] = [];
  applicationData;
  constructor(

    private readonly authService: AuthenticationService,
    private readonly api: ApiService,
    private readonly router: Router,
    public loadingService: ProgressSpinnerService,
    private portalControlService: PortalControlService
  ) {}

  async ngOnInit() {
    console.log("------- Login Page ----------")
    

    

    // await this.goToSigninPage();
    
    // this.authService.logout();
    // this.api.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });
  }
  

  // async goToSigninPage() {
  //   let url = await this.getSigninUrl();
  //   location.href = url;
  //   
  // }

}
