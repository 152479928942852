// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-link.active, .active > .page-link {
  font-size: 140% !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/lists-parts/pagination-unit/pagination-unit.component.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":[".page-link.active, .active > .page-link {\n  font-size: 140% !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
