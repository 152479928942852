import { Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { UploadProgressFile } from 'src/app/models/owner-dashboard';
import {
  MediaLibraryFileModel,
  MediaLibraryUploadResponseModel,
} from 'src/app/models/sales-funnel';
import { ApiService } from '../../../services/lsg.api.service';

@Component({
  selector: 'app-messenger-attachment-component',
  templateUrl: './attachment-component.component.html',
  styleUrls: ['./attachment-component.component.css'],
})
export class AttachmentComponentComponent implements OnInit, OnDestroy {
  @Input() uploadType: 'any' | 'content' | 'media' | 'video' | 'image' =
    'media';
  @Output() selectedFilesChange: EventEmitter<MediaLibraryFileModel[]> =
    new EventEmitter<MediaLibraryFileModel[]>();

  @Output() selectedFilesIdsChange: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Input() selectedFiles: MediaLibraryFileModel[] = [];
  @Input() selectedFilesIds: number[] = [];

  @Input() resetUploader: EventEmitter<any> = new EventEmitter<any>();
  @Input() removeFile: EventEmitter<any> = new EventEmitter<any>();

  multiFiles: boolean = true;
  formData = new FormData();
  reader = new FileReader();
  uploadToast: any;
  uploadForm = new UntypedFormGroup({
    files: new UntypedFormControl('', { updateOn: 'blur' }),
  });

  maxFileSize: number = 20580;

  acceptedImages = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ];
  acceptedVideos = [
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'application/ogg',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
  ];

  // UI Variables
  @Output() uploadingFilesChange: EventEmitter<UploadProgressFile[]> =
    new EventEmitter<UploadProgressFile[]>();
  uploadingFiles: UploadProgressFile[] = [];

  resetUploaderSubscription: Subscription;
  removeFileSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private _httpClient: HttpClient,
    private api: ApiService
  ) {}
  ngOnDestroy(): void {
    this.removeFileSubscription.unsubscribe();
    this.resetUploaderSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.resetUploaderSubscription = this.resetUploader.subscribe(
      (event: any) => {
        this.onResetUploader();
      }
    );

    this.removeFileSubscription = this.removeFile.subscribe((id: number) => {
      this.onRemoveClick(id);
    });
  }

  onRemoveClick(id: number) {
    let fileIndex = this.selectedFiles.findIndex((a) => a.id == id);
    let fileIdIndex = this.selectedFilesIds.findIndex((a) => a == id);
    let mediasIndex = this.uploadingFiles.findIndex((a) => a.id == id);

    if (fileIndex != -1) {
      this.selectedFiles.splice(fileIndex, 1);
    }
    if (fileIdIndex != -1) {
      this.selectedFilesIds.splice(fileIdIndex, 1);
    }

    if (mediasIndex != -1) {
      this.uploadingFiles.splice(mediasIndex, 1);
    }
    this.emitAllFiles();
    this.emitFilesProgress();
  }

  onResetUploader() {
    this.selectedFiles = [];
    this.selectedFilesIds = [];
    this.uploadingFiles = [];
    this.emitAllFiles();
    this.emitFilesProgress();
  }
  // addUploadedFile(file: MediaLibraryFileModel) {
  //     this.addFileSingleFile(file);
  // }

  addFileSingleFile(file: MediaLibraryFileModel) {
    this.selectedFiles.push(file);
    this.selectedFilesIds.push(file.id);
    this.emitAllFiles();
  }

  replaceSingleFile(file: MediaLibraryFileModel) {
    this.selectedFiles = [file];
    this.selectedFilesIds = [file.id];
    this.emitAllFiles();
  }

  resetData() {
    this.formData.delete('files');
    this.uploadForm.get('files').setValue('');
  }

  emitAllFiles() {
    this.selectedFilesChange.emit(this.selectedFiles);
    this.selectedFilesIdsChange.emit(this.selectedFilesIds);
  }

  emitFilesProgress() {
    this.uploadingFilesChange.emit(this.uploadingFiles);
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const files = (event.target as HTMLInputElement).files;
      this.onUpload(files);
    }
    this.resetData();
  }

  async onUpload(files: FileList) {
    let filesCount = 0;
    let preview = null;
    for (let index = 0; index < files.length; index++) {
      if (this.maxFileSize >= files[index].size / 1024) {
        if (
          (this.uploadType === 'image' &&
            this.acceptedImages.includes(files[index].type)) ||
          (this.uploadType === 'video' &&
            this.acceptedVideos.includes(files[index].type)) ||
          (this.uploadType === 'media' &&
            (this.acceptedImages.includes(files[index].type) ||
              this.acceptedVideos.includes(files[index].type))) ||
          this.uploadType === 'any' ||
          this.uploadType === 'content'
        ) {
          let formData = new FormData();
          formData.append('files', files[index]);

          filesCount++;

          let upIndex = this.uploadingFiles.push({
            name: files[index].name,
            size: files[index].size,
            extension: files[index].name.split('.').pop(),
          });

          const reader = new FileReader();
          reader.onload = () => {
            this.uploadingFiles[upIndex - 1].preview = reader.result as string;
            this.uploadFile(formData, upIndex - 1);
          };
          reader.readAsDataURL(files[index]);
        } else {
          this.toastr.error(
            files[index].name,
            `Your file's extension is not accepted`,
            {
              closeButton: false,
              positionClass: 'toast-bottom-right',
            }
          );
        }
      } else {
        this.toastr.error(
          files[index].name,
          `Your file is larger than ${Math.round(this.maxFileSize / 1024)}MB`,
          {
            closeButton: false,
            positionClass: 'toast-bottom-right',
          }
        );
      }
    }
  }

  async uploadFile(formData: FormData, index: number) {
    const req$ = this._httpClient
      .post(`${this.api.apiUrl}/messenger/media`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.uploadingFiles[index].percentage =
              percentDone.toString() + '%';

            if (percentDone === 100) {
              this.uploadingFiles[index].done = true;
            }
          } else if (event.type == HttpEventType.Response) {
            if (
              (event.body as MediaLibraryUploadResponseModel).refusedFiles
                .length > 0
            ) {
              this.uploadingFiles[index].error = {
                message: (event.body as MediaLibraryUploadResponseModel)
                  .refusedFiles[0].reason,
                status: true,
              };
              this.uploadingFiles[index].done = false;
              this.emitFilesProgress();
              this.toastr.error(
                this.uploadingFiles[index].name,
                this.uploadingFiles[index].error.message,
                {
                  closeButton: false,
                  positionClass: 'toast-bottom-right',
                }
              );
              setTimeout(() => {
                this.uploadingFiles[index] = {
                  name: null,
                  percentage: null,
                  size: null,
                };
              }, 5000);
              this.uploadingFiles.splice(index, 1);
            } else {
              // this.uploadingFiles[index] = {
              //   name: null,
              //   percentage: null,
              //   size: null,
              // };

              if (!!this.multiFiles) {
                (
                  event.body as MediaLibraryUploadResponseModel
                ).uploadedFiles.forEach((image) => {
                  this.addFileSingleFile(image);
                  this.uploadingFiles[index].id = image.id;
                });
              } else {
                this.replaceSingleFile(
                  (event.body as MediaLibraryUploadResponseModel)
                    .uploadedFiles[0]
                );
                this.uploadingFiles[index].id = (
                  event.body as MediaLibraryUploadResponseModel
                ).uploadedFiles[0].id;
              }
            }
          }

          this.emitFilesProgress();
        },
        (error: any) => {
          this.uploadingFiles[index].error = {
            status: true,
            message: '',
          };
          setTimeout(() => {
            this.uploadingFiles[index] = {
              name: null,
              percentage: null,
              size: null,
            };
          }, 5000);
        }
      );
  }
}
