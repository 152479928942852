import { NotificationElement } from '../../../../models/owner-dashboard';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Plan } from '../../../../models/plans';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';


// Actions for loading websites
export const SystemPlansActions = createActionGroup({
  source: 'Load System Plans',
  events: {
    load:emptyProps(),
    loadSuccess: props<{ plans: Plan[]}>(),
    loadFailure: props<{ error: any }>(),
  },
});

export const CurrentUserSubscriptionActions = createActionGroup({
  source: 'Load Current User Subscription',
  events: {
    load:emptyProps(),
    loadSuccess: props<{ currentUserSubscriptionInfo: SubscriptionBillingInfo}>(),
    loadFailure: props<{ error: any }>(),
  },
});





// Actions for updating identity
// export const newNotifications = createAction('[Notifications] New Notifications Success', props<{ notification: NotificationElement }>());
// export const setNotificationAsRead = createAction('[Notifications] Set Notifications as Read', props<{ notificationsIds: number[] }>());
// export const setNotificationAsReadSuccess = createAction('[Notifications] Set Notifications as Read Success', props<{ notificationsIds: number[] }>());


// Actions for loading websites
// export const loadNotifications = createAction('[Notifications] Load Notifications');
// export const loadNotificationsSuccess = createAction('[Notifications] Load Notifications Success', props<{ notifications: NotificationElement[], unreadNotificationsCount: number }>());
// export const loadNotificationsFailure = createAction('[Notifications] Load Notifications Failure', props<{ error: any }>());


