import { SharedFunnelService } from './../../../../../components/user-dashboard/funnel/shared-funnel.service';
import {
  FormSubscriber,
  FormSubscriberStatusEnum,
} from './../../../../../models/funnels-subscribers';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscriber-item-popup',
  templateUrl: './subscriber-item-popup.component.html',
  styleUrls: ['./subscriber-item-popup.component.css'],
})
export class SubscriberItemPopupComponent implements OnInit {
  formSubscriberStatusEnum = FormSubscriberStatusEnum;
  @Input() subscriberItem: FormSubscriber;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    public sharedFunnelService: SharedFunnelService,
    private modalService: NgbModal,
    private route: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {}

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in sales-item-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  // FileUpload
}
