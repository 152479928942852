import { SystemProcessLoadingStatus, SystemTypedError } from './../../../../../../models/global-classes';
// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';

import { UpdateCourseItemActions, CourseItemStatusActions, DeleteCourseItemActions, ChangeItemProcessStatus, loadCourseItemFullDetails } from './course-items.actions';
import { CourseHierarchyItem, CourseHierarchyItemTypeEnum } from '../../../../../../components/user-dashboard/product/models/course';

// export interface CourseHeirarchyState extends CourseHeirarchyModelBase, EntityState<CourseHierarchyItem> {}
// extends EntityState<ProductItem> {

export interface CourseItemProcessChangePayload {
  itemId: number;
  itemType: CourseHierarchyItemTypeEnum;
  status?: SystemProcessLoadingStatus;
}
export interface CourseItemState {
  currentCourseItem: CourseHierarchyItem;
  CourseItemsProcess: {
    id: number;
    itemType: CourseHierarchyItemTypeEnum;
    status: SystemProcessLoadingStatus;
    error?: SystemTypedError;
    message?: string;
  }[];

}

// export const courseAdapter = createEntityAdapter<ProductItem>();
// export const courseHeirarchyAdapter = createEntityAdapter<CourseHierarchyItem>();

const initialState: CourseItemState = {
  currentCourseItem: null,
  CourseItemsProcess: [],
};

export const courseItemReducer = createReducer(
  initialState,

  
  on(loadCourseItemFullDetails.success, (state, {loadedItem}) => {

    return {
      ...state,
      currentCourseItem: loadedItem
    }
  }),


 



  // Generic Item Status Update
  on(ChangeItemProcessStatus, (state, { statusChange, error  }) => {

    const {itemId, itemType, status} = statusChange;
    
    let itemStatusUpdateProcess =
      state.CourseItemsProcess.find(
        (proc) => proc.id == itemId && proc.itemType == itemType
      );

    if (!itemStatusUpdateProcess) {
      return {
        ...state,
        CourseItemsProcess: [
          ...state.CourseItemsProcess,
          {
            id: itemId,
            itemType,
            status: status,
            message: 'Updating Status',
            error: error
          },
        ],
      };
    } else {
      return {
        ...state,
        CourseItemsProcess: [
          ...state.CourseItemsProcess.filter(
            (proc) => proc.id != itemId || proc.itemType != itemType
          ),
          {
            id: itemId,
            itemType,
            status: status,
            message: 'Updaing Status',
            error: error
          },
        ],
      };
    }
  }),

  
  
);
