
// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { NEVER, of } from 'rxjs';

import {
  CurrentCourseCategoryActions,
  CurrentCourseDetailsActions,
  CurrentCourseInstructorsActions,
} from './course-details.actions';

import { Store } from '@ngrx/store';
import { ApiService } from '../../../../../services';
import { CourseExtraMetaModel } from '../../../../../../components/user-dashboard/product/models/course';

@Injectable()
export class CourseDetailsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}



  loadCurrentCourseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseDetailsActions.load), // Listen for the loadProducts action
      mergeMap(({ productId, errorHandler = undefined }) =>
        this.api.getCourseDetailsById(productId, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CurrentCourseDetailsActions.loadSuccess({ details: response })
          ),
          catchError((error) =>
            of(CurrentCourseDetailsActions.loadFailure({ error }))
          )
        )
      )
    )
  );



  updateCurrentCourseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseDetailsActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) =>{
       return this.api.updateCourseExtraMetaById(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: CourseExtraMetaModel) =>
            CurrentCourseDetailsActions.updateSuccess({ details: {extraMetas: response} })
          ),
          catchError((error) =>
            of(CurrentCourseDetailsActions.updateFailure({ error }))
          )
        )
      }
      )
    )
  );


  updateCurrentCourseCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseCategoryActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) =>{
       return this.api.updateCourseCategoryById(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CurrentCourseCategoryActions.updateSuccess({ details: {categories: response} })
          ),
          catchError((error) =>
            of(CurrentCourseCategoryActions.updateFailure({ error }))
          )
        )
      }
      )
    )
  );

  updateCurrentCourseInstructors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseInstructorsActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) =>{
       return this.api.updateCourseInstructorsById(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CurrentCourseInstructorsActions.updateSuccess({ details: {instructors: response} })
          ),
          catchError((error) =>
            of(CurrentCourseInstructorsActions.updateFailure({ error }))
          )
        )
      }
      )
    )
  );


}
