export function specialFilter(
  items: any[],
  filters: { column: string, value: any }[] = [],
  exclusions: { column: string, value: any }[] = []
): any[] {
  if (!items) { return []; }

  // Filter out excluded items
  items = items.filter(item =>
    exclusions.every(exclusion =>
      resolve(item, exclusion.column)?.toString().toLowerCase() !== exclusion.value.toString().toLowerCase()
    )
  );

  // Apply filters
  return items.filter((item) =>
    filters?.every(filter => {
      const filterValue = filter.value.toString().toLowerCase();
      const itemValue = resolve(item, filter.column)?.toString().toLowerCase();
      return itemValue === filterValue;
    })
  );
}

function resolve(obj, path) {
  path = path.split('.');
  let current = obj;
  while (path.length) {
    if (typeof current !== 'object') { return undefined; }
    current = current[path.shift()];
  }
  return current;
}
