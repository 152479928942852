// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.browser {
  width: 1200px;
  /* height: 350px; */
  overflow: hidden;
  border-radius: 10px;
  /* box-shadow: 0px 20px 25px 10px rgba(0, 0, 0, .4); */
  margin: auto
}

.toolbar {
  /* background-color: #E6E6E6; */
  width: auto;
  height: 35px;
  border-radius: 10px 10px 0 0;
}

.tab {
  border-bottom: 15px solid #E6E6E6;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-radius: 5px 5px 0 0;
  height: 23px;
  width: 110px;
  display: inline-block;
}

.tabbar {
background-color: #E6E6E6;
width: auto;
height: 23px;
}

.btn {
width: 10px;
height: 10px;
border-radius: 15px;
float: left;
/* margin: 8px 5px 0 0; */
box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, .05);
}
.btn:first-of-type {
margin-left: 10px;
}

.close {
  background-color: #E06752;
}

.min {
  background-color:  #ecc343;
}

.max {
  background-color: #57d08d;
}

.viewport {
  background-color: #fff;
  width: auto;

  /* height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #CBCBCB;
  border-radius: 0 0 10px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/product/create/product-create-wizard/product-type-wizard-step/product-type-preview/product-type-preview.component.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,sDAAsD;EACtD;AACF;;AAEA;EACE,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,iCAAiC;EACjC,mCAAmC;EACnC,oCAAoC;EACpC,0BAA0B;EAC1B,YAAY;EACZ,YAAY;EACZ,qBAAqB;AACvB;;AAEA;AACA,yBAAyB;AACzB,WAAW;AACX,YAAY;AACZ;;AAEA;AACA,WAAW;AACX,YAAY;AACZ,mBAAmB;AACnB,WAAW;AACX,yBAAyB;AACzB,8CAA8C;AAC9C;AACA;AACA,iBAAiB;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,WAAW;;EAEX,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;EAC7B,4BAA4B;AAC9B","sourcesContent":["\n.browser {\n  width: 1200px;\n  /* height: 350px; */\n  overflow: hidden;\n  border-radius: 10px;\n  /* box-shadow: 0px 20px 25px 10px rgba(0, 0, 0, .4); */\n  margin: auto\n}\n\n.toolbar {\n  /* background-color: #E6E6E6; */\n  width: auto;\n  height: 35px;\n  border-radius: 10px 10px 0 0;\n}\n\n.tab {\n  border-bottom: 15px solid #E6E6E6;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-radius: 5px 5px 0 0;\n  height: 23px;\n  width: 110px;\n  display: inline-block;\n}\n\n.tabbar {\nbackground-color: #E6E6E6;\nwidth: auto;\nheight: 23px;\n}\n\n.btn {\nwidth: 10px;\nheight: 10px;\nborder-radius: 15px;\nfloat: left;\n/* margin: 8px 5px 0 0; */\nbox-shadow: 0px 2px 2px 1px rgba(0, 0, 0, .05);\n}\n.btn:first-of-type {\nmargin-left: 10px;\n}\n\n.close {\n  background-color: #E06752;\n}\n\n.min {\n  background-color:  #ecc343;\n}\n\n.max {\n  background-color: #57d08d;\n}\n\n.viewport {\n  background-color: #fff;\n  width: auto;\n\n  /* height: 300px; */\n  overflow-y: auto;\n  overflow-x: hidden;\n  border-top: 1px solid #CBCBCB;\n  border-radius: 0 0 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
