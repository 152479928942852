import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[horizontalScroll]'
})
export class HorizontalScrollDirective {
  mouseDown = false;

  startX: any;

  scrollLeft: any;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {

   }

  @HostListener('wheel', ['$event'])
  onScroll(event: WheelEvent) {
    const el = this.elRef.nativeElement as HTMLElement;
    
    
    if (event.deltaY > 0) {
      el.scrollLeft += 100;
      event.preventDefault();
// prevenDefault() will help avoid worrisome
// inclusion of vertical scroll
    }
    else {
      el.scrollLeft -= 100;
      event.preventDefault();
    }
  }

  @HostListener('mousedown', ['$event'])
  startDragging(e) {
    const el = this.elRef.nativeElement as HTMLElement;


    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }

  @HostListener('mouseup', ['$event'])
  stopDragging(e, flag) {
    const el = this.elRef.nativeElement as HTMLElement;
    this.enableAllChilds(el);
    el.style.cursor = 'auto'

    this.mouseDown = false;
    e.preventDefault();
    el.style.pointerEvents = 'auto';
  }

  @HostListener('mouseleave', ['$event'])
  stopDraggingOnLeave(e, flag) {
    const el = this.elRef.nativeElement as HTMLElement;
    this.enableAllChilds(el);
    el.style.cursor = 'auto'
    this.mouseDown = false;
    e.preventDefault();
    el.style.pointerEvents = 'auto';
  }



  @HostListener('mousemove', ['$event'])
  moveEvent(e) {

    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const el = this.elRef.nativeElement as HTMLElement;
    this.disableAllChilds(el);
    el.style.cursor = 'grab'
    
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }

  disableAllChilds(element: HTMLElement) {
    var children = element.children;
    for(var i=0; i<children.length; i++){
        var child = children[i];
        (child as HTMLElement).style.pointerEvents = "none";
    }
  }

  enableAllChilds(element: HTMLElement) {
    var children = element.children;
    for(var i=0; i<children.length; i++){
        var child = children[i];
        (child as HTMLElement).style.pointerEvents = "auto";
    }
  }




}
