import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { forgetSession } from '../../user/register/register.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  WebsiteBlogCreateActions,
  WebsiteBlogListActions,
  deleteWebsiteBlogPostActions,
  updatePostStatusActions,
  updateWebsiteBlogPostActions,
} from './website-blog.actions';
import { BlogPostItem } from '../../../../../models/blog.model';
import { SystemProcess, SystemProcessLoadingStatus } from 'src/app/models/global-classes';

export interface WebsiteBlogState extends EntityState<BlogPostItem> {
  createPostProcess: {
    status: SystemProcessLoadingStatus;
    error: {processType: SystemProcessLoadingStatus, error: any}
  };
  postsLoaded: boolean;
  postsError: any;
}

export const blogPostsAdapter = createEntityAdapter<BlogPostItem>();

const initialState: WebsiteBlogState = blogPostsAdapter.getInitialState({
  createPostProcess: { status: 'none', error: null },
  postsLoaded: false,
  postsError: null,
});

export const websiteBlogReducer = createReducer(
  initialState,

  // Posts List
  on(WebsiteBlogListActions.load, (state) => ({
    ...state,
    postsLoaded: false,
    postsError: null,
  })),
  on(WebsiteBlogListActions.loadSuccess, (state, { posts }) =>
    blogPostsAdapter.setAll(posts, {
      ...state,
      postsLoaded: true,
    })
  ),

  on(WebsiteBlogListActions.loadFailure, (state, { error }) => ({
    ...state,
    postsError: error,
  })),

  // Post Create
  on(WebsiteBlogCreateActions.create, (state) => ({
    ...state,
    createPostProcess: { status: 'creating', error: null },
  })),
  on(WebsiteBlogCreateActions.loadSuccess, (state, { post }) =>
    blogPostsAdapter.addOne(post, {
      ...state,
      createPostProcess: { status: 'done', error: null },
    })
  ),

  on(WebsiteBlogCreateActions.loadFailure, (state, { error }) => ({
    ...state,
    createPostProcess: { status: 'error', error: {processType: 'creating', error} },
  })),

  on(deleteWebsiteBlogPostActions.delete, (state) => ({
    ...state,
    postsError: null,
    createPostProcess: { status: 'deleting', error: null },
  })),
  on(deleteWebsiteBlogPostActions.deleteSuccess, (state, { postId }) =>
    blogPostsAdapter.removeOne(postId, {
      ...state,
      createPostProcess: { status: 'done', error: null },
    })
  ),
  on(
    deleteWebsiteBlogPostActions.deleteFailure,
    (state, {  error }) => ({
      ...state,
      postsError: error,
      createPostProcess: { status: 'error', error: {processType: 'deleting', error} },
    })
  ),

  // Post Update
  on(updateWebsiteBlogPostActions.update, (state) => ({
    ...state,
    createPostProcess: { status: 'updating', error: null },
  })),
  on(updateWebsiteBlogPostActions.updateSuccess, (state, { post }) =>
    blogPostsAdapter.updateOne(
      { id: post.id, changes: post },
      {
        ...state,
        createPostProcess: { status: 'done', error: null },
      }
    )
  ),
  on(updateWebsiteBlogPostActions.updateFailure, (state, { error }) => ({
    ...state,
    createPostProcess: { status: 'error', error: {processType: 'updating', error} },
  })),

  on(updatePostStatusActions.update, (state) => ({
    ...state,
    createPostProcess: { status: 'updating', error: null },
  })),

  on(forgetSession, (state) => initialState)
);
