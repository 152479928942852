const ICONS = {
    category_name: 'Network',
    icons_list: [
      "underlining",
      "disconnect",
      "code",
      "gear",
      "loading",
      "scroll",
      "wrench",
      "square-brackets",
      "frame",
      "message-programming",
      "data",
      "fasten",
      "click"



    ],

};


export default ICONS
