import { Component } from '@angular/core';

@Component({
  selector: 'app-global-learmo-modal',
  templateUrl: './global-learmo-modal.component.html',
  styleUrls: ['./global-learmo-modal.component.css']
})
export class GlobalLearmoModalComponent {

}
