import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

// @Injectable({
//   providedIn: 'root',
// })
export class LearmoRouteReuseStrategy implements RouteReuseStrategy {
  // These methods can be kept simple as we're not actually storing routes
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    // no-op
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Check if the current and future route configurations are the same
    if (future.routeConfig === curr.routeConfig) {
      // Check if the future route has data['reload'] set to true
      if (future.data && future.data['reload']) {
        return false; // This will force the route to reload
      }
    }
    return future.routeConfig === curr.routeConfig;
  }
}
