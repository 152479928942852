// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.animate {
  opacity: 0;
    transform: scale(0.25);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    animation: append-animate .6s ease-in-out forwards;
}

@keyframes append-animate {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}





`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/template-item/template-item-info-popup/template-item-page/template-item-page.component.css"],"names":[],"mappings":";;AAEA;EACE,UAAU;IACR,sBAAsB;IACtB,mDAAmD;IACnD,kDAAkD;AACtD;;AAEA;CACC;EACC,mBAAmB;EACnB,UAAU;CACX;CACA;EACC,mBAAmB;EACnB,UAAU;CACX;AACD","sourcesContent":["\n\n.animate {\n  opacity: 0;\n    transform: scale(0.25);\n    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);\n    animation: append-animate .6s ease-in-out forwards;\n}\n\n@keyframes append-animate {\n\tfrom {\n\t\ttransform: scale(0);\n\t\topacity: 0;\n\t}\n\tto {\n\t\ttransform: scale(1);\n\t\topacity: 1;\n\t}\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
