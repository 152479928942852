import { MediaLibraryFileModel } from './../../../../models/sales-funnel';
import { OfferItem, OfferPaymentTypeModel } from '../../offer/models/offer';
import {
  CourseCreationInput,
} from './course';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';

export enum ProductTypeStatusEnum {
  active = 'active',
  soon = 'soon',
}

export class ProductType {
  id: number;
  name: string;
  enum: 'course' | 'downloadable' | 'bundle' | 'coaching' | 'podcast';
  svgIcon: string;
  description?: string;
  productFeatures?: { title: string }[];
  status: ProductTypeStatusEnum;
}

export class ProductWizardData {
  productType: ProductType;
  courseInput?: CourseCreationInput;
  temp: any;
}

export class ProductCreationInput {
  name: string = '';
  shortDescription: string = '';
  description?: string = '';
  image?: MediaLibraryFileModel[];
  image_id?: number[];
  payment_type?: OfferPaymentTypeModel; //Same one used in creating offers
}

export class ProductItem {
  public id: number;
  public name: string;
  public description: string;
  public image: MediaLibraryFileModel[];
  public image_id?: number[];
  public type: ProductTypeModel;
  public delete: boolean;
  public isComplete?: boolean;
  public offerCount: number;
  public studentsCount: number;
  public offers: OfferItem[];
  public createdAt: number;
  public updatedAt: number;
  public shortDescription: string;
  previewUrl: string;
  visitorUrl: string;
  public updating?: SystemProcessLoadingStatus;
  public errorMessage?: string;

  courseMeta: {enrolledStudents: number, overallProgress: number, earnings: number};
  // TODO: Remove These
  public content?: MediaLibraryFileModel[];
  public content_ids?: number[];
}



export class DownloadableProductItem extends ProductItem {
  // TODO: Uncomment These
  // public content?: MediaLibraryFileModel[];
  // public content_ids?: number[];
}

export class ProductModel {
  public id: number;
  public name: string;
  public description: string;
  public image: MediaLibraryFileModel[];
  public image_id?: number[];
  public type: ProductTypeModel;
  public delete: boolean;
  public isComplete?: boolean;
  public content?: MediaLibraryFileModel[];
  public content_ids?: number[];
  public offerCount: number;
  public offers: OfferItem[];
  public createdAt: number;
  public updatedAt: number;
}

export class ProductCreateModel {
  constructor(
    public name: string = '',
    public description: string = null,
    public image_id?: number[],
    public type_id?: number,
    public content_ids?: number[],
    public createdAt?: number,
    public updatedAt?: number
  ) {}
}

export class ProductTypeModel {
  id: number;
  name: string;
  icon: string;
  image: string;
  description: string;
}

export class ProductEditResponse {
  public anyDataUpdated: boolean;
  public forceRequired: boolean;
  public product: ProductItem;
  public offers: {
    id: number;
    title: string;
    effect: string;
  }[];
  public errorItems: [];
}
