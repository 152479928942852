import { AiService } from 'src/app/shared/services/ai.service';
import { EditorConfigService } from './../../../../../../shared/services/editor-config.service';
import { ProductCreateService } from './../../product-create.service';
import { Component, Input, OnInit } from '@angular/core';
import { InOutAnimation } from 'src/app/shared/animations/animations';

export enum DescriptionEnterStatusEnum {
  none = 'none',
  manual = 'manual',
  ai = 'ai',
  thinking = 'thinking',
  writing = 'writing',
}
@Component({
  selector: 'app-product-description-image-wizard-step',
  templateUrl: './product-description-image-wizard-step.component.html',
  styleUrls: ['./product-description-image-wizard-step.component.css'],
  animations: [InOutAnimation],
})
export class ProductDescriptionImageWizardStepComponent implements OnInit {
  ctaData = {
    colorSet: 'primary',
    image: 'assets/images/illustrations/description.svg',
    message: {main: {value: 'Do you require a description?', writing: false}, sub: {value: "Let's create one within seconds", writing: false}},
  }

  descriptionEnterStatusEnum = DescriptionEnterStatusEnum;

  // @Input() enterStatus: DescriptionEnterStatusEnum = DescriptionEnterStatusEnum.none;
  
  maxDescriptionLength = 800;
  constructor(
    public productCreateService: ProductCreateService,
    public editorConfig: EditorConfigService,
    private ai: AiService
  ) {}

  ngOnInit() {
    this.productCreateService.setWizardNextTitle(`Continue`);
    if (
      !!this.productCreateService.wizardData?.courseInput?.shortDescription
        ?.length
    ) {
      this.productCreateService.wizardSettings.descriptionAiCurrentState = DescriptionEnterStatusEnum.manual;
    }
  }

  changeAiStatus(status: DescriptionEnterStatusEnum) {
    switch (status) {
      case DescriptionEnterStatusEnum.ai:
        this.generateATitleRequest();
        break;

      default:
        this.productCreateService.wizardSettings.descriptionAiCurrentState = status;
        break;
    }
  }

  updateLengthIndicator() {}
  consoleTest(value: any) {
    
  }

  editorCreated(editor) {
    editor.focus();
  }

  truncateString(text) {
    if (text.length > 800) {
      return text.substring(0, 800);
    }
    return text;
  }

  scrollToBottomOfDescriptionContainer() {
    let el = document.getElementById('descriptionContainer')
    if (!!el) {
      el.scrollTop = el.scrollHeight
    }
  }
  async generateATitleRequest(
    courseTitle: string = this.productCreateService.wizardData.courseInput.name
  ) {
    this.changeAiStatus(DescriptionEnterStatusEnum.thinking);
    this.productCreateService.wizardData.courseInput.shortDescription = '';
    // const prompt = `A medium-short marketing description for a course with the title "${courseTitle}" maximum 500 characters could be:`;
    const prompt = `Generate a very short description that grabs attention for the course titled '${courseTitle}'. Make it engaging and informative!

    Instructions:
    - Avoid generating more than 500 characters.
    - The Maximum Length is 500 characters.
    `;

    this.ai.getCompletion(prompt).subscribe({
      next: (response) => {
        // 

        const completions = response;
        if (completions != '\n') {
          this.productCreateService.wizardData.courseInput.shortDescription =
            this.productCreateService.wizardData.courseInput.shortDescription.concat(
              completions
            );
          this.changeAiStatus(DescriptionEnterStatusEnum.writing);
          this.scrollToBottomOfDescriptionContainer();
        }
      },
      error: (error) => {
        // Handle the error
      },
      complete: () => {
        // this.productCreateService.wizardData.courseInput.shortDescription =
        //   this.productCreateService.wizardData.courseInput.shortDescription.replace(
        //     /['"]+/g,
        //     ''
        //   );
        this.changeAiStatus(DescriptionEnterStatusEnum.manual);
        // this.resultsSync--;
      },
    });
  }
}
