import { PortalControlService } from '../services/portal-control.service';
import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { DashboardCanDeactivate } from './dashboard-can-deactivate';
import { SharedOwnerDashboardService } from '../layout/owner-dashboard/shared-owner-dashboard.service';

@Injectable()
export class CanDeactivateDashboardGuard
  implements CanDeactivate<DashboardCanDeactivate>
{
  constructor(
    private sharedDashboardService: SharedOwnerDashboardService,
    private route: Router
  ) {}
  canDeactivate(component: DashboardCanDeactivate): boolean {
    if (!!component?.canDeactivate && !component?.canDeactivate()) {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.sharedDashboardService.canNavigateAll = true;
        return true;
      } else {
        return false;
      }
    } else {
      this.sharedDashboardService.canNavigateAll = true;
      return true;
    }
  }
}
