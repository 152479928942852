import {
  DashboardNetworksStatistics,
  OverviewTrackingAndEarningsGraph,
  OwnerOverview,
  AffiliateNetworkPayoutSettingsObject,
  BankDetailsFromSwift,
} from './../../models/affiliates-dashboard';
import { EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

import Swal from 'sweetalert2';

import { SharedAffiliateDashboardService } from './../../shared/layout/affiliate-dashboard/shared-affiliate-dashboard.service';
import { AffiliateApi } from '../../shared/services/affiliate.api.service';
import {
  PeriodResultModel,
  AnnouncementObject,
} from '../../models/owner-dashboard';
import {
  NetworkEarnings,
  NetworkEarningsList,
} from '../../models/affiliates-dashboard';
import {
  AffiliateDashboardTotalTrackingData,
  OnwerForAffiliateModel,
} from 'src/app/models/affiliates-dashboard';
import { PaypalRequestModel } from './network-view/preferences/preferences-forms/paypal-integration-form/paypal-integration-form.component';
import { BtcpayRequestModel } from './network-view/preferences/preferences-forms/btcpay-integration-form/btcpay-integration-form.component';
import { WireTransferRequestModel } from './network-view/preferences/preferences-forms/wiretransfer-integration-form/wiretransfer-integration-form.component';

export enum AffiliateDashboardPageStatusEnum {
  overview = 'dashboard',
  statistics = 'statistics',
  earnings = 'earnings',
  announcements = 'announcements',
  messages = 'messages',
  none = 'none',
}

export enum NetworkPageStatusEnum {
  overview = 'overview',
  earnings = 'earnings',
  announcements = 'announcements',
  messages = 'messages',
  preferences = 'preferences',
  none = 'none',
}

@Injectable({
  providedIn: 'root',
})
export class SharedAffiliateUserDashboardService {
  settingsStatus: {
    payoutMethods: 'error' | 'edited' | 'none';
  } = {
    payoutMethods: 'none',
  };

  dashboardData: {
    announcements: AnnouncementObject[];
    earnings: NetworkEarningsList;
    graph: OverviewTrackingAndEarningsGraph;
    statistics: DashboardNetworksStatistics;
  } = {
    announcements: [],
    earnings: { earnings: [] },
    graph: { dashboardseries: [] },
    statistics: { networksStatistics: [] },
  };
  totalTrackingData: AffiliateDashboardTotalTrackingData;
  currentNetworkTrackingData: AffiliateDashboardTotalTrackingData;
  currentNetworkId: number;
  currentNetwork: {
    network: OnwerForAffiliateModel;
    overview: OwnerOverview;
    earningsData: NetworkEarningsList;
    settings: AffiliateNetworkPayoutSettingsObject;
  } = {
    network: null,
    overview: { affiliateLinks: [] },
    earningsData: null,
    settings: null,
  };
  loaded = false;
  internalLoaded = false;

  pageStatus: AffiliateDashboardPageStatusEnum =
    AffiliateDashboardPageStatusEnum.overview;
  networkPageStatus: NetworkPageStatusEnum = NetworkPageStatusEnum.overview;
  processing: 'loading' | 'done' | 'none' | 'error' = 'none';

  constructor(
    private modalService: NgbModal,
    private affiliateApi: AffiliateApi,
    private route: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private sharedDashboardService: SharedAffiliateDashboardService
  ) {}

  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing(by: 'done' | 'error' = 'done') {
    this.processing = by;
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  // AnnouncementsPart
  async getAnnouncementsList() {
    let announcementsList$ = this.affiliateApi.getMyAnnouncements();
    let announcementsList: AnnouncementObject[] = await lastValueFrom(
      announcementsList$
    );

    this.dashboardData.announcements = announcementsList;
  }

  async getAnnouncementsListByNetworkId(id: number) {
    let announcementsList$ =
      this.affiliateApi.getMyAnnouncementsByNetworkId(id);
    let announcementsList: AnnouncementObject[] = await lastValueFrom(
      announcementsList$
    );

    this.dashboardData.announcements = announcementsList;
  }

  // Begin: Current Network Functions
  async acceptCurrentNetwork() {
    let acceptInvitation$ = this.affiliateApi.acceptInvitationById(
      this.currentNetworkId
    );
    let acceptInvitation = await lastValueFrom(acceptInvitation$);
    this.sharedDashboardService.reloadMyOwnersList();
  }

  async rejectCurrentNetwork(prevent: boolean = false) {
    let acceptInvitation$ = this.affiliateApi.rejectInvitationById(
      this.currentNetworkId,
      prevent
    );
    let acceptInvitation = await lastValueFrom(acceptInvitation$);
    this.sharedDashboardService.reloadMyOwnersList();
  }

  async getCurrentNetworkTotalTrackingData() {
    let totalTrackingData$ =
      this.affiliateApi.getDashboardTrackingTotalsByNetwork(
        this.currentNetworkId
      );
    let totalTrackingData = await lastValueFrom(totalTrackingData$);
    this.currentNetworkTrackingData = totalTrackingData;
  }

  async getCurrentNetwork() {
    let currentNetwork$ = this.affiliateApi.getOwnerById(this.currentNetworkId);
    let currentNetwork = await lastValueFrom(currentNetwork$);
    this.currentNetwork.network = currentNetwork;
  }

  async getCurrentNetworkOverview(period: PeriodResultModel = null) {
    let overviewData$ = this.affiliateApi.getOwnerOverviewData(
      this.currentNetworkId,
      period
    );
    let overviewData = await lastValueFrom(overviewData$);
    this.currentNetwork.overview = overviewData;
  }

  async getCurrentNetworkEarningsSales(period: PeriodResultModel = null) {
    let currentNetworkEarnings$ = this.affiliateApi.getEarningsByNetworkId(
      this.currentNetworkId,
      period
    );
    let currentNetworkEarnings: NetworkEarningsList = await lastValueFrom(
      currentNetworkEarnings$
    );
    this.currentNetwork.earningsData = currentNetworkEarnings;
  }

  async getCurrentNetworkSettings() {
    let currentNetwork$ = this.affiliateApi.getOwnerPreferences(
      this.currentNetworkId
    );
    let currentNetwork: AffiliateNetworkPayoutSettingsObject =
      await lastValueFrom(currentNetwork$);
    this.currentNetwork.settings = currentNetwork;
  }

  async setPaypalSettings(
    requestModel: PaypalRequestModel,
    networkId: number = this.currentNetworkId
  ) {
    let paypalSettings$ = this.affiliateApi.setOwnerPaypalPreferences(
      networkId,
      requestModel
    );
    let paypalSettings = await lastValueFrom(paypalSettings$);

    this.toastr.success('Done!', 'Preferences Saved', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async setBtcpaySettings(
    requestModel: BtcpayRequestModel,
    networkId: number = this.currentNetworkId
  ) {
    let paypalSettings$ = this.affiliateApi.setOwnerBtcpayPreferences(
      networkId,
      requestModel
    );
    let paypalSettings = await lastValueFrom(paypalSettings$);

    this.toastr.success('Done!', 'Preferences Saved', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async CheckSwift(swift: string) {
    let swiftInfo$ = this.affiliateApi.checkSwiftCodeInformation(swift);
    let swiftInfo: BankDetailsFromSwift = await lastValueFrom(swiftInfo$);

    return swiftInfo;
  }

  async setWiretransferSettings(
    requestModel: WireTransferRequestModel,
    networkId: number = this.currentNetworkId
  ) {
    let paypalSettings$ = this.affiliateApi.setOwnerWiretransferPreferences(
      networkId,
      requestModel
    );
    let paypalSettings = await lastValueFrom(paypalSettings$);

    this.toastr.success('Done!', 'Preferences Saved', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  // End: Current Network Functions

  async getDashboardTotalTrackingData() {
    let totalTrackingData$ = this.affiliateApi.getDashboardTrackingTotals();
    let totalTrackingData = await lastValueFrom(totalTrackingData$);
    this.totalTrackingData = totalTrackingData;
  }

  async getDashboardEarnings(period: PeriodResultModel = null) {
    let dashboardEarnings$ = this.affiliateApi.getDashboardEarnings(period);
    let dashboardEarnings = await lastValueFrom(dashboardEarnings$);
    this.dashboardData.earnings = dashboardEarnings;
  }

  async getDashboardComparisionGraph(period: PeriodResultModel = null) {
    let dashboardGraph$ = this.affiliateApi.getDashboardGraph(period);
    let dashboardGraph = await lastValueFrom(dashboardGraph$);
    this.dashboardData.graph = dashboardGraph;
  }

  async getStatisticsComparisionData(period: PeriodResultModel = null) {
    let statisticsData$ =
      this.affiliateApi.getStatisticsComparisionData(period);
    let statisticsData = await lastValueFrom(statisticsData$);
    this.dashboardData.statistics = statisticsData;
  }

  startInternalLoading() {
    this.internalLoaded = false;
  }

  stopInternalLoading() {
    this.internalLoaded = true;
  }

  startLoading() {
    this.loaded = false;
  }

  stopLoading() {
    this.loaded = true;
  }

  goToAffiliateLinks() {
    this.route.navigate(['dashboard', 'affiliate', 'links']);
  }

  goToAffiliates() {
    this.route.navigate(['dashboard', 'affiliate', 'my-affiliates']);
  }

  async goToTab(slug: AffiliateDashboardPageStatusEnum) {
    this.pageStatus = slug;
  }

  async goToNetworkTab(slug: NetworkPageStatusEnum) {
    this.networkPageStatus = slug;
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }
}
