import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from './notifications.reducer';



// Create a feature selector for the 'website' feature
export const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');

// // Selectors for website data
export const selectNotifications = createSelector(selectNotificationsState, (state) => state.notifications);

export const selectUnreadNotificationsCount = createSelector(selectNotificationsState, (state) => state.unreadNotificationsCount);

export const isNotificationsLoaded = createSelector(selectNotificationsState, (state) => state.loaded);
export const isNotificationsLoadingError = createSelector(selectNotificationsState, (state) => state.error);

// // Selectors for website pages
// export const selectWebsitePages = createSelector(selectWebsiteState, (state) => state.pages);
// export const isWebsitePagesLoaded = createSelector(selectWebsiteState, (state) => state.pagesLoaded);
// export const isWebsitePagesLoadingError = createSelector(selectWebsiteState, (state) => state.pagesError);
