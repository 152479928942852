import { Component, Input, OnInit } from '@angular/core';
import { SocketConnectionStringStatus } from 'src/app/shared/services/portal-socket.service';

@Component({
  selector: 'app-socket-status',
  templateUrl: './socket-status.component.html',
  styleUrls: ['./socket-status.component.css']
})
export class SocketStatusComponent implements OnInit {
  @Input() socketStatusString: SocketConnectionStringStatus;
  socketConnectionStringStatus = SocketConnectionStringStatus
  constructor() { }

  ngOnInit() {
  }

}
