import { Component, Input } from '@angular/core';
import { RefundStatusEnum } from 'src/app/models/owner-dashboard';

@Component({
  selector: 'app-refund-item-status-label',
  templateUrl: './refund-item-status-label.component.html',
  styleUrls: ['./refund-item-status-label.component.css']
})
export class RefundItemStatusLabelComponent {
  refundStatusEnum = RefundStatusEnum;
  @Input() itemStatus: RefundStatusEnum
}
