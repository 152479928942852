import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FilteringTypeModel, SortingTypeModel } from 'src/app/models/portal';

@Component({
  selector: 'app-head-title-add',
  templateUrl: './head-title-add.component.html',
  styleUrls: ['./head-title-add.component.css'],
})
export class HeadTitleAddComponent implements OnInit {
  // Emitters
  @Output() onAddClick = new EventEmitter();
  @Output() onSecondaryButtonClick = new EventEmitter();
  // Options
  @Input() showActionButton = true;
  @Input() showSecondaryButton = false;
  @Input() processing:
    | 'loading'
    | 'done'
    | 'none'
    | 'error'
    | 'no-change'
    | 'success' = 'none';
  @Input() secondaryProcessing:
    | 'loading'
    | 'done'
    | 'none'
    | 'error'
    | 'no-change'
    | 'success' = 'none';
  @Input() actionButtonIcon = 'plus';
  @Input() secondaryButtonIcon;

  // Placeholders
  @Input() title: string = 'My Portals';
  @Input() addLabel: string = 'Add New Portal';
  @Input() secondaryLabel: string = 'Save as draft';
  @Input() processingLabel = 'Processing';
  @Input() secondaryProcessingLabel = 'Processing';
  constructor() {}

  ngOnInit(): void {}

  addClick() {
    this.onAddClick.emit();
  }

  secondaryClick() {
    this.onSecondaryButtonClick.emit();
  }
}
