import { Router } from '@angular/router';
import { SharedProfileService } from './../../../../components/profile/shared-profile.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isCurrentUserSubscriptionInfoLoaded, selectCurrentUserSubscriptionInfo } from 'src/app/shared/store/_features/subscription/subscription.selector';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';
import { SystemPlanType } from 'src/app/models/plans';

@Component({
  selector: 'app-learmo-subscription-widget',
  templateUrl: './learmo-subscription-widget.component.html',
  styleUrls: ['./learmo-subscription-widget.component.css']
})
export class LearmoSubscriptionWidgetComponent implements OnInit {
  SystemPlanType = SystemPlanType
  subscriptionInfo: SubscriptionBillingInfo;
  loaded: boolean = false;
  constructor(public router: Router, private store: Store) {
    this.store.select(selectCurrentUserSubscriptionInfo).subscribe((subscriptionInfo) => {
      this.subscriptionInfo = subscriptionInfo
    })
    this.store.select(isCurrentUserSubscriptionInfoLoaded).subscribe((isLoaded) => {
      this.loaded = isLoaded
    })
  }

  async ngOnInit() {
 
  }



}
