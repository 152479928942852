import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalPaginationConfig } from 'src/app/models/owner-dashboard';

@Component({
  selector: 'app-pagination-unit',
  templateUrl: './pagination-unit.component.html',
  styleUrls: ['./pagination-unit.component.css']
})
export class PaginationUnitComponent implements OnInit, OnDestroy {
  @Input() listArrayLength: number;
  @Input() resultsArrayLength: number


  @Input() paginationConfig: LocalPaginationConfig;
  @Output() paginationConfigChange: EventEmitter<LocalPaginationConfig> = new EventEmitter<LocalPaginationConfig>();


  subscriptions: Subscription[] = []
  constructor() { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => 
      sub.unsubscribe()
    )
  }

  ngOnInit() {
    this.subscriptions.push(
      this.paginationConfig.OnChange.subscribe(() => {
        this.paginationConfigChange.emit(this.paginationConfig);
      })
    )
  }


    // Pagination
    getArrayLenght(length) {
      return new Array(this.getPagesCount(length));
    }

    getPagesCount(length) {
      return Math.ceil(length / this.paginationConfig.numberInPage);
    }

    getIndex(pageIndex) {
      let canNavigate = pageIndex > 0 && pageIndex != this.paginationConfig.currentPage && pageIndex - 1 < this.getPagesCount(this.listArrayLength)
      if (canNavigate) {
        this.paginationConfig.currentPage = pageIndex;
        this.paginationConfig.startIndex = (pageIndex - 1) * this.paginationConfig.numberInPage;
        this.paginationConfig.endIndex = this.paginationConfig.startIndex + this.paginationConfig.numberInPage;
        this.scrollToTop();
      }
    }

    scrollToTop(timeout: number = 500) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

      }, timeout);

    }

}
