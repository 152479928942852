import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../services';

import {
  websiteActions,
  websitePagesActions,
  updateWebsitePage,
  loadWebsitePagesScreenshot,
  addNewWebsitePage,
  websiteCategoriesActions,
  createWebsiteCategoriesActions,
  websiteInstructorsActions,

} from './website.actions';
import { selectWebsiteId, selectWebsitePages } from './website.selector';
import { Store } from '@ngrx/store';
import { PageItem } from 'src/app/models/portal';
import { HttpErrorResponse } from '@angular/common/http';
import {findCountryByCodeOrName} from '../../../data/countries.data'

@Injectable()
export class WebsiteEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }

  loadWebsite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(websiteActions.load),
      mergeMap(() =>
        this.api.getCurrentWebsite().pipe(
          map((website) => websiteActions.loadSuccess({ website: website })),
          catchError((error) => of(websiteActions.loadFailure({ error })))
        )
      )
    )
  );



  loadWebsitePages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(websitePagesActions.load),
      withLatestFrom(this.store.select(selectWebsiteId)),
      mergeMap(([action, websiteId]) => {
        return this.api.getWebsitePages().pipe(
          mergeMap((response) => [
            websitePagesActions.loadSuccess({ pages: response.pages }),
            loadWebsitePagesScreenshot(undefined),
          ]),
          catchError((error) => of(websitePagesActions.loadFailure({ error })))
        );
      })
    )
  );




  addNewWebsitePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewWebsitePage),
      map((action) => loadWebsitePagesScreenshot({ pageIds: [action?.page?.id] }))
    )
  );

  loadWebsitePagesScreenshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebsitePagesScreenshot),
      withLatestFrom(this.store.select(selectWebsitePages)),
      mergeMap(([action, pages]) => {
        const screenshotPromises = pages.map((pageItem) => {
          if (!!action?.pageIds?.length) {
            if (action.pageIds.includes(pageItem.id)) {
              return this.api
                .screenshot(pageItem.url, false, (error: any) => { throw error })
                .pipe(
                  map((result: { img: string }) => ({
                    ...pageItem,
                    screenshot: result.img,
                  }))
                );
            }
          } else {
            return this.api
              .screenshot(pageItem.url, false, (error: any) => { throw error })
              .pipe(
                map((result: { img: string }) => ({
                  ...pageItem,
                  screenshot: result.img,
                }))
              );
          }
        });

        // Use concatMap to update pages one by one
        return of(...screenshotPromises).pipe(
          concatMap((observable) => observable),
          map((updatedPage) => updateWebsitePage({ page: updatedPage }))
        );
      })
    )
  );




  // Categories
  loadWebsiteCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(websiteCategoriesActions.load),
      mergeMap(() => {
        return this.api.getWebsiteCategories().pipe(
          map((response) =>
            websiteCategoriesActions.loadSuccess({ categories: response })
          ),
          catchError((error) =>
            of(websiteCategoriesActions.loadFailure({ error }))
          )
        );
      })
    )
  );


  createWebsiteCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWebsiteCategoriesActions.create),
      mergeMap(({ payload, errorHandler = undefined }) => {
        return this.api.createWebsiteCategories(payload, errorHandler).pipe(
          map((response) =>
            createWebsiteCategoriesActions.createSuccess({ category: { id: response.id, name: response.name } })
            // createWebsiteCategoriesSuccess({ category: response })
          ),
          catchError((error: HttpErrorResponse) =>
            of(createWebsiteCategoriesActions.createFailure({ error }))
          )
        );
      })
    )
  );






  // Instructors
  loadWebsiteInstructors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(websiteInstructorsActions.load),
      mergeMap(() => {
        return this.api.getWebsiteInstructors().pipe(
          map((response) =>
            websiteInstructorsActions.loadSuccess({ instructors: response })
          ),
          catchError((error) =>
            of(websiteInstructorsActions.loadFailure({ error }))
          )
        );
      })
    )
  );




 


  // this.createWebsiteInstructors$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(createWebsiteInstructors),
  //     mergeMap(({payload, errorHandler = undefined}) => {
  //       return this.api.createWebsiteInstructors(payload, errorHandler).pipe(
  //         map((response) =>
  //           createWebsiteInstructorsSuccess({ instructor: response })
  //           // createWebsiteCategoriesSuccess({ category: response })
  //         ),
  //         catchError((error: HttpErrorResponse) =>
  //           of(createWebsiteInstructorsFailure({ error }))
  //         )
  //       );
  //     })
  //   )
  // );
}
