import { SharedProfileService } from '../../../../components/profile/shared-profile.service';
import { ProfileUiControlService } from './../../../services/profile-ui-control.service';
import { User } from './../../../../models/user';
import { OnboardingService } from './../../../services/onboarding.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';

@Component({
  selector: 'app-owner-drop',
  templateUrl: './owner-drop.component.html',
  styleUrls: ['./owner-drop.component.css'],
})
export class OwnerDropComponent implements OnInit {
  // public currentUser: User;
  static onUpdateProfileEvent: EventEmitter<any>;
  itemClicked = new EventEmitter();

  constructor(
    public userDashboardService: UserDashboardService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private onboardingService: OnboardingService,
    public profileUiService: ProfileUiControlService,
    public sharedProfileService: SharedProfileService
  ) {}

  ngOnInit(): void {

  }

  toggleDarkMode() {
    this.sharedProfileService.toggleDarkMode();
  }

  switchToAffiliateMode() {
    this.userDashboardService.navigateToAffiliateMode();
    this.userDashboardService.disableOtherSideNotification();
  }
  goToAccountSettings() {
    this.router.navigate(['dashboard','profile']);
  }

  ActivateTour() {
    this.onboardingService.startGeneralTour(true);
  }

  logout() {
    this.authenticationService.logout();
  }
}
