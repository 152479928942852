
import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductType } from '../../../../models/product';

@Component({
  selector: 'app-product-type-more-details',
  templateUrl: './product-type-more-details.component.html',
  styleUrls: ['./product-type-more-details.component.css']
})
export class ProductTypeMoreDetailsComponent implements OnInit {
  @Input() productType: ProductType;
  @ViewChild('productTypeModal') modalContent: TemplateRef<any>;
  modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private route: Router,
  ) {}

  ngOnDestroy(): void {

  }

  async ngOnInit() {

  }

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List


  // Modal Open

  async open(content?) {
    
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: true,
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in informative-item-view-window',
    });
  }

  useActionPopup() {

  }


  async onOpenModal() {

  }


}
