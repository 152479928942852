import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
  keyframes,
  animateChild,
} from '@angular/animations';

export const ShrinkifyInOutAnimation = [
  trigger('shrinkifyInOutAnimation', [
    transition('* => void', [
      style({ transform: 'translateY(0)', opacity: 1, 'height': '*'  }),
      animate(200, style({ transform: 'translateY(-20px)', opacity: 0, 'height': '0' })),
    ]),
    transition('void => *', [
      style({ transform: 'translateY(-20px)', opacity: 0, 'height': '0' }),
      animate(150, style({ transform: 'translateY(0)', opacity: 1, 'height': '*' })),
    ]),
  ]),
];


export const ShrinkInOutAnimation = [
  trigger('exportRecordExpand', [
    state('*', style({'overflow-y': 'hidden'})),
    transition('* => void', [
      style({ transform: 'translateY(0)', opacity: 1, 'height': '*'  }),
      animate(200, style({ transform: 'translateY(-20px)', opacity: 0, 'height': '0' })),
    ]),
    transition('void => *', [
      style({ transform: 'translateY(-20px)', opacity: 0, 'height': '0' }),
      animate(300, style({ transform: 'translateY(0)', opacity: 1, 'height': '*' })),
    ]),
  ]),
];
export const scaleInOutAnimation = trigger('scaleInOut', [
  state('in', style({ transform: 'scale(1)' })),
  state('out', style({ transform: 'scale(0)' })),
  transition('in => out', animate('300ms ease-in')),
  transition('out => in', animate('300ms ease-out')),
]);


export const formSlideInAnimation = trigger('formSlideInAnimation', [
  transition(':enter', [
    style({ height: '0px', minHeight: '0', opacity: 0, 'overflow-y': 'hidden', 'overflow-x' : 'hidden' }),
    animate('0.5s ease-out', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: '*', minHeight: '0', opacity: 1, 'overflow-y': 'hidden', 'overflow-x' : 'hidden' }),
    animate('0.5s ease-out', style({ height: '0', opacity: 0 })),
  ]),
]);


export const cardAnimation = trigger('card-animation', [
  transition(':enter', [
    style({ height: '0px', minHeight: '0', opacity: 0, 'overflow-y': 'hidden' }),
    animate('0.5s ease-out', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: '*', minHeight: '0', opacity: 1, 'overflow-y': 'hidden' }),
    animate('0.5s ease-out', style({ height: '0', opacity: 0 })),
  ]),
]);

export const DivStateAnimation = [
  trigger('divState', [
    state('in', style({ backgroundColor: 'red', transform: 'translateX(0)' })),

    transition('void => *', [
      animate(
        1000,
        keyframes([
          style({ opacity: 0, transform: 'translateY(-100%)' }),
          style({ opacity: 1, transform: 'translateY(15px)' }),
          style({ opacity: 1, transform: 'translateY(0)' }),
        ])
      ),
    ]),
    transition('* => void', [
      animate(
        0,
        keyframes([
          style({ opacity: 1, transform: 'translateY(0)' }),
          style({ opacity: 1, transform: 'translateY(-15px)' }),
          style({ opacity: 0, transform: 'translateY(100%)' }),
        ])
      ),
    ]),
  ]),
];

export const listPopUpdateAnimation = trigger('listPopUpdateAnimation', [
  transition(':increment', [
    style({ backgroundColor: 'yellow' }),
    animate('200ms', style({ backgroundColor: 'white' })),
  ]),
]);


export const InOutAnimation =
  [trigger('inOutAnimation', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate('1s ease-out', style({ opacity: 1 })),
    ]),
    transition('* => void', [
      style({ opacity: 1 }),
      animate('0s ease-in', style({ opacity: 0 })),
    ]),
  ])];

  export const disableOutAnimation =
  [trigger('disableOutAnimation', [
    transition('* => void', [
      style({ opacity: 1 }),
      animate('0s ease-in', style({ opacity: 0 })),
    ]),
  ])];



export const simpleFadeAnimation = [
  trigger('simpleFadeAnimation', [
    state('in', style({ opacity: 1, height: 100 + 'vh' })),
    transition(':enter', [
      style({ opacity: 0, height: 0 + 'vh' }),
      animate(200),
    ]),
    transition(':leave', animate(0, style({ opacity: 0, height: 0 + 'vh' }))),
  ]),
];



export const slideUp = [
  query(
    ':leave',
    style({
      position: 'absolute',
      'overflow-y': 'hidden',
      left: 0,
      right: 0,
      transform: 'translate3d(0%,0,0)',
      opacity: 1
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'absolute',
      'overflow-y': 'hidden',
      left: 0,
      right: 0,
      transform: 'translate3d(0,-100%,0)',
      opacity: 0
    }),
    { optional: true }
  ),
  group([
    query(
      ':leave',
      group([
        animate(
          '500ms cubic-bezier(0.48, -0.08, 0.44, 0.08)',
          style({ transform: 'translate3d(0,100%,0)', opacity: 0, 'overflow-y': 'hidden', })
        ), // y: '-100%'
      ]),
      { optional: true }
    ),
    query(
      ':enter',
      group([
        animate(
          '500ms cubic-bezier(0.48, -0.08, 0.44, 0.08)',
          style({ transform: 'translate3d(0%,0,0)', opacity: 1, 'overflow-y': 'hidden', })
        ),
      ]),
      { optional: true }
    ),
  ]),
];


export const slideDown = [
  query(
    ':leave',
    style({
      position: 'absolute',
      'overflow-y': 'hidden',
      left: 0,
      right: 0,
      transform: 'translateY(0%)',
      opacity: 1
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'absolute',
      'overflow-y': 'hidden',
      left: 0,
      right: 0,
      transform: 'translateY(100%)',
      opacity: 0
    }),
    { optional: true }
  ),

  group([
    query(
      ':leave',
      group([
        animate(
          '500ms cubic-bezier(0.48, -0.08, 0.44, 0.08)',
          style({ transform: 'translateY(-100%)', opacity: 0, 'overflow-y': 'hidden', })
        ), // y: '-100%'
      ]),
      { optional: true }
    ),
    query(
      ':enter',
      group([
        animate(
          '500ms cubic-bezier(0.48, -0.08, 0.44, 0.08)',
          style({ transform: 'translateY(0%)' , opacity: 1,'overflow-y': 'hidden', })
        ),
      ]),
      { optional: true }
    ),
  ]),
];

export const slider = trigger('routeAnimations', [
  transition(':increment', slideDown),
  transition(':decrement', slideUp),
]);


export const slideInOut = trigger('slideInOutAnimation', [
  state('1', style({ 'max-height': '*', opacity: 1 })),
  state('0', style({ 'max-height': '0px', opacity: 0 })),

  transition(':enter', [
    style({
      transform: 'translateY(-10%)',
      opacity: 0
    }),
    animate('.3s ease-in-out', style({
      transform: 'translateY(0)',
      opacity: 1
    }))
  ]),
  transition(':leave', [
    animate('0s ease-in-out', style({
      transform: 'translateY(-10%)',
      opacity: 0
    }))
  ])
])




// List Animation
export const listAnimationInOnly = trigger('listAnimationInOnly', [
  // Transition from any state to any state
  transition('* => *', [
   // Initially the all cards are not visible
   query(':enter', style({ transform: 'translateY(-50px)', opacity: 0 }), { optional: true }),

   // Each card will appear sequentially with the delay of 300ms
   query(':enter', stagger('.2s', [
     animate('0.75s cubic-bezier(.8, -0.6, 0.2, 1.5)', keyframes([
       style({ transform: 'translateY(0)', opacity: 1 })
     ]))]), { optional: true }),

 ]),
]);

export const itemAnimation = trigger('itemAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-10px)' }),
    animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', [
      style({ transform: 'translateY(-50px)', opacity: 0 }),
      stagger('.2s', [
        animate('0.75s cubic-bezier(.8, -0.6, 0.2, 1.5)', keyframes([
          style({ transform: 'translateY(0)', opacity: 1 })
        ]))
      ])
    ], { optional: true }),

    query(':leave', [
      stagger('150ms', [
        animate('.7s cubic-bezier(.8, -0.6, 0.2, 1.5)', keyframes([
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          })
        ]))
      ])
    ], { optional: true })
  ])
]);


export const disableAnimation = trigger('disableAnimation', []);


export const conditionalListAnimation = trigger('conditionalListAnimation', [
  // Transition from any state to any state
  transition('* => true', [
   // Initially the all cards are not visible
   query(':enter', style({ transform: 'translateY(-50px)', opacity: 0 }), { optional: true }),

   // Each card will appear sequentially with the delay of 300ms
   query(':enter', stagger('.2s', [
     animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', keyframes([
       style({ transform: 'translateY(0)', opacity: 1 })
     ]))]), { optional: true }),


     query(':leave', style({ transform: 'scale(1)', opacity: 1, height: '*' }), { optional: true }),
   // Cards will disappear sequentially with the delay of 300ms
   query(':leave', stagger('250ms', [
     animate('.7s cubic-bezier(.8, -0.6, 0.2, 1.5)', keyframes([
       style({
    transform: 'scale(0.5)', opacity: 0,
    height: '0px', margin: '0px'
  })
     ]))]), { optional: true })
 ]),
]);



// query(':enter', stagger('.2s', [
//   animate('.2s ease-in', keyframes([
//     style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
//     style({ opacity: .5, transform: 'translateY(-10px) scale(1.1)', offset: 0.3 }),
//     style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
//   ]))]), { optional: true }),
