import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AffiliateDashboardTotalTrackingData } from 'src/app/models/affiliates-dashboard';
import {
  AffiliateDashboardPageStatusEnum,
  SharedAffiliateUserDashboardService,
} from '../../../shared-affiliate-user-dashboard.service';

@Component({
  selector: 'app-affiliate-head-stats',
  templateUrl: './affiliate-head-stats.component.html',
  styleUrls: ['./affiliate-head-stats.component.css'],
})
export class AffiliateHeadStatsComponent implements OnInit {
  @Input() trackingData: AffiliateDashboardTotalTrackingData;
  affiliateDashboardPageStatusEnum = AffiliateDashboardPageStatusEnum;
  constructor(
    public sharedAffiliateService: SharedAffiliateUserDashboardService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}
}
