import { BundleRenewalType } from "./subscription";


export enum SystemPlanType {
  subscription = 'subscription',
  free = 'free',
}
export class Plan {
  id: number;
  name: string;
  type: SystemPlanType;
  public recommended: boolean;
  public description: string;
  public short_description: string;
  public features: PlanFeatureItem[];
  annual: Bundle;
  monthly: Bundle;
  // renewal_type: BundleRenewalType;
}

export class Bundle {
  public id: number;
  public name: string;
  
  public description: string;
  public short_description: string;
  public price: number;
  public new_price: number;
  public is_coupon: boolean;
  public readonly numOfPortals: number;
  public features: PlanFeatureItem[];
  public additionalFeatures: string[];
  parentId: number;
  renewal_type: BundleRenewalType;
}

export class PlanFeatureItem {
  title: string;
  enabled: boolean;
  different: boolean;
}
// export class PlansResponseResult {
//   public readonly monthlyBundles: Plan[];
//   public readonly annualBundles: Plan[];
// }

export class CouponPlansResult {
  public readonly couponId: number;
  public readonly monthlybundles: Plan[];
  public readonly annualbundles: Plan[];
}
