import { Plan } from './../../../../models/plans';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../services';

import { Store } from '@ngrx/store';
import { CurrentUserSubscriptionActions, SystemPlansActions } from './subscription.actions';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';



@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}

  loadSystemPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemPlansActions.load),
      mergeMap(() =>
        this.api.getPlans().pipe(
          map((plans: Plan[]) =>
            SystemPlansActions.loadSuccess({
              plans: plans
            })
          ),
          catchError((error) => of(SystemPlansActions.loadFailure({ error })))
        )
      )
    )
  );


  loadCurrentUserSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserSubscriptionActions.load),
      mergeMap(() =>
        this.api.getSubscriptionBundleInfo().pipe(
          map((currentUserSubscriptionInfo: SubscriptionBillingInfo) =>
            CurrentUserSubscriptionActions.loadSuccess({
              currentUserSubscriptionInfo
            })
          ),
          catchError((error) => of(CurrentUserSubscriptionActions.loadFailure({ error })))
        )
      )
    )
  );
}
