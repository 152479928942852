import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-no-results-search',
  templateUrl: './no-results-search.component.html',
  styleUrls: ['./no-results-search.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class NoResultsSearchComponent implements OnInit {
  @Input() searchWord: string;
  // @Input() title: string;
  // @Input() description: string;

  copying = false;
  // offerUrl: string = '';

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {}
}
