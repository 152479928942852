import { PaymentGatewayModel, Payment_Gateway_Enum } from "../../../../../models/portal";

const staticPaymentGateways: PaymentGatewayModel[] = [
    {
      name: 'Stripe',
      id: Payment_Gateway_Enum.stripe,
      image: 'assets/images/svg/payment/stripe.svg',
      description:
        'Integrate your Stripe account and allow customers to checkout using Credit Card.',
      integrated: null,
    },
    {
      name: 'BTCPay',
      id: Payment_Gateway_Enum.btcpay,
      image: 'assets/images/svg/payment/btcpay.svg',
      description:
        'Integrate BTCPay and allow customers to checkout using Crypto Currencies.',
      integrated: null,
    },
    {
      name: 'Paypal',
      id: Payment_Gateway_Enum.paypal,
      image: 'assets/images/svg/payment/paypal.svg',
      description:
        'Integrate your PayPal account and allow customers to checkout using PayPal.',
      integrated: null,
    },
  ];




  export function generatePaymentMethodsFullModel(paymentGateways): PaymentGatewayModel[] {
    return staticPaymentGateways.map((a) => {
        if (!!paymentGateways && !!paymentGateways[a.id]) {
          return {...a, ...paymentGateways[a.id]};
        }
        else {
          return a;
        }
      });
  }