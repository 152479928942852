import { Component, Input, OnInit } from '@angular/core';
import { AffiliateLinkMinimizedModel } from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-afiliate-link-type-label',
  templateUrl: './afiliate-link-type-label.component.html',
  styleUrls: ['./afiliate-link-type-label.component.css'],
})
export class AfiliateLinkTypeLabelComponent implements OnInit {
  @Input() affiliateLink: AffiliateLinkMinimizedModel;
  constructor() {}

  ngOnInit(): void {}
}
