const ICONS = {
    category_name: 'General',
    icons_list: [
    "triangle",
    "subtitle",
    "ghost",
    "information",
    "milk",
    "home",
    "happy-emoji",
    "mouse-square",
    "filter-tick",
    "filter-search",
    "wifi-home",
    "trash-square",
    "paper-clip",
    "archive",
    "pin",
    "wifi-square",
    "auto-brightness",
    "coffee",
    "icon",
    "emoji-happy",
    "general-mouse",
    "ranking",
    "slider",
    "crown-2",
    "rescue",
    "flash-circle",
    "safe-home",
    "cloud-change",
    "crown",
    "filter-edit",
    "picture",
    "verify",
    "send",
    "tag-cross",
    "cloud-add",
    "home-3",
    "disk",
    "trash",
    "star",
    "cd",
    "home-2",
    "mouse-circle",
    "home-1",
    "call",
    "gift",
    "share",
    "sort",
    "magnifier",
    "filter-square",
    "tree",
    "filter",
    "switch",
    "cloud",
    "cup",
    "diamonds",
    "status",
    "rocket",
    "cloud-download",
    "menu",
    "chrome",
    "text-align-center",
    "text-italic",
    "text-bold",
    "text-strikethrough",
    "text-underline",
    "text-number",
    "text-align-left",
    "text-align-right",
    "text-circle",
    "text-align-justify-center",
    "night-day",
    "sun",
    "drop",
    "moon",
    "capsule",
    "virus",
    "bandage",
    "thermometer",
    "flask",
    "test-tubes",
    "syringe",
    "mask",
    "pill",
    "pulse",
    "burger-menu",
    "burger-menu-6",
    "burger-menu-5",
    "burger-menu-4",
    "burger-menu-1",
    "burger-menu-3",
    "burger-menu"

    ],

};


export default ICONS
