import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, timer, switchMap } from 'rxjs';
import { InOutAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-welcome-step',
  templateUrl: './welcome-step.component.html',
  styleUrls: ['./welcome-step.component.css'],
  animations: [InOutAnimation],
})
export class WelcomeStepComponent implements OnInit {
  @Output() onSkip = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  @Input() firstName: string;
  messages: {
    text: string;
    subText: string;
    interval: number;
    emoji: string;
  }[] = [
    { emoji: '👋', text: '<span class="display-4 fw-light">Hello, <span class="fw-bolder">{name}</span></span>', subText: '', interval: 0 },
    { emoji: '🤝', text: 'Welcome to Learmo', subText: '', interval: 2500 },
    {
      emoji: '💰',
      text: 'Your Sales Success Partner',
      subText: `We're here for you every step of the way to make sure your path to selling products and boosting your earnings is nothing but smooth sailing!`,
      interval: 2500,
    },
    {
      emoji: '🚀',
      text: `Let's commence our journey with the first step`,
      subText: `Let's set up your website`,
      interval: 8000,
    },
  ];
  currentMessageIndex: number = 0;
  userName: string;

  messageChange$: Observable<string>;
  processedTimeouts: number = 0;

  ngOnInit() {
    this.startMessagesSequence();
  }


  onClickNext() {
    this.onDone.emit();
  }
  onClickBullet(index: number) {
    if (this.processedTimeouts == null) {
    this.currentMessageIndex = index;
    }
  }
  startMessagesSequence() {
    this.messages.forEach((message, index) => {
      this.processedTimeouts += message.interval;
      // console.log(
      //   this.currentMessageIndex,
      //   this.messages.length,
      //   this.processedTimeouts
      // );
      setTimeout(() => {
        this.currentMessageIndex = index;
        if (index == this.messages.length - 1) {
          this.processedTimeouts = null;
        }
      }, this.processedTimeouts);
    });
  }
}
