import { Component, OnInit, Input } from '@angular/core';
import {
  MessengerUser,
  MessengerUserRoleEnum,
} from '../../../../../models/owner-dashboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-user-item',
  templateUrl: './chat-user-item.component.html',
  styleUrls: ['./chat-user-item.component.css'],
})
export class ChatUserItemComponent implements OnInit {
  @Input() user: MessengerUser;
  messengerUserRoleEnum = MessengerUserRoleEnum;
  @Input() me: boolean = false;
  constructor(private router: Router) {}

  ngOnInit() {}

  viewAffiliate(id: number) {
    this.router.navigate([
      'dashboard',
      'affiliate',
      'my-affiliates',
      id,
      'view',
    ]);
  }
}
