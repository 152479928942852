import {
  ChangeCourseItemStatusInput,
  CourseDetailsModelBase,
  CourseHeirarchyModel,
  CourseHierarchyItemTypeEnum,
  CourseItemStatus,
  CourseLessonItem,
  CourseSettingsModel,
  CreateModuleInput,
  GenericCourseItemCreationInput,
  LessonEditInput,
  SetCourseHeirarchyInput,
  StudentItem,
} from 'src/app/components/user-dashboard/product/models/course';
// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { MoveCourseItemInput } from 'src/app/components/user-dashboard/product/models/course';
import { CategoryUpdateDto } from 'src/app/components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-category-card/course-category-edit-modal/dto/category-edit.dto';
import { InstructorsUpdateDto } from 'src/app/components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-instructors-card/course-instructors-edit-modal/dto/instructors-edit.dto';
import { fail } from 'assert';



// Current Product Management
export const CurrentCourseHeirarchyActions = createActionGroup({
  source: 'Load Current Course Heirarchy',
  events: {
    load: props<{ productId: number; errorHandler?: any }>(),
    loadSuccess: props<{ heirarchy: CourseHeirarchyModel }>(),
    loadFailure: props<{ error: any }>(),
    resetProcessingStatus: emptyProps(),
  },
});


export const CreateCourseItemActions = createActionGroup({
  source: 'Create Course Item',
  events: {
    create: props<{
      productId: number;
      itemType: CourseHierarchyItemTypeEnum;
      payload: GenericCourseItemCreationInput;
      errorHandler?: any;
    }>(),
    success: props<{ heirarchy: CourseHeirarchyModel }>(),
    failure: props<{ error: any }>(),
  },
});

export const ApplyTemplateToCourseAction = createActionGroup({
  source: 'Apply Template To Course Heirarchy',
  events: {
    set: props<{
      productId: number;
      payload: SetCourseHeirarchyInput;
      errorHandler?: any;
    }>(),
    success: props<{ heirarchy: CourseHeirarchyModel }>(),
    failure: props<{ error: any }>(),
  },
});


export const UpdateCourseSettingsActions = createActionGroup({
  source: 'Update Course Settings',
  events: {
    update: props<{
      productId: number;
      payload: CourseSettingsModel;
      errorHandler?: any;
    }>(),
    success: props<{ heirarchy: CourseHeirarchyModel }>(),
    failure: props<{ error: any }>(),
  },
});


// Current Product Update
export const MoveCourseItemActions = createActionGroup({
  source: 'Update Current Course Heirarchy',
  events: {
    move: props<{
      productId: number;
      payload: MoveCourseItemInput;
      errorHandler?: any;
    }>(),
    moveSuccess: props<{ heirarchy: CourseHeirarchyModel }>(),
    moveFailure: props<{ error: any }>(),
  },
});


// Generic
export const updateCurrentCourseHeirarchyStructure = createAction(
  '[Course] Update Current Course Heirarchy Structure',
  props<{ heirarchy: CourseHeirarchyModel }>()
);

export const clearCurrentCourse = createAction(
  '[Course] Clear Current Course Data'
);
