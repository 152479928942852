// actions/products.actions.ts
import { createAction, createActionGroup, props } from '@ngrx/store';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';






export const CurrentCourseReportsActions = createActionGroup({
  source: 'Load Current Course Reports',
  events: {
    load: props<{ productId: number; errorHandler?: any }>(),
    loadSuccess: props<{ students: StudentItem[], demoStudents: StudentItem[], totalProgress: number, completedStudentsCount: number }>(),
    loadFailure: props<{ error: any }>(),
  },
});
