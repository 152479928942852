const ICONS = {
    category_name: 'Settings',
    icons_list: [
      "toggle-on",
      "toggle-on-circle",
      "toggle-off",
      "category",
      "setting",
      "toggle-off-circle",
      "more-2",
      "setting-4",
      "setting-2",
      "setting-3"


    ],

};


export default ICONS
