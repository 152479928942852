import { ProductItem, ProductModel } from './../../product/models/product';
import { MinimizedPortalModel } from './../../portal/dto/portal.output';
import { CurrenciesModel, MediaLibraryFileModel, MoneyModel } from './../../../../models/sales-funnel';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';

export class OfferEditResponse {
  public anyDataUpdated: boolean;
  public errorItems: [];
  public errorMessage: string;
  public errorSeverity: 'error' | 'warning';
  public offer: OfferItem;
}

export class OfferItem {
  public id: number;
  public title: string;
  public description: string;
  public image: MediaLibraryFileModel[];
  public payment_type: OfferPaymentTypeModel;
  public parentPortal: MinimizedPortalModel;
  public products: ProductItem[];
  public products_ids: number[];
  public constraints: offerConstraints;
  public status: OfferStausEnum;
  public isComplete?: IsOfferCompleteResponse;
  public productCount?: number;
  public isDefault: boolean;
  public image_id?: number[];
  public qtySold: number
  public netRevenue: MoneyModel;
  public delete: boolean;
  public createdAt: number;
  public updatedAt: number;
  public offerLink: string;
  public previewLink: string;
  public updating?: SystemProcessLoadingStatus;
  public errorMessage?: string
  


  constructor(data: Partial<OfferItem> = {}) {
    Object.assign(this, data);
  }

  public getOfferDTO(): OfferCreationDTO {
    const dto = new OfferCreationDTO({
      title: this.title,
      productId: this.products_ids && this.products_ids.length > 0 ? this.products_ids[0] : null,
      payment_type: {
        name: this.payment_type.name,
        price: {
          value: this.payment_type.price.value,
          currency: {
            id: this.payment_type.price.currency.id,
          },
        },
        btcPay: this.payment_type.btcPay,
        payPal: this.payment_type.payPal,
        stripe: this.payment_type.stripe,
      },
      // Add other properties as needed
    });
    console.log("Offer DTO", dto)
    return dto;
  }
}

export class OfferEditPayload {
  public id?: number;
  public title?: string;
  public description?: string;
  public image?: MediaLibraryFileModel[];
  public payment_type?: OfferPaymentTypeModel;
  public constraints?: offerConstraints;
  public status?: OfferStausEnum;
  public isComplete?: IsOfferCompleteResponse;
  public productCount?: number;
  public isDefault?: boolean;
  public image_id?: number[];
}


export class OfferCreationDTO {
  title: string;
  productId: number;
  payment_type: Partial<OfferPaymentTypeModel>;

  constructor(data: {
  title: string,
  productId: number,
  payment_type: Partial<OfferPaymentTypeModel>
  } = {
    title : '',
    productId : null,
    payment_type : new OfferPaymentTypeModel()
  }
  ) {
    Object.assign(this, data)
  }
}
// undefined,undefined,undefined,Offer_Payment_Type_Enum.free, {currency: {id: 1}, value: 100}
export class OfferPaymentTypeModel {
  constructor(
    public btcPay?: Payment_Status_Enum | boolean,
    public payPal?: Payment_Status_Enum | boolean,
    public stripe?: Payment_Status_Enum | boolean,
    public name: Offer_Payment_Type_Enum = Offer_Payment_Type_Enum.free,
    public price: Partial<MoneyModel> = new MoneyModel()
  ) {}
}

export enum Payment_Status_Enum {
  notIntegrated = 'notIntegrated',
  enabled = 'enabled',
  disabled = 'disabled',
}


export enum OfferStausEnum {
  published = 'published',
  draft = 'draft',
  deleted = 'deleted',
}
export enum Offer_Payment_Type_Enum {
  free = 'free',
  one_time = 'one_time',
}


class IsOfferCompleteResponse {
  status: boolean;
  incompleteItems: {
    area: 'payment' | 'settings' | 'information' | 'products' | 'offer';
    msg: '';
  }[];
  msg: string; // A user friendly message to let him know the missing items, can be null if the offer is complete
}

export class offerConstraints {
  public expireAt: number;
  public stock: number;
  public downloadExpiry: number;
  public allowedDownloadTimes: number;
}
