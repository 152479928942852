// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';
import { ApiService } from '../../../services';


import {     updateOfferStatusActions,  startOfferLoadingActions, deleteOfferActions, cloneOfferActions, offersActions, currentOfferActions, updateOfferActions, createNewOfferActions } from './offers.actions';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { Store } from '@ngrx/store';

@Injectable()
export class OffersEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {

  }
  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(offersActions.load), // Listen for the loadProducts action
      mergeMap(() =>
        this.api.getOffers().pipe( // Use pipe method correctly within mergeMap
          map((response) => offersActions.loadSuccess({ offers: response.offers })),
          catchError((error) => of(offersActions.loadFailure({ error }))
        )
      )
    )
  ));


  loadCurrentOffer$ = createEffect(() =>
  this.actions$.pipe(
    ofType(currentOfferActions.load), // Listen for the loadProducts action
    mergeMap(({offerId}) =>
      this.api.getOfferById(offerId).pipe( // Use pipe method correctly within mergeMap
        map((response: OfferItem) => currentOfferActions.loadSuccess({ offer: response })),
        catchError((error) => of(currentOfferActions.loadFailure({ error }))
      )
    )
  )
));

createNewOffer$ = createEffect(() =>
this.actions$.pipe(
  ofType(createNewOfferActions.create), // Listen for the loadProducts action
  mergeMap(({createPayload}) =>

    this.api.createOffer(createPayload).pipe( // Use pipe method correctly within mergeMap
      map((response: OfferItem) => createNewOfferActions.createSuccess({ offer: response, productId: createPayload.productId })),
      catchError((error) => of(createNewOfferActions.createFailure({ error }))
    )
  )
)
));


updateCurrentOffer$ = createEffect(() =>
this.actions$.pipe(
  ofType(updateOfferActions.update), // Listen for the loadProducts action
  tap(({offerId, productId}) => this.store.dispatch(startOfferLoadingActions.startOffer({offerId, productId, loadingStatus: 'loading'}))), // Dispatch startOfferLoading action before API call
  mergeMap(({offerId, productId, updates}) =>
    this.api.updateOffer(offerId, updates, (error)=>{throw error}).pipe( // Use pipe method correctly within mergeMap
      map((response: {offer: OfferItem}) => updateOfferActions.updateSuccess({ offerId, productId ,changes: response.offer })),
      catchError((error) => of(updateOfferActions.updateFailure({ offerId, productId, error }))
    )
  )
)
));


updateOfferStatus$ = createEffect(() =>
this.actions$.pipe(
  ofType(updateOfferStatusActions.update), // Listen for the loadProducts action
  tap(({offerId, productId}) => this.store.dispatch(startOfferLoadingActions.startOffer({offerId, productId, loadingStatus: 'loading'}))), // Dispatch startOfferLoading action before API call
  mergeMap(({offerId, productId, status}) =>
  this.api.setOfferStatus(
    offerId,
    status,
    (error)=>{ throw error}
  ).pipe( // Use pipe method correctly within mergeMap
      map((response: {offer: OfferItem}) => updateOfferActions.updateSuccess({ offerId, productId, changes: response.offer })),
      catchError((error) => of(updateOfferActions.updateFailure({ offerId, productId, error }))
    )
  )
)
));


deleteOffer$ = createEffect(() =>
this.actions$.pipe(
  ofType(deleteOfferActions.delete), // Listen for the loadProducts action
  tap(({offerId, productId}) => this.store.dispatch(startOfferLoadingActions.startOffer({offerId, productId, loadingStatus: 'deleting'}))), // Dispatch startOfferLoading action before API call
  mergeMap(({offerId, productId}) =>
    this.api.deleteOffer(offerId,true, (error)=>{throw error}).pipe( // Use pipe method correctly within mergeMap
      map((response: {offer: OfferItem}) => deleteOfferActions.deleteSuccess({ offerId, productId: productId })),
      catchError((error) => of(deleteOfferActions.deleteFailure({ offerId, productId, error }))
    )
  )
)
));


cloneOffer$ = createEffect(() =>
this.actions$.pipe(
  ofType(cloneOfferActions.clone), // Listen for the loadProducts action
  mergeMap(({offerId, productId}) =>
    this.api.cloneOffer(offerId, (error)=>{throw error}).pipe( // Use pipe method correctly within mergeMap
      map((response: OfferItem) => createNewOfferActions.createSuccess({ offer: response, productId: productId })),
      catchError((error) => of(cloneOfferActions.cloneFailure({  offerId, productId, error }))
    )
  )
)
));

}
