import { Component, Input, OnInit } from '@angular/core';
import {
  AffiliateLinkMinimizedModel,
  AffiliateLinkStatusEnum,
} from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-afiliate-link-active-suspended-label',
  templateUrl: './afiliate-link-active-suspended-label.component.html',
  styleUrls: ['./afiliate-link-active-suspended-label.component.css'],
})
export class AfiliateLinkActiveSuspendedLabelComponent implements OnInit {
  affiliateLinkStatusEnum = AffiliateLinkStatusEnum;
  @Input() status: AffiliateLinkStatusEnum = AffiliateLinkStatusEnum.active;
  constructor() {}

  ngOnInit(): void {}
}
