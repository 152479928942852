import { Injectable } from '@angular/core';

export enum EditModeType {
  none, input_edit, saved_changes
}

export enum EditPasswordModeType {
  none, input_edit, saved_changes
}

@Injectable({
  providedIn: 'root'
})

export class ProfileUiControlService {
  profileEditStatus = false;
  profilePictureContextStatus = false;
  profileAsideStatus = false;
  randProfile = 2143124;
  changesSaved: EditModeType = EditModeType.none;
  passwordChangesSaved: EditPasswordModeType = EditPasswordModeType.none;
  canNavigateAll = true;


  public getRandomeProfile() {
    this.randProfile = new Date().getTime();
  }

  public editModeOnChange() {
    if (this.profileEditStatus === true){

    this.changesSaved = EditModeType.input_edit;
    }
  }

  constructor() {this.getRandomeProfile()}
}
