import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, materialize, tap } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services';
import { VerificationModel } from '../models/verification.model';
@Injectable()
export class VerificationResolver
  implements Resolve<Observable<VerificationModel>>
{
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const email = route.queryParamMap.get('email');
    const sc = route.queryParamMap.get('sc');
    if (!email || !sc) {
      return of(email).pipe(map((re) => new VerificationModel(false)));
    } else {
      return of(email).pipe(
        map((re) => new VerificationModel(true, email, sc))
      );
    }
  }
}
