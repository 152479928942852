// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-empty-interactions {
  min-height: 300px;
  border: 2px dashed #e5e5e5;
  border-radius: 10px;
}

.empty_state {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.empty_state i {
  margin: auto;
  margin-bottom: 0px;
  font-size: 90px;
  color: #ccc;
}
.empty_state h3 {
  margin: 8px 0px;
  text-align: center;
  font-weight: normal;
}
.empty_state p {
  font-size: 14px;
  margin: 0px;
  color: #999;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/misc/no-results-search/no-results-search.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".user-empty-interactions {\n  min-height: 300px;\n  border: 2px dashed #e5e5e5;\n  border-radius: 10px;\n}\n\n.empty_state {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.empty_state i {\n  margin: auto;\n  margin-bottom: 0px;\n  font-size: 90px;\n  color: #ccc;\n}\n.empty_state h3 {\n  margin: 8px 0px;\n  text-align: center;\n  font-weight: normal;\n}\n.empty_state p {\n  font-size: 14px;\n  margin: 0px;\n  color: #999;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
