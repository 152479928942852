// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Features List */
ul.package {
  list-style: none;
  padding: 0;
  font-size: 1.2em;
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 29px; */
  text-align: left;
}

ul.package li {
  margin-bottom: 20px;
}

ul.package li:before {
  /* font-size: 1rem; */
  margin-right: 19px;
}

ul.package.feat-orange li:before {
  color: #ff6e3b;
}

ul.package.feat-primary li:before {
  color: #007bff;
}

ul.feat-mag li:before {
  color: #6a4bf6;
  font-family: "FontAwesome";
  content: "\\f00c";
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/plan-features-list/plan-features-list.component.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB;;;qBAGmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":["/* Features List */\nul.package {\n  list-style: none;\n  padding: 0;\n  font-size: 1.2em;\n  /* columns: 2;\n  -webkit-columns: 2;\n  -moz-columns: 2;\n  margin-top: 29px; */\n  text-align: left;\n}\n\nul.package li {\n  margin-bottom: 20px;\n}\n\nul.package li:before {\n  /* font-size: 1rem; */\n  margin-right: 19px;\n}\n\nul.package.feat-orange li:before {\n  color: #ff6e3b;\n}\n\nul.package.feat-primary li:before {\n  color: #007bff;\n}\n\nul.feat-mag li:before {\n  color: #6a4bf6;\n  font-family: \"FontAwesome\";\n  content: \"\\f00c\";\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
