// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Element Styling */
a:hover {
  text-decoration: none !important;
}

a.notification-element {
  background-color: #fff;
}

a.notification-element.unread {
  background-color: #ecf3ff;
}

a.notification-element.unread:hover {
  background-color: #e4edff;
}

a.notification-element:hover {
  background-color: #f2f3f5;
}

.notification-image {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  /* max-height: 50px; */
}
.notification-image .image-overlay {
  background-position: center top;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #ecf0f3 !important;
}

.toast-custom-notification {
  transition: all 150ms ease-in-out !important;
  text-align: left;
  padding: 1em 2em !important;
  background-color: #fff !important;
  border-radius: 14px;
  max-width: 500px;
  top: 0px;
  position: relative;
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.8;
}

.toast-custom-notification:hover {
  background-color: #fafafa;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/top-menu/fast-actions-buttons/affiliate-announcements-list/affiliate-announcements-element/affiliate-announcements-element.component.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,+BAA+B;EAC/B,sBAAsB;EACtB,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,4CAA4C;EAC5C,gBAAgB;EAChB,2BAA2B;EAC3B,iCAAiC;EACjC,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;EACR,kBAAkB;EAClB,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,wCAAwC;EACxC,UAAU;AACZ","sourcesContent":["/* Element Styling */\na:hover {\n  text-decoration: none !important;\n}\n\na.notification-element {\n  background-color: #fff;\n}\n\na.notification-element.unread {\n  background-color: #ecf3ff;\n}\n\na.notification-element.unread:hover {\n  background-color: #e4edff;\n}\n\na.notification-element:hover {\n  background-color: #f2f3f5;\n}\n\n.notification-image {\n  width: 50px;\n  height: 50px;\n  min-width: 50px;\n  min-height: 50px;\n  /* max-height: 50px; */\n}\n.notification-image .image-overlay {\n  background-position: center top;\n  background-size: cover;\n  border-radius: 50%;\n  border: 1px solid #ecf0f3 !important;\n}\n\n.toast-custom-notification {\n  transition: all 150ms ease-in-out !important;\n  text-align: left;\n  padding: 1em 2em !important;\n  background-color: #fff !important;\n  border-radius: 14px;\n  max-width: 500px;\n  top: 0px;\n  position: relative;\n  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);\n  pointer-events: auto;\n  cursor: pointer;\n  opacity: 0.8;\n}\n\n.toast-custom-notification:hover {\n  background-color: #fafafa;\n  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
