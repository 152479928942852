import { TemplateItem } from './../../../../components/user-dashboard/portal/portal.model';

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-template-item',
  templateUrl: './template-item.component.html',
  styleUrls: ['./template-item.component.css'],
})
export class TemplateItemComponent implements OnInit {
  @Input() template: TemplateItem;
  @Input() index: number;
  @Input() selected: boolean = false;
  @Output() onClickUse: EventEmitter<TemplateItem> =
    new EventEmitter<TemplateItem>();
  @Output() onClickUsePopup: EventEmitter<TemplateItem> =
    new EventEmitter<TemplateItem>();
  @Output() onClickUnUse: EventEmitter<TemplateItem> =
    new EventEmitter<TemplateItem>();
  constructor() {}

  ngOnInit(): void {}

  useAction(template: TemplateItem) {
    this.onClickUse.emit(template);
  }

  unUseAction(template: TemplateItem) {
    this.onClickUnUse.emit(template);
  }
}
