// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard Cards */

.templates-grid {
  display: inline-block;
  display: flex;
  flex-flow: wrap;
  flex-grow: 50px;
  gap: 3.5%;
}
.template-col{
 display: inline-block;
 float: left;
 width: 31%;
 text-align: center;
}
/*
.categories-area {
  width: 25%;
}

.category-item a {
  font-size: 1.3em;
  color: #919191;
  font-weight: 400;
  padding-bottom: 0.6em;
  transition: all .15s ease-in-out;
}

.category-item a:hover {
  color: #919191;
  font-weight: 500;
  padding-left: 3px;
}

.category-item a.active {
  color: #007bff;
  font-weight: 600;
  padding-left: 7px;
} */


.templates-category-swiper .swiper-slide {
  cursor: pointer;
}
.templates-category-swiper .swiper-slide:not(.swiper-slide-active) {
  opacity: 1 !important;
}
.badge-salesfunnel {
  background: #E89E3A;
  color: #fff;
}

.badge-optin {
  background: #D540BF;
  color: #fff;
}

.badge-webinar {
  background: #2ebef7;
  color: #fff;
}

.type-badge {
  min-width: 70px;
    font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
}

 @media screen and (max-width: 900px) {
  .newtemplate-col,.template-col{width: 100%;}
  .cards {
    padding: 0;
  }

}

@media screen and (min-width:900px) and (max-width: 1024px) {
  .newtemplate-col,.template-col{width: 46%;}

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/templates-layout.component.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,qBAAqB;EACrB,aAAa;EACb,eAAe;EACf,eAAe;EACf,SAAS;AACX;AACA;CACC,qBAAqB;CACrB,WAAW;CACX,UAAU;CACV,kBAAkB;AACnB;AACA;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;;AAGH;EACE,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;IACb,eAAe;EACjB,yCAAyC;AAC3C;;CAEC;EACC,+BAA+B,WAAW,CAAC;EAC3C;IACE,UAAU;EACZ;;AAEF;;AAEA;EACE,+BAA+B,UAAU,CAAC;;AAE5C","sourcesContent":["/* Dashboard Cards */\n\n.templates-grid {\n  display: inline-block;\n  display: flex;\n  flex-flow: wrap;\n  flex-grow: 50px;\n  gap: 3.5%;\n}\n.template-col{\n display: inline-block;\n float: left;\n width: 31%;\n text-align: center;\n}\n/*\n.categories-area {\n  width: 25%;\n}\n\n.category-item a {\n  font-size: 1.3em;\n  color: #919191;\n  font-weight: 400;\n  padding-bottom: 0.6em;\n  transition: all .15s ease-in-out;\n}\n\n.category-item a:hover {\n  color: #919191;\n  font-weight: 500;\n  padding-left: 3px;\n}\n\n.category-item a.active {\n  color: #007bff;\n  font-weight: 600;\n  padding-left: 7px;\n} */\n\n\n.templates-category-swiper .swiper-slide {\n  cursor: pointer;\n}\n.templates-category-swiper .swiper-slide:not(.swiper-slide-active) {\n  opacity: 1 !important;\n}\n.badge-salesfunnel {\n  background: #E89E3A;\n  color: #fff;\n}\n\n.badge-optin {\n  background: #D540BF;\n  color: #fff;\n}\n\n.badge-webinar {\n  background: #2ebef7;\n  color: #fff;\n}\n\n.type-badge {\n  min-width: 70px;\n    font-size: 16px;\n  font-family: Helvetica, Arial, sans-serif;\n}\n\n @media screen and (max-width: 900px) {\n  .newtemplate-col,.template-col{width: 100%;}\n  .cards {\n    padding: 0;\n  }\n\n}\n\n@media screen and (min-width:900px) and (max-width: 1024px) {\n  .newtemplate-col,.template-col{width: 46%;}\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
