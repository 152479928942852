import { takeUntil } from 'rxjs/operators';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';

import {  Router } from '@angular/router';
import {
  PortalSocketService,
  SocketConnectionStringStatus,
} from '../../services/portal-socket.service';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { AffiliateApi } from '../../services/affiliate.api.service';
import { SharedAffiliateDashboardService } from './shared-affiliate-dashboard.service';
import { NotificationsService } from '../../services/notifications.service';
import { SharedMessengerService } from '../../partials/messenger/shared-messenger.service';
import { MessengerThread } from '../../../models/owner-dashboard';

@Component({
  selector: 'app-affiliate-dashboard',
  templateUrl: './affiliate-dashboard.component.html',
  styleUrls: ['./affiliate-dashboard.component.css'],
})
export class AffiliateDashboardComponent implements OnInit, OnDestroy {
  socketConnectionStringStatus = SocketConnectionStringStatus;
  destroy = new Subject();

  destroy$ = this.destroy.asObservable();

  scrollSidebar: boolean = false;
  _toggleSidebar = false;
  constructor(
    public userDashboardService: UserDashboardService,
    public sharedAffiliateDashboardService: SharedAffiliateDashboardService,
    public socketService: PortalSocketService,
    private route: Router,
    public notificationsService: NotificationsService,
    public sharedMessengerService: SharedMessengerService,
    public portalSocketService: PortalSocketService
  ) {
    route.events.subscribe((val) => {
      this.toggleSidebar(false);
    });
    // this.socketService.webSocketConnect();

    fromEvent(document.body, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        this.userDashboardService.stickyScrollHandle();
      });
  }
  ngOnDestroy(): void {
    this.notificationsService.endAffiliateNotificationsListening();
    this.notificationsService.endMessengerListening();
  }

  async ngOnInit() {
    this.userDashboardService.switchToAffiliateMode();
    this.sharedMessengerService.getMessengerThreads(
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );
    await this.sharedAffiliateDashboardService.getMyOwnersList();
    await this.notificationsService.getAffiliateNotificationsList();
    this.notificationsService.startAffiliateNotificationsListening();
    // this.notificationsService.startMessengerListening();
  }

  goToNetworkById(id: number) {
    this.route.navigate(['affiliate', 'network', id]);
  }

  toggleSidebar(show: boolean = true) {
    this._toggleSidebar = show;
  }
}
