import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ThemeMediaTypeEnum,
  TemplateMediaItem,
} from 'src/app/components/user-dashboard/portal/portal.model';
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  SwiperOptions,
} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);


export class CarousellMediaItem {
  public title?: string;
  public content?: string;
  public image?: string;
  public type: ThemeMediaTypeEnum;
}


@Component({
  selector: 'app-dynamic-carousell',
  templateUrl: './dynamic-carousell.component.html',
  styleUrls: ['./dynamic-carousell.component.css'],
})
export class DynamicCarousellComponent implements OnInit {
  // @Input() mainImage: TemplateMediaItem;
  // @Input() images: TemplateMediaItem[];

  @Output() onDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  themeMediaTypeEnum = ThemeMediaTypeEnum;
  thumbsSwiper: any;

  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoplay: { delay: 2500, disableOnInteraction: true },
    navigation: {
      enabled: true,
    },
    direction: 'horizontal',
    pagination: { clickable: true },

    effect: 'slide',
    grabCursor: true,
    centeredSlides: true,
    zoom: {
      maxRatio: 2,
      toggle: true,
    },
    // preventClicks: true,
    // preventClicksPropagation: true,
  };
  _loadedImages: boolean = false;
  _images: CarousellMediaItem[] = [];

  constructor() {}

  @Input('images') set images(value: CarousellMediaItem[]) {
    // this._images = value;
    this._images.push(...value);

    this._loadedImages = true;
  }

  onSwiper([swiper]) {}
  onSlideChange() {}

  ngOnInit(): void {
    // this._images = [
    //   new ImageItem({ src: this.mainImage.url, thumb: this.mainImage.url }),
    //   // ... more items
    // ];
  }
}
