import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '../../../services';
import { DashboardTrackingActions } from './dashboard-tracking.actions';


@Injectable()
export class DashboardTrackingEffects {
  loadDashboardTracking$;

  constructor(
    private actions$: Actions,
    private api: ApiService
  ) {
    this.loadDashboardTracking$ = createEffect(() =>
      this.actions$.pipe(
        ofType(DashboardTrackingActions.load),
        mergeMap(({period}) =>
          this.api.getDashboardStatisticsData(period).pipe(
            map((response) => {

              return DashboardTrackingActions.loadSuccess({ dashboardTracking: response });
            }), // Add closing parenthesis here
            catchError((error) => of(DashboardTrackingActions.loadFailure({ error })))
          )
        )
      )
    );
  }
}
