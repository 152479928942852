import { PointerGuidanceService } from './../../../services/pointer-guidance.service';
import { Component, OnInit } from '@angular/core';

export class OffsetType {
  left: number;
  top: number;
}
@Component({
  selector: 'app-pointer-guide',
  templateUrl: './pointer-guide.component.html',
  styleUrls: ['./pointer-guide.component.css'],
})
export class PointerGuideComponent implements OnInit {
  constructor(public pointerGuidanceService: PointerGuidanceService) {}
  ngOnInit(): void {
    this.pointerGuidanceService.myPointer =
      document.getElementById('my-pointer');
    window.addEventListener('mousemove', this.mouseCoordinates);
  }

  mouseCoordinates = (event) => {
    if (!this.pointerGuidanceService.activateGuidance) {
      var offset: OffsetType = { left: event.clientX, top: event.clientY };

      this.pointerGuidanceService.movePointerWithOffset(offset);
      // this.myPointer.style.transform = `translate(${offset.left}px, ${offset.top}px)`;
    }
  };

  endTour() {
    setTimeout(() => {
      if (
        !!this.pointerGuidanceService.activateGuidance &&
        this.pointerGuidanceService.moveState == 'end'
      ) {
        this.pointerGuidanceService.pointerType = 'checkmark-circle';
        setTimeout(() => {
          this.pointerGuidanceService.activateGuidance = false;
          this.pointerGuidanceService.moveState = 'none';
          this.pointerGuidanceService.pointerType = 'pointer';
        }, 1000);
      }
    }, 500);
  }
}
