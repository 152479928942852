import {
  EditFunnelRequest,
  FunnelItem,
  FunnelSequenceItemTypeEnum,
  OwnerFunnelEmail,
  OwnerFunnelOffer,
  OwnerFunnelPage,
} from './../../../models/funnels';
import { UserWebsite } from './../portal/portal.model';

import { EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, map } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal from 'sweetalert2';

import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { EditFunnelSidebarEnum } from './edit/edit-funnel/edit-funnel.component';
import { CurrentTabEnum } from './edit/edit-funnel/funnel-page-edit/funnel-page-edit.component';
import {
  FormSubscriber,
  FormSubscribersListData,
} from 'src/app/models/funnels-subscribers';
import { OfferItem, OfferStausEnum } from '../offer/models/offer';

export enum SaveButtonStatusEnum {
  error = 'error',
  loading = 'loading',
  none = 'none',
  done = 'done',
}
@Injectable({
  providedIn: 'root',
})
export class SharedFunnelService {
  subscribersList: FormSubscribersListData = {
    subscribers: [],
    availableFunnels: [],
    requested: null,
  };

  sideMenuBackReference: { pageId: number } = { pageId: null };

  onClickEditPage: EventEmitter<OwnerFunnelPage> =
    new EventEmitter<OwnerFunnelPage>();

  onClickEditEmail: EventEmitter<OwnerFunnelEmail> =
    new EventEmitter<OwnerFunnelEmail>();

  funnels: FunnelItem[] = [];

  reorderStatus: boolean = false;
  currentEditingEmail: OwnerFunnelEmail;
  currentEditingOffer: OwnerFunnelOffer;
  currentEditingPage: OwnerFunnelPage;
  currentPageEditTab: CurrentTabEnum = CurrentTabEnum.settings;

  currentMenuTab: EditFunnelSidebarEnum = EditFunnelSidebarEnum.loading;
  closedMenu: boolean = true;
  scrollTo: string = null;
  saveButtonStatus: SaveButtonStatusEnum = SaveButtonStatusEnum.none;
  editStatus: 'error' | 'edited' | 'none' = 'none';
  timeoutReference: ReturnType<typeof setTimeout>;

  offers: OfferItem[];
  funnel: FunnelItem;

  funnelEditPayload: FunnelItem;

  loaded = false;
  currentFunnelId: number = null;
  deleting: boolean = false;

  pageStatus: 'edit' | 'view' | 'edit-checkout';

  parentPageId: number = null;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private router: Router,
    private arouter: ActivatedRoute,
    private toastr: ToastrService,
    private sharedOwnerDashboardService: SharedOwnerDashboardService
  ) {}

  async goToTab(slug: EditFunnelSidebarEnum) {
    this.currentMenuTab = slug;
  }

  // Funnel Flow Reorder
  enableFunnelReorder() {
    this.onCloseSideMenu(() => {
      this.reorderStatus = true;
    });
  }

  disableFunnelReorder() {
    this.reorderStatus = false;
  }

  async callScreenshot(link: string) {
    const screenShot$ = this.api.screenshot(link, false, () => {});
    let screenShot: { img: string } = await lastValueFrom(screenShot$);

    return screenShot;
  }

  async onReorderDone() {
    this.disableFunnelReorder();
    const funnel$ = this.api.changeFunnelOrder(
      this.currentFunnelId,
      this.funnelEditPayload.sequence,
      this.reorderErrorHandler
    );
    let funnel = await lastValueFrom(funnel$);

    this.funnel = this.funnelEditPayload = funnel;
    this.toastr.success('Done!', 'Reorder Saved Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    // await this.getFunnelById(this.currentFunnelId);
  }

  backToMenu() {}

  resetFunnelData() {}

  // Side Menu Functions

  onNavigationDisabled(callback = () => {}) {
    if (!this.sharedOwnerDashboardService.canNavigateAll) {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        callback();
      } else {
        return false;
      }
    } else {
      callback();
    }
  }

  onCloseSideMenu(callback = () => {}) {
    this.onNavigationDisabled(() => {
      this.backSideMenu();
      callback();
    });
  }

  backSideMenu() {
    if (!!this.sideMenuBackReference.pageId) {
      this.editPagById(
        this.sideMenuBackReference.pageId,
        CurrentTabEnum.automation
      );
      this.sharedOwnerDashboardService.enableNavigate();
      this.sideMenuBackReference.pageId = null;
    } else {
      this.closeMenu();
      setTimeout(() => {
        this.goToTab(EditFunnelSidebarEnum.none);
      }, 600);
      this.sharedOwnerDashboardService.enableNavigate();
    }
  }

  // UI Functions
  openMenu() {
    this.closedMenu = false;
  }
  closeMenu() {
    this.closedMenu = true;
  }

  updateSuccessToast() {
    this.startEdited('edited');
    this.toastr.success('Done!', 'Funnel Updated Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  startEdited(
    status: 'error' | 'edited' | 'none',
    msg: string = '',
    time: number = 5000
  ) {
    switch (status) {
      case 'edited':
        this.editStatus = status;
        setTimeout(() => {
          this.editStatus = 'none';
        }, 2000);
        break;
      case 'error':
        this.editStatus = status;
        if (!!time) {
          setTimeout(() => {
            this.editStatus = 'none';
          }, time);
        }
        break;
      case 'none':
        this.editStatus = 'none';
        break;
      default:
        break;
    }
  }

  scroll(id: string, position: ScrollLogicalPosition = 'start') {
    let el = document.getElementById(id);
    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth', block: position });
    }
  }

  saveDoneAction() {
    this.saveButtonStatus = SaveButtonStatusEnum.done;
    setTimeout(() => {
      this.saveButtonStatus = SaveButtonStatusEnum.none;
    }, 1000);
  }

  startSaveProgress() {
    this.saveButtonStatus = SaveButtonStatusEnum.loading;
  }

  resetSave() {
    this.saveButtonStatus = SaveButtonStatusEnum.none;
  }

  saveError() {
    this.saveButtonStatus = SaveButtonStatusEnum.error;
    setTimeout(() => {
      this.saveButtonStatus = SaveButtonStatusEnum.none;
    }, 1000);
  }

  // Email Item Functions
  async editAutomation(
    funnelEmail: OwnerFunnelEmail,
    withPageRef: number = null,
    parentPageId: number
  ) {
    if (!!withPageRef) {
      this.sideMenuBackReference.pageId = withPageRef;
    }
    this.goToTab(EditFunnelSidebarEnum.none);
    if (!!funnelEmail) {
      this.editEmail(funnelEmail, parentPageId);
    }
  }

  editEmail(item: OwnerFunnelEmail, parentPageId: number) {
    this.parentPageId = parentPageId;
    this.currentEditingEmail = item;
    this.goToTab(EditFunnelSidebarEnum.email);
    this.openMenu();
  }

  editOffer(item: OwnerFunnelOffer) {
    this.currentEditingOffer = item;
    this.goToTab(EditFunnelSidebarEnum.offer);
    this.openMenu();
  }

  async updateEmail(payload: OwnerFunnelEmail, parentId: number) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.updateFunnelEmail(
      this.funnel.id,
      payload,
      this.updateFunnelErrorHandler
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    // this.funnel = updateFunnel;
    const pageObjectIndex = this.funnel.sequence.findIndex(
      (a) => a.id == parentId && a.type == FunnelSequenceItemTypeEnum.page
    );

    // this.funnel = updateFunnel;
    if (pageObjectIndex > -1) {
      this.funnel.sequence[pageObjectIndex] =
        updateFunnel.sequence[pageObjectIndex];
    }

    this.toastr.success('Done!', 'Email Updated Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.enableNavigate();
    this.saveDoneAction();
  }

  // Funnel Offer Functions
  async addOffer(offerId: number) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.addOfferToFunnel(
      this.currentFunnelId,
      offerId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;
    this.toastr.success('Done!', 'Offer Added Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.saveDoneAction();
  }

  async deleteOffer() {
    const updateFunnel$ = this.api.deleteOfferFromFunnel(
      this.currentFunnelId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;
    this.toastr.success('Done!', 'Offer Removed Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async addAutomation(pageId: number, automationType: string = 'email') {
    const updateFunnel$ = this.api.addAutomationToPage(
      this.currentFunnelId,
      pageId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;
    this.toastr.success('Done!', 'Automation Added Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async deleteAutomation(automationId: number) {
    const updateFunnel$ = this.api.deleteAutomationFromFunnel(
      this.currentFunnelId,
      automationId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;
    this.toastr.success('Done!', 'Automation Deleted Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async addBlankPage(slotId: number) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.addBlankPageToFunnel(
      this.currentFunnelId,
      slotId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;

    this.toastr.success('Done!', 'Page Added Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.saveDoneAction();
  }

  async addOptinPage(slotId: number) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.addOptinPageToFunnel(
      this.currentFunnelId,
      slotId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;

    this.toastr.success('Done!', 'Page Added Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.saveDoneAction();
  }

  // Page Item Functions
  async updatePage(payload: OwnerFunnelPage, slotId: number) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.updateFunnelPage(
      this.currentFunnelId,
      payload,
      this.updateFunnelErrorHandler
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    // this.funnel = updateFunnel;

    const pageObjectIndex = this.funnel.sequence.findIndex(
      (a) => a.slotId == slotId && a.type == FunnelSequenceItemTypeEnum.page
    );

    // this.funnel = updateFunnel;
    if (pageObjectIndex > -1) {
      this.funnel.sequence[pageObjectIndex] =
        updateFunnel.sequence[pageObjectIndex];
    }

    this.toastr.success('Done!', 'Page Updated Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    this.saveDoneAction();
  }

  async deletePage(pageId: number) {
    const updateFunnel$ = this.api.deletePageFromFunnel(
      this.currentFunnelId,
      pageId
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.funnel = updateFunnel;
    this.toastr.success('Done!', 'Page Removed Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  editPage(
    item: OwnerFunnelPage,
    tab: CurrentTabEnum = CurrentTabEnum.settings
  ) {
    this.goToPageEditingTab(tab);
    this.currentEditingPage = item;
    this.goToTab(EditFunnelSidebarEnum.page);
    this.openMenu();
  }

  goToPageEditingTab(tab: CurrentTabEnum) {
    this.currentPageEditTab = tab;
  }

  editPagById(id: number, tab: CurrentTabEnum = CurrentTabEnum.settings) {
    let itemIndex = this.funnel.sequence.findIndex((a) => a.id == id);
    if (itemIndex > -1) {
      let item = this.funnel.sequence[itemIndex] as OwnerFunnelPage;

      this.currentEditingPage = item;
      this.goToPageEditingTab(tab);
      this.goToTab(EditFunnelSidebarEnum.page);
      this.openMenu();
    }
  }

  enableNavigate() {
    this.sharedOwnerDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedOwnerDashboardService.disableNavigate();
  }

  // Funnel Navigation Functions
  goToCreateFunnel() {
    this.sharedOwnerDashboardService.setBackRoute('Funnels');
    this.router.navigate(['dashboard', 'funnel', 'create']);
  }

  goToFunnels() {
    this.router.navigate(['dashboard', 'funnel']);
  }

  goToEdit(id: number = this.currentFunnelId) {
    this.router.navigate(['dashboard', 'funnel', id, 'edit']);
  }

  goToSubscribersList(id: number = this.currentFunnelId) {
    this.router.navigate(['dashboard', 'funnel', id, 'subscribers']);
  }

  async getOffers() {
    // this.loaded = false;
    const offers$ = this.api.getOffers(OfferStausEnum.published);
    this.offers = await lastValueFrom(offers$);
    // this.loaded = true;
  }

  // Funnel Main Actions
  async getAllFunnels() {
    this.loaded = false;
    const funnels$ = this.api.getAllFunnels(this.getFunnelErrorHandler);
    let funnels = await lastValueFrom(funnels$);
    this.funnels = funnels;

    this.loaded = true;
  }

  async getFunnelById(id: number) {
    this.loaded = false;
    const funnel$ = this.api.getFunnelById(id, this.getFunnelErrorHandler);
    this.funnel = await lastValueFrom(funnel$);
    this.funnelEditPayload = JSON.parse(JSON.stringify(this.funnel));

    this.loaded = true;
  }

  async getSubscribersByFunnel(funnelId: number = this.currentFunnelId) {
    this.loaded = false;
    const subscribersRequest$ = this.api.getPortalSubscribers(
      null,
      funnelId
    );
    let subscribers: FormSubscribersListData = await lastValueFrom(
      subscribersRequest$
    );

    this.subscribersList = subscribers;
    this.loaded = true;
  }

  async updateFunnel(funnelId = this.funnel.id, payload?: EditFunnelRequest) {
    this.startSaveProgress();
    const updateFunnel$ = this.api.updateFunnel(
      funnelId,
      payload,
      this.updateFunnelErrorHandler
    );
    let updateFunnel: FunnelItem = await lastValueFrom(updateFunnel$);

    this.updateSuccessToast();
    // this.funnel = updateFunnel;
    let funnelIndex = this.funnels.findIndex(
      (item) => item.id == updateFunnel.id
    );
    if (funnelIndex > -1) {
      this.funnels[funnelIndex] = updateFunnel;
      if (this.funnel?.id == updateFunnel.id) {
        this.funnel = this.funnelEditPayload = this.funnels[funnelIndex];
      }
    }

    this.saveDoneAction();
  }

  async cloneOfferAlert(offer: OfferItem) {
    Swal.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      buttonsStyling: false,
      icon: 'info',
      width: '650px',
      padding: '20px 70px',
      customClass: {
        title: 'd-block',
        container: 'text-center',
        confirmButton: 'btn btn-success m-1',
        cancelButton: 'btn btn-danger m-1',
      },
      inputPlaceholder:
        'I confirm that I want to <strong class="text-info">Clone</strong> this offer.',
      title:
        'Are you sure you want to clone <strong>' +
        offer.title +
        '</strong> offer?',
      html: 'This <strong>Offer</strong> will be duplicated',
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const cloneRequest$ = this.api.cloneOffer(offer.id);
        const cloneRequest = await lastValueFrom(cloneRequest$);
        this.toastr.success('Done!', 'Offer Cloned Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return cloneRequest;
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value) {
        this.offers.unshift(res.value);
      } else {
        return false;
      }
    });
    const deleteCheck = Swal.getInput();
    const confirmCheck = Swal.getConfirmButton();
    const cancelButton = Swal.getCancelButton();

    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Cloning, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

  deleteFunnel(funnel: FunnelItem = this.funnel, fromEdit = false) {
    const SwalDelete = Swal.mixin({
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger m-1',
        cancelButton: 'btn btn-lg btn-success m-1',
      },
      buttonsStyling: false,
    });

    SwalDelete.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      inputPlaceholder: 'I confirm that I want to Delete this Funnel.',
      title: 'You’re about to Delete your Funnel',
      html:
        "<span style='font-size:1.2em'>Are you sure you want to Delete</span><br>" +
        funnel.title +
        `<br>
      <span class='badge badge-light-danger text-danger badge-lg mt-3' style='text-transform:uppercase'>
      <i class="ler-duotone ler-trash text-danger fs-2 me-2">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
      </i> this step cannot be undone
      </span>`,
      icon: 'warning',
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const DeleteRequest$ = this.api.deleteFunnelById(funnel.id);
        const DeleteRequest = await lastValueFrom(DeleteRequest$);
        if (!!fromEdit) {
          await this.getAllFunnels();
        }
        this.toastr.success('Done!', 'Funnel Deleted Successfully', {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return DeleteRequest;
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value?.success) {
        if (!!fromEdit) {
          this.sharedOwnerDashboardService.goBack();
        } else {
          const funnelIndex = this.funnels.findIndex((a) => a.id === funnel.id);

          if (funnelIndex > -1 && !fromEdit) {
            setTimeout(() => {
              funnel.delete = true;
              setTimeout(() => {
                this.funnels.splice(funnelIndex, 1);
              }, 1000);
            }, 500);
          }
        }
      }
    });
    const deleteCheck = SwalDelete.getInput();
    const confirmCheck = SwalDelete.getConfirmButton();
    const cancelButton = SwalDelete.getCancelButton();
    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    SwalDelete.getIcon().className = 'swal2-icon swal2-error swal2-icon-show';
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Deleting, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

  // Funnel Auxiliary Functions
  async getFunnelAvailablePages(funnelId: number = this.funnel.id) {
    const funnelPages$ = this.api.getFunnelAvailablePages(funnelId);
    let funnelPages = await lastValueFrom(funnelPages$);

    this.funnel.availablePages = funnelPages;
  }

  // Error Handlers
  updateFunnelErrorHandler = (error: any) => {
    this.saveError();
    this.toastr.error('Error!', 'Something went wrong', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  };
  getFunnelErrorHandler(error: any) {
    Swal.fire({
      icon: 'error',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'bg-primary',
        cancelButton: 'bg-danger',
      },
      confirmButtonText: 'Back to Offers',
      showCancelButton: false,
      preConfirm: (result) => {
        this.goToFunnels();
      },

      title: 'Something went wrong',
      html: `<span>Cannot load this Funnel</span>`,
    });
  }

  updateErrorMessage(
    title: string,
    type: 'error' | 'warning' | 'info' | 'success' = 'info',
    content: string
  ) {
    Swal.fire({
      icon: type,
      title: '<span>' + title + '</span>',
      html: `
      <span class="text-${
        type === 'error' ? 'danger' : 'black-50'
      } text-start" style="white-space: pre-wrap;">${content}</span>`,
    });
  }

  reorderErrorHandler = async () => {
    this.toastr.success('Error', 'Something went wrong!', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    await this.getFunnelById(this.currentFunnelId);
  };
}
