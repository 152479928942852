// Import necessary modules and types
import { Action, createReducer, on } from '@ngrx/store';
import {
  CurrentCourseHeirarchyActions,
  updateCurrentCourseHeirarchyStructure,
  MoveCourseItemActions,
  clearCurrentCourse,
  CreateCourseItemActions,
  ApplyTemplateToCourseAction,
  UpdateCourseSettingsActions,
} from './course.actions';
import {
  CourseDetailsModelBase,
  CourseHeirarchyModel,
  CourseHierarchyItemTypeEnum,
  StudentItem,
} from 'src/app/components/user-dashboard/product/models/course';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { forgetSession } from '../../user/auth/auth.actions';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { CourseHierarchyItem } from 'src/app/components/user-dashboard/product/models/course';
import { clearCurrentProduct } from '../products.actions';
import {  DeleteCourseItemActions, UpdateCourseItemActions, loadCourseItemFullDetails } from './course-items/course-items.actions';
import { CreateQuizQuestionActions, DeleteQuizQuestionActions, MoveQuizQuestionActions, UpdateQuizQuestionActions } from './course-items/course-quiz-item/course-quiz-items.actions';

// Define the state interface for the course
export interface CourseState {
  
  currentCourseHeirarchy: CourseHeirarchyModel;
  currentCourseHeirarchyLoadProcess: {
    status: SystemProcessLoadingStatus;
    loadingMessage?: string;
    error: {processType: SystemProcessLoadingStatus, error: any}
  };
 

  loaded: boolean;
  error: any;
}

// Define the initial state for the course
const initialState: CourseState = {
  currentCourseHeirarchy: {
    hierarchy: { items: [] },
    heirarchy_source: null,
    heirarchy_status: null,
    settings: null,
    usage_count: null,
  },
  currentCourseHeirarchyLoadProcess: { status: 'none', error: null },
  
  loaded: false,
  error: null,
};

// Create the course reducer
export const courseReducer = createReducer(
  initialState,

  // Load Current Course Hierarchy
  on(CurrentCourseHeirarchyActions.load, (state) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: { status: 'loading', error: null },
  })),
  on(CurrentCourseHeirarchyActions.loadSuccess, (state, { heirarchy }) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: { status: 'done', error: null },
    currentCourseHeirarchy: {
      ...state.currentCourseHeirarchy,
      hierarchy: heirarchy.hierarchy,
      heirarchy_status: heirarchy.heirarchy_status,
      heirarchy_source: heirarchy.heirarchy_source,
      settings: heirarchy.settings,
      usage_count: heirarchy.usage_count,
    },
  })),
  on(CurrentCourseHeirarchyActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    currentCourseHeirarchyLoadProcess: {
      status: 'error',
      error: {processType: 'loading', error: error.message},
    },
  })),

 
  // Move Course Item
  on(MoveCourseItemActions.move, (state) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'updating',
      loadingMessage: 'Moving',
      error: null,
    },
  })),

  on(MoveCourseItemActions.moveFailure, (state, {error}) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'error',
      error: {processType: 'updating', error: error.message},
    },
  })),


  // Create Course Item
  on(CreateCourseItemActions.create, (state) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'creating',
      loadingMessage: 'Creating',
      error: null,
    },
  })),

  on(CreateCourseItemActions.failure, (state, {error}) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'error',
      error: {processType: 'creating', error: error.message},
    },
  })),


    // Apply Template Course to Course Heirarchy
    on(ApplyTemplateToCourseAction.set, (state) => ({
      ...state,
      currentCourseHeirarchyLoadProcess: {
        ...state.currentCourseHeirarchyLoadProcess,
        status: 'applying_template',
        loadingMessage: 'Processing your AI-generated course',
        error: null,
      },
    })),
  
    on(ApplyTemplateToCourseAction.failure, (state, {error}) => ({
      ...state,
      currentCourseHeirarchyLoadProcess: {
        ...state.currentCourseHeirarchyLoadProcess,
        status: 'error',
        error: {processType: 'applying_template', error: error.message},
      },
    })),



    // Update Current Course Settings
    on(UpdateCourseSettingsActions.update, (state) => ({
      ...state,
      currentCourseHeirarchyLoadProcess: {
        ...state.currentCourseHeirarchyLoadProcess,
        status: 'updating_settings',
        loadingMessage: 'Updating Course Settings',
        error: null,
      },
    })),
  
    on(UpdateCourseSettingsActions.failure, (state, {error}) => ({
      ...state,
      currentCourseHeirarchyLoadProcess: {
        ...state.currentCourseHeirarchyLoadProcess,
        status: 'error',
        error: {processType: 'updating_settings', error: error.message},
      },
    })),


  // From Outside Actions

  on(loadCourseItemFullDetails.success, (state, {loadedItem}) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, loadedItem);
    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),

  
  on(UpdateCourseItemActions.success, (state, { updatedItem }) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, updatedItem);

    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),
 
  on(CreateQuizQuestionActions.success, (state, { updatedItem }) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, updatedItem);

    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),
  
  on(MoveQuizQuestionActions.success, (state, { updatedItem }) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, updatedItem);

    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),


  on(UpdateQuizQuestionActions.success, (state, { updatedItem }) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, updatedItem);

    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),


  on(DeleteQuizQuestionActions.success, (state, { updatedItem }) => {
    const updatedHierarchy = updateParentAndNestedItem(state.currentCourseHeirarchy.hierarchy.items, updatedItem);

    return {
      ...state,
      currentCourseHeirarchy: {
        ...state.currentCourseHeirarchy,
        hierarchy: {
          ...state.currentCourseHeirarchy.hierarchy,
          items: updatedHierarchy,
        },
      },
    };
  }),


  // Generic Actions
  on(CurrentCourseHeirarchyActions.resetProcessingStatus, (state) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'none',
      loadingMessage: 'Updating',
      error: null,
    },
  })),

  on(updateCurrentCourseHeirarchyStructure, (state, { heirarchy }) => ({
    ...state,
    currentCourseHeirarchyLoadProcess: {
      ...state.currentCourseHeirarchyLoadProcess,
      status: 'done',
      loadingMessage: null,
      error: null,
    },
    currentCourseHeirarchy: {
      ...state.currentCourseHeirarchy,
      hierarchy: heirarchy.hierarchy,
      heirarchy_status: heirarchy.heirarchy_status,
      heirarchy_source: heirarchy.heirarchy_source,
      settings: heirarchy.settings,
      usage_count: heirarchy.usage_count,
    },
  })),
  on(forgetSession, clearCurrentProduct, clearCurrentCourse, () => initialState)
);


//Separate Functions
const updateNestedItem = (items, updatedItem) => {
  return items.map(item => {
    if (item.id === updatedItem.id && item.type === updatedItem.type) {
      return { ...item, ...updatedItem };
    }
    if (item.items) {
      return { ...item, items: updateNestedItem(item.items, updatedItem) };
    }
    return item;
  });
};

const updateParentAndNestedItem = (items, updatedItem) => {
  return items.map(item => {
    if (item.id === updatedItem.id && item.type === updatedItem.type) {
      return { ...item, ...updatedItem, items: updateNestedItem(item.items || [], updatedItem) };
    }
    if (item.id === updatedItem.parentId) {
      return { ...item, items: updateNestedItem(item.items, updatedItem) };
    }
    if (item.items) {
      return { ...item, items: updateParentAndNestedItem(item.items, updatedItem) };
    }
    return item;
  });
};
