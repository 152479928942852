import {
  BrowserNameEnum,
  DeviceTypeEnum,
  OperatingSystemEnum,
} from './../components/profile/security-settings/active-sessions-section/active-session-item/active-session-item.component';
import {
  AffiliateNotificationTypeEnum,
  NotificationStatusEnum,
} from './affiliates-dashboard';
import {
  MediaLibraryFileModel,
  CurrenciesModel,
  MoneyModel,
} from './sales-funnel';
import { Payment_Gateway_Enum, Payout_Gateway_Enum } from './portal';
import { User } from './user';
import { DashboardSessionModeType } from '../shared/services/user-dashboard.service';
import { SalesLogElement, Sales_Type_Enum } from './system-earnings';
import { OfferItem } from '../components/user-dashboard/offer/models/offer';
import { SeriesMapDataOptions } from 'highcharts';
import { NotificationUserTypeEnum } from '../shared/services/notifications.service';
import { Country } from './country';
import { EventEmitter } from '@angular/core';

export class LocalPaginationConfigData {
  currentPage: number;
  numberInPage: number;
  startIndex: number;
  endIndex: number;
}
export class LocalPaginationConfig {
  OnChange: EventEmitter<LocalPaginationConfigData> =
    new EventEmitter<LocalPaginationConfigData>();
  initialValueStore: LocalPaginationConfigData;

  currentPage: number;
  numberInPage: number;
  startIndex: number;
  endIndex: number;

  constructor(initialValue: LocalPaginationConfigData) {
    this.initialValueStore = JSON.parse(JSON.stringify(initialValue));

    this.currentPage = initialValue.currentPage;
    this.numberInPage = initialValue.numberInPage;
    this.startIndex = initialValue.startIndex;
    this.endIndex = initialValue.endIndex;
  }

  public resetInitialValue() {
    this.currentPage = this.initialValueStore.currentPage;
    this.numberInPage = this.initialValueStore.numberInPage;
    this.startIndex = this.initialValueStore.startIndex;
    this.endIndex = this.initialValueStore.endIndex;
    this.OnChange.emit(this);
  }
}
export class StandardPagination {
  currentPage?: number;
  countInCurrentPage?: number;
  totalItems?: number;
  totalPages?: number;
  maxPreviousPage?: number;
  maxNextPage?: number;
}

// --------------------- Announcements -----------------------------------------

export class CreateAnnouncementRequest {
  public structuredName?: { label: string; othersCount: number };
  constructor(
    public title: string = '',
    public content: string = '',
    public mediaId: number = null,
    public affiliateLinkIds: number[] = [],
    public affiliateLink: AffiliateLinkMinimizedModel[] = [],
    public offerIds: number[] = null,
    public affiliateIds: number[] = [],
    public affiliates: AnnouncementAffiliate[] = []
  ) {}

  public getRequestValues(): any {
    return {
      title: this.title,
      content: this.content,
      mediaId: this.mediaId,
      affiliateIds: this.affiliateIds,
      affiliateLinkIds: this.affiliateLinkIds,
      offerIds: this.offerIds,
    };
  }
  public clearAffiliateLinks() {
    this.affiliateLink = [];
    this.affiliateLinkIds = [];
  }
  public clearAffiliate() {
    this.affiliates = [];
    this.affiliateIds = [];
  }

  public toggleAffiliateLink(affiliateLink: AffiliateLinkMinimizedModel) {
    const affiliateLinkIdIndex = this.affiliateLinkIds?.findIndex(
      (a) => a == affiliateLink.id
    );

    const affiliateLinkIndex = this.affiliateLink?.findIndex(
      (a) => a.id == affiliateLink.id
    );

    if (!!affiliateLinkIdIndex && affiliateLinkIdIndex == -1) {
      this.affiliateLinkIds.push(affiliateLink.id);
      this.affiliateLink.push(affiliateLink);
    } else {
      this.affiliateLinkIds.splice(affiliateLinkIdIndex, 1);
      this.affiliateLink.splice(affiliateLinkIndex, 1);
    }
  }

  public toggleAffiliate(affiliate: AffiliateMinimizedModel) {
    const affiliateIdIndex = this.affiliateIds?.findIndex(
      (a) => a == affiliate.id
    );

    const affiliateIndex = this.affiliates?.findIndex(
      (a) => a.affiliate.id == affiliate.id
    );

    if (!!affiliateIdIndex && affiliateIdIndex == -1) {
      this.affiliateIds.push(affiliate.id);
      this.affiliates.push({ affiliate });
    } else {
      this.affiliateIds.splice(affiliateIdIndex, 1);
      this.affiliates.splice(affiliateIndex, 1);
    }
  }
}
export class AnnouncementObject {
  id: number;
  public structuredName: { label: string; othersCount: number };
  owner: AffiliateMinimizedModel;
  affiliates: AnnouncementAffiliate[]; // Will be empty array if the caller is an affiliate
  affiliateCount: number;
  readRate: number; // Will have a value if the caller is the owner, otherwise = 0
  image: MediaLibraryFileModel;
  createdAt: number;
  title: string;
  content: string;
  status: AnnouncementAffiliateReadStatusEnum; // ['New', 'Seen'] will be sent only if the caller is an affiliate
  affiliateLinks: AffiliateLinkMinimizedModel[]; // Will have values if this announcement is about specific affiliate links
  offers: Partial<OfferItem>[]; // Will have values if this announcement is about specific offers
}

export class AnnouncementAffiliate {
  affiliate: AffiliateMinimizedModel;
  status?: AnnouncementAffiliateReadStatusEnum; // 'read' | 'unread'
  readDate?: number;
}

export enum AnnouncementAffiliateReadStatusEnum {
  read = 'read',
  unread = 'unread',
}
// ----------------------------------------- Refund ------------------------------
export enum RefundStatusEnum {
  actionRequired = 'actionRequired',
  Refunded = 'Refunded',
  Refused = 'Refused',
}

export enum RefundActionEnum {
  Refunded = 'Refunded',
  Refused = 'Refused',
}

export class RefundItem {
  id: number;
  customer: CustomerObject;
  reason: string;
  // TODO: Ask the backend developer to remove the commented properties
  offer: Partial<OfferItem>;
  status: RefundStatusEnum;
  requestedAt: number;
  transactionDate?: number;
  refundAmount?: MoneyModel;
  totalCommissions?: MoneyModel;
  paymentMethod: Payment_Gateway_Enum;
  commissions?: CommissionObject[];
  resolvedAt: number;
  sale: SalesLogElement;
}

export enum CommissionTypeEnum {
  lev1 = 'lev1',
  lev2 = 'lev2',
  vip = 'vip',
}
// --------------------------------------------- Product -----------------------------------------------

export class PeriodBasedObject {
  period?: PeriodTypeValue;
  unit?: string;
}

// Dashboard Data
export class TotalTrackingData {
  totalClicks: number;
  conversion: number;
  conversionRate: number;
  affiliateCount: number;
  affiliateLinkCount: number;
}

export class EarningsTrackingData {
  salesLast30: number;
  revenueLast30: number;
  revenueAllTime: number;
  duePayout: number;
}

export class AffiliatesTrackingData {
  totalClicks: number;
  conversion: number;
  conversionRate: number;
  lastSaleDate: number;
}

export class AffiliateDashboardData {
  hash: string;
  signupLink: { url: string; clicks?: number; conversion?: number };
  loginLink: { url: string; clicks?: number };
}

export class AffiliateMinimizedModel {
  public id: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public note?: string;
  public status?: AffiliatesInvitationStatusEnum;
  public image?: any; // AvatarResult | string
  public avatar?: AvatarResult;
  public affiliateLinks?: AffiliateLinkMinimizedModel[];
  public isVip?: boolean;
  public delete?: boolean;
  public image_id?: number[];
  public invitedAt?: number;
  public joinedAt?: number;
  public preferredPaymentMethod?: Payout_Gateway_Enum[];
  public messengerThreadId?: number;
  public unreadMessagesCount?: number;
}

export enum AffiliatesInvitationStatusEnum {
  pending = 'invited',
  joined = 'joined',
  rejected = 'rejected',
  blocked = 'blocked',
}

export enum AffiliateLinkStatusEnum {
  active = 'active',
  inactive = 'inactive',
  suspended = 'suspended',
}
export class AffiliateLinkMinimizedModel {
  public id?: number;
  public name: string;
  public link?: string;
  public offer?: OfferItem;
  public offerId?: number;
  public vipAffiliate?: AffiliateMinimizedModel;
  public affiliates?: AffiliateMinimizedModel[];
  public vipEmail?: string;
  public affiliateEmails?: string[];
  public image?: string;
  public type: 'general' | 'vip';
  public visibility?: 'public' | 'private';
  public commissions: CommissionsModel;
  public affiliateCount: number;
  // TODO: Combine Statistics
  public clicks?: number;
  public conversion?: number;
  public conversionRate?: number;
  public isVip?: boolean;

  stats: { sales: number; revenue: number; commissions: number };

  public status: AffiliateLinkStatusEnum;
  public updatedAt?: number;
  public createdAt?: number;
}

export class CommissionsModel {
  public lev1: number;
  public lev2?: number;
  public vip?: number;
}

// Affiliate Dashboard
export class AvatarResult {
  big: string;
  medium: string;
  small: string;
}

export class PeriodResultModel {
  public period: PeriodTypeModel;
  public unit?: PeriodUnitModel;
}
export class PeriodTypeModel {
  public index?: number;
  public displayName: string;
  public enum?: PeriodTypeEnum;
  public value?: PeriodTypeValue;
  public by?: PeriodUnitEnum;
  public outOfRange?: boolean;

  constructor(periodModel: PeriodTypeModel) {
    Object.assign(this, {...periodModel, value: null});
  }

  setPeriodStartEnd?(startDate: number, endDate: number) {
    this.value = {startDate, endDate};
  }
}

export class PeriodTypeValue {
  public startDate: number;
  public endDate: number;
}

export enum PeriodTypeEnum {
  all_time = 'all_time',
  lastyear = 'lastyear',
  last6months = 'last6months',
  last3months = 'last3months',
  last30days = 'last30days',
  last7days = 'last7days',
  custom = 'custom',
}

export class PeriodUnitModel {
  public index?: number;
  public displayName?: string;
  public enum: PeriodUnitEnum;
}

export class EveryDueUIModel {
  public displayName: string;
  public value: number;
}

export enum PeriodUnitEnum {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}
export class ChartConfigModel {
  public view: number[];
  public gradient?: boolean;
  public legend: boolean;
  public showLabels: boolean;
  public animations: boolean;
  public xAxis: boolean;
  public yAxis: boolean;
  public showYAxisLabel: boolean;
  public showXAxisLabel: boolean;
  public xAxisLabel: string;
  public yAxisLabel: string;
  public timeline: boolean;
  public colorScheme: {
    domain: string[];
  };
}

export enum OwnerNotificationTypeEnum {
  invitation_accepted = 'invitation_accepted',
  invitation_rejected = 'invitation_rejected',
  invitation_blocked = 'invitation_blocked',
  general = 'general',
  new_sale = 'new_sale',
  refund = 'refund',
  payout_critical = 'payout_critical',
  payout_due = 'payout_due',
}

export class NotificationElement {
  public id: number;
  public content: string;
  public type: AffiliateNotificationTypeEnum | OwnerNotificationTypeEnum;
  public status: NotificationStatusEnum;
  public image?: string;
  public createdAt?: number;
  public notificationUserType: NotificationUserTypeEnum;
  public target: NotificationTarget;
}

export class NotificationTarget {
  label: string;
  ownerId?: number;
  affiliateId?: number;
  affiliateLinkId?: number;
  announcementId?: number;
  announcementTitle?: string;
  periodId?: number;
  refundId?: number;
  saleId?: number;
  targetUrl?: string;
}

export class NotificationsResponseModel {
  unreadNotificationsCount: number;
  unreadInvitaionsCount?: number;
  unreadAnnouncementsCount?: number;
  unreadMessagesCount?: number;
  public announcements?: NotificationElement[];
  public notifications: NotificationElement[];
  public invitations?: NotificationElement[];
}

// export class OfferMini {
//   id: number;
//   title: string;
//   image?: string;
// }
export class ChartValue {
  name: string;
  time: number;
  value: number;
}

export class ChartData extends PeriodBasedObject {
  name?: string;
  series: ChartValue[];
  chartData: ChartData[];
}

// Affiliates Earnings Classes
export class AffiliatesEarnings {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  image: string;
  invitedAt: number;
  joinedAt: number;
  sales: number;
  revenue: number;
  commission: number;
  series: ChartValue[];
  inCompare: boolean;
}

export class AffiliateEarningsList extends PeriodBasedObject {
  affiliateEarnings: AffiliatesEarnings[];
}

// Affiliate Links Earnings Classes
export class AffiliateLinkEarnings {
  id?: number;
  name: string;
  offer?: Partial<OfferItem>;
  type?: string;
  visibility?: string;
  image?: string;
  sales?: number;
  revenue?: number;
  commission?: number;
  series: ChartValue[];
  inCompare: boolean;
}

export class AffiliateLinkEarningsList extends PeriodBasedObject {
  links: AffiliateLinkEarnings[];
}

// Affiliate Earnings Vs Commissions Classes
export class EarningsVsCommissions extends PeriodBasedObject {
  // earrnings: ChartValue[];
  // commissions: ChartValue[];
  chartData: { name: string; series: ChartValue[] }[];
}

export class SalesGraphElement extends PeriodBasedObject {
  name?: string;
  data?: { x: any[]; y: any[] };
  totalEarnings?: MoneyModel;
}

export class SalesByCountryGraphElement extends PeriodBasedObject {
  salesByCountry?: { x: any[]; y: any[] };
}

export class SalesByTechGraphElement extends PeriodBasedObject {
  data?: { x: any[]; y: any[] };
}

export class TitleBreadcrumbsConfig {
  title: { main: string; sub: string };
  breadcrumb: BreadcrumbsElement[];
  actionsSelectors: any[] = [];
}

export class BreadcrumbsElement {
  url?: string;
  label: string;
  isLastNode?: boolean;
  tooltip?: string;
}
export class NavigationMenuItem {
  tagId?: string;
  tooltip?: { text: string; placement: string };
  customClass?: string;
  routerLink?: string;
  routerActiveArray?: string[];
  expandable?: {
    active: boolean;
    status: boolean;
  };
  routerLinkExact?: boolean;
  iconName?: string;
  label: string;
  subItems?: NavigationMenuItem[];
  separator?: boolean;
}

// My Affiliate Payouts
export class OwnerPaidPayoutsPerAffiliate {
  affiliate: AffiliateMinimizedModel;
  // paidTotal: number;
  payouts: PayoutMoreDetailsObject[];
}

export class OwnerUnpaidPayoutsPerAffiliate {
  affiliate: AffiliateMinimizedModel;
  // unpaidTotals: AffiliateUnpaidPayoutTotals;
  payouts: PayoutMoreDetailsObject[];
}

class CommissionObject {
  id: number;
  affiliate: AffiliateMinimizedModel;
  type: string;
  amount: MoneyModel;
  currency: string;
  percentage: string;
  time: number;
}

export class DatePeriodObject {
  id: number;
  label: string;
  startDate: string;
  endDate: string;
}

export class PayoutsExportList extends StandardPagination {
  periodsPayouts: PayoutsExportObject[];
}

export class PayoutsExportObject extends DatePeriodObject {
  amount: MoneyModel;
  count: number;
  status: PayoutStatusEnum;
  dueDate: number;
  details: PayoutsExportSubDetailsObject[];
}

export class PayoutsExportSubDetailsObject {
  id?: number;
  label: string;
  paymentMethod: Payout_Gateway_Enum;
  amount: MoneyModel;
  status: PayoutStatusEnum;
}

export class MarkPayoutAsProccessedResponse {
  success: boolean;
  error: string;
  payoutSubDetail: PayoutsExportSubDetailsObject;
}

export class PayoutMoreDetailsObject {
  id: number;
  affiliate: AffiliateMinimizedModel;
  amount: MoneyModel;
  status?: string;
  dueDate: number;
  details?: PayoutMoreDetailsSubDetailsObject[];
  totals?: {
    critical: MoneyModel;
    dueSoon: MoneyModel;
    pending: MoneyModel;
  };
}

export class PayoutMoreDetailsSubDetailsObject {
  amount: MoneyModel;
  status: string;
  dueDate: number;
}

export class PayoutLogsList {
  tempID: number;
  periodInfos: DatePeriodObject[];
  requestedPeriod: DatePeriodObject;
  affiliatePayouts: PayoutLogsObject[];
}

export class PayoutMoreDetailsList {
  tempID: number;
  periodInfos: DatePeriodObject[];
  requestedPeriod: DatePeriodObject;
  payoutMoreDetailsList: PayoutMoreDetailsObject[];
}

export class PeriodBasedLoading {
  navigator: boolean;
  data: boolean;
}

export class PayoutLogsObject {
  id: number;
  affiliate: AffiliateMinimizedModel;
  amount: MoneyModel;
  paymentMethod: Payout_Gateway_Enum;
  processDate: number;
  dueDate: number;
  details?: PayoutLogsSubDetailsObject[];
}

export class PayoutLogsSubDetailsObject {
  amount: MoneyModel;
  paymentMethod: Payout_Gateway_Enum;
  processDate: number;
  dueDate: number;
}

export enum PayoutStatusEnum {
  'due' = 'due',
  'due_soon' = 'due_soon',
  'partially_processed' = 'partially_processed',
  'processed' = 'processed',
  'critical' = 'critical',
  'pending' = 'pending',
  'hold' = 'hold',
}

export class PayoutTotals {
  previousPayout: PayoutResponse;
  nextPayout: PayoutResponse;
  pending: PayoutResponse;
}
export class PayoutResponse {
  id: number;
  name: string;
  amount: MoneyModel;
  startDate: number;
  dueDate: number;
  status: string;
  paymentMethod: string;
}

export class CustomerObject {
  name: string;
  email: string;
}

export class CustomerDetailedObject extends CustomerObject {
  country: Country;
  browser: BrowserNameEnum;
  os: OperatingSystemEnum;
  device: DeviceTypeEnum;
}

export class EngagementByCountry {
  name: string;
  iso2: string;
  sales: number;
  revenue: number;
  commissions: number;
}

export class DemographicMapChart {
  chartData: (number | [string, number] | SeriesMapDataOptions)[];
}
export class SalesByCountryDetailed {
  engagementByCountry: EngagementByCountry[];
}

export class SalesByTechDetailed {
  data: EngagementByTech[];
}

export class EngagementByTech {
  os?: OperatingSystemEnum;
  device?: DeviceTypeEnum;
  browser?: BrowserNameEnum;
  sales: number;
  revenue: number;
}

// Messenger
export class MessengerData {
  public threads: MessengerThreadsPaginated;
  public currentThread?: MessengerThread;
  public currentThreadId?: number;
  public currentThreadIndex?: number;
}

export class IPGalleryItem {
  public imageId: number;
  public messageId: string;
  public id: number;
  public src: string;
  public thumb: string;
}
// Begin: Pagination Config
export class MessengerThreadsPaginated extends StandardPagination {
  data: MessengerThread[];
  unreadMessagesCount: number;
}

export class MessengerMessagesPaginated extends StandardPagination {
  data: MessengerMessage[];
}
// End: Pagination Config
export class MessengerThread {
  public id: number;
  public name?: string;
  public structuredName: { label: string; othersCount: number };
  public user: MessengerUser;
  public users: MessengerUser[];
  public messages?: MessengerMessagesPaginated;
  public status: MessengerThreadStatusEnum;
  public type: DashboardSessionModeType;
  public lastActivity: {
    message: string;
    time: number;
  };
  eventType?: Messages_Event_Type_Enum;
}

export enum Messages_Event_Type_Enum {
  newMessage = 'newMessage',
  seenUpdate = 'seenUpdate',
}

export class UploadProgressFile {
  id?: number;
  name: string;
  size: number;
  percentage?: string;
  type?: string;
  error?: {
    status: boolean;
    message: string;
  };
  done?: boolean;
  extension?: string;
  preview?: string;
}
export class MessengerMessage {
  public senderStatus: MessageSenderTypeEnum;
  public sender: MessengerUser;
  public content: MessageBody[];
}

export class SendMessageRequest {
  threadId?: number;
  threadUserIds?: number[];
  threadUsers?: AffiliateMinimizedModel[];
  threadName?: string;
  threadDescription?: string;
  text?: string;
  mediaIds?: number[];
}

export class MessageBody {
  id: number;
  medias?: MediaLibraryFileModel[];
  lastSeenUsers?: MessengerUser[];
  time?: number;
  text: string;
  type: MessageTypeEnum;
}
export class MessengerUser {
  id: number;
  username: string;
  email?: string;
  firstName: string;
  lastName: string;
  avatar: {
    small: string;
    medium: string;
    big: string;
    customized?: boolean;
  };
  role?: MessengerUserRoleEnum;
}

export enum MessengerUserRoleEnum {
  admin = 'admin',
  member = 'member',
}

export enum MessengerThreadStatusEnum {
  read = 'read',
  unread = 'unread',
}

export enum MessageTypeEnum {
  text = 'text',
  media = 'media',
  reference = 'reference',
}

export enum MessageSenderTypeEnum {
  'me' = 'me',
  'other' = 'other',
  'system' = 'system',
}
