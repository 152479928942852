import { Component, Input, OnInit } from '@angular/core';
import {
  MessengerThread,
  MessengerThreadStatusEnum,
} from '../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-thread-item',
  templateUrl: './messenger-thread-item.component.html',
  styleUrls: [
    './messenger-thread-item.component.css',
    '../messenger.component.css',
  ],
})
export class MessengerThreadItemComponent implements OnInit {
  @Input() thread: MessengerThread;
  @Input() isActive: boolean = false;
  messengerThreadStatusEnum = MessengerThreadStatusEnum;
  @Input() forToast: boolean = false;
  constructor() {}

  ngOnInit() {}
}
