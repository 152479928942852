import { HttpEvent, HttpEventType, HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  MediaLibraryCategoryEnum,
  MediaLibraryFileModel,
  MediaLibraryUploadResponseModel,
  MediaLibraryUploadType,
} from 'src/app/models/sales-funnel';
import { ApiService } from 'src/app/shared/services';
import Swal, { SweetAlertResult } from 'sweetalert2';

export class UploadingFileProgressItem {
  name: string;
  size: number;
  percentage?: string;
  error?: {
    status: boolean;
    message: string;
  };
  extension?: string;
  preview?: string;
}
@Component({
  selector: 'app-media-library-upload-form',
  templateUrl: './media-library-upload-form.component.html',
  styleUrls: ['./media-library-upload-form.component.css'],
})
export class MediaLibraryUploadFormComponent implements OnInit {
  uploadForm = new UntypedFormGroup({
    files: new UntypedFormControl('', { updateOn: 'change' }),
  });

  formData = new FormData();
  reader = new FileReader();


  cropFile: File;

  // Files being uploaded
  @Input() uploadingFiles: UploadingFileProgressItem[] = [];
  @Output() uploadingFilesChange = new EventEmitter<UploadingFileProgressItem[]>();

  @Output() onUploadDone = new EventEmitter<MediaLibraryFileModel[]>()


  @Output() onOpenDialog = new EventEmitter<any>();


  @Input() uploadCategory: MediaLibraryCategoryEnum;
  @Input() multiFiles = true;
  @Input() uploadType: MediaLibraryUploadType = MediaLibraryUploadType.Media;

  // Image specifications
  @Input() imgSpecs: {
    width?: number;
    height?: number;
    ratio?: number;
  } = {
    width: null,
    height: null,
    ratio: null,
  };


  // File size restriction
  maxFileSize: number = 205800;

  acceptedImages = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ];
  acceptedVideos = [
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'application/ogg',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
  ];
  acceptedAudios = [
    'audio/mpeg',
    'audio/wav',
    'audio/webm',
    'audio/ogg',
    'audio/aac',
    'audio/flac',
    'audio/midi',
    'audio/x-ms-wma',
    'audio/vnd.rn-realaudio',
    'audio/amr',
    'audio/x-aiff',
    'audio/x-pn-realaudio',
    'audio/x-pn-realaudio-plugin',
    'audio/x-realaudio',
    'audio/x-wav',
    'audio/3gpp',
    'audio/3gpp2',
  ];


  constructor(private toastr: ToastrService, private _httpClient: HttpClient, private api: ApiService) {}

  ngOnInit() {

  }

  onCropDone(event: File) {
    this.uploadFileItem(event)
  }
  openDialog() {
    this.onOpenDialog.emit();

  }
  resetData() {
    this.formData.delete('files');
    this.uploadForm.get('files').setValue('');
  }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      const files = (event.target as HTMLInputElement).files;
      this.onUpload(files);
    }
    this.resetData();
  }

  async onUpload(files: FileList) {
    const fileList = Array.from(files); // Convert FileList to an array
    let filesCount = 0;

    for (const file of fileList) {
      const isFileTypeAccepted = () => {
        switch (this.uploadType) {
          case 'image':
            return this.acceptedImages.includes(file.type);
          case 'video':
            return this.acceptedVideos.includes(file.type);
          case 'audio':
            return this.acceptedAudios.includes(file.type);
          case 'media':
            return (
              this.acceptedImages.includes(file.type) ||
              this.acceptedVideos.includes(file.type) ||
              this.acceptedAudios.includes(file.type)
            );
          case 'any':
          case 'content':
            return true;
          default:
            return false;
        }
      };

      const isFileSizeAccepted = () => {
        return this.maxFileSize > file.size / 1024;
      };

      const isImageRatioAccepted = async () => {
        switch (this.uploadType) {
          case 'image':
            if (
              !!this.imgSpecs?.ratio ||
              !!this.imgSpecs?.height ||
              !!this.imgSpecs?.width
            ) {
              let ImageRatio = await this.getImageRatio(file);
              
              

              return ImageRatio == this.imgSpecs.ratio;
            }

            return true;
            break;

          default:
            return true;
            break;
        }

        return this.maxFileSize < file.size / 1024;
      };

      if (!isFileSizeAccepted()) {
        this.toastr.error(
          file.name,
          `Your file is larger than ${Math.round(this.maxFileSize / 1024)}MB`,
          {
            closeButton: false,
            positionClass: 'toast-bottom-right',
          }
        );
        continue;
      }

      if (!isFileTypeAccepted()) {
        this.toastr.error(file.name, `Your file's extension is not accepted`, {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        continue;
      }

      if (!(await isImageRatioAccepted())) {
        // this.openCropper(undefined, file);
        let result = await this.onUploadRatioNotAccepted();
        if (result.value) {
          this.openCropper(file)
        }
        continue;
      }

      this.uploadFileItem(file);
    }

    // if (this.getUploadingRealCount() > 0) {
    // this.uploadingWithCountToast(this.getUploadingRealCount());
    // }
  }

  openCropper(file: File) {
    this.cropFile = file;
  }
  onUploadRatioNotAccepted() {
    return Swal.fire({
      icon: 'warning',
      title: 'Unsupported Image Aspect Ratio Detected',
      html: `While you can still proceed with the upload, please note that the image's aspect ratio is not perfectly match. For optimal use, we recommend cropping it.`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Crop & Upload',
      cancelButtonText: 'Cancel'
    })
  }

  getUploadingRealCount() {
    let counter = 0;
    this.uploadingFiles.forEach((item) => {
      if (!!item?.name && !!item?.percentage) {
        counter++;
      }
    });
    return counter;
  }

  uploadFileItem(file: File) {
    let formData = new FormData();
    formData.append('files', file);

    // filesCount++;
    let upIndex = this.uploadingFiles.push({
      name: file.name,
      size: file.size,
    });
    this.uploadingFilesChange.emit(this.uploadingFiles);

    this.uploadFile(formData, upIndex - 1);
  }

  getImageRatio(file: File): Promise<number | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const ratio = img.width / img.height;
          resolve(ratio);
        };

        img.onerror = () => {
          resolve(null); // Return null in case of an error (e.g., invalid image file)
        };
      };

      reader.readAsDataURL(file);
    });
  }

  async uploadFile(formData: FormData, index: number) {
    const uploadPath = `sales-funnel/media/${this.uploadCategory ?? 'any'}`;
    const request = this._httpClient.post(
      `${this.api.apiUrl}/${uploadPath}`,
      formData,
      { reportProgress: true, observe: 'events' }
    );

    request.subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type !== HttpEventType.UploadProgress) {
          if (event.type === HttpEventType.Response) {
            const response = event.body as MediaLibraryUploadResponseModel;
            if (response.refusedFiles.length > 0) {
              const error = response.refusedFiles[0].reason;
              this.uploadingFiles[index].error = {
                status: true,
                message: error,
              };

              setTimeout(() => {
                this.uploadingFiles[index] = {
                  name: null,
                  percentage: null,
                  size: null,
                };
              }, 5000);
            } else {
              const uploadedFiles = response.uploadedFiles;
              this.onUploadDone.emit(uploadedFiles)
              this.uploadingFiles[index] = {
                name: null,
                percentage: null,
                size: null,
              };


            }
          }
          return;
        }

        const percentDone = Math.round((100 * event.loaded) / event.total);
        this.uploadingFiles[index].percentage = `${percentDone}%`;
        this.uploadingFilesChange.emit(this.uploadingFiles);

        if (percentDone === 100) {
          // File upload is complete
        }
      },
      error: (error) => {
        this.uploadingFiles[index].error = { status: true, message: error?.message };
        this.uploadingFilesChange.emit(this.uploadingFiles);
        setTimeout(() => {
          this.uploadingFiles[index] = {
            name: null,
            percentage: null,
            size: null,
          };
          this.uploadingFilesChange.emit(this.uploadingFiles);
        }, 5000);
      },
    });
  }
}
