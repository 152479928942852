import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { ProductItem } from 'src/app/components/user-dashboard/product/models/product';
import { AnimatedLoaderType } from '../ai/ai-thinking-loader/ai-thinking-loader.component';

export type GenericItem = ProductItem | OfferItem | any;
@Component({
  selector: 'app-dynamic-items-card',
  templateUrl: './dynamic-items-card.component.html',
  styleUrls: ['./dynamic-items-card.component.css'],
})
export class DynamicItemsCardComponent implements OnInit {
  animatedLoaderType = AnimatedLoaderType
  item: GenericItem;
  @Input('item') set setItem(item: GenericItem) {
    this.item = item;
    if (this.isProduct(item)) {
      this.itemType = 'product';
    } else if (this.isOffer(item)) {
      this.itemType = 'offer';
    }
  }

  itemType: 'offer' | 'product';
  // portalViewPageStatusEnum = PortalViewPageStatusEnum;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  goBack() {
    this.router.navigate(['dashboard', 'portal']);
  }

  isProduct(item: GenericItem = this.item): item is ProductItem {
    if (item === undefined) return false;
    return (item as ProductItem)?.name !== undefined;
  }

  isOffer(item: GenericItem = this.item): item is OfferItem {
    if (item === undefined) return false;
    return (item as OfferItem)?.title !== undefined;
  }

  // async reloadPortalOffers() {
  //   await this.sharedPortalViewService.reloadPortalOffers();
  // }
}
