import { Component, Input } from '@angular/core';
import { TopLevelNavMenuItem } from '../dynamic-top-level-nav/dynamic-top-level-nav.component';

// export class TopLevelNavMenuItem {
//   label: string; url: string; iconPath?: string; customId?: string;helpText?: string;
// }
@Component({
  selector: 'app-dynamic-second-level-nav',
  templateUrl: './dynamic-second-level-nav.component.html',
  styleUrls: ['./dynamic-second-level-nav.component.css']
})
export class DynamicSecondLevelNavComponent {
  @Input() menuItems: TopLevelNavMenuItem[] = []

}
