
import { SharedPortalViewService } from './../../../shared-portal-view.service';
import { SetWebsiteIdentityRequestBody, UserWebsite, WebsiteIdentity, WebsiteModel } from './../../../../portal.model';

import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../../../shared/services';
import { Store } from '@ngrx/store';
import { selectWebsite, isWebsiteLoaded, selectWebsiteIdentity } from 'src/app/shared/store/_features/website/website.selector';



@Component({
  selector: 'app-portal-identity-settings',
  templateUrl: './portal-identity-settings.component.html',
  styleUrls: ['./portal-identity-settings.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class PortalIdentitySettingsComponent implements OnInit, OnDestroy {

  @Input() saveListener =  new EventEmitter<any>();

  website$: Observable<WebsiteModel>;
  websiteLoaded$: Observable<boolean>;
  
  portalIdentityConfigurationForm = new FormGroup({
    label: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
    title: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
    description: new FormControl<string>(null, {
      updateOn: 'change',
      validators: [Validators.required],
    }),
    favIcon_id: new FormControl(null, {
      updateOn: 'change',
    }),
    logo_id: new FormControl(null, {
      updateOn: 'change',
    }),
    learmo_primary_color: new FormControl(null, {
      updateOn: 'change',
    }),
  });

  identity: WebsiteIdentity;
  @Input('identity') set websiteIdentity (value:WebsiteIdentity) {
    this.identity = value;
    this.portalIdentityConfigurationForm.patchValue(value);
  }

  subscriptions: Subscription[] = []
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  website: WebsiteModel;

  confirmationRequired = false;
  iConfirm = false;

  loaded = false;
  

  // offerSettingsForm = new FormGroup({
  //   isDefault: new FormControl('', {
  //     updateOn: 'change',
  //   }),
  //   parentPortal: new FormControl('', { updateOn: 'change' }),
  //   iConfirm: new FormControl('', { updateOn: 'change' }),
  // });

  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private store: Store
  ) {
 
    // this.website$ = this.store.select(selectWebsite);
    this.websiteLoaded$ = this.store.select(isWebsiteLoaded)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  async ngOnInit() {
    // await this.loadData();

    this.subscriptions.push(this.saveListener.subscribe((value)=> {
      
      this.onSave();
    }));
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  compareWith(item, selected) {
    return +item.siteId === +selected.siteId;
  }

  // async loadData() {

  //   this.identity =
  //   JSON.parse(
  //     JSON.stringify(this.website.identity));

  //     this.identity.label = this.website.label

  // }
  async afterOpenModal() {
    // await this.loadData();
  }

  async onSave() {
    
    let payload: SetWebsiteIdentityRequestBody = {
      title: this.portalIdentityConfigurationForm.value.title,
      description: this.portalIdentityConfigurationForm.value.description,
      label: this.portalIdentityConfigurationForm.value.label,
    }
    await this.sharedPortalViewService.setWebsiteIdentity(payload)

  }

  async onOpenModal() {
    this.iConfirm = false;
    this.confirmationRequired = false;
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
