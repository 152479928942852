import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-input',
  templateUrl: './pricing-input.component.html',
  styleUrls: ['./pricing-input.component.css']
})
export class PricingInputComponent implements OnInit {
  minValue: number = 100;
  maxValue: number = 400;

  constructor() { }

  ngOnInit() {
  }

}
