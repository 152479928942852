// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-outline-fix-button:hover .svg-icon{
  fill: #fff !important;
}

.ip-outline-fix-button:focus {
  color: #007bff !important;
    background-color: transparent !important;
    border-color: #007bff !important;
}

.ip-solid-fix-button:focus {
  color: #FFFFFF !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.stats-grid {
  display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

@media (max-width: 1336px) {
  .stats-grid {
    display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
  }
}


`, "",{"version":3,"sources":["webpack://./src/app/components/affiliate-dashboard/my-dashboard/affiliate-dashboard-page/affiliate-head-stats/affiliate-head-stats.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;IACvB,wCAAwC;IACxC,gCAAgC;AACpC;;AAEA;EACE,yBAAyB;IACvB,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;EACE,aAAa;IACX,sCAAsC;IACtC,cAAc;AAClB;;AAEA;EACE;IACE,aAAa;MACX,kCAAkC;MAClC,cAAc;EAClB;AACF","sourcesContent":[".ip-outline-fix-button:hover .svg-icon{\n  fill: #fff !important;\n}\n\n.ip-outline-fix-button:focus {\n  color: #007bff !important;\n    background-color: transparent !important;\n    border-color: #007bff !important;\n}\n\n.ip-solid-fix-button:focus {\n  color: #FFFFFF !important;\n    background-color: #007bff !important;\n    border-color: #007bff !important;\n}\n\n.stats-grid {\n  display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    grid-gap: 20px;\n}\n\n@media (max-width: 1336px) {\n  .stats-grid {\n    display: grid;\n      grid-template-columns: 1fr 1fr 1fr;\n      grid-gap: 20px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
