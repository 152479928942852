import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  ContentChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../../shared/services/lsg.api.service';
import { MediaLibraryFileModel } from '../../../../models/sales-funnel';
import { lastValueFrom, take, takeWhile } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedOfferService } from '../shared-offer.service';
import { OnDestroy, AfterViewInit } from '@angular/core';
import { SharedProductService } from '../../product/shared-product.service';
import { ProductModel } from '../../product/models/product';
import { OfferCreationDTO, OfferItem, Offer_Payment_Type_Enum } from '../models/offer';
import { Store } from '@ngrx/store';
import { createNewOfferActions } from 'src/app/shared/store/_features/offers/offers.actions';
import { addNewOfferToProduct } from 'src/app/shared/store/_features/products/products.actions';
import { isCreateOfferLoaded, selectOffersState } from 'src/app/shared/store/_features/offers/offers.selector';

@Component({
  selector: 'app-offer-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class OfferCreateComponent implements OnInit, OnDestroy {
  OfferPaymentTypeEnum = Offer_Payment_Type_Enum;

  processing: boolean = false;
  // @Input() customAction = false;
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() includeThisProduct: number;
  @Input() setThisPortal: number;

  @Output() onCreateDone = new EventEmitter<OfferItem>();
  // loading: { currencies: boolean; portals: boolean } = {
  //   currencies: true,
  //   portals: true,
  // };
  // Multi Select
  selectedItems: ProductModel[] = [];

  // Multi Select
  ProductTypes: number[] = [1];
  contentSelectedFiles: number[] = [];
  ImageSelectedFiles: number[] = [];
  includedProductsSelect: ProductModel[] = [];
  @Input() isRestrectedProduct = false;

  // Form Variables
  createOfferForm = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    productId: new FormControl(null, { updateOn: 'change', validators: [Validators.required] }),

    payment_type: new FormGroup({
      name: new FormControl(Offer_Payment_Type_Enum.free, {
        updateOn: 'change',
        validators: [Validators.required],
      }),
      price: new FormGroup({
        value: new FormControl(0, { updateOn: 'change' }),
        currency: new FormGroup({
          id: new FormControl(null),
        }),
      }),
    }),
  });

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    public sharedOfferService: SharedOfferService,
    public sharedProductService: SharedProductService,
    private store: Store
  ) {
    this.store.select(isCreateOfferLoaded).subscribe((loaded) => {
      this.processing = !loaded
    })
  }

  ngOnDestroy(): void {}

  async ngOnInit() {
    // await this.sharedOfferService.getProducts();
    this.setupConditionalValidation();
  }

  // Function to set up conditional validation
  private setupConditionalValidation() {
    const priceControl = this.createOfferForm.get('payment_type.price');
    const nameControl = this.createOfferForm.get('payment_type.name');

    nameControl.valueChanges.subscribe((value) => {
      if (value === 'free') {
        priceControl.clearValidators(); // Remove validators if name is 'free'
      } else {
        priceControl.setValidators([Validators.required]); // Add required validator if name is not 'free'
      }

      priceControl.updateValueAndValidity(); // Trigger revalidation
    });
  }

  closeModal() {
    if (this.processing) {
      this.toastr.warning('Wait until processing is finished');
    }
    else {
      this.modalService.dismissAll();
    }

  }

  openModalProcess() {
    this.open();
    if (!!this.includeThisProduct) {
      this.createOfferForm.patchValue({ productId: this.includeThisProduct });
    }
  }
  // updatePrice(formGroup: FormGroup) {
  //   this.createOfferForm.get('payment_type.price').patchValue(formGroup.value)
  //   // this.createOfferForm.get('payment_type.price').setValue(eventValue);
  // }

  async createNewOffer() {

    let createPayload: OfferCreationDTO = new OfferCreationDTO({
      title: this.createOfferForm.value.title,
      productId: this.createOfferForm.value.productId,
      payment_type: {
        name: this.createOfferForm.value.payment_type?.name,
        price: {
          value: this.createOfferForm.value.payment_type?.price?.value,
          currency: {
            id: this.createOfferForm.value.payment_type?.price?.currency?.id
          }
        }
      }
    });    this.createOfferForm.markAllAsTouched();



    if (!!this.createOfferForm.valid) {
      this.store.dispatch(createNewOfferActions.create({createPayload}))
      this.store.select(selectOffersState).pipe(takeWhile(state => state?.createNewOfferProcess.status !== 'done' && state?.createNewOfferProcess.status !== 'error', true)).subscribe(state=> {
        switch (state?.createNewOfferProcess.status) {
          case 'done':
            this.onOfferCreateDone(state.currentOffer);
            break;
          case 'error':
            this.onOfferCreateError(state.currentOffer.errorMessage)
          break;
          default:
            break;
        }

      })

    }
  }

  onOfferCreateDone = (offer: OfferItem) => {
    // this.route.navigate(['dashboard', 'product']);
    this.closeModal()
    // this.onCreateDone.emit();
    // if (!!offer?.products[0]?.id) {
    //   this.store.dispatch(addNewOfferToProduct({productId: offer.products[0].id,offer: offer}))
    // }
    this.toastr.success('Done!', 'Offer Created Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
    if (!!this.route.url.includes('product')) {
      this.sharedProductService.getProductById(
        this.sharedProductService.currentProductId
      );
    }
  }

  onOfferCreateError = (error) => {

  }

  createOfferErrorHandler = (error) => {
    this.processing = false;
    this.toastr.error(error.message)

  }
  resetForm() {
    this.processing = false;
    this.createOfferForm.reset();
  }




  async afterOpenModal() {
    // await this.sharedOfferService.getProducts();
    // await this.sharedOfferService.getUserPortals();
    // await this.sharedOfferService.getCurrencies();
  }

  async onOpenModal() {
    this.resetForm();
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        centered: true,
        animation: false,
        windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {
          this.resetForm();
        },
        (reason) => {
          this.resetForm();
        }
      );
    await this.afterOpenModal();
  }

  // addPaymentControls() {
  //   // this.createOfferForm.get('paymentPrice').addValidators(Validators.required);
  //   // this.createOfferForm
  //   //   .get('paymentCurrency')
  //   //   .addValidators(Validators.required);
  //   this.createOfferForm.addControl(
  //     'paymentPrice',
  //     new UntypedFormControl('', {
  //       validators: [Validators.required],
  //       updateOn: 'change',
  //     })
  //   );

  //   this.createOfferForm.addControl(
  //     'paymentCurrency',
  //     new UntypedFormControl('', {
  //       validators: [Validators.required],
  //       updateOn: 'change',
  //     })
  //   );

  //   this.refreshPaymentControls();
  // }

  // onPriceChange(value: any) {
  //   if (value <= 0 && value != null) {
  //     this.offerObject.payment_type.name = Offer_Payment_Type_Enum.free;
  //   }
  // }

  // refreshPaymentControls() {
  //   const paymentPrice = this.createOfferForm.get('paymentPrice');
  //   const paymentCurrency = this.createOfferForm.get('paymentCurrency');
  //   paymentPrice?.updateValueAndValidity();
  //   paymentCurrency?.updateValueAndValidity();
  // }

  // removePaymentControls() {
  //   const paymentPrice = this.createOfferForm.get('paymentPrice');
  //   const paymentCurrency = this.createOfferForm.get('paymentCurrency');
  //   if (!!paymentPrice) {
  //     this.createOfferForm.removeControl('paymentPrice');
  //   }
  //   if (!!paymentCurrency) {
  //     this.createOfferForm.removeControl('paymentCurrency');
  //   }

  //   // this.refreshValidators();
  // }
  // onChangePaymentType(value: any) {
  //   switch (value) {
  //     case 'one_time':
  //       this.addPaymentControls();
  //       this.createOfferForm.get('paymentCurrency').markAsUntouched();
  //       this.createOfferForm.get('paymentPrice').markAsUntouched();
  //       break;

  //     case 'free':
  //       setTimeout(() => {
  //         this.removePaymentControls();
  //       }, 300);

  //       // this.offerObject.payment_type.currency_id = null;
  //       this.offerObject.payment_type.price = null;
  //       break;
  //     default:
  //       this.removePaymentControls();
  //       // this.offerObject.payment_type.currency_id = null;
  //       this.offerObject.payment_type.price = null;
  //       break;
  //   }
  // }
}
