import { Title } from '@angular/platform-browser';
import { SharedPortalService } from './../../../components/user-dashboard/portal/shared-portal.service';

import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AffiliateDashboardData,
  TotalTrackingData,
} from 'src/app/models/owner-dashboard';
import { lastValueFrom } from 'rxjs';

import { InOutAnimation, listAnimation, simpleFadeAnimation } from 'src/app/shared/animations/animations';
import { SwiperOptions } from 'swiper';
import { TemplateItem } from 'src/app/components/user-dashboard/portal/portal.model';

@Component({
  selector: 'app-templates-layout',
  templateUrl: './templates-layout.component.html',
  styleUrls: ['./templates-layout.component.css'],
  animations: [simpleFadeAnimation, listAnimation, InOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class TemplatesLayoutComponent implements OnInit {
  @Output() onSelectTemplate = new EventEmitter<TemplateItem>()
  loadedTemplate: boolean = false;
  categoriesConfig: SwiperOptions = {
      
    
    // slidesPerView: 4,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        navigation: {enabled: true},
        slidesPerView: 3,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        navigation: {enabled: true},
        slidesPerView: 5,
        spaceBetween: 40
      }
    },
    spaceBetween: 30,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      enabled: true,
    },
    direction: 'horizontal',
    pagination: { clickable: false, enabled: false, bulletClass: 'swiper-pagination-bullet bg-white' },

    effect: 'slide',
    grabCursor: false,
    centeredSlides: false,
    preventInteractionOnTransition: true,
    zoom: {
      maxRatio: 2,
      toggle: true,
    },
    on: {
      click : (swiper, event) => {
        this.selectTemplateCategoryByIndex(swiper.clickedIndex);
      },
    }
    // preventClicks: true,
    // preventClicksPropagation: true,
  };

  dashboardData: AffiliateDashboardData;
  themes: number[] = [1, 2, 3, 4, 5];
  constructor(
    public sharedPortalService: SharedPortalService,
    private title: Title,
    private cdr: ChangeDetectorRef
  ) {
    this.title.setTitle('Choose Your Template');
    
  }


  selectTemplateCategoryByIndex = (index: number) => {
    this.loadedTemplate = false;
    this.sharedPortalService.selectTemplateCategoryByIndex(index)
    this.cdr.detectChanges();
    this.loadedTemplate = true;
  }


  async ngOnInit() {
    this.sharedPortalService.startLoading();
    await this.getTemplates();
    
    this.sharedPortalService.stopLoading();
  }

  async getTemplates() {
    this.loadedTemplate = false;
    await this.sharedPortalService.getTemplates();
    this.loadedTemplate = true;
  }

  async selectTemplateByIndex(index: number) {
    
    this.sharedPortalService.selectTemplateByIndex(index);
    this.onSelectTemplate.emit(this.sharedPortalService.templates.currentTemplate.template);
    // this.sharedPortalService?.wizardModel?.stickActionButtons();
    // this.sharedPortalService.wizardModel.currentStep.step.hideButtons.next =
    //   false;
  }
}
