import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { OfferStausEnum } from 'src/app/components/user-dashboard/offer/models/offer';
import { SharedOfferService } from 'src/app/components/user-dashboard/offer/shared-offer.service';
import { CourseHierarchyItem, CourseHierarchyItemTypeEnum } from 'src/app/components/user-dashboard/product/models/course';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-dynamic-offer-status',
  templateUrl: './dynamic-offer-status.component.html',
  styleUrls: ['./dynamic-offer-status.component.css']
})
export class DynamicOfferStatusComponent implements OnInit {
  loaded: boolean = true;
  openDripModal: boolean = false;
  openLockByModal: boolean = false;


  courseHierarchyItemTypeEnum = CourseHierarchyItemTypeEnum
  offerStausEnum = OfferStausEnum;
  @Input() item: OfferItem;
  @Input() courseId: number;

  @Output() onDone = new EventEmitter<any>()
  dropIsActive: boolean = false;
  constructor(private toastr: ToastrService, public sharedOfferService: SharedOfferService) { }


  onChangeOpen(event: any) {
    this.dropIsActive = event;
    
  }
  ngOnInit() {
    // this.item.status = CourseItemStatus.PUBLISHED
  }

  async publishItem() {
    this.changeStatus(OfferStausEnum.published);
  }

  async draftItem() {
    this.changeStatus(OfferStausEnum.draft);
    // await this.editOffer(offer.id);
    // this.sharedOfferService.changeOfferStatus(true, offer, 'status');
  }





  async changeStatus(status: OfferStausEnum) {

    // this.courseViewService.courseBuilderLoading.loading = true;
    // this.loaded = false;
    await this.sharedOfferService.changeOfferStatus(this.item, status);

    // this.toastr.success(`Successfully updated the Offer ${this.item.title}`);
    // this.onDone.emit();
    // this.loaded = true;
    // this.courseViewService.courseBuilderLoading.loading = false;
  }


  async deleteItem() {
    // this.courseViewService.deleteCourseHeirarchyItem(this.courseId, this.item);
  }


}
