// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container img {
  margin: auto auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/media-library/file-type-icon/file-type-icon.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".image-container img {\n  margin: auto auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
