import { Component, Input, OnInit } from '@angular/core';
import { UserQuotaModel } from 'src/app/models/sales-funnel';

@Component({
  selector: 'app-media-library-storage-meter',
  templateUrl: './media-library-storage-meter.component.html',
  styleUrls: ['./media-library-storage-meter.component.css']
})
export class MediaLibraryStorageMeterComponent implements OnInit {
  @Input() mediaLibraryQuota: UserQuotaModel;

  constructor() { }

  ngOnInit() {
  }

}
