import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { loadCustomScript } from '@paypal/paypal-js';
import { lastValueFrom } from 'rxjs';
import { PaymentGatewaysIntegration, PaypalPaymentGatewayModel, StripePaymentGatewayModel } from 'src/app/models/portal';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-stripe-integration-from',
  templateUrl: './stripe-integration-from.component.html',
  styleUrls: ['./stripe-integration-from.component.css'],
})
export class StripeIntegrationFromComponent implements OnInit {
  isError: string;
  paypalStorageKey = environment.storageKeys.paypalIntegration;
  processing = false;
  integratePaymentForm: FormGroup = new FormGroup({
    publishableKey: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    secretKey: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });


  
  constructor(private api: ApiService, private toastr: ToastrService) {
    this.integratePaymentForm.valueChanges.subscribe((a) => {
      this.isError = null;
    });
  }

  ngOnInit() {
  }



  async submitForm() {
    this.integratePaymentForm.markAllAsTouched();
    if (!this.integratePaymentForm.valid) {
      return false;
    }

    let payload:StripePaymentGatewayModel = {
      publishableKey: this.integratePaymentForm.get('publishableKey').value,
      secretKey: this.integratePaymentForm.get('secretKey').value,
    }

    await this.integrationRequest({ stripe: payload });
  }
  
  async integrationRequest(payload: PaymentGatewaysIntegration) {
    this.processing = true;
    this.integratePaymentForm.disable();
    const request$ = this.api.integratePaymentGateways(
      payload,
      this.onError
    );
    const response: { success: boolean } = await lastValueFrom(request$);
    if (response.success) {
      this.onSuccess();
    }
  }
  

  async onSuccess() {

    // await this.sharedPortalViewService.goToPayments();
    this.toastr.success(
      `Stripe payment gateway integrated successfully`,
      'Done!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );
    // await this.sharedPortalViewService.getPaymentMethods();
    setTimeout(() => {
      this.processing = false;
    }, 500);
    this.integratePaymentForm.enable();
  }

  onError = (error: any) => {
    this.integratePaymentForm.enable();
    this.processing = false;
    // this.startEdited('offer', 'edited');
    this.toastr.error(
      `Stripe payment gateway doesn't integrated`,
      'Error!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );

    this.isError = error?.message;
  };

  
}
