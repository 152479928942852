const ICONS = {
    category_name: 'Abstract',
    icons_list: [
      "abstract-33",
      "abstract-27",
      "abstract-26",
      "abstract-32",
      "abstract-18",
      "abstract-24",
      "abstract-30",
      "abstract-8",
      "abstract-9",
      "abstract-31",
      "abstract-25",
      "abstract-19",
      "abstract-21",
      "abstract-35",
      "abstract-34",
      "abstract-20",
      "abstract-36",
      "abstract-22",
      "abstract-23",
      "abstract-37",
      "abstract-44",
      "abstract",
      "abstract-45",
      "abstract-47",
      "abstract-46",
      "abstract-42",
      "abstract-43",
      "abstract-41",
      "abstract-40",
      "abstract-48",
      "abstract-49",
      "abstract-12",
      "abstract-2",
      "abstract-3",
      "abstract-13",
      "abstract-39",
      "abstract-11",
      "abstract-1",
      "abstract-10",
      "abstract-38",
      "abstract-14",
      "abstract-28",
      "abstract-4",
      "abstract-5",
      "abstract-29",
      "abstract-15",
      "abstract-17",
      "abstract-7",
      "abstract-6",
      "abstract-16"

    ],

};


export default ICONS
