import { Component } from '@angular/core';

@Component({
  selector: 'app-global-search-results-box',
  templateUrl: './global-search-results-box.component.html',
  styleUrls: ['./global-search-results-box.component.css']
})
export class GlobalSearchResultsBoxComponent {
  results: any[] = []
}
