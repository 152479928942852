import { UserWebsite, WebsiteModel } from './../../../portal.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portal-identity-tooltip',
  templateUrl: './portal-identity-tooltip.component.html',
  styleUrls: ['./portal-identity-tooltip.component.css'],
})
export class PortalIdentityTooltipComponent implements OnInit {
  @Input() website: WebsiteModel
  constructor() {}

  ngOnInit(): void {}
}
