import { SharedAffiliateService } from './../../../../components/user-dashboard/affiliate/shared-affiliate.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { FunnelItem } from 'src/app/models/funnels';
import {
  AffiliateLinkMinimizedModel,
  AffiliateLinkStatusEnum,
} from 'src/app/models/owner-dashboard';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-affiliate-links-list',
  templateUrl: './affiliate-links-list.component.html',
  styleUrls: ['./affiliate-links-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AffiliateLinksListComponent implements OnInit {
  @Input() linksList: AffiliateLinkMinimizedModel[] = [];
  @Input() loaded = false;

  affiliateLinkStatusEnum = AffiliateLinkStatusEnum;

  // Pagination
  currentPage = 1;
  startIndex = 0;
  numberInPage = 6;
  endIndex = 6;

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdAt',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
    { displayName: 'A-Z Title', column: 'name', order: 'asc' },
    { displayName: 'Z-A Title', column: 'name', order: 'dsc' },
  ];

  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    filters: [],
    exclusions: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
  };
  filterTypes: FilteringTypeModel[] = [
    {
      index: 0,
      displayName: 'All',
      filters: [],
      exclusions: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
    },
    {
      index: 1,
      displayName: 'General (Public)',
      filters: [{column: 'type', value: 'general'}, {column: 'visibility', value: 'public'}],
      exclusions: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
    },
    {
      index: 2,
      displayName: 'General (Private)',
      filters: [{column: 'type', value: 'general'}, {column: 'visibility', value: 'private'}],
      exclusions: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
    },
    {
      index: 3,
      displayName: 'VIP',
      filters: [{column: 'type', value: 'vip'}],
      exclusions: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
    },
  ];
  customFilter: FilteringTypeModel = {
    index: 4,
    displayName: 'Suspended',
    filters: [{column: 'status', value: AffiliateLinkStatusEnum.suspended}],
  };

  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private router: Router
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  resetPagination() {
    this.startIndex = 0;
    this.endIndex = this.numberInPage;
    this.currentPage = 1;
  }

  filterTypeChange(filterObject: FilteringTypeModel) {
    //
    this.filterObject = filterObject;
    this.resetPagination();
  }

  viewAffiliateLink(id: number) {
    this.router.navigate(['dashboard', 'affiliate', 'links', id, 'view']);
  }

  async editAffiliateLink(id: number) {
    await this.router.navigate(['dashboard', 'affiliate', 'links', id, 'edit']);
  }

  async suspendAffiliateLink(affiliateLink: AffiliateLinkMinimizedModel) {
    await this.sharedAffiliateService.suspendAffiliateLinkAlert(affiliateLink);
  }

  async resumeAffiliateLink(affiliateLink: AffiliateLinkMinimizedModel) {
    await this.sharedAffiliateService.resumeAffiliateLinkAlert(affiliateLink);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}

  async onChooseCreate() {
    this.router.navigate(['dashboard', 'affiliate', 'links', 'create']);
  }

  // Pagination
  getArrayLenght(length) {
    return new Array(this.getPagesNumber(length));
  }

  getPagesNumber(length) {
    return Math.ceil(length / this.numberInPage);
  }

  getIndex(pageIndex) {
    if (pageIndex > 0) {
      this.currentPage = pageIndex;
      this.startIndex = (pageIndex - 1) * this.numberInPage;
      this.endIndex = this.startIndex + this.numberInPage;
    }
  }
}
