import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { colorSet } from 'src/app/models/global-classes';

export class GlobalRadioInfoCardModel {
  title: string;
  description?: string;
  iconSvg?: string;
  iconLabel?: string;
  iconColor?: colorSet;
}
@Component({
  selector: 'app-global-radio-info-card',
  templateUrl: './global-radio-info-card.component.html',
  styleUrls: ['./global-radio-info-card.component.css']
})
export class GlobalRadioInfoCardComponent implements OnInit {
  inputName = 'radio'
  @Input() value: any = true;
  @Input() selected: boolean = false;
  @Input() options: GlobalRadioInfoCardModel;

  @Output() onSelected = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }


  onSelect(evt) {
    var target = evt.target;
    if (target.checked) {
      this.onSelected.emit(this.value);
    } else {
      
    }

    
  }
}
