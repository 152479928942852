import { SharedDomainService } from './../../../../components/user-dashboard/domains/shared-domain.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { DomainItem } from './../../../../models/domain';
import { SharedProductService } from './../../../../components/user-dashboard/product/shared-product.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-domains-list',
  templateUrl: './domains-list.component.html',
  styleUrls: ['./domains-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class DomainsListComponent implements OnInit {
  @Input() backText: string = 'Domains';
  @Input() domainsList: DomainItem[] = [];
  @Input() loaded: boolean = false;

  // Pagination
  currentPage = 1;
  startIndex = 0;
  numberInPage = 6;
  endIndex = 6;

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdDate',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Renewed', column: 'renewalDate', order: 'dsc' },
  ];

  constructor(
    public sharedDomainService: SharedDomainService,
    private router: Router,
    private sharedOwnerDashboardService: SharedOwnerDashboardService
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  goToDomainView(id: number) {
    this.sharedOwnerDashboardService.setBackRoute(this.backText);
    this.router.navigate(['dashboard', 'domains', 'view', id]);
  }
  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  goToTutorials() {
    this.router.navigate(['/dashboard/tutorials']);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}

  // Pagination
  getArrayLenght(length) {
    return new Array(this.getPagesNumber(length));
  }

  getPagesNumber(length) {
    return Math.ceil(length / this.numberInPage);
  }

  getIndex(pageIndex) {
    if (pageIndex > 0) {
      this.currentPage = pageIndex;
      this.startIndex = (pageIndex - 1) * this.numberInPage;
      this.endIndex = this.startIndex + this.numberInPage;
    }
  }
}
