import { Component, Input, OnInit } from '@angular/core';
import EditorJS from '@editorjs/editorjs';

@Component({
  selector: 'app-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.css']
})
export class EditorToolbarComponent implements OnInit {
  editor: EditorJS;
  @Input('editor') set editorRef(editorRef: EditorJS) {
    this.editor = editorRef;
  }
  constructor() { }

  ngOnInit() {
  }

  callBlock(event: any) {
    let $event = event as PointerEvent;
    let currentBlockIndex

    $event.preventDefault();
    $event.stopPropagation();
    
    
    currentBlockIndex = this.editor.blocks.getCurrentBlockIndex();
    if (currentBlockIndex == -1) {this.editor.focus(true);}
    currentBlockIndex = this.editor.blocks.getCurrentBlockIndex();
    const isCurrentBlockEmpty = this.editor.blocks.getBlockByIndex(currentBlockIndex).isEmpty;
    
    if (currentBlockIndex == -1 || !isCurrentBlockEmpty) {this.editor.blocks.insert();}
    this.editor.toolbar.toggleToolbox(true);
  }
}
