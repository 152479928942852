import { NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInOut } from '../../../animations/animations';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Component, Input, OnInit, Renderer2, ElementRef } from '@angular/core';
import { NavigationMenuItem } from 'src/app/models/owner-dashboard';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';

@Component({
  selector: 'app-main-nav-bar-list',
  templateUrl: './main-nav-bar-list.component.html',
  styleUrls: ['./main-nav-bar-list.component.css'],
  animations: [slideInOut]
})
export class MainNavBarListComponent implements OnInit {
  @Input() sideMenu: NavigationMenuItem[];
  @Input() isMobile: boolean;
  @Input() isCollapsed: boolean;
  hoverOnItem: NavigationMenuItem = null;


  popoverTippyProps: NgxTippyProps = {
    placement: 'right', followCursor: false, trigger: 'mouseenter click', interactive: true, appendTo: document.body
  }
  constructor(
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public router: Router,
    public userDashboardService: UserDashboardService,
    private ngxTippyService: NgxTippyService

  ) { }

  ngOnInit(): void {
    this.sideMenu.forEach(item => {
      if (!!item.subItems?.length) {
        let routerActiveArray = item.subItems.forEach(sub => {
          if (this.router.url.includes(sub.routerLink)) {
            this.sharedOwnerDashboardService.expandSidebarItem(this.sideMenu, item);
          }
        });

      }
    }
    );
  }

  toggleSidebarItem(sideMenu: NavigationMenuItem[], item: NavigationMenuItem, forceExpand = false) {
    if (!!item?.subItems?.length) {
      if (!!item?.expandable?.status && !forceExpand) {
        this.sharedOwnerDashboardService.collapseSidebarItem(item);
      } else {
        this.sharedOwnerDashboardService.expandSidebarItem(sideMenu, item);
      }
    } else {
      this.sharedOwnerDashboardService.expandSidebarItem(sideMenu, item);
    }

    if (!!item?.routerLink) {
      this.userDashboardService.closeMobileCollapse();
    }
  }


  showMenuOnHover(item: any) {
    if (item.subItems && item.subItems.length && !item.expandable.status) {
      return item.subItems.map(sub => sub.label).join("<br>");
    }
    return item.label;
  }



}
