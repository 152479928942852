import { WizardModel, WizardStepModel } from './../../../../models/wizard';
import { WizardStepStatusEnum } from 'src/app/models/wizard';

import { Component, OnInit, Input } from '@angular/core';




@Component({
  selector: 'app-wizard-step-item',
  templateUrl: './wizard-step-item.component.html',
  styleUrls: ['./wizard-step-item.component.css'],
})
export class WizardStepItemComponent implements OnInit {
  wizardStepStatusEnum = WizardStepStatusEnum;
  @Input() wizardControl: WizardModel;
  @Input() step: WizardStepModel;
  @Input() index: number = 1;
  @Input() lastStep: boolean = false;
  @Input() direction: 'vertical' | 'horizontal' = 'horizontal'
  constructor() { }

  ngOnInit(): void {
  }

}
