import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  ContentChild,
  ElementRef,
  AfterContentInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../../../shared/services/lsg.api.service';
import { MediaLibraryFileModel } from '../../../../../models/sales-funnel';
import { lastValueFrom } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { OnDestroy, AfterViewInit } from '@angular/core';
import { SharedProductService } from '../../../product/shared-product.service';
import { ProductModel } from '../../../product/models/product';
import { CustomNgbModalService } from 'src/app/shared/services/custom-ngbmodal.service';

@Component({
  selector: 'app-funnel-create',
  templateUrl: './funnel-create.component.html',
  styleUrls: ['./funnel-create.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FunnelCreateComponent implements OnInit, OnDestroy, AfterContentInit {
  hasCustomTemplate = false;
  @ContentChild('customTemplate') public customTemplateRef: ElementRef;
  // @Input() customAction = false;
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() includeThisProduct: number;
  @Input() setThisPortal: number;

  @Output() onCreateDone = new EventEmitter();
  // loading: { currencies: boolean; portals: boolean } = {
  //   currencies: true,
  //   portals: true,
  // };
  // Multi Select
  selectedItems: ProductModel[] = [];

  // Multi Select
  ProductTypes: number[] = [1];
  contentSelectedFiles: number[] = [];
  ImageSelectedFiles: number[] = [];
  includedProductsSelect: ProductModel[] = [];

  constructor(
    private modalService: CustomNgbModalService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private toastr: ToastrService,

    public sharedProductService: SharedProductService
  ) {
    this.createOfferErrorHandler = this.createOfferErrorHandler.bind(this);
  }

  ngOnDestroy(): void {}

  ngAfterContentInit() {

    this.hasCustomTemplate = !!this.customTemplateRef;
  }


  async ngOnInit() {
    // Check if the route data indicates that the modal should be shown
    const showInModal = this.route.snapshot.data['showModal'];

    if (showInModal) {
      // Open the modal when the route is activated
      this.openModalProcess()
    }
    // await this.sharedOfferService.getProducts();
  }

  openModalProcess() {
    this.open();
    // if (!!this.includeThisProduct) {
    //   this.createOfferForm.patchValue({ productId: this.includeThisProduct });
    // }
  }
  // updatePrice(formGroup: FormGroup) {
  //   this.createOfferForm.get('payment_type.price').patchValue(formGroup.value)
  //   // this.createOfferForm.get('payment_type.price').setValue(eventValue);
  // }

  resetForm() {
    // this.createOfferForm.reset();
  }
  // onOfferCreateDone() {
  //   // this.route.navigate(['dashboard', 'product']);
  //   this.modalService.dismissAll();
  //   this.onCreateDone.emit();
  //   this.toastr.success('Done!', 'Offer Created Successfully', {
  //     closeButton: false,
  //     positionClass: 'toast-bottom-right',
  //   });
  //   if (!!this.route.url.includes('product')) {
  //     this.sharedProductService.getProductById(
  //       this.sharedProductService.currentProductId
  //     );
  //   }
  // }

  createOfferErrorHandler(error: any) {
    alert(error.message);
  }

  async afterOpenModal() {
    // this.router.navigate(['dashboard', 'funnel', 'create', 'funnel']);
    // await this.sharedOfferService.getProducts();
    // await this.sharedOfferService.getUserPortals();
    // await this.sharedOfferService.getCurrencies();
  }

  async onOpenModal() {
    this.resetForm();
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        fullscreen: true,
        animation: false,
        backdrop: true,
        // beforeDismiss: ()=> {
        //   return false
        // },
        windowClass:
          'modal-animate-in lesson-item-modal learmo-full-screen-modal',
      })
      .result.then(
        (result) => {
          this.resetForm();
        },
        (reason) => {
          this.resetForm();
        }
      );
    await this.afterOpenModal();
  }

  // addPaymentControls() {
  //   // this.createOfferForm.get('paymentPrice').addValidators(Validators.required);
  //   // this.createOfferForm
  //   //   .get('paymentCurrency')
  //   //   .addValidators(Validators.required);
  //   this.createOfferForm.addControl(
  //     'paymentPrice',
  //     new UntypedFormControl('', {
  //       validators: [Validators.required],
  //       updateOn: 'change',
  //     })
  //   );

  //   this.createOfferForm.addControl(
  //     'paymentCurrency',
  //     new UntypedFormControl('', {
  //       validators: [Validators.required],
  //       updateOn: 'change',
  //     })
  //   );

  //   this.refreshPaymentControls();
  // }

  // onPriceChange(value: any) {
  //   if (value <= 0 && value != null) {
  //     this.offerObject.payment_type.name = Offer_Payment_Type_Enum.free;
  //   }
  // }

  // refreshPaymentControls() {
  //   const paymentPrice = this.createOfferForm.get('paymentPrice');
  //   const paymentCurrency = this.createOfferForm.get('paymentCurrency');
  //   paymentPrice?.updateValueAndValidity();
  //   paymentCurrency?.updateValueAndValidity();
  // }

  // removePaymentControls() {
  //   const paymentPrice = this.createOfferForm.get('paymentPrice');
  //   const paymentCurrency = this.createOfferForm.get('paymentCurrency');
  //   if (!!paymentPrice) {
  //     this.createOfferForm.removeControl('paymentPrice');
  //   }
  //   if (!!paymentCurrency) {
  //     this.createOfferForm.removeControl('paymentCurrency');
  //   }

  //   // this.refreshValidators();
  // }
  // onChangePaymentType(value: any) {
  //   switch (value) {
  //     case 'one_time':
  //       this.addPaymentControls();
  //       this.createOfferForm.get('paymentCurrency').markAsUntouched();
  //       this.createOfferForm.get('paymentPrice').markAsUntouched();
  //       break;

  //     case 'free':
  //       setTimeout(() => {
  //         this.removePaymentControls();
  //       }, 300);

  //       // this.offerObject.payment_type.currency_id = null;
  //       this.offerObject.payment_type.price = null;
  //       break;
  //     default:
  //       this.removePaymentControls();
  //       // this.offerObject.payment_type.currency_id = null;
  //       this.offerObject.payment_type.price = null;
  //       break;
  //   }
  // }
}
