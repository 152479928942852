import { Component, OnInit, Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";



// import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class ProgressSpinnerService {
    isLoading = false;
    message = 'Loading';
    messageListner : EventEmitter<string>;
    constructor() {
        this.messageListner = new EventEmitter();
    }

    showLoader(message: string) {
        this.messageListner.emit(message);
        if (message !== '') {
            this.message = message;
        }
        this.isLoading = true;
    }
    hideLoader() {
        this.message = "Loading";
        this.isLoading = false;
    }
}
