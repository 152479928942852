import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AffiliateLinkTypeEnum } from '../../afiliate-links/create/create.component';
import {
  AffiliatesInvitationStatusEnum,
  AffiliatesEarnings,
  AffiliateMinimizedModel,
} from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-affiliate-mini-item',
  templateUrl: './affiliate-mini-item.component.html',
  styleUrls: ['./affiliate-mini-item.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AffiliateMiniItemwComponent implements OnInit {
  affiliateLinkTypeEnum = AffiliateLinkTypeEnum;
  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;
  @Input() affiliate: AffiliateMinimizedModel;

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {}
}
