import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { LoginMethodsEnum } from 'src/app/components/auth/login-alt/user-login-form-alt/user-login-form-alt.component';
import { ApiService } from 'src/app/shared/services';
import { changeLoginMethodConfigActions, userLoginMethodConfigActions } from 'src/app/shared/store/_features/user/profile/profile.actions';
import { selectLoginMethods } from 'src/app/shared/store/_features/user/user.selector';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

export enum LoginMethodStatusEnum {
    enabled = 'enabled', // When the method is enabled
    disabled = 'disabled', // When the method is disabled
    ineligible = 'ineligible', // When the user is not eligible for the login method (Ex. MagicLink :Email is not verified, Phone: Phone number is not verified)
}

export class LoginMethodUIItem {
  displayName: string;
  enum: LoginMethodsEnum;
  description: string;
  icon: string;
  soon?: boolean;
  enabled?: LoginMethodStatusEnum;
  reason?: string;
  default?: boolean;
  visible?: boolean;
}
@Component({
  selector: 'app-login-methods',
  templateUrl: './login-methods.component.html',
  styleUrls: ['./login-methods.component.css']
})
export class LoginMethodsComponent implements OnInit {
  loginMethodStatusEnum = LoginMethodStatusEnum;
  loginMethodsEnum = LoginMethodsEnum;
  _loginMethods: LoginMethodUIItem[] = [
  ]

  _magicLinkOnBoarding: boolean = false;
  isEmailVerified: boolean = false;
  constructor(private api: ApiService, private store: Store, private sharedProfileService: SharedProfileService, private toastr: ToastrService) {
    // this.store.select(isEmailConfirmed).subscribe((isEmailVerified) => {
    //   this.isEmailVerified = isEmailVerified;
    // })
    this.store.select(selectLoginMethods).subscribe((loginMethods) => {
      this._loginMethods = loginMethods;
    })
  }

  ngOnInit() {
    // this.buildMethodsMenu();
    this.store.dispatch(userLoginMethodConfigActions.get())
  }


  async enableMethod(method: LoginMethodsEnum) {
    this.store.dispatch(changeLoginMethodConfigActions.change({ method: method, enabled: true }));
  }

  async disableMethod(method: LoginMethodsEnum) {
    this.store.dispatch(changeLoginMethodConfigActions.change({ method: method, enabled: false }));
  }

  openMagicLinkOnboarding() {
    this._magicLinkOnBoarding = true;
  }




  onMethodClick(method: LoginMethodUIItem) {
    switch (method.enum) {
        case LoginMethodsEnum.email:
        this.onEmailLoginClick(method);
        break;
      case LoginMethodsEnum.magic_link:
        this.onMagicURLClick(method);
        break;
      case LoginMethodsEnum.phone:
        this.onSMSLoginClick(method);
        break;
      case LoginMethodsEnum.authenticator:
        this.onAuthenticatorClick(method);

        break;

      default:
        break;
    }

  }

  onMagicURLClick(method: LoginMethodUIItem) {
    if (!this.sharedProfileService.appwriteProfile?.emailVerification) {
      this.sharedProfileService.scrollToVerification()
    this.toastr.warning('You have to verify your email first.')
      return;
    }

    switch (method?.enabled) {
      case LoginMethodStatusEnum.enabled:
        this.disableMethodAlert(method);

        break;
        case LoginMethodStatusEnum.disabled:

        this.openMagicLinkOnboarding();
        break;
        case LoginMethodStatusEnum.ineligible:

        if (!!method?.reason) {
        this.toastr.warning(method?.reason, 'Cannot enable')
        }
        break;
      default:
        break;
    }

  }

  onEmailLoginClick(method: LoginMethodUIItem) {
  }

  onSMSLoginClick(method: LoginMethodUIItem) {
  }

  onAuthenticatorClick(method: LoginMethodUIItem) {
  }




  disableMethodAlert(method: LoginMethodUIItem) {
    Swal.fire({
      title: 'Are you sure?',
      html: `By confirming, you are indicating that you intend to disable login method <strong>${method.displayName}</strong>`,
      icon: 'warning',
      width: '400px',
      buttonsStyling: false,

      reverseButtons: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Apply Changes',
      customClass: {
        confirmButton: 'btn btn-light-warning',
        cancelButton: 'btn btn-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.disableMethod(method.enum);
      }
    });
  }
}
