import { SystemProcessLoadingStatus } from './../../../../models/global-classes';
import { productAdapter } from './../products/products.reducer';
// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import { OfferItem } from '../../../../components/user-dashboard/offer/models/offer';
import { createNewOfferActions,      updateOfferStatusActions, deleteOfferActions, startOfferLoadingActions,   cloneOfferActions, offersActions, currentOfferActions, updateOfferActions } from './offers.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { forgetSession } from '../user/auth/auth.actions';



export interface OffersState extends EntityState<OfferItem> {
  currentOffer: OfferItem;
  currentOfferProcess: {status: SystemProcessLoadingStatus, error: any};
  createNewOfferProcess: {status: SystemProcessLoadingStatus, error: any};
  offersLoadingProcess: {status: SystemProcessLoadingStatus, error: any};
  cloneOfferProcess: { status: SystemProcessLoadingStatus; error: any }
}

export const offerAdapter = createEntityAdapter<OfferItem>();

const initialState: OffersState = offerAdapter.getInitialState({
  currentOffer: null,
  currentOfferProcess: {status: 'none', error: null},
  createNewOfferProcess: {status: 'none', error: null},
  offersLoadingProcess: {status: 'none', error: null},
  cloneOfferProcess: {status: 'none', error: null}
});

export const offersReducer = createReducer(
  initialState,
  on(offersActions.load, (state) => ({ ...state, loading: true, error: null })),
  on(offersActions.loadSuccess, (state, { offers }) => {
    return offerAdapter.setAll(offers, { ...state, loaded: true });
  }),
  on(offersActions.loadFailure, (state, { error }) => ({ ...state, error, loaded: false })),
  // Handle other actions and state changes here

// Current Offer Management
  on(currentOfferActions.load, (state) => ({ ...state, currentOfferProcess: {status: 'loading', error: null}  })),
  on(currentOfferActions.loadSuccess, (state, { offer }) => ({ ...state, currentOffer: new OfferItem(offer), currentOfferProcess: {status: 'done', error: null} })),
  on(currentOfferActions.loadFailure, (state, { error }) => ({ ...state, error, currentOfferProcess: {status: 'error', error: error.message} })),



  on(updateOfferActions.updateSuccess, (state, { offerId, changes }) => {
    let currentOfferUpdate = state.currentOffer
    if (offerId == state.currentOffer?.id) {
      currentOfferUpdate = {...changes, updating: 'done' } as OfferItem;
    }


    return offerAdapter.updateOne({id: offerId, changes: {...changes ,updating: 'done'}}, {...state, currentOffer: new OfferItem(currentOfferUpdate)});
  }),

  on(updateOfferActions.updateFailure, (state, { error }) => ({ ...state, currentOffer: new OfferItem({...state.currentOffer, updating: 'error', errorMessage: error.message}) })),


  // on(updateOfferStatusSuccess, (state, { offer }) => {
  //   let currentOfferUpdate = state.currentOffer
  //   if (offer.id == state.currentOffer?.id) {
  //     currentOfferUpdate = {...state.currentOffer, ...offer, updating: 'done' } as OfferItem;
  //   }


  //   return offerAdapter.updateOne({id: offer.id, changes: {...offer ,updating: 'done'}}, {...state, currentOffer: currentOfferUpdate});
  //   // return { ...state, products: [product, ...state.products], loaded: true };
  // }),

  on(startOfferLoadingActions.startOffer, (state, { offerId, loadingStatus }) => {
    const targetOfferFromCollection = state.entities[offerId];
    console.log("targetOfferFromCollection",targetOfferFromCollection)
    let currentOfferUpdate = state.currentOffer
    if (offerId == state.currentOffer?.id) {
      currentOfferUpdate = {...currentOfferUpdate, updating: loadingStatus } as OfferItem;
    }

    if (!!targetOfferFromCollection) {
      return offerAdapter.updateOne({id: offerId, changes: {...(targetOfferFromCollection || {}) ,updating: loadingStatus}}, state);
    }

    return state;
  }),

  on(startOfferLoadingActions.startOffer, (state, { offerId, loadingStatus }) => {
    const isCurrentOffer = offerId == state.currentOffer?.id;

    if (isCurrentOffer) {
      return {...state, currentOffer: {...state.currentOffer, updating: loadingStatus} as OfferItem};
    }


    return state;
  }),

  on(cloneOfferActions.clone, (state, { }) => ({...state, cloneOfferProcess: {status: 'loading', error: null} })),
  on(cloneOfferActions.cloneFailure, (state, { error }) => {
    return {...state, cloneOfferProcess: {status: 'error', error: error.message} };
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),

  on(createNewOfferActions.create, (state, { createPayload }) => ({...state, createNewOfferProcess: {status: 'loading', error: null} })),
  on(createNewOfferActions.createSuccess, (state, { offer }) => {
    return offerAdapter.addOne(offer, {...state, createNewOfferProcess: {status: 'done', error: null}, cloneOfferProcess: {status: 'done', error: null} });
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(createNewOfferActions.createFailure, (state, { error }) => {
    return {...state, createNewOfferProcess: {status: 'error', error: error.message} };
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),




  on(deleteOfferActions.deleteSuccess, (state, { offerId }) => offerAdapter.removeOne(offerId, { ...state })),

  on(forgetSession, (state) => initialState)
);

