import { Injectable } from '@angular/core';

export enum EditModeType {
  none,
  input_edit,
  saved_changes,
}

export enum EditPasswordModeType {
  none,
  input_edit,
  saved_changes,
}

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor() {}

  copyToClipboard(val: string) {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
      navigator.clipboard.writeText(val)
        .then(() => {
          
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error);
        });
    } else {
      console.warn('Clipboard API not supported');
    }
  }
}
