import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

export enum SummaryItemType {
  template = 'template',
  funnel = 'funnel',
  portal = 'portal',
}
@Component({
  selector: 'app-informative-section',
  templateUrl: './informative-section.component.html',
  styleUrls: ['./informative-section.component.css'],
})
export class InformativeSectionComponent implements OnInit {
  @Input() title: string;
  @Input() image: string;
  @Output() onClickChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  changeAction() {
    this.onClickChange.emit();
  }
}
