import { Component, OnInit, Output, EventEmitter, ElementRef, ContentChild, AfterContentInit, Input } from '@angular/core';


@Component({
  selector: 'app-list-is-empty',
  templateUrl: './list-is-empty.component.html',
  styleUrls: ['./list-is-empty.component.css']
})
export class ListIsEmptyComponent implements OnInit {

  @Input() svgUrl: string = 'assets/images/svg/notfound.svg'
  @Input() mainTitle: string = "Create your first product";
  @Input() subTitle:string =  `Once you start adding products, you'll see them here.`;

  @Input() customActionButton: boolean = false;

  // @Input() customActionButtonLabel: boolean = false;

  @Output() actionButtonClick = new EventEmitter<any>();
  @Output() secondaryButtonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }




}
