import { SharedProfileService } from './../../../../../../profile/shared-profile.service';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { SharedPortalViewService } from '../../../shared-portal-view.service';
import { ApiService } from '../../../../../../../shared/services/lsg.api.service';
import { Observable, catchError, lastValueFrom, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  BtcpayPaymentGatewayModel,
  PaymentGatewaysIntegration,
  Payment_Gateway_Enum,
  PaypalPaymentGatewayModel,
  StripePaymentGatewayModel,
} from '../../../../../../../models/portal';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

declare var paypal: any; // Declare PayPal variable

// export class PayPalToken {
//   access_token: string;
//   app_id: string;
//   expires_in: number;
//   nonce: string;
//   scope: string;
//   token_type: string;
// }
@Component({
  selector: 'app-method-form',
  templateUrl: './method-form.component.html',
  styleUrls: ['./method-form.component.css'],
})
export class MethodFormComponent implements OnInit {
  
  
  onPaypalEmail = new EventEmitter<string>();
  @ViewChild('paypalButton') existingButton: ElementRef;
  @ViewChild('paypalContainer') paypalContainer: ElementRef;
  nonce: string;
  paypalLoaded = false;
  integrationUrl = '';
  processing: boolean = false;
  @Input() gateway: Payment_Gateway_Enum;

  dataError = false;
  stripeObject: StripePaymentGatewayModel = new StripePaymentGatewayModel();
  btcpayObject: BtcpayPaymentGatewayModel = new BtcpayPaymentGatewayModel();
  paypalObject: PaypalPaymentGatewayModel = new PaypalPaymentGatewayModel();

  integratePaymentForm: UntypedFormGroup;

  @Output() onStartLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSuccess: EventEmitter<Payment_Gateway_Enum> = new EventEmitter<Payment_Gateway_Enum>();

  // @Output() onIntegrationDone = new EventEmitter<Payment_Gateway_Enum>();

  navigationInProgress: boolean = false;
  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    public sharedProfileService: SharedProfileService,
    public route: ActivatedRoute,
    public router: Router,
    public api: ApiService,
    public fb: UntypedFormBuilder,
    public toastr: ToastrService,
    public progressSpinnerService: ProgressSpinnerService,
    private http: HttpClient,
    private renderer: Renderer2
  ) {
    this.integratePaymentForm = new UntypedFormGroup({

 

    
    });

    
  }

   // Function to delay navigation

  ngOnInit(): void {
    if (this.gateway.toLowerCase() == Payment_Gateway_Enum.paypal.toLowerCase()) {
      

      
    }
 
    
  }


  
 

 

  // Explicitly assign the function to the global scope
 

  // openPopupWindow(url: string) {
  //   const popupOptions = 'width=400,height=600,left=100,top=100';
  //   window.open(url, '_blank', popupOptions);
  // }






}
