import { NotificationElement } from './../../../../models/owner-dashboard';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';


// Actions for loading websites
export const NotificationsActions = createActionGroup({
  source: 'Load Notifications',
  events: {
    load:emptyProps(),
    loadSuccess: props<{ notifications: NotificationElement[], unreadNotificationsCount: number }>(),
    loadFailure: props<{ error: any }>(),
  },
});





// Actions for updating identity
export const newNotifications = createAction('[Notifications] New Notifications Success', props<{ notification: NotificationElement }>());
export const setNotificationAsRead = createAction('[Notifications] Set Notifications as Read', props<{ notificationsIds: number[] }>());
export const setNotificationAsReadSuccess = createAction('[Notifications] Set Notifications as Read Success', props<{ notificationsIds: number[] }>());


// Actions for loading websites
// export const loadNotifications = createAction('[Notifications] Load Notifications');
// export const loadNotificationsSuccess = createAction('[Notifications] Load Notifications Success', props<{ notifications: NotificationElement[], unreadNotificationsCount: number }>());
// export const loadNotificationsFailure = createAction('[Notifications] Load Notifications Failure', props<{ error: any }>());


