import {
  OwnerFunnelEmail,
  OwnerFunnelPage,
} from './../../../../../../../models/funnels';
import { SharedFunnelService } from './../../../../shared-funnel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDashboardService } from '../../../../../../../shared/services/user-dashboard.service';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';


@Component({
  selector: 'app-html-builder',
  templateUrl: './html-builder.component.html',
  styleUrls: ['./html-builder.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HtmlBuilderComponent implements OnInit, OnDestroy {
  // @ViewChild(DiceTemplateEditorComponent)
  // private emailEditor: DiceTemplateEditorComponent;
  public minHeight = '0px';
  public optionsConfig = {
    editor: { displayMode: 'email' },
    specialLinks: {},
    features: {},
    tools: {
      html: {
        enabled: false,
      },
      menu: {
        enabled: false,
      },
    },
    appearance: {
      theme: 'dark',
      panels: {
        tools: {
          dock: 'right',
        },
      },
    },
  };

  editData: {
    funnelId: number;
    parentId: number;
    emailId: number;
    currentEmail?: OwnerFunnelEmail;
  } = {
    funnelId: null,
    parentId: null,
    emailId: null,
    currentEmail: null,
  };
  constructor(
    public sharedFunnelService: SharedFunnelService,
    public userDashboardService: UserDashboardService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const sub = this.route.params.subscribe((params) => {
      this.editData = {
        funnelId: params['funnelId'],
        parentId: params['parentId'],
        emailId: params['emailId'],
      };
    });

    // this.userDashboardService.hideSidebar();
    this.userDashboardService.hideUi(['header', 'sidebar', 'toolbar']);
  }
  ngOnDestroy(): void {
    this.userDashboardService.showUi(['header', 'sidebar', 'toolbar']);
  }

  async ngOnInit() {
    let emailIndex;
    this.sharedFunnelService.loaded = false;
    await this.sharedFunnelService.getFunnelById(this.editData.funnelId);
    this.sharedFunnelService.loaded = false;
    let pageIndex = this.sharedFunnelService.funnel.sequence.findIndex(
      (item) => item.id == this.editData.parentId
    );
    if (pageIndex > -1) {
      emailIndex = (
        this.sharedFunnelService.funnel.sequence[pageIndex] as OwnerFunnelPage
      ).automations.findIndex((item) => item.id == this.editData.emailId);
    }

    this.editData.currentEmail = (
      this.sharedFunnelService.funnel.sequence[pageIndex] as OwnerFunnelPage
    ).automations[emailIndex];
    this.sharedFunnelService.loaded = true;
  }

  backToFunnel() {
    this.router.navigate([
      'dashboard',
      'funnel',
      'ip',
      this.editData.funnelId,
      'edit',
    ]);
  }
  // called when the editor is created
  editorLoaded(event) {
    // load the design json here

    
    // this.emailEditor.editor.loadDesign(
    //   JSON.parse(this.editData.currentEmail.content)
    // );
    // this.emailEditor.editor.displayMode = this.optionsConfig.editor.displayMode;
  }

  // called when the editor has finished loading
  editorReady() {}

  exportHtml() {
    // this.emailEditor.editor.saveDesign(async (data) => {
    //   this.editData.currentEmail.content = JSON.stringify(data);
    //   this.editData.currentEmail.slotId = undefined;
    //   await this.sharedFunnelService.updateEmail(
    //     this.editData.currentEmail,
    //     this.editData.funnelId
    //   );
    // });
  }
}
