import { OwnerFunnelStatusEnum } from './../../../../../../models/funnels';
import { FunnelItem } from 'src/app/models/funnels';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnel-published-draft-label',
  templateUrl: './funnel-published-draft-label.component.html',
  styleUrls: ['./funnel-published-draft-label.component.css'],
})
export class FunnelPublishedDraftLabelComponent implements OnInit {
  ownerFunnelStatusEnum = OwnerFunnelStatusEnum;
  @Input() funnel: FunnelItem;
  constructor() {}

  ngOnInit(): void {}
}
