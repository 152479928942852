import { EventEmitter, Injectable } from '@angular/core';

import { AffiliateApi } from '../../services/affiliate.api.service';
import { lastValueFrom } from 'rxjs';
import { GetOnwersForAffiliateResponse } from '../../../models/affiliates-dashboard';

@Injectable({
  providedIn: 'root',
})
export class SharedAffiliateDashboardService {
  loaded = false;
  internalLoaded = false;
  myOwnersList: GetOnwersForAffiliateResponse = {
    invitedNetworks: [],
    joinedNetworks: [],
  };

  constructor(public affiliateApi: AffiliateApi) {}
  canNavigateAll = true;

  async getMyOwnersList() {
    this.startInternalLoading();
    let ownersList$ = this.affiliateApi.getMyOwners();
    let ownersList = await lastValueFrom(ownersList$);
    this.myOwnersList = ownersList;
    this.stopInternalLoading();
  }

  async reloadMyOwnersList() {
    let ownersList$ = this.affiliateApi.getMyOwners();
    let ownersList = await lastValueFrom(ownersList$);
    this.myOwnersList = ownersList;
  }

  startInternalLoading() {
    this.internalLoaded = false;
  }

  stopInternalLoading() {
    this.internalLoaded = true;
  }

  startLoading() {
    this.loaded = false;
  }

  stopLoading() {
    this.loaded = true;
  }

  enableNavigate() {
    this.canNavigateAll = true;
  }

  disableNavigate() {
    this.canNavigateAll = false;
  }
}
