
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { emailRegex } from 'src/app/shared/services/regex';

import { RegisterModel } from 'src/app/models/register';
import { AuthenticationService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';
import { cardAnimation, formSlideInAnimation } from 'src/app/shared/animations/animations';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { environment } from 'src/environments/environment';
import { OAuthProvider } from 'appwrite';

export enum RegisterErrorCode {
  username_exist = 602,
  email_exist = 609,
}

export enum CurrentRegistrationStep {
  capture = 'capture',
  register = 'register',
}
@Component({
  selector: 'app-user-register-form',
  templateUrl: './user-register-form.component.html',
  styleUrls: ['./user-register-form.component.css'],
  animations: [formSlideInAnimation, cardAnimation],
})
export class UserRegisterFormComponent implements OnInit {
  OAuthProvider = OAuthProvider;

  isOAuthEnabled: boolean = false;

  currentRegistrationStep = CurrentRegistrationStep;
  currentStep: CurrentRegistrationStep = CurrentRegistrationStep.capture;

  @Output() onLoadingStart = new EventEmitter<any>();
  @Output() onLoadingDone = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  passwordInputType: 'password' | 'text' = 'password';

  validationObj: { isEmailExist: boolean; isUsernameExist: boolean } = {
    isEmailExist: false,
    isUsernameExist: false,
  };

  signupForm = new FormGroup({
    leadGroup: new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(emailRegex),
      ]),
    }),
    registrationGroup: new FormGroup({
      // username: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      toc: new FormControl(false, [this.requiredTrueValidator]),
    }),
  });

  constructor(
    private authenticationService: AuthenticationService,
    public appwriteService: AppwriteService
  ) {
    this.isOAuthEnabled = environment.appwrite.oAuthEnabled
  }

  ngOnInit() {
    let emailControl = this.signupForm.get('leadGroup').get('email');
    emailControl.valueChanges.subscribe((value) => {
      if (emailControl.hasError('isExist')) {
        emailControl.hasError = undefined;
      }
    });

    // let usernameControl = this.signupForm
    //   .get('registrationGroup')
    //   .get('username');
    // usernameControl.valueChanges.subscribe((value) => {
    //   if (usernameControl.hasError('isExist')) {
    //     usernameControl.hasError = undefined;
    //   }
    // });
    // this.signupForm.controls.password.valueChanges.subscribe((value)=> {
    // });
  }

  isCurrentStep(step: CurrentRegistrationStep): boolean {
    return this.currentStep === step;
  }

  // Custom validator for "I Agree" checkbox
  requiredTrueValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (!control?.value) {
      return { requiredTrue: true };
    }
    return null;
  }

  togglePasswordVisibility() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  async captureLead(payload: Partial<RegisterModel>) {
    let request$ = this.authenticationService.captureLead(
      payload,
      this.handleCaptureLeadError
    );
    let response = await lastValueFrom(request$);
    
  }

  handleCaptureLeadError = (error: {
    error: string;
    message: string;
    statusCode: number;
  }) => {
    switch (error?.statusCode) {
      case 609:
        this.validationObj.isEmailExist = true;
        this.signupForm
          .get('leadGroup')
          .get('email')
          .setErrors({ isExist: true });
        break;
      default:
        break;
    }
    this.onSignupError(error);
  };

  async signUp(registerModel: Partial<RegisterModel>) {
    this.onSignupStart();
    let request = this.authenticationService.register(
      registerModel,
      this.handleRegisterError
    );

    // this.onSignupDone(response);

    //   this.authenticationService
    //     .register(registerModel, this.handleRegisterError)
    //     .subscribe(async (response) => {
    //       if (this.paymentMethod === PaymentMethodEnum.stripe) {
    //         this.payEmit.emit({
    //           bundleID: this.selectedPlan.plan.id,
    //           paymentProcess: PaymentProcessType.save,
    //         });
    //       } else {
    //         let subscribe: { created: boolean } = await this.paymentService
    //           .subscriptionBtcPay(this.selectedPlan.plan.id)
    //           .toPromise();
    //         if (!!subscribe.created) {
    //           this.fireCreatedAccountAlert();
    //         } else {
    //           this.fireStopLoadingSwalError('Error');
    //         }
    //       }
    //     });
    // } else {
    //   if (!this.isPaymentValid && !!this.registerform.valid) {
    //     this.PaymentErrorMessage(
    //       'Please enter a valid credit card information.'
    //     );
    //   }
    // }
  }

  handleRegisterError = (error: {
    error: string;
    message: string;
    statusCode: number;
  }) => {
    switch (error?.statusCode) {
      case 609:
        this.validationObj.isEmailExist = true;
        this.signupForm
          .get('leadGroup')
          .get('email')
          .setErrors({ isExist: true });
        break;
      case 602:
        this.signupForm
          .get('registrationGroup')
          .get('username')
          .setErrors({ isExist: true });
        break;

      default:
        break;
    }
    this.onSignupError(error);
  };

  onSubmit() {
    if (this.isCurrentStep(CurrentRegistrationStep.capture)) {
      this.submitCaptureStep();
    } else if (this.isCurrentStep(CurrentRegistrationStep.register)) {
      this.submitRegisterStep();
    }
  }

  private async submitCaptureStep() {
    this.signupForm.controls.leadGroup.markAllAsTouched();

    if (this.signupForm.controls.leadGroup.valid) {
      const payload: Partial<RegisterModel> = {
        firstname: this.signupForm.value.leadGroup.firstname,
        lastname: this.signupForm.value.leadGroup.lastname,
        email: this.signupForm.get('leadGroup').get('email').value,
      };

      await this.captureLead(payload);
      // this.signupForm.get('leadGroup').get('email').disable();
      this.currentStep = CurrentRegistrationStep.register;
    }
  }

  private submitRegisterStep() {
    
    this.signupForm.controls.registrationGroup.markAllAsTouched();
    this.logFormErrors(this.signupForm);

    if (
      this.signupForm.controls.leadGroup.valid &&
      this.signupForm.controls.registrationGroup.valid
    ) {
      const payload: Partial<RegisterModel> = {
        firstname: this.signupForm.value.leadGroup.firstname,
        lastname: this.signupForm.value.leadGroup.lastname,
        email: this.signupForm.get('leadGroup').get('email').value,
        // username: this.signupForm.value.registrationGroup.username,
        password: this.signupForm.value.registrationGroup.password,
        confirmPassword: this.signupForm.value.registrationGroup.password,
      };

      this.signUp(payload);
    }
  }

  logFormErrors(formGroup: FormGroup | FormControl, prefix: string = '') {
    let errors: any[] = []
    if (formGroup instanceof FormGroup) {
      for (const controlName in formGroup.controls) {
        const control = formGroup.get(controlName);

        if (control instanceof FormGroup || control instanceof FormControl) {
          this.logFormErrors(control, `${prefix}${controlName}.`);
        }
      }
    } else if (formGroup instanceof FormControl && formGroup.errors) {
      errors.push(formGroup.errors)
    }

    
  }

  onSignupStart() {
    this.onLoadingStart.emit();
  }

  onSignupDone(resp) {
    // this.authenticationService.onRegisterResponse(resp)
    setTimeout(() => {
      this.onLoadingDone.emit();
    }, 500);
  }

  onSignupError(error: { error: string; message: string; statusCode: number }) {
    this.onError.emit();
  }
}
