import { Title } from '@angular/platform-browser';
import { SummaryAnimationEnum } from './../../../components/user-dashboard/portal/dto/portal.input';
import { PortalActiveChoiceEnum } from 'src/app/shared/wizard/select-portal/select-portal.component';
import { SharedPortalService } from './../../../components/user-dashboard/portal/shared-portal.service';

import { Component, OnInit } from '@angular/core';
import {
  AffiliateDashboardData,
  TotalTrackingData,
} from 'src/app/models/owner-dashboard';
import { lastValueFrom } from 'rxjs';

import { trigger, transition, style, animate } from '@angular/animations';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SummaryItemType } from './summary-item/summary-item.component';

@Component({
  selector: 'app-wizard-summary',
  templateUrl: './wizard-summary.component.html',
  styleUrls: ['./wizard-summary.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class WizardSummaryComponent implements OnInit {
  summaryItemType = SummaryItemType;
  portalActiveChoiceEnum = PortalActiveChoiceEnum;
  summaryAnimationEnum = SummaryAnimationEnum;
  dashboardData: AffiliateDashboardData;
  createPortalForm = new UntypedFormGroup({
    portalName: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  constructor(
    public sharedPortalService: SharedPortalService,
    private title: Title
  ) {
    // this.title.setTitle('Summary');
  }

  async ngOnInit() {
    this.sharedPortalService.startLoading();
    setTimeout(() => {
      this.sharedPortalService.stopLoading();
    }, 500);
  }

  async goNext() {
    this.createPortalForm.markAllAsTouched();
    if (
      !!this.createPortalForm.valid ||
      this.sharedPortalService.wizardSettings.portalType ==
        PortalActiveChoiceEnum.existing
    ) {
      this.sharedPortalService?.wizardModel?.goToNextStep();
    }
  }
  // async getTemplates() {
  //   await this.sharedPortalService.getTemplates()
  // }
}
