import { Plan } from '../../../../models/plans';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../services';

import { Store } from '@ngrx/store';
import { UserPaymentCardsActions } from './payments.actions';
import { PaymentMethodModel } from '../../../../models/payment.model';




@Injectable()
export class PaymentsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}

  loadUserPaymentCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPaymentCardsActions.load),
      mergeMap(() =>
        this.api.getAllPaymentMethod().pipe(
          map((cards: PaymentMethodModel[]) => {
            console.log(cards)
            return UserPaymentCardsActions.loadSuccess({
              cards
            })
          }
          ),
          catchError((error) => of(UserPaymentCardsActions.loadFailure({ error })))
        )
      )
    )
  );


  // loadCurrentUserSubscription$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CurrentUserSubscriptionActions.load),
  //     mergeMap(() =>
  //       this.api.getSubscriptionBundleInfo().pipe(
  //         map((currentUserSubscriptionInfo: SubscriptionBillingInfo) =>
  //           CurrentUserSubscriptionActions.loadSuccess({
  //             currentUserSubscriptionInfo
  //           })
  //         ),
  //         catchError((error) => of(CurrentUserSubscriptionActions.loadFailure({ error })))
  //       )
  //     )
  //   )
  // );
}
