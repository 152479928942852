import { Component, OnInit, ViewChild } from '@angular/core';


import { NotificationsService } from '../../../services/notifications.service';
import { SharedMessengerService } from '../../../partials/messenger/shared-messenger.service';

import {
  UserDashboardService,
  DashboardSessionModeType,
} from '../../../services/user-dashboard.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NotificationElement } from 'src/app/models/owner-dashboard';

export enum NotificationsTabEnum {
  notifications = 'notifications',
  announcements = 'announcements',
}
export enum MessagesTabEnum {
  messages = 'messages',
}
@Component({
  selector: 'app-fast-actions-buttons',
  templateUrl: './fast-actions-buttons.component.html',
  styleUrls: ['./fast-actions-buttons.component.css'],
})
export class FastActionsButtonsComponent implements OnInit {
  @ViewChild('notificationDropdown', { static: false }) notificationDropdown: NgbDropdown;

  notificationsTabEnum = NotificationsTabEnum;
  messagesTabEnum = MessagesTabEnum;
  dashboardSessionModeType = DashboardSessionModeType;
  notificationsTab: NotificationsTabEnum = NotificationsTabEnum.notifications;
  messagesTab: MessagesTabEnum = MessagesTabEnum.messages;

  constructor(
    public userDashboardService: UserDashboardService,
    public notificationsService: NotificationsService,
    public sharedMessengerService: SharedMessengerService
  ) {}

  ngOnInit() {
    
  }

  onNotificationClicked(element: NotificationElement) {
    this.notificationDropdown.close();
  }
  onActivateNotifications() {
    this.notificationsTab = NotificationsTabEnum.notifications;
  }

  async onActivateMessages() {
    this.messagesTab = MessagesTabEnum.messages;
  }

  async onOpenNotificationsDrop() {
    let isAffiliate =
      this.userDashboardService?.dashboardSessionMode ==
      this.dashboardSessionModeType.affiliate;

    if (isAffiliate) {
      if (
        this.notificationsService.affiliateNotifications
          .unreadAnnouncementsCount >
        this.notificationsService.affiliateNotifications
          .unreadNotificationsCount +
          this.notificationsService.affiliateNotifications.unreadInvitaionsCount
      ) {
        this.onActivateAnnouncements();
      }
    }
  }

  goToMessenger() {
    this.sharedMessengerService.goToMessenger();
  }

  async onOpenMessageDrop() {
    let isAffiliate =
      this.userDashboardService?.dashboardSessionMode ==
      this.dashboardSessionModeType.affiliate;
    this.notificationsService.loaded.messages = false;
    if (!!this.sharedMessengerService.messengerData?.threads?.data?.length) {
      this.notificationsService.loaded.messages = true;
    } else {
      await this.sharedMessengerService.getMessengerThreads(
        undefined,
        undefined,
        undefined,
        undefined,
        isAffiliate
      );
      this.notificationsService.loaded.messages = true;
    }
    this.onActivateMessages();
  }

  onActivateAnnouncements() {
    this.notificationsTab = NotificationsTabEnum.announcements;
  }
}
