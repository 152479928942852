import { SystemProcessLoadingStatus, SystemTypedError } from 'src/app/models/global-classes';
import { AssignmentEditInput, ChangeCourseItemStatusInput, CourseHeirarchyModel, CourseHierarchyItem, CourseHierarchyItemTypeEnum, CourseLessonItem, CourseQuizItem, CourseQuizQuestionItem, CreateAssignmentInput, CreateModuleInput, CreateQuizInput, CreateSubModuleInput, DeleteCourseItemInput, LessonEditInput, ModuleEditInput, MoveQuizQuestionItemInput, QuizEditInput, SubModuleEditInput } from './../../../../../../components/user-dashboard/product/models/course';

// actions/products.actions.ts
import { createAction, createActionGroup, props } from '@ngrx/store';
import { CourseItemProcessChangePayload } from './course-items.reducer';



export const loadCourseItemFullDetails = createActionGroup({
  source: 'Load Course Item Full Details',
  events: {
    load: props<{
      productId: number;
      itemId: number;
      itemType: CourseHierarchyItemTypeEnum;
      errorHandler?: any;
    }>(),
    success: props<{ loadedItem: CourseHierarchyItem, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});


export const UpdateCourseItemActions = createActionGroup({
  source: 'Update Course Item Details',
  events: {
    update: props<{
      productId: number;
      itemId: number;
      itemType: CourseHierarchyItemTypeEnum;
      payload: ModuleEditInput | SubModuleEditInput | LessonEditInput | AssignmentEditInput | QuizEditInput;
      errorHandler?: any;
    }>(),
    success: props<{ updatedItem: ModuleEditInput | SubModuleEditInput | LessonEditInput | AssignmentEditInput | QuizEditInput, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});



export const CourseItemStatusActions = createActionGroup({
  source: 'Course Item Status Actions',
  events: {
    update: props<{
      productId: number;
      payload: ChangeCourseItemStatusInput,
      errorHandler?: any;
    }>(),
    success: props<{ heirarchy: CourseHeirarchyModel, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});


export const DeleteCourseItemActions = createActionGroup({
  source: 'Delete Course Item',
  events: {
    delete: props<{
      productId: number;
      payload: DeleteCourseItemInput;
      errorHandler?: any;
    }>(),
    success: props<{ heirarchy: CourseHeirarchyModel, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});





// Generic Actions
export const ChangeItemProcessStatus = createAction('Change Item Process Status', props<{ statusChange: CourseItemProcessChangePayload, error?: {processType: SystemProcessLoadingStatus, error: any}}>());