import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountSetupState, accountSetupAdapter } from './account-setup.reducer';
import { AccountSetupStepStatusEnum } from 'src/app/components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';



// Create a feature selector for the 'website' feature
export const selectAccountSetupState = createFeatureSelector<AccountSetupState>('accountSetup');

// // Selectors for website data
export const selectAccountSetupSteps = createSelector(selectAccountSetupState, (state) => accountSetupAdapter.getSelectors().selectAll(state));

export const selectAccountSetupNextDoStep = createSelector(selectAccountSetupState, (state) => {
    let selectAll = accountSetupAdapter.getSelectors().selectAll(state);
    let step = selectAll.find((step) => step.status == AccountSetupStepStatusEnum.NONE || step.status == AccountSetupStepStatusEnum.IN_PROGRESS);
    return step;
});


export const isAccountSetupStepsDone = createSelector(selectAccountSetupState, (state) => {
    let selectAll = accountSetupAdapter.getSelectors().selectAll(state);
    let isDone = selectAll.every((step) => step.status == AccountSetupStepStatusEnum.DONE);
    return isDone;
});

export const isAccountSetupStepsLoaded = createSelector(selectAccountSetupState, (state) => state.loaded);
export const isAccountSetupStepsLoadingError = createSelector(selectAccountSetupState, (state) => state.error);

// // Selectors for website pages
// export const selectWebsitePages = createSelector(selectWebsiteState, (state) => state.pages);
// export const isWebsitePagesLoaded = createSelector(selectWebsiteState, (state) => state.pagesLoaded);
// export const isWebsitePagesLoadingError = createSelector(selectWebsiteState, (state) => state.pagesError);
