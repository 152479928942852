import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubscriptionState } from './subscription.reducer';




// Create a feature selector for the 'website' feature
export const selectSubscriptionState = createFeatureSelector<SubscriptionState>('subscription');

// // Selectors for website data
export const selectSystemPlans = createSelector(selectSubscriptionState, (state) => state.systemPlans);
export const isSystemPlansLoaded = createSelector(selectSubscriptionState, (state) => state.loaded);
export const isSystemPlansLoadingError = createSelector(selectSubscriptionState, (state) => state.error);



export const selectCurrentUserSubscriptionInfo = createSelector(selectSubscriptionState, (state) => state.currentUserSubscriptionInfo);
export const isCurrentUserSubscriptionInfoLoaded = createSelector(selectSubscriptionState, (state) => state.currentUserSubscriptionInfoProcess.status == 'loading' ? false : true);
export const isCurrentUserSubscriptionInfoError = createSelector(selectSubscriptionState, (state) => state.currentUserSubscriptionInfoProcess.error);


// export const selectUnreadNotificationsCount = createSelector(selectNotificationsState, (state) => state.unreadNotificationsCount);

// export const isNotificationsLoaded = createSelector(selectNotificationsState, (state) => state.loaded);
// export const isNotificationsLoadingError = createSelector(selectNotificationsState, (state) => state.error);

// // Selectors for website pages
// export const selectWebsitePages = createSelector(selectWebsiteState, (state) => state.pages);
// export const isWebsitePagesLoaded = createSelector(selectWebsiteState, (state) => state.pagesLoaded);
// export const isWebsitePagesLoadingError = createSelector(selectWebsiteState, (state) => state.pagesError);
