import { ComponentCanDeactivate } from './component-can-deactivate';
import { Directive } from '@angular/core';
import { SharedOwnerDashboardService } from '../layout/owner-dashboard/shared-owner-dashboard.service';

@Directive()
export abstract class DashboardCanDeactivate extends ComponentCanDeactivate {
  constructor(public sharedDashboardService: SharedOwnerDashboardService) {
    super();
  }
  canDeactivate(): boolean {
    return !!this.sharedDashboardService.canNavigateAll;
  }
}
