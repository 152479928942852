import { Component, Input, OnInit } from '@angular/core';
import { MessengerThread } from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-chat-image',
  templateUrl: './messenger-chat-image.component.html',
  styleUrls: ['./messenger-chat-image.component.css'],
})
export class MessengerChatImageComponent implements OnInit {
  @Input() thread: MessengerThread;
  @Input() enableTooltip: boolean = true;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'sm';
  constructor() {}

  ngOnInit(): void {}
}
