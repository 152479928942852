// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Element Styling */
a:hover {
  text-decoration: none !important;
}

a.notification-element {
  background-color: var(--bs-body-bg);
}

a.notification-element:hover {
  background-color: var(--bs-light);
}


a.notification-element.unread {
  background-color: var(--bs-primary-light);
}

a.notification-element.unread:hover {
  background-color: var(--bs-primary-bg-subtle);
}





.toast-custom-notification {
  transition: all 150ms ease-in-out !important;
  text-align: left;
  padding: 1em 2em !important;
  background-color: #fff !important;
  border-radius: 14px;
  max-width: 500px;
  top: 0px;
  position: relative;
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.8;
}

.toast-custom-notification:hover {
  background-color: #fafafa;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notification-element/owner-notification-element.component.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,gCAAgC;AAClC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iCAAiC;AACnC;;;AAGA;EACE,yCAAyC;AAC3C;;AAEA;EACE,6CAA6C;AAC/C;;;;;;AAMA;EACE,4CAA4C;EAC5C,gBAAgB;EAChB,2BAA2B;EAC3B,iCAAiC;EACjC,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;EACR,kBAAkB;EAClB,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,wCAAwC;EACxC,UAAU;AACZ","sourcesContent":["/* Element Styling */\na:hover {\n  text-decoration: none !important;\n}\n\na.notification-element {\n  background-color: var(--bs-body-bg);\n}\n\na.notification-element:hover {\n  background-color: var(--bs-light);\n}\n\n\na.notification-element.unread {\n  background-color: var(--bs-primary-light);\n}\n\na.notification-element.unread:hover {\n  background-color: var(--bs-primary-bg-subtle);\n}\n\n\n\n\n\n.toast-custom-notification {\n  transition: all 150ms ease-in-out !important;\n  text-align: left;\n  padding: 1em 2em !important;\n  background-color: #fff !important;\n  border-radius: 14px;\n  max-width: 500px;\n  top: 0px;\n  position: relative;\n  box-shadow: 0 0 5px 0px rgb(0 0 0 / 20%);\n  pointer-events: auto;\n  cursor: pointer;\n  opacity: 0.8;\n}\n\n.toast-custom-notification:hover {\n  background-color: #fafafa;\n  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
