import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { AuthenticationService } from '../shared/services';
import { AppwriteService } from '../shared/services/appwrite.sdk.service';
import { User } from '../models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private appwriteService: AppwriteService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const disableInterceptor = request.headers.get('disableInterceptor');
    if (disableInterceptor) {
      return next.handle(request);
    }

    const token = this.authenticationService.currentTokenValue; //localStorage.getItem('token');
    const currentUserProfile: User = this.authenticationService.currentUserValue; //JSON.parse(localStorage.getItem('currentUser'));

    if (!token && !currentUserProfile?.id) {
      return next.handle(request);
    }

    if (
      this.appwriteService.isTokenValidParse(token, {URL: request.url})
    ) {
      const modifiedRequest = this.addAuthorizationHeader(request, token);
      return next.handle(modifiedRequest);
    } else {
      return this.handleBrokenToken(request, next);
    }
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private handleBrokenToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.authenticationService.isRefreshingToken) {
      this.authenticationService.isRefreshingToken = true;

      return this.authenticationService.refreshTokenObservable().pipe(
        mergeMap((newToken: string) => {
          console.log('Inter New Token Result: ', newToken);
          this.authenticationService.refreshTokenSubject.next(newToken);
          this.authenticationService.isRefreshingToken = false;

          const updatedRequest = this.addAuthorizationHeader(request, newToken);
          console.info('Repeating Current Request', request.url);
          return next.handle(updatedRequest);
        }),
        catchError((error: any) => {
          this.authenticationService.logout(true);
          this.authenticationService.isRefreshingToken = false;
          // location.reload();
          return throwError(() => 'Unauthorized');
        })
      );
    } else {
      return this.authenticationService.refreshTokenSubject.pipe(
        filter((token) => !!token),
        take(1),
        switchMap((newToken) => {
          // Retry the original request with the new token
          const updatedRequest = this.addAuthorizationHeader(request, newToken);
          console.info('Repeating Other Request', request.url);
          return next.handle(updatedRequest);
        })
      );
    }
  }
}
