import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services';

export enum FirstModalTypeEnum {
  tour = 'tour',
  portal = 'portal',
}
@Component({
  selector: 'app-first-modal',
  templateUrl: './first-modal.component.html',
  styleUrls: ['./first-modal.component.css'],
})
export class FirstModalComponent implements OnInit, OnDestroy {
  firstModalTypeEnum = FirstModalTypeEnum;
  @Input() type: FirstModalTypeEnum = FirstModalTypeEnum.tour;
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalOpener: EventEmitter<any>;

  @Output() onModalEvent: EventEmitter<any> = new EventEmitter<any>();

  modalRef: NgbModalRef;
  modalOpenerSubscription: Subscription;
  isOpen: boolean = false;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnDestroy(): void {
    // this.modalOpenerSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.modalOpenerSubscription = this.modalOpener.subscribe((event) => {
      if (!!this.isOpen) {
        this.closeModal();
        this.open();
      } else {
        this.open();
      }
    });
  }

  async onOpenModal() {
    this.resetEverything();

    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  closeModal() {
    this.modalRef.dismiss();
    
    this.onModalEvent.emit('close');
  }
  resetEverything() {}

  onStartTour() {
    this.onModalEvent.emit('startTour');
    this.modalRef.dismiss();
  }

  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in onboarding-new-modal',
    });
    this.isOpen = true;
    this.modalRef.result.then(
      (result) => {
        this.isOpen = false;
      },
      (reason: ModalDismissReasons) => {
        this.isOpen = false;
      }
    );
  }
}
