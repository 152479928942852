// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinking-cursor {
  font-weight: 100;
  font-size: inherit;
  color: #2e3d48;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.cursor-block {
  background-color: #616161;
  width: 7px;
  display: inline-block;
  /* min-height: 12px; */
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/ai/ai-cursor/ai-cursor.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE;;IAEE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,qBAAqB;EACrB,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".blinking-cursor {\n  font-weight: 100;\n  font-size: inherit;\n  color: #2e3d48;\n  animation: 1s blink step-end infinite;\n}\n\n@keyframes blink {\n  from,\n  to {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n\n.cursor-block {\n  background-color: #616161;\n  width: 7px;\n  display: inline-block;\n  /* min-height: 12px; */\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
