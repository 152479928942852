const ICONS = {
    category_name: 'Grid',
    icons_list: [
      "bootstrap",
      "figma",
      "dropbox",
      "xaomi",
      "microsoft",
      "android",
      "vue",
      "js",
      "spring-framework",
      "github",
      "dj",
      "google-play",
      "angular",
      "soft-3",
      "python",
      "soft-2",
      "ts",
      "xd",
      "spotify",
      "js-2",
      "laravel",
      "css",
      "google",
      "photoshop",
      "twitch",
      "illustrator",
      "pails",
      "react",
      "html",
      "slack",
      "soft",
      "yii",
      "apple",
      "vuesax"
    ]


};


export default ICONS
