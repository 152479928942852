import {
  OwnerFunnelEmail,
  OwnerFunnelPage,
} from './../../../../../models/funnels';
import { FunnelSequenceStep } from './../../../portal/portal.model';
import { SharedFunnelService } from '../../shared-funnel.service';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DashboardCanDeactivate } from 'src/app/shared/guards/dashboard-can-deactivate';
import { ApiService } from '../../../../../shared/services';

import { UserDashboardService } from '../../../../../shared/services/user-dashboard.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { AfterViewInit } from '@angular/core';
import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs/internal/Subscription';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';

export enum EditFunnelSidebarEnum {
  none = 'none',
  loading = 'loading',
  email = 'email',
  offer = 'offer',
  page = 'page',
}

@Component({
  selector: 'app-edit-funnel',
  templateUrl: './edit-funnel.component.html',
  styleUrls: ['./edit-funnel.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-250px)', opacity: 0 }),

        group([
          animate(300, style({ transform: 'translateX(0)' })),
          animate('300ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class EditFunnelComponent
  extends DashboardCanDeactivate
  implements OnInit, OnDestroy, AfterViewInit
{
  editFunnelSidebarEnum = EditFunnelSidebarEnum;

  pageUrl: string = '';
  iframeLoaded = false;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private route: Router,
    private aroute: ActivatedRoute,
    public sharedFunnelService: SharedFunnelService,
    private toastr: ToastrService,
    public userDashboardService: UserDashboardService,
    public sharedDashboardService: SharedOwnerDashboardService,
    public sanitizer: DomSanitizer,
    private title: Title
  ) {
    super(sharedDashboardService);
    this.userDashboardService.hideUi(['sidebar', 'header']);
    // this.sharedOfferService.pageStatus = 'edit-checkout';
    const sub = this.aroute.params.subscribe((params) => {
      this.sharedFunnelService.currentFunnelId = +params['id']; // (+) converts string 'id' to a number
    });
  }

  async ngOnInit() {
    if (!!this.sharedFunnelService.currentFunnelId) {
      await this.sharedFunnelService.getFunnelById(
        +this.sharedFunnelService.currentFunnelId
      );
    } else {
      this.sharedFunnelService.goToFunnels();
    }

    this.title.setTitle(
      this.sharedFunnelService.funnel.title + ' > ' + 'Edit Funnel'
    );
  }

  ngOnDestroy(): void {
    this.userDashboardService.showUi(['sidebar', 'header']);
    this.sharedFunnelService.closedMenu = true;
    this.sharedFunnelService.currentMenuTab = EditFunnelSidebarEnum.loading;
  }

  scrollHandler(event: any) {}

  ngAfterViewInit() {}

  backToMenu() {
    // this.sharedOfferService.backToMenu();
  }

  previewButton() {
    // window.open(this.sharedOfferService.checkout.previewLink, '_blank');
  }

  getLinkButton() {
    // alert(this.sharedOfferService.getOfferUrl());
  }
}
