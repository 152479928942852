import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebsiteState, pageAdapter } from './website.reducer';
import { WebsiteCompoundState } from '../../learmo.reducer';


// Create a feature selector for the 'website' feature
export const selectCompoundWebsiteState = createFeatureSelector<WebsiteCompoundState>('website');
export const selectWebsiteState = createSelector(selectCompoundWebsiteState, (state) => state.main);

// Selectors for website data
export const selectWebsite = createSelector(selectWebsiteState, (state) => state.website);
export const selectIsWebsiteReady = createSelector(selectWebsiteState, (state) => state.isWebsiteReady);

export const selectWebsiteId = createSelector(
    selectWebsiteState,
    (state) => state.website?.id
  );

  export const selectWebsiteIdentity = createSelector(
    selectWebsiteState,
    (state) => {
      let identity = JSON.parse(JSON.stringify(state.website.identity));
      identity.label = state.website.label;
      return identity
    }
  );

  export const selectWebsiteSenderMailConfig = createSelector(
    selectWebsiteState,
    (state) => {
      let senderMailConfig = JSON.parse(JSON.stringify(state.website.senderMailConfig));
      
      return senderMailConfig
    }
  );

export const isWebsiteLoaded = createSelector(selectWebsiteState, (state) => state.loaded);
export const isWebsiteLoadingError = createSelector(selectWebsiteState, (state) => state.error);

// Selectors for website pages
export const selectWebsitePages = createSelector(selectWebsiteState, (state) => pageAdapter.getSelectors().selectAll(state));
export const isWebsitePagesLoaded = createSelector(selectWebsiteState, (state) => state.pagesLoaded);
export const isWebsitePagesLoadingError = createSelector(selectWebsiteState, (state) => state.pagesError);





export const selectWebsiteCategories = createSelector(selectWebsiteState, (state) => state.categories);
export const isWebsiteCategoriesLoaded = createSelector(selectWebsiteState, (state) => state.categoriesProcess.status == 'loading' ? false : true);
export const isCreateWebsiteCategoriesProcess = createSelector(selectWebsiteState, (state) => ({...state.categoriesProcess, lastAddedCategory: state.lastAddedCategory}));


// export const selectWebsiteInstructors = createSelector(selectWebsiteState, (state) => state.instructors);
// export const isWebsiteInstructorsLoaded = createSelector(selectWebsiteState, (state) => state.instructorsProcess.status == 'loading' ? false : true);
// export const isCreateWebsiteInstructorsProcess = createSelector(selectWebsiteState, (state) => ({...state.instructorsProcess, lastAddedInstructor: state.lastAddedInstructor}));

