import { Payment_Gateway_Enum } from 'src/app/models/portal';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from 'src/app/shared/services';

import { SalesLogDetailedElement } from 'src/app/models/system-earnings';
import { CustomNgbModalService } from 'src/app/shared/services/custom-ngbmodal.service';


@Component({
  selector: 'app-sales-item-popup',
  templateUrl: './sales-item-popup.component.html',
  styleUrls: ['./sales-item-popup.component.css'],
})
export class SalesItemPopupComponent implements OnInit {
  salesPaymentMethodEnum = Payment_Gateway_Enum;


  @Input() saleItem: SalesLogDetailedElement;
  
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(
    
    private modalService: CustomNgbModalService,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {}

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      fullscreen: true,
      animation: false,
      backdrop: true,
windowClass: 'modal-animate-in lesson-item-modal learmo-full-screen-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  // FileUpload
}
