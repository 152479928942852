// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    display: flex;
    justify-content: center;
    align-items: center;
}
.background-format {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}


.img-item {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
.img-item.loading {
    opacity: 0;
    width: 0;
}

.thumb-container, .img-item, .skeleton-item {
    transition: all 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/list-thumbnail/list-thumbnail.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,kCAAkC;IAClC,4BAA4B;AAChC;;;AAGA;IACI,UAAU;IACV,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[":host {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.background-format {\n    background-size: 100%;\n    background-position: center center;\n    background-repeat: no-repeat;\n}\n\n\n.img-item {\n    opacity: 1;\n    transition: opacity 0.5s ease-in-out;\n    min-width: 100%;\n    min-height: 100%;\n    object-fit: cover;\n}\n.img-item.loading {\n    opacity: 0;\n    width: 0;\n}\n\n.thumb-container, .img-item, .skeleton-item {\n    transition: all 0.3s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
