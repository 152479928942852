import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-info-tooltip',
  templateUrl: './dynamic-info-tooltip.component.html',
  styleUrls: ['./dynamic-info-tooltip.component.css']
})
export class DynamicInfoTooltipComponent implements OnInit {
  @Input() config: {tooltipText: string}
  constructor() { }

  ngOnInit() {
  }

}
