import {
  isMediaLibraryLoaded,
  isMediaLibraryQuotaLoaded,
  selectMediaLibraryFiles,
  selectMediaLibraryQuota,
  selectMediaLibraryTypes,
} from './../../../store/_features/media-library/media-library.selector';
import { addNewMediaLibraryFiles, MediaLibraryActions,  MediaLibraryQuotaActions,  MediaLibraryTypesActions } from './../../../store/_features/media-library/media-library.actions';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, lastValueFrom, skipWhile } from 'rxjs';
import {
  MediaLibraryCategoryEnum,
  MediaLibraryFileModel,
  MediaLibraryTypeModel,
  MediaLibraryUploadType,
  UserQuotaModel,
} from 'src/app/models/sales-funnel';
import { ApiService } from 'src/app/shared/services';
import { UploadingFileProgressItem } from './media-library-upload-form/media-library-upload-form.component';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { InOutAnimation, listAnimation } from 'src/app/shared/animations/animations';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-media-library-modal',
  templateUrl: './media-library-modal.component.html',
  styleUrls: ['./media-library-modal.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(200, style({ transform: 'translateX(-150px)', opacity: 0 })),
      ]),
      transition('void => *', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    InOutAnimation,
    listAnimation
  ],
})
export class MediaLibraryModalComponent implements OnInit, OnDestroy {
  loaded: boolean = false;

  mediaStorage: MediaLibraryFileModel[][] = [];
  mediaLibraryFiles: MediaLibraryFileModel[] = [];
  mediaLibraryTypes: MediaLibraryTypeModel[] = [];
  filterFilesBy: string[] = [];
  mediaLibraryQuota: UserQuotaModel;
  isMediaLibraryQuotaLoaded: Observable<boolean>;

  @Input() uploadCategory: MediaLibraryCategoryEnum;

  // Selected files in the media library
  @Input() selectedFiles: MediaLibraryFileModel[] = [];

  // Temporary selected files in the media library
  @Input() selectedFilesTemp: MediaLibraryFileModel[] = [];

  // Temporary selected file IDs in the media library
  @Input() selectedFilesTempIds: number[] = [];

  // Selected file IDs in the media library
  @Input() selectedFilesIds: number[] = [];

  @Input() multiFiles = true;

  @Input() view = false;

  @Input() imgSpecs: {
    width?: number;
    height?: number;
    ratio?: number;
  } = {
    width: null,
    height: null,
    ratio: null,
  };

  @Input() uploadType: MediaLibraryUploadType | string = MediaLibraryUploadType.Media;

  uploadingFiles: UploadingFileProgressItem[] = [];

  // Pagination settings
  startIndex = 0;
  lastIndex = 13;
  perPage = 4;
  partiallyLoading = false;

  @Output() selectedFilesChange: EventEmitter<MediaLibraryFileModel[]> =
    new EventEmitter<MediaLibraryFileModel[]>();
  @Output() selectedFilesIdsChange: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  subscription: Subscription[] = [];

  _isModalOpen: boolean = false;
  @Input('isModalOpen') set isModalOpen(value: boolean) {
    if (!!value) {
      this._isModalOpen = value;
      this.open();
    } else {
      // this.close()
    }
  }

  @Output() isModalOpenChange = new EventEmitter<boolean>();

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  modalRef: NgbModalRef;

  constructor(
    public modalService: NgbModal,
    private api: ApiService,
    private store: Store
  ) {
    this.isMediaLibraryQuotaLoaded = this.store.select(isMediaLibraryQuotaLoaded);
    this.subscription.push(
      this.store.select(isMediaLibraryLoaded).subscribe((value) => {
        this.loaded = value;
      })
    );

    this.subscription.push(
      this.store.select(selectMediaLibraryFiles).subscribe((files) => {

        this.mediaLibraryFiles = files;
      })
    );

    this.subscription.push(
      this.store.select(selectMediaLibraryQuota).pipe(skipWhile((value) => !value)).subscribe((quota) => {

        this.mediaLibraryQuota = quota;
      })
    );

    this.subscription.push(
      this.store.select(selectMediaLibraryTypes).pipe(skipWhile((value) => !value.length)).subscribe((types) => {

        this.mediaLibraryTypes = types;
        this.filterByType([this.mediaLibraryTypes[0].name]);
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnInit() {}


  get isThereUploading() {
    return !!this.uploadingFiles.length && this.uploadingFiles.findIndex((value) => value.name != null) != -1;
  }

  async onOpenModal() {
    await this.getMediaLibraryTypes();
    await this.getMediaLibraryFiles();
    await this.getMediaLibraryQuota();
    if (this.selectedFiles?.length > 0) {
      this.selectedFilesTemp = this.selectedFiles.slice();
      this.selectedFilesTempIds = this.selectedFiles
        .slice()
        .map((value) => value.id);
    }
  }

  open(content = this.modalContent) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
      windowClass: 'global-modal-class modal-animate-in mymodalcustomclass',
    });

    this.modalRef.result.then(
      (result) => {
        this._isModalOpen = false;
        this.isModalOpenChange.emit(false);
      },
      (reason) => {
        this._isModalOpen = false;
        this.isModalOpenChange.emit(false);
      }
    );
  }

  async getMediaLibraryFiles() {
    // this.loaded = false;
    this.store.dispatch(
      MediaLibraryActions.load({
        category: this.uploadCategory,
        uploadType: this.uploadType,
      })
    );

    // const media$ = this.api.getAllMedia(this.uploadCategory);
    // const media: MediaLibraryFileModel[] = await lastValueFrom(media$);

    // this.mediaLibraryFiles = media.slice().reverse();
    // this.loaded = true;
  }

  async getMediaLibraryQuota() {
    // this.loaded = false;
    this.store.dispatch(
      MediaLibraryQuotaActions.load()
    );

    // const media$ = this.api.getAllMedia(this.uploadCategory);
    // const media: MediaLibraryFileModel[] = await lastValueFrom(media$);

    // this.mediaLibraryFiles = media.slice().reverse();
    // this.loaded = true;
  }

  async getMediaLibraryTypes() {
    this.store.dispatch(MediaLibraryTypesActions.load({uploadType: this.uploadType}))
    // const typeToActions = {
    //   any: {
    //     getMediaTypes: true,
    //   },
    //   content: {
    //     getMediaTypes: true,
    //   },
    //   media: {
    //     mediaLibraryTypes: [
    //       {
    //         name: 'image',
    //         display_name: 'Image',
    //         color_hex: '#FFFFFF',
    //         icon: 'smdnfmn',
    //       },
    //       {
    //         name: 'video',
    //         display_name: 'Video',
    //         color_hex: '',
    //         icon: '',
    //       },
    //     ],
    //     disableFilterByType: true,
    //   },
    //   image: {
    //     mediaLibraryTypes: [
    //       {
    //         name: 'image',
    //         display_name: 'Image',
    //         color_hex: '#FFFFFF',
    //         icon: 'smdnfmn',
    //       },
    //     ],
    //     filterByType: ['image'],
    //   },
    //   video: {
    //     mediaLibraryTypes: [
    //       {
    //         name: 'video',
    //         display_name: 'Video',
    //         color_hex: '',
    //         icon: '',
    //       },
    //     ],
    //     filterByType: ['video'],
    //   },
    // };

    // const actions = typeToActions[this.uploadType];
    // if (!actions) {
    //   throw new Error(`Invalid upload type: ${this.uploadType}`);
    //   // or set a default action
    //   // actions = typeToActions['any'];
    // }

    // if (actions.getMediaTypes) {
    //   const types$ = this.api.getMediaTypes();
    //   const types: MediaLibraryTypeModel[] = await lastValueFrom(types$);
    //   this.mediaLibraryTypes = types.slice().reverse();
    // } else {
    //   this.mediaLibraryTypes = actions.mediaLibraryTypes;
    // }

    // if (actions.disableFilterByType) {
    //   this.disableFilterByType();
    // } else if (actions.filterByType) {
    //   this.filterByType(actions.filterByType);
    // }
  }

  onUploadDone(uploadedFiles: MediaLibraryFileModel[]) {
    // this.mediaLibraryFiles.unshift(...uploadedFiles);
    this.store.dispatch(addNewMediaLibraryFiles({files: uploadedFiles}))

    if (this.multiFiles) {
      uploadedFiles.forEach(this.toggleSelectFile.bind(this));
    } else {
      this.toggleSelectFile(uploadedFiles[0]);
    }
  }


  filterByType(type: string[]) {
    this.filterFilesBy = [...type];
  }

  disableFilterByType() {
    if (this.uploadType === 'content' || this.uploadType === 'any') {
      this.filterFilesBy = [];
    }

    if (this.uploadType === 'media') {
      this.filterFilesBy = ['image', 'video'];
    }
  }

  toggleSelectFile(file: MediaLibraryFileModel) {
    if (!!this.multiFiles) {
      this.toggleFileMultiFiles(file);
    } else {
      this.toggleFileSingleFile(file);
    }
  }

  deselectFiles(files: MediaLibraryFileModel[]) {
    for (let i = 0; i < files.length; i++) {
      if (this.selectedFilesTempIds.includes(files[i].id)) {
        for (let i = 0; i < this.selectedFilesTempIds.length; i++) {
          if (this.selectedFilesTempIds[i] === files[i]?.id) {
            this.selectedFilesTemp.splice(i, 1);
            this.selectedFilesTempIds.splice(i, 1);
            break;
          }
        }
      }
    }

  }

  toggleFileSingleFile(file: MediaLibraryFileModel) {
    if (this.selectedFilesTemp.includes(file)) {
      this.selectedFilesTemp.pop();
      this.selectedFilesTempIds.pop();
    } else {
      this.selectedFilesTemp.pop();
      this.selectedFilesTempIds.pop();
      this.selectedFilesTemp.push(file);
      this.selectedFilesTempIds.push(file.id);
    }
  }

  toggleFileMultiFiles(file: MediaLibraryFileModel) {
    if (this.selectedFilesTempIds.includes(file.id)) {
      for (let i = 0; i < this.selectedFilesTempIds.length; i++) {
        if (this.selectedFilesTempIds[i] === file?.id) {
          this.selectedFilesTemp.splice(i, 1);
          this.selectedFilesTempIds.splice(i, 1);
          break;
        }
      }
    } else {
      this.selectedFilesTemp.push(file);
      this.selectedFilesTempIds.push(file.id);
    }
  }

  doneSelectFiles() {
    this.selectedFiles = this.selectedFilesTemp.slice();
    this.selectedFilesIds = this.selectedFiles.slice().map((value) => value.id);

    this.emitAllFiles();

    this.close();
  }

  onUnselectFile(itemId: number) {
    this.selectedFiles.forEach((file, index) => {
      if (this.selectedFiles.length < 1) {
        this.selectedFiles = [];
        this.selectedFilesIds = [];
        this.resetMediaLibrary();
      } else {
        if (file.id == itemId) {
          this.selectedFiles.splice(index, 1);
          this.selectedFilesIds.splice(index, 1);
        }
      }
    });
    this.selectedFilesIds = this.selectedFiles.slice().map((value) => value.id);
    this.emitAllFiles();
  }

  emitAllFiles() {
    this.selectedFilesChange.emit(this.selectedFiles);
    this.selectedFilesIdsChange.emit(this.selectedFilesIds);
  }

  close() {
    this.modalRef.close();
    this.resetMediaLibrary();
  }

  resetMediaLibrary() {
    this.selectedFilesTemp = [].slice();
    this.selectedFilesTempIds = [].slice();
  }

  onScroll(): void {
    if (this.lastIndex < this.mediaLibraryFiles.length) {
      this.partiallyLoading = true;
      setTimeout(() => {
        this.lastIndex = this.lastIndex + this.perPage;
        this.partiallyLoading = false;
      }, 1200);
    }
  }
}
