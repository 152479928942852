import { Action, createReducer, on } from '@ngrx/store';
import { WebsiteModel } from '../../../../components/user-dashboard/portal/portal.model';

import { NotificationElement } from '../../../../models/owner-dashboard';


import { forgetSession } from '../user/auth/auth.actions';
import { CurrentUserSubscriptionActions, SystemPlansActions } from './subscription.actions';
import { Plan } from 'src/app/models/plans';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';
import { SystemProcess } from 'src/app/models/global-classes';

export interface SubscriptionState {
  currentUserSubscriptionInfo: SubscriptionBillingInfo,
  currentUserSubscriptionInfoProcess: SystemProcess;
  systemPlans: Plan[],
  loaded: boolean;
  error: any;
}

const initialState: SubscriptionState = {
  currentUserSubscriptionInfo: {
    planData: null,
    subscriptionData: null
  },
  currentUserSubscriptionInfoProcess: { status: 'none', error: null },
  systemPlans: [],
  loaded: false,
  error: null,
};

export const subscriptionReducer = createReducer(
  initialState,

  // System Plans
  on(SystemPlansActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(SystemPlansActions.loadSuccess, (state, { plans }) => {
    return     {
      ...state,
      systemPlans: plans,
      loaded: true,
    }
  }),

  on(SystemPlansActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),


  // Current User Subscription Info
  on(CurrentUserSubscriptionActions.load, (state) => ({ ...state, currentUserSubscriptionInfoProcess: { status: 'loading', error: null } })),
  on(CurrentUserSubscriptionActions.loadSuccess, (state, { currentUserSubscriptionInfo }) => {
    return     {
      ...state,
      currentUserSubscriptionInfo,
      currentUserSubscriptionInfoProcess: { status: 'done', error: null },
    }
  }),

  on(CurrentUserSubscriptionActions.loadFailure, (state, { error }) => ({
    ...state,
    currentUserSubscriptionInfoProcess: { status: 'loading', error: error }
  })),


  on(forgetSession, (state) => initialState)
);
