import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { User } from 'src/app/models';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../shared/services';
import { ProfileUiControlService } from '../../../shared/services/profile-ui-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalControlService } from '../../../shared/services/portal-control.service';
import { SharedOfferService } from '../../../components/user-dashboard/offer/shared-offer.service';
import { OfferStausEnum } from 'src/app/components/user-dashboard/offer/models/offer';
import { colorSet } from 'src/app/models/global-classes';

@Component({
  selector: 'app-full-screen-dynamic-header',
  templateUrl: './full-screen-dynamic-header.component.html',
  styleUrls: ['./full-screen-dynamic-header.component.css'],
})
export class FullScreenDynamicHeaderComponent implements OnInit {
  offerStausEnum = OfferStausEnum
  @Input() backButtonLabel = 'Offer Edit';
  @Output() backButton = new EventEmitter();
  @Output() primaryButton = new EventEmitter();
  @Output() secondaryButton = new EventEmitter();

  @Input() headerCustomClasses: string;
  @Input() logoType: 'icon' | 'logo' = 'icon';
  @Input() logoColor: 'normal' | 'white' = 'white';
  @Input() showBackButton = true;
  @Input('bgColor') set setBgColor (value: colorSet) {
    this._bgColor = value;
    if (value.includes('#')) {
      this._bgHex = value;
    }
  }

  _bgColor: colorSet = 'dark';
  _bgHex: string;

  constructor(public sharedOfferService: SharedOfferService,) {}

  ngOnInit() {}

  async onPrimaryButton() {
    this.primaryButton.emit();
  }

  async onSecondaryButton() {
    this.secondaryButton.emit();
  }

  async onBackButton() {
    this.backButton.emit();
  }
  
}
