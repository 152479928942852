import { FunnelItem } from './funnels';
export class FormSubscribersListData {
  availableFunnels?: FunnelItem[];
  requested?: FunnelItem;
  subscribers: FormSubscriber[];
}

export class FormSubscriber {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  extraData: FormExtraData[];
  status: FormSubscriberStatusEnum;
  funnel: FunnelItem;
  createdAt: number;
  updatedAt: number;
}

export class FormExtraData {
  key: string;
  value: string;
}

export enum FormSubscriberStatusEnum {
  unconfirmed = 'unconfirmed',
  subscribed = 'subscribed',
  unsubscribed = 'unsubscribed',
  removed = 'removed',
  suspended = 'suspended',
}
