// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-item {
  transition: all 0.3s ease-in-out;
    display: block;
    color: #0000007c;
    text-decoration: none;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    /* font-weight: bold; */
    padding: 20px 25px;
    margin: 0;
    border-bottom: 1px #E8E8E8 solid;

}

* {transition: all 0.5s ease-in-out;}

.disable-button {
  pointer-events: none;
}

.sticky {
  position: sticky;
  top: 0;
  align-self: flex-start;
  background-color: white;
  z-index: 1;
}


.page-tabs li {
  font-weight:bold ;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/funnel/edit/edit-funnel/funnel-page-edit/funnel-page-edit.component.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;IAC9B,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;IACf,yCAAyC;IACzC,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,gCAAgC;;AAEpC;;AAEA,GAAG,gCAAgC,CAAC;;AAEpC;EACE,oBAAoB;AACtB;;AAEA;EAEE,gBAAgB;EAChB,MAAM;EACN,sBAAsB;EACtB,uBAAuB;EACvB,UAAU;AACZ;;;AAGA;EACE,iBAAiB;AACnB","sourcesContent":[".back-item {\n  transition: all 0.3s ease-in-out;\n    display: block;\n    color: #0000007c;\n    text-decoration: none;\n    font-size: 16px;\n    font-family: Helvetica, Arial, sans-serif;\n    /* font-weight: bold; */\n    padding: 20px 25px;\n    margin: 0;\n    border-bottom: 1px #E8E8E8 solid;\n\n}\n\n* {transition: all 0.5s ease-in-out;}\n\n.disable-button {\n  pointer-events: none;\n}\n\n.sticky {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  align-self: flex-start;\n  background-color: white;\n  z-index: 1;\n}\n\n\n.page-tabs li {\n  font-weight:bold ;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
