import { AnimatedLoaderType } from './../../shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgressSpinnerService } from '../../shared/services/progress-spinner.service';

@Component({
  selector: 'app-progress-spinner-dialog',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.css'],
})
export class ProgressSpinnerDialogComponent implements OnInit, OnDestroy {
  animatedLoaderType = AnimatedLoaderType
  message = 'Loading';
  messageSubscription: Subscription;
  constructor(public spinService: ProgressSpinnerService) {}
  ngOnDestroy(): void {
    // this.spinService.messageListner.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  ngOnInit() {
    this.messageSubscription = this.spinService.messageListner.subscribe(
      (a) => {
        this.message = a;
      }
    );
  }
}
