
import { SharedDomainService } from './../../domains/shared-domain.service';
import {
  SetPortalConfigResponse,
  WebsiteModel,
  WebsiteTemplateModel,
  SetWebsiteIdentityRequestBody,
  WebsiteIdentity,
  WebsiteSenderMailConfiguration,
  SetWebsiteBrandingRequestBody,
} from './../portal.model';
import { FormSubscribersListData } from './../../../../models/funnels-subscribers';
import { FunnelItem } from './../../../../models/funnels';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { HttpErrorResponse } from '@angular/common/http';

import { SharedSfxService } from './../../../../shared/services/shared-sfx.service';
import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  BTCPayInfoModel,
  PaymentGatewayModel,
  PaymentGatewaysInformation,
  Payment_Gateway_Enum,
  PaypalInfoModel,
  PageItem,
  PortalSenderEmailInput,
} from 'src/app/models/portal';
import { UserWebsite } from '../portal.model';
import { ApiService } from '../../../../shared/services/lsg.api.service';
import { lastValueFrom, map } from 'rxjs';
import Swal from 'sweetalert2';
import {
  DeleteResponse,
  OfferDeleteResponse,
} from 'src/app/models/sales-funnel';
import { ToastrService } from 'ngx-toastr';

import { DomainItem } from 'src/app/models/domain';
import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';
import { Store } from '@ngrx/store';
import {
  websiteActions,
  updateWebsiteIdentity,
  updateWebsiteSenderEmailConfig,
} from 'src/app/shared/store/_features/website/website.actions';
import { selectWebsite } from 'src/app/shared/store/_features/website/website.selector';


export enum PortalViewPageStatusEnum {
  overview = 'overview',
  pages = 'pages',
  funnels = 'funnels',
  offers = 'offers',
  affiliate_links = 'affiliate_links',
  payment = 'payment',
  domains = 'domains',
  marketing_overview = 'marketing_overview',
  marketing_subscribers = 'marketing_subscribers',
  none = 'none',
}

@Injectable({ providedIn: 'root' })
export class SharedPortalViewService {
  websitePageNavigations: TopLevelNavMenuItem[] = [];

  portalDomainsTimeouts = [];
  userDomains: DomainItem[];

  currentWebsite: {
    id: number;
    website: WebsiteModel;
    pages?: PageItem[];

  } = {
      id: null,
      pages: [],
      website: null,
    };

  mainTemplate: {
    template: WebsiteTemplateModel;
    index: number;
  };
  // currentWebsiteId: number;

  // Being Canceled
  pageStatus: PortalViewPageStatusEnum;
  paymentPageStatus: 'list' | 'view' | 'integrate';

  currentPaymentGateway: PaymentGatewayModel;


  processing: 'loading' | 'done' | 'none' | 'error' = 'none';

  loadedUserDomains: boolean = false;

  constructor(
    private router: Router,
    private api: ApiService,
    public toastr: ToastrService,
    public sharedSfxService: SharedSfxService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public sharedDomainService: SharedDomainService,
    private store: Store
  ) {
    this.revokeErrorHandler = this.revokeErrorHandler.bind(this);

    // this.store.select(selectWebsitePaymentGateways).subscribe((value) => {


    // });

    // if (!this.currentWebsite?.website?.isProcessing) {
    //   this.getPaymentMethods();
    // }


    this.store.select(selectWebsite).subscribe((value) => {
      this.currentWebsite.website = value;
      this.currentWebsite.id = value?.id;
    });

  }




  // Begin: New Functions 2023
  async getCurrentWebsite() {
    this.store.dispatch(websiteActions.load());
  }

  callScreenShot = async (
    url: string,
    fullPage: boolean = false
  ): Promise<string> => {
    const screenshot$ = this.api.screenshot(
      'https://' + url,
      fullPage,
      this.handleScreenshotError
    );
    const screenshot = await lastValueFrom(screenshot$);

    return screenshot?.img;
  };

  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing(by: 'done' | 'error' = 'done') {
    this.processing = by;
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  setPageStatus(status: PortalViewPageStatusEnum) {
    this.pageStatus = status;
  }
  // startLoading() {
  //   this.internalLoaded = false;
  // }

  // stopLoading() {
  //   this.internalLoaded = true;
  // }

  public async getUserDomains() {
    this.loadedUserDomains = false;
    const userDomains$ = this.api.getDomainsDetails();
    const userDomains = await lastValueFrom(userDomains$);

    this.userDomains = userDomains;
    this.loadedUserDomains = true;
  }

  public async getUserDomainsExcludePortal(
    websiteId: any = this.currentWebsite.id
  ) {
    // this.loading.portals = true;
    const userDomains$ = this.api.getDomainsDetails(websiteId);
    const userDomains = await lastValueFrom(userDomains$);

    return userDomains;
    // this.loading.portals = false;
  }

  // async setPortalIdentity(
  //   identity: SetPortalIdentityRequestBody,
  //   portalId = this.currentWebsite.id
  // ) {
  //   let identityRequest$ = this.api.setPortalIdentity(portalId, identity);
  //   const identityRequest: SetPortalConfigResponse = await lastValueFrom(
  //     identityRequest$
  //   );

  //   if (!!identityRequest.success) {
  //     this.currentWebsite.portalIdentity =
  //       identityRequest.portal.portalIdentity;
  //     this.currentWebsite.label = identityRequest.portal.portalName;
  //     this.toastr.success('Saved Successfully', 'Done!');
  //   }
  // }

  async linkPortalWithCustomDomain(
    website: WebsiteModel = this.currentWebsite.website,
    domain: DomainItem,
    isDefault: boolean = false
  ) {
    let pastPortalInfo = domain.portalInfo;
    let pastIsDefault = domain.isDefault;
    let linkDomainRequest$ = this.api.linkDomainToPortal(
      domain.id,
      website.id,
      isDefault,
      this.handleLinkPortalWithCustomDomainError
    );
    let linkDomainRequest: { success: boolean; domain: DomainItem } =
      await lastValueFrom(linkDomainRequest$);

    if (!!linkDomainRequest.success) {
      if (!!linkDomainRequest.domain.process.isProcessing) {
        await this.sharedDomainService.handleDomainProcessWithRefLoop(
          linkDomainRequest.domain
        );
      }
      this.toastr.success('Your Portal is Processing. Please check', 'Done!');
      website.domains.customdomains.push(linkDomainRequest.domain);
    }
  }

  handleLinkPortalWithCustomDomainError = (error: HttpErrorResponse) => {
    this.toastr.error(error.message);
  };

  async changePortalSubdomain(
    domain: string,
    websiteId = this.currentWebsite.id
  ) {
    let subdomainChangeRequest$ = this.api.changePortalSubdomain(
      websiteId,
      domain
    );
    const subdomainChangeRequest: SetPortalConfigResponse = await lastValueFrom(
      subdomainChangeRequest$
    );

    if (!!subdomainChangeRequest.success) {
      // this.setcurrentWebsite(subdomainChangeRequest.portal);
      // this.currentWebsite = subdomainChangeRequest.portal;
      this.toastr.success('Changed Successfully', 'Done!');
    }
  }

  async getcurrentWebsiteScreenshot() {
    const screenshot = await this.callScreenShot(
      this.currentWebsite.website.siteUrl,
      true
    );
    this.currentWebsite.website.screenshot = screenshot;
  }

  private handleScreenshotError = (error: HttpErrorResponse) => {
    const msg = 'Something went wrong in portal service.';
    // return an observable with a user friendly message
    return error.message || msg;
  };

  async setPortalSendingEmail(data: PortalSenderEmailInput) {
    const request$ = this.api.setPortalSendingEmail(data);
    const response: WebsiteSenderMailConfiguration = await lastValueFrom(
      request$
    );
    this.store.dispatch(
      updateWebsiteSenderEmailConfig({ senderEmailConfig: response })
    );
    this.toastr.success('Done!', 'All is set');
  }

  async setWebsiteIdentity(data: SetWebsiteIdentityRequestBody | SetWebsiteBrandingRequestBody) {
    this.startProcessing();
    const request = this.api.setWebsitedentity(data, this.setWebsiteIdentityErrorHandler);
    const response: WebsiteIdentity = await lastValueFrom(request);
    this.store.dispatch(updateWebsiteIdentity({ identity: response }));
    // this.currentWebsite.website.identity = response;
    this.endProcessing('done');
    return response;


  }

  setWebsiteIdentityErrorHandler = (error: HttpErrorResponse) => {
    this.toastr.error(error.message);
    this.endProcessing('error');
  }



  goToCreateFunnelAndSetPortal(portalId: number = this.currentWebsite.id) {
    this.sharedOwnerDashboardService.setBackRoute(
      this.currentWebsite.website.label
    );
    this.router.navigate(['dashboard', 'funnel', 'create', portalId]);
  }

  goToPages() {
    this.router.navigate([
      'dashboard',
      'portal',
      'view',
      this.currentWebsite.id,
      'pages',
    ]);
  }

  goToOverview() {
    this.router.navigate([
      'dashboard',
      'portal',
      'view',
      this.currentWebsite.id,
    ]);
  }

  goToPortals() {
    this.router.navigate(['dashboard', 'portal']);
  }






  setupWalletToStore() {
    // let btcpay = this.paymentGateways.find(a=> a.slug == Payment_Gateway_Enum.btcpay)

    // // return false;
    // // this.btcPayWizardStatus = BtcPayWizardStatusEnum.waiting;
    // window.open(
    //   `${btcpay.btcPayUrl}/stores/${btcpay.storeId}/onchain/BTC`,
    //   '_blank'
    // );
  }


  async revokePaymentGatewayAlert(gateway: PaymentGatewayModel) {
    let offersList = '';
    const beforeDeleteRequest$ = this.api.revokePayment(
      gateway.id,
      false,
      this.revokeErrorHandler
    );
    const beforeDelete: DeleteResponse = await lastValueFrom(
      beforeDeleteRequest$
    );

    beforeDelete.offers?.forEach((offerItem) => {
      offersList =
        offersList +
        '<li class="py-1">' +
        offerItem.title +
        `<span class="h5 ps-2"><span
      class="badge badge-primary badge-lg"
      style="vertical-align: bottom"
      >${offerItem.effect}</span></span>` +
        '</li>';
    });

    Swal.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      buttonsStyling: false,
      icon: 'warning',
      width: '650px',
      padding: '20px 70px',
      customClass: {
        title: 'd-block',
        container: 'text-start px-0',
        confirmButton: 'btn btn-lg btn-danger m-1',
        cancelButton: 'btn btn-lg btn-success m-1',
      },
      inputPlaceholder:
        'I confirm that I want to <strong class="text-danger">Revoke</strong> this payment gateway.',
      title:
        'Are you sure you want to revoke <strong>' +
        gateway.name +
        '</strong> payment gateway?',
      html:
        beforeDelete.offers?.length > 0
          ? 'By <strong>revoking</strong> this <strong>Gateway</strong> from this portal, it will be <strong>affect</strong> the following <strong>Offers:</strong>' +
          `<br>
            <ul class="text-danger" style="max-height: 20vh;overflow: auto;">
      ${offersList}
      </ul>
      `
          : '',
      confirmButtonText: 'Revoke',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const request$ = this.api.revokePayment(
          gateway.id,
          true,
          this.revokeErrorHandler
        );
        const response = await lastValueFrom(request$);

        this.toastr.success('Done!', `${gateway.name} Revoked Successfully`, {
          closeButton: false,
          positionClass: 'toast-bottom-right',
        });
        return response;
        // TODO: Delete Request
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (!!res.value?.success) {
        // this.reloadPortalOffers();
        // this.getPaymentMethods();
        // this.goToPayments();
      }
    });
    const deleteCheck = Swal.getInput();
    const confirmCheck = Swal.getConfirmButton();
    const cancelButton = Swal.getCancelButton();
    Swal.getIcon().className = 'swal2-icon swal2-error swal2-icon-show';
    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Deleting, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

  revokeErrorHandler() {
    Swal.fire({
      icon: 'error',
      customClass: {
        confirmButton: 'bg-primary',
        cancelButton: 'bg-danger',
      },
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      showCancelButton: true,
      text: 'Error',
      title: 'Something went worng',
    });
  }

  unlinkDomainFromPortal(
    domain: DomainItem,
    currentWebsite: WebsiteModel = this.currentWebsite.website
  ) {
    const SwalDelete = Swal.mixin({
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger m-1',
        cancelButton: 'btn btn-lg btn-success m-1',
      },
      buttonsStyling: false,
    });

    SwalDelete.fire({
      allowOutsideClick: false,
      input: 'checkbox',
      inputPlaceholder: `I confirm that I want to Unlink.`,
      title: 'You’re about to Unlink this domain from your portal',
      html: `
        <span style='font-size:1.2em'>Are you sure you want to Unlink</span><br>
        <strong>${domain.info.name}.${domain.info.tld}</strong><br>from<br> <strong>${currentWebsite.label}</strong>
      `,
      icon: 'warning',
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      heightAuto: false,
      preConfirm: async () => {
        const unlinkResponse$ = this.api.unlinkDomainToPortal(
          domain.id,
          currentWebsite.id
        );
        const unlinkResponse: { success: boolean; domain: DomainItem } =
          await lastValueFrom(unlinkResponse$);
        if (!!unlinkResponse.success) {
          const domainIndex = currentWebsite.domains.customdomains.findIndex(
            (a) => a.id == domain.id
          );
          currentWebsite.domains.customdomains.splice(domainIndex, 1);
        }
        return unlinkResponse;
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (result) => {
        return !result && 'You need to agree';
      },
    }).then((res) => {
      if (res.value) {
      } else {
        return false;
      }
    });
    const deleteCheck = SwalDelete.getInput();
    const confirmCheck = SwalDelete.getConfirmButton();
    const cancelButton = SwalDelete.getCancelButton();
    confirmCheck.setAttribute('disabled', 'disabled');
    confirmCheck.setAttribute('style', 'pointer-events: none;');
    SwalDelete.getIcon().className = 'swal2-icon swal2-error swal2-icon-show';
    deleteCheck.addEventListener('change', function () {
      if (this.checked) {
        confirmCheck.removeAttribute('disabled');
        confirmCheck.removeAttribute('style');
      } else {
        confirmCheck.setAttribute('disabled', 'disabled');
        confirmCheck.setAttribute('style', 'pointer-events: none;');
      }
    });
    confirmCheck.addEventListener('click', function () {
      confirmCheck.innerHTML = 'Deleting, Please Wait...';
      cancelButton.setAttribute('disabled', 'disabled');
      confirmCheck.setAttribute('disabled', 'disabled');
      deleteCheck.setAttribute('disabled', 'disabled');
    });
  }

}
