import { UtilitiesService } from './../../../../../../shared/services/utilities.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-get-funnel-link',
  templateUrl: './get-funnel-link.component.html',
  styleUrls: ['./get-funnel-link.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class GetFunnelLinkComponent implements OnInit {
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() funnelUrl: string;

  copying = false;
  // offerUrl: string = '';

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.dismissAll();
  }

  async afterOpenModal() {
    // const urlReuqest$ = this.sharedOfferService.getOfferUrl();
    // const urlRequest = await lastValueFrom(urlReuqest$);
    // this.offerUrl = this.sharedOfferService.getOfferUrl();
  }

  async onOpenModal() {}

  copyText() {
    this.copying = true;
    this.utilitiesService.copyToClipboard(this.funnelUrl);

    setTimeout(() => {
      this.copying = false;
      this.toastr.success('Done!', 'URL copied to clipboard', {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      });
    }, 600);
  }

  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: true,
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
