import { selectContacts } from './../../../shared/store/_features/contacts/contacts.selector';
import { EventEmitter, Injectable, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
 import { ContactItem } from './models/contacts';
import { ContactsActions } from 'src/app/shared/store/_features/contacts/contacts.actions';

@Injectable({
  providedIn: 'root',
})
export class SharedContactService {


  currentContact: ContactItem;
  contacts: ContactItem[] = [];


  constructor(
    private route: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private store: Store
  ) {
      this.store.select(selectContacts).subscribe((contacts)=> {
        this.contacts = contacts;

      })
  }

  async getContacts() {
    console.log("Getting Contacts Service")
    this.store.dispatch(ContactsActions.load());
    // this.loaded = false;
    // const offers$ = this.api.getOffers();
    // let offers: { offers: OfferItem[] } = await lastValueFrom(offers$);
    // this.offers = offers.offers;
    // this.loaded = true;
    // return offers;
  }

}

