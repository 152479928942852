import { loginMethodsUIBase } from './data/login-methods';
import { User } from './../../../../models/user';
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth/auth.actions';
import * as RegisterActions from './register/register.actions';
import * as ProfileActions from './profile/profile.actions';
import * as PasswordForgotActions from './password-forgot/password-forgot.actions';

import { Subscription } from 'rxjs';
import { Models } from 'appwrite';
import { SystemProcess, SystemProcessLoadingStatus } from 'src/app/models/global-classes';
import { LoginMethodUIItem } from 'src/app/components/profile/security-settings/login-methods/login-methods.component';

export interface UserState {
  // User Profiles
  learmoProfile: User | undefined;
  appwriteProfile: Models.User<Models.Preferences> | undefined;

  // Processes
  profileUpdateProcess: SystemProcess;
  emailUpdateProcess: SystemProcess;
  passwordUpdateProcess: SystemProcess;
  enable2FaUpdateProcess: SystemProcess;
  loginMethodUpdateProcess: SystemProcess;
  passwordRecoveryProcess: SystemProcess;

  // Authentication
  token: string | undefined;
  subscription: Subscription | undefined;
  isRegistering: boolean;
  isEmailConfirmed: boolean | undefined;
  isEmailChanged: boolean;
  isRegistrationWebhookDone: boolean;
  loginMethods: LoginMethodUIItem[]
  error: any;

  tokenTimeout: number;
  isTokenRefreshScheduled: boolean;
}

export const initialState: UserState = {
  // User Profiles
  learmoProfile: undefined,
  appwriteProfile: undefined,

  // Processes
  profileUpdateProcess: new SystemProcess(),
  emailUpdateProcess: new SystemProcess(),
  passwordUpdateProcess: new SystemProcess(),
  enable2FaUpdateProcess: new SystemProcess(),
  loginMethodUpdateProcess: new SystemProcess(),
  passwordRecoveryProcess: new SystemProcess(),

  // Authentication
  token: undefined,
  subscription: undefined,
  isRegistering: false,
  isEmailConfirmed: undefined,
  isEmailChanged: false,
  isRegistrationWebhookDone: false,
  loginMethods: [],
  error: undefined,
  tokenTimeout: undefined,
  isTokenRefreshScheduled: false

};

// User-related actions
export const userReducer = createReducer(
  initialState,

  // Authentication and Profile updates
  on(
    AuthActions.setCurrentUserLearmoProfile,
    ProfileActions.userProfileActions.updateSuccess,
    (state, { learmoProfile }) => {
      let avatar = prepareAvatarValue(learmoProfile);
      const updatedProfile = {
        ...learmoProfile,
        avatar,
      };

      return {
        ...state,
        learmoProfile: updatedProfile,
      };
    }
  ),

  // Appwrite Profile update
  on(AuthActions.setCurrentUserAppwriteProfile, (state, { appwriteProfile }) => {
    return {
      ...state,
      appwriteProfile: appwriteProfile,
    };
  }),

  // Registration actions
  on(RegisterActions.createNewAccount, (state, {}) => ({
    ...state,
    isRegistering: true,
  })),
  on(RegisterActions.emailVerificationRequestActions.sendSuccess, (state, {}) => ({
    ...state,
    isRegistering: false,
  })),

  // Authentication actions
  on(AuthActions.setUserToken, (state, { token }) => ({
    ...state,
    token,
  })),
  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(AuthActions.onWebHookDone, (state, {}) => ({
    ...state,
    isRegistrationWebhookDone: true,
  })),

  on(AuthActions.refreshUserToken, (state, {}) => ({
    ...state,
    isTokenRefreshScheduled: false,
    tokenTimeout: undefined
  })),

  on(AuthActions.lockTokenRefreshSchedule, (state, {tokenTimeout}) => ({
    ...state,
    isTokenRefreshScheduled: true,
    tokenTimeout: tokenTimeout
  })),

  on(AuthActions.unlockTokenRefreshSchedule, (state, {}) => ({
    ...state,
    isTokenRefreshScheduled: false,
    tokenTimeout: undefined
  })),

  // Profile update actions
  on(ProfileActions.userProfileActions.update, (state, { payload }) => {
    return {
      ...state,
      profileUpdateProcess: { status: 'loading', error: undefined },
    };
  }),
  on(ProfileActions.userProfileActions.updateSuccess, (state, { learmoProfile }) => {
    let avatar = prepareAvatarValue(learmoProfile);
    return {
      ...state,
      learmoProfile: { ...state.learmoProfile, ...learmoProfile, avatar },
      profileUpdateProcess: { status: 'done', error: undefined },
    };
  }),
  on(ProfileActions.userProfileActions.updateFailure, (state, { error }) => ({
    ...state,
    profileUpdateProcess: { status: 'error', error: error.message },
  })),

  // 2FA related actions
  on(ProfileActions.enable2FAActions.enable, (state, {}) => {
    return {
      ...state,
      enable2FaUpdateProcess: { status: 'loading', error: undefined },
    };
  }),
  on(ProfileActions.enable2FAActions.enableSuccess, (state, {}) => {
    return {
      ...state,
      learmoProfile: { ...state.learmoProfile, twofa: 1 },
      enable2FaUpdateProcess: { status: 'done', error: undefined },
    };
  }),
  on(ProfileActions.enable2FAActions.enableFailure, (state, { error }) => ({
    ...state,
    enable2FaUpdateProcess: { status: 'error', error: error.message },
  })),


    // Login Methods related actions
    on(ProfileActions.userLoginMethodConfigActions.get, (state, {}) => {
      return {
        ...state,
        loginMethodUpdateProcess: { status: 'loading', error: undefined },
      };
    }),
    on(ProfileActions.userLoginMethodConfigActions.getSuccess, (state, {loginMethodsResults}) => {
      let loginMethodsUI = loginMethodsUIBase;

      loginMethodsResults.forEach((loginMethod) => {
        let methodBase = loginMethodsUI.find(m => m.enum == loginMethod.method);
        if (methodBase) {
          methodBase.enabled = loginMethod.enabled;
          methodBase.reason = loginMethod?.reason;
        }
      });

      return {
        ...state,
        loginMethods: loginMethodsUI,
      };
    }),
    on(ProfileActions.userLoginMethodConfigActions.getFailure, (state, { error }) => ({
      ...state,
      loginMethodUpdateProcess: { status: 'error', error: error.message },
    })),


    on(ProfileActions.changeLoginMethodConfigActions.change, (state, {}) => {
      return {
        ...state,
        loginMethodUpdateProcess: { status: 'loading', error: undefined },
      };
    }),
    on(ProfileActions.changeLoginMethodConfigActions.changeSuccess, (state, {method, enabled}) => {
      let loginMethodsClone: LoginMethodUIItem[] = JSON.parse(JSON.stringify(state.loginMethods));
      let targetMethod = loginMethodsClone.find(m => m.enum == method);
      targetMethod.enabled = enabled;
      return {
        ...state,
        loginMethods: loginMethodsClone,
        loginMethodUpdateProcess: { status: 'done', error: undefined },
      };
    }),
    on(ProfileActions.changeLoginMethodConfigActions.changeFailure, (state, { error }) => ({
      ...state,
      loginMethodUpdateProcess: { status: 'error', error: error.message },
    })),

  // Email update actions
  on(ProfileActions.userEmailActions.update, (state, {}) => {
    return {
      ...state,
      emailUpdateProcess: { status: 'loading', error: undefined },
    };
  }),
  on(ProfileActions.userEmailActions.updateSuccess, (state, { response }) => {
    return {
      ...state,
      learmoProfile: { ...state.learmoProfile, ...response },
      isEmailChanged: true,
      emailUpdateProcess: { status: 'done', error: undefined },
    };
  }),
  on(ProfileActions.userEmailActions.updateFailure, (state, { error }) => ({
    ...state,
    emailUpdateProcess: {
      status: 'error',
      error: error.message,
      errorBody: error,
    },
  })),

  // Password recovery actions
  on(PasswordForgotActions.passwordRecoveryRequestActions.create, (state, {}) => {
    return {
      ...state,
      passwordRecoveryProcess: { status: 'loading', error: undefined },
    };
  }),
  on(PasswordForgotActions.passwordRecoveryRequestActions.createSuccess, (state, {}) => {
    return {
      ...state,
      passwordRecoveryProcess: { status: 'done', error: undefined },
    };
  }),
  on(PasswordForgotActions.passwordRecoveryConfirmActions.confirm, (state, {}) => {
    return {
      ...state,
      passwordRecoveryProcess: { status: 'loading', error: undefined },
    };
  }),
  on(PasswordForgotActions.passwordRecoveryConfirmActions.confirmSuccess, (state, {}) => {
    return {
      ...state,
      passwordRecoveryProcess: { status: 'done', error: undefined },
    };
  }),
  on(PasswordForgotActions.passwordRecoveryConfirmActions.confirmFailure, (state, { error }) => {
    return {
      ...state,
      passwordRecoveryProcess: { status: 'error', error: error.message },
    };
  }),

  // Website onboarding actions
  on(AuthActions.markWebsiteOnboardingAsDoneSuccess, (state, { resp }) => ({
    ...state,
    learmoProfile: !!state.learmoProfile
      ? { ...state.learmoProfile, onBoarding: { websiteSetting: true } }
      : undefined,
  })),

  // Session cleanup action
  on(AuthActions.forgetSession, (state) => initialState)
);

export function prepareAvatarValue(learmoProfile: User) {
  const randomNumber = Math.floor(Math.random() * 1000);

  if (
    !!learmoProfile?.avatar?.small &&
    !!learmoProfile?.avatar?.medium &&
    !!learmoProfile?.avatar?.big
  ) {
    return {
      small: learmoProfile.avatar.small + `?random=${randomNumber}`,
      medium: learmoProfile.avatar.medium + `?random=${randomNumber}`,
      big: learmoProfile.avatar.big + `?random=${randomNumber}`,
      customized: learmoProfile.avatar.customized, // Include customized if it's optional
    };
  } else {
    return {
      small: 'assets/images/users/blank.jpg',
      medium: 'assets/images/users/blank.jpg',
      big: 'assets/images/users/blank.jpg',
      customized: learmoProfile?.avatar?.customized, // Include customized if it's optional
    };
  }
}
