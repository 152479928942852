import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MessengerThread } from '../../../../../models/owner-dashboard';

@Component({
  selector: 'app-messenger-chat-label',
  templateUrl: './messenger-chat-label.component.html',
  styleUrls: ['./messenger-chat-label.component.css'],
})
export class MessengerChatLabelComponent implements OnInit {
  @Input() thread: MessengerThread;
  @Input() disableTooltip: boolean = false;
  tooltipLimit: number = 5;
  tooltipList: string = '';
  constructor() {}

  ngOnInit(): void {
    this.thread.users.forEach((user, index) => {
      if (index < this.tooltipLimit) {
        this.tooltipList =
          this.tooltipList + `${user.firstName + ' ' + user?.lastName} \n`;
      } else if (index == this.tooltipLimit) {
        if (this.thread.users.length - this.tooltipLimit == 1) {
          this.tooltipList =
            this.tooltipList +
            `+${this.thread.users.length - this.tooltipLimit} other`;
        } else {
          this.tooltipList =
            this.tooltipList +
            `+${this.thread.users.length - this.tooltipLimit} others`;
        }
      }
    });
  }
}
