import { Component, Input, OnInit } from '@angular/core';
import { UiContactType } from '../create.component';

@Component({
  selector: 'ui-contact-type-view',
  templateUrl: './contact-type-view.component.html',
  styleUrls: ['./contact-type-view.component.css']
})
export class ContactTypeViewComponent implements OnInit {
  @Input() type: UiContactType;
  constructor() { }

  ngOnInit() {
  }

}
