export class AiGeneratorComponentData {
  selectedCategories: AiCourseCategory[];
  results: {
    value: string;
    writing?: 'title' | 'category' | null;
    category: string;
    skipCharacter?: number;
    processing: boolean;
  }[];
  selectedResultIndex: number;
  currentIdeationStep: CurrentIdeationStepEnum;
  writingOwn: boolean;
  localGeneratorData:LocalDataObject
}

export class LocalDataObject {
  entering: 'title' | 'category';
  // lastChar: string | null;
  index: number | null;
  resultsSync: number = 0;
  generating: boolean = false;
  lastChar: string = '';
}
export class AiCourseCategory {
  id?: number;
  label: string;
  slug?: string;
  selected?: boolean;
}

export enum CurrentIdeationStepEnum {
  category = 'category',
  custom_category = 'custom_category',
  thinking = 'thinking',
  results = 'results',
}
