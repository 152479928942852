import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learmo-target',
  templateUrl: './learmo-target.component.html',
  styleUrls: ['./learmo-target.component.css']
})
export class LearmoTargetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
