import { MediaLibraryFileModel } from "src/app/models/sales-funnel";
import { ContactItemType } from "../../models/contacts";

export class CreateContactDto {
    name: string;
    description?: string;
    email?: string;
    avatar?: MediaLibraryFileModel[];
    avatar_id?: number[];
    type: ContactItemType

    constructor(data: {
    name: string,
    description: string,
    email: string,
    avatar?: MediaLibraryFileModel[],
    avatar_id: number[],
    type: ContactItemType
    } = {
      name : '',
      description : '',
      email : '',
      avatar_id : [],
      type : 'customer'
    }
    ) {
      Object.assign(this, data)
    }
  }