// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-floating > .form-control:focus ~ label {
    /* color: var(--bs-primary) !important; */
}
.form-floating > .form-control:not(:placeholder-shown) ~ label{
    /* color: var(--bs-dark) !important; */
}


/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {
    color: var(--bs-danger) !important;
} */

/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{
    color: var(--bs-primary) !important;
} */`, "",{"version":3,"sources":["webpack://./src/app/components/wait-list/wait-list-form/wait-list-form.component.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;AACA;IACI,sCAAsC;AAC1C;;;AAGA;;GAEG;;AAEH;;GAEG","sourcesContent":[".form-floating > .form-control:focus ~ label {\n    /* color: var(--bs-primary) !important; */\n}\n.form-floating > .form-control:not(:placeholder-shown) ~ label{\n    /* color: var(--bs-dark) !important; */\n}\n\n\n/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {\n    color: var(--bs-danger) !important;\n} */\n\n/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{\n    color: var(--bs-primary) !important;\n} */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
