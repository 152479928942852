import { Component } from '@angular/core';
import { AnimatedLoaderType } from 'src/app/shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';

@Component({
  selector: 'app-application-loading-component',
  templateUrl: './application-loading-component.component.html',
  styleUrls: ['./application-loading-component.component.css']
})
export class ApplicationLoadingComponentComponent {
  animatedLoaderType = AnimatedLoaderType;

}
