import { Pipe, PipeTransform } from '@angular/core';
import { MediaLibraryFileModel } from '../../models/sales-funnel';
@Pipe({
  name: 'mediafilter',
})
export class MediaLibraryFilterPipe implements PipeTransform {
  transform(items: MediaLibraryFileModel[], typeName: string[]): any[] {
    if (!items) {return []}
    if (!typeName || !typeName?.length) {return items}

    return items.filter((item) => typeName.includes(item.type.name));
  }
}
