import { ProgressSpinnerService } from '../../../../../shared/services/progress-spinner.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { SharedPortalViewService } from '../../../portal/view/shared-portal-view.service';
import { SharedFunnelService } from '../../shared-funnel.service';
import { CreateWebsiteOutput } from '../../../portal/dto/portal.output';
import { CreateWebsiteInput } from '../../../portal/dto/portal.input';
import { FunnelItem } from '../../../../../models/funnels';
import { SharedPortalService } from '../../../portal/shared-portal.service';
import {
  UserWebsites,
  DomainCheckResultType,
} from '../../../portal/portal.model';
import {
  StepVisibilityEnum,
  WizardModel,
  WizardTypeEnum,
} from '../../../../../models/wizard';

import { PortalSocketService } from '../../../../../shared/services/portal-socket.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';
import { ApiService } from '../../../../../shared/services/lsg.api.service';

import { ProfileUiControlService } from '../../../../../shared/services/profile-ui-control.service';

import Swal from 'sweetalert2';

import { HttpErrorResponse } from '@angular/common/http';
import { PortalControlService } from '../../../../../shared/services/portal-control.service';
import { NavCanDeactivate } from 'src/app/shared/guards/nav-can-deactivate';
import { UserDashboardService } from '../../../../../shared/services/user-dashboard.service';

import { WizardStepStatusEnum } from 'src/app/models/wizard';
import { slider } from 'src/app/shared/animations/animations';
import { lastValueFrom, Subscription } from 'rxjs';
import { PortalActiveChoiceEnum } from 'src/app/shared/wizard/select-portal/select-portal.component';
import { CreateFunnelInput } from 'src/app/models/funnels';
import { Title } from '@angular/platform-browser';

export enum FunnelFlowIndicatorEnum {
  home = 'home',
  portal = 'portal',
}
@Component({
  selector: 'app-funnel-create-wizard',
  templateUrl: './funnel-create-wizard.component.html',
  styleUrls: [
    './funnel-create-wizard.component.css',
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    slider,
  ],
})
export class FunnelCreateWizardComponent
  extends NavCanDeactivate
  implements OnInit, OnDestroy
{
  isAnimationWorking = false;
  animationState: any;
  isActionsFixed = false;

  wizardStepStatusEnum = WizardStepStatusEnum;
  stepVisibilityEnum = StepVisibilityEnum;

  funnelFlowIndicator: FunnelFlowIndicatorEnum = FunnelFlowIndicatorEnum.home;
  // Booleans
  domainAgreement = false;
  startLoading = false;
  changePaymentMethod = false;
  selectedService = 1;
  addNewCard = false;
  loaded = false;
  isPortalTab = true;
  isSiteNameChecked = false;
  isCreatingWebsite = false;
  divUpdater: any;
  isEditingEmail = false;
  public isInputEmailValid: boolean;
  counter = 0;

  setFunnelPortalTypeSubscription: Subscription;
  // Values
  // public siteName: string;
  // public domainTld: string = 'myinfluencerportal.com';
  // public changingSiteName = '';
  // public portalName = '';
  // public email = '';
  // private domainUrl: string;
  // selectedPaymentMethod: string;
  // descArr: any;
  // public domainType: DomainType = DomainType.Free;

  // Models

  userWebsites: UserWebsites;
  public domainCheckResultType: DomainCheckResultType;
  currentUser: User;

  constructor(
    private authenticationService: AuthenticationService,
    private api: ApiService,
    public portalControlService: PortalControlService,
    public userDashboardService: UserDashboardService,
    // private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,

    public profileUiService: ProfileUiControlService,
    public socketService: PortalSocketService,
    public sharedPortalService: SharedPortalService,
    public sharedPortalViewService: SharedPortalViewService,
    public sharedFunnelService: SharedFunnelService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public loadingService: ProgressSpinnerService,
    // private title: Title
  ) {
    super(portalControlService);

    this.callScreenShot = this.callScreenShot.bind(this);
    this.handleGetTemplatesError = this.handleGetTemplatesError.bind(this);
    this.handleCreateWebsiteError = this.handleCreateWebsiteError.bind(this);
  }

  ngOnDestroy(): void {
    this.resetWizard();
    
    this.setFunnelPortalTypeSubscription?.unsubscribe();
  }




  async ngOnInit(): Promise<void> {
    this.currentUser = this.authenticationService.currentUserValue;

    // await this.sharedPortalService.getUserPortals();
    this.initializeNewPortalFunnelCreate();

    this.sharedOwnerDashboardService.clearActions();
    this.sharedOwnerDashboardService.clearBreadcrumbs();
    this.sharedOwnerDashboardService.setBreadcrumbs({base: 'funnel', lastNode: 'Create Funnel'});
    this.sharedOwnerDashboardService.setTitle({main: 'Create Funnel', sub: 'Unlock the Power of Funnels: Your Blueprint for Nurturing Audience Interest into Actionable Results.'});
    // this.title.setTitle('Create Funnel');
  }

  // Animation Factory

  prepareRouteTransition(outlet: RouterOutlet): void {
    if (this.animationState !== outlet.activatedRouteData.animation) {
      this.isAnimationWorking = true;
      setTimeout(() => {
        this.isAnimationWorking = false;
      }, 900);

      this.animationState = outlet.activatedRouteData.animation;
    }



    return outlet.activatedRouteData.animation;
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }




  initializeNewPortalFunnelCreate() {
    this.sharedPortalService.wizardModel = new WizardModel(
      [
        {
          id: 1,
          title: 'Select a Funnel',
          description: 'Construct your Funnel',
          status: WizardStepStatusEnum.done,
          isValid: true,
          canNavigateByClick: true,
          enumTag: 'funnel',
          hideButtons: {
            next: false,
            back: false,
          },
          innerContent: {
            title: 'Select a Template',
            description : 'A sales funnel is a step-by-step process that turns potential customers into paying clients by guiding them from initial interest to final purchase.',
          },
          stickButtons: true,
          hideSteps: true,
          visibility: StepVisibilityEnum.visible,
          navigationAction: this.goToSelectFunnel,
          validate: this.selectFunnelValidator,
        },
        {
          id: 4,
          title: 'Final Step',
          description: 'Finalize your portal',
          status: WizardStepStatusEnum.none,
          isValid: true,
          hideButtons: {
            next: true,
            back: true,
          },
          visibility: StepVisibilityEnum.visible,
          navigationAction: this.goToFinalStep,
        },
      ],
      0,
      WizardTypeEnum.funnel,
      this.backAction,
      this.createExecution
    );

    this.loaded = true;
  }

  goToSelectFunnel = () => {
    this.router.navigate(['dashboard', 'funnel', 'create', 'template'], {skipLocationChange: true, });
  };

  // goToSelectPortal = () => {
  //   this.router.navigate(['dashboard', 'funnel', 'create', 'funnel'], {skipLocationChange: true, });
  // };

  backAction = () => {
    this.router.navigate(['dashboard']);
  };

  // goToSelectTemplate = () => {
  //   this.router.navigate([
  //     'dashboard',
  //     'funnel',
  //     'create',
  //     !!this.sharedPortalService.wizardData.portalIdTemp
  //       ? this.sharedPortalService.wizardData.portalIdTemp
  //       : '',
  //     'step2',
  //   ]);
  // };

  goToFinalStep = () => {
    this.router.navigate(['dashboard', 'funnel', 'create', 'step3'], {skipLocationChange: true, });
  };

  selectFunnelValidator = () => {
    return !!this.sharedPortalService.wizardData?.funnelId;
  };

  selectPortalValidator = () => {
    return !!this.sharedPortalService.wizardSettings?.portalType;
  };

  selectTemplateValidator = () => {
    return !!this.sharedPortalService.wizardData?.templateId;
  };

  createExecution = async () => {
    if (
      this.sharedPortalService.wizardSettings.portalType ==
      PortalActiveChoiceEnum.new
    ) {
      await this.createFunnelWithNewPortal();
    } else if (PortalActiveChoiceEnum.existing) {
      await this.createFunnelWithExistingPortal();
    }
    // await this.onCreatePortal(this.sharedPortalService.createPortalObject);
    // this.startAnimationAndRedirect(()=> {
    //   this.resetWizard();
    //   this.router.navigate(['dashboard', 'portal']);
    //   this.sharedPortalService.getCountOfSubscriptionPermissionsPortalsAndDomains();
    //   // setTimeout(async () => {
    //   //   this.socketService.scrollTo('createObj' + siteID);
    //   // }, 2500);
    // })
    
  };

  setBackRouteIfNotSet(portalId: number): void {
    const currentRoute = this.sharedOwnerDashboardService.backSettings;
    if (
      currentRoute?.route === '/dashboard' ||
      currentRoute?.route === '/dashboard/portal'
    ) {
      this.sharedOwnerDashboardService.setBackRoute(
        'Back',
        `dashboard/portal/view/${portalId}/content/funnels`
      );
    }
  }
  createFunnelWithExistingPortal = async () => {
    this.loadingService.showLoader('Processing');

    const funnelData = this.sharedPortalService.wizardData;
    this.setBackRouteIfNotSet(funnelData.portalId);
    const funnelCreate$ = this.api.createFunnel(
      new CreateFunnelInput(
        +funnelData.portalId,
        funnelData.funnelId,
        `Funnel ${Math.floor(Math.random() * 9999)}`
      ),
      this.handleCreateFunnelError
    );
    const funnelCreate: FunnelItem = await lastValueFrom(funnelCreate$);

    this.sharedOwnerDashboardService.goBack();
    this.loadingService.hideLoader();
    // this.sharedPortalService.getCountOfSubscriptionPermissionsPortalsAndDomains();
    // setTimeout(async () => {
    //   this.socketService.scrollTo('createObj' + siteID);
    // }, 2500);
  };

  createFunnelWithNewPortal = async () => {};

  startAnimationAndRedirect(callback: () => void | Promise<void>): void {
    this.sharedPortalService.countForFinishing.count = 5;
    this.sharedPortalService.countForFinishing.counter = setInterval(() => {
      this.sharedPortalService.countForFinishing.count--;
      if (this.sharedPortalService.countForFinishing.count <= 0) {
        callback();
      }
    }, 1000);
  }

  public resetWizard(): void {
    if (this.sharedPortalService.countForFinishing.counter) {
      clearInterval(this.sharedPortalService.countForFinishing.counter);
    }
    this.sharedPortalService.resetWizard();
  }

  private handleGetTemplatesError(error: any): void {}

  async onCreatePortal(createPortalObject: CreateWebsiteInput): Promise<void> {
    const portalCreate$ = this.api.createWebsite(
      createPortalObject,
      this.handleCreateWebsiteError
    );
    const portalCreate: CreateWebsiteOutput = await lastValueFrom(portalCreate$);
    this.createdSitePendingAlert(portalCreate.domain, portalCreate.siteId);
  }

  // onCreateWebsite() {
  //   this.api
  //     .createWebsite(
  //       this.sharedPortalService.createPortalObject,
  //       this.handleCreateWebsiteError
  //     )
  //     .subscribe((res: CreateWebsiteOutput) => {
  //       if (res.status === 0 || res.status === 1) {
  //         this.createdSitePendingAlert(res.domain, res.siteId);
  //       }
  //     });
  // }

  async callScreenShot(url: string, fullPage: boolean): Promise<string> {
    const img = await this.api
      .screenshot('https://' + url, fullPage, this.handleScreenshotError)
      .toPromise();
    return img.img;
  }

  private handleScreenshotError(error: HttpErrorResponse) {
    const msg = 'Something went wrong in portal service.';
    // return an observable with a user friendly message
    return error.message || msg;
  }

  async createdSitePendingAlert(domainUrl: string, siteID: any) {
    this.portalControlService.canNavigateAll = true;
    // await this.socketService.startRedirect(siteID);
  }

  handleCreateFunnelError = (error: any) => {
    Swal.fire({
      title: 'Error',
      text: 'Something went wrong, Please retry again later!',
      icon: 'error',
      confirmButtonText: 'OK',
    }).then((res) => {
      this.portalControlService.canNavigateAll = true;
      this.router.navigate(['dashboard', 'portal']);
      return false;
    });
  };

  handleCreateWebsiteError(error: any) {
    Swal.fire({
      title: 'Error',
      text: 'Something went wrong, Please retry again later!',
      icon: 'error',
      confirmButtonText: 'OK',
    }).then((res) => {
      this.portalControlService.canNavigateAll = true;
      this.router.navigate(['dashboard', 'portal']);
      return false;
    });
  }

  exitAlert() {
    if (!!this.portalControlService.canNavigateAll) {
      this.resetWizard();
      this.router.navigate(['dashboard', 'portal']);
    } else {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.portalControlService.canNavigateAll = true;
        this.resetWizard();
        this.router.navigate(['dashboard', 'portal']);
      } else {
        return false;
      }
    }
  }
}
