export class HelpCategory {
  id?: number;
  displayName: string;
  description?: string;
  type: HelpCategoryTypeEnum;
  items: HelpItem[];
}

export class HelpItem {
  id?: number;
  displayName: string;
  enum: HelpActionsEnum;
  type: HelpItemTypeEnum;
  icon?: string;
  action?: () => void | Promise<void>;
}
export enum HelpActionsEnum {
  help_center = 'help_center',
}

export enum HelpItemTypeEnum {
  default = 'default',
  soon = 'soon',
}

export enum HelpCategoryTypeEnum {
  default = 'default',
  new = 'new',
  hidden = 'hidden',
}
