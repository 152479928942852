import { DomainCheckData, DomainItem } from './../../../models/domain';
import {
  FormSubscriber,
  FormSubscribersListData,
} from './../../../models/funnels-subscribers';
import { AffiliateLinkMinimizedModel } from './../../../models/owner-dashboard';
import {
  MediaImageInfo,
  MediaLibraryFileModel,
  MediaVideoInfo,
} from './../../../models/sales-funnel';
import {
  NamecheapCreateDomainOutput,
  NamecheapErrorResult,
  PortalTemplateModel,
  TemplateTypeModel,
} from './dto/portal.output';
import { timestampToDate } from '../../../shared/services/date-converter';
import { PageItem, WebsiteUpdate } from 'src/app/models/portal';
import { MediaLibraryTypeModel } from '../../../models/sales-funnel';
import { OfferItem } from '../offer/models/offer';
export enum StepType {
  none,
  complete,
  current,
}
export enum TemplatesMode {
  normal,
  add,
  edit,
  remove,
}
export enum PortalContentEnum {
  Dashboard,
  Website,
  Create,
}

export enum PortalWarningEnum {
  none,
  one_day_warning,
  grace_warning,
}

export enum DomainType {
  Free,
  Paid,
}

export enum MailServiceStatus {
  not_configured,
  configured,
  pending,
  activated,
}

export enum DomainCheckResultType {
  Free_Available,
  Free_Unavailable,
  Paid_Available_Owner,
  Paid_Available_Not_Owner,
  Paid_Unavailable,
}

export class StepViewModel {
  public stepsType: Array<StepType>;
  private isBackFromFinalStep: boolean;

  constructor(
    public stepsSelectedList: Array<boolean>,
    public currentStep: number
  ) {
    this.stepsType = stepsSelectedList.map((a, b) => {
      if (currentStep < b) {
        return StepType.none;
      } else if (currentStep === b) {
        return StepType.current;
      } else {
        return StepType.complete;
      }
    });
  }
  public changeStep(index: number, isBackFromFinalStep: boolean) {
    if (this.isBackFromFinalStep && index > this.currentStep) {
      this.stepsSelectedList[this.currentStep] = false;
      this.setStepType(3);
      this.currentStep = 3;
      this.stepsSelectedList[this.currentStep] = true;
      this.setStepType(3);
    } else {
      this.stepsSelectedList[this.currentStep] = false;
      this.setStepType(index);
      this.currentStep = index;
      this.stepsSelectedList[this.currentStep] = true;
      this.setStepType(index);
    }
    this.isBackFromFinalStep = isBackFromFinalStep;
  }
  private setStepType(index: number) {
    if (index > this.currentStep) {
      this.stepsType[this.currentStep] = StepType.complete;
    } else if (index === this.currentStep) {
      this.stepsType[this.currentStep] = StepType.current;
    } else {
      this.stepsType[this.currentStep] = StepType.none;
    }
  }
}
// Funnels
export class PortalFunnels {
  public categories: FunnelCategory[];
}

export class FunnelCategory {
  public label: string;
  public funnels: FunnelTemplateItem[];
}

export class FunnelTemplateItem {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public pagesCount: number,
    public mainImage: TemplateMediaItem,
    public media: TemplateMediaItem[],
    public sequence?: FunnelSequenceStep[],
    public portal?: { id: number; name: string },
    public modified?: number
  ) {}
}

export class FunnelSequenceStep {
  public title: string;
  public type: Funnel_Sequence_Enum;
}
export enum Funnel_Sequence_Enum {
  page = 'page',
  email = 'email',
  optin = 'optin',
  salesPage = 'salesPage',
  offer = 'offer',
}

//Login Page
export class LoginMediaItem {
  public title: string;
  public content: string;
  public image: string;
  public type: ThemeMediaTypeEnum;
  public mainColor?: string;
}

// Templates
export class PortalTemplates {
  public categories: PortalCategory[];
}

export class PortalCategory {
  public label: string;
  public count: number;
  public templates: TemplateItem[];
}

export class TemplateItem {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public pages: TemplatePageItem[],
    public mainImage: TemplateMediaItem,
    public media: TemplateMediaItem[]
  ) {}
}

export class TemplatePageItem {
  title: string;
  image: string;
  previewUrl: string;
}

export class TemplateMediaItem {
  public url: string;
  public type: ThemeMediaTypeEnum;
  public thumbnail: string;
}

export enum ThemeMediaTypeEnum {
  video = 'video',
  image = 'image',
}

export class domainWizardModel {
  public siteName: string = '';
  public changingSiteName: string = '';
  public domainType: DomainType = DomainType.Free;
  public isSiteNameChecked: boolean = false;
  public domainTld: string = '';
  public domainAgreement: boolean = false;
  public isCreatingDomain: boolean = false;
  public isInputEmailValid: boolean;
  public addNewCard: boolean;
  // constructor() {}
  public changeDomainType(
    value: 'com' | 'net' | 'org' | 'myinfluencerportal.com'
  ) {
    switch (value) {
      case 'com':
      case 'net':
      case 'org':
        this.domainType = DomainType.Paid;
        break;
      case 'myinfluencerportal.com':
        this.domainType = DomainType.Free;
        break;
      default:
        break;
    }
  }

  public getDomain() {
    return this.siteName + '.' + this.domainTld;
  }

  public isFreeDomain() {
    if (this.domainTld === 'myinfluencerportal.com') {
      return 'free';
    } else {
      return 'paid';
    }
  }
}

export enum PortalBuilderEnum {
  elementor = 'elementor',
  brizy = 'brizy',
}
export class UserWebsites {
  public portalName?: string = '';
  public changingSiteName?: string = '';
  public domainType?: DomainType = DomainType.Free;
  public isSiteNameChecked?: boolean = false;
  public domainTld?: string = '';
  public domains?: WebsiteDomains;
  public builder?: PortalBuilderEnum;
  public choosedWebsite?: number;
  public email?: string;
  public siteName?: string;
  public websites: UserWebsite[];
  public isEditingEmail?: boolean;
  public isInputEmailValid?: boolean;
  public isCreatingWebsite?: boolean;
  public template?: PortalTemplateModel;
  constructor() {}
  // public changeDomainType(
  //   value: 'com' | 'net' | 'org' | 'myinfluencerportal.com'
  // ) {
  //   switch (value) {
  //     case 'com':
  //     case 'net':
  //     case 'org':
  //       this.domainType = DomainType.Paid;
  //       break;
  //     case 'myinfluencerportal.com':
  //       this.domainType = DomainType.Free;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // public getDomain() {
  //   return this.siteName + '.' + this.domainTld;
  // }
  // public isFreeDomain() {
  //   if (this.domainTld === 'myinfluencerportal.com') {
  //     return 'free';
  //   } else {
  //     return 'paid';
  //   }
  // }
}

// BEGIN: NEW WEBSITE MODEL
// Define a new class called WebsiteModel
export class WebsiteModel {
  id: number; // Unique identifier for the website
  label: string; // User-friendly label for the website
  identity: WebsiteIdentity; // Object containing information about the website's identity (e.g. title, description, favicon)
  siteUrl: string;
  domains: WebsiteDomains; // Object containing information about the website's domain(s)
  demoCredentials: WebsiteCredentials; // Object containing the website's credentials (e.g. username, token)
  websiteCredentials: WebsiteCredentials; // Object containing the website's credentials (e.g. username, token)
  senderMailConfig: WebsiteSenderMailConfiguration; // Object containing configuration for the website's sender email (e.g. from name, from email address)
  defaultTemplateId: number; // The ID of the website's default template
  templates: WebsiteTemplateModel[]; // Array of objects containing information about the website's templates
  createdAt: number; // Timestamp indicating when the website was created
  updatedAt: number; // Timestamp indicating when the website was last updated
  screenshot: string;

  process: WebsiteProcessModel;
}

export class WebsiteProcessModel {
    isProcessing: boolean //Represents if the website is still being processing
    type: WebsiteProcessTypeEnum;
    percentage: number; 
    step: string;
}

export enum WebsiteProcessTypeEnum {
  'creating' = 'creating',
}
// Define a class for the website's sender email configuration
export class WebsiteSenderMailConfiguration {
  fromName?: string; // The name to display as the sender of emails (optional)
  fromEmail: {
    username: string; // The username portion of the sender email address
    domain: string; // The domain portion of the sender email address
  };
  replyEmail?: string; // The email address to use for replies (optional)
}

// Define a class for the website's domain(s)
export class WebsiteDomains {
  subdomain: DomainItem; // Object containing information about the website's subdomain
  customdomains: DomainItem[]; // Array of objects containing information about the website's custom domains
}

// Define a class for the website's credentials
export class WebsiteCredentials {
  public username: string; // The username associated with the website's credentials
  public token: string; // The token associated with the website's credentials
}



// Define a class for website templates
export class WebsiteTemplateModel {
  id: number; // Unique identifier for the template
  label: string; // User-friendly label for the template
  status: WebsiteTemplateStatusEnum; // The status of the template (e.g. draft, published)
  pages: PageItem[];
  url: string;
  screenshot: string;
}



// Define an enum for website template statuses
export enum WebsiteTemplateStatusEnum {
  draft = 'draft',
  published = 'published',
}

// END: NEW WEBSITE MODEL

export class UserWebsite {
  public siteId: number;
  public portalName: string;

  public username: string;
  public token: string;

  public screenshot: string;
  public domains: WebsiteDomains;

  public offers?: OfferItem[];
  public affiliateLinks?: AffiliateLinkMinimizedModel[];

  public portalIdentity?: WebsiteIdentity;

  public pages?: PageItem[];

  public siteUrl: string;

  public builder: PortalBuilderEnum;
  public subscribers?: FormSubscribersListData;
  public isProcessing?: { status: boolean; message: string };
  funnelsCount: number;
  public date?: string = '';
  public createdAt: number;
  public process?: WebsiteUpdate = undefined;

  public isFree: boolean;
  public isLinkedWithEmailService: boolean;

  public isVerified: boolean;
  public template: PortalTemplateModel;

  public subscribersCount?: number;

  public senderMailConfig?: WebsiteSenderMailConfiguration;
  constructor() {} // screenshotCallback: (a: string, b: boolean) => Promise<string>

  async init?(
    siteId: number,
    portalName: string,
    url: string,
    createdAt: number,
    username: string,
    screenshotCallback: (a: string, b: boolean) => Promise<string>
  ) {
    this.siteId = siteId;
    this.portalName = portalName;
    this.createdAt = createdAt;
    this.date = timestampToDate(createdAt);
    this.username = username;
    this.screenshot = await screenshotCallback(this.siteUrl, true);
  }
}

// Define a class for the website's identity
export class WebsiteIdentity {
  title: string;
  description: string;
  favIcon: MediaLibraryFileModel[];
  favIcon_id: number[];
  // image: MediaLibraryFileModel[];
  // image_id: number[];
  logo: MediaLibraryFileModel[];
  logo_id: number[];
  label: string;
}

export class SetWebsiteIdentityRequestBody {
  title: string;
  description: string;
  label?: string;
}

export class SetWebsiteBrandingRequestBody {
  favIcon_id?: number[];
  logo_id?: number[];
  learmo_primary_color?: string;
}

export class SetPortalConfigResponse {
  success: boolean;
  portal: UserWebsite; // same as portal/user/user-websites/:pid
}
