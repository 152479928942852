import { WebsiteModel } from './../../../../portal.model';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
} from '../../../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../../../shared/services/lsg.api.service';
import { AffiliatesInvitationStatusEnum } from '../../../../../../../models/owner-dashboard';
import { emailRegex } from '../../../../../../../shared/services/regex';
import { PageItem } from 'src/app/models/portal';
import { UserWebsite } from '../../../../portal.model';
import Swal from 'sweetalert2';
import { SharedPortalViewService } from '../../../shared-portal-view.service';
import { Store } from '@ngrx/store';
import { addNewWebsitePage } from 'src/app/shared/store/_features/website/website.actions';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}
@Component({
  selector: 'app-create-page-popup',
  templateUrl: './create-page-popup.component.html',
  styleUrls: ['./create-page-popup.component.css'],
})
export class CreatePagePopupComponent implements OnInit {
  createPageForm: UntypedFormGroup;

  processing: 'none' | 'loading' = 'none';
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  @Input() website: WebsiteModel
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public sharedPortalViewService: SharedPortalViewService,
    private store: Store
  ) {}

  async ngOnInit() {
    this.buildForm();
  }

  startProcessing() {
    this.processing = 'loading';
  }

  stopProcessing() {
    this.processing = 'none';
  }

  async createNewPage() {
    this.createPageForm.markAllAsTouched();
    const newPageName = this.createPageForm.controls.name.value;
    if (this.createPageForm.valid) {
      this.startProcessing();

      const newPage$ = this.api.createNewPage(
        newPageName,
        this.addNewPageErrorHandle
      );
      const newPage: PageItem = await lastValueFrom(newPage$);
      // this.sharedPortalViewService.currentWebsite.pages.unshift(newPage);
      this.store.dispatch(addNewWebsitePage({ page: newPage }));
      this.onCreateDone();
      // this.sharedPortalViewService.currentWebsite.pages[0].screenshot =
        // await this.callScreenShot(newPage.link.toString(), false, false);
    }
  }

  async callScreenShot(url: string, fullPage: boolean, https: boolean = true) {
    if (!!https) {
      url = 'https://' + url;
    }
    const img$ = this.api.screenshot(url, fullPage);
    const img = await lastValueFrom(img$);
    return img.img;
  }

  addNewPageErrorHandle = (error: any) => {
    this.toastr.error(error?.message);
    this.closeModal();
  }

  onCreateDone() {
    this.closeModal();

    this.toastr.success('Done!', 'Page Created Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  resetModal() {
    this.stopProcessing();
    this.createPageForm.reset();
  }
  closeModal() {
    this.modalRef.dismiss();
    this.resetModal();
  }

  buildForm() {
    this.createPageForm = this.fb.group({
      name: ['', Validators.required],
    });
    // this.setDefaultValues();
  }

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
    });
  }

  async onOpenModal() {
    // this.resetForm();
    // this.productObject.type_id = 1;
  }
}
