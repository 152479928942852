const ICONS = {
    category_name: 'Logistics',
    icons_list:[
      "scooter-2",
      "parcel",
      "delivery-time",
      "delivery",
      "delivery-24",
      "ship",
      "courier",
      "logistic",
      "trailer",
      "car-2",
      "car-3",
      "airplane-square",
      "scooter",
      "truck",
      "cube-3",
      "bus",
      "cube-2",
      "delivery-door",
      "delivery-3",
      "delivery-2",
      "car",
      "courier-express",
      "airplane",
      "delivery-geolocation",
      "parcel-tracking"
    ]


};


export default ICONS
