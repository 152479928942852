import { UtilitiesService } from '../../../../../shared/services/utilities.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import { ApiService } from '../../../../../shared/services';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProductItem } from '../../models/product';
import { FormControl, Validators } from '@angular/forms';
import { OfferItem } from '../../../offer/models/offer';
import { Subscription } from 'rxjs';
import { SystemItemTypeEnum, SystemSequenceItem } from 'src/app/shared/partials/system-items-sequence/system-items-sequence.component';

@Component({
  selector: 'app-get-product-link',
  templateUrl: './get-product-link.component.html',
  styleUrls: ['./get-product-link.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class GetProductLinkComponent implements OnInit {
  SystemItemTypeEnum = SystemItemTypeEnum
  sequence: SystemSequenceItem[] = [
   
  ];

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() productGeneratedUrl: {
    value: string,
    description: string,
    shareMessage: string,
    hashtags: string[]
  } = {
    value: null,
    description: "This link directs to <strong>Product Funnel</strong> ends with this offer’s Checkout page",
    shareMessage: "🚀 Exciting news, Introducing:\n{meta[0]}!\n\nLimited-time offer inside! Enroll now! 🎉 \n",
    hashtags: ["LearnMore","SpecialOffer"]
  };
  @Input() checkoutUrl: {
    value: string,
    description: string,
    shareMessage: string,
    hashtags: string[]
  } = {
    value: null,
    description: "This link directs to this offer’s Checkout page",
    shareMessage: "🚀 Exciting news, Introducing:\n{meta[0]}!\n\nLimited-time offer inside! Enroll now! 🎉 \n",
    hashtags: ["LearnMore","SpecialOffer"]
  };

  product: ProductItem;
  @Input('product') set setProduct(product: ProductItem) {
    this.product = product;
    this.productGeneratedUrl.value = product.visitorUrl
  }
  selectedOfferItem: OfferItem;
  copying = false;


  // offerUrl: string = null;

  productSequenceItems: SystemSequenceItem;
  subscriptions: Subscription[] = [];
  
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService,
    private sanitizer: DomSanitizer
  ) {
    
  }

  ngOnInit(): void {
    
    this.productSequenceItems = {
      label: "Product Landing",//this.product.name
      type: SystemItemTypeEnum.product
    };

    this.sequence = [this.productSequenceItems]
  }

  listenToOfferChange(offer: OfferItem) {
    
      this.selectedOfferItem = offer;
      this.checkoutUrl.value = offer.offerLink
      this.sequence = [this.productSequenceItems, {
        label: "Offer Checkout", //offer.title
        type: SystemItemTypeEnum.offer
      }]
      // this.buildShareUrls();
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  async afterOpenModal() {
    // const urlReuqest$ = this.sharedOfferService.getOfferUrl();
    // const urlRequest = await lastValueFrom(urlReuqest$);
    // this.offerUrl = this.sharedOfferService.getOfferUrl();
  }

  async onOpenModal() {}



  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: true,
        centered: true,
        animation: false,
        windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
