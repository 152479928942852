import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import stickybits, { StickyBits } from 'stickybits';

@Directive({
  selector: '[stickybits]',
})
export class StickybitsDirective
  implements AfterContentInit, OnChanges, OnDestroy
{
  private cssClassObserver?: MutationObserver;
  private instance?: StickyBits;
  private isSticky = false;
  private isStuck = false;

  @Input() noStyles: boolean;
  @Input() scrollEl: Element | string;
  @Input() parentClass = 'sticky-parent';
  @Input() stickyChangeClass = 'sticky--change';
  @Input() stickyChangeNumber: number;
  @Input() stickyClass = 'sticky';
  @Input() stickyOffset: number = 0;
  @Input() stuckClass = 'stucky';
  @Input() useFixed: boolean = false;
  @Input() useGetBoundingClientRect: boolean;
  @Input() useStickyClasses = true;
  @Input() verticalPosition: 'top' | 'bottom';
  @Output() sticky = new EventEmitter<boolean>();
  @Output() stuck = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private zone: NgZone
  ) {}

  ngAfterContentInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.instance) {
      this.destroy();
      this.init();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  private init() {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.zone.runOutsideAngular(() => {
      this.instance = this.initStickybits(element);
      this.cssClassObserver = this.initClassObserver(element);
    });

  }

  private initStickybits(element: HTMLElement): StickyBits {
    element.classList.add(this.stuckClass);
    let instance = stickybits(element, {
      customStickyChangeNumber: this.stickyChangeNumber,
      noStyles: this.noStyles ?? false,
      stickyBitStickyOffset: this.stickyOffset,
      scrollEl: this.scrollEl,
      parentClass: this.parentClass,
      stickyClass: this.stickyClass,
      stuckClass: this.stuckClass,
      stickyChangeClass: this.stickyChangeClass,
      useStickyClasses: this.useStickyClasses,
      useFixed: this.useFixed ?? false,
      useGetBoundingClientRect: this.useGetBoundingClientRect ?? false,
      verticalPosition: this.verticalPosition,
      applyStyle: ({ styles, classes }, instance) => {
        console.log(styles);
        let element = instance.el as HTMLElement;
        const stickClasses = this.stickyClass.split(' ');
        const stuckClasses = this.stuckClass.split(' ');
        if (!!classes['sticky--change']) {
          // element.classList.replace(this.stuckClass, this.stickyClass)
          element.classList.remove(...stickClasses)
          element.classList.add(...stuckClasses)
          element.style.position = 'relative';
          element.style.top = undefined;
          
        }
        else {
          // element.classList.replace(this.stickyClass, this.stuckClass)
          element.classList.remove(...stuckClasses)
          element.classList.add(...stickClasses)
          if (this.useFixed) {
            element.style.position = 'fixed';
            element.style.top = `${this.stickyOffset}px`;
          }
          else {
            element.style.position = 'sticky';
            element.style.top = `${this.stickyOffset}px`;
          }
          element.style.zIndex = `5`;
        }
        
      },
    } as StickyBits.Options);
    return instance;
  }

  getTopPosition (el) {
    if (this.elementRef.nativeElement.useGetBoundingClientRect) {
      return el.getBoundingClientRect().top + ((this.instance.props.scrollEl as Window).scrollY || document.documentElement.scrollTop)
    }
    let topPosition = 0
    do {
      topPosition = el.offsetTop + topPosition
    } while ((el = el.offsetParent))
    return topPosition
  }

  private initClassObserver(element: HTMLElement): MutationObserver {
    
    const observer = new MutationObserver((mutations) => {
      mutations
        .filter((mutation) => mutation.oldValue !== element.classList.value)
        .forEach(() => {
          let shouldDetectChanges = false;
          const hasStickyClass = element.classList.contains(this.stickyClass);
          if (!!hasStickyClass && hasStickyClass !== this.isSticky) {
            this.isSticky = hasStickyClass;
            this.sticky.emit(this.isSticky);

            shouldDetectChanges = true;
          }
          else {
            this.isSticky = false;
          }
          const hasStuckClass = element.classList.contains(this.stuckClass);
          if (!!hasStuckClass && hasStuckClass !== this.isStuck) {
            this.isStuck = hasStuckClass;
            this.stuck.emit(this.isStuck);
            
            shouldDetectChanges = true;
          }
          else {
            this.isStuck = false;
          }
          if (shouldDetectChanges) {
            this.changeDetectorRef.detectChanges();
          }
        });
    });
    observer.observe(element, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });
    return observer;
  }

  private destroy() {
    this.instance?.cleanup();
    this.instance = undefined;
    this.cssClassObserver?.disconnect();
    this.cssClassObserver = undefined;
  }
}
