import { WizardModel, StepVisibilityEnum } from './../../../models/wizard';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { slider } from '../../animations/animations';

@Component({
  selector: 'app-steps-ui-view',
  templateUrl: './steps-ui-view.component.html',
  styleUrls: ['./steps-ui-view.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    slider,
  ],
})
export class StepsUiViewComponent implements OnInit {
  stepVisibilityEnum = StepVisibilityEnum;
  @Input() wizardModel: WizardModel;
  @Input() direction: 'vertical' | 'horizontal' = 'horizontal'
  constructor() {}

  ngOnInit(): void {}
}
