import { Component } from "@angular/core";
import { NgxTippyContent } from "ngx-tippy-wrapper";

export enum WizardTypeEnum {
  portal = 'portal',
  funnel = 'funnel',
  domain = 'domain',
  product = 'product'
}

export enum StepVisibilityEnum {
  visible = 'visible',
  hidden = 'hidden',
}

export class WizardModel {
  loading: boolean;
  stepsCount: number;
  currentStep: { index: number; step: WizardStepModel };
  constructor(
    public steps: WizardStepModel[] = [],
    public startSteps: number = 0,
    public wizrdType: WizardTypeEnum = WizardTypeEnum.portal,
    public backAction:  () => void | Promise<void> = () => {},
    public finalAction: () => void | Promise<void> = () => {}
  ) {
    if (!!steps?.length) {
      this.stepsCount = steps.length;
      this.setCurrentStepByIndex(startSteps);
    }
  }

  startLoading() {
    this.loading = true;
  }

  endLoading() {
    this.loading = false;
  }

  resetWizard() {
    this.currentStep = null;
  }

  navigateByClick(index: number) {
    let currentStepValidation = true;
    let step = this.steps.filter(
      (a) => a.visibility != StepVisibilityEnum.hidden
    )[index];
    let realIndex = this.steps.findIndex((a) => a.id == step.id);

    if (this.currentStep.index < realIndex) {
      currentStepValidation = !!this.currentStep.step.validate();
    }

    if (!!this.steps[realIndex].canNavigateByClick && currentStepValidation) {
      this.navigateToStep(realIndex, true);
    }
  }

  navigateByEnum(enumTag: string) {
    let step = this.steps.find((a) => a.enumTag == enumTag);
    if (!!step) {
      let realIndex = this.steps.findIndex((a) => a.id == step.id);
      this.navigateToStep(realIndex);
    }
  }
  setSteps(steps: WizardStepModel[]) {
    this.steps = steps;
    this.stepsCount = steps.length;
    this.currentStep.step = this.steps[this.currentStep.index]
    
  }

  isThereCurrentStep() {
    return this.currentStep?.index > -1;
  }
  navigateToStep(index: number, filtered: boolean = false) {
    let step: WizardStepModel;
    // let realIndex: number;

    if (this.isThereCurrentStep()) {
      this.markStepAsInactive(this.currentStep.index);
    }

    step = this.steps[index];
    // realIndex = index;

    this.currentStep = { index: index, step: step };
    this.enableStepNavigateByClick(index);
    this.currentStep.step.navigationAction();
    this.markStepAsActive(index);

  }

  setCurrentStepByIndex(index: number) {
    if (!!this.steps[index]) {
      this.navigateToStep(index);
    }
  }

  markCurrentStepAsValid() {
    this.currentStep.step.isValid = true;
  }

  enableStepNavigateByClick(index: number) {
    this.steps[index].canNavigateByClick = true;
  }
  markStepAsDone(index: number) {
    if (!!this.steps[index]) {
      this.steps[index].status = WizardStepStatusEnum.done;
    }
  }

  markStepAsActive(index: number) {

    if (
      this.steps[index].status == WizardStepStatusEnum.done ||
      this.steps[index].status == WizardStepStatusEnum.active_done
    ) {
      this._markStepAsActiveDone(index);
    } else if (this.steps[index].status == WizardStepStatusEnum.none) {
      this._markStepAsActive(index);
    }
    
  }

  markStepAsInactive(index: number) {
    if (this.steps[index].status == WizardStepStatusEnum.active) {
      this._markStepAsInactive(index);
    } else if (this.steps[index].status == WizardStepStatusEnum.active_done) {
      this.markStepAsDone(index);
    }
  }

  async goToNextStep() {
    if (this.currentStep.index + 1 < this.stepsCount) {
      this.stepValidationAction(this.currentStep.index);

      if (!!this.currentStep.step.isValid) {
        this.startLoading()
        this.markStepAsDone(this.currentStep?.index);
        await this.stepSubmissionAction(this.currentStep.index)
        this.setCurrentStepByIndex(this.currentStep?.index + 1);
        this.endLoading()
      } else {
      }
    } else {
      if (!!this.finalAction) {
        this.startLoading();
        await this.finalAction();
        this.endLoading();
        this.markStepAsDone(this.currentStep?.index);
      }
    }
  }

  stepValidationAction(index: number) {
    if (!!this.steps[index]?.validate) {
      this.steps[index].isValid = this.steps[index].validate();
    } else {
      this.steps[index].isValid = true;
    }
  }

  async stepSubmissionAction(index: number) {
    if (!!this.steps[index]?.submissionCustomAction) {
      await this.steps[index].submissionCustomAction();
    }
  }

  goToPreviousStep() {
    if (this.currentStep.index > 0) {
      this.setCurrentStepByIndex(this.currentStep.index - 1);
    } else {
      if (!!this.backAction) {
        this.backAction();
      }
    }
  }

  // Indirect Functions
  _markStepAsActive(index: number) {
    this.steps[index].status = WizardStepStatusEnum.active;
  }

  _markStepAsInactive(index: number) {
    this.steps[index].status = WizardStepStatusEnum.none;
  }

  _markStepAsActiveDone(index: number) {
    this.steps[index].status = WizardStepStatusEnum.active_done;
  }

  stickActionButtons() {
    this.currentStep.step.stickButtons = true;
  }

  unstickActionButtons() {
    this.currentStep.step.stickButtons = false;
  }

}

export class WizardStepModel {
  constructor(
    public id: number,
    public title: string,

    public description: string,

    public status: WizardStepStatusEnum,
    public hideButtons: {
      next: boolean;
      back: boolean;
    },
    public nextButtonLabel?: string,
    public innerContent?: {title: string, description?: string, tooltip?: string | NgxTippyContent},
    public stickButtons?: boolean,
    public enumTag?: string,
    public hideSteps?: boolean,
    public visibility?: StepVisibilityEnum,
    public isValid?: boolean,
    public canNavigateByClick?: boolean,
    public submissionCustomAction?: () => void | Promise<void>,
    public navigationAction?: () => void | Promise<void>,
    public validate?: () => boolean
  ) {}
}

export enum WizardStepStatusEnum {
  none = 'none',
  active = 'active',
  done = 'done',
  active_done = 'active_done',
  dummy = 'dummy',
}
