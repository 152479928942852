import { Offer_Payment_Type_Enum } from '../components/user-dashboard/offer/models/offer';
import { FormSubscriber } from './funnels-subscribers';

export class CreateFunnelInput {
  constructor(
    public portalId: number,
    public funnelId: number,
    public title?: string
  ) {}
}

export class FunnelItem {
  public id: number;
  public title: string;
  public pageCount?: number;
  public status?: OwnerFunnelStatusEnum;
  public sequence?: FunnelSequenceObject[];
  public availablePages?: OwnerFunnelPage[];
  public delete?: boolean;
  public hasOffer?: boolean;
  public isOfferRequired?: boolean;
  public link?: string;
  public subscribers?: FormSubscriber[];
  public subscribersCount?: number;
  public createdAt: number;
  public modified: number; //ToDO: Change to updatedAt
}

export class EditFunnelRequest {
  title: string; //Optional
  status: OwnerFunnelStatusEnum; //Optional
}
export enum FunnelSequenceAutomationTypeEnum { //New
  email = 'email',
}

export enum FunnelSequenceItemTypeEnum {
  page = 'page',
  offer = 'offer',
  offer_placeholder = 'offer_placeholder',
}

export type FunnelSequenceObject = OwnerFunnelPage | OwnerFunnelOffer;
export type FunnelAutomationObject = OwnerFunnelEmail;

export class OwnerFunnelPage {
  id: number;
  wpid?: number;
  slotId?: number;
  title?: string;
  image?: string;
  type?: FunnelSequenceItemTypeEnum;
  link?: string;
  portal?: {
    id: number;
    name: string;
    domain: string;
    username: string;
    token: string;
  };
  newPageId: number; //OPTIONAL
  canHaveAutomations?: boolean;
  automations?: FunnelAutomationObject[];
}

export class OwnerFunnelOffer {
  id: number;
  slotId?: number;
  title?: string;
  type?: FunnelSequenceItemTypeEnum;
  status?: string;
  portal?: { id: number; name: string; domain: string; username: string };
  image?: string;
  payment_type?: Offer_Payment_Type_Enum;
  previewLink?: string;
  isSet: boolean;
}

export class OwnerFunnelEmail {
  id: number;
  slotId: number;
  title: string;
  type: FunnelSequenceAutomationTypeEnum;
  internalTitle: string;
  subject: string;
  content: string;
}

export class EditFunnelPageData {
  funnelId: number;
  pageId: number;
}

export enum OwnerFunnelStatusEnum {
  published = 'active',
  draft = 'suspended',
  deleted = 'deleted',
}
