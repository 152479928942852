import {
  SystemEarningsFilter,
  SalesLogList,
  SalesLogDetailedElement,
} from '../../../models/system-earnings';
import {
  PeriodResultModel,
  AffiliateLinkMinimizedModel,
  PeriodTypeValue,
} from '../../../models/owner-dashboard';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../shared/services';
import { OfferItem } from '../offer/models/offer';
import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';

@Injectable({
  providedIn: 'root',
})
export class SharedEarningsService {
  currentSalesItem: SalesLogDetailedElement;
  periodLimits: PeriodTypeValue = {
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
  };

  earningsPageNavigations: TopLevelNavMenuItem[] = [];
  cardLoading: boolean = false;


  systemEarnings: SalesLogList = {
    logs: [],
  };
  currentSystemEarningsFilter: SystemEarningsFilter = {};

  offers: OfferItem[] = [];
  links: AffiliateLinkMinimizedModel[] = [];

  loaded = false;
  processing: 'loading' | 'done' | 'none' = 'none';
  // loading: {
  //   offers: boolean;
  //   links: boolean;
  // } = {
  //   offers: true,
  //   links: true,
  // };

  constructor(
    private api: ApiService,
    private sharedDashboardService: SharedOwnerDashboardService,
    private router: Router
  ) {}

  generateEarningsNavigationMenu() {
    this.earningsPageNavigations = [
      {
        label: 'Overview',
        url: `overview`,
      },
      { label: 'Sales Log', url: `sales` },
      {
        label: 'Insights',
        url: `insights`,
        subMenu: [
          {
            label: 'Demographic',
            url: `insights/demographic`,
          },
          {
            label: 'Tech',
            url: `insights/tech`,
          },
        ],
      },
    ];
  }

  async getEarningsPeriodLimit() {
    let earningsLimit$ = this.api.getEarningsPeriodLimit();
    let earningsLimit: { period: PeriodTypeValue } = await lastValueFrom(
      earningsLimit$
    );

    this.periodLimits = earningsLimit.period;
    
  }




  async getSystemEarnings(period?: PeriodResultModel) {
    let systemEarnings$ = this.api.getEarningsLog(period);
    let systemEarnings = await lastValueFrom(systemEarnings$);

    this.systemEarnings = systemEarnings;
  }

  goToSalesItem(id: number) {
    this.router.navigate(['dashboard', 'earnings', 'sales','item', id])
  }

  // async getEarningsChartData(filterModel: SystemEarningsFilter = {}) {
  //   Object.keys(filterModel).forEach((key) => {
  //     if (filterModel[key] != undefined) {
  //       this.currentSystemEarningsFilter[key] = filterModel[key];
  //     }
  //   });

  //   if (!filterModel.period) {
  //     this.currentSystemEarningsFilter.period = null;
  //   }

  //   let systemEarnings$ = this.api.getSystemEarnings(
  //     this.currentSystemEarningsFilter
  //   );
  //   let systemEarnings = await lastValueFrom(systemEarnings$);

  //   this.systemEarnings = systemEarnings;
  // }

  // public async getUserOffers() {
  //   this.loading.offers = true;
  //   const res$ = this.api.getOffers();
  //   this.offers = await lastValueFrom(res$);
  //   this.loading.offers = false;
  // }

  // public async getUserAffiliateLinks() {
  //   this.loading.links = true;
  //   const res$ = this.api.getMyAffiliateLinks();
  //   this.links = await lastValueFrom(res$);
  //   this.loading.links = false;
  // }

  startLoading() {
    this.loaded = false;
  }

  stopLoading() {
    this.loaded = true;
  }
  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing() {
    this.processing = 'done';
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }
}
