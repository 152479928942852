import { MinimizedPortalModel } from './../portal/dto/portal.output';
import { ProgressSpinnerService } from './../../../shared/services/progress-spinner.service';
import { PaymentMethodModel } from 'src/app/models/payment.model';
import { PaymentService } from './../../../shared/services/payment.service';
import {
  PaymentServiceInterface,
  PaymentServiceInterfaceTypeEnum,
} from './../../../models/payment.model';
import { WizardModel } from './../../../models/wizard';
import {
  DomainItem,
  DomainWizardData,
  DomainWizardSettings,
} from './../../../models/domain';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../shared/services';
import Swal, { SweetAlertResult } from 'sweetalert2';

import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import {
  CardModeStatusEnum,
  CheckPageViewStatusEnum,
  DomainCheckStatusEnum,
} from './purchase/steps/domain-purchase-summary/domain-purchase-summary.component';

declare var btcpay: any;

@Injectable({
  providedIn: 'root',
})
export class SharedDomainService {
  currentDomain: { id: number; domain: DomainItem } = {
    id: null,
    domain: null,
  };
  currentInvoiceUrl: string;
  userPaymentMethods: PaymentMethodModel[] = [];
  paymentServices: PaymentServiceInterface[];

  public wizardModel: WizardModel;

  wizardSettings: DomainWizardSettings = {
    domainCheckStatus: DomainCheckStatusEnum.none,
    checkPageViewStatus: CheckPageViewStatusEnum.input,
    finalStepLabel: 'Checkout',
  };
  wizardData: DomainWizardData = {
    iAgree: false,
    domainResult: {
      alternatives: [],
      current: null,
    },
    paymentService: null,
    selectedCard: null,
  };

  availableTLD: string[] = ['com', 'org', 'net'];

  domainsList: DomainItem[] = [];
  loaded = false;
  processing: 'loading' | 'done' | 'none' = 'none';

  pageStatus: 'edit' | 'view' | 'offers';

  cardMode: CardModeStatusEnum = CardModeStatusEnum.old;

  loading: {
    portals: boolean;
  } = {
    portals: true,
  };

  userPortals: MinimizedPortalModel[] = [];

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private router: Router,
    private aroute: ActivatedRoute,
    private toastr: ToastrService,
    private sharedDashboardService: SharedOwnerDashboardService,
    private paymentService: PaymentService,
    private progressSpinnerService: ProgressSpinnerService,
    private zone: NgZone
  ) {}

  public async getUserPortals() {
    this.loading.portals = true;
    const res$ = this.api.getPortalsList();
    res$.forEach((a: MinimizedPortalModel) => {
      delete a.defaultOffer;
      // delete a.template;
    });
    this.userPortals = await lastValueFrom(res$);
    this.loading.portals = false;
  }

  async getDomainById(id: number = this.currentDomain.id, setToCurrent = true) {
    let domainDetails$ = this.api.getDomainDetailsById(id);
    let domainDetails: DomainItem = await lastValueFrom(domainDetails$);

    if (setToCurrent) {
      this.currentDomain.domain = domainDetails;
      if (!!domainDetails.process.isProcessing) {
        await this.handleDomainProcessWithRefLoop(domainDetails);
      }
    }

    return domainDetails;
  }

  async linkDomainToPortal(
    domain: DomainItem = this.currentDomain.domain,
    portalId: number,
    isDefault: boolean = false
  ) {
    let pastPortalInfo = domain.portalInfo;
    let pastIsDefault = domain.isDefault;

    this.startProcessing();
    let linkDomainRequest$ = this.api.linkDomainToPortal(
      domain.id,
      portalId,
      isDefault,
      this.domainLinkErrorHandler
    );
    let linkDomainRequest: { success: boolean; domain: DomainItem } =
      await lastValueFrom(linkDomainRequest$);

    domain.isDefault = linkDomainRequest?.domain?.isDefault;
    domain.portalInfo = linkDomainRequest?.domain?.portalInfo;
    domain.process = linkDomainRequest?.domain?.process;

    if (!!linkDomainRequest?.success) {
      if (domain.process.isProcessing) {
        this.handleDomainProcessWithRefLoop(domain);
      }
      if (!pastPortalInfo && !!linkDomainRequest.domain.portalInfo) {
        this.toastr.success('Your Portal is Processing. Please check', 'Done!');
      } else if (
        !pastIsDefault &&
        !!linkDomainRequest.domain.isDefault &&
        pastPortalInfo?.siteId == linkDomainRequest.domain.portalInfo.siteId
      ) {
        this.toastr.success('Successfully set as Main Domain', 'Done!');
      } else if (
        pastPortalInfo?.siteId != linkDomainRequest.domain?.portalInfo?.siteId
      ) {
        this.toastr.success('Your Portal is Processing. Please check', 'Done!');
      }
      this.endProcessing();
    } else {
      this.domainLinkErrorHandler();
    }
  }

  domainLinkErrorHandler = (
    error: any = { message: 'Something Went Wrong' }
  ) => {
    this.failProcessing();
    this.toastr.error(error?.message, 'Error!');
  };

  purchaseNewDomain() {
    this.router.navigate(['dashboard', 'domains', 'purchase']);
  }

  async handleDomainProcessWithRefLoop(domain: DomainItem) {
    let reloadedDomain = await this.getDomainById(domain.id, false);
    Object.keys(domain).forEach((key) => {
      if (!!reloadedDomain[key]) {
        domain[key] = reloadedDomain[key];
      }
    });

    if (domain.process.isProcessing) {
      domain.process.timeout = setTimeout(async () => {
        await this.handleDomainProcessWithRefLoop(domain);
      }, 10000);
      
    }
  }

  async unlinkDomainToPortal(
    domain: DomainItem = this.currentDomain.domain,
    portalId: number
  ) {
    this.startProcessing();
    let unlinkDomainRequest$ = this.api.unlinkDomainToPortal(
      domain.id,
      portalId
    );
    let unlinkDomainRequest: { success: boolean; domain: DomainItem } =
      await lastValueFrom(unlinkDomainRequest$);
    if (!!domain.process.isProcessing) {
      await this.handleDomainProcessWithRefLoop(unlinkDomainRequest.domain);
    } else {
      domain.isDefault = unlinkDomainRequest.domain.isDefault;
      domain.portalInfo = unlinkDomainRequest.domain.portalInfo;
      domain.process = unlinkDomainRequest?.domain?.process;
    }

    if (!!unlinkDomainRequest.success) {
      this.toastr.success('Done!', 'Unlinked Successfully');
      this.endProcessing();
    } else {
      this.failProcessing();
    }
  }

  async getDomainsList() {
    let domainsList$ = this.api.getDomainsDetails();
    let domainsList: DomainItem[] = await lastValueFrom(domainsList$);

    domainsList.forEach(async (domain) => {
      if (!!domain.process.isProcessing) {
        await this.handleDomainProcessWithRefLoop(domain);
      }
    });
    this.domainsList = domainsList;
  }

  initializePaymentMethods() {
    this.paymentServices = [
      {
        id: 1,
        name: 'Credit Card',
        enum: PaymentServiceInterfaceTypeEnum.CreditCard,
        logo: 'credit-card',
        recommended: true,
        active: true,
      },
      {
        id: 2,
        name: 'BTC + Altcoins',
        enum: PaymentServiceInterfaceTypeEnum.BitCoin,
        logo: 'bitcoin',
        recommended: true,
        active: true,
      },
      {
        id: 3,
        name: 'eWallet',
        enum: PaymentServiceInterfaceTypeEnum.EWallet,
        logo: 'e-wallet',
        recommended: true,
        active: false,
      },
    ];
  }

  resetWizard() {
    this.wizardData.domainResult = { alternatives: [], current: null };
    this.wizardData.iAgree = false;
    this.wizardData.paymentService = null;
    this.wizardData.selectedCard = null;
  }

  startLoading() {
    this.loaded = false;
  }
  stopLoading() {
    this.loaded = true;
  }
  startProcessing() {
    this.processing = 'loading';
  }

  endProcessing() {
    this.processing = 'done';
    setTimeout(() => {
      this.processing = 'none';
    }, 1000);
  }

  failProcessing() {
    this.processing = 'none';
  }

  enableNavigate() {
    this.sharedDashboardService.enableNavigate();
  }

  disableNavigate() {
    this.sharedDashboardService.disableNavigate();
  }

  get currentWizardDomain() {
    return (
      this.wizardData.domainResult.current.domain.name +
      '.' +
      this.wizardData.domainResult.current.domain.tld
    );
  }
  // Payment Purchasing

  async scroll(
    id: string,
    position: ScrollLogicalPosition = 'end',
    timeout: number = 300
  ) {
    let el = document.getElementById(id);
    if (!!el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth', block: position });
      }, timeout);
    }
  }

  async switchCardMode(mode: CardModeStatusEnum) {
    switch (mode) {
      case CardModeStatusEnum.new:
        this.hideNextButton();
        break;
      case CardModeStatusEnum.old:
        await this.getAllPaymentMethodsForUser();
        break;
      default:
        break;
    }
    this.cardMode = mode;
    this.scroll('paymentAction', 'start');
  }
  hideNextButton() {
    this.wizardModel.currentStep.step.hideButtons.next = true;
  }

  showNextButton() {
    this.wizardModel.currentStep.step.hideButtons.next = false;
  }

  selectPaymentMethod(methodId: string) {
    this.wizardData.selectedCard = methodId;
    this.wizardModel.currentStep.step.hideButtons = {
      back: false,
      next: false,
    };
  }
  async getAllPaymentMethodsForUser() {
    this.userPaymentMethods =
      await this.paymentService.getAllPaymentMethodsForUser(true);

    if (!!this.userPaymentMethods?.length) {
      this.selectPaymentMethod(this.userPaymentMethods[0].id);
      this.showNextButton();
    } else {
      this.switchCardMode(CardModeStatusEnum.new);
    }
  }
  async purchaseDomainWithStripe() {
    this.progressSpinnerService.showLoader('Processing');
    const domainPaymentIntent: { transactionId: string; status: boolean } =
      await this.paymentService.payOneTimeStripe(
        this.wizardData.domainResult.current.result.price.price,
        this.currentWizardDomain,
        this.wizardData.selectedCard,
        undefined,
        this.paymentErrorHandler
      );

    if (!!domainPaymentIntent.status) {
      this.paymentSuccessAlert(domainPaymentIntent.transactionId);
    } else {
      this.paymentErrorHandler();
    }
  }

  showInvoice(invoiceUrl: string = this.currentInvoiceUrl) {
    btcpay.showInvoice(invoiceUrl.split('/')[4]);
  }
  async purchaseDomainWithBtcPay() {
    this.progressSpinnerService.showLoader('Creating the invoice');
    const { invoiceUrl, transactionId } = await this.paymentService.payBtcpay(
      this.wizardData.domainResult.current.result.price.price,
      this.currentWizardDomain,
      undefined,
      'BTC',
      this.paymentErrorHandler
    );
    this.currentInvoiceUrl = invoiceUrl;

    this.showInvoice(invoiceUrl);

    btcpay.onModalWillEnter(() => {
      this.progressSpinnerService.hideLoader();
    });
    btcpay.onModalWillLeave(this.onLeaveInvoice);

    btcpay.onModalReceiveMessage(async (message: any) => {
      const { invoiceId, status } = message?.data;
      if (!!invoiceId && status == 'paid') {
        this.progressSpinnerService.showLoader('Processing');
        await this.onInvoicePaid(transactionId);
        this.progressSpinnerService.hideLoader();
      } else {
      }
    });

    // window.btcpay.showInvoice(invoiceId);
    // window.location.href = invoiceUrl;

    // if (!!domainPaymentIntent.status) {
    //   this.paymentSuccessAlert(domainPaymentIntent.transactionId);
    // } else {
    //   this.paymentErrorHandler();
    // }
  }

  onInvoicePaid(transactionId: string) {
    // btcpay.hideFrame();
    this.hideIframeWithoutEmit();
    this.paymentSuccessAlert(transactionId);
  }

  hideIframeWithoutEmit() {
    let iframe: HTMLElement = window.document.getElementsByName('btcpay')[0];
    if (!!iframe) {
      iframe.style.display = 'none';
      btcpay.showingInvoice = false;
      iframe = window.document.body.removeChild(iframe);
    }
  }

  onLeaveInvoice = () => {
    Swal.fire({
      title: `Warning`,
      icon: 'warning',
      html: `You Closed the invoice, you can <b>Recreate the invoice</b> or just <b>Dismiss</b> to change the payment method`,
      cancelButtonText: 'Dismiss',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Recreate the invoice',
    }).then((value: SweetAlertResult) => {
      if (value.isConfirmed) {
        // this.purchaseDomainWithBtcPay();
        this.showInvoice();
      }
    });
  };

  paymentErrorHandler = () => {
    this.progressSpinnerService.hideLoader();
    Swal.fire({
      title: 'Something Went Wrong!',
      text: 'Error',
      icon: 'error',
    });
  };

  private paymentSuccessAlert(transactionID: string) {
    this.zone.run(() => {
      this.router.navigate([
        'dashboard',
        'domains',
        'purchase',
        'verification',
        this.currentWizardDomain,
        transactionID,
      ]);
    });
    this.progressSpinnerService.hideLoader();
  }
}
