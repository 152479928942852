import { ProductCreateService } from './../product-create.service';
import { slider } from 'src/app/shared/animations/animations';
import { PortalControlService } from './../../../../../shared/services/portal-control.service';

import { Title } from '@angular/platform-browser';

import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import {
  StepVisibilityEnum,
  WizardModel,
  WizardTypeEnum,
} from './../../../../../models/wizard';
import { WizardStepStatusEnum } from 'src/app/models/wizard';
import { SharedProductService } from './../../shared-product.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  HostListener,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  RouterOutlet,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../../../shared/services/lsg.api.service';

import { lastValueFrom } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models';
import { GlobalWizardData } from '../../../portal/dto/portal.input';
import { NavCanDeactivate } from 'src/app/shared/guards/nav-can-deactivate';
import { AiCurrentStateEnum } from './product-title-wizard-step/product-title-wizard-step.component';

@Component({
  selector: 'app-product-create-wizard',
  templateUrl: './product-create-wizard.component.html',
  styleUrls: ['./product-create-wizard.component.css'],
  animations: [
    trigger('inOutFadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('0.35s ease-out', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.35s ease-in', style({ opacity: 0, height: 0 })),
      ]),
    ]),
    trigger('horizontalInOutAnimation', [
      state(':enter', style({ 'overflow-x': 'hidden' })),
      transition(':enter', [
        style({ opacity: 0, width: 0, 'white-space': 'nowrap' }),
        animate('0.3s ease-out', style({ opacity: 1, width: '*' })),
      ]),
    ]),
    slider,
  ],
})
export class ProductCreateWizardComponent
  extends NavCanDeactivate
  implements OnInit, OnDestroy, AfterViewInit
{
  AiCurrentStateEnum = AiCurrentStateEnum;

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event) {
  //   // Check if the container is out of the view box
  //   const container = document.querySelector('#actionsContainer');
  //   const containerRect = container.getBoundingClientRect();
  //   const viewportHeight = window.innerHeight;
  //   const bottom = containerRect.bottom - viewportHeight;

  //   // If the container is out of the view box, fix it to the bottom of the page
  //   if (bottom > 0) {
  //     this.isActionsFixed = true;
  //   } else {
  //     this.isActionsFixed = false;
  //   }
  // }

  @ViewChild('actionsContainer', { static: true }) actionsContainer: ElementRef;
  isActionsFixed = false;
  loaded: boolean = false;
  wizardStepStatusEnum = WizardStepStatusEnum;
  stepVisibilityEnum = StepVisibilityEnum;
  // Booleans
  domainAgreement = false;
  startLoading: boolean = false;
  changePaymentMethod = false;
  selectedService = 1;
  addNewCard = false;
  isLoading = false;
  isProductTab: boolean = true;
  isSiteNameChecked = false;
  isCreatingWebsite = false;
  divUpdater: any;
  isEditingEmail = false;
  public isInputEmailValid: boolean;
  counter = 0;

  height: string = '30vh';
  //Values
  // public siteName: string;
  // public domainTld: string = 'myinfluencerproduct.com';
  // public changingSiteName = '';
  // public productName = '';
  // public email = '';
  // private domainUrl: string;
  // selectedPaymentMethod: string;
  // descArr: any;
  // public domainType: DomainType = DomainType.Free;

  // Models
  currentUser: User;

  @ViewChild('outlet') outlet!: ElementRef;

  @ViewChild('routerContainer') routerContainer!: ElementRef;
  isAnimationWorking: boolean = false;
  animationState: any;
  constructor(
    private authenticationService: AuthenticationService,
    private api: ApiService,
    private paymentService: PaymentService,
    public userDashboardService: UserDashboardService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public portalControlService: PortalControlService,
    // private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public productCreateService: ProductCreateService,
    private title: Title,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    super(portalControlService);
    this.productCreateService.initializeWizardProductCreate();
  }

  ngOnDestroy(): void {
    this.productCreateService.resetWizard();
  }

  async ngOnInit() {
    setTimeout(() => {
      this.loaded = true;
    }, 800);
    this.currentUser = this.authenticationService.currentUserValue;
    // await this.sharedProductService.getTemplates();
    this.sharedOwnerDashboardService.clearActions();
    this.sharedOwnerDashboardService.clearBreadcrumbs();
    this.sharedOwnerDashboardService.setBreadcrumbs({base: 'product', lastNode: 'Create Product'})
    this.sharedOwnerDashboardService.setTitle({main: 'Create Product', sub: 'Transform Your Ideas into Marketable Reality with Ease and Precision, Empower Your Inner Innovator.'})


  }

  // Animation Factory

  ngAfterViewInit(): void {}

  prepareRouteTransition(outlet: RouterOutlet) {
    if (this.animationState != outlet.activatedRouteData.animation) {
      this.isAnimationWorking = true;
      setTimeout(() => {
        this.isAnimationWorking = false;
      }, 900);

      this.animationState = outlet.activatedRouteData.animation;
    }



    return outlet.activatedRouteData.animation;
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  convertWizardDataToProductInput(wizardData: GlobalWizardData) {
    // let createProductObject: CreateWebsiteInput = {
    //   productName: wizardData.productName,
    //   templateId: wizardData.templateId,
    //   productTitle: wizardData.productTitle,
    //   domainId: wizardData.domainId,
    // };
    // return createProductObject;
  }

  exitAlert() {
    if (!!this.portalControlService.canNavigateAll) {
      this.productCreateService.resetWizard();
      this.router.navigate(['dashboard', 'product']);
    } else {
      if (
        confirm(
          'You have unsaved changes! If you leave, your changes will be lost.'
        )
      ) {
        this.portalControlService.canNavigateAll = true;
        this.productCreateService.resetWizard();
        this.router.navigate(['dashboard', 'product']);
      } else {
        return false;
      }
    }
  }

  createProductErrorHandler(error: any) {
    alert(error.message);
  }
}
