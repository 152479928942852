import { MinimizedPortalModel } from '../components/user-dashboard/portal/dto/portal.output';
// --------------------------------------------- Product -----------------------------------------------

export class DeleteResponse {
  public success: boolean;
  public reason: string;
  public offers: {
    id: number;
    title: string;
    effect: string;
  }[];
  public offer_ids: number[];
}

// --------------------------------------------- Offer -----------------------------------------------

export class CurrenciesModelInput {
  constructor(public id?: number) {}
}

export class MoneyModelInput {
  value: number;
  currency: CurrenciesModelInput;
}

export class CurrenciesModel {
  constructor(
    public symbol?: string,
    public code?: string,
    public id?: number,
    public display_name?: string
  ) {}
}

export class MoneyModel {
  value: number;
  currency: CurrenciesModel;
}

export class OfferDeleteResponse {
  parentPortal: {
    id: string;
    name: string;
  };
  reason: string;
  success: boolean;
}

// --------------------------------------------- Media Library -----------------------------------------------
export class MediaLibraryFileModel {
  public id?: number;
  public fileInfo: {
    name: string;
    displayName: string;
    ext: string;
    mimetype: string;
  };
  public video?: MediaVideoInfo;
  public img?: MediaImageInfo;
  public url: string;
  public used: boolean;
  public deleting?: boolean;
  public uploadDate: number;
  public category: MediaLibraryCategoryEnum;
  public type: MediaLibraryTypeModel;
}

export enum MediaLibraryCategoryEnum {
  media = 'media',
  content = 'content',
  any = 'any',
  messenger = 'messenger',
  stream = 'stream',
}

export enum MediaLibraryUploadType {
  Any = 'any',
  Content = 'content',
  Media = 'media',
  Video = 'video',
  Image = 'image',
  Audio = 'audio',
}

export class MediaLibraryUploadResponseModel {
  public uploadedFiles: MediaLibraryFileModel[];
  public refusedFiles: {
    filename: string;
    reason: string;
  }[];
}

export class MediaVideoInfo {
  public url: string;
  public thumbnail: string;
  public durationInSeconds: number;
}

export class MediaImageInfo {
  public url?: string;
  public thumbnail: string;
  public width?: number;
  public height?: number;
}

export class MediaLibraryTypeModel {
  public name: string;
  public display_name: string;
  public color_hex?: string;
  public icon?: string;
}

export class UserQuotaModel {
  public quota: number;
  public remain: number;
  public used: number;
  public percentage: number;
}

// Edit Checkout
export class CheckoutTestimonials {
  constructor(
    public author?: string,
    public quote?: string,
    public image: MediaLibraryFileModel[] = [],
    public imageId: number[] = [],
    public open: boolean = false
  ) {}
}

export class CheckoutContent {
  constructor(
    public title: string = null,
    public description: string = null,
    public mediaId: number[] = [],
    public media: MediaLibraryFileModel[] = []
  ) {}
}

export class CheckoutBanner {
  constructor(
    public enableBanner: boolean = false,
    public bannerHeight: number = 0,
    public bannerId: number[] = [],
    public banner: MediaLibraryFileModel[] = [],
    public logoId: number[] = [],
    public logo: MediaLibraryFileModel[] = []
  ) {}
}

export class CheckoutAppearances {
  constructor(public primaryColor: string = null) {}
}
export class CheckoutExtraInfo {
  constructor(
    public collectAddress: boolean = null,
    public collectPhone: boolean = null
  ) {}
}

export class EditCheckoutPageModel {
  constructor(
    public checkoutContent: CheckoutContent = new CheckoutContent(),
    public checkoutBanner: CheckoutBanner = new CheckoutBanner(),
    public appearances: CheckoutAppearances = new CheckoutAppearances(),
    public extraContactInfo: CheckoutExtraInfo = new CheckoutExtraInfo(),
    public testimonials: CheckoutTestimonials[] = [],
    public editLink: string = '',
    public previewLink: string = '',
    public offerLink: string = ''
  ) {}
}

export class UpdateEditCheckoutResponseModel {
  public anyDataUpdated: boolean;
  public checkoutInfo: EditCheckoutPageModel;
  public errorItems: [];
}
