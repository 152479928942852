import { slideInOut, ShrinkInOutAnimation } from './../../animations/animations';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

export enum SearchViewModeEnum {
  results = 'results',
  preferences = 'preferences',
  welcome = 'welcome',
  none = 'none'
}
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
  animations: [ShrinkInOutAnimation, slideInOut]
})
export class GlobalSearchComponent {

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('interactiveBox') interactiveBox: ElementRef;
  @ViewChild('searchArea') searchArea: ElementRef;

  searchViewModeEnum = SearchViewModeEnum
  searchViewMode: SearchViewModeEnum = SearchViewModeEnum.none
  searchWord: string;
  isSearchInProgress: boolean = false;


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if(!this.searchArea.nativeElement.contains(event.target)){
     this.terminateSearch()
     }
  }



  onSearchWordChange() {
    this.isSearchInProgress = true;

      setTimeout(() => {
        if (!!this.searchWord.length) {
        this.isSearchInProgress = false;
        this.searchViewMode = SearchViewModeEnum.results;
      }
      else {
        this.isSearchInProgress = false;
        this.searchViewMode = SearchViewModeEnum.welcome

      }
      }, 1000);


  }


  initSearch() {
    this.searchViewMode = SearchViewModeEnum.welcome
  }

  terminateSearch() {
    this.searchViewMode = SearchViewModeEnum.none
  }

  resetSearch() {
    this.searchWord = null;
  }
}
