// actions/products.actions.ts
import { createAction, createActionGroup, props } from '@ngrx/store';
import { StudentItem } from '../../../../../../components/user-dashboard/product/models/course';






export const CurrentCourseStudentsActions = createActionGroup({
  source: 'Load Current Course Students',
  events: {
    load: props<{ productId: number; errorHandler?: any }>(),
    loadSuccess: props<{ students: StudentItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});
