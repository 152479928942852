import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';

@Component({
  selector: 'app-affiliate-drop',
  templateUrl: './affiliate-drop.component.html',
  styleUrls: ['./affiliate-drop.component.css'],
})
export class AffiliateDropComponent implements OnInit {
  constructor(
    public userDashboardService: UserDashboardService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  switchToOwnerMode() {
    this.userDashboardService.navigateToOwnerMode();
    this.userDashboardService.disableOtherSideNotification();
  }

  goToAccountSettings() {
    this.router.navigate(['profile', 'overview']);
  }

  ActivateTour() {}

  logout() {
    this.authenticationService.logout();
  }
}
