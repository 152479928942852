const ICONS = {
    category_name: 'Social',
    icons_list: [
      "instagram",
      "snapchat",
      "classmates",
      "facebook",
      "whatsapp",
      "social-media",
      "youtube",
      "dribbble",
      "twitter",
      "tiktok",
      "behance"



    ],

};


export default ICONS
