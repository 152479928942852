// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import { DashboardStatisticsTracking } from '../../../../models/learmo-dashboard';
 import { forgetSession } from '../user/auth/auth.actions';
import { DashboardTrackingActions } from './dashboard-tracking.actions';

export interface DashboardState {
  dashboardTracking: DashboardStatisticsTracking | null;
  dashboardTrackingLoaded: boolean;
  dashboardTrackingError: any;
}

const initialState: DashboardState = {
  dashboardTracking: null,
  dashboardTrackingLoaded: false,
  dashboardTrackingError: null,
};

export const dashboardTrackingReducer = createReducer(
  initialState,
  on(DashboardTrackingActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(DashboardTrackingActions.loadSuccess, (state, { dashboardTracking }) => {

    return {
    ...state,
    dashboardTracking,
    dashboardTrackingLoaded: true,
    dashboardTrackingError: null,
  }}

  ),
  on(DashboardTrackingActions.loadFailure, (state, { error }) => {
    if (typeof error !== 'string') {
      return state;
    }

    return { ...state, dashboardTrackingError: error, dashboardTrackingLoaded: false };
  }),
  on(forgetSession, (state) => initialState)
);
