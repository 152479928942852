// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-active {
  color: var(--bs-success-inverse);
    border-color: var(--bs-success);
    background-color: var(--bs-success);
}

.drop-active .svg-icon {
  color: var(--bs-success-inverse);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/publish-status-badges/system-label/system-label.component.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;IAC9B,+BAA+B;IAC/B,mCAAmC;AACvC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".drop-active {\n  color: var(--bs-success-inverse);\n    border-color: var(--bs-success);\n    background-color: var(--bs-success);\n}\n\n.drop-active .svg-icon {\n  color: var(--bs-success-inverse);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
