import { Pipe, PipeTransform } from '@angular/core';
import { timeAgo } from './utils/dateago.utils';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class TimePipe implements PipeTransform {
  transform(value: any): any {
    return timeAgo(value);
  }
}
