// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  border-radius:50% ;
  width: 100%;
  height: 100%;
}

.sm {
  width: 20px !important;
  height: 20px !important;
}

.md {
  width: 40px !important;
  height: 40px !important;
}

.lg {
  width: 50px !important;
  height: 50px !important;
}

.xl {
  width: 75px !important;
  height: 75px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/affiliate/owner-announcements/owner-announcement-ui-elements/announcement-recipients-images/announcement-recipients-images.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":["img {\n  border-radius:50% ;\n  width: 100%;\n  height: 100%;\n}\n\n.sm {\n  width: 20px !important;\n  height: 20px !important;\n}\n\n.md {\n  width: 40px !important;\n  height: 40px !important;\n}\n\n.lg {\n  width: 50px !important;\n  height: 50px !important;\n}\n\n.xl {\n  width: 75px !important;\n  height: 75px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
