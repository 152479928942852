import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent implements OnInit {
  @Input() videoURL;
  @Input() videoTitle;
  @Input() videoPoster;
  constructor(private modalService: NgbModal) {}

  open(content) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in mymodalcustomclass',
    });
  }

  ngOnInit(): void {}
}
