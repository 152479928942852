import { Component, OnInit, Input } from '@angular/core';
import { AffiliateApi } from '../../../../services/affiliate.api.service';
import { NotificationsService } from '../../../../services/notifications.service';


@Component({
  selector: 'app-affiliate-announcements-list',
  templateUrl: './affiliate-announcements-list.component.html',
  styleUrls: ['./affiliate-announcements-list.component.css'],
})
export class AffiliateAnnouncementsListViewComponent implements OnInit {
  constructor(
    public affiliateApi: AffiliateApi,
    public notificationsService: NotificationsService
  ) {}

  async ngOnInit() {
    // let noti = await this.affiliateApi.getNotifications().toPromise();
    //
  }
}
