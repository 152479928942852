import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentsState } from './payments.reducer';




// Create a feature selector for the 'website' feature
export const selectPaymentsState = createFeatureSelector<PaymentsState>('payments');

// // Selectors for website data
export const selectUserPaymentCards = createSelector(selectPaymentsState, (state) => state.userPaymentCards);
export const isUserPaymentCardsLoaded = createSelector(selectPaymentsState, (state) => state.loaded);
export const isUserPaymentCardsError = createSelector(selectPaymentsState, (state) => state.error);





// export const selectUnreadNotificationsCount = createSelector(selectNotificationsState, (state) => state.unreadNotificationsCount);

// export const isNotificationsLoaded = createSelector(selectNotificationsState, (state) => state.loaded);
// export const isNotificationsLoadingError = createSelector(selectNotificationsState, (state) => state.error);

// // Selectors for website pages
// export const selectWebsitePages = createSelector(selectWebsiteState, (state) => state.pages);
// export const isWebsitePagesLoaded = createSelector(selectWebsiteState, (state) => state.pagesLoaded);
// export const isWebsitePagesLoadingError = createSelector(selectWebsiteState, (state) => state.pagesError);
