import { AiService } from './../../../../../../shared/services/ai.service';
import { ProductCreateService } from './../../product-create.service';
import { SharedProductService } from './../../../shared-product.service';
import { Component, OnInit } from '@angular/core';
import {
  InOutAnimation,
  listAnimation,
} from 'src/app/shared/animations/animations';
import { colorSet } from 'src/app/models/global-classes';
import { CtaMessageItem } from 'src/app/shared/partials/ai/ai-cta-card/ai-cta-card.component';
import { DescriptionEnterStatusEnum } from '../product-description-image-wizard-step/product-description-image-wizard-step.component';
import { AiGeneratorComponentData } from 'src/app/shared/partials/ai/ai-idea-generator/ai-generator.models';

export enum AiCurrentStateEnum {
  prompt = 'prompt',
  using = 'using',
  disabled = 'disabled',
  done = 'done',
}
@Component({
  selector: 'app-product-title-wizard-step',
  templateUrl: './product-title-wizard-step.component.html',
  styleUrls: ['./product-title-wizard-step.component.css'],
  animations: [listAnimation, InOutAnimation],
})
export class ProductTitleWizardStepComponent implements OnInit {
  ctaData: {colorSet: colorSet, image: string, message: CtaMessageItem} = {
    colorSet: 'primary',
    image: 'assets/images/illustrations/brain-question.svg',
    message: {main: {value: 'Feeling out of ideas?'}, sub: {value: "No need to worry; I'm here to assist you."}},
  }

  aiCurrentStateEnum = AiCurrentStateEnum;

  output: string;

  tempTitleAndDescription: {
    title: string;
    description?: string;
    tooltip?: string;
  };
  constructor(
    public productCreateService: ProductCreateService,
    private aiService: AiService
  ) {}

  ngOnInit() {
    this.productCreateService.setWizardNextTitle(`Continue`);
  }

  onGetResult(result: string) {
    // this.changeAiStatus(AiCurrentStateEnum.done);
    this.productCreateService.wizardData.courseInput.name = result;
    this.productCreateService.wizardData.courseInput.shortDescription = '';
    this.productCreateService.wizardSettings.descriptionAiCurrentState = DescriptionEnterStatusEnum.none
    this.productCreateService.wizardModel.goToNextStep()
  }

  onAiDataChange(event: AiGeneratorComponentData) {
    // Todo: Save temp data on the wizard
    this.productCreateService.wizardData.temp.titleAiState = event;
  }

  setCurrentStepTitleAndDescriptionForAi() {
    this.tempTitleAndDescription = JSON.parse(
      JSON.stringify(
        this.productCreateService.wizardModel.currentStep.step.innerContent
      )
    );
    this.productCreateService.wizardModel.currentStep.step.hideButtons = {
      back: true,
      next: true,
    };
    this.productCreateService.wizardModel.currentStep.step.innerContent = {
      // title: 'Using Learmo AI',
      // description: "Let's Craft a catchy title and capture your students.",
      title: '',
      // description: "Let's Craft a catchy title and capture your students.",
    };
  }

  revertFromTemp() {
    this.productCreateService.wizardModel.currentStep.step.hideButtons = {
      back: false,
      next: false,
    };
    if (!!this.tempTitleAndDescription) {
      this.productCreateService.wizardModel.currentStep.step.innerContent =
        JSON.parse(JSON.stringify(this.tempTitleAndDescription));
    }
  }

  changeAiStatus(status: AiCurrentStateEnum) {
    switch (status) {
      case AiCurrentStateEnum.using:
        this.setCurrentStepTitleAndDescriptionForAi();
        break;

      default:
        this.revertFromTemp();
        break;
    }
    this.productCreateService.wizardSettings.titleAiCurrentState = status;
  }

  writeInTitle(value: string) {
    // if (!!value.length) {
    //   this.changeAiStatus(AiCurrentStateEnum.disabled);
    // } else {
    //   this.changeAiStatus(AiCurrentStateEnum.prompt);
    // }
    if (!!value.length) {
      this.ctaData.message = {main: {value:'Amazing!'}, sub: {value: 'I truly admire human creativity, yet I will remain steadfast.' }}
    }
    else {
      this.ctaData.message = {main: {value:'If you require assistance!'}, sub: {value: 'I would be delighted to brainstorm with you.' }}
    }
  }

  consoleTest(value: any) {
    
  }
}
