import { Router } from '@angular/router';
import { SystemPlanType } from './../../../../models/plans';
import {
  isSystemPlansLoaded,
  selectCurrentUserSubscriptionInfo,
  selectSystemPlans,
} from './../../../../shared/store/_features/subscription/subscription.selector';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { Bundle, Plan } from 'src/app/models/plans';
import {
  BundleRenewalType,
  SubscriptionBillingInfo,
  SubscriptionData,
} from 'src/app/models/subscription';
import { ApiService } from 'src/app/shared/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css'],
})
export class PlansComponent implements OnInit, AfterViewInit {
  BundleRenewalType = BundleRenewalType;
  SystemPlanType = SystemPlanType;
  loaded: boolean = false;
  plansList: Plan[] = [];
  freePlans: Plan[] = [];
  // @Input('currentUserSubscriptionInfo') currentUserSubscriptionInfo: SubscriptionBillingInfo;

  // @Input('currentUserSubscriptionInfo') set setCurrentUserSubscriptionInfo (info: SubscriptionBillingInfo) {

  // }
  // @Input() currentUserBundleRenewalType: BundleRenewalType;

  
  @ViewChild('plansModal') modalContent: TemplateRef<any>;
  modalRef: NgbModalRef;
  

  @Output() onClickUpgrade = new EventEmitter<any>();



  // Plans View Component
 

  @Input() currentViewPlansType: BundleRenewalType = BundleRenewalType.annual;
  


  currentUserPlan: Plan;
  currentUserBundle: Bundle;
  currentSubscriptionData: SubscriptionData;
  
  currentActivePlan: Plan;
  currentActiveBundle: Bundle;

  // @Output() onChoosePlan = new EventEmitter<any>();
  // currentActivePlan: Plan;

  constructor(private router: Router,private store: Store, private modalService: NgbModal, private toastr: ToastrService) {
    this.store.select(selectSystemPlans).subscribe((plans) => {
      this.freePlans = plans.filter((p) => p.type === SystemPlanType.free)
      this.plansList = plans.filter((p) => p.type === SystemPlanType.subscription)
    });

    this.store.select(selectCurrentUserSubscriptionInfo).subscribe((info) => {
      this.currentUserPlan = info.planData;
      this.currentUserBundle = info.subscriptionData.renewal_type == BundleRenewalType.annual ? this.currentUserPlan.annual : this.currentUserPlan.monthly;
      console.log(this.currentUserBundle, this.currentUserPlan)
      this.currentSubscriptionData = info.subscriptionData;

      this.activateItem(info.planData, info.subscriptionData.renewal_type);
    });

    this.store.select(isSystemPlansLoaded).subscribe((loaded) => {
      this.loaded = loaded;
    });
  }
  ngAfterViewInit(): void {
    // this.activateItem(this.currentUserPlan, this.currentSubscriptionData.renewal_type);
  }

  ngOnInit() {}

  // onChoosePlan(plan: Plan) {
  //   this.currentActivePlan = plan;
  // }


  activateItem(
    item: Plan,
    type: BundleRenewalType = this.currentViewPlansType
  ) {
    this.currentViewPlansType = type;
    this.currentActivePlan = item;
    this.currentActiveBundle =
      this.currentViewPlansType == BundleRenewalType.annual
        ? item.annual
        : item.monthly;

    // this.onChoosePlan.emit(item);
  }
  onItemClick(item: Plan) {
    this.activateItem(item);
  }

  switchPlanTypes(type: BundleRenewalType) {
    this.currentViewPlansType = type;
    if (!!this.currentActivePlan) {
      this.activateItem(this.currentActivePlan, type);
    }
  }


  closeModal() {
    this.modalRef.dismiss();
  }
  goToUpgradePage(bundleId = this.currentActiveBundle.id) {
    this.closeModal();
    if (bundleId == this.currentUserPlan.id) {
      this.toastr.warning("This is your current bundle. You can't upgrade to it.");
      return;
    }
    this.router.navigate(['/dashboard','profile', 'subscription-and-billing','upgrade-subscription', bundleId]);
  }

  // async getUpgradeCalculations(bundleId = this.currentActiveBundle.id) {
  //   const request$ = this.api
  //     .getUpgradeCalculations(
  //       bundleId,
  //       ()=> {}
  //     );

  //     const response = await lastValueFrom(request$);

  //     console.log(response)
  // }


  openModal(content = this.modalContent) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      backdrop: true,
      centered: true,
      animation: false,
      windowClass: 'global-modal-class modal-animate-in plans-modal',
      scrollable: false,
    });

    this.modalRef.result.then(
      () => {
        // this.modalStatusOpened = false;
      },
      (reason) => {
        // this.modalStatusOpened = false;
      }
    );
  }

}
