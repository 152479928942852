import { Action, createReducer, on } from '@ngrx/store';
import { WebsiteModel } from '../../../../components/user-dashboard/portal/portal.model';

import { NotificationElement } from '../../../../models/owner-dashboard';
import {   NotificationsActions, newNotifications, setNotificationAsReadSuccess } from './notifications.actions';
import { NotificationStatusEnum } from 'src/app/models/affiliates-dashboard';
import { forgetSession } from '../user/auth/auth.actions';

export interface NotificationsState {
  notifications: NotificationElement[];
  unreadNotificationsCount: number;
  loaded: boolean;
  error: any;
}

const initialState: NotificationsState = {
  notifications: [],
  unreadNotificationsCount: null,
  loaded: false,
  error: null,
};

export const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(NotificationsActions.loadSuccess, (state, { notifications, unreadNotificationsCount }) => ({
    ...state,
    notifications: notifications,
    unreadNotificationsCount: unreadNotificationsCount,
    loaded: true,
  })),

  on(NotificationsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  on(newNotifications, (state, { notification }) => {
    const updatedObject = [notification, ...state.notifications ];
    return { ...state, notifications: updatedObject, unreadNotificationsCount: state.unreadNotificationsCount + 1, loaded: true };
  }),

  on(setNotificationAsReadSuccess, (state, { notificationsIds }) => {

    let notifications: NotificationElement[] = JSON.parse(JSON.stringify(state.notifications));
    const oldNotification = notifications.find(n => n.id === notificationsIds[0]);

    if (oldNotification.status == NotificationStatusEnum.read) {
      return state;
    }

    const updatedNotifications = notifications.map(notification => {
      if (notificationsIds.includes(notification.id)) {
        return { ...notification, status: NotificationStatusEnum.read };
      }
      return notification;
    });

    return { ...state, notifications: updatedNotifications, unreadNotificationsCount: state.unreadNotificationsCount - 1, loaded: true };
  }),
  on(forgetSession, (state) => initialState)
);
