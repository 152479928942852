import { Component, Input, OnInit } from '@angular/core';
import { SharedProfileService } from '../../../shared-profile.service';

@Component({
  selector: 'app-subscription-status-label',
  templateUrl: './subscription-status-label.component.html',
  styleUrls: ['./subscription-status-label.component.css']
})
export class SubscriptionStatusLabelComponent implements OnInit {
  @Input() labelClasses: string = 'badge-lg';
  constructor(public sharedProfileService: SharedProfileService) { }

  ngOnInit() {
  }

}
