import { Component, Input, OnInit } from '@angular/core';
import { AffiliateNotificationTypeEnum } from '../../../../../../../models/affiliates-dashboard';

@Component({
  selector: 'app-affiliate-notification-type-label',
  templateUrl: './aff-noti-type-label.component.html',
  styleUrls: ['./aff-noti-type-label.component.css'],
})
export class AffiliateNotificationTypeLabelComponent implements OnInit {
  affiliateNotificationTypeEnum = AffiliateNotificationTypeEnum;
  @Input() notificationType: AffiliateNotificationTypeEnum =
    AffiliateNotificationTypeEnum.general;
  constructor() {}

  ngOnInit(): void {}
}
