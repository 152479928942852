// ai-idea-generator.component.ts
import { Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AiIdeaProcessor } from './ai-idea-processor';
import {
  AiCourseCategory,
  AiGeneratorComponentData,
  CurrentIdeationStepEnum,
} from './ai-generator.models';
import {
  InOutAnimation,
  disableAnimation,
  listAnimation,
} from 'src/app/shared/animations/animations';
import { AnimatedLoaderType } from '../ai-thinking-loader/ai-thinking-loader.component';
import { AiService } from 'src/app/shared/services/ai.service';

export class AiIdeaGeneratorPromptSettings {
  enableCategories: boolean = true;
}

@Component({
  selector: 'app-ai-idea-generator',
  templateUrl: './ai-idea-generator.component.html',
  styleUrls: ['./ai-idea-generator.component.css'],
  animations: [listAnimation, InOutAnimation, disableAnimation],
})
export class AiIdeaGeneratorComponent implements OnInit, OnDestroy {
  animatedLoaderType = AnimatedLoaderType;
  currentIdeationStepEnum = CurrentIdeationStepEnum;

  @Output() SelectedResult: EventEmitter<string> = new EventEmitter<string>();
  @Output() CancelClicked: EventEmitter<any> = new EventEmitter<any>();


  @Input() tempComponentData: AiGeneratorComponentData = undefined;
  
  @Input() promptFunction = () => {
    return `Generate five modern fancy creative course titles ideas`
  }

  @Input() promptSettings: AiIdeaGeneratorPromptSettings = {
    enableCategories: true,
  }

  componentData: AiGeneratorComponentData = {
    results: [],
    currentIdeationStep: CurrentIdeationStepEnum.category,
    selectedResultIndex: null,
    writingOwn: false,
    selectedCategories: [],
    localGeneratorData: { entering: 'title', index: null, generating: false, resultsSync: 0, lastChar: '' }
  };

  @Output() componentDataChange = new EventEmitter<AiGeneratorComponentData>();
  // @Output() currentIdeationStepChange = new EventEmitter<CurrentIdeationStepEnum>();

  private aiIdeaProcessor: AiIdeaProcessor;

  constructor(
    private ai: AiService,
    private toastr: ToastrService
  ) {

  }
  ngOnDestroy(): void {
    this.tempComponentData = JSON.parse(JSON.stringify(this.componentData));
  }

  ngOnInit() {
    this.initializeAiService();
  }
  _promptFunction = (catMap = this.componentData.selectedCategories.map((a) => a.label).join(', ')) => {
    let catPrompt = ``;
    if (this.promptSettings.enableCategories) {
      catPrompt = `in the categories '${catMap}' formatted as 'title (category)'`;
    }
    
    return `${this.promptFunction()} ${catPrompt}, do not say anything after that:`
  }


  initializeAiService() {
    this.aiIdeaProcessor = new AiIdeaProcessor({ promptFunction: this._promptFunction, componentData: this.tempComponentData }, this.ai);

    this.aiIdeaProcessor._componentData.subscribe((data) => {
      this.componentData = data;

      this.scrollTo(`title-result-${data.localGeneratorData.index}`);
      this.componentDataChange.emit(this.componentData);
    });
  }
  startThinking() {
    if (this.componentData.selectedCategories.length) {
      // this.changeCurrentStep(CurrentIdeationStepEnum.thinking);
      this.generateCourseTitle();
    } else {
      this.toastr.warning('Select/Enter a category');
    }
  }

  scrollTo(elementID: string) {
    setTimeout(() => {
      (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }



  exitTheGenerator() {
    this.resetTheProcess();
    this.CancelClicked.emit();
  }

  async generateCourseTitle() {
    if (this.componentData.selectedCategories.length) {
      await this.aiIdeaProcessor.generateATitleRequest(this.componentData.selectedCategories);
    }
  }


  onSelectResult(index: number) {
    this.componentData.selectedResultIndex = index;
    this.focusElementById(`title-input${index}`);
  }

  focusElementById(elementID: string, timeout: number = 500) {
    setTimeout(() => {
      (document.getElementById(elementID) as HTMLElement)?.focus();
    }, timeout);
  }


  writeYourOwn() {
    if (!this.componentData.writingOwn) {
      let newIndex = this.componentData.results.push({
        category: 'Custom Title',
        value: '',
        processing: false
      });
      this.onSelectResult(newIndex - 1);
      this.componentData.writingOwn = true;
    }
  }

  onSubmit(index: number = this.componentData.selectedResultIndex) {
    const selected = this.componentData.results[index];
    if (!!selected && !!selected.value.length) {
      let finalizedTitle = selected.value.replace(/^\d+\.\s/g, '');
      this.SelectedResult.emit(finalizedTitle);
    } else {
      this.toastr.error('Course title is required');
    }
  }

  resetTheProcess() {
    this.aiIdeaProcessor.resetTheProcess();
  }

  // changeCurrentStep(step: CurrentIdeationStepEnum) {
  //   this.componentData.currentIdeationStep = step;
  //   // this.currentIdeationStepChange.emit(step);
  // }
}
