// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :root {
  --size: 54px;
  --speed:2s;
} */


.custom-title-button {
  transition: all 0.5s ease-in-out !important;
}
.think-ai {
  transition: all 0.5s ease-in-out !important;
  transform: translate(-50%, 50%) !important;
  left: 50%;
  bottom: 50%;
  z-index: 99;
  font-size: 100px;
}

.inactive-result {
  /* filter: blur(0.9px); */
  opacity: 0.5;
}

.idea-item{
  transition: all 0.5s ease-in-out !important;
}

.yes-results {
  position: sticky !important;
  transform: translate(0) !important;
  bottom: 20px;
  /* margin-left: 20px; */
  font-size: 50px;
  justify-content: flex-start;
}

/* .yes-results loader, .yes-results .loader__inner {
  width: 50px;
  height: 50px;
} */


/* Results Style  */
.result-item,
.result-item .bullet,
.result-item .text {
  transition: all 0.3s ease-in-out !important;
}

.result-item:hover {
  transform: translateY(-5px);
  background-color: var(--bs-primary-light) !important;
}

.result-item:hover > .bullet {
  background-color: var(--bs-primary) !important;
}

.result-item:hover .text {
  color: var(--bs-text-primary) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/ai/ai-idea-generator/ai-idea-generator.component.css"],"names":[],"mappings":"AAAA;;;GAGG;;;AAGH;EACE,2CAA2C;AAC7C;AACA;EACE,2CAA2C;EAC3C,0CAA0C;EAC1C,SAAS;EACT,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,kCAAkC;EAClC,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;;;GAGG;;;AAGH,mBAAmB;AACnB;;;EAGE,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,oDAAoD;AACtD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":["/* :root {\n  --size: 54px;\n  --speed:2s;\n} */\n\n\n.custom-title-button {\n  transition: all 0.5s ease-in-out !important;\n}\n.think-ai {\n  transition: all 0.5s ease-in-out !important;\n  transform: translate(-50%, 50%) !important;\n  left: 50%;\n  bottom: 50%;\n  z-index: 99;\n  font-size: 100px;\n}\n\n.inactive-result {\n  /* filter: blur(0.9px); */\n  opacity: 0.5;\n}\n\n.idea-item{\n  transition: all 0.5s ease-in-out !important;\n}\n\n.yes-results {\n  position: sticky !important;\n  transform: translate(0) !important;\n  bottom: 20px;\n  /* margin-left: 20px; */\n  font-size: 50px;\n  justify-content: flex-start;\n}\n\n/* .yes-results loader, .yes-results .loader__inner {\n  width: 50px;\n  height: 50px;\n} */\n\n\n/* Results Style  */\n.result-item,\n.result-item .bullet,\n.result-item .text {\n  transition: all 0.3s ease-in-out !important;\n}\n\n.result-item:hover {\n  transform: translateY(-5px);\n  background-color: var(--bs-primary-light) !important;\n}\n\n.result-item:hover > .bullet {\n  background-color: var(--bs-primary) !important;\n}\n\n.result-item:hover .text {\n  color: var(--bs-text-primary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
