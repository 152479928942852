// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-option-item.soon {
    opacity: 0.5;
    pointer-events: none;
}


.payment-option-item.active {
    border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/dynamic-checkbox-card/dynamic-checkbox-card.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;AACxB;;;AAGA;IACI,uBAAuB;AAC3B","sourcesContent":[".payment-option-item.soon {\n    opacity: 0.5;\n    pointer-events: none;\n}\n\n\n.payment-option-item.active {\n    border: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
