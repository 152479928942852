import { SharedProductService } from './../../shared-product.service';
import { Router } from '@angular/router';
import { AfterContentInit, Component, ContentChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.css']
})
export class ProductCreateComponent implements AfterContentInit{
  hasCustomTemplate: boolean = false;
  @ContentChild('customTemplate') public customTemplateRef: ElementRef;
  constructor(public sharedProductService: SharedProductService) {}


  ngAfterContentInit() {
    this.hasCustomTemplate = !!this.customTemplateRef;
  }
}
