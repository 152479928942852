export function timeAgo(value: any): string {
  let parsedDate: number = 0;
  let strValue = '';
  if (typeof value == 'number') {
    strValue = value.toString();
  }

  if (typeof value == 'string') {
    value = +value;
    strValue = value;
  }

    if (strValue.length === 10) {
      // Assume it's seconds, convert to milliseconds
      value = (+value * 1000);
    }

  

  if (value instanceof Date) {
    parsedDate = value.valueOf();
  } else if (typeof value === 'number') {
    parsedDate = new Date(value).valueOf();
  } else if (typeof value === 'string') {
    parsedDate = new Date(value).valueOf();

  } else {
    return `Invalid date ${parsedDate}`;
  }

  if (isNaN(parsedDate)) {
    return `Invalid date ${parsedDate}`;
  }


  const current = new Date().valueOf();
  

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - parsedDate;
  const returnValue: { count: number; label: string } = { count: 0, label: '' };

  if (elapsed < msPerMinute) {
    returnValue.count = Math.round(elapsed / 1000);
    if (returnValue.count > 30) {
      return 'About a minute';
    } else {
      return 'Just Now';
    }
  } else if (elapsed < msPerHour) {
    returnValue.count = Math.round(elapsed / msPerMinute);
    returnValue.label = returnValue.count > 1 ? 'minutes ago' : 'minute ago';
  } else if (elapsed < msPerDay) {
    returnValue.count = Math.round(elapsed / msPerHour);
    returnValue.label = returnValue.count > 1 ? 'hours ago' : 'hour ago';
  } else if (elapsed < msPerMonth) {
    returnValue.count = Math.round(elapsed / msPerDay);
    returnValue.label = returnValue.count > 1 ? 'days ago' : 'day ago';
  } else if (elapsed < msPerYear) {
    returnValue.count = Math.round(elapsed / msPerMonth);
    returnValue.label = returnValue.count > 1 ? 'months ago' : 'month ago';
  } else {
    returnValue.count = Math.round(elapsed / msPerYear);
    returnValue.label = returnValue.count > 1 ? 'years ago' : 'year ago';
  }

  return `${returnValue.count} ${returnValue.label}`;
}
