import { ProductItem } from './../../../../components/user-dashboard/product/models/product';
import { SharedProductService } from './../../../../components/user-dashboard/product/shared-product.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { disableOutAnimation, itemAnimation, listAnimation } from 'src/app/shared/animations/animations';
import { LocalPaginationConfig } from 'src/app/models/owner-dashboard';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    listAnimation,
    disableOutAnimation,
    itemAnimation
  ],
})
export class ProductsListComponent implements OnInit {
  @Input() productsList: ProductItem[] = [];
  @Input() loaded: boolean = false;

  // Pagination
  paginationConfig: LocalPaginationConfig = new LocalPaginationConfig({
    currentPage : 1,
    numberInPage : 8,
    startIndex : 0,
    endIndex : 8,
  })


  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdAt',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
    { displayName: 'A-Z Title', column: 'name', order: 'asc' },
    { displayName: 'Z-A Title', column: 'name', order: 'dsc' },
  ];

  constructor(
    public sharedProductService: SharedProductService,
    private router: Router
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  deleteProduct(product: ProductItem) {
    this.sharedProductService.deleteProductAlert(product);
  }

  cloneProduct(product: ProductItem) {
    this.sharedProductService.cloneProductAlert(product);
  }

  viewProduct(id: number) {
    this.router.navigate(['dashboard', 'product', id, 'view']);
  }

  editProduct(id: number) {
    this.router.navigate(['dashboard', 'product', id, 'edit']);
  }

  goToTutorials() {
    this.router.navigate(['/dashboard/tutorials']);
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}


}
