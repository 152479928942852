// reducers/products.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {   NewContactActions, ContactsActions,  updateContact } from './contacts.actions';
import { RefundItem } from '../../../../models/owner-dashboard';
import { forgetSession } from '../user/auth/auth.actions';
import { ContactItem } from 'src/app/components/user-dashboard/contacts/models/contacts';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';



export interface ContactsState extends EntityState<ContactItem> {
  loaded: boolean;
  error: any;

  createNewContactProcess: {status: SystemProcessLoadingStatus, error: any, lastCreatedContact?: ContactItem};
}

export const contactsAdapter = createEntityAdapter<ContactItem>();


const initialState: ContactsState = contactsAdapter.getInitialState({
  loaded: false,
  error: null,

  createNewContactProcess: {status: 'none', error: null, lastCreatedProduct: null},
});

export const contactsReducer = createReducer(
  initialState,
  on(ContactsActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(ContactsActions.loadSuccess, (state, { contacts }) => {
    return contactsAdapter.setAll(contacts, { ...state, loaded: true });
  }),
  on(ContactsActions.loadFailure, (state, { error }) => {
    if (typeof error !== 'string') {
      return state;
    }

    return { ...state, error, loaded: false };
  }),


  // Handle Create Contact
  on(NewContactActions.loadNewContact, (state, { createPayload }) => ({...state, createNewContactProcess: {status: 'creating', error: null} })),
  on(NewContactActions.loadSuccess, (state, { contact }) => {
    return contactsAdapter.addOne(contact, {...state, createNewContactProcess: {status: 'done', error: null, lastCreatedContact: contact} });
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(NewContactActions.loadFailure, (state, { error }) => {
    return {...state, createNewContactProcess: {status: 'error', error: error} };
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),


  // Handle other actions and state changes here
  on(updateContact, (state, { itemId, item}) => {
    return contactsAdapter.updateOne(
      {
        id: itemId,
        changes: { ...item },
      },
      state
    );
    // return { ...state, products: [product, ...state.products], loaded: true };
  }),
  on(forgetSession, (state) => initialState)
);
