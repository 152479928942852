import { forgetSession } from '../user/auth/auth.actions';
import { AccountSetupStep, AccountSetupStepStatusEnum } from './../../../../components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';
import { Action, createReducer, on } from '@ngrx/store';


import { NotificationElement } from '../../../../models/owner-dashboard';
import {   loadAccountSetupStepsActions, setStepInProgress, stepUpdate } from './account-setup.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface AccountSetupState extends EntityState<AccountSetupStep> {
  loaded: boolean;
  error: any;
}

export const accountSetupAdapter = createEntityAdapter<AccountSetupStep>();

const initialState: AccountSetupState = accountSetupAdapter.getInitialState({

  loaded: false,
  error: null,
});

export const accountSetupReducer = createReducer(
  initialState,
  on(loadAccountSetupStepsActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(loadAccountSetupStepsActions.loadSuccess, (state, { steps }) =>
  {
    return accountSetupAdapter.setAll(steps, { ...state, loaded: true });

}
  ),

  on(loadAccountSetupStepsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  on(stepUpdate, (state, { step }) => {

    return accountSetupAdapter.updateOne(
      {
        id: step.id,
        changes: { ...step },
      },
      state
    );

  }),


  on(setStepInProgress, (state, { step }) => {

    return accountSetupAdapter.updateOne(
      {
        id: step.id,
        changes: { status:  AccountSetupStepStatusEnum.IN_PROGRESS},
      },
      state
    );
  }),

  on(forgetSession, (state) => initialState)
);
