// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-item {
    transition: all 0.2s ease-in-out;
}
.plan-item:not(.active) {
    cursor: pointer;
    
    
}

.plan-item:not(.active):hover {
    transform: scale(1.03);
}

.plan-item.active {
    transform: translateY(-5px) scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/subscription-and-billing-page/plans/settings-plan-item/settings-plan-item.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA;IACI,eAAe;;;AAGnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".plan-item {\n    transition: all 0.2s ease-in-out;\n}\n.plan-item:not(.active) {\n    cursor: pointer;\n    \n    \n}\n\n.plan-item:not(.active):hover {\n    transform: scale(1.03);\n}\n\n.plan-item.active {\n    transform: translateY(-5px) scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
