import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export enum LearmoFeatureItemType {
  Fix = 'fix',
  New = 'new',
  UI = 'ui',
}

export class LearmoFeatureItem {
  title: string;
  description: string;
  type: LearmoFeatureItemType;

  constructor(title: string, description: string, type: LearmoFeatureItemType) {
    this.title = title;
    this.description = description;
    this.type = type;
  }
}

export class LearmoAppVersionStatus {
  status: string;
  version: string;
  changes: LearmoFeatureItem[];

  constructor(status: string, version: string, changes: LearmoFeatureItem[]) {
    this.status = status;
    this.version = version;
    this.changes = changes;
  }
}

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  latestVersion: string = '';
  featuresList: LearmoFeatureItem[] = [];
  private readonly versionUrl = 'assets/version.json';
  private readonly versionKey = 'learmo_app_version';
  private readonly featuresKey = 'learmo_app_features';

  constructor(private http: HttpClient) {}

  checkForUpdate(): Observable<{newVersion: boolean, newFeatures: boolean}> {
    const currentVersion = localStorage.getItem(this.versionKey);
    const currentFeatures = localStorage.getItem(this.featuresKey);

    return this.getVersionFromJson().pipe(
      tap((appVersionStatus) => {
        if (appVersionStatus && appVersionStatus.version) {
          this.latestVersion = appVersionStatus.version;
          this.featuresList = appVersionStatus.changes;
          localStorage.setItem(this.versionKey, appVersionStatus.version);
          localStorage.setItem(this.featuresKey, JSON.stringify(appVersionStatus.changes));
        }
      }),
      map((appVersionStatus) => {
        return {newVersion: !!currentVersion && appVersionStatus && appVersionStatus.version !== currentVersion, newFeatures: appVersionStatus && JSON.stringify(appVersionStatus.changes) != currentFeatures};
      })
    );
  }

  private getVersionFromJson(): Observable<LearmoAppVersionStatus> {
    const headers = new HttpHeaders().append("disableInterceptor","disable")
    return this.http.get<LearmoAppVersionStatus>(this.versionUrl, {headers}).pipe(
      catchError(() => of({ status: 'healthy', version: null, changes: [] })),
      map((response) => ({
        status: 'healthy',
        version: response.version,
        changes: response.changes.map(
          (change) =>
            new LearmoFeatureItem(
              change.title,
              change.description,
              change.type
            )
        ),
      }))
    );
  }
}
