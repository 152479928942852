import { Action, createReducer, on } from '@ngrx/store';
import { WebsiteModel } from '../../../../components/user-dashboard/portal/portal.model';

import { NotificationElement } from '../../../../models/owner-dashboard';


import { forgetSession } from '../user/auth/auth.actions';
import { PaymentMethodModel } from '../../../../models/payment.model';
import { UserPaymentCardsActions } from './payments.actions';


export interface PaymentsState {
  userPaymentCards: PaymentMethodModel[];
  loaded: boolean;
  error: any;
}

const initialState: PaymentsState = {
  userPaymentCards: [],
  loaded: false,
  error: null,
};

export const paymentsReducer = createReducer(
  initialState,

  // System Plans
  on(UserPaymentCardsActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(UserPaymentCardsActions.loadSuccess, (state, { cards }) => {
    return     {
      ...state,
      userPaymentCards: cards,
      loaded: true,
    }
  }),

  on(UserPaymentCardsActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),




  on(forgetSession, (state) => initialState)
);
