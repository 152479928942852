import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-paypal-integration-listener',
  templateUrl: './paypal-integration-listener.component.html',
  styleUrls: ['./paypal-integration-listener.component.css']
})
export class PaypalIntegrationListenerComponent implements OnInit {
  paypalStorageKey = environment.storageKeys.paypalIntegration;
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private api: ApiService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // Access the query parameter value
      let email = params['merchantId'];
      if (!!email) {
        this.onboardingEmailIsHere(email);
      }
    }); 
  }


  async onboardingEmailIsHere(email: string) {
    const data = localStorage.getItem(this.paypalStorageKey);
    if (!!data && !!email) {
      let parsed: {
        authCode: string;
        sharedId: string;
        nonce: string;
        email: string;
      } = JSON.parse(data);
      parsed.email = email;
      localStorage.setItem(this.paypalStorageKey, JSON.stringify(parsed))

      let request$ = this.api.sendPaypalData(parsed);
      let response = await lastValueFrom(request$);
      localStorage.removeItem(this.paypalStorageKey);
      this.toastr.success('Paypal Integration Done');
      // this.sharedPortalViewService.getPaymentMethods()

      
    }
  }

}
