import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tip',
  templateUrl: './info-tip.component.html',
  styleUrls: ['./info-tip.component.css'],
})
export class InfoTipComponent implements OnInit {
  @Input() text: string = '';
  @Input() color: string = 'muted';
  @Input() colorHover: string = 'dark';
  constructor() {}

  ngOnInit(): void {}
}
