import { Plan } from "./plans";

export enum Subscriptions_State_Enum {
  active = 'active',
  grace = 'grace',
  canceled = 'canceled',
  expired = 'expired',
  suspended = 'suspended',
  pending = 'pending',
  trial_active = 'trial_active',
  trial_expired = 'trial_expired',
  canceled_trial = 'canceled_trial',
  canceled_active = 'canceled_active',
  suspended_refunded = 'suspended_refunded',
  suspended_unrefunded = 'suspended_unrefunded',
  upgraded = 'upgraded'
}

export class SubscriptionData {
  public readonly currentSubscriptionType: string;
  public readonly endDate: number;
  public readonly startDate: number;
  public readonly graceEndDate?: number | undefined;
  trialEndDate: number;
  subscription_id: string;
  renewal_type: BundleRenewalType;
}

export enum BundleRenewalType {
  annual = 'annual',
  monthly = 'monthly',
  lifetime = 'lifetime',
}
// export class BundleData {
//   public readonly bundleID: number;
//   public readonly bundleName: string;
//   public readonly annual: boolean;
//   public readonly fee: number;
// }

export class UpgradeCalculationsData {
    public readonly remainingDaysValueCurrentPlan: number;
    public readonly priceBeforeSubtraction: number;
    public readonly requiredAmount: number;
    public readonly currentEndDate: number;
    public readonly newEndDate: number;
    public readonly newPlanPrice: number;
}

export class SubscriptionBillingInfo  {
  public readonly subscriptionData: SubscriptionData;
  public readonly planData: Plan;
}

export class NumbersOfDomainsOrPortalsForUser{
  public readonly userCurrentCount: number;
  public readonly availableCount: number;
}


export class NumbersOfDomainsAndPortalsForUser{
  public readonly portals: LimitsForUser;
  public readonly domains: LimitsForUser;
}

export class LimitsForUser{
  public readonly current: number;
  public readonly max: number;
}

export class CancelAccountReasons{
  // public readonly id: number;
  public readonly problem: string;
  public readonly solution: string;
}
