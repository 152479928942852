import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

export class SystemSequenceItem {
  label: string;
  type: SystemItemTypeEnum;
}
export enum SystemItemTypeEnum {
  product = 'product',
  offer = 'offer',
  page = 'page',
  email = 'email'
}
@Component({
  selector: 'app-system-items-sequence',
  templateUrl: './system-items-sequence.component.html',
  styleUrls: ['./system-items-sequence.component.css'],
})
export class SystemItemsSequenceComponent implements OnInit, AfterViewInit {
  systemItemTypeEnum = SystemItemTypeEnum;
  @Input() sequence: SystemSequenceItem[];

  @Input() firstItem: boolean = false;
  constructor() {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
