import { Component, OnDestroy, OnInit } from "@angular/core";
import { Chat, ChatService } from "../../services/chat.service";
import { KeyboardShortcutService } from "../../services/keyboardshortcut.service";

@Component({
  selector: "app-chat-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class ChatSidebarComponent implements OnInit, OnDestroy {
    

  constructor(
    public chatService: ChatService,
    private keyboardShortcutService: KeyboardShortcutService
  ) {
    // chatService.getChats().subscribe((chats) => {
    //   this.chats = chats;
    //   this.chatGroups = this.groupChatsByDate(this.chats);
    // });
    // chatService.currentChat.subscribe((chat) => {
    //   this.currentChat = chat;
    // });
  }

  ngOnInit(): void {
    this.keyboardShortcutService.registerShortcut(
      { key: "t", ctrlKey: true },
      () => this.createNewChat()
    );

    this.keyboardShortcutService.registerShortcut(
      { key: "w", ctrlKey: true },
      () => this.deleteCurrentChat()
    );
  }

  ngOnDestroy(): void {
    this.keyboardShortcutService.unregisterShortcut({
      key: "KeyT",
      ctrlKey: true,
    });
  }

  deleteCurrentChat() {
    this.deleteChat(this.chatService.currentChat);
  }

  deleteChat(chat: Chat) {
    this.chatService.deleteChat(chat.id);

    // Refreshing chat groups
    this.chatService.refreshChats();

    // If the current chat was the deleted one, switch to another chat if available
    if (this.chatService.currentChat?.id === chat.id && this.chatService.chats.length) {
      this.chatService.selectChat(this.chatService.chats[0]);
    }
  }



  



 

  createNewChat() {
    this.chatService.addChat("New Chat", '', true);
  }
}
