import { UtilitiesService } from './../../../../../shared/services/utilities.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

import { ApiService } from '../../../../../shared/services';
import { MinimizedPortalModel } from '../../../portal/dto/portal.output';
import { SharedOfferService } from '../../shared-offer.service';
import { OfferItem } from '../../models/offer';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-get-offer-link',
  templateUrl: './get-offer-link.component.html',
  styleUrls: ['./get-offer-link.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class GetOfferLinkComponent implements OnInit {
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() offerUrl: string;
  @Input() offer: OfferItem
  copying = false;

  shareUrls = {
    facebook: null,
    twitter: null,
    instagram: null,
    whatsapp: null,
    telegram: null
  }
  // offerUrl: string = null;

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private utilitiesService: UtilitiesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.buildShareUrls()
  }

  buildShareUrls() {
    this.shareUrls.facebook = this.sanitizer.bypassSecurityTrustUrl(`https://www.facebook.com/sharer/sharer.php?u=${this.offerUrl}`);
    this.shareUrls.twitter =  this.sanitizer.bypassSecurityTrustUrl(`https://twitter.com/intent/tweet?text=🚀 Exciting News! Just launched my latest course "${this.offer.title}", Enroll now:&url=${this.offerUrl}`);
    // this.shareUrls.instagram =  this.sanitizer.bypassSecurityTrustUrl(`https://www.instagram.com/create/?caption=${this.offer.title}&media_url=${this.offer.image[0].url}`);
    this.shareUrls.whatsapp =  this.sanitizer.bypassSecurityTrustUrl(`https://wa.me/?text=Exciting News! Just launched my latest course "${this.offer.title}", Enroll now:&url=${this.offerUrl}`);
    this.shareUrls.telegram =  this.sanitizer.bypassSecurityTrustUrl(`https://t.me/share/url?url=${this.offerUrl}&text=🚀 Exciting News! Just launched my latest course "${this.offer.title}", Enroll now:`);
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  async afterOpenModal() {
    // const urlReuqest$ = this.sharedOfferService.getOfferUrl();
    // const urlRequest = await lastValueFrom(urlReuqest$);
    // this.offerUrl = this.sharedOfferService.getOfferUrl();
  }

  async onOpenModal() {}

  copyText() {
    this.copying = true;
    this.utilitiesService.copyToClipboard(this.offerUrl);

    setTimeout(() => {
      this.copying = false;
      this.toastr.success('Done!', 'URL copied to clipboard', {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      });
    }, 600);
  }

  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: true,
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
