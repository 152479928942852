import { LoginMethodsEnum } from "src/app/components/auth/login-alt/user-login-form-alt/user-login-form-alt.component";
import { LoginMethodStatusEnum, LoginMethodUIItem } from "src/app/components/profile/security-settings/login-methods/login-methods.component";

export const loginMethodsUIBase: LoginMethodUIItem[] = [
  //   {
  //     displayName: 'Standard Login (Email & Password)',
  //     enum: LoginMethodsEnum.email,
  //     description: 'Traditional login using email and password for secure access.',
  //     icon: 'lock', // You can choose an appropriate icon
  //     soon: false,
  //     enabled: LoginMethodStatusEnum.enabled,
  //     default: true
  // },
  {
    displayName: 'Email Magic URL',
    enum: LoginMethodsEnum.magic_link,
    description: `We'll send you a special link through email for a secure login without needing a password.`,
    icon: 'abstract-21',
    soon: false,
    enabled: LoginMethodStatusEnum.disabled,
  },
  {
    displayName: 'SMS Two-Factor Authentication',
    enum: LoginMethodsEnum.phone,
    description: `We will send a code via SMS if you need to use your backup login method.`,
    icon: 'sms',
    soon: true,
    enabled: LoginMethodStatusEnum.ineligible,
  },
//   {
//     displayName: 'Authenticator Apps',
//     enum: LoginMethodsEnum.authenticator,
//     description: `Get codes from an app like Google Authenticator, Microsoft Authenticator, Authy or 1Password.`,
//     icon: 'key-square',
//     soon: true,
//     enabled: false,
//   }
]