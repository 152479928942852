import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethodModel } from 'src/app/models/payment.model';

@Component({
  selector: 'app-payment-cards-view-list',
  templateUrl: './payment-cards-view-list.component.html',
  styleUrls: ['./payment-cards-view-list.component.css']
})
export class PaymentCardsViewListComponent implements OnInit {
  @Input() isChangingTheCard: boolean = false;
  @Output() isChangingTheCardChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  selectedItem: PaymentMethodModel;
  @Output() selectedItemChange: EventEmitter<PaymentMethodModel> = new EventEmitter<PaymentMethodModel>();

  userPaymentCards: PaymentMethodModel[] = [];

  @Input('userPaymentCards') set setUserPaymentCards(value: PaymentMethodModel[]) {
    this.userPaymentCards = value;
    this.userPaymentCards.forEach(card => {
      if (card.is_default) {
        this.onCardClicked(card);
        // this.changeCardMode(true);
      }
    })
  }

  constructor() { }

  ngOnInit() {
  }


  onCardClicked(item: PaymentMethodModel) {
    this.selectedItem = item;
    this.selectedItemChange.emit(item);
    this.changeCardMode(false);
  }


  changeCardMode(mode: boolean) {
    this.isChangingTheCard = mode;
    this.isChangingTheCardChange.emit(mode);
  }
}
