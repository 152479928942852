const ICONS = {
    category_name: 'Grid',
    icons_list: [
      "grid",
      "slider-vertical-2",
      "maximize",
      "slider-vertical",
      "row-horizontal",
      "kanban",
      "row-vertical",
      "fat-rows",
      "grid-2",
      "element-8",
      "element-9",
      "element-12",
      "element-4",
      "element-5",
      "grid-frame",
      "element-11",
      "element-7",
      "element-6",
      "element-10",
      "element-2",
      "element-3",
      "element-equal",
      "element-1",
      "slider-horizontal-2",
      "slider-horizontal",
      "element-plus"


    ],

};


export default ICONS
