import { Injectable } from '@angular/core';

// import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor() {}

  getAll() {
    // return this.http.get<User[]>(`${config.apiUrl}/users`);
  }
}
