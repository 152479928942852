import { EditorConfigService } from './../../../../../shared/services/editor-config.service';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../../shared/services/lsg.api.service';
import {
  CreateAnnouncementRequest,
} from '../../../../../models/owner-dashboard';
import { SharedAffiliateService } from '../../../affiliate/shared-affiliate.service';
import {
  AnnouncementObject,
  AffiliateMinimizedModel,
} from '../../../../../models/owner-dashboard';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { FilteringTypeModel } from '../../../../../models/portal';
import { AffiliateLinkMinimizedModel } from '../../../../../models/owner-dashboard';
import { lastValueFrom } from 'rxjs';

export enum StepModelEnum {
  step1 = 'step1',
  step2 = 'step2',
}

export enum FilterValuesEnum {
  affiliate = 'affiliate',
  link = 'link',
  offer = 'offer',
}
@Component({
  selector: 'app-announcement-create-popup',
  templateUrl: './announcement-create-popup.component.html',
  styleUrls: ['./announcement-create-popup.component.css'],
})
export class AnnouncementCreatePopupComponent implements OnInit {
  filterValuesEnum = FilterValuesEnum;
  stepModelEnum = StepModelEnum;
  stepModel: StepModelEnum = StepModelEnum.step1;

  createAnnouncementForm = new UntypedFormGroup({
    title: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    content: new UntypedFormControl('', {
      updateOn: 'change',
      validators: [Validators.required],
    }),
  });

  filterObject: FilteringTypeModel = null;

  filterTypes: FilteringTypeModel[] = [
    {
      index: 1,
      displayName: 'By Affiliate',
      filters: [{column: 'affiliate', value: FilterValuesEnum.affiliate}],
    },
    {
      index: 2,
      displayName: 'By Affiliate Link',
      filters: [{column: 'affiliate', value: FilterValuesEnum.link}],
    },
  ];

  // {
  //   index: 3,
  //   displayName: 'By Offer',
  //   value: FilterValuesEnum.offer,
  //   column: null,
  // },

  error = {
    step1: false,
    step2: false,
  };

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;

  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    public editorConfig: EditorConfigService
  ) {}

  async ngOnInit() {}

  async createNewAnnouncement() {
    this.createAnnouncementForm.markAllAsTouched();
    if (this.createAnnouncementForm.valid) {
      await this.sharedAffiliateService.createNewAnnouncement(
        this.sharedAffiliateService.createAnnouncement.getRequestValues()
      );
      this.onAnnouncementCreateDone();
    }
  }

  setMediaFile(mediaId: number[]) {
    if (!!mediaId?.length) {
      this.sharedAffiliateService.createAnnouncement.mediaId = mediaId[0];
    } else {
      this.sharedAffiliateService.createAnnouncement.mediaId = null;
    }
  }

  onAnnouncementCreateDone() {
    this.modalRef.dismiss();
    this.toastr.success('Done!', 'Announcement Created Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  async onToggleAffiliate(affiliate: AffiliateMinimizedModel) {
    this.sharedAffiliateService.createAnnouncement.toggleAffiliate(affiliate);
    this.error.step1 = false;
  }

  async onToggleAffiliateLink(affiliateLink: AffiliateLinkMinimizedModel) {
    this.sharedAffiliateService.createAnnouncement.toggleAffiliateLink(
      affiliateLink
    );
    this.error.step1 = false;
  }

  async filterTypeChange(filter: FilteringTypeModel) {
    if (filter.filters[0]?.value !== this.filterObject.filters[0]?.value) {
      this.filterObject = filter;
      this.sharedAffiliateService.startInternalLoading();

      switch (filter?.filters[0]?.value) {
        case FilterValuesEnum.affiliate:
          this.sharedAffiliateService.createAnnouncement.clearAffiliateLinks();
          await this.sharedAffiliateService.getMyAffiliates('joined');
          this.sharedAffiliateService.stopInternalLoading();
          break;

        case FilterValuesEnum.link:
          this.sharedAffiliateService.createAnnouncement.clearAffiliate();
          await this.sharedAffiliateService.getMyAffiliateLinks(true);
          this.sharedAffiliateService.stopInternalLoading();
          break;
        case FilterValuesEnum.offer:
          break;
        default:
          break;
      }
    }
  }

  goToStep2() {
    if (
      !!this.sharedAffiliateService.createAnnouncement.affiliateIds.length ||
      !!this.sharedAffiliateService.createAnnouncement.affiliateLinkIds
        .length ||
      !!this.sharedAffiliateService.createAnnouncement.offerIds.length
    ) {
      this.stepModel = StepModelEnum.step2;
    } else {
      this.error.step1 = true;
    }
  }
  async goNext() {
    if (this.stepModel == StepModelEnum.step1) {
      this.goToStep2();
    } else if (this.stepModel == StepModelEnum.step2) {
      await this.createNewAnnouncement();
    }
  }

  closeModal() {
    this.modalRef.dismiss();
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in sales-item-modal',
    });
  }

  async resetForm() {
    this.sharedAffiliateService.createAnnouncement =
      new CreateAnnouncementRequest();
  }
  async onOpenModal() {
    this.resetForm();
    await this.filterTypeChange(this.filterTypes[0]);
    // this.productObject.type_id = 1;
  }

  // FileUpload
}
