// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 100px;
  height: 100px;
}

.loader__inner {
  position: absolute;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 7.5px -5px 0 0 #0dfde9d1, -7.5px -5px 0 0 #0d6efda8,
    0 10px 0 0 #0dfde9d1;
  animation: rotate 2s linear infinite;
}
.loader__inner:nth-child(2) {
  box-shadow: 0 -10px 0 0 #0d6efd, -7.5px 5px 0 0 #0dfde9d1,
    7.5px 5px 0 0 #0d6efd;
  animation: rotate__other 2s linear infinite;
}
@keyframes rotate {
  50% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(720deg);
  }
}

@keyframes rotate__other {
  50% {
    transform: scale(1.2) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(-720deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/product/create/product-create-wizard/product-description-image-wizard-step/product-description-image-wizard-step.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB;wBACsB;EACtB,oCAAoC;AACtC;AACA;EACE;yBACuB;EACvB,2CAA2C;AAC7C;AACA;EACE;IACE,oCAAoC;EACtC;EACA;IACE,kCAAkC;EACpC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA;IACE,mCAAmC;EACrC;AACF","sourcesContent":[".loader {\n  width: 100px;\n  height: 100px;\n}\n\n.loader__inner {\n  position: absolute;\n  display: block;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  box-shadow: 7.5px -5px 0 0 #0dfde9d1, -7.5px -5px 0 0 #0d6efda8,\n    0 10px 0 0 #0dfde9d1;\n  animation: rotate 2s linear infinite;\n}\n.loader__inner:nth-child(2) {\n  box-shadow: 0 -10px 0 0 #0d6efd, -7.5px 5px 0 0 #0dfde9d1,\n    7.5px 5px 0 0 #0d6efd;\n  animation: rotate__other 2s linear infinite;\n}\n@keyframes rotate {\n  50% {\n    transform: scale(1.1) rotate(360deg);\n  }\n  100% {\n    transform: scale(1) rotate(720deg);\n  }\n}\n\n@keyframes rotate__other {\n  50% {\n    transform: scale(1.2) rotate(-360deg);\n  }\n  100% {\n    transform: scale(1) rotate(-720deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
