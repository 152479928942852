import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-messenger-chat-popup',
  templateUrl: './messenger-chat-popup.component.html',
  styleUrls: ['./messenger-chat-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MessengerChatPopupComponent implements OnInit {
  @Input() modalRef: NgbModalRef;

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  async open(content?) {
    // this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      backdrop: false,

      animation: false,
windowClass: 'global-modal-class modal-animate-in messenger-modal',
    });
  }
}
