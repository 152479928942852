import { BehaviorSubject } from 'rxjs';
import { AiService } from './../../../services/ai.service';

// ai-idea-processor.ts
import { AiCourseCategory, CurrentIdeationStepEnum, AiGeneratorComponentData, LocalDataObject } from './ai-generator.models';

import { ToastrService } from 'ngx-toastr';



export class AiIdeaProcessor {
    _componentData: BehaviorSubject<AiGeneratorComponentData>;

    componentData: AiGeneratorComponentData = {

        results: [],
        currentIdeationStep: CurrentIdeationStepEnum.category,
        selectedResultIndex: null,
        writingOwn: false,
        selectedCategories: [],
        localGeneratorData: { entering: 'title', index: null, generating: false, resultsSync: 0, lastChar: '' }
    };

    ai: AiService;
    toastr: ToastrService;
    generating: boolean = false;
    resultsSync: number = 0;

    promptFunction: (...args) => string = () => `Generate five modern fancy creative course titles ideas formatted as 'title (category)', do not say anything after that:`;
    constructor(initialConfiguration: { promptFunction?: (...args) => string, componentData: AiGeneratorComponentData }, aiGeneratorService: AiService) {
        console.log('constructor processor initialized', initialConfiguration);
        this.ai = aiGeneratorService;
        this._componentData = new BehaviorSubject<AiGeneratorComponentData>(this.componentData);
        this.promptFunction = initialConfiguration.promptFunction;
        if (!!initialConfiguration?.componentData && !!initialConfiguration?.componentData?.results?.length) {
            this.componentData = JSON.parse(JSON.stringify(initialConfiguration.componentData));
            this.emitComponentDataChange();
        }
    }


    emitComponentDataChange() {
        this._componentData.next(this.componentData);
        console.log("Changes Emitted With : ", this.componentData.results.length);
    }
    async generateATitleRequest(categories: AiCourseCategory[]) {

        this.changeCurrentStep(CurrentIdeationStepEnum.thinking);
        let catMap = categories.map(a => a.label).join();
        this.generating = true;

        const prompt = this.promptFunction();
        this.resultsSync++;

        this.ai.getCompletion(prompt).subscribe({
            next: (response: string) => {
                this.generating = true;
                let splitList = response.split('\n');

                if (splitList.length > 3) {
                    this.processBulkOfTitles(splitList);
                } else {
                    this.processStreamPiece(response);
                }
                this.emitComponentDataChange();
            },
            error: (error) => {
                // Handle the error
                this.generating = false;
            },
            complete: () => {
                this.processFinishedTitle();
                this.componentData.results = this.componentData.results.filter(a => a.value.length > 3);
                this.generating = false;
                this.resultsSync--;
                this.emitComponentDataChange();
            },
        });
    }

    changeCurrentStep(step: CurrentIdeationStepEnum) {
        // this.disableAnimations = true;
        this.componentData.currentIdeationStep = step;
        this.emitComponentDataChange();
        setTimeout(() => {
            // this.disableAnimations = false;
        }, 500);
    }

    processBulkOfTitles(response: string[]) {
        response.forEach((line) => {
            if (line == '' || line == ' ' || line == '\n') {
                return false;
            }
            const regex = /^(.*?)\s*\((.*?)\)$/; // Regular expression pattern

            const matches = line.match(regex); // Matching the pattern with the string

            if (matches) {
                const part1 = matches[1].trim(); // "1. "The Art and Science of Wellness: A Multidisciplinary Approach""
                const part2 = matches[2]; // "(Health and Fitness)"
                this.componentData.results = this.componentData.results.concat([
                    {
                        category: part2,
                        value: part1,
                        processing: true,
                    },
                ]);
            } else {
                this.componentData.results = this.componentData.results.concat([
                    {
                        category: '',
                        value: line,
                        processing: true,
                    },
                ]);
            }
            this.processFinishedTitle(this.componentData.results.length - 1);
        });

        this.changeCurrentStep(CurrentIdeationStepEnum.results);
    }

    processStreamPiece(response, data: LocalDataObject = this.componentData.localGeneratorData) {

        const completions: string = response;

        if (
            (completions.includes('\n') ||
                completions.includes(')') ||
                completions.includes(']')) &&
            (completions.includes('(') || completions.includes('['))
        ) {
            let primarySplitChar = '(';

            let secondarySplitChar = '\n';

            // Prepare Primary Split Char
            if (completions.includes('[')) {
                primarySplitChar = '[';
            }

            // Prepare Secondary Split Char
            if (completions.includes(')')) {
                secondarySplitChar = ')';
            } else if (completions.includes(']')) {
                secondarySplitChar = ']';
            }

            const [beforePrim, afterPrim] = completions.split(primarySplitChar);
            this.appendValue(data, beforePrim);

            const [beforeSec, afterSec] = afterPrim.split(secondarySplitChar);
            this.enterCategory(data);
            this.appendValue(data, beforeSec);
            if (!!afterSec?.length) {
                this.newTitle(data, afterSec);
            }

            console.error('Special Case');
        } else if (
            completions.includes('\n') ||
            completions.includes(')') ||
            completions.includes(']')
        ) {
            let splitChar = '\n';
            if (completions.includes(')')) {
                splitChar = ')';
            } else if (completions.includes(']')) {
                splitChar = ']';
            }
            const [before, after] = completions.split(splitChar);
            this.appendValue(data, before);
            if (after && !after.includes(')')) {
                this.newTitle(data, after, `after && !after.includes(')')`);
            } else {
                this.processFinishedTitle(data.index);
                data.index = null;
            }
        } else if (completions.includes('(') || completions.includes('[')) {
            let splitChar = '(';
            if (completions.includes('[')) {
                splitChar = '[';
            }
            const [before, after] = completions.split(splitChar);
            this.appendValue(data, before);
            this.enterCategory(data);
            this.appendValue(data, after.replace(')', '').replace(']', ''));
        } else {
            if (completions != '\n') {
                if (data.index == null) {
                    this.newTitle(
                        data,
                        completions,
                        `completions != '\n' && !data?.index`
                    );
                } else {
                    this.appendValue(data, completions);
                }
            }
        }

        data.lastChar = completions;
        this.changeCurrentStep(CurrentIdeationStepEnum.results);
    }

    enterCategory(data: {
        entering: 'title' | 'category';
        lastChar: string;
        index: number;
    }) {
        data.entering = 'category';
        this.componentData.results[data.index].writing = 'category';
    }

    newTitle(
        data: { entering: 'title' | 'category'; lastChar: string; index: number },
        completions: string,
        source: string = 'none'
    ) {
        completions = completions.replace('\n', '')
        this.processFinishedTitle(data.index);
        console.table({
            index: data.index,
            completions: completions,
            source: source,
        });
        data.entering = 'title';

        data.index =
            this.componentData.results.push({
                value: completions,
                writing: 'title',
                category: '',
                skipCharacter: 0,
                processing: true
            }) - 1;

        // this.scrollTo(`title-result-${data.index}`);
    }

  

    appendValue(
        data: { entering: 'title' | 'category'; lastChar: string; index: number },
        completions: string
    ) {
        completions = completions.replace('\n', '')
        if (data.index == null) {
            return false;
        }
        if (data.entering == 'title') {
            this.componentData.results[data.index].value += completions;
        } else {
            this.componentData.results[data.index].category += completions;
        }
    }

    processFinishedTitle(index: number = this.componentData.results.length - 1) {
        if (index != null && !!this.componentData.results[index].value) {
            const regex = /['"]+|\d+\.\s+/g;

            this.componentData.results[index].value = this.componentData.results[
                index
            ].value.replace(regex, '');
            this.componentData.results[index].writing = null;
            this.componentData.results[index].processing = false;
        }
    }


    resetTheProcess() {
        
    this.componentData = {
        results: [],
        currentIdeationStep: CurrentIdeationStepEnum.category,
        selectedResultIndex: null,
        writingOwn: false,
        selectedCategories: [],
        localGeneratorData: { entering: 'title', index: null, generating: false, resultsSync: 0, lastChar: '' }
      };
      this.emitComponentDataChange();
    }

}
