import { Payment_Gateway_Enum, Payout_Gateway_Enum } from './portal';
import {
  DomainCheckStatusEnum,
  CheckPageViewStatusEnum,
} from './../components/user-dashboard/domains/purchase/steps/domain-purchase-summary/domain-purchase-summary.component';
import {
  PaymentServiceInterface,
  PaymentServiceInterfaceTypeEnum,
} from './payment.model';

export class DomainItem {
  public id: number;
  public info: MiniDomainModel;
  public purchaseInfo: DomainPurchaseInfo;
  public portalInfo?: MiniPortalItem;
  public status: string;
  public actionsLog?: DomainActionsLog[];
  public renewalDate: number;
  public createdAt: number;
  public isDefault: boolean;
  public process: {
    isProcessing: boolean;
    description: string;
    timeout: any;
  };
}

export enum ActionTypeEnum {
  transaction = 'transaction',
  link_with_portal = 'link_with_portal',
  unlink_from_portal = 'unlink_from_portal',
  status_change = 'status_change',
  process = 'process',
}
export class DomainActionsLog {
  type: ActionTypeEnum;
  description: string;
}

export class MiniPortalItem {
  public siteId: number;
  public portalName: string;
  public isFree: boolean;
  public domain: MiniDomainModel;
  public siteUrl: string;
}

export class DomainPurchaseInfo {
  paymentType: Payment_Gateway_Enum;
  renewalPrice: string;
  price: string;
  transactionDate: number;
  transactionId: string;
}

export class DomainWizardData {
  iAgree: boolean = false;
  domainResult: DomainCheckResponseData;
  paymentService: PaymentServiceInterface;
  selectedCard: string;
}

export class DomainWizardSettings {
  domainCheckStatus: DomainCheckStatusEnum;
  checkPageViewStatus: CheckPageViewStatusEnum;
  finalStepLabel: string;
}

export class DomainCheckResponseData {
  current: DomainCheckData;
  alternatives: DomainCheckData[];
}

export class DomainCheckData {
  domain: MiniDomainModel;
  result: DomainResult;
}

export class MiniDomainModel {
  name?: string;
  tld?: string;
}

export class DomainResult {
  public exist: boolean;
  public free: boolean;
  public isOwner?: boolean;
  public isAvailable?: boolean;
  public price?: {
    duration: number;
    price: number;
    priceInBtc: number;
    realPrice: number;
  };
}
