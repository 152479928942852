import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class SystemColorItem {
  hex: string
}
@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.css']
})
export class ColorSelectComponent implements OnInit {
  @Input() enableCustomColor = true;
  @Input() currentSelectedColor: string;
  @Output() currentSelectedColorChange = new EventEmitter<string>();
  colors : string[] = [
     '#ff3b30'
    ,
     '#ff9500'
    ,
     '#ffcc00'
    ,
     '#4cd964'
    ,
     '#34aadc'
    ,
     '#007aff'
    ,
     '#5856d6'
    ,
     '#ff2d55'

  ];

  constructor() { }

  ngOnInit() {
  }


  onSelectColor(color: string) {
    this.currentSelectedColor = color;
    this.currentSelectedColorChange.emit(color);

  }
}
