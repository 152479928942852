import { Component, OnInit } from '@angular/core';
import { LearmoFeatureItemType, VersionCheckService } from 'src/app/shared/services/version-check.service';

@Component({
  selector: 'app-new-version-announcement',
  templateUrl: './new-version-announcement.component.html',
  styleUrls: ['./new-version-announcement.component.css']
})
export class NewVersionAnnouncementComponent implements OnInit {
  learmoFeatureItemType = LearmoFeatureItemType;
  showUpdateNotification = false;
  isThereNewFeatures: boolean = false;
  updateInterval: any;

  constructor(public versionCheckService:VersionCheckService ) {}

  ngOnInit(): void {
    this.checkForUpdate();
    this.updateInterval = setInterval(() => {
      this.checkForUpdate();
    }, 60000);
  }


  checkForUpdate = () => {
    this.versionCheckService.checkForUpdate().subscribe((updateAvailable) => {
      // if (!!updateAvailable.newVersion) {
      //   clearInterval(this.updateInterval);
      // }
      this.showUpdateNotification = updateAvailable.newVersion;
      this.isThereNewFeatures = updateAvailable.newFeatures;
    });
  };

  dismiss() {
    this.showUpdateNotification = false;
    window.location.reload();
  }
  
}
