import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ListThumbnailPreset = 'list' | 'head' | 'notification' | 'minimal-head' | 'info-card' | string;
@Component({
  selector: 'app-list-thumbnail',
  templateUrl: './list-thumbnail.component.html',
  styleUrls: ['./list-thumbnail.component.css'],
})
export class ListThumbnailComponent implements OnInit {
  @Input() itemType: 'offer' | 'product' | 'page' | 'post' | 'user' | 'notification' = 'product';
  _currentTypeSvg: string;
  src: any;
  @Input('src') set ImageSource(value: any) {
    if (!!value && value != this.src && value?.length != 0) {
      this.src = value
    }
    else {
      this._imageLoaded = true;
    }
    
  }

  @Input() forceSkeletonLoader: boolean = false;
  @Input() borderRadius: string = '8px';
  @Input() width: number;
  @Input() height: number;
  @Input() aspectRatio: string;
  @Input('preset') set setPresetValue(preset: ListThumbnailPreset) {
    this.preset = preset;
    this.setPreset(preset);
  }

  
  preset: ListThumbnailPreset = 'list';
 
  

  @Input() imageClass: string;
  @Input() imageStyle: { [key: string]: string };
  @Input() imageAlt: string = 'Thumbnail';

  _imageLoaded: boolean = false;

  @Output() imageLoaded = new EventEmitter<boolean>();
  @Output() imageError = new EventEmitter<Error>();
  constructor() {}

  ngOnInit() {
    this.setPreset();
    this._currentTypeSvg = this.getSvgForItemType()
  }

  setPreset(preset = this.preset) {
    switch (preset) {
      case 'list':
        this.width = 125;
        this.height = 75;
        this.aspectRatio = `${this.width / this.height}`;
        break;
      case 'head':
        this.width = 100;
        this.height = 60;
        this.aspectRatio = `${this.width / this.height}`;
        break
        case 'notification':
        this.width = 50;
        this.height = 50;
        this.aspectRatio = `${this.width / this.height}`;
        break;
        case 'minimal-head':
        this.width = 100;
        this.height = 60;
        this.aspectRatio = `${this.width / this.height}`;
        break;
        case 'info-card':
          this.width = 250;
          this.height = 150;
          this.aspectRatio = `${this.width / this.height}`;
          break;
      default:
        return 'default_icon_url';
        break;
    }
  }

  getSvgForItemType(): string {
    switch (this.itemType) {
      case 'offer':
        return 'assets/images/svg/system/core/offer.svg';
      case 'product':
        return 'assets/images/svg/system/core/product.svg';
      case 'page':
        return 'assets/images/svg/system/core/page.svg';
        case 'post':
        return 'assets/images/svg/system/core/post.svg';
        case 'notification':
          return 'assets/images/svg/system/notification.svg';
      default:
        return 'default_icon_url';
    }
  }

  onImageLoad() {
    this._imageLoaded = true;
    this.imageLoaded.emit(true);
  }

  onImageError(error: any) {
    this.imageError.emit(error);
  }
}
