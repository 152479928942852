const ICONS = {
    category_name: 'Business',
    icons_list: [

     "chart-line",
    "chart",
    "graph-3",
    "chart-pie-3",
    "graph-2",
    "chart-line-down",
    "chart-pie-too",
    "chart-pie-4",
    "chart-line-down-2",
    "graph-4",
    "chart-line-up-2",
    "badge",
    "chart-line-up",
    "chart-simple-3",
    "chart-pie-simple",
    "chart-simple-2",
    "graph-up",
    "chart-line-star",
    "graph",
    "chart-simple"

    ]
};


export default ICONS
