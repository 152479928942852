const ICONS = {
    category_name: 'Design',
    icons_list: ["eraser",
    "paintbucket",
    "add-item",
    "design-2",
    "brush",
    "size",
    "design",
    "copy",
    "text",
    "design-frame",
    "bucket",
    "glass",
    "feather",
    "pencil",
    "colors-square",
    "design-mask",
    "bucket-square",
    "copy-success",
    "color-swatch"



    ],

};


export default ICONS
