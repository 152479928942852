import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TitleBreadcrumbsConfig } from 'src/app/models/owner-dashboard';
import { SharedOwnerDashboardService } from '../shared-owner-dashboard.service';

@Component({
  selector: 'app-title-breadcrumbs-head',
  templateUrl: './title-breadcrumbs-head.component.html',
  styleUrls: ['./title-breadcrumbs-head.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TitleBreadcrumbsHeadComponent implements OnInit {
  constructor(public sharedOwnerDashboardService: SharedOwnerDashboardService) {

  }
  // @Input() titleBreadcrumbConfig: TitleBreadcrumbsConfig = {
  //   title: {
  //     main: '',
  //     sub: ''
  //   },
  //   breadcrumb: [],
  //   actionsSelectors: null
  // };

  ngOnInit(): void {
    // this.titleBreadcrumbConfig = this.sharedOwnerDashboardService.titleBreadcrumbConfig;

  }

}
