// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-outline-fix-button:hover .svg-icon{
  fill: #fff !important;
}

.ip-outline-fix-button:focus {
  color: #007bff !important;
    background-color: transparent !important;
    border-color: #007bff !important;
}

.ip-solid-fix-button:focus {
  color: #FFFFFF !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}


.product-head-card {

}

.product-head-card.minimal-view {

}

.d-none-minimal {
  transition: all 0.4s ease-in-out !important;
}

.product-head-card.minimal-view .d-none-minimal {
  height: 0 !important;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/skeletons/item-head-skeleton/item-head-skeleton.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;IACvB,wCAAwC;IACxC,gCAAgC;AACpC;;AAEA;EACE,yBAAyB;IACvB,oCAAoC;IACpC,gCAAgC;AACpC;;;AAGA;;AAEA;;AAEA;;AAEA;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".ip-outline-fix-button:hover .svg-icon{\n  fill: #fff !important;\n}\n\n.ip-outline-fix-button:focus {\n  color: #007bff !important;\n    background-color: transparent !important;\n    border-color: #007bff !important;\n}\n\n.ip-solid-fix-button:focus {\n  color: #FFFFFF !important;\n    background-color: #007bff !important;\n    border-color: #007bff !important;\n}\n\n\n.product-head-card {\n\n}\n\n.product-head-card.minimal-view {\n\n}\n\n.d-none-minimal {\n  transition: all 0.4s ease-in-out !important;\n}\n\n.product-head-card.minimal-view .d-none-minimal {\n  height: 0 !important;\n  opacity: 0;\n  visibility: hidden;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
