// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol-group .symbol .symbol-label {
    transition: 0.2s all ease-in-out;
}

.symbol-group .symbol:hover .symbol-label {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/users-symbols-group/users-symbols-group.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".symbol-group .symbol .symbol-label {\n    transition: 0.2s all ease-in-out;\n}\n\n.symbol-group .symbol:hover .symbol-label {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
