
import { BlogItemStatusEnum, BlogPostItem } from './../../../../../models/blog.model';
import { Router } from '@angular/router';

import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { DropdownItem, DropdownItemActionTypeEnum } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { OfferItem, Offer_Payment_Type_Enum } from 'src/app/components/user-dashboard/offer/models/offer';
import { PageItem, PageItemStatusEnum } from 'src/app/models/portal';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/shared/services';
import { lastValueFrom, map, takeWhile } from 'rxjs';
import { Store } from '@ngrx/store';
import { deleteWebsitePage } from 'src/app/shared/store/_features/website/website.actions';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
 import { deleteWebsiteBlogPostActions } from 'src/app/shared/store/_features/website/blog/website-blog.actions';
import { selectBlogPostProcessStatus } from 'src/app/shared/store/_features/website/blog/website-blog.selector';



@Component({
  selector: 'app-blog-posts-list-item',
  templateUrl: './blog-posts-list-item.component.html',
  styleUrls: ['./blog-posts-list-item.component.css']
})
export class BlogPostsListItemComponent implements OnInit {
  post: BlogPostItem;
  posts: BlogPostItem[] = []


  @Input('post') set setPage(post: BlogPostItem) {
    this.post = post;
    this.generateOfferDropdown();
   }

  // @Output() onDeleteDone: EventEmitter<BlogPostItem> = new EventEmitter<BlogPostItem>();
  @Output() onCloneDone: EventEmitter<any> = new EventEmitter<any>();
  dropdownItems: DropdownItem[] = [];

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
   modalRef: NgbModalRef;
  // editBlogPostForm: FormGroup;
  processing: 'none' | 'loading' = 'none';

  constructor(private router: Router, private api: ApiService, private toastr: ToastrService, private modalService: NgbModal , private store: Store) { }



  // Existing properties and methods...

  @Output() onEditPost: EventEmitter<BlogPostItem> = new EventEmitter<BlogPostItem>();

  onEditClick() {
    this.onEditPost.emit(this.post);
  }

  ngOnInit() {
     // this.editBlogPostForm = new FormGroup({
    //   title: new FormControl(this.post.title, [Validators.required]),
    //   content: new FormControl(this.post.content),
    //   image: new FormControl(this.post.image),
    //   // status: new FormControl(this.post.status),
    //   imageId: new FormControl(this.post.imageId || null),
    // });

    //

    // this.offerProductNameMatches = this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name);
    // switch (this.product.type.name) {
    //   case 'course':
    //     this.generateCourseDropdown()
    //     break;
    //     case 'downloadable':
    //     this.generateDownloadableDropdown()
    //     break;
    //   default:
    //     break;
    // }

  }

  // get offerProductNameMatches() {
  //   return this.offer.products?.length && this.offer.title.includes(this.offer.products[0].name)
  // }

  generateOfferDropdown() {
    // this.onClickRouter = ['/dashboard', 'offer', this.page.id, 'edit'];

    this.dropdownItems = [
      {
        label: 'Preview Article',
        iconSvg: 'assets/images/svg/system/preview.svg',
        type: DropdownItemActionTypeEnum.portal,
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        routerLink: this.post.url


      },
      {
        label: 'Edit Post',
        iconSvg: 'assets/images/svg/system/edit.svg',
         colorSet: 'gray-700',
        colorSetHover: 'primary',
          action: ()=>{this. onEditClick()}

      },
      // {
      //   label: 'Duplicate',
      //   iconSvg: 'assets/images/svg/system/copy.svg',
      //   colorSet: 'gray-700',
      //   colorSetHover: 'primary',
      //   action : () => {
      //     this.clonePage(this.page)
      //   },
      // },
      undefined,
      {
        label: 'Delete',
        iconSvg: 'assets/images/svg/system/trash.svg',
        colorSet: 'danger',
        colorSetHover: 'danger',
        action: ()=>{this.deletePost(this.post)}
      }
    ]
  }


  // clonePage = async (page: PageItem) => {

  //   // let offerClone: OfferItem = await this.sharedOfferService.cloneOfferAlert(offer);
  //   // if (!!offerClone) {
  //   //   this.onCloneDone.emit(offerClone)
  //   // }
  // }
  async deletePost(post: BlogPostItem) {
    return Swal.fire({
      title: 'Are you sure want to delete?',
      html: `You will not be able to recover this Post`,
      icon: 'error',
      width: '600px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-danger',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger',
      },
      inputPlaceholder: `I am sure that I'll lose my deleted post.`,

      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          this.store.dispatch(deleteWebsiteBlogPostActions.delete({ postId: post.id }));

          this.store.select(selectBlogPostProcessStatus).pipe(takeWhile(process => process.status == 'deleting', true)).subscribe(process => {
            if (process.status === 'done' && process?.error?.processType != 'deleting') {
              resolve(true);
              this.onDeleteDone();

            }
    
            if (process.status === 'error' && process?.error?.processType == 'deleting') {
              this.onDeleteError(process.error.error);
            }
          });
          
   
        });
      }
    })
  }


  onDeleteDone() {
    
    this.toastr.success('Done!', 'Post Deleted Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  onDeleteError(error) {
    this.toastr.error('Error!', `${error}`, {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }






  startProcessing() {
    this.processing = 'loading';
  }

  stopProcessing() {
    this.processing = 'none';
  }






}
