import { MediaLibraryFileModel } from '../../../../../../models/sales-funnel';
import { SharedPortalViewService } from '../../shared-portal-view.service';
import { UserWebsite, WebsiteIdentity, WebsiteModel } from '../../../portal.model';

import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../../shared/services';
import { MinimizedPortalModel } from '../../../dto/portal.output';
import { Store } from '@ngrx/store';
import { isWebsiteLoaded, selectWebsite, selectWebsiteIdentity } from 'src/app/shared/store/_features/website/website.selector';

@Component({
  selector: 'app-portal-identity-settings-modal',
  templateUrl: './portal-identity-settings-modal.component.html',
  styleUrls: ['./portal-identity-settings-modal.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class PortalIdentitySettingsModalComponent implements OnInit, OnDestroy {
  saveEmitter = new EventEmitter<any>();
  website$: Observable<WebsiteModel>;
  websiteLoaded$: Observable<boolean>;
  
  // portalIdentityConfigurationForm = new FormGroup({
  //   label: new FormControl<string>(null, {
  //     updateOn: 'change',
  //     validators: [Validators.required],
  //   }),
  //   title: new FormControl<string>(null, {
  //     updateOn: 'change',
  //     validators: [Validators.required],
  //   }),
  //   description: new FormControl<string>(null, {
  //     updateOn: 'change',
  //     validators: [Validators.required],
  //   }),
  //   favIcon: new FormControl(null, {
  //     updateOn: 'change',
  //   }),
  // });

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  website: WebsiteModel;

  confirmationRequired = false;
  iConfirm = false;

  identity:WebsiteIdentity;
  loaded: boolean = false;
  // offerSettingsForm = new FormGroup({
  //   isDefault: new FormControl('', {
  //     updateOn: 'change',
  //   }),
  //   parentPortal: new FormControl('', { updateOn: 'change' }),
  //   iConfirm: new FormControl('', { updateOn: 'change' }),
  // });
  subscriptions: Subscription[] = []
  constructor(
    public sharedPortalViewService: SharedPortalViewService,
    private modalService: NgbModal,
    private route: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private store: Store
  ) {

    this.subscriptions.push(
      this.store.select(selectWebsiteIdentity).subscribe(value=> {
        
        this.identity = value;
        // this.portalIdentityConfigurationForm.patchValue(value);
        this.loaded = true;
      })
    )

    this.store.select(selectWebsite).subscribe(website=> {
      this.website = website;
    });
    this.websiteLoaded$ = this.store.select(isWebsiteLoaded)
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async ngOnInit() {
    // await this.loadData();
  }

  closeModal() {
    if (this.sharedPortalViewService.processing === 'loading') {
      this.toastr.warning('Wait until processing is finished');
    } else {
      this.modalService.dismissAll();  
    }
    
  }

  compareWith(item, selected) {
    return +item.siteId === +selected.siteId;
  }

  // async loadData() {

  //   this.identity =
  //   JSON.parse(
  //     JSON.stringify(this.website.identity));

  //     this.identity.label = this.website.label

  // }
  async afterOpenModal() {
    // await this.loadData();
  }

  async updateSettings() {
    this.saveEmitter.emit();
    // this.portalIdentityConfigurationForm.markAllAsTouched();
    // if (!!this.portalIdentityConfigurationForm.valid) {
    //   // delete this.websiteClone.portalIdentity.favIcon;
    //   // await this.sharedPortalViewService.setPortalIdentity(
    //   //   this.websiteClone.portalIdentity,
    //   //   this.websiteClone.
    //   // );

    //   this.closeModal();
    // }
  }

  async onOpenModal() {
    this.iConfirm = false;
    this.confirmationRequired = false;
  }
  async open() {
    await this.onOpenModal();
    this.modalService
      .open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        backdrop: 'static',
        centered: true,
        animation: false,
windowClass: 'global-modal-class modal-animate-in add-new-modal',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
    await this.afterOpenModal();
  }
}
