import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[card-loader]',
})
export class CardLoaderDirectives implements OnInit {
  _cardLoading: boolean = false;
  _message: string = 'Please wait...';
  loaderContainer: HTMLElement;
  constructor(
    el: ElementRef,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    // this.elementRef.nativeElement.classList.add('overflow-hidden');
    this.loaderContainer = this.renderer.createElement('div');
    this.loaderContainer.setAttribute('id', 'uniqueLoadingBlock');
    this.loaderContainer.setAttribute('style', 'z-index:5');
    this.loaderContainer.classList.add(
      'd-none',
      'flex-center',
      'blockUI',
      'blockOverlay',
      'start-0',
      'top-0',
      'w-100',
      'h-100',
      'position-absolute',
      'bg-body',
      'bg-opacity-50',
    );
    this.loaderContainer.innerHTML = `<div class="blockUI blockMsg blockElement"><div class="d-flex flex-column bg-white shadow rounded-2 max-w-600px blockui ">
    <div class="blockui-message"><span class="spinner-border text-primary"></span> <span id="loaderText">${this._message}</span></div>

    </div></div>`;
    this.renderer.appendChild(
      this.elementRef.nativeElement,
      this.loaderContainer
    );
  }
  ngOnInit(): void {}

  @Input('message') set messageConfiguration(message: string) {
    this._message = message;
    let element: HTMLElement = this.elementRef.nativeElement.querySelector(
      '#uniqueLoadingBlock #loaderText'
    );

    element.innerHTML = message;
  }
  @Input('cardLoading') set configuration(cardLoading: boolean) {
    this._cardLoading = cardLoading;

    let element: HTMLElement = this.elementRef.nativeElement.querySelector(
      '#uniqueLoadingBlock'
    );

    if (!!element) {
      if (!!this._cardLoading) {
        element.classList.add('d-flex');
        element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
        element.classList.remove('d-flex');
      }
    }
  }
}
