import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AiCourseCategory } from '../ai-generator.models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ai-category-select',
  templateUrl: './ai-category-select.component.html',
  styleUrls: ['./ai-category-select.component.css']
})
export class AiCategorySelectComponent implements OnInit {

  @Input() categories: AiCourseCategory[] =  [
    { "id": 1, "label": "Technology", "slug": "technology" },
    { "id": 2, "label": "Business", "slug": "business" },
    { "id": 3, "label": "Creative Arts and Design", "slug": "creative-arts-and-design" },
    { "id": 4, "label": "Health and Fitness", "slug": "health-and-fitness" },
    { "id": 5, "label": "Education and Teaching", "slug": "education-and-teaching" },
    { "id": 6, "label": "Language Learning", "slug": "language-learning" },
    { "id": 7, "label": "Lifestyle", "slug": "lifestyle" },
    { "id": 8, "label": "Data Science", "slug": "data-science" },
    { "id": 9, "label": "Information Technology", "slug": "information-technology" },
    { "id": 10, "label": "Marketing", "slug": "marketing" },
    { "id": 11, "label": "Social Sciences", "slug": "social-sciences" },
    { "id": 12, "label": "Environmental Studies", "slug": "environmental-studies" },
    { "id": 13, "label": "Mathematics", "slug": "mathematics" },
    { "id": 14, "label": "Science", "slug": "science" },
    { "id": 15, "label": "Writing", "slug": "writing" },
    { "id": 16, "label": "Communication", "slug": "communication" },
    { "id": 17, "label": "History", "slug": "history" },
    { "id": 18, "label": "Psychology", "slug": "psychology" },
    { "id": 19, "label": "Philosophy", "slug": "philosophy" },
    { "id": 20, "label": "Economics", "slug": "economics" },
    { "id": 21, "label": "Music", "slug": "music" },
    { "id": 22, "label": "Film and Cinema", "slug": "film-and-cinema" },
    { "id": 23, "label": "Cooking and Culinary Arts", "slug": "cooking-and-culinary-arts" },
    { "id": 24, "label": "Travel and Tourism", "slug": "travel-and-tourism" },
    { "id": 25, "label": "Sports and Recreation", "slug": "sports-and-recreation" },
    { "id": 26, "label": "Fashion and Style", "slug": "fashion-and-style" },
    { "id": 27, "label": "Photography", "slug": "photography" },
    { "id": 28, "label": "Architecture", "slug": "architecture" },
    { "id": 29, "label": "Astronomy", "slug": "astronomy" },
    { "id": 30, "label": "Geography", "slug": "geography" },
    { "id": 31, "label": "Political Science", "slug": "political-science" },
    { "id": 32, "label": "Religion and Spirituality", "slug": "religion-and-spirituality" },
    { "id": 33, "label": "Law and Legal Studies", "slug": "law-and-legal-studies" },
    { "id": 34, "label": "Sociology", "slug": "sociology" },
    { "id": 35, "label": "Anthropology", "slug": "anthropology" },
    { "id": 36, "label": "Theater and Performing Arts", "slug": "theater-and-performing-arts" },
    { "id": 37, "label": "Automotive", "slug": "automotive" },
    { "id": 38, "label": "Home and Garden", "slug": "home-and-garden" },
    { "id": 39, "label": "DIY and Crafts", "slug": "diy-and-crafts" },
    { "id": 40, "label": "Engineering", "slug": "engineering" }
];
  @Input() selectedCategories: AiCourseCategory[] = [];
  @Input() tempSelectedCategories: AiCourseCategory[] = [];
  @Input() maximumCategories: number = 3;

  @Output() selectedCategoriesChange: EventEmitter<AiCourseCategory[]> = new EventEmitter<AiCourseCategory[]>();


  
  // @Output() chooseOther: EventEmitter<void> = new EventEmitter<void>();
  // @Output() disableOther: EventEmitter<void> = new EventEmitter<void>();

  otherCategory: {
    status: boolean;
    value: string;
  } = {
    status: false,
    value: '',
  };
  


  constructor(private toastr: ToastrService) {}
  ngOnInit(): void {
    
  }

 

  onUpdateCustomCategory(value: string) {
    if (!!value) {
      const category = new AiCourseCategory();
      category.label = value;

      this.selectedCategories = [category];
      this.onUpdateCategories(this.selectedCategories);
    }

  }
  onUpdateCategories(value: AiCourseCategory[]) {
    if (!!value && !!value.length) {
        this.selectedCategoriesChange.emit(value);
    }
  }

  chooseOther() {
    this.otherCategory.status = true;
    this.clearSelectedCategories();
  }

  disableOther() {
    this.otherCategory.status = false;
    this.otherCategory.value = '';
    this.restoreSelectedCategories();
  }

  clearSelectedCategories() {
    if (!!this.selectedCategories?.length) {
      this.tempSelectedCategories = JSON.parse(
        JSON.stringify(this.selectedCategories)
      );
      this.selectedCategories = [];
    }
  }

  restoreSelectedCategories() {
    if (!!this.tempSelectedCategories?.length) {
      this.selectedCategories = JSON.parse(
        JSON.stringify(this.tempSelectedCategories)
      );
      this.tempSelectedCategories = [];
    }
  }

  toggleCategory(category: AiCourseCategory) {
    let catIndex = this.selectedCategories.findIndex(
      (cat) => cat.id == category.id
    );
    if (catIndex < 0) {
      if (this.selectedCategories?.length >= 3) {
        this.toastr.warning(
          'You can select up to three contexts as your maximum choice.'
        );
      } else {
        this.selectedCategories.unshift(category);
        category.selected = true;
      }
    } else {
      this.selectedCategories.splice(catIndex, 1);
      category.selected = false;
    }

    this.onUpdateCategories(this.selectedCategories);
  }
}
