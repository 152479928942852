import { MediaLibraryFileModel } from './../../../../models/sales-funnel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-library-view-only',
  templateUrl: './media-library-view-only.component.html',
  styleUrls: ['./media-library-view-only.component.css']
})
export class MediaLibraryViewOnlyComponent implements OnInit {
  @Input() files: MediaLibraryFileModel[] = [];
  constructor() { }

  ngOnInit() {
  }

}
