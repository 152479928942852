import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// <script defer data-domain="learmo.dev" src="https://analytics.learmo.dev/js/script.js"></script>
// <script defer data-domain="learmo.com/home" src="https://analytics.learmo.com/js/script.js"></script>


@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    this.loadScript();
  }
  loadScript(): void {
    if (!!environment.analytics.plausible.analyticsEnabled) {
      const script = document.createElement('script');
      script.defer = true;
      script.dataset.domain = environment.analytics.plausible.dataDomain;
      script.src = environment.analytics.plausible.jsSource;
      document.head.appendChild(script);
    }
  }
}