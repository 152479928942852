// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.image-loaded-style {
  background-size: 100% !important;
  background-position: top center !important;
}

.loading-style {
  background-size: 37% !important;
  background-position: center center !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-blog/portal-blog-posts/create-blog-post-popup/create-blog-post-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,0CAA0C;AAC5C;;AAEA;EACE,+BAA+B;EAC/B,6CAA6C;AAC/C","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.image-loaded-style {\n  background-size: 100% !important;\n  background-position: top center !important;\n}\n\n.loading-style {\n  background-size: 37% !important;\n  background-position: center center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
