


import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { BlogItemStatusEnum, BlogPostItem } from '../../../../../models/blog.model';
import { CreateOrUpdateBlogPostDTO } from 'src/app/components/user-dashboard/portal/view/portal-blog/portal-blog-posts/create-blog-post-popup/dto/create-blog.dto';
import { SystemProcessLoadingStatus } from 'src/app/models/global-classes';



export const WebsiteBlogListActions = createActionGroup({
  source: ' Load Website Blog Posts',
  events: {
    load:emptyProps(),
    loadSuccess:props<{ posts: BlogPostItem[] }>(),
    loadFailure: props<{ error: any }>(),
  },
});


export const WebsiteBlogCreateActions = createActionGroup({
  source: ' Create Website Blog Post',
  events: {
    create:props<{ payload: CreateOrUpdateBlogPostDTO }>(),
    loadSuccess:props<{ post: BlogPostItem }>(),
    loadFailure: props<{ error: any }>(),
  },
});


export const updateWebsiteBlogPostActions = createActionGroup({
  source: ' Update post',
  events: {
    update:props<{postId: number, changes: Partial<BlogPostItem>}>(),
    updateSuccess: props<{ post: BlogPostItem }>(),
    updateFailure: props<{ error: any }>(),

  },
});

export const deleteWebsiteBlogPostActions = createActionGroup({
  source: 'Delete post',
  events: {
    delete: props<{ postId: number }>(),
    deleteSuccess: props<{ postId: number }>(),
    deleteFailure: props<{ postId: number, error: any }>(),
  },
});



export const updatePostStatusActions = createActionGroup({
  source: ' Update post Status',
  events: {
    update:props<{postId: number,  status: BlogItemStatusEnum}>(),

  },
});


