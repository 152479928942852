export const HolderNameRegex: RegExp = /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex: RegExp =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{0,}$/;
export const usernameRegex: RegExp = /^[^@\s]{1,}$/;

export const domainRegex = /^[a-z0-9][a-z0-9-]*$/;
// export const usernameRegex : RegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w-.]{0,}$/;
export const passwordWhiteSpace: RegExp = /^\S*$/;
export const checkEmailRegex = (email: string) => {
  const pattern = emailRegex;
  return new RegExp(pattern).test(email);
};

export const checkSiteNameRegex = (name: string) => {
  const pattern = domainRegex;
  return new RegExp(pattern).test(name);
};

export const checkPortalRegex = (name: string) => {
  const pattern = /^[A-Za-z0-9][A-Za-z0-9 -]*$/;
  return new RegExp(pattern).test(name);
};
