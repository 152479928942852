import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  MessageSenderTypeEnum,
  MessengerData,
  MessengerThreadStatusEnum,
  UploadProgressFile,
} from 'src/app/models/owner-dashboard';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MessengerThread,
  MessageTypeEnum,
} from '../../../models/owner-dashboard';
import { MessengerApi } from '../../services/messenger.api.service';
import {
  SectionLoadedStatusEnum,
  SharedMessengerService,
} from './shared-messenger.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import {
  Gallery,
  GalleryItem,
  ImageItem,
  ThumbnailsPosition,
  ImageSize,
  GalleryRef,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { MediaLibraryFileModel } from '../../../models/sales-funnel';
import { Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { emailRegex } from '../../services/regex';
import { AffiliateMinimizedModel } from '../../../models/owner-dashboard';
import {
  DashboardSessionModeType,
  UserDashboardService,
} from '../../services/user-dashboard.service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(200, style({ transform: 'translateX(-150px)', opacity: 0 })),
      ]),
      transition('void => *', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateY(150px)', opacity: 0 }),
        animate(150, style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class MessengerComponent implements OnInit, AfterViewInit, OnDestroy {
  messageSenderTypeEnum = MessageSenderTypeEnum;
  messengerThreadStatusEnum = MessengerThreadStatusEnum;
  sectionLoadedStatusEnum = SectionLoadedStatusEnum;
  messageTypeEnum = MessageTypeEnum;
  loadedPage: boolean = false;
  @ViewChild('messagesChatArea') private chatMessagesBox: ElementRef;

  @Input() resetUploader: EventEmitter<any> = new EventEmitter<any>();
  @Input() clearMessage: EventEmitter<any> = new EventEmitter<any>();

  @Output() onBackClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() backText: string = 'Dashboard';
  @Input() isAffiliate: boolean;
  @Input() startWithUser: number;

  lightboxRef: GalleryRef;

  // pagination
  throttle = 0;
  scrollDistance = 1;
  scrollUpDistance = 1;
  direction = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sharedMessengerService: SharedMessengerService, //private messengerApi: MessengerApi
    public gallery: Gallery,
    public lightbox: Lightbox,
    public userDashboardService: UserDashboardService
  ) {
    // this.sharedMessengerService.messengerData = messengerData;
    const sub = this.route.params.subscribe((params) => {
      // this.messengerData.currentThreadId = +params['id']; // (+) converts string 'id' to a number
      let id = +params['id'];
      if (!!id) {
        this.sharedMessengerService.messengerData.currentThreadId = id;
      } else {
        this.sharedMessengerService.changeLoadingStatus(
          'currentThread',
          SectionLoadedStatusEnum.none
        );
      }
    });
  }
  ngOnDestroy(): void {
    this.resetMessenger();
  }

  resetMessenger() {
    this.sharedMessengerService.clearMessage();
    this.sharedMessengerService.clearUsersList();
    this.clearMessage.emit();
    this.resetUploader.emit();
    this.sharedMessengerService.messengerData.currentThread = null;
    this.sharedMessengerService.messengerData.currentThreadId = null;
    this.sharedMessengerService.messengerData.currentThreadIndex = null;
  }

  startPageLoading() {
    this.loadedPage = false;
  }

  stopPageLoading() {
    this.loadedPage = true;
  }

  async ngOnInit() {
    // this.startPageLoading();
    // await this.sharedMessengerService.getMessengerUpdate();
    await this.sharedMessengerService.getMessengerThreads(
      undefined,
      undefined,
      'firstTime',
      this.sharedMessengerService?.messengerData?.currentThreadId,
      this.isAffiliate
    );

    if (!!this.sharedMessengerService.messengerData.currentThreadId) {
      await this.sharedMessengerService.changeActiveThread(
        this.sharedMessengerService.messengerData.currentThreadId
      );
      setTimeout(async () => {
        // await this.sharedMessengerService.buildGalleryImages();
      }, 1000);
    }

    if (!!this.startWithUser) {
      await this.sharedMessengerService.createNewThreadByUserId(
        this.startWithUser
      );
    }

    this.stopPageLoading();
  }

  ngAfterViewInit(): void {
    this.sharedMessengerService.scrollChatBoxToBottom();
  }

  // Begin: Scroll Pagination Handle
  // Current Thread

  async loadCurrentThreadNextPage() {
    this.sharedMessengerService.scrollLoading.currentThread.next = true;
    await this.sharedMessengerService.getCurrentThreadNextPage();
    this.sharedMessengerService.scrollLoading.currentThread.next = false;
  }

  async loadCurrentThreadPreviousPage() {
    this.sharedMessengerService.scrollLoading.currentThread.previous = true;
    await this.sharedMessengerService.getCurrentThreadPreviousPage();
    this.sharedMessengerService.scrollLoading.currentThread.previous = false;
  }

  async onScrollCurrentThread(event: any) {
    if (!this.sharedMessengerService.scrollLoading?.currentThread.next) {
      await this.loadCurrentThreadNextPage();
    }
  }

  async onScrollDownCurrentThread() {
    //
    if (!this.sharedMessengerService.scrollLoading?.currentThread.previous) {
      await this.loadCurrentThreadPreviousPage();
    }
    this.direction = 'down';
  }

  async loadThreadsListNextPage() {
    this.sharedMessengerService.scrollLoading.threadsList.next = true;

    await this.sharedMessengerService.getThreadsListNextPage();
    this.sharedMessengerService.scrollLoading.threadsList.next = false;
  }

  async loadThreadsListPreviousPage() {
    this.sharedMessengerService.scrollLoading.threadsList.previous = true;

    await this.sharedMessengerService.getThreadsListPreviousPage();
    this.sharedMessengerService.scrollLoading.threadsList.previous = false;
  }
  // Threads List
  async onScrollThreadsList(event: any) {
    if (!this.sharedMessengerService.scrollLoading?.threadsList.previous) {
      await this.loadThreadsListPreviousPage();
    }
  }

  async onScrollDownThreadsList() {
    if (!this.sharedMessengerService.scrollLoading?.threadsList.next) {
      await this.loadThreadsListNextPage();
    }
  }

  // End: Scroll Pagination Handle

  goBack() {
    this.onBackClick.emit();
  }

  async onImageClick(media: MediaLibraryFileModel) {
    await this.sharedMessengerService.buildGalleryImages();
    let imageIndex = this.sharedMessengerService.galleryItems.findIndex(
      (item) => item.imageId == media.id
    );
    this.InitializeGallery();
    this.openGallery(imageIndex);
  }

  InitializeGallery() {
    // Get a lightbox gallery ref
    this.lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    this.lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom,
      thumb: this.sharedMessengerService.galleryItems.length > 1,
      disableThumb: this.sharedMessengerService.galleryItems.length < 1,
      counter: this.sharedMessengerService.galleryItems.length > 1,
      gestures: true,
    });
    // Load items into the lightbox gallery ref
    this.lightboxRef.load(this.sharedMessengerService.galleryData);
  }

  openGallery(index: number = 0) {
    if (index >= 0) {
      this.lightbox.open(index);
    } else {
      this.lightbox.open(0);
    }
  }

  onUploadingUpdate(mediaIds: number[]) {
    this.sharedMessengerService.message.mediaIds = mediaIds;
  }

  async onKeydown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();

      await this.sendMessageAction();

      return false;
    } else {
    }
  }

  // async goToThread(id: number) {
  //   this.router.navigate([id], { relativeTo: this.route.parent });
  //   await this.sharedMessengerService.changeActiveThread(id);
  //   this.sharedMessengerService.enableGalleryRefresh();
  //   // this.changeActiveThread(id);
  // }

  messageValidator(message: string) {
    const regex = /^(?!\s*$).+/gm;
    let result = regex.test(message);
    //
    return result;
  }

  async sendMessageAction() {
    if (
      this.messageValidator(this.sharedMessengerService.message.text) ||
      !!this.sharedMessengerService.message.mediaIds.length
    ) {
      if (
        this.sharedMessengerService.loaded.currentThread ==
        SectionLoadedStatusEnum.creating
      ) {
        this.sharedMessengerService.changeLoadingStatus(
          'currentThread',
          SectionLoadedStatusEnum.filled
        );
      }
      await this.sharedMessengerService.sendMessage(
        this.sharedMessengerService.message
      );
      this.sharedMessengerService.scrollChatBoxToBottom(0);
      if (!!this.sharedMessengerService.message.mediaIds.length) {
        this.sharedMessengerService.enableGalleryRefresh();
      }
      this.sharedMessengerService.clearMessage();
      this.clearMessage.emit();
      this.resetUploader.emit();
    }
  }

  async startNewThreadAction() {
    if (
      (this.messageValidator(this.sharedMessengerService.newChatBody.text) ||
        !!this.sharedMessengerService.newChatBody.mediaIds.length) &&
      !!this.sharedMessengerService.newChatBody.threadUsers.length
    ) {
      await this.sharedMessengerService.startNewThread(
        this.sharedMessengerService.newChatBody
      );
      this.sharedMessengerService.scrollChatBoxToBottom(0);
      if (!!this.sharedMessengerService.newChatBody.mediaIds.length) {
        this.sharedMessengerService.enableGalleryRefresh();
      }
      this.sharedMessengerService.clearMessage();
      this.sharedMessengerService.clearUsersList();
      this.clearMessage.emit();
      this.resetUploader.emit();
    }
  }

  // NGX Chips Functions
  // Ngx-Tag Validation
  // async onAdd(affiliate: AffiliateMinimizedModel) {
  //   //
  //   // await this.sharedMessengerService.onAddAffiliateToNewChat(
  //   //   affiliate,
  //   //   this.isAffiliate
  //   // );
  //   //
  //   // this.syncUserIds();
  // }

  async onChange(value: AffiliateMinimizedModel[]) {
    this.sharedMessengerService.newChatBody.threadUsers = value;
    this.syncUserIds();

    await this.sharedMessengerService.lookForThreadByUsers(
      this.sharedMessengerService.newChatBody.threadUserIds,
      false,
      false
    );
  }
  syncUserIds() {
    this.sharedMessengerService.newChatBody.threadUserIds =
      this.sharedMessengerService.newChatBody.threadUsers.map((a) => a.id);
  }

  async onRemove() {
    //
    // this.syncUserIds();
    // await this.sharedMessengerService.lookForThreadByUsers(
    //   this.sharedMessengerService.newChatBody.threadUserIds,
    //   this.isAffiliate
    // );
    //
    //
  }
}
