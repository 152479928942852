import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsState, productAdapter, productOfferAdapter } from './products.reducer';


// Create a feature selector for the 'products' feature
export const selectProductsState = createFeatureSelector<ProductsState>('products');

// Create a selector to get the products from the state (maintaining the same name)
export const selectProducts = createSelector(
    selectProductsState,
    (state) => productAdapter.getSelectors().selectAll(state)
  );

// Create a selector to get the loading status (maintaining the same name)
export const isProductsLoaded = createSelector(selectProductsState, (state) => state.loaded);

// Create a selector to get the error status (maintaining the same name)
export const isProductsLoadingError = createSelector(selectProductsState, (state) => state.error);



export const selectCurrentProduct = createSelector(
  selectProductsState,
  (state) => state.currentProduct
);

export const isCurrentProductLoaded = createSelector(selectProductsState, (state) => state.currentProductProcess.loaded);
export const isCurrentProductLoadingError = createSelector(selectProductsState, (state) => state.currentProductProcess.error);


export const selectCurrentProductOffers = createSelector(
  selectProductsState,
  (state) => productOfferAdapter.getSelectors().selectAll(state.currentProductOffers)
);

export const selectCurrentProductDefaultOffer = createSelector(
  selectProductsState,
  (state) => productOfferAdapter.getSelectors().selectAll(state.currentProductOffers).find(offer => offer.isDefault)
);


export const selectProductOfferById = (offerId: number) => createSelector(
  selectProductsState,
  (state) => state.currentProductOffers.entities[offerId]
);