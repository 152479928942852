export class ResetPasswordModel {
    constructor(
      public readonly userId: boolean,
      public email?: string,
      public sc?: string
    ){}
}

export class ResetPasswordConfirmResult {
    public readonly success : boolean;
}