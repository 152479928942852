import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSpaces',
  pure: true,
})
export class RemoveSpacesPipe implements PipeTransform {
  transform(value: string, replaceWith: string = ''): any {
    if (!!value?.length) {
    return value.replace(' ', replaceWith).toLowerCase();
    }
    else {
      return value;
    }
  }
}
