import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';

// animations: [
//   trigger('slideInOut', [
//     transition(':enter', [
//       style({transform: 'translateY(-10%)', opacity: 0}),
//       animate('400ms ease-in', style({transform: 'translateY(0%)', opacity: 1}))
//     ]),
//     transition(':leave', [
//       animate( '420ms ease-in', style({transform: 'translateY(-10%)', opacity: 0}))
//     ])
//   ])
// ]

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-20%)', opacity: 0 }),
        animate(
          '500ms ease-in',
          style({ transform: 'translateY(0%)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '0ms ease-in',
          style({ transform: 'translateY(-15%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AnnouncementComponent implements AfterViewInit {
  // Animation Control
  state = false;
  timeFormat: string;
  // Alert Control
  // @Input() show = true;
  @Input() timer = null;
  @Input() symbol = undefined;

  // Alert Styling
  @Input() type:
    | 'warning'
    | 'danger'
    | 'primary'
    | 'dark '
    | 'info'
    | 'secondary'
    | 'light'
    | 'dark-75'
    | 'success' = 'light';

  @Input() announcementTitle = undefined;
  @Input() showTimerAlert = false;

  // Dismiss Button Settings
  @Input() dismissButton: boolean = true;
  @Input() dismissText = 'Dismiss';
  @Input() closeIcon: boolean = true;
  @Output() onDismiss = new EventEmitter();
  @Output() onClose = new EventEmitter();

  // Action Button Settings
  @Input() actionButton: boolean = false;
  @Input() actionText = 'Action';
  @Output() onAction = new EventEmitter();

  @Input() actionDisabled = false;

  // Timer Variables
  public _actionTimer = 0;
  @Input() timerMessage: string = 'Locked for :';
  @Input() set actionTimer(value: number) {
    if (+value > 0) {
      this._actionTimer = value;
      this.sec2time(value);
      this.showTimerAlert = true;
    } else {
      this._actionTimer = 0;
      this.showTimerAlert = false;
    }
  }

  get actionTimer(): number {
    return this._actionTimer;
  }

  // show
  public _show = true;

  @Input() set show(value: boolean) {
    this._show = value;
  }

  actionButtonEmit() {
    if (+this._actionTimer > 0) {
      this.showTimerAlert = true;
    } else {
      this.onAction.emit();
    }
  }

  // get show(): boolean {
  //   return this._show;
  // }

  constructor() {}

  //   ngOnChanges(changes: SimpleChanges) {
  //     if (!!this._actionTimer && !!changes._actionTimer) {
  //       this.sec2time(this._actionTimer);
  //     }

  // }

  ngAfterViewInit(): void {
    if (!!this.timer) {
      setTimeout(async () => {
        this.show = false;
      }, this.timer * 1000);
    }
  }

  dismissAlert() {
    this.show = false;
  }
  pad(num, size) {
    return ('000' + num).slice(size * -1);
  }
  sec2time(timeInSeconds) {
    const time = +parseFloat(timeInSeconds).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);

    this.timeFormat = (
      this.pad(minutes, 2) +
      ':' +
      this.pad(seconds, 2)
    ).toString();
  }
}
