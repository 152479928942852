// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-container {
  position: relative;
  border-radius: 22px;
}

.template-info {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  border-radius: 22px 22px 0 0;
  /* height: 31%; */
}

.template-image {
  position: relative;
  width: 100%;
  /* padding-bottom: 100%; */
  /* border-radius: 22px;
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: top; */
  /* overflow: hidden; */
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
}


.selected-style {
  transform: translateY(-2.5%);
    transition: transform 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/funnels-layout/funnel-item/funnel-item.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,WAAW;EACX,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B;;;6BAG2B;EAC3B,sBAAsB;EACtB,+LAA+L,EAAE,mDAAmD;AACtP;;;AAGA;EACE,4BAA4B;IAC1B,+BAA+B;AACnC","sourcesContent":[".template-container {\n  position: relative;\n  border-radius: 22px;\n}\n\n.template-info {\n  /* position: absolute; */\n  /* bottom: 0; */\n  width: 100%;\n  border-radius: 22px 22px 0 0;\n  /* height: 31%; */\n}\n\n.template-image {\n  position: relative;\n  width: 100%;\n  /* padding-bottom: 100%; */\n  /* border-radius: 22px;\n  background-size: auto 75%;\n  background-repeat: no-repeat;\n  background-position: top; */\n  /* overflow: hidden; */\n  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */\n}\n\n\n.selected-style {\n  transform: translateY(-2.5%);\n    transition: transform 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
