// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-pages-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/template-item/template-item-info-popup/template-item-info-popup.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".template-pages-grid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-column-gap: 1em;\n  grid-row-gap: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
