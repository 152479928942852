import { DomainItem } from 'src/app/models/domain';
import {
  FunnelTemplateItem,
  TemplateItem,
  UserWebsite,
  WebsiteModel,
} from './../../../../components/user-dashboard/portal/portal.model';

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

export enum SummaryItemType {
  template = 'template',
  funnel = 'funnel',
  portal = 'portal',
  domain = 'domain',
}
@Component({
  selector: 'app-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.css'],
})
export class SummaryItemComponent implements OnInit {
  summaryItemType = SummaryItemType;
  @Input() funnel: FunnelTemplateItem;
  @Input() template: TemplateItem;
  @Input() website: WebsiteModel
  @Input() domain: DomainItem;
  @Input() type: SummaryItemType;
  @Output() onClickChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  changeAction() {
    this.onClickChange.emit();
  }
}
