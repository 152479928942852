const ICONS = {
    category_name: 'Time',
    icons_list: [
      "heart-circle",
      "like",
      "information-4",
      "information-5",
      "information-2",
      "information-3",
      "question",
      "dislike",
      "message-question",
      "medal-star",
      "like-tag",
      "like-2",
      "support-24",
      "question-2",
      "lovely",
      "like-shapes",
      "heart"
    ]



};


export default ICONS
