import { ApiService } from 'src/app/shared/services';
// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';

import {
  ApplyTemplateToCourseAction,
  CreateCourseItemActions,
  CurrentCourseHeirarchyActions,
  MoveCourseItemActions,
  UpdateCourseSettingsActions,
  updateCurrentCourseHeirarchyStructure,
} from './course.actions';
import {
  CourseHeirarchyModel,
} from 'src/app/components/user-dashboard/product/models/course';
import { Store } from '@ngrx/store';
import { CourseItemStatusActions, DeleteCourseItemActions } from './course-items/course-items.actions';

@Injectable()
export class CourseEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }

  // Load Current course Heirarchy
  loadCurrentCourseHeirarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseHeirarchyActions.load), // Listen for the loadProducts action
      mergeMap(({ productId, errorHandler = undefined }) =>
        this.api.getCourseContentById(productId, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: CourseHeirarchyModel) =>
            CurrentCourseHeirarchyActions.loadSuccess({ heirarchy: response })
          ),
          catchError((error) =>
            of(CurrentCourseHeirarchyActions.loadFailure({ error }))
          )
        )
      )
    )
  );


  // Create Course Item
  createCourseItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateCourseItemActions.create), // Listen for the loadProducts action
      mergeMap(({ productId, itemType, payload, errorHandler = undefined }) => {

        return this.api.genericAddCourseItem(productId, itemType, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CreateCourseItemActions.success({ heirarchy: response })
          ),
          catchError((error) =>
            of(CreateCourseItemActions.failure({ error }))
          )
        )
      }
      )
    )
  );

  // Move Course Item
  moveCurrentCourseItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveCourseItemActions.move), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) =>
        this.api.moveCourseitem(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: CourseHeirarchyModel) =>
            MoveCourseItemActions.moveSuccess({ heirarchy: response })
          ),
          catchError((error) =>
            of(MoveCourseItemActions.moveFailure({ error }))
          )
        )
      )
    )
  );


  // Apply Template to course heirarchy
  applyTemplateToCourseHeirarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplyTemplateToCourseAction.set), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) => {

        return this.api.applyTemplateToCourse(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            ApplyTemplateToCourseAction.success({ heirarchy: response })
          ),
          catchError((error) =>
            of(ApplyTemplateToCourseAction.failure({ error }))
          )
        )
      }
      )
    )
  );


  // Update Course Settings
  updateCourseSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCourseSettingsActions.update), // Listen for the loadProducts action
      mergeMap(({ productId, payload, errorHandler = undefined }) => {

        return this.api.updateCourseSettingsById(productId, payload, errorHandler).pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            UpdateCourseSettingsActions.success({ heirarchy: response })
          ),
          catchError((error) =>
            of(UpdateCourseSettingsActions.failure({ error }))
          )
        )
      }
      )
    )
  );



  // Generic Effects
  resetStatusAfterTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentCourseHeirarchyActions.loadSuccess, MoveCourseItemActions.moveSuccess, CreateCourseItemActions.success, ApplyTemplateToCourseAction.success, CourseItemStatusActions.success, DeleteCourseItemActions.success, UpdateCourseSettingsActions.success),
      delay(3000), // Adjust delay time as needed (in milliseconds)
      map(() => CurrentCourseHeirarchyActions.resetProcessingStatus())
    )
  );


  updateCurrentCourseHeirarchyStructureCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveCourseItemActions.moveSuccess, CreateCourseItemActions.success, ApplyTemplateToCourseAction.success, CourseItemStatusActions.success, DeleteCourseItemActions.success, UpdateCourseSettingsActions.success), // Listen for the loadProducts action
      mergeMap(({ heirarchy }) => {
        return of(updateCurrentCourseHeirarchyStructure({ heirarchy }))
      }),
    )
  );
}
