import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCurrentProduct } from 'src/app/shared/store/_features/products/products.selector';


@Component({
  selector: 'app-item-head-skeleton',
  templateUrl: './item-head-skeleton.component.html',
  styleUrls: ['./item-head-skeleton.component.css'],
})
export class ItemHeadSkeletonComponent implements OnInit {
  
  // portalViewPageStatusEnum = PortalViewPageStatusEnum;
  constructor(
    private router: Router,
  ) {}



  ngOnInit(): void {}


  // async reloadPortalOffers() {
  //   await this.sharedPortalViewService.reloadPortalOffers();
  // }
}
