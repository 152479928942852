import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountSetupStep, AccountSetupStepStatusEnum } from '../setup-steps.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-account-setup-item',
  templateUrl: './account-setup-item.component.html',
  styleUrls: ['./account-setup-item.component.css']
})
export class AccountSetupItemComponent implements OnInit {
  @Input() item: AccountSetupStep;
  @Input() isCurrentStep: boolean;
  @Input() disableTooltip: boolean = false;

  @Output() StepClicked = new EventEmitter<AccountSetupStep>();
  accountSetupStepStatusEnum = AccountSetupStepStatusEnum;
  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  onStepClicked(item: AccountSetupStep = this.item) {
      this.StepClicked.emit(item);
    
  }
}
