// actions/products.actions.ts
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { CourseCreationInput } from 'src/app/components/user-dashboard/product/models/course';
import { ProductItem } from 'src/app/components/user-dashboard/product/models/product';


export const ProductsActions = createActionGroup({
  source: 'Load Products',
  events: {
    load: emptyProps(),
    loadSuccess: props<{ products: ProductItem[] }>(),
    loadFailure: props<{ error: any }>(),

  },
});



// Current Product Management
export const CurrentProductActions = createActionGroup({
  source: 'Load Current Product',
  events: {
    load:props<{productId: number}>(),
    loadSuccess: props<{ product: ProductItem }>(),
    loadFailure: props<{ error: any }>(),

  },
});



// Current Product Update
export const updateCurrentProductActions = createActionGroup({
  source: ' Update Current Product',
  events: {
    update:props<{productId: number, changes: Partial<ProductItem>}>(),
    updateSuccess: props<{ product: ProductItem }>(),
    updateFailure: props<{ error: any }>(),

  },
});






// Product Offer Actions
export const productOfferActions = createActionGroup({
  source: ' Set Offer as Default',
  events: {
    setOffer:props<{offerId: number, productId: number}>(),
    setOfferSuccess: props<{ offerId: number, offer: OfferItem }>(),
    setOfferFailure: props<{ offerId: number, error: any }>(),

  },
});



 export const createNewProductActions = createActionGroup({
  source: 'Add New Product',
  events: {
    create:props<{ createPayload: CourseCreationInput }>(),
    createSuccess: props<{ product: ProductItem }>(),
    createFailure: props<{ error: any }>(),

  },
});





export const addNewOfferToProduct = createAction('[Products] Offer Added to Product Successfully', props<{ productId: number, offer: OfferItem }>());
// export const deleteOfferFromProduct = createAction('[Products] Offer Deleted from Product Successfully', props<{ productId: number, offerId: number }>());
// export const addNewProduct = createAction('[Products] Product Added Successfully', props<{ product: ProductItem }>());





export const deleteProduct = createAction('[Products] Product Added Successfully', props<{ product: ProductItem }>());


export const clearCurrentProduct = createAction('[Products] Clear Current Product Data');




// export const loadProducts = createAction('[Products] Load Products');
// export const loadProductsSuccess = createAction('[Products] Load Products Success', props<{ products: ProductItem[] }>());
// export const loadProductsFailure = createAction('[Products] Load Products Failure', props<{ error: any }>());
// Define other actions as needed for your feature



// Current Product Management
// export const loadCurrentProduct = createAction('[Product] Load Current Product', props<{productId: number}>());
// export const loadCurrentProductSuccess = createAction('[Product] Load Current Product Success', props<{ product: ProductItem }>());
// export const loadCurrentProductFailure = createAction('[Product] Load Current Product Failure', props<{ error: any }>());



// Current Product Update
// export const updateCurrentProduct = createAction('[Product] Update Current Product', props<{productId: number, changes: Partial<ProductItem>}>());
// export const updateCurrentProductSuccess = createAction('[Product] Update Current Product Success', props<{ product: ProductItem }>());
// export const updateCurrentProductFailure = createAction('[Product] Update Current Product Failure', props<{ error: any }>());



// Product Offer Actions
// export const setOfferAsDefault = createAction('[Offers] Set Offer as Default', props<{offerId: number, productId: number}>());
// export const setOfferAsDefaultSuccess = createAction('[Offers] Set Offer as Default Success', props<{ offerId: number, offer: OfferItem }>());
// export const setOfferAsDefaultFailure = createAction('[Offers] Set Offer as Default Failure', props<{ offerId: number, error: any }>());



// export const createNewProduct = createAction('[Products] Add New Product', props<{ createPayload: CourseCreationInput }>());
// export const createNewProductSuccess = createAction('[Products] Product Added Successfully', props<{ product: ProductItem }>());
// export const createNewProductFailure = createAction('[Products] Create new Product Failure', props<{ error: any }>());
