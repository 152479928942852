import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { PortalControlService } from '../../../../shared/services/portal-control.service';
import { ProgressSpinnerService } from '../../../../shared/services/progress-spinner.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../../shared/services/lsg.api.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CountdownComponent } from 'ngx-countdown';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import {
  passwordRegex,
  passwordWhiteSpace,
  usernameRegex,
  emailRegex,
} from '../../../../shared/services/regex';
import { matchValues } from '../../../../shared/services/validators';
import { trigger, transition, style, animate } from '@angular/animations';
import { User } from '../../../../models/user';
import { catchError, lastValueFrom, switchMap } from 'rxjs';
import { AnimatedLoaderType } from 'src/app/shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { AppwriteException, OAuthProvider } from 'appwrite';
import { Store } from '@ngrx/store';
import { selectLoginFailureError } from 'src/app/shared/store/_features/user/user.selector';
import {
  ShrinkInOutAnimation,
  ShrinkifyInOutAnimation,
  cardAnimation,
  formSlideInAnimation,
  slideInOut,
} from 'src/app/shared/animations/animations';
import { LoginMethodStatusEnum, LoginMethodUIItem } from 'src/app/components/profile/security-settings/login-methods/login-methods.component';

export enum LoginProcessSteps {
  email_capture = 'email_capture',
  password_capture = 'password_capture',
  phone_verification = 'phone_verification',
  magic_link_instuctions = 'magic_link_instuctions',
  select_method = 'select_method',
}
export enum LoginErrorType {
  none,
  login_failed,
  login_confirm,
  login_required,
  wrong_email,
}

export enum RegisterErrorType {
  none,
  username_exist,
  email_exist,
}

export enum PinCodeErrorType {
  none,
  invalid_code,
  expired_code,
}

export enum TimerErrorType {
  none,
  message,
  timer,
}

export enum LoginMethodsEnum {
  unknown = 'unknown',
  email = 'email',
  magic_link = 'magic_link',
  phone = 'phone',
  authenticator = 'authenticator',
}

@Component({
  selector: 'app-user-login-form-alt',
  templateUrl: './user-login-form-alt.component.html',
  styleUrls: ['./user-login-form-alt.component.css'],
  animations: [
    formSlideInAnimation,
    ShrinkifyInOutAnimation,
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.4s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserLoginFormAltComponent implements OnInit {
  OAuthProvider = OAuthProvider;
  
  loginMethods: LoginMethodUIItem[] = [
    {
      displayName: 'Standard Login',
      enum: LoginMethodsEnum.email,
      description:
        'Traditional login using email and password.',
      icon: 'lock', // You can choose an appropriate icon
      enabled: LoginMethodStatusEnum.enabled,
      visible: true
    },
    {
      displayName: 'Email Magic URL',
      enum: LoginMethodsEnum.magic_link,
      description: `We'll send you a special link through email.`,
      icon: 'abstract-21',
      enabled: LoginMethodStatusEnum.disabled,
      visible: true
    },
    {
      displayName: 'SMS Verification Code',
      enum: LoginMethodsEnum.phone,
      description: `We will send a code via SMS.`,
      icon: 'sms',
      enabled: LoginMethodStatusEnum.ineligible,
      visible: false
    },
  ];
  isOAuthEnabled: boolean = false;
  LoginProcessSteps = LoginProcessSteps;
  LoginErrorType = LoginErrorType;
  loginFailureError: AppwriteException;

  signinForm = new FormGroup({
    identity: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(emailRegex),
    ]),
    password: new FormControl('', [Validators.required]),
  });

  loginProcessCurrentStep: LoginProcessSteps = LoginProcessSteps.email_capture;
  animatedLoaderType = AnimatedLoaderType;
  @Output() onLoadingStart = new EventEmitter<any>();
  @Output() onLoadingDone = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();

  @Input() userObject: any;
  // UserDataForInvitation
  @Input() affiliate: boolean = false;
  loginErrorType: LoginErrorType = LoginErrorType.none;
  registerErrorType: RegisterErrorType = RegisterErrorType.none;
  pinErrorType: PinCodeErrorType = PinCodeErrorType.none;
  timerError: TimerErrorType = 0;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  loading: boolean = false;

  loadingTimeout: any;

  loginFormState = 0;
  twoFaResendTime = false;
  loginVerification = false;
  checkTimer: any;
  secDiff: number;

  // registerForm: UntypedFormGroup;
  pinForm: UntypedFormGroup;
  // resetPasswordForm: UntypedFormGroup;

  resetPasswordError = false;
  usernameReg: any;
  userId2FA: number = 0;
  usernameError = false;
  passwordError = false;
  requestedReset = false;
  email: string;

  isProduction: boolean = environment.production;
  isRegestrationEnabled: boolean = !environment.disableRegister;

  url: string;

  preferredLoginMethod: LoginMethodsEnum = LoginMethodsEnum.email;
  availableLoginMethods: LoginMethodsEnum[] = [];
  constructor(
    public appwriteService: AppwriteService,
    private fb: UntypedFormBuilder,
    private readonly authService: AuthenticationService,
    private readonly api: ApiService,
    private readonly router: Router,
    public loadingService: ProgressSpinnerService,
    private portalControlService: PortalControlService,
    private store: Store
  ) {
    this.isOAuthEnabled = environment.appwrite.oAuthEnabled;
    this.resendCheckTimer();
    this.store.select(selectLoginFailureError).subscribe((error) => {
      this.loginFailureError = error;
      if (!!error) {
        this.handleLoginError();
      }
    });
  }

  ngOnInit() {
    this.appwriteService.clearAppwriteSession();
    this.buildPinForm();

    this.signinForm.controls.identity.valueChanges.subscribe(() => {
      this.loginProcessCurrentStep = LoginProcessSteps.email_capture;
    });
    if (!!this.userObject) {
      this.signinForm.controls.identity.setValue(this.userObject.email);
    }
    // this.api.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });
  }

  otpInputChange(event) {
    console.log(event);
  }
  resendPhoneCode() {}
  // async signInWithGoogle() {

  // }

  // reset2Fa() {
  //   this.twoFaLoading = false;
  //   Array.from(
  //     document.getElementsByClassName(
  //       'splitInputDig'
  //     ) as HTMLCollectionOf<HTMLInputElement>
  //   ).forEach((element) => {
  //     element.value = '';
  //   });
  // }
  // private buildSigninForm() {
  //   this.signinForm = this.fb.group({
  //     username: ['', [Validators.required]],
  //     password: ['', Validators.required],
  //   });
  // }

  private buildPinForm() {
    this.pinForm = this.fb.group({
      pincode: ['', [Validators.required]],
    });
  }

  resetLoginErrors() {
    this.usernameError = false;
    this.passwordError = false;
    this.loginErrorType = LoginErrorType.none;
  }

  // Login Form State
  // 0 = Login
  // 1 = Register
  // 2 = Forgot

  goToStep(step: LoginProcessSteps) {
    this.loginProcessCurrentStep = step;
  }
  openForgotForm() {
    this.router.navigate(['auth', 'forgot']);
  }

  async login() {
    this.signinForm.markAllAsTouched();
    switch (this.loginProcessCurrentStep) {
      case LoginProcessSteps.email_capture:
        if (this.signinForm.controls.identity.valid) {
          await this.handlePreLogin();
        } else {
          return false;
        }
        break;
      case LoginProcessSteps.password_capture:
        this.handleLoginWithEmailAndPassword();

        break;
      case LoginProcessSteps.phone_verification:
        this.handleLoginWithPhone();

        break;
      default:
        break;
    }
  }

  async handlePreLogin() {
    let identity = this.signinForm.controls.identity.value;
    let preLoginResult = await this.checkPreferredLoginMethod(identity);
    this.preferredLoginMethod = preLoginResult.preferred_method;
    this.availableLoginMethods = preLoginResult.available_methods;

    if (this.availableLoginMethods?.includes(LoginMethodsEnum.magic_link)) {
      this.triggerLoginMethod(LoginMethodsEnum.magic_link);
    }
    else {
      this.triggerLoginMethod(LoginMethodsEnum.email);
    }
    // if (
    //   preLoginResult?.available_methods?.length > 1 &&
    //   !preLoginResult?.preferred_method
    // ) {
    //   this.handleSelectMethod(preLoginResult.available_methods);
    // } else {
    //   this.triggerLoginMethod(preLoginResult.preferred_method);
    // }
  }

  handleSelectMethod(availableMethods: LoginMethodsEnum[]) {
    this.goToStep(LoginProcessSteps.select_method);
  }

  triggerLoginMethod(methodEnum: LoginMethodsEnum) {
    switch (methodEnum) {
      case LoginMethodsEnum.unknown:
        this.startLoginWithEmail();
        break;
      case LoginMethodsEnum.email:
        this.startLoginWithEmail();
        break;
      case LoginMethodsEnum.phone:
        this.startLoginWithPhone();
        break;
      case LoginMethodsEnum.magic_link:
        this.startLoginWithMagicLink();
        break;
      default:
        break;
    }
  }

  startLoginWithEmail() {
    this.goToStep(LoginProcessSteps.password_capture);
  }

  startLoginWithMagicLink() {
    this.authService
      .loginWithMagicLink(this.signinForm.value.email)
      .pipe(
        switchMap((result) => {
          // Do something with the result if needed
          console.log(result);

          // Dispatch a success action if necessary
          this.goToStep(LoginProcessSteps.magic_link_instuctions);
          return null;
        }),
        catchError((error) => {
          // Handle the error, and dispatch a failure action if necessary
          console.log(error);
          throw error;
        })
      )
      .subscribe();
  }

  startLoginWithPhone() {
    this.authService
      .loginWithPhone(this.signinForm.value.phone)
      .pipe(
        switchMap((result) => {
          // Do something with the result if needed
          console.log(result);

          // Dispatch a success action if necessary
          this.goToStep(LoginProcessSteps.phone_verification);
          return null;
        }),
        catchError((error) => {
          // Handle the error, and dispatch a failure action if necessary
          console.log(error);
          throw error;
        })
      )
      .subscribe();
  }
  handleLoginWithPhone() {}
  handleLoginWithEmailAndPassword() {
    if (this.signinForm.valid) {
      this.onLoginStart();
      this.loginErrorType = LoginErrorType.none;

      const email = this.signinForm.get('email').value;
      const password = this.signinForm.get('password').value;

      this.authService.loginWithEmailAndPassword(email, password);
    } else {
      const wrongEmail = this.signinForm.get('email').hasError('pattern');
      const usernameEmpty = this.signinForm.get('email').hasError('required');
      const passwordEmpty = this.signinForm.get('email').hasError('required');
      if (!!usernameEmpty || !!passwordEmpty) {
        this.loginErrorType = LoginErrorType.login_required;
        return false;
      }

      if (!!wrongEmail) {
        this.loginErrorType = LoginErrorType.wrong_email;
      }
    }
  }
  async checkPreferredLoginMethod(identity: string) {
    this.setIdentityPart(identity);
    const request$ = this.api.preLogin(identity, () => {
      return LoginMethodsEnum.unknown;
    });
    const response: {
      preferred_method: LoginMethodsEnum;
      available_methods: LoginMethodsEnum[];
      meta: string;
    } = await lastValueFrom(request$);

    if (!!response?.meta) {
      let decryptedMeta = atob(response.meta);
      this.setIdentityPart(decryptedMeta);
    }
    return {
      preferred_method: !!response?.preferred_method
        ? response.preferred_method
        : LoginMethodsEnum.unknown,
      available_methods: response.available_methods,
      meta: response.meta,
    };
  }

  setIdentityPart(identity: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+\d{1,15}$/;
    if (emailRegex.test(identity)) {
      this.signinForm.controls.email.setValue(identity);
      console.log("It's email: ", identity);
    } else if (phoneRegex.test(identity)) {
      this.signinForm.controls.phone.setValue(identity);
      console.log("It's phone: ", identity);
    } else {
      console.log("It's Invalid: ", identity);
      return 'Not a valid email or phone number';
    }
  }

  onLoginStart() {
    this.loading = true;
    this.loadingTimeout = setTimeout(() => {
      this.onLoadingStart.emit();
    }, 1450);
  }

  onLoginDone(resp: { isTowfa: boolean; userId: number }) {
    // this.authenticationService.onRegisterResponse(resp)
    // this.authService.onLoginResponse(resp, false);
    this.onLoadingDone.emit();
    // setTimeout(() => {
    //   this.authService.onLoginResponse(resp, false);
    //   setTimeout(() => {
    //     this.onLoadingDone.emit();
    //     this.loading = false;
    //   }, 2000);
    // }, 1500);
  }

  onLoginError() {
    clearTimeout(this.loadingTimeout);
    setTimeout(() => {
      this.onError.emit();
      this.loading = false;
    }, 1500);
  }

  async submitPin() {}

  resetPinInputs() {
    var slides = document.getElementsByClassName('pinInputs');
    for (var i = 0; i < slides.length; i++) {
      (slides.item(i) as HTMLInputElement).value = '';
    }
  }
  handleLoginError = () => {
    this.loginErrorType = LoginErrorType.login_failed;
    // switch (error.statusCode) {
    //   case 600:
    //     this.loginErrorType = LoginErrorType.login_failed;
    //     break;
    //   case 601:
    //     this.loginErrorType = LoginErrorType.login_confirm;
    //     break;
    //   default:
    //     break;
    // }

    this.onLoginError();
  };

  resendCheckTimer() {
    clearInterval(this.checkTimer);
    let localEndTime = localStorage.getItem('endTime');
    if (!!localEndTime) {
      let endTime = new Date(+localEndTime);
      this.checkTimer = setInterval(() => {
        this.secDiff = Math.floor(
          (endTime.getTime() - new Date().getTime()) / 1000
        );
        if (this.secDiff <= 0) {
          clearInterval(this.checkTimer);
          localStorage.removeItem('endTime');
        }
      }, 1000);
    } else {
      this.secDiff = 0;
    }
  }

  resendSetTimer(time: number) {
    let endTime = localStorage.setItem(
      'endTime',
      (new Date().getTime() + time * 1000).toString()
    );
    this.timerError = TimerErrorType.message;
    this.resendCheckTimer();
  }

  destroyTimer() {
    localStorage.removeItem('endTime');
    clearInterval(this.checkTimer);
    this.resendCheckTimer();
  }

  resetPasswordAlert() {
    const swalError = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success m-1',
      },
      buttonsStyling: false,
    });

    swalError
      .fire({
        allowOutsideClick: false,
        title: 'Reset Password',
        text: 'Email sent successfully, check your email',
        icon: 'success',
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
        showCancelButton: false,
      })
      .then((res) => {
        // this.openSigninForm();
      });
  }

  termsAndConditions() {
    const SwalTerms = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success m-1',
      },
      buttonsStyling: false,
    });

    SwalTerms.fire({
      allowOutsideClick: false,
      title: 'Terms And Conditions',
      width: '75%',
      html: "<div id='general1' class='collapse show' role='tabpanel' aria-labelledby='headingOne'><p>An admin theme is a HTML, CSS, JavaScript template that is used to build the user interface for the admin side of a web application. They usually contain the HTML, CSS code for designs that you can use while building your admin dashboard.</p><p>Using admin themes in your projects saves you a lot of time and money simply because you don't need to design and code the frontend templates from scratch and you can get your application up and running in a short time.</p></div>",
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      showCancelButton: false,
    }).then((res) => {});
  }

  startTimer() {
    this.twoFaResendTime = true;
  }

  endTimer(e: any) {
    if (e['action'] == 'done') {
      this.twoFaResendTime = false;
    }
  }
}
