import { Component, OnInit } from '@angular/core';

import * as iconsSource from './data'


@Component({
  selector: 'app-icons-selector',
  templateUrl: './icons-selector.component.html',
  styleUrls: ['./icons-selector.component.css'],
})
export class IconsSelectorComponent implements OnInit {
  ICON_STARTER = 'ler';
  ICON_TYPE: 'duotone' | 'outline' | 'solid' = 'outline';

  selectedCategory: number = null;
  selectedIcon: string;
  dropdownOpen: boolean = false;



  categories = [

    { id: null, name: 'All Icons', icons: Object.values(iconsSource).flatMap(iconCategory => iconCategory.icons_list)},
    ...Object.values(iconsSource).map((icon, index) => ({id: index, name: icon.category_name, icons: icon.icons_list})),
  ];

  constructor() {}

  ngOnInit() {

    // this.selectedCategory = this.categories[0].id;
    //  this.categories[0].icons = this.categories.flatMap(category => category.icons);

  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectCategory(categoryId: number ) {
    this.selectedCategory = categoryId;
  }



  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.dropdownOpen = false;
  }

  updateIconType(iconType: 'duotone' | 'outline' | 'solid') {

   this.ICON_TYPE = iconType;

  }




}
