import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-media-library-upload-zone',
  templateUrl: './media-library-upload-zone.component.html',
  styleUrls: ['./media-library-upload-zone.component.css'],
})
export class MediaLibraryUploadZoneComponent implements OnInit {
  @Input() uploadText: string;
  ratioDisplay: string = '';
  @Input() imgSpecs: {
    width?: number;
    height?: number;
    ratio?: number;
  };

  @Output() ClickOnActiveArea: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {
    this.ratioDisplay = this.decimalToImageRatio(
      this.imgSpecs
    );
  }

  onClickOnActiveArea() {
    this.ClickOnActiveArea.emit();
  }

  decimalToImageRatio(
    specs: {
      width?: number;
      height?: number;
      ratio?: number;
    }
  ): string {
    // Ensure the input is a valid decimal greater than or equal to 0
    if (specs.ratio < 0) {
      throw new Error('Input must be a decimal greater than or equal to 0.');
    }

    // If width and height are not provided, return the simplified ratio
    if (!specs.width && !specs.height) {
      const gcd = (a: number, b: number): number => {
        if (b === 0) {
          return a;
        }
        return gcd(b, a % b);
      };

      const numerator = Math.round(specs.ratio * 100);
      const denominator = 100;
      const divisor = gcd(numerator, denominator);

      return `${numerator / divisor}: ${denominator / divisor}`;
    }

    // If width and height are provided, calculate the ratio based on them
    if (specs.width || specs.height) {
      if (specs.width) {
        specs.height = specs.width / specs.ratio;
      }
      if (specs.height) {
        specs.width = specs.height * specs.ratio;
      }

      return `${specs.width}px : ${specs.height}px`;
    }

    throw new Error('Provide either both width and height or neither.');
  }
}
