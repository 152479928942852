import {
  Payout_Gateway_Enum,
  Payment_Gateway_Enum,
} from 'src/app/models/portal';
import { Component, OnInit, Input } from '@angular/core';

export enum PaymentMethodViewTypeEnum {
  icon = 'icon',
  text = 'text',
  both = 'both',
}
@Component({
  selector: 'app-payment-method-view',
  templateUrl: './payment-method-view.component.html',
  styleUrls: ['./payment-method-view.component.css'],
})
export class PaymentMethodViewComponent implements OnInit {
  payoutGateway = Payout_Gateway_Enum;
  paymentGateway = Payment_Gateway_Enum;
  paymentMethodViewTypeEnum = PaymentMethodViewTypeEnum;

  @Input() methodEnum: Payout_Gateway_Enum | Payment_Gateway_Enum | string;
  enums: [] = [];
  @Input() type: PaymentMethodViewTypeEnum | string = PaymentMethodViewTypeEnum.text;
  @Input() iconSize: number = 50;

  @Input() disableBG = false;
  constructor() {}

  ngOnInit(): void {}
}
