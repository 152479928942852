import { Component, OnInit, Input } from '@angular/core';

import { NotificationsService } from '../../../../services/notifications.service';
import { SharedMessengerService } from '../../../../partials/messenger/shared-messenger.service';


@Component({
  selector: 'app-owner-messages-list',
  templateUrl: './owner-messages-list.component.html',
  styleUrls: ['./owner-messages-list.component.css'],
})
export class OwnerMessagesListComponent implements OnInit {
  // @Input() notifications: NotificationElement[] = [
  //   {
  //     id: 1,
  //     content: ', has accepted your invitation to join your network.',
  //     target: { label: 'Hossam Abdallah (hossam@gmail.com)' },
  //     type: OwnerNotificationTypeEnum.affiliate_accepted,
  //     status: NotificationStatusEnum.unread,
  //     image: 'https://picsum.photos/200',
  //     time: 1648358110,
  //   },
  // ];

  constructor(
    public notificationsService: NotificationsService,
    public sharedMessengerService: SharedMessengerService
  ) {}

  ngOnInit(): void {}
}
