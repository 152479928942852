import { Component } from '@angular/core';

@Component({
  selector: 'app-global-search-preferences',
  templateUrl: './global-search-preferences.component.html',
  styleUrls: ['./global-search-preferences.component.css']
})
export class GlobalSearchPreferencesComponent {

}
