import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../services';

import { Store } from '@ngrx/store';
import {

  NotificationsActions,
   setNotificationAsRead,
  setNotificationAsReadSuccess,
} from './notifications.actions';
import { NotificationsResponseModel } from 'src/app/models/owner-dashboard';

@Injectable()
export class NotificationsEffects {
  loadNotifications$;
  setNotificationAsRead$;
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {
    this.loadNotifications$ = createEffect(() =>
      this.actions$.pipe(
        ofType(NotificationsActions.load),
        mergeMap(() =>
          this.api.getOwnerNotifications().pipe(
            map((notifications: NotificationsResponseModel) =>
              NotificationsActions.loadSuccess({
                notifications: notifications.notifications,
                unreadNotificationsCount:
                  notifications.unreadNotificationsCount,
              })
            ),
            catchError((error) => of(NotificationsActions.loadFailure({ error })))
          )
        )
      )
    );



    this.setNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNotificationAsRead),
      mergeMap((action) =>

      this.api.markNotificationsAsRead(action.notificationsIds, (error)=>{throw error}).pipe(
          map((notifications: NotificationsResponseModel) =>
          setNotificationAsReadSuccess({
              notificationsIds: action.notificationsIds
            })
          ),
          catchError((error) => of(NotificationsActions.loadFailure({ error })))
        )
      )
    )
  );
  }
}
