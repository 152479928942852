import { Component, Input, OnInit } from '@angular/core';
import { DynamicActionButtonItem } from '../dynamic-action-buttons.component';

@Component({
  selector: 'app-button-view',
  templateUrl: './button-view.component.html',
  styleUrls: ['./button-view.component.css']
})
export class ButtonViewComponent implements OnInit {
  @Input() item: DynamicActionButtonItem;
  constructor() { }

  ngOnInit() {
  }

}
