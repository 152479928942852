import { PaymentMethodViewTypeEnum } from './../../../../../shared/partials/payment-method-view/payment-method-view.component';
import { SharedDomainService } from './../../shared-domain.service';
import { DomainItem, ActionTypeEnum } from './../../../../../models/domain';
import { Component, Input, OnInit } from '@angular/core';

export enum AffiliateNoteStatusEnum {
  done = 'done',
  saving = 'saving',
  editing = 'editing',
  no_changes = 'no_changes',
  none = 'none',
}
@Component({
  selector: 'app-domain-details-card',
  templateUrl: './domain-details-card.component.html',
  styleUrls: ['./domain-details-card.component.css'],
})
export class DomainDetailsCardComponent implements OnInit {
  paymentMethodViewTypeEnum = PaymentMethodViewTypeEnum;

  actionTypeEnum = ActionTypeEnum;
  @Input() domain: DomainItem;
  @Input() onlyCard: boolean = false;

  constructor(private sharedDomainService: SharedDomainService) {}

  ngOnInit(): void {}
}
