import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WebsiteBlogState, blogPostsAdapter } from './website-blog.reducer';
import { WebsiteCompoundState } from '../../../learmo.reducer';


// Create a feature selector for the 'website' feature
export const selectCompoundWebsiteState = createFeatureSelector<WebsiteCompoundState>('website');
export const selectWebsiteBlogState = createSelector(selectCompoundWebsiteState, (state) => state.blog);



export const selectBlogPosts = createSelector(
  selectWebsiteBlogState,
  (state) => blogPostsAdapter.getSelectors().selectAll(state)
);

export const isBlogPostsLoaded = createSelector(selectWebsiteBlogState, (state) => state.postsLoaded);
export const isWebsiteAggregateAnalyticsLoadingError = createSelector(selectWebsiteBlogState, (state) => state.postsError);



// // Select the delete process status
// export const selectDeleteProcessStatus = createSelector(
//   selectWebsiteBlogState,
//   (state) => state.createPostProcess.status
// );

export const selectBlogPostProcessStatus = createSelector(
  selectWebsiteBlogState,
  (state) => state.createPostProcess
);
